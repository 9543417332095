const styles = {
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  profileDetails: {
    alignItems: "flex-start",
    justifyContent: "space-between",
    margin: "0 16px"
  },
  profileName: {
    fontWeight: "700",
    letterSpacing: "0.32px"
  },
  profileInfoLink: {
    color: "#0076cf"
    // textTransform: "capitalize"
  },
  versionNo: {
    color: "#9d9ea4",
    margin: "0 16px"
  },
  versionCard: {
    backgroundColor: "#e5f4fb",
    borderRadius: "8px",
    margin: "0 16px",
    padding: "14px 16px"
  },
  navigationLabel: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: 1.4,
    marginLeft: "16px"
    // textTransform: "capitalize"
  },
  info: {
    margin: "0 16px"
  },
  caption: {
    fontWeight: "700",
    marginBottom: "16px"
  },
  idInfo: {
    margin: "0px 16px 40px",
    padding: "8px 0",
    borderRadius: "4px",
    backgroundColor: "#fff",
    boxShadow: "0 12px 24px -10px #d8d8d8"
  },
  inputLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.57,
    marginBottom: "8px"
  },
  btnContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // position: "fixed",
    width: "100%",
    marginTop: "auto",
    bottom: 0
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  btn: {
    display: "inline-block",
    position: "relative",
    width: "100%"
  },
  btnText: {
    color: "#0076CF",
    marginTop: "16px",
    padding: 0,
    textTransform: "capitalize"
  },
  errorText: {
    fontSize: "14px",
    lineHeight: 1.57,
    color: "#be0712",
    margin: "4px 0 0 0"
  },
  dropdownTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4
  },
  otp: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: 1.25,
    color: "#262d58",
    width: "48px",
    height: "64px",
    borderRadius: "4px",
    border: "solid 1px rgba(51, 51, 51, 0.2)"
  },
  infoFooter: {
    margin: "0 16px 24px",
    lineHeight: "1.57",
    color: "#505050",
    letterSpacing: "normal",
    fontSize: "14px",
    textAlign: "left"
  }
};

export default styles;
