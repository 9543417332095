import {
  INVEST_CHECKOUT,
  INVEST_CHECKOUT_SUCCESS,
  INVEST_CHECKOUT_FAILED,
  CLEAR_INVEST_CHECKOUT_LOADING,
  CLEAR_INVEST_CHECKOUT_ERROR
} from "./constant";

const initialState = {
  investCheckoutRes: {},
  investCheckoutError: false,
  messageInvestCheckout: "",
  fundResult: {}
};

export default function investCheckoutReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case INVEST_CHECKOUT:
      return {
        ...state,
        investCheckoutLoading: true
      };
    case INVEST_CHECKOUT_SUCCESS:
      return {
        ...state,
        investCheckoutRes: payload,
        investCheckoutLoading: true
      };
    case INVEST_CHECKOUT_FAILED:
      return {
        ...state,
        investCheckoutError: true,
        messageInvestCheckout: payload,
        investCheckoutLoading: false
      };
    case CLEAR_INVEST_CHECKOUT_LOADING:
      return {
        ...state,
        investCheckoutLoading: false
      };
    case CLEAR_INVEST_CHECKOUT_ERROR:
      return {
        ...state,
        investCheckoutError: false
      };

      return {
        ...state,
        investCheckoutError: true,
        messageInvestCheckout: payload,
        createFundLoading: false
      };
    default:
      return state;
  }
}
