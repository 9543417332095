import React from "react";
import { compose } from "underscore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import styles from "./styles";
import CustomButton from "../Button";
import { withStyles } from "@material-ui/core/styles";
import "./alertAccount.scss";
function AlertAccount(props) {
  const {
    handleClose,
    isOpen,
    classes,
    firstText,
    secondText,
    title,
    titleStyle,
    firstTextDialogStyle,
    hideDoneButton,
    children
  } = props;

  const StyledDialog = withStyles({
    scrollPaper: {
      padding: "0 16px"
    },
    paperFullWidth: {
      minWidth: "100%"
    }
  })(Dialog);
  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="popup-account-sts-container">
        <DialogTitle className={classes.titleContainer}>
          <Typography className={`${classes.baseFont} ${classes.title}`} style={titleStyle}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.descContainerAccountBox} style={firstTextDialogStyle}>
          <Typography className={`${classes.baseFont} ${classes.description}`}>
            {firstText}
          </Typography>
          <Typography className={`${classes.baseFont} ${classes.link}`}>
            {secondText}
          </Typography>
        </DialogContent>

        <DialogActions
          className={classes.actionContainer}
          style={{
            display: "flex",
            flexDirection: "row"
          }}
        >
          {hideDoneButton ? null : (
            <CustomButton
              text={"Done"}
              color="#fff"
              backgroundColor="#1278cc"
              onClick={handleClose}
            />
          )}

          {children}
        </DialogActions>
      </div>
    </StyledDialog>
  );
}
export default compose(withStyles(styles))(AlertAccount);
