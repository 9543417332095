import { Icon } from "@material-ui/core";
import React from "react";

const DoneIcon = ({ active }) => {
  return (
    <Icon
      fontSize="small"
      style={{
        color: "#fff",
        backgroundColor: active ? "#0091da" : "#9d9ea4",
        borderRadius: "100%",
        padding: "4px",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      done
    </Icon>
  );
};

const FailedIcon = () => {
  return (
    <Icon
      fontSize="small"
      style={{
        color: "#fff",
        backgroundColor: "#be0712",
        borderRadius: "100%",
        padding: "4px",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      close
    </Icon>
  );
};

const WarningIcon = () => {
  return (
    <Icon
      style={{
        color: "#fff",
        backgroundColor: "#be0712",
        borderRadius: "100%",
        padding: "4px",
        width: "24px",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "16px"
      }}
    >
      priority_high
    </Icon>
  );
};

export { DoneIcon, FailedIcon, WarningIcon };
