import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  getGoals,
  getCustomerGoals,
  setSavingType,
  setSelectedGoal,
  setGeneralSavingDetails,
  hideError,
  resetData,
} from "../redux/action";
import { resetErrorGoalCreation, createGoal } from "../step3/redux/action";
import {
  CATEGORY_SAVING,
  GENERAL_SAVING,
  NEW_USER,
  ONBOARDING_USER,
  GOAL_CREATION,
} from "../redux/constant";
import { setStep } from "../../globalRedux/actions";

import {
  StepNumber,
  StepCaption,
  GoalItem,
  CustomButton,
  ErrorAlert,
  DefaultLoader,
  AlertInfo,
} from "../../components";
import styles from "./styles";
import "./styles.scss";
import "../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { deleteGoalForm } from "../redux/action";
const StepOne = props => {
  const {
    profile,
    isModalOpen,
    errorMessage,
    goalList,
    customerGoals,
    getGoals,
    getCustomerGoals,
    setSavingType,
    setSelectedGoal,
    setGeneralSavingDetails,
    setStep,
    hideError,
    resetData,
    history,
    classes,
    deleteGoalForm,
    resetErrorGoalCreation,
    createGoal,
    createGoalLoading,
    errorCreateGoal,
    listGoalLoading
  } = props;

  const [goals, setGoals] = useState([]);
  const [generalSaving, setGeneralSaving] = useState();
  const [isGeneralSaving, setIsGeneralSaving] = useState(true);
  const masterIdRef = useRef(null);
  const alreadySubmit = useRef(null);

  useEffect(() => {
    if (profile && profile.masterId) {
      if (
        masterIdRef.current !== null &&
        masterIdRef.current !== profile.masterId
      ) {
        resetData();
        localStorage.clear();
        history.push("/");
      }

      masterIdRef.current = profile.masterId;
    }
  }, [profile]);

  /**
   * @function Get Goal List from API
   * @function Save current step into redux
   */
  useEffect(() => {
    getGoals();
    getCustomerGoals();
    deleteGoalForm();
    setStep(`/step-one`);
  }, []);

  useEffect(() => {
    // window.consoleLog(goalList);
    if (goalList && goalList.length > 0) {
      setGoals(goalList.filter(goal => goal.productType === "Plan"));
      setGeneralSaving(goalList.find(goal => goal.productType === "Retail"));
    }
  }, [goalList]);

  useEffect(() => {
    if (customerGoals && customerGoals.length > 0) {
      let isGeneral =
        customerGoals.find(goal => goal.plan.retailFlag !== false) || null;

      if (isGeneral && Object.keys(isGeneral).length !== 0) {
        setIsGeneralSaving(false);
      }
    }
  }, [customerGoals]);

  /** @constant isNewUser to be used as conditional rendering */
  const isNewUser = localStorage.getItem("isNewUser");

  /**
   * @function Navigate back to previous screen
   *    if New User go back to get started screen
   *    else redirect to dashboard screen
   */
  const handleBack = () => {
    isNewUser === NEW_USER
      ? history.push("/")
      : isNewUser === ONBOARDING_USER
      ? history.push("/onBoarding/form-step-one")
      : isNewUser === GOAL_CREATION
      ? history.push("/dashboard")
      : history.push("/dashboard");
  };

  /**
   * Save saving data and save saving type as category saving
   * @param {Object} item
   */
  const handleClickCategory = item => {
    setSavingType(CATEGORY_SAVING);
    setSelectedGoal(item);
    history.push("/step-two");
  };

  /**
   * Save saving data and save saving type as general saving
   */
  const handleClickGeneralSaving = () => {
    if (alreadySubmit.current) return;
    alreadySubmit.current = true;
    dataLayerGTM("click_tag", "Click", "Button", "General Savings");
    setSavingType(GENERAL_SAVING);
    setGeneralSavingDetails(generalSaving);
    const payload = {
      productId: generalSaving.id,
      invsPlanId: "120",
      term: "1",
      targetAmount: "0",
      planAlias: "General Savings",
    };

    createGoal(payload, () => history.push(`/make-deposit/${GOAL_CREATION}`));
    // history.push(`/make-deposit/${GOAL_CREATION}`);
  };

  /**
   *
   * @param {component} children
   * @returns skeleteon loading box
   */
  const Box = ({ children }) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          width: "160px",
          height: "100px",
          marginLeft: "8px",
          marginRight: "8px",
          marginBottom: "8px",
        }}
      >
        {children}
      </div>
    );
  };

  return (
    <div className={`bg-white ${classes.container}`}>
      {(listGoalLoading || createGoalLoading) && <DefaultLoader />}
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCreateGoal}
        isTimeout
        handleAction={() => {
          resetErrorGoalCreation();
          alreadySubmit.current = false;
        }}
      />
      <StepNumber onClick={handleBack} text="Step 1 of 3" />
      <StepCaption text='Select your financial goal or "General savings" if you do not have a specific goal' />

      {listGoalLoading ? (
        <div
          style={{
            display: "flex",
            margin: "0 8px",
            justifyContent: "space-around",
          }}
        >
          <Skeleton count={4} wrapper={Box} width="100%" height="100%" />
          <Skeleton count={4} wrapper={Box} width="100%" height="100%" />
        </div>
      ) : (
        <>
          {goals && goals.length > 0 && (
            <div className="goalListContainer">
              <Grid container spacing={8}>
                {goals
                  .slice()
                  .sort((a, b) => (a.idx > b.idx ? 1 : -1))
                  .map(item => {
                    return (
                      <GoalItem
                        key={item.id}
                        onClick={() => {
                          dataLayerGTM(
                            "click_tag",
                            "Click",
                            "Goal",
                            `Goal ${item.name}`
                          );
                          handleClickCategory(item);
                        }}
                        name={item.name}
                        image={item.image}
                      />
                    );
                  })}
              </Grid>
            </div>
          )}
          {generalSaving && isGeneralSaving && (
            <div
              className={classes.btn}
              style={{ boxShadow: "0px 0px 20px rgba(0,0,0,0.16" }}
            >
              <CustomButton
                text="General Savings"
                variant="outlined"
                color="#1278CC"
                backgroundColor="#fff"
                onClick={handleClickGeneralSaving}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

const withConnect = connect(
  state => ({
    listGoalLoading: state.goal.listGoalLoading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    goalList: state.goal.goalList,
    customerGoals: state.goal.customerGoals,
    profile: state.goal.profile,
    ...state.stepThreeReducer,
  }),
  {
    getGoals,
    getCustomerGoals,
    setSavingType,
    setSelectedGoal,
    setGeneralSavingDetails,
    setStep,
    hideError,
    resetData,
    deleteGoalForm,
    resetErrorGoalCreation,
    createGoal,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withProfileRedirect
)(StepOne);
