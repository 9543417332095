const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  historyLabel: {
    padding: "16px 16px 8px",
    justifyContent: "space-between"
  },
  historyLabelMonth: {
    fontWeight: "700",
    fontSize: "14px",
    color: "#043964"
  },
  historyValue: {
    backgroundColor: "#fff",
    padding: "16px",
    justifyContent: "space-between"
  },
  historyValueMonth: {
    lineHeight: 1.38,
    color: "#616367"
  },
  historyValueMoney: {
    fontWeight: "700",
    lineHeight: 1.5
  }
};

export default styles;
