import {
  SET_SELECTED_PROMO,
  RESET_SELECTED_PROMO,
  GET_PROMO,
  GET_PROMO_SUCCESS,
  GET_PROMO_FAILED,
} from "./constants";

export const setSelectedPromo = payload => {
  return {
    type: SET_SELECTED_PROMO,
    payload,
  };
};

export const resetSelectedPromo = () => {
  return {
    type: RESET_SELECTED_PROMO,
  };
};

export const getPromo = (payload, callback) => {
  return {
    type: GET_PROMO,
    payload,
    callback,
  };
};
export const getPromoSuccess = payload => {
  return {
    type: GET_PROMO_SUCCESS,
    payload,
  };
};

export const getPromoFailed = payload => {
  return {
    type: GET_PROMO_FAILED,
    payload,
  };
};
