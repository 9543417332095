import {
  SET_AUTO_DEPOSIT,
  SET_AUTO_DEPOSIT_SUCCESS,
  SET_AUTO_DEPOSIT_FAILED,
  CLEAR_ERROR_RSP,
  SET_EDIT_RSP,
  SET_EDIT_RSP_SUCCESS,
  SET_EDIT_RSP_FAILED,
  SET_DISABLE_RSP_FAILED,
  SET_DISABLE_RSP,
  SET_DISABLE_RSP_SUCCESS,
  GET_RSP_DETAIL,
  GET_RSP_DETAIL_SUCCESS,
  GET_RSP_DETAIL_FAILED,
  SET_BACK_PAYLOAD,
  GET_RSP_MIN_AMOUNT,
  GET_RSP_MIN_AMOUNT_SUCCESS,
  GET_RSP_MIN_AMOUNT_FAILED,
  SET_RSP_PAYLOAD,
  MAKE_DEPOSIT,
  MAKE_DEPOSIT_SUCCESS,
  MAKE_DEPOSIT_FAILED,
  CLEAR_ERROR_CASHIN,
  SET_DEPOSIT_SUMMARY,
  SET_LAST_AMOUNT_SAVE
} from "./contant";

const initialState = {
  errorRSP: false,
  backPayloadRsp: {},
  rspMinAmount: { value: process.env.REACT_APP_RSP_MIN_AMOUNT.trim() },
  rspDetail: { rspStatus: "" },
  rspPayload: {},
  loadingRspDetail: false,
  makeDepositLoading: false,
  errorCashIn: false,
  depositSummaryPayload: null,
  lastISaveAmount: null
};

export default function autoDepositReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_BACK_PAYLOAD:
      return {
        ...state,
        backPayloadRsp: payload
      };
    case GET_RSP_DETAIL:
      return {
        ...state,
        loadingRspDetail: true
      };
    case GET_RSP_DETAIL_SUCCESS:
      return {
        ...state,
        rspDetail: payload,
        loadingRspDetail: false
      };
    case GET_RSP_DETAIL_FAILED:
      return {
        ...state,
        rspDetail: payload,
        loadingRspDetail: false
      };
    case SET_DISABLE_RSP:
      return {
        ...state,
        loadingAutoDeposit: true
      };
    case SET_DISABLE_RSP_SUCCESS:
      return {
        ...state,
        disableRSPStatus: payload,
        loadingAutoDeposit: false
      };
    case SET_DISABLE_RSP_FAILED:
      return {
        ...state,
        loadingAutoDeposit: false,
        errorRSP: true
      };
    case SET_EDIT_RSP:
      return {
        ...state,
        loadingAutoDeposit: true
      };
    case SET_EDIT_RSP_SUCCESS:
      return {
        ...state,
        loadingAutoDeposit: false,
        editAutoDeposit: payload
      };
    case SET_EDIT_RSP_FAILED:
      return {
        ...state,
        loadingAutoDeposit: false,
        errorRSP: true
      };
    case SET_AUTO_DEPOSIT:
      return {
        ...state,
        loadingAutoDeposit: true
      };
    case SET_AUTO_DEPOSIT_SUCCESS:
      return {
        ...state,
        // loadingAutoDeposit: false,
        autoDeposit: payload
      };
    case SET_AUTO_DEPOSIT_FAILED:
      return {
        ...state,
        loadingAutoDeposit: false,
        errorRSP: true
      };
    case CLEAR_ERROR_RSP:
      return {
        ...state,
        errorRSP: false
      };

    case "rsp/setup-loading":
      return {
        ...state,
        loadingAutoDeposit: false
      };
    case GET_RSP_MIN_AMOUNT:
      return {
        ...state,
        rspMinAmountLoading: true
      };
    case GET_RSP_MIN_AMOUNT_SUCCESS:
      return {
        ...state,
        rspMinAmount: payload,
        rspMinAmountLoading: false
      };
    case GET_RSP_MIN_AMOUNT_FAILED:
      return {
        ...state,
        rspMinAmount: {
          value: process.env.REACT_APP_RSP_MIN_AMOUNT.trim()
        },
        rspMinAmountLoading: false
      };
    case SET_RSP_PAYLOAD:
      return {
        ...state,
        rspPayload: payload
      };
    case MAKE_DEPOSIT:
      return {
        ...state,
        makeDepositLoading: true
      };
    case MAKE_DEPOSIT_FAILED:
      return {
        ...state,
        makeDepositLoading: false,
        errorCashIn: true
      };
    case MAKE_DEPOSIT_SUCCESS:
      return {
        ...state,
        // make it true to avoid user tap again cash in when there is delay after success and before POST DIRECT
        makeDepositLoading: true
      };
    case CLEAR_ERROR_CASHIN:
      return {
        ...state,
        errorCashIn: false
      };
    case SET_DEPOSIT_SUMMARY:
      return {
        ...state,
        depositSummaryPayload: payload
      };
    case SET_LAST_AMOUNT_SAVE:
      return {
        ...state,
        lastISaveAmount: payload
      };

    default:
      return state;
  }
}
