import {
  GET_CUST_INVEST,
  GET_CUST_INVEST_SUCCESS,
  GET_CUST_INVEST_FAILED
} from "./constants";

const initialState = { custInvest: null };

export default function custinvestReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUST_INVEST:
      return {
        ...state,
        custInvestLoading: true
      };
    case GET_CUST_INVEST_SUCCESS:
      return {
        ...state,
        custInvest: action.payload,
        custInvestLoading: false
      };
    case GET_CUST_INVEST_FAILED:
      return {
        ...state,
        custInvestLoading: false
      };

    default:
      return state;
  }
}
