import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";
import CustomButton from "../Button";
import { hideError } from "../../globalRedux/actions";

const StyledDialog = withStyles({
  scrollPaper: {
    padding: "0 16px"
  },
  paperFullWidth: {
    minWidth: "100%"
  }
})(Dialog);

const AlertInfo = ({
  title,
  description,
  isOpen,
  isCS,
  isTimeout,
  isCancel,
  isObject,
  handleClose,
  handleAction,
  handleCancel,
  classes,
  btnTxt,
  fullWidthBtn,
  hideError,
  children
}) => {
  const split = text => {
    return text
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, str => str.toUpperCase());
  };

  const handleClick = () => {
    handleAction();
    hideError();
  };

  return (
    <StyledDialog fullWidth open={isOpen} onClose={handleClose}>
      <DialogTitle className={classes.titleContainer}>
        <Typography className={`${classes.baseFont} ${classes.title}`}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.descContainer}>
        {!isObject ? (
          children ? (
            children
          ) : (
            <>
              <Typography
                className={`${classes.baseFont} ${classes.description}`}
              >
                {description}
              </Typography>
              {isTimeout && (
                <Typography
                  className={`${classes.baseFont} ${classes.description}`}
                  style={{ marginTop: "16px" }}
                >
                  Please try again later.
                </Typography>
              )}
              {isCS && (
                <Typography className={`${classes.baseFont} ${classes.link}`}>
                  For{" "}
                  {title === "Account Verification"
                    ? "enquiries"
                    : "assistance"}
                  , kindly contact our{" "}
                  <span
                    style={{ color: "#1278cc" }}
                    onClick={() =>
                      window.my.navigateTo({
                        url:
                          "/pages/link/link?url=" + encodeURIComponent(isCS),
                      })
                    }
                  >
                    Customer Care Centre
                  </span>{" "}
                  for assistance.
                </Typography>
              )}
            </>
          )
        ) : (
          <>
            {Object.keys(isObject)
              .filter(key => isObject[key])
              .map(key => {
                return (
                  <Typography
                    className={`${classes.baseFont} ${classes.description}`}
                  >
                    &#183; {split(key)}
                  </Typography>
                );
              })}
          </>
        )}
      </DialogContent>
      <DialogActions
        className={classes.actionContainer}
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {fullWidthBtn ? (
          <button
            onClick={handleClick}
            className={`${classes.baseFont} ${classes.fullWidthBtn}`}
          >
            Okay
          </button>
        ) : (
          <Button
            onClick={handleClick}
            className={`${classes.baseFont} ${classes.cancel}`}
            style={{ fontWeight: "700" }}
          >
            OK
          </Button>
        )}
      </DialogActions>
    </StyledDialog>
  );
};

const withConnect = connect(
  () => ({}),
  { hideError }
);

export default compose(
  withConnect,
  withStyles(styles)
)(AlertInfo);
