import {
  GET_OCCUPATIONS,
  GET_OCCUPATIONS_SUCCESS,
  GET_OCCUPATIONS_FAILED,
  GET_NATURE_BUSSINESS,
  GET_NATURE_BUSSINESS_SUCCESS,
  GET_NATURE_BUSSINESS_FAILED,
  GET_ANNUAL_INCOME_V2,
  GET_ANNUAL_INCOME_V2_SUCCESS,
  GET_ANNUAL_INCOME_V2_FAILED,
  GET_COUNTRY_V2,
  GET_COUNTRY_V2_SUCCESS,
  GET_COUNTRY_V2_FAILED,
  GET_RACE_V2,
  GET_RACE_V2_SUCCESS,
  GET_RACE_V2_FAILED,
  GET_STATE_V2,
  GET_STATE_V2_SUCCESS,
  GET_STATE_V2_FAILED,
  GET_SOURCE_OF_FUND_V2,
  GET_SOURCE_OF_FUND_V2_SUCCESS,
  GET_SOURCE_OF_FUND_V2_FAILED,
  GET_SOURCE_OF_WEALTH,
  GET_SOURCE_OF_WEALTH_SUCCESS,
  GET_SOURCE_OF_WEALTH_FAILED,
  GET_ESTIMATED_NET_WORTH,
  GET_ESTIMATED_NET_WORTH_SUCCESS,
  GET_ESTIMATED_NET_WORTH_FAILED,
  GET_NUMBER_OF_TRANSACTION,
  GET_NUMBER_OF_TRANSACTION_SUCCESS,
  GET_NUMBER_OF_TRANSACTION_FAILED,
  GET_VOLUME_OF_TRANSACTION,
  GET_VOLUME_OF_TRANSACTION_SUCCESS,
  GET_VOLUME_OF_TRANSACTION_FAILED,
  SET_ONBOARD_ONE,
  GET_MARITAL_STATUSV2,
  GET_MARITAL_STATUSV2_SUCCESS,
  GET_MARITAL_STATUSV2_FAILED,
  GET_INVESTMENT_OBJECTIVE,
  GET_INVESTMENT_OBJECTIVE_FAILED,
  GET_INVESTMENT_OBJECTIVE_SUCCESS
} from "./constant";

export function setOnboardStepOne(payload) {
  return {
    type: SET_ONBOARD_ONE,
    payload
  };
}

export function getInvestmentObjective() {
  return {
    type: GET_INVESTMENT_OBJECTIVE
  };
}

export function getInvestmentObjectiveSuccess(data) {
  return {
    type: GET_INVESTMENT_OBJECTIVE_SUCCESS,
    payload: data
  };
}

export function getInvestmentObjectiveFailed(data) {
  return {
    type: GET_INVESTMENT_OBJECTIVE_FAILED,
    payload: data
  };
}

export function getVolumeOfTransaction() {
  return {
    type: GET_VOLUME_OF_TRANSACTION
  };
}

export function getVolumeOfTransactionSuccess(data) {
  return {
    type: GET_VOLUME_OF_TRANSACTION_SUCCESS,
    payload: data
  };
}

export function getVolumeOfTransactionFailed(data) {
  return {
    type: GET_VOLUME_OF_TRANSACTION_FAILED,
    payload: data
  };
}

export function getNumberOfTransaction() {
  return {
    type: GET_NUMBER_OF_TRANSACTION
  };
}

export function getNumberOfTransactionSuccess(data) {
  return {
    type: GET_NUMBER_OF_TRANSACTION_SUCCESS,
    payload: data
  };
}

export function getNumberOfTransactionFailed(data) {
  return {
    type: GET_NUMBER_OF_TRANSACTION_FAILED,
    payload: data
  };
}

export function getEstimatedNetWorth() {
  return {
    type: GET_ESTIMATED_NET_WORTH
  };
}

export function getEstimatedNetWorthSuccess(data) {
  return {
    type: GET_ESTIMATED_NET_WORTH_SUCCESS,
    payload: data
  };
}

export function getEstimatedNetWorthFailed(data) {
  return {
    type: GET_ESTIMATED_NET_WORTH_FAILED,
    payload: data
  };
}

export function getSourceOfWealth() {
  return {
    type: GET_SOURCE_OF_WEALTH
  };
}

export function getSourceOfWealthSuccess(data) {
  return {
    type: GET_SOURCE_OF_WEALTH_SUCCESS,
    payload: data
  };
}

export function getSourceOfWealthFailed(data) {
  return {
    type: GET_SOURCE_OF_WEALTH_FAILED,
    payload: data
  };
}

export function getSourceOfFundV2() {
  return {
    type: GET_SOURCE_OF_FUND_V2
  };
}

export function getSourceOfFundV2Success(data) {
  return {
    type: GET_SOURCE_OF_FUND_V2_SUCCESS,
    payload: data
  };
}

export function getSourceOfFundV2Failed(data) {
  return {
    type: GET_SOURCE_OF_FUND_V2_FAILED,
    payload: data
  };
}

export function getStateV2() {
  return {
    type: GET_STATE_V2
  };
}

export function getStateV2Success(data) {
  return {
    type: GET_STATE_V2_SUCCESS,
    payload: data
  };
}

export function getStateV2Failed(data) {
  return {
    type: GET_STATE_V2_FAILED,
    payload: data
  };
}
export function getRaceV2() {
  return {
    type: GET_RACE_V2
  };
}
export function getRaceV2Success(data) {
  return {
    type: GET_RACE_V2_SUCCESS,
    payload: data
  };
}

export function getRaceV2Failed(data) {
  return {
    type: GET_RACE_V2_FAILED,
    payload: data
  };
}

export function getCountryV2(data) {
  return {
    type: GET_COUNTRY_V2,
    payload: data
  };
}

export function getCountryV2Success(data) {
  return {
    type: GET_COUNTRY_V2_SUCCESS,
    payload: data
  };
}

export function getCountryV2Failed(data) {
  return {
    type: GET_COUNTRY_V2_FAILED,
    payload: data
  };
}

export function getAnnualIncomeV2(data) {
  return {
    type: GET_ANNUAL_INCOME_V2,
    payload: data
  };
}
export function getAnnualIncomeV2Success(data) {
  return {
    type: GET_ANNUAL_INCOME_V2_SUCCESS,
    payload: data
  };
}

export function getAnnualIncomeFailed(data) {
  return {
    type: GET_ANNUAL_INCOME_V2_FAILED,
    payload: data
  };
}

export function getOccupations(data) {
  return {
    type: GET_OCCUPATIONS,
    payload: data
  };
}
export function getOccupationsSuccess(data) {
  return {
    type: GET_OCCUPATIONS_SUCCESS,
    payload: data
  };
}

export function getOccupationsFailed(data) {
  return {
    type: GET_OCCUPATIONS_FAILED,
    payload: data
  };
}

export function getNOBV2(data) {
  return {
    type: GET_NATURE_BUSSINESS,
    payload: data
  };
}
export function getNOBV2Success(data) {
  return {
    type: GET_NATURE_BUSSINESS_SUCCESS,
    payload: data
  };
}

export function getNOBV2Failed(data) {
  return {
    type: GET_NATURE_BUSSINESS_FAILED,
    payload: data
  };
}

export function getMaritalStatusV2() {
  return {
    type: GET_MARITAL_STATUSV2
  };
}
export function getMaritalStatusV2Success(data) {
  return {
    type: GET_MARITAL_STATUSV2_SUCCESS,
    payload: data
  };
}

export function getMaritalStatusV2Failed() {
  return {
    type: GET_MARITAL_STATUSV2_FAILED
  };
}
