export function dataMapping(content) {
  const isSwitchFrom = content.type === "from";

  let data = {};

  if (content.productType === "invest") {
    data = {
      type: content.type,
      // there's different in property name in `customer-portfolio` and `funds` api
      name: isSwitchFrom ? (content.fund || {}).name : content.name,
      fundCd: isSwitchFrom ? content.fundCd : content.code,
      cifPlanId: content.cifPlanId,
      productType: "invest",
      accountNo: content.accountNo
    };
  } else {
    data = {
      type: content.type,
      name: content.planAlias,
      fundCd:
        content.plan &&
        "planFund" in content.plan &&
        Array.isArray(content.plan.planFund) &&
        content.plan.planFund.length > 0
          ? content.plan.planFund[0].fundCd
          : "",
      cifPlanId: content.id,
      productType: "save",
      accountNo: content.accountNo
    };
  }

  if (isSwitchFrom) {
    data.switchableAmount = content.currentValue;

    // assign fee for invest only
    if (content.productType === "invest") {
      data.fee = content.salesFeePerc;
    }
  }

  return data;
};