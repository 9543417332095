import React, { useState, useEffect } from "react";
import { Typography, FormControl, TextField } from "@material-ui/core";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { updateProfile, hideError } from "../../redux/action";

import {
  StepLabel,
  CustomButton,
  DefaultLoader,
  ErrorAlert
} from "../../../components";
import styles from "../styles";

const UpdateState = props => {
  const {
    loading,
    isModalOpen,
    errorMessage,
    profile,
    updateProfile,
    hideError,
    history,
    match: { params }
  } = props;

  const [userInfoStorage, setUserInfoStorage] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [state, setState] = useState("");

  useEffect(() => {
    setUserInfoStorage(JSON.parse(localStorage.getItem("mpUserInfo")));
  }, []);

  useEffect(() => {
    if (params && params.state) {
      setState(params.state);
    }
  }, [params]);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    } else if (userInfoStorage) {
      setUserInfo(userInfoStorage);
    } else {
      return;
    }
  }, [profile, userInfoStorage]);

  const handleUpdate = () => {
    // window.consoleLog(city);

    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("L"), //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      identities: [
        {
          idTypeCd: "NRIC", //R
          idNo: userInfo.identities.find(
            identity => identity.idTypeCd === "NRIC"
          ).idNo, //R
          citizenship: userInfo.identities.find(
            identity => identity.citizenship
          ).citizenship //R
        }
      ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R
        city: userInfo.contacts.find(contact => contact.city).city, //R the updated
        state: state, //R,
        country: userInfo.identities.find(identity => identity.citizenship)
          .citizenship //R
      }
    };

    // updateProfile(payload);
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="Update State"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            State
          </Typography>
          <TextField
            placeholder="City"
            variant="outlined"
            fullWidth
            value={state}
            onChange={e => setState(e.target.value)}
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" }
            }}
          />
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={state ? false : true}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile
  }),
  {
    updateProfile,
    hideError
  }
);

export default compose(
  withRouter,
  withConnect
)(UpdateState);
