import {
  GET_CUST_INVEST,
  GET_CUST_INVEST_SUCCESS,
  GET_CUST_INVEST_FAILED
} from "./constants";

export const getCustInvest = payload => {
  return {
    type: GET_CUST_INVEST,
    payload
  };
};

export const getCustInvestSuccess = payload => {
  return {
    type: GET_CUST_INVEST_SUCCESS,
    payload
  };
};

export const getCustInvestFailed = payload => {
  return {
    type: GET_CUST_INVEST_FAILED,
    payload
  };
};
