import React from "react";
import { NewsCard } from "..";
import { Typography } from "@material-ui/core";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { icons } from "../../constants";
import "./style.scss";

const NewsList = ({ newsFeed, handleViewAllNews }) => {
  return (
    <>
      <div className="l-news-list">
        <p className="l-news-list__title">What's new</p>
        <div className="l-news-list__wrapper" onClick={handleViewAllNews}>
          <Typography
            color="primary"
            style={{
              fontFamily: "FSElliotPro",
              fontSize: "16px",
              fontWeight: "normal",
              lineHeight: 1.5,
            }}
          >
            View all
          </Typography>
          <img src={icons.arrowRight} alt="arrowRight" />
        </div>
      </div>
      {newsFeed
        .sort((a, b) => a.dataIndex - b.dataIndex)
        .slice(0, 2)
        .map((item, index) => {
          return (
            <div
              key={index}
              style={{
                margin: "0 16px",
                paddingTop: "8px",
              }}
            >
              <NewsCard
                key={index}
                image={item.imageUrl}
                title={item.name}
                url={item.articleUrlEn}
                date={item.lastUpdated}
                // onClick={dataLayerGTM(
                //   "click_tag",
                //   "Click",
                //   "Link",
                //   `${item.name}`
                // )}
              />
            </div>
          );
        })}
    </>
  );
};

export default NewsList;
