import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import NumberFormat from "react-number-format";
import moment from "moment";

import styles from "./styles";

const TransactionValue = ({ date, transactionType, amount, classes, fee }) => {
  return (
    <div className={`${classes.row} ${classes.historyValue}`}>
      <Typography
        className={`${classes.baseFont} ${classes.historyValueMonth}`}
      >
        {moment(date)
          .utcOffset("+0800")
          .format("DD MMM YYYY")}
      </Typography>
      <Typography
        className={`${classes.baseFont} ${classes.historyValueMoney}`}
        style={{
          color:
            transactionType === "Buy" ||
            transactionType === "RSPBuy" ||
            transactionType === "TransferIn" ||
            transactionType === "SwitchIn"
              ? "#18a29a"
              : transactionType === "Dividend"
              ? "#1278CC"
              : "#333"
        }}
      >
        {transactionType === "Buy" ||
        transactionType === "Dividend" ||
        transactionType === "ManBuy" ||
        transactionType === "Bonus" ||
        transactionType === "RSPBuy" ||
        transactionType === "TransferIn" ||
        transactionType === "SwitchIn"
          ? "+"
          : "-"}
        RM{" "}
        <NumberFormat
          value={
            transactionType === "Buy" ||
            transactionType === "RSPBuy" ||
            transactionType === "TransferIn" ||
            transactionType === "SwitchIn"
              ? amount + fee
              : transactionType === "Dividend"
              ? amount
              : amount - fee
          }
          thousandSeparator
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </Typography>
    </div>
  );
};

export default compose(withStyles(styles))(TransactionValue);
