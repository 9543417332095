/**
 *
 * App.js
 *
 */

import React, { useEffect } from "react";
import Routes from "../../components/Routes";
import { Switch, Router } from "react-router-dom";
import { getUserConfirmation } from "./getUserConfirmation";
import history from "../../history";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { withErrorBoundary } from "../../utils/functions/withErrorBoundary";
import { AlertInfo } from "../../components";
import { sendLogError } from "../../utils/api";
import {
  clearUnauthorizedNotif,
  hideError,
  showError,
} from "../../globalRedux/actions";
import { compose } from "underscore";
import { connect } from "react-redux";
const errorMessageDefault =
  "We are facing some technical issue, we're sorry for the inconvenience.";
const App = props => {
  const {
    errorMessage = "We are facing some technical issues, and we are working very hard to resolve the issue.",
    isModalErrorOpen,
    hideError,
    showError,
  } = props;

  // useEffect(() => {
  //   ReactGA.initialize("UA-214526305-1");
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  const GTM_ID =
    process.env.REACT_APP_ENV.trim() === "development" ||
    process.env.REACT_APP_ENV.trim() === "uat"
      ? process.env.REACT_APP_GTM_UAT.trim()
      : process.env.REACT_APP_GTM_PROD.trim();

  const tagManagerArgs = {
    gtmId: GTM_ID,
  };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    const triggerError = window.eventManager.on("TRIGGER_ERROR", res => {
      if (process.env.REACT_APP_ENV.trim() === "production") {
        showError(errorMessageDefault);
      } else {
        showError(res);
      }
    });
    return () => triggerError.clearEvents();
  }, []);

  return (
    <>
      <AlertInfo
        handleAction={() => {
          //sendLogError({ app: true });
          props.clearUnauthorizedNotif();
          hideError();
        }}
        description={errorMessage}
        isOpen={isModalErrorOpen || props.unauthorizedNotif}
        isTimeout
      />

      <Router getUserConfirmation={getUserConfirmation} history={history}>
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </>
  );
};

const withConnect = connect(
  state => ({
    ...state.globalReducer.globalData,
  }),
  {
    clearUnauthorizedNotif,
    hideError,
    showError,
  }
);

export default compose(
  withConnect,
  withErrorBoundary
)(App);
