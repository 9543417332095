const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  historyDate: {
    fontSize: "14px",
    lineHeight: 1.57,
    color: "#616367",
    margin: "8px 0"
  },
  transactionType: {
    fontWeight: "700",
    color: "#000",
    textTransform: "capitalize"
  },
  transactionName: {
    fontSize: "14px",
    lineHeight: 1.57,
    color: "#616367",
    textTransform: "capitalize",
    marginTop: "4px"
  },
  transactionStatus: {
    fontSize: "14px",
    lineHeight: 1.57,
    color: "#616367",
    textTransform: "capitalize",
    marginTop: "4px"
  },
  label: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.57,
    marginLeft: "16px"
  },
  content: {
    fontSize: "14px",
    lineHeight: 1.57,
    color: "#616367",
    marginLeft: "16px"
  },
  date: {
    color: "#9d9ea4"
  },
  refundStatus: {
    color: "#616367",
    fontSize: "14px"
  }
};

export default styles;
