import moment from 'moment';

export const formatCurrency = (value = '', options = {}) => {
  const parts = value.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
};

function compareValues(key, order = "asc") {
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  };
}

export const sortBy = (items, key, order) => {
  return items.sort(compareValues(key, order));
};


export const countPasswordExpiredInDays = (createdDate) => {
  const expiredTime = moment(createdDate).add(3, "months")
  const now = moment()

  return expiredTime.diff(now, "days")
}