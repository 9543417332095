const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: 1.5
  },
  container: {
    margin: "16px",
    padding: "16px",
    backgroundColor: "#fff",
    boxShadow: "0 12px 24px -10px #d8d8d8",
    borderRadius: "4px"
  },
  img: {
    width: "56px",
    height: "56px",
    borderRadius: "4px",
    objectFit: "cover"
  },
  detail: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px"
  },
  bar: {
    marginTop: "16px",
    borderRadius: "4px",
    height: "8px"
  },
  period: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px"
  },
  periodMonth: {
    fontSize: "14px",
    lineHeight: 1.57
  },
  periodPercentage: {
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: 1.57
  },
  bold: {
    fontWeight: "700"
  },
  heavy: {
    fontWeight: "900"
  },
  RM: {
    marginTop: "4px"
  }
};

export default styles;
