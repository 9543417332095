export const filterSwitchReject = data => {
  if (Array.isArray(data) && data.length > 0) {
    let resultSwitchOut = [];
    let resultSwitchIn = [];
    let outSuccessInFail = [];

    resultSwitchOut = data.filter(
      item => !(item.type === "SwitchOut" && item.status === "9")
    );

    resultSwitchIn = resultSwitchOut.filter(
      item => !(item.type === "SwitchIn" && item.status === "9")
    );

    outSuccessInFail = resultSwitchIn.filter(item => {
      if (item.type === "SwitchOut") {
        const hasSwitchInDetailWithStatus9 =
          item.details &&
          item.details.some(detail => {
            return detail.type === "SwitchIn" && detail.status === "9";
          });

        return !hasSwitchInDetailWithStatus9;
      }

      return true;
    });

    return outSuccessInFail;
  }
  return [];
};
