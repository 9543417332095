import { all, takeLatest, call, put, select } from "redux-saga/effects";
import {
  GET_FUND_LIST,
  GET_FUND_LIST_SUCCESS,
  GET_FUND_LIST_FAILED,
  GET_SCORE_LIST,
  GET_SCORE_LIST_SUCCESS,
  GET_SCORE_LIST_FAILED,
  CREATE_FUND_V2,
  CREATE_FUND_SUCCESS_V2,
  CREATE_FUND_FAILED_V2,
  GET_FUND_DETAIL,
} from "./constant";
import { setCustFundDetail } from "../../fundDetails/redux/action";
import { setRSPPayload } from "../../deposit/redux/action";
import { getFundDetailSuccess, getFundDetailFailed } from "./action";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";

function* getFundListSaga(action) {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/funds`);
    const { data } = res;
    if (Array.isArray(data) && data.length > 0) {
      data.forEach(item => {
        item.threeAnnualReturn = item.threeAnnualReturn || "0%";
      });
    }

    yield put({ type: GET_FUND_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_FUND_LIST_FAILED, payload: error.message });
  } finally {
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  }
}

function* getScoreListSaga(action) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/risk-profile/score-list`
    );
    const { data } = res;

    yield put({ type: GET_SCORE_LIST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_SCORE_LIST_FAILED, payload: error.message });
  } finally {
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  }
}

function* createFundV2Saga({ payload, callback }) {
  try {
    let fundResult;
    let { cifId, fundsPayload } = payload;
    let temp = yield select(
      state => state.custFundDetailReducer.custFundDetail
    );

    const res = yield call(api.post, `${API_URL.mp}/smp/customer-portfolio`, {
      cifId,
      funds: fundsPayload,
    });

    if ("data" in res) {
      fundResult = res.data;
    }
    if ("errorCode" in fundResult && "message" in fundResult) {
      throw new Error(fundResult);
    }

    temp.productId = fundResult.productId;
    temp.cifPlanId = fundResult.cifPlanId;

    yield put(setCustFundDetail(temp));
    yield put(
      setRSPPayload({
        cifPlanId: fundResult.cifPlanId,
        fundCd: fundsPayload[0].fundCode,
        target: "INVEST_RSP",
      })
    );
    yield put({ type: CREATE_FUND_SUCCESS_V2, payload: fundResult });
    if (callback && typeof callback === "function") {
      callback(fundResult);
    }
  } catch (error) {
    yield put({ type: CREATE_FUND_FAILED_V2, payload: error.message });
  }
}

function* getFundDetailSaga({ payload, callback }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/funds?fundCode=${payload}`
    );

    if (
      res.status === 204 ||
      (typeof res.data === "object" &&
        Array.isArray(res.data) &&
        res.data.length === 0)
    ) {
      throw new Error("Failed to get fund detail");
    }

    const { data } = res;
    yield put(getFundDetailSuccess(data[0]));
    typeof callback === "function" && callback();
  } catch (error) {
    yield put(getFundDetailFailed());
    typeof callback === "function" && callback();
  }
}

export function* fundSelectionWorkerSaga() {
  yield all([
    takeLatest(GET_FUND_LIST, getFundListSaga),
    takeLatest(GET_SCORE_LIST, getScoreListSaga),
    takeLatest(CREATE_FUND_V2, createFundV2Saga),
    takeLatest(GET_FUND_DETAIL, getFundDetailSaga),
  ]);
}
