import React from "react";
import { Typography, Icon } from "@material-ui/core";

import styles from "./styles";

const CountryItem = ({ country, isSelected, onClick, containerStyles }) => {
  return (
    <div style={{ ...styles.container, ...containerStyles }} onClick={onClick}>
      <Typography color="textPrimary" style={styles.country}>
        {country}
      </Typography>
      {isSelected && (
        <Icon
          className="material-icons-outlined"
          style={{ cursor: "pointer", color: "#0091DA" }}
        >
          done
        </Icon>
      )}
    </div>
  );
};

export default CountryItem;
