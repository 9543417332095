import React from "react";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";

const CustomButton = ({
  color,
  backgroundColor,
  variant,
  text,
  onClick,
  disabled,
  classes,
  getStarted,
  style
}) => {
  const shadow = disabled
    ? "0 12px 24px -10px #9d9ea4"
    : variant === "outlined"
    ? "0 12px 20px -10px rgba(8, 76, 149, 0.4)"
    : "0 12px 24px -10px rgba(16, 109, 186, 0.6)";
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant={variant}
      className={classes.btn}
      style={{
        color: color,
        backgroundColor: disabled ? "#9d9ea4" : backgroundColor,
        borderColor: variant === "outlined" && color,
        boxShadow: getStarted ? "none" : shadow,
        fontWeight: text === "Chat with us on WhatsApp" && "400",
        ...style
      }}
    >
      {text}
    </Button>
  );
};

export default compose(withStyles(styles))(CustomButton);
