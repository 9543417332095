import { all, takeLatest, call, put } from "redux-saga/effects";
import {
  SET_TRANSFER,
  SET_TRANSFER_SUCCESS,
  SET_TRANSFER_FAILED
} from "./constant";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";

function* transferSaga(action) {
  try {
    const res = yield call(api.post, `${API_URL.mp}/transfer`, action.payload);

    if (
      res instanceof Error ||
      (typeof res === "object" &&
        (!("status" in res) || (res.status === 200 && res.data === "")))
    ) {
      throw new Error(res);
    }

    const { data } = res;

    yield put({ type: SET_TRANSFER_SUCCESS, payload: data });
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  } catch (error) {
    yield put({ type: SET_TRANSFER_FAILED, payload: Boolean(error) });
  }
}

export function* switchingWorkerSaga() {
  yield all([takeLatest(SET_TRANSFER, transferSaga)]);
}
