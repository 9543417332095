const styles = {
  container: { margin: "16px 16px 0" },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  title: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    marginBottom: "16px"
  },
  content: {
    justifyContent: "space-between",
    padding: "16px 0px 14px"
  },
  contentItem: {
    fontWeight: "700",
    letterSpacing: "0.32px"
  },
  itemList: {
    margin: "32px 0",
    justifyContent: "space-between"
  },
  bold: {
    fontWeight: "700"
  },
  noList: {
    marginTop: "-8px",
    paddingBottom: "8px"
  }
};

export default styles;
