import React, { useEffect, useState, useRef } from "react";
import history from "../../history";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { resetData } from "../redux/action";
import { setStep } from "../../globalRedux/actions";
import cogoToast from "cogo-toast";

import "./styles.scss";
function GetStartedStep({ resetData, profile, setStep }) {
  useEffect(() => {
    history.push("/");
    return;
  }, []);

  const pervData = useRef(null);
  let setVersion = window.location.pathname;

  const VERSION_ONE = "/version/V1";
  const VERSION_TWO = "/version/V2";
  const [versionGo, setVersionGo] = useState("V1");
  const [clearData, setClearData] = useState(false);
  const getVersion = () => {
    if (localStorage.getItem("version") !== null) {
      return;
    }
    if (setVersion === VERSION_TWO) {
      localStorage.setItem("version", "V2");
    } else if (setVersion === VERSION_ONE) {
      localStorage.setItem("version", "V1");
    } else {
      localStorage.setItem("versionNote", "undefined");
      localStorage.setItem("version", "V1");
    }
  };

  const changePathname = (val, from) => {
    const text = val.replace(/\//g, " ");
    const temp = text.split(" ");
    const getVersion = temp[temp.length - 1];

    let textDisplayed =
      getVersion === "V2"
        ? "you are now on version two"
        : "you are now on version one";

    if (from === "TEXT") {
      return textDisplayed;
    }
    return getVersion;
  };

  const checkData = () => {
    if (profile === null) {
      cogoToast.error("Data is Empty");

      return;
    }
    resetData();
    localStorage.removeItem("mpUserInfo");
    setStep(null);
    localStorage.clear();
  };

  useEffect(() => {
    // getVersion();
    setVersionGo(() => changePathname(window.location.pathname, "STATE"));
  }, []);

  useEffect(() => {
    pervData.current = profile;
  }, []);

  useEffect(() => {
    if (profile) {
      return;
    }
    if (profile === null) {
      setClearData(true);
    }

    if (pervData.current !== profile) {
      cogoToast.success("Your data has been deleted");
    }
  }, [profile]);

  const redirectSet = val => {
    localStorage.setItem("version", val);
    cogoToast.success(`set to version ${val === "V1" ? "one" : "two"}`);
    let inDevlopment =
      process.env.REACT_APP_ENV.trim() === "development" ||
      process.env.REACT_APP_ENV.trim() === "uat";
    if (inDevlopment) {
      history.push("/");
    }
    return;
  };

  return (
    <div className="started_step">
      {/* <p className="started_step__info">
        {changePathname(window.location.pathname, "TEXT")}{" "}
      </p> */}
      {/* <div>
        <button
          onClick={() => {
            redirectSet("V2");
          }}
        >
          VERSION TWO
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            redirectSet("V1");
          }}
        >
          VERSION ONE
        </button>
      </div>
      <div>
        <button
          style={{ backgroundColor: clearData ? "#8a8c8e" : "#1278cc" }}
          onClick={checkData}
        >
          RESET DATA
        </button>
      </div> */}
    </div>
  );
}
const withConnect = connect(
  state => ({
    profile: state.goal.profile
  }),

  {
    resetData,
    setStep
  }
);

export default compose(
  withRouter,
  withConnect
)(GetStartedStep);
