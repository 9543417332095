import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import {
  Alert,
  AlertAccount,
  AlertDDA,
  CampaignCard,
  CustomButton,
  DefaultLoader,
  Dropdown,
  DropdownItem,
  EmptyStateInvest,
  InvestedFundCard,
  InvestorCard,
  MenuBar,
  NewsList,
  OverviewCard,
  GiveawayAlert
} from "../../../components";
import { icons, images } from "../../../constants";
import { dataLayerGTM } from "../../../utils/functions/dataLayerGTM";
import { setStatus } from "../../../utils/functions/goalCombineTrx";
import { stringDateToNumber } from "../../../utils/functions/stringDateToNumber";
import {
  checkAcctStsCashIn,
  checkAcctStsCashOut,
} from "../../../utils/functions/validationsDeposit";
import {
  createFundV2,
  getScoreList,
  sendFundsCode,
  setIvestMinAmount,
  setSelectedFundCust,
} from "../../fundSelection/redux/action";
import { getTotalUnitFunds } from "../../getStarted/redux/actions";
import { getResultAssessment } from "../../riskAssessment/redux/action";
import "../../styles/index.scss"; // global styles
import { getCustomerPortfolio, getSubAccountInvest } from "../redux/actions";
import styles from "../styles";

import moment from "moment";
import { injectHtmlToSpecificSentence } from "../../../utils/functions/formatCampaignContent";
import { setRSPPayload } from "../../deposit/redux/action";
import { setCustFundDetail } from "../../fundDetails/redux/action";
import { ddaStatus } from "../../fundDetails/utils";
import { getGoalSummary } from "../../redux/action";
import { DASHBOARD } from "../../redux/constant";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../../constants/subaccount-status";
import { useGiveawayAlert } from "../../../utils/hooks";

const INVEST_RSP = "INVEST_RSP";
const INVEST_CASHIN = "INVEST_CASHIN";

const Invest = ({
  resultAssessmentLoading,
  getResultAssessment,
  sendFundsCode,
  setSelectedFundCust,
  riskProfileResult,
  history,
  customerPortfolio,
  loadingCustomerPortfolio,
  getCustomerPortfolio,
  // getTotalUnitFunds,
  fundsUnitloading,
  setIvestMinAmount,
  getSubAccountInvest,
  subAccStsInvestLoading,
  subAccStsInvest,
  getGoalSummary,
  investSummary,
  setRSPPayload,
  setCustFundDetail,
  getScoreList,
  scoreList,
  profile,
  loading,
  setbackPayloadRsp,
  handleViewAllNews,
  handleCheckEcdd,
  campaignsInvest = [],
  newsFeed,
  createFundV2,
}) => {
  const cifId = profile.masterId;
  const [target, setTarget] = useState("");
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [showInvestorLevel, setShowInvestorLevel] = useState(false);
  const [risk, setRisk] = useState({ level: "", index: 0, desc: "" });
  const [fundCustSlice, setFundCustSlice] = useState([]);
  const [ddaPending, setDdaPending] = useState(false);
  const [rspList, setRspList] = useState({ full: false, list: [] });
  const [isLoading, setIsLoading] = useState({
    render: true,
    subAccountSts: false,
  });
  const [accountSts, setAccountSts] = useState({
    status: "Active",
    blockedCashIn: false,
    blockedCashout: false,
    alert: false,
  });
  const [isRiskProfileExpired, setRiskProfileExpired] = useState(false);
  const { notifyGiveaway, setNotifyGiveaway } = useGiveawayAlert();

  const now = new Date().getTime();
  React.useEffect(() => {
    getCustomerPortfolio();
    // getTotalUnitFunds("MPInvest");
    getGoalSummary({ cifId: profile.masterId, type: "MPInvest" });
    getResultAssessment(cifId);
    getScoreList();
  }, []);

  React.useEffect(() => {
    if (scoreList.length > 0 && Object.entries(riskProfileResult).length > 0) {
      const riskNames = scoreList.reduce((prev, curr) => {
        prev[curr.nameEn.toLowerCase()] = curr.descEn;
        return prev;
      }, {});

      const level = riskProfileResult.resultNameEn.toLowerCase();

      setRisk(prev => ({
        ...prev,
        index: Object.keys(riskNames).indexOf(level),
        desc: riskNames[level],
        level: level,
      }));
    }
  }, [scoreList, riskProfileResult]);

  /**
   ** check if subaccount status is posible to do transaction
   ** start
   */

  const checkAccStsinvest = () => {
    let newObj = {
      status: "Active",
      alert: false,
      blockedCashIn: false,
      blockedCashout: false,
    };
    if ("sts" in subAccStsInvest) {
      let { sts } = subAccStsInvest;
      newObj.status = sts;
      let stopCashIn = checkAcctStsCashIn(sts);
      let stopCashOut = checkAcctStsCashOut(sts);
      if (!stopCashIn) {
        newObj.blockedCashIn = true;
      }
      if (!stopCashOut) {
        newObj.blockedCashout = true;
      }
      setAccountSts(newObj);
    }
  };

  useEffect(() => {
    if (Array.isArray(customerPortfolio) && customerPortfolio.length > 0) {
      let tempAccountNo = customerPortfolio[0];
      if (
        "accountNo" in tempAccountNo &&
        (tempAccountNo.accountNo !== "" || tempAccountNo.accountNo !== null)
      ) {
        getSubAccountInvest(tempAccountNo.accountNo);
      }

      const showableRSPStatus = [null, "1", "6", "16"];
      const showableFunds = (customerPortfolio || []).filter(({ rspStatus }) =>
        showableRSPStatus.includes(rspStatus)
      );

      let tempRspSet = { full: false, list: showableFunds };

      if (customerPortfolio.length > 0 && showableFunds.length === 0) {
        tempRspSet = {
          full: true,
          list: [
            {
              id: INVEST_RSP,
              fund: { name: "Explore other Unit Trust Funds" },
            },
          ],
        };
      }
      setRspList(tempRspSet);
    }
  }, [customerPortfolio]);

  useEffect(() => {
    checkAccStsinvest();
  }, [subAccStsInvest]);
  /**
   ** check if subaccount status is posible to do transaction
   ** end
   */

  useEffect(() => {
    if (Array.isArray(customerPortfolio)) {
      setFundCustSlice(setStatus(customerPortfolio).slice(0, 2));
    }
  }, [customerPortfolio]);

  useEffect(() => {
    setIsLoading({ ...isLoading, render: false });

    setbackPayloadRsp({});
  }, []);

  const handleDeposit = item => {
    const { fund = {} } = item;
    const { code = "", name = "", minSubsAmt = "10", salesFeePerc = "" } = fund;
    setIvestMinAmount(minSubsAmt);
    sendFundsCode([{ code, name, salesCharge: salesFeePerc }]);
    setCustFundDetail(item);
    setSelectedFundCust(item.fund);
    createFundV2({
      cifId: profile.masterId,
      fundsPayload: [{ fundCode: code }],
    });
    history.push("/fund-selection-step-two");
  };

  const handleWithdraw = item => {
    sendFundsCode([
      {
        code: item.fund.code,
        name: item.fund.name,
        cifPlanId: item.cifPlanId,
        product: item.product,
        nav: item.nav.nav,
        unitHeld: item.unitHeld,
      },
    ]);
    history.push("/make-withdrawal");
  };

  const handleGoRsp = item => {
    if (item.id === INVEST_RSP) {
      let directFundTabs = goToWhichFundTabs();
      history.push(directFundTabs);
      return;
    }

    const { cifPlanId, fundCd, rspStatus = "" } = item;
    let ddaResult = ddaStatus(rspStatus);

    if (ddaResult) {
      setDdaPending(ddaResult);
      return;
    }
    setRSPPayload({ cifPlanId, fundCd, target: INVEST_RSP });
    history.push(`/make-deposit/${DASHBOARD}/success/auto-deposit-v2/setupRSP`);
  };

  const handleOpenRsp = () => {
    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }

    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }

    setTarget(INVEST_RSP);

    setOpenDeposit(true);
  };

  const handleOpenDeposit = () => {
    setTarget(INVEST_CASHIN);
    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }

    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }

    setOpenDeposit(true);
  };

  const handleOpenWithdraw = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Dashboard Cash out button");
    if (accountSts.blockedCashout) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      "Click Dashboard - Cash out button"
    );
    setOpenWithdraw(true);
  };

  const goToWhichFundTabs = () => {
    let pathToGo = "/fund-selection/conservative";
    if ("resultNameEn" in riskProfileResult) {
      let level = riskProfileResult.resultNameEn;
      pathToGo = `/fund-selection/${level.toLowerCase()}`;
    }
    return pathToGo;
  };

  const handleInvest = () => {
    const profileIsObject = profile && typeof profile === "object";

    const headAccountIsObjectAndItsStatusExist =
      "headAccount" in profile &&
      typeof profile.headAccount === "object" &&
      "sts" in profile.headAccount;

    if (profileIsObject && headAccountIsObjectAndItsStatusExist) {
      const headAccountStatusIsSuspended =
        profile.headAccount.sts === "Suspended";

      const headAccountStatusIsDeactived = NOT_ALLOWED_ACCOUNT_STATUS.includes(
        profile.headAccount.sts
      );

      if (headAccountStatusIsSuspended)
        return setAccountSts(prev => ({
          ...prev,
          alert: true,
          status: "Suspended",
        }));

      if (headAccountStatusIsDeactived)
        return setAccountSts(prev => ({
          ...prev,
          alert: true,
          status: "Deactived",
        }));
    }

    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }
    if (Object.entries(riskProfileResult).length === 0) {
      history.push("/invest-introduction");
      return;
    }

    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }
    let directFundTabs = goToWhichFundTabs();
    history.push(directFundTabs);
  };

  const handleViewAllInvestedFunds = () => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Link",
      "Dashboard Fund view all button"
    );
    history.push("/invested-funds");
  };

  const handleTACPdf = url => {
    // window.my.showLoading();
    // window.my.postMessage({
    //   api: "downloadFile",
    //   pdf: url,
    //   event: "openPdf",
    // });
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(url),
    });
  };

  const heightDropdown = val => {
    if (target !== INVEST_RSP) {
      return customerPortfolio.length > 10 ? val - val / 10 : val / 2;
    }

    if (target === INVEST_RSP && rspList.full) {
      return val / 10;
    }
    if (target === INVEST_RSP && !rspList.full) {
      return rspList.list.length > 10 ? val - val / 2 : val / 3;
    }
    return customerPortfolio.length > 10 ? val - val / 10 : val / 2;
  };

  if (
    loading ||
    loadingCustomerPortfolio ||
    fundsUnitloading ||
    resultAssessmentLoading ||
    subAccStsInvestLoading
  )
    return <DefaultLoader />;

  return (
    <>
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={isRiskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={() => {
          history.push("/invest-introduction");
        }}
        handleCancel={() => setRiskProfileExpired(false)}
      />
      <AlertAccount
        title={`Account ${
          accountSts.status === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountSts.status === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountSts({ ...accountSts, alert: false });

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={accountSts.alert}
        handleClose={() => setAccountSts({ ...accountSts, alert: false })}
      />
      <GiveawayAlert
        isOpen={notifyGiveaway}
        handleClose={() => {
          localStorage.setItem("lastNotifyGiveaway", new Date().toDateString());
          setNotifyGiveaway(false);
        }}
        sourceImg="laburiaBg"
      />
      {fundCustSlice.length > 0 ? (
        <>
          {" "}
          <AlertDDA
            title="This Fund has already enabled Auto Cash In."
            firstText="Your Auto Cash In request is being processed, please come back and check the status later."
            isOpen={ddaPending}
            handleClose={() => setDdaPending(false)}
          />
          <InvestorCard
            riskIndex={risk.index}
            riskLevel={risk.level}
            desc={risk.desc}
            handleSubmit={handleCheckEcdd({
              cb: () => {
                if (accountSts.blockedCashIn) {
                  setAccountSts({
                    ...accountSts,
                    alert: true,
                  });
                  return;
                }

                if (
                  new Date() >=
                  moment(riskProfileResult.expiredDate)
                    .startOf("date")
                    .toDate()
                ) {
                  setRiskProfileExpired(true);
                  return;
                }
                setShowInvestorLevel(false);
                let directFundTabs = goToWhichFundTabs();
                history.push(directFundTabs);
              },
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
            })}
            onClose={() => {
              setShowInvestorLevel(false);
            }}
            isOpen={showInvestorLevel}
            isPopup
          />
          {/* Dropdown */}
          <Dropdown
            bottomHeight={({ maxHeight }) => heightDropdown(maxHeight)}
            isOpen={openDeposit}
            handleDismiss={() => {
              setTarget("");
              setOpenDeposit(false);
            }}
          >
            <DropdownItem
              containerStyle={{ marginBottom: "80px" }}
              title={
                target === INVEST_RSP
                  ? "Please select a goal or fund for setting up Auto Cash In?"
                  : "Please select a fund to Cash In"
              }
              fullRsp={target === INVEST_RSP && rspList.full}
              funds={
                target === INVEST_RSP
                  ? rspList.list
                  : customerPortfolio.filter(
                      portfolio => portfolio.fund.isActive === "1"
                    )
              }
              onClick={val => {
                target === INVEST_CASHIN
                  ? handleDeposit(val)
                  : handleGoRsp(val);
              }}
            />
          </Dropdown>
          <Dropdown
            bottomHeight={({ maxHeight }) =>
              customerPortfolio.length > 10
                ? maxHeight - maxHeight / 10
                : maxHeight / 2
            }
            isOpen={openWithdraw}
            handleDismiss={() => setOpenWithdraw(false)}
          >
            <DropdownItem
              title="Please select a fund to Cash Out"
              containerStyle={{ marginBottom: "80px" }}
              funds={customerPortfolio}
              onClick={handleWithdraw}
            />
          </Dropdown>
          <OverviewCard
            totalAsset={investSummary.totalAsset}
            totalReturn={investSummary.totalReturn}
            lastUpdate={moment(investSummary.lastUpdated)
              .utcOffset("+0800")
              .format("DD MMM YYYY, h:mm A")}
            type="invest"
            riskLevel={risk.level}
            riskIndex={risk.index}
            handleShowInvestorPopup={() => setShowInvestorLevel(true)}
          />
          <MenuBar
            handleWithdraw={handleOpenWithdraw}
            handleSwitching={handleCheckEcdd({
              cb: () => history.push("/switching/create"),
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Dashboard Switching button",
              },
            })}
            handleDeposit={handleCheckEcdd({
              cb: handleOpenDeposit,
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Dashboard Cash In button",
              },
            })}
            handleAutoDeposit={handleCheckEcdd({
              cb: handleOpenRsp,
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Dashboard Auto Cash In button",
              },
            })}
          />
          {campaignsInvest.map((campaign, idx) =>
            now < stringDateToNumber(campaign.endDate) ? (
              <div style={{ margin: "0 1rem 1rem 1rem" }} key={idx}>
                <CampaignCard
                  startDate={campaign.startDate}
                  endDate={campaign.endDate}
                  onCashIn={handleCheckEcdd({
                    cb: handleOpenDeposit,
                    ecddExpiredCb: () =>
                      history.push("/onBoarding/ecdd/DASHBOARD"),
                    gtm: {
                      event: "click_tag",
                      action: "Click",
                      category: "Button",
                      label: "Dashboard Cash In button",
                    },
                  })}
                  openPDF={() => handleTACPdf(campaign.extUrl)}
                  type="lebaran"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: injectHtmlToSpecificSentence({
                        text: campaign.desc.replace(/\s(Get)/, "\n$1"),
                        regex: /(8.00%\*)/g,
                        classNames: {
                          "8.00%*": "campaign-content",
                        },
                      }),
                    }}
                  />
                </CampaignCard>
              </div>
            ) : null
          )}
          <div
            style={{
              backgroundColor: "#fff",
              paddingTop: "1rem",
            }}
          >
            <div
              style={{
                ...styles.row,
                justifyContent: "space-between",
                margin: "0 16px 8px",
              }}
            >
              <Typography
                style={{
                  ...styles.baseFont,
                  fontWeight: "700",
                  color: "#262D58",
                }}
              >
                Invested Funds
              </Typography>
              <div
                style={{ ...styles.row }}
                onClick={handleViewAllInvestedFunds}
              >
                <Typography color="primary" style={{ ...styles.baseFont }}>
                  View all
                </Typography>
                <img src={icons.arrowRight} alt="arrowRight" />
              </div>
            </div>

            <div className="dashboard-funds">
              {fundCustSlice.map((item, idx) => (
                <InvestedFundCard
                  key={idx}
                  fundName={item.fund.name}
                  totalAsset={item.currentValue}
                  cashInStatus={item.cashInStatus}
                  unrealisedGain={item.unrealisedGain}
                  totalCost={item.totalCost}
                  percentage={!item.unrealisedGain ? false : true}
                  handleClick={() =>
                    history.push(`/invested-funds/${item.fundCd}`)
                  }
                />
              ))}
            </div>

            <div style={{ margin: "16px 16px 24px" }}>
              <CustomButton
                text="Invest New Funds"
                variant="outlined"
                color="#1278CC"
                backgroundColor="#fff"
                onClick={handleCheckEcdd({
                  cb: handleInvest,
                  ecddExpiredCb: () =>
                    history.push("/onBoarding/ecdd/DASHBOARD"),
                  gtm: {
                    event: "click_tag",
                    action: "Click",
                    category: "Button",
                    label: "Dashboard + invest funds button",
                  },
                })}
              />
            </div>
          </div>
          {newsFeed && newsFeed.length > 0 && (
            <NewsList
              handleViewAllNews={handleViewAllNews}
              newsFeed={newsFeed}
            />
          )}
        </>
      ) : (
        <EmptyStateInvest
          handleNext={handleCheckEcdd({
            cb: handleInvest,
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Dashboard empty - explore  funds",
            },
          })}
          faqLink="https://www.principal.com.my/en/investment-platform-faq/mv"
          title="Haven't invested yet?"
          desc={
            <>
              Start investing from as low as{" "}
              <span
                style={{
                  color: "#fbd822",
                  fontWeight: 900,
                  fontSize: "22px",
                }}
              >
                RM10{" "}
              </span>
              and potentially grow your investments over time. Explore a variety
              of funds, offering exposure to both global and domestic markets.
              <div className="empty-fund-ul-wrapeer">
                <ul>
                  <li>No maximum investment cap</li>
                  <li>Safe and secure investment feature</li>
                  <li>No lock-in period</li>
                </ul>
              </div>
            </>
          }
          actionText="View all funds"
          illustration={images.investmentBg}
        />
      )}
    </>
  );
};

const withConnect = connect(
  state => ({
    ...state.riskAssessmentReducer,
    ...state.getStartedReducer,
    loadingCustomerPortfolio: state.dashboardReducer.loadingCustomerPortfolio,
    customerPortfolio: state.dashboardReducer.customerPortfolio,
    subAccStsInvestLoading: state.dashboardReducer.subAccStsInvestLoading,
    subAccStsInvest: state.dashboardReducer.subAccStsInvest,
    investSummary: state.goal.goalSummary,
    scoreList: state.fundSelectionReducer.scoreList,
  }),
  {
    getCustomerPortfolio,
    sendFundsCode,
    getTotalUnitFunds,
    getResultAssessment,
    setIvestMinAmount,
    getSubAccountInvest,
    getGoalSummary,
    setRSPPayload,
    setCustFundDetail,
    getScoreList,
    setSelectedFundCust,
    createFundV2,
  }
);

export default compose(
  withRouter,
  withConnect
)(Invest);
