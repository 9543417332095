import React, { useState, useEffect } from "react";
import { Typography, TextField, Button } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { sendFeedback } from "../../redux/action";

import {
  StepLabel,
  Rate,
  CustomButton,
  DefaultLoader,
} from "../../../components";
import styles from "../styles";
import { dataLayerGTM } from "../../../utils/functions/dataLayerGTM";

const Feedback = props => {
  const { loading, profile, sendFeedback, history } = props;

  const [cifId, setCifId] = useState("");
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");

  const color = {
    filled: "#1278CC",
    unfilled: "#E7E7E7",
  };

  useEffect(() => {
    if (profile) {
      // cifId is customer.id, only for this request
      setCifId(profile.id);
    }
  }, [profile]);

  const handleSubmit = () => {
    sendFeedback({
      cifId: cifId,
      feedback: message,
      rating: rating.toString(),
    });
  };

  return (
    <div
      style={{
        ...styles.column,
        flex: 1,
        minHeight: "100vh",
        backgroundColor: "#fff",
      }}
    >
      {loading && <DefaultLoader />}

      <StepLabel
        title="Feedback"
        onClick={() => history.back()}
        containerStyles={{ padding: "16px 16px 22px" }}
      />
      <div>
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            ...styles.inputLabel,
            margin: "0 16px 28px 16px",
          }}
        >
          How would you rate your experience using our product?
        </Typography>
        {/* Star Rating */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Rate
            rating={rating}
            color={color}
            onRating={rate => {
              dataLayerGTM("click_tag", "Click", "Rate", `${rate}`);
              setRating(rate);
            }}
          />
        </div>
      </div>
      <div style={{ margin: "46px 16px 0" }}>
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            ...styles.inputLabel,
            marginBottom: "16px",
          }}
        >
          Please share your feedback to help us improve our service.
        </Typography>
        <TextField
          placeholder="What do you think we can improve"
          variant="outlined"
          multiline
          rows="4"
          fullWidth
          value={message}
          onChange={e => setMessage(e.target.value)}
          inputProps={{ style: { ...styles.baseFont, padding: 0 } }}
          InputProps={{ style: { padding: "12px 16px" } }}
        />
      </div>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Submit"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={cifId && rating && message ? false : true}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    profile: state.goal.profile,
  }),
  { sendFeedback }
);

export default compose(
  withRouter,
  withConnect
)(Feedback);
