import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import React from "react";
import { CustomButton } from "..";
import "./style.scss";

const EmptyState = ({
  handleNext,
  faqLink,
  title,
  desc,
  tagline,
  actionText,
  illustration,
  className,
}) => {
  const faqNavigate = () => {
    dataLayerGTM("click_tag", "Click", "Link", "Dashboard empty - FAQ");
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(faqLink),
    });
  };

  return (
    <div className={`l-save  ${className}`}>
      <h2 className="l-save__title">{title}</h2>
      <p className="l-save__desc">{desc}</p>
      <p>{tagline}</p>
      <div style={{ marginInline: "4rem" }}>
        <CustomButton
          text={actionText}
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          style={{ marginTop: "1rem" }}
          onClick={handleNext}
        />
      </div>
      <p onClick={faqNavigate} className="l-save__faq">
        Frequently Asked Questions
      </p>
      <img src={illustration} alt="Investing" className="l-save__bg" />
    </div>
  );
};

export default EmptyState;
