import {
  INVEST_CHECKOUT,
  INVEST_CHECKOUT_SUCCESS,
  INVEST_CHECKOUT_FAILED,
  CLEAR_INVEST_CHECKOUT_LOADING,
  CLEAR_INVEST_CHECKOUT_ERROR
} from "./constant";

export const investCheckout = payload => {
  return {
    type: INVEST_CHECKOUT,
    payload
  };
};

export const investCheckoutSuccess = data => {
  return {
    type: INVEST_CHECKOUT_SUCCESS,
    payload: data
  };
};

export const investCheckoutFailed = message => {
  return {
    type: INVEST_CHECKOUT_FAILED,
    payload: message
  };
};

export const clearInvestCheckoutLoading = () => {
  return {
    type: CLEAR_INVEST_CHECKOUT_LOADING
  };
};
export const clearErrorInvestCheckout = () => {
  return {
    type: CLEAR_INVEST_CHECKOUT_ERROR
  };
};
