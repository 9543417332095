import React, { useState, useEffect } from "react";
import { Typography, FormControl, TextField } from "@material-ui/core";
import moment from "moment";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import { updateProfile, hideError } from "../../redux/action";
import { StepLabel, CustomButton, DefaultLoader } from "../../../components";
import styles from "../styles";
const REQUIRED = "REQUIRED";

const UpdateAddress = props => {
  const { loading, profile, updateProfile, history } = props;

  const [userInfo, setUserInfo] = useState(null);
  const [address1, setAddress1] = useState({ value: "", error: false });
  const [address2, setAddress2] = useState({ value: "", error: false });
  const [address3, setAddress3] = useState({ value: "", error: false });
  const [isTax, setIsTax] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  const checkAddressLine = (value, line) => {
    let addressLine = "";
    if (value.contacts) {
      if (
        value.contacts.find(contact => contact.contactTypeCd === "PERMANENT")[
          line
        ]
      ) {
        addressLine = value.contacts
          .find(contact => contact.contactTypeCd === "PERMANENT")
          [line].slice(0, 40);
      }
    }
    return addressLine;
  };

  useEffect(() => {
    if (userInfo) {
      setAddress1({ ...address1, value: checkAddressLine(userInfo, "line1") });
      setAddress2({ ...address2, value: checkAddressLine(userInfo, "line2") });
      setAddress3({ ...address3, value: checkAddressLine(userInfo, "line3") });
      setIsTax(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? true
          : false
      );
    }
  }, [userInfo]);

  const handleUpdate = () => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: userInfo.investmentObjCd,
      employerName: userInfo.employerName,
      identities:
        isTax === true
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship, //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).citizenship, //R
              },
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship, //R
              },
            ],
      address: {
        line1: address1.value, //R
        line2: address2.value,
        line3: address3.value,
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R
        city: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship, //R
      },
    };

    updateProfile({ payloadData: payload, from: PROFILE });
  };

  const checkIsHaveAnError = () => {
    if (address1.value.length === 0 || address1.error) return true;
    if (address2.error) return true;
    if (address3.error) return true;
    return false;
  };

  useEffect(() => {
    setBtnDisabled(checkIsHaveAnError());
  }, [address1, address2, address3]);

  const addressLineSetupErr = (value, isValid, required) => {
    if (required) return !isValid;
    return !isValid && value.length > 0;
  };

  const handleChange = (field, e, required) => {
    const value = e.target.value;
    const format = /^[a-zA-Z0-9\x20,.\-\/&#!@$%^*~+]{1,40}$/;
    const isValid = format.test(value);
    field(prevState => ({
      ...prevState,
      value: value,
      error: addressLineSetupErr(value, isValid, required),
    }));
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="Update address"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            Address*
          </Typography>
          <TextField
            placeholder="Line 1 (Required)"
            variant="outlined"
            fullWidth
            value={address1.value}
            error={address1.error}
            helperText={address1.error && "Invalid address format"}
            onChange={e => handleChange(setAddress1, e, REQUIRED)}
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" },
              maxLength: 40,
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <TextField
            placeholder="Line 2 (Optional)"
            variant="outlined"
            fullWidth
            value={address2.value}
            error={address2.error && address2.value.length > 0}
            helperText={
              address2.error &&
              address2.value.length > 0 &&
              "Invalid address format"
            }
            onChange={e => handleChange(setAddress2, e)}
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" },
              maxLength: 40,
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <TextField
            placeholder="Line 3 (Optional)"
            variant="outlined"
            fullWidth
            value={address3.value}
            error={address3.error && address3.value.length > 0}
            helperText={
              address3.error &&
              address3.value.length > 0 &&
              "Invalid address format"
            }
            onChange={e => handleChange(setAddress3, e)}
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" },
              maxLength: 40,
            }}
          />
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={btnDisabled}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile,
  }),
  {
    updateProfile,
    hideError,
  }
);

export default compose(
  withRouter,
  withConnect
)(UpdateAddress);
