const stopOccupations = ["23", "91"];
const occupationWhiteList = ["23", "62", "65", "90", "91"];

export const findValue = (list, code, searchBy) => {
  let tempValue = { name: "", code: "" };
  let newItem = [];
  if (Array.isArray(list)) {
    newItem = list.filter(item => item.code === code);
  } else {
    return tempValue;
  }

  if (newItem.length > 0) {
    if (searchBy in newItem[0] && "code" in newItem[0]) {
      tempValue = { name: newItem[0][searchBy], code: newItem[0].code };
    }
  }
  return tempValue;
};

export const findOccupation = (list, code, searchBy) => {
  let tempOccupation = {
    name: "",
    code: "",
    blockedNob: false,
    employerNameEdited: true,
  };
  let newItem = [];
  if (Array.isArray(list)) {
    newItem = list.filter(item => item.code === code);
  } else {
    return tempOccupation;
  }
  if (newItem.length > 0) {
    if (searchBy in newItem[0] && "code" in newItem[0]) {
      tempOccupation = {
        name: newItem[0][searchBy],
        code: newItem[0].code,
        blockedNob: false,
        employerNameEdited: true,
      };
    }
  }
  if (stopOccupations.includes(code)) {
    tempOccupation.blockedNob = true;
  }
  if (occupationWhiteList.includes(code)) {
    tempOccupation.employerNameEdited = false;
  }
  return tempOccupation;
};

export const manipulateCountry = list => {
  let result = [];
  if (Array.isArray(list)) {
    result = list;
  }
  return result;
};

export const knowTheGender = code => {
  switch (code) {
    case "F":
      return "F";
    case "M":
      return "M";
    case "Female":
      return "F";
    case "female":
      return "F";
    case "Male":
      return "M";
    case "male":
      return "M";
    case "":
      return "Unknown";
    default:
      return "Unknown";
  }
};

const replaceCharacter = val => {
  // there are characters that do not match the system requirements so they need to be removed so they don't cause problems in the address line
  const sanitizedValue = val.replace(
    /[^a-zA-Z0-9\x20\,\.\-\/\&\#\!\@\$\%\^\*\~\+]/g,
    ""
  );
  return sanitizedValue;
};

export const findAddress = (contact, stateCode) => {
  let tempAddress = {
    city: "",
    country: "",
    line1: "",
    line2: "",
    line3: "",
    postCode: "",
    state: "",
  };
  let tempContact = contact.filter(item => item.contactTypeCd === "PERMANENT");
  if (Array.isArray(tempContact) && tempContact.length > 0) {
    let addressitem = tempContact[0];
    if ("city" in addressitem) {
      tempAddress.city = addressitem.city.slice(0, 40);
    }
    if ("country" in addressitem) {
      tempAddress.country = addressitem.country;
    }

    if ("postCode" in addressitem) {
      tempAddress.postCode = addressitem.postCode;
    }

    if ("line1" in addressitem && !!addressitem.line1) {
      tempAddress.line1 = replaceCharacter(addressitem.line1).slice(0, 40);
    }
    if ("line2" in addressitem && !!addressitem.line2) {
      tempAddress.line2 = replaceCharacter(addressitem.line2).slice(0, 40);
    }
    if ("line3" in addressitem && !!addressitem.line3) {
      tempAddress.line3 = replaceCharacter(addressitem.line3).slice(0, 40);
    }
  }
  tempAddress.state = stateCode;

  return tempAddress;
};

export const findIdentities = (identities, idNo) =>
  (identities || []).find(identity => idNo === identity.idNo) || {};
// {
//   let citizenship = "MY";
//   if (Array.isArray(identities)) {
//     let tempIdentity = identities.filter(item => item.idTypeCd === "NRIC");

//     if (Array.isArray(tempIdentity) && tempIdentity.length > 0) {
//       if ("citizenship" in tempIdentity[0]) {
//         citizenship = tempIdentity[0].citizenship;
//       }
//     }
//   }
//   return citizenship;
// };

export const handleNotExist = val => {
  let result = "";
  if (val !== null && val !== undefined) {
    result = val;
  }
  return result;
};

export const checkEmptyObject = val => {
  if (typeof val === "object" && val !== null) {
    if (Object.keys(val).length !== 0) {
      return true;
    }
  }
  return false;
};

export const checkEmptyArray = val => {
  if (Array.isArray(val)) {
    if (val.length > 0) {
      return true;
    }
  }
  return false;
};

export const autoPopulateForm = (
  country,
  occupation,
  nob,
  annualIncome,
  sourceOfFund,
  maritalSts
) => {
  if (!country) {
    return false;
  }

  if (!occupation) {
    return false;
  }
  if (!nob) {
    return false;
  }

  if (!annualIncome) {
    return false;
  }

  if (!sourceOfFund) {
    return false;
  }

  if (!maritalSts) {
    return false;
  }
  return true;
};

export const validationFromStepOne = ({
  idNo,
  state,
  race,
  email,
  mobileNo,
  masterId,
}) => {
  let stateCheck = checkEmptyObject(state);
  if (!idNo || idNo === "") {
    return false;
  }
  if (!stateCheck || !("code" in state)) {
    return false;
  }
  if (!race || !("code" in race)) {
    return false;
  }
  if (!email || email === "") {
    return false;
  }
  if (!mobileNo || mobileNo === "") {
    return false;
  }
  if (!masterId || masterId === "") {
    return false;
  }

  return true;
};

export const validationAddress = ({ postCode, state }) => {
  if (!state || state === "") {
    return false;
  }
  if (!postCode || state === "") {
    return false;
  }
  return true;
};
