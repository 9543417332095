import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import { compose } from "underscore";
import {
  Alert,
  DefaultLoader,
  FooterSaveInvest,
  GiveawayAlert,
  StartedCard,
} from "../../components";
import { icons, images } from "../../constants";
import { getNewTokenSuccess } from "../../containers/Authentication/redux/actions";
import { setStep, showError } from "../../globalRedux/actions";
import { sendLogError } from "../../utils/api";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { getAuthTNG } from "../../utils/getAuthTNG";
import { useGiveawayAlert, useHandleDuplicate } from "../../utils/hooks";
import {
  checkEcddStatus,
  getCustomerGoals,
  updateProfile,
  resetData,
} from "../redux/action";
import {
  GET_STARTED,
  GOAL_CREATION,
  OLD_USER,
  ONBOARDING_USER,
  ONBOARDING,
} from "../redux/constant";
import {
  getProjectedReturn,
  getProjectedReturnInvest,
  getTotalUnitFunds,
  setProfileInfoV2,
  setStepStarted,
  updateCustomerDuplicate,
} from "./redux/actions";
import { ENV_INVEST, ENV_SAVE } from "./redux/constant";
import { checkProfileRedirect, createPayload } from "./utils";
import "./styles.scss";

/**
 * ! sChange to conditionanl Component after ready to use version one/two
 */
export const COMPLETE_PAYLOAD = "COMPLETE_PAYLOAD";
const errorMessageDefault =
  "We are facing some technical issue, we're sorry for the inconvenience.";
function GetStartedV2(props) {
  const {
    saveProjectedReturnValue,
    getProjectedReturn,
    profile,
    setStepStarted,
    history,
    checkEcddStatus,
    ecddStatus,
    setStep,
    getStartedLoading,
    loading,
    getTotalUnitFunds,
    totalUnitFunds,
    getCustomerGoals,
    customerGoals = [],
    startedStep,
    getProjectedReturnInvest,
    investProjectedReturnValue,
    investInceptionDate,
    investGetStartedLoading,
    updateCustomerDuplicate,
    updateCustomerDuplicateLoading,
    token,
    showError,
    getNewTokenSuccess,
    fundsUnitloading,
    updateProfile,
    resetData,
  } = props;

  const [nricAlert, setOpenNricAlert] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [pepAlert, setPEPalert] = useState(false);
  // const { notifyGiveaway, setNotifyGiveaway } = useGiveawayAlert();
  const { duplicated, copywriting, handleDuplicate } = useHandleDuplicate(
    updateCustomerDuplicate,
    getNewTokenSuccess
  );

  const list = [
    {
      name: "Info",
      link: "https://www.principal.com.my/en/invest-via-tngewallet",
      action: "url",
    },
    {
      name: "About us",
      link: "https://www.principal.com.my/en/aboutus/mv",
      action: "url",
    },
    {
      name: "FAQ",
      link: "https://www.principal.com.my/en/invest-va-tngewallet-faq",
      action: "url",
    },
    {
      name: "Contact Us",
      link: "https://wa.me/60162999792",
      action: "navigate",
    },
  ];

  const openTNGTnC = () => {
    dataLayerGTM("click_tag", "Click", "Link", `Get Started - T&C button`);
    window.my.navigateTo({
      url:
        "/pages/link/link?url=" +
        encodeURIComponent(
          "https://www.principal.com.my/en/privacy-notice-my/mv"
        ),
    });
  };

  const manipulateLogError = (amlError, ecddError) => {
    let amlLogError;

    if (amlError && amlError.amlHistory && amlError.amlHistory.length > 0) {
      amlLogError = amlError.amlHistory[0].amlSts;
    }

    let errorPayload = {
      page: "Get Started",
      path: "/",
      aml: amlLogError || "Not Found",
      ecdd: ecddError || "Not Found",
    };

    sendLogError(errorPayload);
  };

  const redirectAction = () => {
    if (
      profile === null ||
      !customerGoals ||
      Boolean(duplicated.idNo || duplicated.mobileNo || duplicated.name)
    ) {
      return;
    }
    let directTo = checkProfileRedirect(profile);

    if (typeof directTo === "object" && !Array.isArray(directTo)) {
      manipulateLogError(profile, ecddStatus);
      return;
    }

    switch (directTo) {
      case "DIRECT_ONBOARDING":
        localStorage.setItem("isNewUser", ONBOARDING_USER);
        history.push("/onBoarding/form-step-one");
        break;
      case "DIRECT_DASHBOARD":
        localStorage.setItem("isNewUser", OLD_USER);
        history.push("/dashboard");
        break;
      case COMPLETE_PAYLOAD:
        if ("pepFlag" in profile && profile.pepFlag === "1") {
          setPEPalert(true);
          break;
        }
        const payload = createPayload(profile);
        
        if(typeof payload === "string" && payload === "invalid-email") {
          history.push("/onBoarding/form-step-one", {
            invalidEmail: true
          });
          break;
        }

        if (!isUpdatingProfile) {
          setIsUpdatingProfile(true);
          updateProfile(
            {
              payloadData: payload,
              from: ONBOARDING,
            },
            () => setIsUpdatingProfile(false)
          );
        }
        break;
      default:
        manipulateLogError(profile, ecddStatus);
        break;
    }
  };

  useEffect(() => {
    getProjectedReturn();
    getTotalUnitFunds("MPInvest");
    getProjectedReturnInvest();
  }, []);

  useEffect(() => {
    setStep("/");

    window.eventManager.on("NRIC_VALIDATION", res => {
      setOpenNricAlert(true);
    });

    window.eventManager.on("TRIGGER_ERROR", res => {
      if (process.env.REACT_APP_ENV.trim() === "production") {
        showError(errorMessageDefault);
      } else {
        showError(res);
      }
    });

    return () => window.eventManager.clearEvents();
  }, []);

  useEffect(() => {
    if (profile && token) {
      getCustomerGoals();
      checkEcddStatus(profile.masterId);
    }
  }, [profile, token]);

  useEffect(() => {
    if (!token || isUpdatingProfile) return;
    redirectAction();
  }, [profile, ecddStatus, customerGoals, token, isUpdatingProfile]);

  /**
   * @event GetStarted click event
   * @function getAuthCode from mini program
   * @event Save user status into local storage as New User
   * @event Navigate to create goal screen
   */

  const handleGetStarted = () => {
    getAuthTNG(GET_STARTED);
    dataLayerGTM("click_tag", "Click", "Button", "Get Started Button");
  };

  /** @constant isNewUser to be used as conditional rendering */
  const isNewUser = localStorage.getItem("isNewUser");

  return (
    <>
      {isNewUser === OLD_USER ? (
        <Redirect to="/dashboard" />
      ) : (
        <div className="invest-save-container">
          {(loading ||
            getStartedLoading ||
            investGetStartedLoading ||
            fundsUnitloading) && <DefaultLoader />}
          <Alert
            title="Account doesn’t meet requirement"
            description="So sorry, your account doesn’t meet our requirement."
            isOpen={pepAlert}
            isCS="https://principal.com.my"
            isCancel={false}
            handleAction={() => {
              resetData();
              setPEPalert(false);
            }}
          />
          <Alert
            title="Invalid ID format"
            description="We encountered an issue with the ID format that was automatically captured. "
            isOpen={nricAlert}
            isCS="https://principal.com.my"
            isCancel={false}
            btnTxt="Chat with us on WhatsApp"
            handleAction={() => {
              setOpenNricAlert(false);
              window.my.call("openAppLink", {
                url: "https://wa.me/60162996213",
              });
            }}
            handleCancel={() => setOpenNricAlert(false)}
            withCloseButton
          />
          <Alert
            title={copywriting.heading}
            description={
              <>
                {copywriting.title}
                <br />
                <br />
                {copywriting.description}
                <br />
                <br />
                {copywriting.additionalContext}
                <span
                  style={{
                    marginTop: "1rem",
                    marginBottom: "0.5rem",
                    fontSize: "16px",
                    fontWeight: 700,
                    display: "block",
                  }}
                >
                  {duplicated.name && <>Name: {duplicated.name}</>}
                  {duplicated.mobileNo && (
                    <>
                      {duplicated.name && <br />}
                      Mobile: {duplicated.mobileNo}
                    </>
                  )}
                  {duplicated.idNo && (
                    <>
                      {(duplicated.name || duplicated.mobileNo) && <br />}
                      MyKad: {duplicated.idNo}
                    </>
                  )}
                </span>
              </>
            }
            additionalContent={
              duplicated.type === "tng" && (
                <p
                  style={{
                    color: "#1278CC",
                    fontSize: "16px",
                    fontWeight: 400,
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.my.navigateTo({
                      url:
                        "/pages/link/link?url=" +
                        encodeURIComponent(
                          "https://support.tngdigital.com.my/hc/en-my/requests/new?ticket_form_id=360001558914"
                        ),
                    });
                  }}
                >
                  Contact Touch ‘n Go Customer Service
                </p>
              )
            }
            isOpen={Boolean(
              duplicated.idNo || duplicated.mobileNo || duplicated.name
            )}
            handleAction={handleDuplicate}
            btnTxt={
              updateCustomerDuplicateLoading
                ? "Please wait..."
                : { mp: "Contact Customer Service", tng: "Confirm" }[
                    duplicated.type
                  ]
            }
            submitButtonDisabled={updateCustomerDuplicateLoading}
            titleStyle={{ fontWeight: 900 }}
          />
          {/* <GiveawayAlert
            isOpen={notifyGiveaway}
            handleClose={() => {
              localStorage.setItem(
                "lastNotifyGiveaway",
                new Date().toDateString()
              );
              setNotifyGiveaway(false);
            }}
          /> */}

          <div className="invest-save-container__first">
            <div className="invest-save-container__first__header">
              <div>
                <img src={images.principalSvg} alt="principal-logo" />
              </div>
              <div>
                <span>In partnership with</span>
                <div>
                  <img src={images.tngdSvg} alt="tngd-logo" />
                </div>
              </div>
            </div>
            <StartedCard
              color={"#1278CC"}
              headerTitle={"Save"}
              text1={"No lock in period & Shariah-compliant"}
              text2={"Global Business Magazine Awards 2022"}
              headerDesc="Principal Islamic money market fund"
              risk={"Low risk"}
              percent={saveProjectedReturnValue}
              investFrom={"RM10"}
              firstIcon={images.checkCircle}
              secondIcon={images.award}
              setAction={() => {
                setStepStarted(ENV_SAVE);
                localStorage.setItem(
                  "currentTab",
                  JSON.stringify({
                    id: 1,
                    name: "Save",
                  })
                );
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Button",
                  `Get Started - Save - Next button`
                );
                handleGetStarted();
              }}
            />
            <StartedCard
              color={"#084C95"}
              headerTitle={"Invest"}
              additionalTitles="Promo"
              text1="No lock in period"
              text2="Funds based on different risk appetite"
              headerDesc={`${totalUnitFunds} unit trust funds available`}
              percent={investProjectedReturnValue}
              inceptionDate={investInceptionDate}
              investFrom={"RM10"}
              firstIcon={images.checkCircleGreen}
              secondIcon={images.userCheck}
              setAction={() => {
                setStepStarted(ENV_INVEST);
                localStorage.setItem(
                  "currentTab",
                  JSON.stringify({
                    id: 2,
                    name: "Invest",
                  })
                );
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Button",
                  `Get Started - Invest - Next button`
                );
                handleGetStarted();
              }}
            />
            <div className="TnC-getStarted" style={{ margin: "24px 0" }}>
              <p>
                By proceeding, you agree to share your Personal Data with
                Principal subjected to the following{" "}
                <span onClick={openTNGTnC}>Terms & Conditions.</span>
              </p>
            </div>
          </div>

          <FooterSaveInvest list={list}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <img src={icons.shieldIcon} alt="shield icon" />
              <p style={{ marginTop: "2px", marginLeft: "0.35rem" }}>
                Regulated by the Securities Commission Malaysia
              </p>
            </div>
          </FooterSaveInvest>
        </div>
      )}
    </>
  );
}

const withConnect = connect(
  state => ({
    token: state.auth.MPtoken,
    startedStep: state.getStartedReducer.startedStep,
    totalUnitFunds: state.getStartedReducer.totalUnitFunds.itemCount,
    profile: state.goal.profile,
    loading: state.goal.loading,
    saveProjectedReturnValue: state.getStartedReducer.saveProjectedReturnValue,
    getStartedLoading: state.getStartedReducer.getStartedLoading,
    ecddStatus: state.goal.ecddStatus,
    customerGoals: state.goal.customerGoals,
    investGetStartedLoading: state.getStartedReducer.investGetStartedLoading,
    investProjectedReturnValue:
      state.getStartedReducer.investProjectedReturnValue,
    investInceptionDate: state.getStartedReducer.investInceptionDate,
    updateCustomerDuplicateLoading:
      state.getStartedReducer.updateCustomerDuplicateLoading,
    ...state.custinvestReducer,
    fundsUnitloading: state.getStartedReducer.fundsUnitloading,
  }),

  {
    setProfileInfoV2,
    getProjectedReturn, //API
    setStepStarted,
    checkEcddStatus, //API
    setStep,
    getTotalUnitFunds, //API
    getCustomerGoals, //API
    getProjectedReturnInvest, //API,
    updateCustomerDuplicate, //API
    showError,
    getNewTokenSuccess,
    updateProfile,
    resetData,
  }
);

export default compose(
  withRouter,
  withConnect
)(GetStartedV2);
