import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Typography, Icon, Checkbox } from "@material-ui/core";
import { CustomButton, DefaultLoader, AlertInfo } from "../../components";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import crypto from "crypto";
import {
  investCheckout,
  clearInvestCheckoutLoading,
  clearErrorInvestCheckout,
} from "./redux/actions";
import { setLastAmountInvest } from "../fundSelectionStep2/redux/actions";
import NumberFormat from "react-number-format";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { getCustomer } from "../redux/action";

const riskDisclosure =
  "https://www.principal.com.my/en/ut-loan-financing-risk-my/mv";
const principalTnCPDF =
  "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf";

function FundSelectionStep3(props) {
  let {
    fundsCode = [],
    totalFundsAmount,
    investAmount,
    investCheckout,
    clearInvestCheckoutLoading,
    investCheckoutLoading,
    history,
    investCheckoutError,
    clearErrorInvestCheckout,
    selectedPromo,
    custFundDetail,
    setLastAmountInvest,
    profile,
    getCustomer,
    loading,
  } = props;

  const [isCheck, setCheck] = useState(false);
  const [errorCheckout, setErrorCheckout] = useState(investCheckoutError);

  const { productId = "", cifPlanId = "" } = custFundDetail;

  useEffect(() => {
    clearInvestCheckoutLoading(investCheckoutError);
    getCustomer();
  }, []);

  useEffect(() => {
    setErrorCheckout(investCheckoutError);
  }, [investCheckoutError]);

  useEffect(() => {
    if (Object.keys(selectedPromo).length === 0) {
      setLastAmountInvest();
    }
  }, [selectedPromo]);
  const getPromoPayload = () => {
    let promoPayload = "";
    if (Object.keys(selectedPromo).length === 0) {
      return promoPayload;
    }
    if (selectedPromo.selectedReferralCode) {
      promoPayload = selectedPromo.selectedReferralCode;
      return promoPayload;
    }
    if (Array.isArray(selectedPromo.eligibilityRules.codes)) {
      const getPromoPayload = selectedPromo.eligibilityRules.codes.filter(
        code => code.isPublic
      );
      if (Array.isArray(getPromoPayload) && getPromoPayload.length > 0) {
        promoPayload = getPromoPayload[0].code;
      }
    }
    return promoPayload;
  };

  const handleSubmit = () => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      "Fund Cash In Summary - Continue"
    );
    let promoCode = getPromoPayload();

    let payload = {
      campaignCode: promoCode,
      cifPlanId,
      productId,
      funds: [
        {
          fundCd: fundsCode[0].code,
          amount: totalFundsAmount,
        },
      ],
      customerChannelCode: "Mini Program",
      trxRefNo: crypto.randomBytes(14).toString("hex"),
      amount: totalFundsAmount.toString(),
      ccy: "MYR",
      cifId: profile.masterId,
      name:
        process.env.REACT_APP_ENV.trim() === "production"
          ? profile.fullName
          : "PnN@m3()/PyN .-&B''UYER",
      email: profile && profile.email,
    };

    investCheckout(payload);
  };

  const handleNavigate = url => {
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(url),
    });
  };

  const principalTnC = () => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: principalTnCPDF,
      event: "openPdf",
    });
  };

  if (investCheckoutLoading || loading) return <DefaultLoader />;

  return (
    <>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCheckout}
        isTimeout
        handleAction={() => {
          clearErrorInvestCheckout();
        }}
      />
      <div className="deposit-wrapper">
        <div className="back-btn-wrapper">
          <Icon
            className="material-icons-outlined"
            onClick={() => {
              dataLayerGTM(
                "click_tag",
                "Click",
                "Button",
                "Fund Cash In Summary - back button"
              );
              history.back();
            }}
          >
            arrow_back
          </Icon>
          <h4 className="investment-summary">Your Investment Summary</h4>
        </div>
      </div>

      <div>
        {fundsCode.map((item, index) => (
          <div className="investment-summary-box" key={index}>
            <h2>{item.name}</h2>
            <div>
              <h4>
                <span>Cash In</span>
                <span>
                  RM
                  <NumberFormat
                    value={investAmount}
                    thousandSeparator
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                </span>
              </h4>
              <p>
                <span>Inclusive sales charge of</span>{" "}
                <span>{item.salesCharge || 0}%</span>
              </p>
              <h4>
                {Object.keys(selectedPromo).length > 0 && (
                  <>
                    <span>{selectedPromo.name}</span>
                    <span>-</span>{" "}
                  </>
                )}
              </h4>
            </div>
          </div>
        ))}
      </div>

      <div className="investment-summary-value-box">
        <p className="investment-summary-value-box__cahsin-label">
          Total Cash In
        </p>
        <p className="investment-summary-value-box__total-cashin">
          RM{" "}
          <NumberFormat
            value={totalFundsAmount}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </div>
      <div className="investment-summery-term">
        <Checkbox
          checked={isCheck}
          onChange={() => setCheck(!isCheck)}
          color="primary"
          style={{ margin: 0, padding: 0 }}
        />
        <div className="investment-summery-term__text">
          <p style={{ marginBottom: "8px" }}>
            I have read and agreed to{" "}
            <span onClick={principalTnC}>Principal’s Terms & Conditions</span>,
            and the Unit Trust Loan Financing{" "}
            <span onClick={() => handleNavigate(riskDisclosure)}>
              Risk Disclosure Statement
            </span>
            .
          </p>
          <p>
            I understand the risks of investing in this fund and I am aware that
            the fund is NOT: E-money, deposit, capital guaranteed or capital
            protected by PIDM. Any losses will not be covered by the Capital
            Market Compensation Fund.
          </p>
        </div>
      </div>

      <div className="invest-summary-confirm">
        <CustomButton
          text="Cash In"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!isCheck || investCheckoutLoading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
}

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    profile: state.goal.profile,
    ...state.riskAssessmentReducer,
    ...state.fundSelectionReducer,
    ...state.investPortfolioReducer,
    ...state.investCheckoutReducer,
    ...state.custFundDetailReducer,
    ...state.promoReducer,
  }),
  {
    investCheckout,
    clearInvestCheckoutLoading,
    clearErrorInvestCheckout,
    setLastAmountInvest,
    getCustomer,
  }
);

export default compose(
  withRouter,
  withConnect
)(FundSelectionStep3);
