import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import TransactionValue from "./TransactionValue";
import styles from "./styles";

const Transaction = ({ transactions, classes }) => {
  return (
    <>
      {/* <div className={`${classes.row} ${classes.historyLabel}`}>
        <Typography className={classes.historyLabelMonth}>month</Typography>
      </div> */}
      {transactions.map((transaction, index) => {
        return (
          <Fragment key={index}>
            <Typography
              style={{
                margin: "24px 0 8px 16px",
                color: "#043964",
                fontWeight: "bold",
                fontSize: "16px"
              }}
            >
              {transaction.month}
            </Typography>
            {transaction.data.map((trx, index) => {
              return (
                <div
                  key={index}
                  style={{
                    marginBottom: index !== trx.length - 1 ? "2px" : 0
                  }}
                >
                  <TransactionValue
                    date={trx.submittedDate}
                    transactionType={trx.type}
                    amount={
                      trx.actualAmt === null
                        ? trx.amount
                        : Number(trx.actualAmt)
                    }
                    fee={trx.feeAmount}
                  />
                </div>
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default compose(withStyles(styles))(Transaction);
