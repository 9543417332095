import React, { useEffect, useRef, useState } from "react";
import { StepLabel, RewardsCard, DefaultLoader } from "../../components";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setRewardDetail, getRewards } from "./redux/action";
import { useIsFirstRender } from "../../utils/functions/useIsFirstRender";
import InfiniteScroll from "react-infinite-scroll-component";
import { images } from "../../constants";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import "./style.scss";

const LoaderTrxinfinite = () => {
  return (
    <div
      className="loader-infinite-container"
      style={{ padding: "10px 0px 16px" }}
    >
      <p className="loader-infinite-container__text">Loading...</p>
      <div className="loader-infinite-container__icon">
        <img src={images.loadingInfiniteScroll} alt="principal-logo" />
      </div>
    </div>
  );
};
const Rewards = props => {
  const {
    rewards,
    history,
    setRewardDetail,
    getRewards,
    profile = {},
    rewardLoading,
  } = props;

  const [rewardsList, setRewardsList] = useState([]);
  const pageNumber = useRef(1);
  const { numPages, totalPages } = rewards;
  const firstRender = useIsFirstRender();

  const callRewards = page => {
    getRewards({ cifId: profile.masterId, page, size: 5 });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    callRewards(pageNumber.current);
  }, []);

  useEffect(() => {
    if (firstRender) return;

    setRewardsList(prevData => [...prevData, ...rewards.data]);
  }, [rewards]);

  if (rewardLoading && pageNumber.current === 1) {
    return <DefaultLoader />;
  }

  return (
    <div className="rewards">
      <div style={{ padding: "16px 16px 22px" }}>
        <StepLabel
          title="Campaign Rewards"
          onClick={() => history.back()}
          useFontSize="16px"
        />
      </div>

      {rewards?.data?.length === 0 ? (
        <div className="rewards__empty">
          <h1 className="rewards__empty-title">
            No Campaign Rewards Available Right Now
          </h1>
          <p className="rewards__empty-desc">
            Stay tuned for more exciting campaign rewards! Check back later to
            discover amazing surprises waiting for you.
          </p>
        </div>
      ) : (
        <div
          style={{
            minHeight: "calc(100vh - 16px)",
            paddingBottom: "50px",
          }}
        >
          <InfiniteScroll
            dataLength={rewardsList.length}
            next={() =>
              callRewards(window.scrollY === 0 ? 1 : (pageNumber.current += 1))
            }
            hasMore={numPages < totalPages}
            loader={
              rewardLoading && pageNumber.current !== 1 && <LoaderTrxinfinite />
            }
            style={{ overflowX: "hidden" }}
          >
            {rewardsList.map((item, idx) => (
              <RewardsCard
                name={item.campaignName}
                planAlias={item.planAlias}
                key={idx}
                onClick={() => {
                  setRewardDetail(item);
                  history.push("/rewards/detail");
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

const withConnect = connect(
  state => ({
    ...state.rewardReducer,
    profile: state.goal.profile,
  }),
  { setRewardDetail, getRewards }
);

export default compose(
  withRouter,
  withConnect,
  withProfileRedirect
)(Rewards);
