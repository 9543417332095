import { Step, StepContent, StepLabel, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { DoneIcon, FailedIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";
import { decideTransactionMethod } from "./utils";


const CoolingOff = ({
  status,
  method,
  amount,
  unit,
  fee,
  completedDate,
  details,
  coolOffAmt
}) => {
  const isSubmitted =
    status === "3" ||
    status === "4" ||
    status === "5" ||
    status === "9" ||
    status === "10" ||
    status === "11";
  const isProcessing =
    status === "4" ||
    status === "5" ||
    status === "9" ||
    status === "10" ||
    status === "11";
  const isCompleted = status === "10" ;
  const isFailed = status === "9" || status === "11";

  return (
    <TransactionHistoryBase>
      {/* Submitted */}
      <Step active={isSubmitted}>
        <StepLabel icon={<DoneIcon active={isSubmitted} />}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.label }}
          >
            Submitted
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isSubmitted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Cooling Off amount has been successfully submitted.
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Transaction Method: {decideTransactionMethod({ method })}
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Cooling Off Amount: RM
            <NumberFormat
              value={coolOffAmt}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Administration Fee: RM
            <NumberFormat
              value={fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {moment(completedDate)
              .utcOffset("+0800")
              .format("DD MMM YYYY")}
          </Typography>
        </StepContent>
      </Step>

      {/* Processing */}
      <Step active={isProcessing} style={{ marginTop: "24px" }}>
        <StepLabel icon={<DoneIcon active={isProcessing} />}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isProcessing ? "#000000" : "#9D9EA4"
            }}
          >
            Processing
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isProcessing && "#0091DA",
            borderWidth: 2,
            height: "100%",
            marginTop: "0"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Cooling Off is being processed.
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {completedDate !== null &&
              moment(completedDate)
                .utcOffset("+0800")
                .format("DD MMM YYYY")}
          </Typography>
        </StepContent>
      </Step>

      {/* Completed */}
      <Step active={isCompleted || isFailed} style={{ marginTop: "24px" }}>
        <StepLabel
          icon={
            isFailed ? (
              <FailedIcon active />
            ) : (
              <DoneIcon active={isCompleted} />
            )
          }
        >
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isCompleted || isFailed ? "#000000" : "#9D9EA4"
            }}
          >
            {isFailed ? "Failed" : "Completed"}
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isCompleted && "#0091DA",
            borderWidth: 2,
            marginTop: "0"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {isFailed ? (
              <>
                Cooling off request is failed, please contact our{" "}
                <span
                  onClick={() =>
                    window.my.call("openAppLink", {
                      url: "https://wa.me/60162999792"
                    })
                  }
                  style={{ color: "#0076CF" }}
                >
                  live agent on WhatsApp
                </span>
              </>
            ) : (
              "Your Cooling Off payment is successful and has been reflected in your account."
            )}
          </Typography>
          {isCompleted && (
            <div
              style={{
                marginTop: "8px",
                display: "flex"
              }}
            >
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit: {unit}
              </Typography>
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit Price: RM
                <NumberFormat
                  value={
                    details &&
                    details.length > 0 &&
                    details[0].actualUnitPrice !== null
                      ? details[0].actualUnitPrice
                      : parseFloat(amount / unit)
                  }
                  thousandSeparator
                  displayType="text"
                  decimalScale={4}
                  fixedDecimalScale
                />
              </Typography>
            </div>
          )}
          {(isFailed || isCompleted || status === "5") && (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {moment(completedDate)
                .utcOffset("+0800")
                .format("DD MMM YYYY")}
            </Typography>
          )}
        </StepContent>
      </Step>
    </TransactionHistoryBase>
  );
};

export default CoolingOff;
