import React, { useState, useEffect } from "react";
import {
  Typography,
  MobileStepper,
  Paper,
  Icon,
  IconButton
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { getOnboarding } from "../redux/action";

import { DefaultLoader } from "../../components";
import styles from "./styles";
import "../styles/index.scss"; // global styles

const OnBoarding = props => {
  const { loading, onboarding, getOnboarding, history } = props;

  const [onboardingData, setOnboardingData] = useState([]);

  useEffect(() => {
    getOnboarding();
  }, []);

  useEffect(() => {
    mapOnboarding();
  }, [onboarding]);

  const mapOnboarding = () => {
    if (onboarding) {
      let images = onboarding;
      let data = [];

      if (images) {
        const arrayImages = [];
        let sortedOnboarding = Object.keys(images).sort();
        const images1 = sortedOnboarding.find(image => image === "image1");
        arrayImages.push(images1);
        const images2 = sortedOnboarding.find(image => image === "image2");
        arrayImages.push(images2);
        const images3 = sortedOnboarding.find(image => image === "image3");
        arrayImages.push(images3);

        arrayImages.forEach((item, index) => {
          data.push({
            image: images[item],
            title: images[`title${index + 1}`],
            desc: images[`word${index + 1}`]
          });
        });
      }

      setOnboardingData(data);
    }
  };

  const maxSteps = onboardingData.length;

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleStepChange = activeStep => {
    setActiveStep(activeStep);
  };

  const handleCompletedStep = () => {
    // window.my.postMessage({
    //   api: "getAuthCode",
    //   scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
    //   event: "getAccessToken"
    // });

    history.push("/onBoarding/form-step-one");
  };

  return (
    <>
      {loading && onboardingData && onboardingData.length > 0 ? (
        <div className="center">
          <DefaultLoader />
        </div>
      ) : (
        <div style={{ overflowY: "hidden", height: "100vh" }}>
          <SwipeableViews
            axis="x"
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            style={{ flexGrow: 1 }}
          >
            {onboardingData.map((step, index) => {
              return (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      src={step.image}
                      alt={onboardingData[activeStep].title}
                      style={{ width: "100%", height: "100vh" }}
                    />
                  ) : null}
                </div>
              );
            })}
          </SwipeableViews>
          <div style={{ ...styles.textContainer, flexShrink: 0 }}>
            <Paper square elevation={0}>
              <Typography
                color="textPrimary"
                style={{ ...styles.baseFont, ...styles.title }}
              >
                {onboardingData.length > 0 && onboardingData[activeStep].title}
              </Typography>
              <Typography style={{ ...styles.baseFont, ...styles.details }}>
                {onboardingData.length > 0 && onboardingData[activeStep].desc}
              </Typography>
            </Paper>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              style={{ backgroundColor: "#fff", margin: 0, padding: 0 }}
              nextButton={
                <IconButton
                  onClick={
                    activeStep < maxSteps - 1 ? handleNext : handleCompletedStep
                  }
                  style={styles.arrowBtn}
                >
                  <Icon
                    fontSize="large"
                    style={{
                      cursor: "pointer",
                      borderRadius: 50,
                      color: "#fff"
                    }}
                  >
                    arrow_forward
                  </Icon>
                </IconButton>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

const withConnect = connect(
  state => ({ onboarding: state.goal.onboarding, loading: state.goal.loading }),
  { getOnboarding }
);

export default compose(
  withRouter,
  withConnect
)(OnBoarding);
