import React, { Fragment } from "react";
import { Typography, LinearProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import NumberFormat from "react-number-format";

import { images } from "../../constants";
import styles from "./styles";
import { formatProcessingText } from "../../utils/functions/formatProcessingText";

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#e7e7e7"
  },
  barColorPrimary: {
    background: "#1278cc"
  }
})(LinearProgress);

const SavingItem = ({
  retailFlag,
  savingName,
  savingTarget,
  savingMonthBar,
  image,
  portfolio,
  onClick,
  cashInSts = [],
  classes,
  currentValue
}) => {
  const getRoundedValue = (val, digit) => {
    const value = parseFloat(val);
    return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
  };

  const myReduce = data => {
    return data.reduce((acc, curr) => acc + curr.unitHeld * curr.fund.nav, 0);
  };

  const savingMonthPercentage = savingMonthBar
    .toFixed(2)
    .replace(/[.,]00$/, "");

  const savingValue = currentValue
    ? Number(currentValue)
    : Array.isArray(portfolio)
    ? getRoundedValue(myReduce(portfolio), 2)
    : getRoundedValue(portfolio.unitHeld * portfolio.fund.nav, 2);

  const savingMonthLeft = savingValue - savingTarget;

  return (
    <div onClick={onClick} className={classes.container}>
      <div className={classes.row}>
        <img
          src={image ? image : images.generalSavings}
          alt="dummy"
          className={classes.img}
        />
        <div className={classes.detail}>
          <Typography
            color="textPrimary"
            className={`${classes.baseFont} ${classes.bold}`}
          >
            {savingName}
          </Typography>
          <Typography className={`${classes.baseFont} ${classes.RM}`}>
            <span className={classes.heavy}>
              {savingValue < 0 && "-"}
              RM{" "}
              <NumberFormat
                value={savingValue > 0 ? savingValue : savingValue * -1}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>{" "}
            {!retailFlag && savingTarget > 0 && (
              <>
                <span> / RM </span>
                <NumberFormat
                  value={savingTarget}
                  thousandSeparator
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </>
            )}
          </Typography>
        </div>
      </div>
      {!retailFlag && savingMonthLeft !== null && (
        <>
          {/* Bar */}
          <StyledLinearProgress
            variant="determinate"
            value={savingMonthBar ? savingMonthBar : 0}
            className={classes.bar}
          />
          <div className={classes.period}>
            <Typography
              className={`${classes.baseFont} ${classes.periodMonth}`}
              style={{
                color: savingMonthLeft > 0 ? "#18a29a" : "#616367",
                fontWeight: savingMonthLeft >= 0 && "700"
              }}
            >
              {savingMonthLeft >= 0 ? `Completed` : ``}
              {savingMonthLeft >= 0 && <br />}
              {cashInSts.map((item, index) => (
                <Fragment key={index}>
                  <span style={{ color: "#1793D7", fontWeight: "400" }}>
                    {formatProcessingText(item, "save")}
                  </span>
                  <br />
                </Fragment>
              ))}
            </Typography>
            <Typography
              color="textPrimary"
              className={`${classes.baseFont} ${classes.periodPercentage}`}
            >
              {savingMonthPercentage ? savingMonthPercentage : 0}%
            </Typography>
          </div>
        </>
      )}
      {retailFlag && (
        <Typography
          className={`${classes.baseFont} ${classes.periodMonth}`}
          style={{
            marginTop: "4px"
          }}
        >
          {" "}
          {cashInSts.map((item, index) => (
            <Fragment key={index}>
              <span
                style={{
                  color: "#1793D7",
                  fontWeight: "400"
                }}
              >
                {formatProcessingText(item, "save")}
              </span>
              <br />
            </Fragment>
          ))}{" "}
        </Typography>
      )}
    </div>
  );
};

export default compose(withStyles(styles))(SavingItem);
