import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import { icons } from "../../constants";
import styles from "./styles";

const DropdownItem = ({ title, goals, funds, onClick, classes, fullRsp, containerStyle }) => {
  return (
    <div className={classes.container} style={containerStyle}>
      <Typography
        color="secondary"
        className={`${classes.baseFont} ${classes.title}`}
      >
        {title}
      </Typography>
      {fullRsp && (
        <div>
          <Typography
            color="secondary"
            className={`${classes.baseFont} ${classes.noList}`}
          >
            {`No ${
              fullRsp && goals ? "goal" : "fund"
            } is available for Auto Cash In.`}
          </Typography>
        </div>
      )}

      {goals &&
        goals.map((item, index) => {
          return (
            <div key={index} onClick={() => onClick(item)}>
              <div className={`${classes.row} ${classes.content}`}>
                <Typography
                  color={fullRsp ? "primary" : "textPrimary"}
                  className={`${classes.baseFont} ${classes.contentItem}`}
                >
                  {item.planAlias}
                </Typography>
                <img src={icons.arrowRight} alt="arrowRight" />
              </div>
            </div>
          );
        })}
      {funds &&
        funds.map((item, index) => (
          <div key={index} onClick={() => onClick(item)}>
            <div className={`${classes.row} ${classes.content}`}>
              <Typography
                color={fullRsp ? "primary" : "textPrimary"}
                className={`${classes.baseFont} ${classes.contentItem}`}
              >
                {item.fund.name}
              </Typography>
              <img src={icons.arrowRight} alt="arrowRight" />
            </div>
          </div>
        ))}
    </div>
  );
};

export default compose(withStyles(styles))(DropdownItem);
