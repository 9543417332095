import React from "react";
import classNames from "classnames";
import "./style.scss";

const Tabs = ({ children, className }) => {
  return <div className={classNames("c-tab", className)}>{children}</div>;
};

Tabs.Item = ({ children, className,  selected, ...props }) => (
  <button
    className={classNames(
      "c-tab__btn",
      selected && "c-tab__btn--selected",
      className
    )}
    {...props}
  >
    {children}
  </button>
);

export default Tabs;
