import {
  SET_TRANSFER,
  SET_TRANSFER_FAILED,
  SET_TRANSFER_SUCCESS,
  RESET_TRANSFER_ERROR,
  SET_RESOURCE,
} from "./constant";

const initialState = {
  switched: {},
  transfer: {},
  resource: null
};

export default function switchingReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_RESOURCE:
      return { ...state, resource: payload };
    case SET_TRANSFER:
      return {
        ...state,
        transferLoading: true
      };
    case SET_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: payload,
        transferLoading: false
      };
    case SET_TRANSFER_FAILED:
      return {
        ...state,
        transfer: {},
        transferError: payload,
        transferLoading: false
      };
    case RESET_TRANSFER_ERROR:
      return {
        ...state,
        transferError: null
      }
    default:
      return state;
  }
}
