import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { DASHBOARD, GOALDETAILS } from "../redux/constant";

import { CustomButton } from "../../components";
import styles from "./styles";

const StyledDialog = withStyles({
  scrollPaper: {
    padding: "0 16px"
  },
  paperFullWidth: {
    minWidth: "100%"
  }
})(Dialog);

const DepositSuccessModal = ({ history, match: { params }, classes }) => {
  const from = params.from;

  const [isOpenAlert, setOpenAlert] = useState(true);

  return (
    <StyledDialog
      fullWidth
      open={isOpenAlert}
      onClose={() => setOpenAlert(false)}
    >
      <DialogTitle style={{ padding: "24px 16px 8px" }}>
        <Typography className={`${classes.baseFont} ${classes.alertTitle}`}>
          Set up auto deposit, a powerful way to help you acheive your goal on
          time
        </Typography>
      </DialogTitle>
      <DialogContent style={{ margin: 0, padding: 0 }}>
        <DialogActions style={{ margin: 0 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              margin: "24px 16px 16px",
              padding: 0
            }}
          >
            <CustomButton
              text="Set Up Auto Deposit"
              variant="contained"
              color="#fff"
              backgroundColor="#1278CC"
              onClick={() =>
                history.push(`/make-deposit/${from}/success/auto-deposit`)
              }
            />
            <Button
              onClick={() => {
                setOpenAlert(false);
                from === DASHBOARD && history.push("/dashboard");
              }}
              className={`${classes.baseFont} ${classes.btnText}`}
            >
              Maybe Later
            </Button>
          </div>
        </DialogActions>
      </DialogContent>
    </StyledDialog>
  );
};

export default compose(
  withRouter,
  withStyles(styles)
)(DepositSuccessModal);
