import React, { useState, useEffect } from "react";
import { Typography, FormControl, TextField } from "@material-ui/core";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import { updateProfile } from "../../redux/action";

import { StepLabel, CustomButton, DefaultLoader } from "../../../components";
import styles from "../styles";

const UpdateEmployerName = props => {
  let format = /^[a-zA-Z. ]+$/;

  const { loading, profile, updateProfile, history } = props;

  const [userInfo, setUserInfo] = useState(null);
  const [employerName, setEmployerName] = useState("");
  const [isError, setIsError] = useState(false);
  const [isTax, setIsTax] = useState(false);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setEmployerName(userInfo.employerName ? userInfo.employerName : "");
      setIsTax(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? true
          : false
      );
    }
  }, [userInfo]);

  const handleChange = e => {
    const value = e.target.value;
    setEmployerName(value);

    if (value) {
      if (!format.test(value)) {
        setIsError(true);
      } else {
        setIsError(false);
      }
    } else {
      setIsError(false);
    }
  };

  const handleUpdate = () => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: userInfo.investmentObjCd,
      employerName: employerName,
      identities:
        isTax === true
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).citizenship //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              }
            ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R
        city: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship //R
      }
    };

    // window.consoleLog(payload);
    updateProfile({ payloadData: payload, from: PROFILE });
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="Update employer name"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            Employer name
          </Typography>
          <TextField
            placeholder="Employer name"
            variant="outlined"
            fullWidth
            value={employerName}
            onChange={handleChange}
            error={isError}
            helperText={isError && "Invalid input name"}
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" },
              maxLength: 100
            }}
            FormHelperTextProps={{
              style: { fontSize: "14px", marginLeft: "0px" }
            }}
          />
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={employerName && !isError ? false : true}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    profile: state.goal.profile
  }),
  {
    updateProfile
  }
);

export default compose(
  withRouter,
  withConnect
)(UpdateEmployerName);
