const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  mediaContainer: {
    margin: "16px 0 4px",
    height: "184px"
  },
  media: {
    width: "100%",
    height: "100%",
    borderRadius: "4px"
  },
  contentText: {
    color: "#162b3a",
    margin: "16px 0"
  }
};

export default styles;
