import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";

import { CustomButton, DefaultLoader } from "../../components";
import { images } from "../../constants";
import styles from "./styles";
import { getTransactionHistory } from "../redux/action";

const WithdrawalPending = props => {
  const {
    history,
    classes,
    lastTransaction,
    loadingLastTransaction,
    getTransactionHistory,
  } = props;

  const [withdrawalData] = useState({
    date: moment()
      .utcOffset("+0800")
      .format("DD MMM YYYY hh:mm:ss")
      .slice(0, 11),
    time: moment()
      .utcOffset("+0800")
      .format("DD MMM YYYY hh:mm:ss A")
      .slice(12, 24),
    refNo: lastTransaction.trxRefNo,
    transactionId: lastTransaction.transactionHostRefNo,
    // backend cannot guarantee that last transaction data is correct, in particular: amount and fee field
    // so in order for data to synchrone with user latest cashout
    // we have to reuse data that user submit during cashout process
    amount: history.location?.state?.amount,
    fee: history.location?.state?.fee,
  });

  useEffect(() => {
    getTransactionHistory({
      page: 1,
      size: 1,
      showAll: true
    });
  }, []);

  if (loadingLastTransaction) return <DefaultLoader />;

  return (
    <div className={classes.container}>
      {/* logo */}
      <div style={{ marginTop: "16px", marginLeft: "16px" }}>
        <img src={images.principalLogo} alt="principal-logo" />
      </div>

      {/* Submitted text */}
      <div style={{ margin: "24px 16px" }}>
        <Typography
          color="textPrimary"
          className={`${classes.baseFont}`}
          style={{
            fontSize: "20px",
            fontWeight: "700"
          }}
        >
          Cash Out is in progress...
        </Typography>
        <Typography
          color="textPrimary"
          className={classes.baseFont}
          style={{
            padding: "16px 0 24px",
            borderBottom: "1px solid #9d9ea4"
          }}
        >
          Your Cash Out request is still pending. Please check the transaction
          history for its status in 15 to 30 minutes. For assistance, please
          contact our{" "}
          <span
            style={{
              color: "#0076cf",
              textDecoration: "underline",
              cursor: "pointer"
            }}
            onClick={() => {}}
          >
            customer support
          </span>
          .
        </Typography>
      </div>

      {/* Submitted details */}
      <div style={{ margin: "0 16px" }}>
        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Cash Out Amount
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            RM{" "}
            <NumberFormat
              value={withdrawalData.amount}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Bank Transaction Fee
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            RM{" "}
            <NumberFormat
              value={withdrawalData.fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Cash Out Via
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            FPX Online Banking
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Date
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.date}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Time
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.time}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Reference No
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.baseFont}
            style={{ textAlign: "right" }}
          >
            {withdrawalData.refNo}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Transaction ID
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.transactionId}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginTop: "16px" }}
        >
          <Typography
            className={classes.baseFont}
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: 1.4,
              color: "#084c95"
            }}
          >
            Total:
          </Typography>
          <Typography
            className={classes.baseFont}
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: 1.4,
              color: "#084c95"
            }}
          >
            RM{" "}
            <NumberFormat
              value={withdrawalData.amount - withdrawalData.fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
      </div>

      <div className={classes.btn}>
        <CustomButton
          text="Done"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          onClick={() => history.push("/dashboard")}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loadingLastTransaction: state.goal.loadingTransactionHistory,
    lastTransaction: state.goal.transactionHistory.data[0]
  }),
  { getTransactionHistory }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(WithdrawalPending);
