import React from "react";
import {
  FormControl,
  Typography,
  TextField,
  InputAdornment
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import NumberFormatCustom from "../NumberFormatCustom";
import styles from "./styles";

const GeneralSavingForm = ({
  saving,
  savingError,
  handleChangeSaving,
  handleSetForm,
  checkSymbol,
  classes
}) => {
  return (
    <div className={classes.container}>
      <FormControl fullWidth className={classes.form}>
        <Typography color="textPrimary" className={classes.label}>
          Name your goal
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Wedding Day"
          type="text"
          value={saving.name}
          onChange={e => {
            handleChangeSaving("name", e.target.value);
          }}
          onBlur={e => {
            handleChangeSaving("name", e.target.value);
            handleSetForm();
            checkSymbol();
          }}
          error={savingError}
          helperText={savingError && "Please enter a valid goal name"}
          inputProps={{ className: classes.inputField }}
          FormHelperTextProps={{ className: classes.error }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.form}>
        <Typography color="textPrimary" className={classes.label}>
          Savings Target (optional)
        </Typography>
        <TextField
          variant="outlined"
          placeholder="5,000"
          value={saving.target}
          onChange={e => {
            handleChangeSaving("target", e.target.value);
          }}
          onBlur={e => {
            handleChangeSaving("target", e.target.value);
            handleSetForm();
          }}
          helperText={saving.target < 10 && "Minimum RM 10.00"}
          FormHelperTextProps={{ className: classes.helper }}
          inputProps={{ className: classes.period }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            className: classes.RM,
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textPrimary" className={classes.prefix}>
                  RM
                </Typography>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
    </div>
  );
};

export default compose(withStyles(styles))(GeneralSavingForm);
