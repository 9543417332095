import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@material-ui/core";
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { Icon } from "@material-ui/core";
import withProfileRedirect from "../../containers/App/withProfileRedirect";
import {
  MenuBar,
  SavingDetailCard,
  Transaction,
  DefaultLoader,
  AlertAccount,
  Alert,
  AlertDDA,
} from "../../components";
import { icons } from "../../constants";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  sendFundsCode,
  setIvestMinAmount,
  createFundSuccessV2,
  setSelectedFundCust,
} from "../fundSelection/redux/action";
import { validateValue, setRspButton, ddaStatus } from "./utils";
import "./style.scss";
import {
  checkAcctStsCashIn,
  checkAcctStsCashOut,
} from "../../utils/functions/validationsDeposit";
import { getSubAccountInvest } from "../dashboard/redux/actions";
import { FUNDDETAILS } from "../redux/constant";
import { setRSPPayload, getRspDetail } from "../deposit/redux/action";
import { setCustFundDetail } from "./redux/action";
import { getTransactionHistory } from "../redux/action";
import { useEcddChecking } from "../../utils/hooks";
import { setCurrentEcddStatus } from "../../globalRedux/actions";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { resetSelectedPromo } from "../promoList/redux/action";
import { setLastAmountInvest } from "../fundSelectionStep2/redux/actions";
import { filterSwitchReject } from "../../utils/functions/filterSwitch";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../constants/subaccount-status";
import { useErrorHandler } from "react-error-boundary";

const FundDetails = props => {
  const {
    history,
    match,
    transactionHistory,
    classes,
    sendFundsCode,
    setIvestMinAmount,
    customerPortfolio,
    loadingCustomerPortfolio,
    getSubAccountInvest,
    subAccStsInvest,
    subAccStsInvestLoading,
    riskProfileResult,
    setCustFundDetail,
    getTransactionHistory,
    loadingTransactionHistory,
    profile,
    ecddStatus,
    isEcddCompleted,
    setCurrentEcddStatus,
    resetSelectedPromo,
    setLastAmountInvest,
    createFundSuccessV2,
    setSelectedFundCust,
    getRspDetail,
    rspDetail,
  } = props;

  const errorHandler = useErrorHandler();

  const [ddaPending, setDdaPending] = useState(false);
  const [rspSetup, setRspSetup] = useState({ edit: false, setup: false });
  const [isRiskProfileExpired, setRiskProfileExpired] = useState(false);
  const [isFundDisabled, setIsFundDisabled] = useState(false);
  const [accountSts, setAccountSts] = useState({
    status: "Active",
    blockedCashIn: false,
    blockedCashout: false,
    blockedRsp: false,
    alert: false,
  });
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
    shouldNotPromptOnEffect: !isEcddCompleted,
  });

  const [fundDetail] = useState(() => {
    const fund = customerPortfolio.find(
      portfolio => portfolio.fundCd === match.params.id
    );

    // in order for react error boundary to catch the error outside the render lifecycle
    // we'll intentionally throw error here in case portfolio is not found
    if (!fund) {
      errorHandler(
        `Cannot find the portfolio with fundCd of ${match.params.id}`
      );
    }

    return fund;
  });

  const transactions = useMemo(() => {
    const groupedTrxByMonth = [];
    const filterSwitch = filterSwitchReject(transactionHistory);

    for (const res of filterSwitch) {
      const month = moment(res.submittedDate).format("MMMM");
      const year = new Date(res.submittedDate).getFullYear();
      const id = groupedTrxByMonth.length + 1;

      if (
        ((res.type === "Buy" ||
          res.type === "SwitchIn" ||
          res.type === "SwitchOut" ||
          res.type === "SwitchAll") &&
          res.status !== "5") ||
        ((res.type === "Sell" || res.type === "FullSell") &&
          res.status !== "10")
      ) {
        continue;
      }

      const found = groupedTrxByMonth.some(
        value => value.month === month && value.year === year
      );
      if (found) {
        const index = groupedTrxByMonth.findIndex(
          value => value.month === month && value.year === year
        );
        groupedTrxByMonth[index].data = [...groupedTrxByMonth[index].data, res];
      } else {
        groupedTrxByMonth.push({
          id: id,
          month: month,
          year: year,
          data: [res],
        });
      }
    }

    return groupedTrxByMonth;
  }, [transactionHistory]);

  useEffect(() => {
    resetSelectedPromo();
    setLastAmountInvest();
    sessionStorage.removeItem("stopRsp");
  }, []);

  useEffect(() => {
    getTransactionHistory({
      size: -1,
      fundCd: fundDetail.fundCd,
      cifPlanId: fundDetail.cifPlanId,
    });
    getRspDetail({
      cifPlanId: fundDetail.cifPlanId,
      fundCd: fundDetail.fundCd,
    });
    getSubAccountInvest(fundDetail.accountNo);
  }, []);

  useEffect(() => {
    if ("rspStatus" in fundDetail) {
      let knowTheStatus = setRspButton(fundDetail);
      setRspSetup(knowTheStatus);
    }

    setCustFundDetail(fundDetail);
  }, [fundDetail]);

  /**
   ** check if subaccount status is posible to do transaction
   ** start
   */

  const checkAccStsinvest = () => {
    let newObj = {
      status: "Active",
      alert: false,
      blockedCashIn: false,
      blockedCashout: false,
    };
    if ("sts" in subAccStsInvest) {
      let { sts } = subAccStsInvest;
      newObj.status = sts;
      let stopCashIn = checkAcctStsCashIn(sts);
      let stopCashOut = checkAcctStsCashOut(sts);
      if (!stopCashIn) {
        newObj.blockedCashIn = true;
      }
      if (!stopCashOut) {
        newObj.blockedCashout = true;
      }
      if (
        (rspSetup.setup || ["0", "21", "26"].includes(rspDetail.rspStatus)) &&
        NOT_ALLOWED_ACCOUNT_STATUS.includes(sts)
      ) {
        newObj.blockedRsp = true;
      }
      setAccountSts(newObj);
    }
  };

  useEffect(() => {
    checkAccStsinvest();
  }, [subAccStsInvest, rspSetup, rspDetail]);
  /**
   ** check if subaccount status is posible to do transaction
   ** end
   */

  const handleDeposit = () => {
    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }

    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }

    if (fundDetail?.fund?.isActive === "0") {
      setIsFundDisabled(true);

      return;
    }

    const { fund = {} } = fundDetail;
    const { code = "", name = "", minSubsAmt = "10", salesFeePerc = "" } = fund;
    setIvestMinAmount(minSubsAmt);
    sendFundsCode([{ code, name, salesCharge: salesFeePerc }]);
    createFundSuccessV2({
      masteId: fundDetail.cifId,
      cifPlanId: fundDetail.cifPlanId,
      productId: fundDetail.product.id,
      accountNo: fundDetail.accountNo,
    });
    setSelectedFundCust(fundDetail.fund);

    history.push("/fund-selection-step-two");
  };

  const handleWithdraw = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Fund detail - Cash Out");
    if (accountSts.blockedCashout) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }
    sendFundsCode([
      {
        code: fundDetail.fund.code,
        name: fundDetail.fund.name,
        cifPlanId: fundDetail.cifPlanId,
        product: fundDetail.product,
        nav: fundDetail.nav.nav,
        unitHeld: fundDetail.unitHeld,
      },
    ]);
    history.push("/make-withdrawal");
  };

  const handleRsp = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Fund detail - RSP ");

    if ("rspStatus" in fundDetail) {
      let ddaResult = ddaStatus(fundDetail.rspStatus);
      if (ddaResult) {
        setDdaPending(ddaResult);
        return;
      }
    }

    if (accountSts.blockedRsp) {
      setAccountSts(prev => ({ ...prev, alert: true }));
      return;
    }

    props.setRSPPayload({
      cifPlanId: fundDetail.cifPlanId,
      fundCd: fundDetail.fund.code,
      target: "INVEST_RSP",
    });

    let rspSet = "setupRSP";
    if (rspSetup.edit) {
      rspSet = "editRSP";
    }

    history.push(
      `/make-deposit/${FUNDDETAILS}/success/auto-deposit-v2/${rspSet}`
    );
  };

  if (
    loadingCustomerPortfolio ||
    loadingTransactionHistory ||
    !fundDetail ||
    subAccStsInvestLoading
  )
    return <DefaultLoader />;

  return (
    <div className="l-fund-details">
      <AlertDDA
        title="This Goal or Fund has already enabled Auto Cash In."
        firstText="Your Auto Cash In request is being processed, please come back and check the status later."
        isOpen={ddaPending}
        handleClose={() => setDdaPending(false)}
      />
      <AlertAccount
        title={`Account ${
          accountSts.status === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountSts.status === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountSts({ ...accountSts, alert: false });

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={accountSts.alert}
        handleClose={() => setAccountSts({ ...accountSts, alert: false })}
      />
      <Alert
        title="This Fund has disabled"
        description="You cannot perform cashin on this fund, please try again later"
        isOpen={isFundDisabled}
        btnTxt="Ok"
        handleAction={() => setIsFundDisabled(false)}
        handleCancel={() => setIsFundDisabled(false)}
      />
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={isRiskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={() => {
          history.push("/invest-introduction");
        }}
        handleCancel={() => setRiskProfileExpired(false)}
      />
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
        }}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <div className="l-fund-details__wrapper">
        <div className="l-fund-details__fund">
          <div className="l-fund-details__top-wrapper">
            <Icon
              className="material-icons-outlined"
              style={{ cursor: "pointer", color: "#ffffff" }}
              onClick={() => history.back()}
            >
              arrow_back
            </Icon>
            <div
              className="l-fund-details__info"
              onClick={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Link",
                  "Fund detail - Fund info"
                );
                window.my.navigateTo({
                  url:
                    "/pages/link/link?url=" +
                    encodeURIComponent(fundDetail?.fund?.fundInfoUrl),
                });
              }}
            >
              <img src={icons.fundInfo} alt="fund info" />
              <p>Fund info</p>
            </div>
          </div>

          <h1 className="l-fund-details__name">{fundDetail.fund.name}</h1>
          <h2 className="l-fund-details__total-asset">
            RM{" "}
            <NumberFormat
              value={fundDetail.currentValue || 0}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </h2>
          <p className="l-fund-details__total-returns">
            Total returns:{" "}
            <span>
              {Number(fundDetail.totalGain) < 0 ? "-" : "+"}RM
              <NumberFormat
                value={validateValue(fundDetail.totalGain, "NUMBER")}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
              />
            </span>
          </p>
          <span className="l-fund-details__last-update">
            Last update:{" "}
            {moment(validateValue(fundDetail.lastUpdated, "DATE"))
              .utcOffset("+0800")
              .format("DD MMM YYYY, h:mm A")}
          </span>

          <div className="l-fund-details__grid">
            <SavingDetailCard
              label="Latest Net Asset Value"
              decimalSet={4}
              needNumberFormat={true}
              detail={validateValue(fundDetail.nav.nav, "NUMBER")}
            />
            <SavingDetailCard
              label="Number of Units"
              decimalSet={4}
              needNumberFormat={true}
              detail={validateValue(fundDetail.unitHeld, "NUMBER")}
            />
          </div>
        </div>
        <div className={`${classes.bg}`}>
          {transactions && transactions.length > 0 && (
            <Typography
              style={{
                ...styles.baseFont,
                padding: "16px 16px 0",
                color: "#616367",
              }}
            >
              You can refer to your Cash In or Cash Out under processing at{" "}
              <span
                style={{ color: "#1278CC" }}
                onClick={() => history.push("/transaction")}
              >
                transaction history
              </span>
              .
            </Typography>
          )}
          {transactions && transactions.length > 0 ? (
            <Transaction transactions={transactions} />
          ) : (
            <div className={`${classes.baseFont} ${classes.emptyText}`}>
              <div>
                You can refer to your Cash In or Cash Out under processing at{" "}
                <span
                  style={{ color: "#1278CC" }}
                  onClick={() => history.push("/transaction")}
                >
                  transaction history
                </span>
                .
                <br />
                Start Cash In now.
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Bottom bar */}
      <div />
      <div className="l-fund-details__menu-bar">
        <MenuBar
          rspText={rspSetup.edit ? "Edit" : "Auto Cash In"}
          handleSwitching={handleCheckEcdd({
            cb: () => {
              history.push("/switching/create", {
                id: fundDetail.fundCd,
                productType: "invest",
              });
            },
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/FUNDDETAILS"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Fund detail - Switching",
            },
          })}
          handleWithdraw={handleWithdraw}
          handleDeposit={handleCheckEcdd({
            cb: handleDeposit,
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/FUNDDETAILS"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Fund detail - Cash In",
            },
          })}
          handleAutoDeposit={handleRsp}
          isBackgroundGrayed
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    ...state.dashboardReducer,
    transactionHistory: state.goal.transactionHistory.data,
    customerPortfolio: state.dashboardReducer.customerPortfolio,
    loadingCustomerPortfolio: state.dashboardReducer.loadingCustomerPortfolio,
    riskProfileResult: state.riskAssessmentReducer.riskProfileResult,
    loadingTransactionHistory: state.goal.loadingTransactionHistory,
    ecddStatus: state.goal.ecddStatus,
    profile: state.goal.profile,
    isEcddCompleted: state.commingFromReducer.isEcddCompleted,
    rspDetail: state.autoDepositReducer.rspDetail,
  }),
  {
    sendFundsCode,
    setIvestMinAmount,
    getSubAccountInvest,
    setRSPPayload,
    setCustFundDetail,
    setCurrentEcddStatus,
    getTransactionHistory,
    resetSelectedPromo,
    setLastAmountInvest,
    createFundSuccessV2,
    setSelectedFundCust,
    getRspDetail,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withProfileRedirect
)(FundDetails);
