import { all, takeLatest, call, put } from "redux-saga/effects";
import { GET_ALLOW_PROFILE } from "./constans";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";
import { getAllowProfileSuccess, getAllowProfileFailed } from "./actions";

import { sendLogError } from "../../../utils/api";

let logError = {};

function* allowProfileUpdateSaga() {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/sys-config/allow_update_profile`
    );
    const { data } = res;

    if (data.errorCode) {
      logError.api = res.url || window.location.pathname;
      logError.message = data.message || "---";
      logError.errorResponse = true;
      logError.status = res.status || "Not Found";
      logError.errorCode = data.errorCode;

      sendLogError(logError);
      throw new Error("fail get allow update profile value");
    }

    if (typeof data === "object" && "value" in data) {
      const converted = { ...data };
      if (converted.value === "false") {
        converted.value = false;
      }
      if (converted.value === "true") {
        converted.value = true;
      }

      yield put(getAllowProfileSuccess(converted));
    } else {
      sendLogError(data);
      throw new Error("fail get allow update profile value");
    }
  } catch (error) {
    yield put(getAllowProfileFailed());
  }
}

export function* profileWorkerSaga() {
  yield all([takeLatest(GET_ALLOW_PROFILE, allowProfileUpdateSaga)]);
}
