import { Step, StepContent, StepLabel, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { DoneIcon, FailedIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";

const Dividend = ({ status, amount, unit, completedDate, details }) => (
  <TransactionHistoryBase>
    {/* Completed */}
    <Step active>
      <StepLabel
        icon={status === "9" ? <FailedIcon active /> : <DoneIcon active />}
      >
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            ...styles.label,
            color: "#000000"
          }}
        >
          {status === "9" ? "Failed" : "Completed"}
        </Typography>
      </StepLabel>
      <StepContent>
        <Typography style={{ ...styles.baseFont, ...styles.content }}>
          {status === "9"
            ? "Your Monthly Income Distribution is unsuccessful."
            : "Your Monthly Income Distribution is available."}
        </Typography>
        {status === "9" ? (
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {completedDate !== null &&
              moment(completedDate)
                .utcOffset("+0800")
                .format("DD MMM YYYY")}
          </Typography>
        ) : (
          <div
            style={{
              marginTop: "8px",
              display: "flex"
            }}
          >
            <Typography style={{ ...styles.baseFont, ...styles.content }}>
              Unit: {unit}
            </Typography>
            <Typography style={{ ...styles.baseFont, ...styles.content }}>
              Unit Price: RM
              <NumberFormat
                value={
                  details &&
                  details.length > 0 &&
                  details[0].actualUnitPrice !== null
                    ? details[0].actualUnitPrice
                    : parseFloat(amount / unit)
                }
                thousandSeparator
                displayType="text"
                decimalScale={4}
                fixedDecimalScale
              />
            </Typography>
          </div>
        )}

        {(status === "9" || status === "5") && (
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {moment(completedDate)
              .utcOffset("+0800")
              .format("DD MMM YYYY")}
          </Typography>
        )}
      </StepContent>
    </Step>
  </TransactionHistoryBase>
);

export default Dividend;
