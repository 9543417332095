import React, { useState } from "react";
import {
  Typography,
  Icon,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";

import { StepLabel } from "../../../components";
import styles from "../styles";

const StyledExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    "&:before": {
      display: "none"
    }
  }
})(ExpansionPanel);

const StyledExpansionPanelSummary = withStyles({
  root: {
    padding: "0 16px"
  }
})(ExpansionPanelSummary);

const StyledExpansionPanelDetails = withStyles({
  root: {
    padding: "0 16px"
  }
})(ExpansionPanelDetails);

const FAQ = props => {
  const { history } = props;

  const [expanded, setExpanded] = useState(null);

  const handleChangePanel = panel => (event, expanded) => {
    setExpanded(expanded ? panel : false);
  };

  return (
    <div
      style={{
        ...styles.column,
        flex: 1,
        minHeight: "100vh",
        backgroundColor: "#fff"
      }}
    >
      <StepLabel
        title="Frequently Asked Questions"
        onClick={() => history.back()}
        containerStyles={{ padding: "16px 16px 22px" }}
      />
      <div style={{ width: "100%" }}>
        <StyledExpansionPanel
          expanded={expanded === "panel1"}
          onChange={handleChangePanel("panel1")}
        >
          <StyledExpansionPanelSummary
            expandIcon={
              <Icon
                className="material-icons-outlined"
                style={{ cursor: "pointer", color: "#0091DA" }}
              >
                expand_more
              </Icon>
            }
          >
            <Typography
              color="textPrimary"
              style={{ ...styles.baseFont, fontWeight: "700" }}
            >
              Expansion Panel 1
            </Typography>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails>
            <Typography
              style={{
                ...styles.baseFont,
                fontSize: "14px",
                lineHeight: 1.29,
                color: "#505050"
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </StyledExpansionPanelDetails>
        </StyledExpansionPanel>
        <StyledExpansionPanel
          expanded={expanded === "panel2"}
          onChange={handleChangePanel("panel2")}
        >
          <StyledExpansionPanelSummary
            expandIcon={
              <Icon
                className="material-icons-outlined"
                style={{ cursor: "pointer", color: "#0091DA" }}
              >
                expand_more
              </Icon>
            }
          >
            <Typography
              color="textPrimary"
              style={{ ...styles.baseFont, fontWeight: "700" }}
            >
              Expansion Panel 2
            </Typography>
          </StyledExpansionPanelSummary>
          <StyledExpansionPanelDetails>
            <Typography
              style={{
                ...styles.baseFont,
                fontSize: "14px",
                lineHeight: 1.29,
                color: "#505050"
              }}
            >
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
              eget.
            </Typography>
          </StyledExpansionPanelDetails>
        </StyledExpansionPanel>
      </div>
    </div>
  );
};

export default compose(withRouter)(FAQ);
