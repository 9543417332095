import React from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import styles from "./styles";

const StepLabel = ({
  title,
  containerStyles,
  onClick,
  classes,
  useFontSize
}) => {
  return (
    <div className={classes.row} style={{ ...containerStyles }}>
      <Icon
        className="material-icons-outlined"
        style={{ cursor: "pointer", color: "#0091DA" }}
        onClick={onClick}
      >
        arrow_back
      </Icon>
      <Typography
        color="secondary"
        className={classes.navigationLabel}
        style={{ fontSize: useFontSize ? useFontSize : "20px" }}
      >
        {title}
      </Typography>
    </div>
  );
};

export default compose(withStyles(styles))(StepLabel);
