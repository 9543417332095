import React from "react";
import { Icon } from "@material-ui/core";
import classNames from "classnames";
import "./style.scss";

const BackButton = ({ handleBack, title, className }) => {
  return (
    <div className={classNames("c-back-btn", className)}>
      <Icon
        className="material-icons-outlined"
        onClick={handleBack}
        style={{ cursor: "pointer", color: "#0091DA" }}
      >
        arrow_back
      </Icon>
      <h2>{title}</h2>
    </div>
  );
};

export default BackButton;
