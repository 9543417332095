import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import moment from "moment";
import cogoToast from "cogo-toast";
import { getPersistConfig } from "redux-deep-persist";
import { persistStore, persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { blacklist } from "./blacklist";
import reducers from "./reducers";
import rootSaga from "./sagas";
import axios from "axios";

const sagaMiddleware = createSagaMiddleware();

const persistConfig = getPersistConfig({
  key: "root",
  storage,
  transforms: [
    encryptTransform({
      secretKey: "mP9fbCC%CAFx!ij",
      onError: err => {
        console.error(err);
      },
    }),
  ],
  stateReconciler: autoMergeLevel2,
  blacklist: blacklist,
  rootReducer: reducers,
});
const globalReducer = (state, action) => {
  if (action.type === "USER_SIGNED_OUT") {
    state = undefined;
  } else if (
    action.type === "STOP_LOADING" ||
    action.type === "persist/REHYDRATE"
  ) {
    // This is likely where rehydration has completed.
    // We try to set the accessToken here, in case the persistStore callback is too late.

    if (state.auth.accessToken) {
      // On rehydrating persistence (or basically refresh the page), we set the axios headers with the token
      // console.log('====== setting header access token in globalReducer on STOP_LOADING')
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        state.auth.accessToken
      }`;
      // console.log('state.auth.accessToken ',state.auth.accessToken)
      // console.log('axios.defaults.headers', axios.defaults.headers)
    }
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, globalReducer);

function isTokenExpired(state) {
  const { accessToken, expTime } = state.auth;
  let expired = true;
  if (moment(expTime).isValid()) {
    expired = moment() > moment(expTime);
  }
  return accessToken && expired;
}

const checkTokenExpirationMiddleware = store => next => action => {
  // this middleware will run pretty much on anything that accesses the store
  // and will check if token is EXPIRED. If it is, will automatically log user out.
  // It will also clean out redux state (memory) and clear redux persist (local storage)
  const state = store.getState();

  if (state.auth.accessToken) {
    next(action);
    if (isTokenExpired(state)) {
      cogoToast.error("Your token has expired, please login again");
      store.dispatch({ type: "USER_SIGNED_OUT" });
      localStorage.clear();
      window.eventEmitter.emit("invalid_token");
    }
  } else {
    next(action);
  }
};

export default function configureStore(initialState = {}) {
  // Create the store with two middlewares
  // x. sagaMiddleware: Makes redux-sagas work
  // x. checks token expiry on each action (so on refreshing browser, or re-opening browser or really any redux action)
  const middlewares = [sagaMiddleware, checkTokenExpirationMiddleware];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    process.env.NODE_ENV !== "production" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          shouldHotReload: false, // eslint-disable-line
        }) // eslint-disable-line
      : compose;
  /* eslint-enable */

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(...enhancers)
  );

  // Extensions

  const persistor = persistStore(store, {}, () => {
    // on redux persist rehydrate, if token is still valid
    // re-add it to axios headers
    // good example of when rehydrate occurs is on browser refresh (F5)
    const state = store.getState();

    if (state.auth.accessToken) {
      // On rehydrating persistence (or basically refresh the page), we set the axios headers with the token
      // This is usually too late though, the app will have rendered by this time.
      // Instead it's usually handled in above globalReducer.
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        state.auth.accessToken
      }`;
    }
  });

  store.runSaga = sagaMiddleware.run(rootSaga);

  return { persistor, store };
}

const { persistor, store } = configureStore({});

export {
  persistor,
  store
}
