import React from "react";
import "./style.scss";
const RewardsCard = ({ name, planAlias, onClick }) => {
  return (
    <div className="rewards-card-container" onClick={onClick}>
      <div>
        <p className="rewards-card-container__promo-name">{name}</p>
        <p className="rewards-card-container__plan-name">{planAlias}</p>
      </div>
      <span>view</span>
    </div>
  );
};

export default RewardsCard;
