/**
 *
 * @param {Array<Record<string, unknown>>} items
 * @returns items with injected property called `cashInStatus` which descript the status of cash in / cash out / auto cash in
 */
export const setStatus = (items = []) => {
  /**
   * prev is array
   * item is current object of items
   * index is current index of items
   */
  const results = items.reduce((prev, item, index) => {
    const cashInStatus = [];

    if (!("transactionHistory" in item)) {
      prev[index] = {
        ...item,
        cashInStatus: []
      };
      return prev;
    }

    for (const transaction of item.transactionHistory) {
      if (
        transaction.type === "Buy" ||
        transaction.type === "Sell" ||
        transaction.type === "FullSell"
      ) {
        cashInStatus.push({
          sts: transaction.status,
          amount: transaction.amount,
          type: transaction.type
        });
      }
    }

    if ("rspDate" in item && "rspAmount" in item) {
      cashInStatus.unshift({
        sts: "",
        amount: item.rspAmount,
        type: "rsp",
        rspDate: item.rspDate
      });
    }
    if ("rspStatus" in item) {
      if (
        item.rspStatus === "4" ||
        item.rspStatus === "14" ||
        item.rspStatus === "24"
      ) {
        cashInStatus.unshift({
          sts: item.rspStatus,
          amount: "",
          type: "PENDING_DDA",
          rspDate: ""
        });
      }
    }

    prev[index] = {
      ...item,
      cashInStatus: cashInStatus
    };

    return prev;
  }, []);

  return results;
};
