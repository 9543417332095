/* eslint-disable react-hooks/rules-of-hooks */
import moment from "moment";
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { Alert, InvestorCard } from "../../components";
import { icons } from "../../constants";
import { useSwitchList } from "../../contexts/SwitchListContext";
import { useSwitching } from "../../contexts/SwitchingContext";
import { dataMapping } from "../../utils/functions/dataMappingSwitching";
import { getSubAccountInvest } from "../dashboard/redux/actions";
import { createFundV2 } from "../fundSelection/redux/action";

const LEVEL_COLORS = ["#1278CC", "#0D629F", "#043964"];

const SwitchList = ({
  children,
  riskProfile,
  scoreList,
  history,
  subaccountInvestLoading,
  getSubAccountInvest,
  createFundV2,
  createdPortfolio,
  createFundLoadingV2,
}) => {
  const {
    tabsResource,
    setShow,
    show,
    setSubaccount,
    forbiddenSubaccount,
    switchedType,
    customerPortfolio,
    fundList,
  } = useSwitchList();

  const {
    handleInvestIntro,
    switched,
    setResource,
    isAlreadyRequestedCreatePortfolio,
  } = useSwitching();

  const handleGotoFundSelection = () => {
    setResource({
      from: {
        cifPlanId: switched.from.cifPlanId,
        fundCd: switched.from.fundCd,
        name: switched.from.name,
        switchableAmount: switched.from.switchableAmount,
        productType: switched.from.productType,
        accountNo: switched.from.accountNo,
        fee: switched.from.fee,
      },
      to: {
        cifPlanId: switched.to.cifPlanId,
        fundCd: switched.to.fundCd,
        name: switched.to.name,
        productType: switched.to.productType,
        accountNo: switched.to.accountNo,
      },
      amount: switched.amount,
      maxAmount: switched.maxAmount,
      cifid: switched.cifid,
    });

    history.push(`/fund-selection/${riskProfile.resultNameEn.toLowerCase()}`);
  };

  const handleInvestorInfo = () => {
    if (
      new Date() >=
      moment(riskProfile.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setShow(prev => ({ ...prev, riskProfileExpired: true }));
      return;
    }

    if (forbiddenSubaccount) {
      setSubaccount(prev => ({ ...prev, open: true }));
      return;
    }

    // user havent click on either fund in `from` or in `to`
    if (
      !(
        switched.from.productType === "invest" ||
        switched.to.productType === "invest"
      )
    ) {
      const callback = freshlyCreatedPortfolio => {
        if (freshlyCreatedPortfolio) {
          isAlreadyRequestedCreatePortfolio.current = true;
        }

        const portfolio =
          customerPortfolio[0] ||
          freshlyCreatedPortfolio ||
          createdPortfolio ||
          {};

        getSubAccountInvest(portfolio.accountNo, subaccountData => {
          if (
            typeof subaccountData === "object" &&
            subaccountData.sts !== "Active"
          ) {
            setSubaccount({
              [`${switchedType}-status`]: subaccountData.sts,
              open: true,
            });
          } else {
            handleGotoFundSelection();
          }
        });

        return;
      };

      //* (forbid user from creating portfolio on subsequent click)

      // in case user is still new and dont have customer-portfolio
      // we create portfolio using first item in fund list
      if (
        !isAlreadyRequestedCreatePortfolio.current &&
        customerPortfolio.length === 0
      ) {
        if (createFundLoadingV2) return;

        createFundV2(
          {
            cifId: switched.cifid,
            fundsPayload: [{ fundCode: fundList[0].code }],
          },
          callback
        );
      } else {
        callback();
      }

      return;
    }

    handleGotoFundSelection();
  };

  return (
    <>
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={show.riskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={handleInvestIntro}
        handleCancel={() =>
          setShow(prev => ({ ...prev, riskProfileExpired: false }))
        }
        handleClose={() =>
          setShow(prev => ({ ...prev, riskProfileExpired: false }))
        }
      />
      {Object.values(riskProfile).length > 0 && (
        <InvestorCard
          riskIndex={tabsResource[riskProfile.resultNameEn]}
          riskLevel={riskProfile.resultNameEn.toLowerCase()}
          desc={
            (
              scoreList.find(
                score =>
                  score.nameEn.toLowerCase() ===
                  riskProfile.resultNameEn.toLowerCase()
              ) || {}
            ).descEn
          }
          handleSubmit={handleInvestorInfo}
          onClose={() => {
            setShow(prev => ({ ...prev, riskInfo: false }));
          }}
          isPopup
          isLoading={subaccountInvestLoading}
          isOpen={show.riskInfo}
        />
      )}
      <div className="l-switch__list">{children}</div>
    </>
  );
};

SwitchList.Content = () => {
  const { switched } = useSwitching();
  const {
    tabsResource,
    resourceLength,
    isLoadingAfterSelecting,
    currentProductType,
    handleSwitched,
    switchedType,
  } = useSwitchList();
  const isInvest = currentProductType === "invest";

  if (resourceLength === 0)
    return (
      <h1 className="l-switch__empty-title">
        {isInvest
          ? "You haven’t invest any Unit trust fund yet."
          : "You haven’t create and invest any financial Goal yet."}
      </h1>
    );

  if (isInvest)
    return (
      <>
        {Object.keys(tabsResource).map(
          (title, riskIndex) =>
            tabsResource[title].length > 0 && (
              <div key={title} className="c-fund-group">
                <h3
                  style={{
                    color: LEVEL_COLORS[riskIndex],
                  }}
                >
                  {title}
                </h3>
                {tabsResource[title].map((content, idx) => {
                  const data = dataMapping({
                    ...content,
                    type: switchedType,
                    productType: currentProductType,
                  });

                  return (
                    <div
                      key={idx}
                      className="c-card-switch c-card-switch--no-inline-padding"
                      onClick={handleSwitched(data)}
                    >
                      <h4>{data.name}</h4>
                      {switched[switchedType].name === data.name &&
                        (isLoadingAfterSelecting ? (
                          <div className="spinner-blueish" />
                        ) : (
                          <img src={icons.check} alt="check" />
                        ))}
                    </div>
                  );
                })}
              </div>
            )
        )}
      </>
    );

  return (
    <>
      {tabsResource.map((content, idx) => {
        const data = dataMapping({
          ...content,
          type: switchedType,
          productType: currentProductType,
        });

        return (
          <div
            key={idx}
            className="c-card-switch"
            onClick={handleSwitched(data)}
          >
            <h4>{data.name}</h4>
            {switched[switchedType].name === data.name &&
              (isLoadingAfterSelecting ? (
                <div className="spinner-blueish" />
              ) : (
                <img src={icons.check} alt="check" />
              ))}
          </div>
        );
      })}
    </>
  );
};

const withConnect = connect(
  state => ({
    riskProfile: state.riskAssessmentReducer.riskProfileResult,
    scoreList: state.fundSelectionReducer.scoreList || [],
    subaccountInvestLoading: state.dashboardReducer.subAccStsInvestLoading,
    createFundLoadingV2: state.fundSelectionReducer.createFundLoadingV2,
    createdPortfolio: state.fundSelectionReducer.fundResult,
  }),
  {
    getSubAccountInvest,
    createFundV2,
  }
);

export default compose(
  withRouter,
  withConnect
)(SwitchList);
