import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { CustomButton, AlertInfo } from "../../components";
import { icons, images } from "../../constants";
import "./style.success.scss";

const Success = ({ history, transfer }) => {
  const [
    isSalesChargeDisclaimerOpen,
    setSalesChargeDisclaimerOpen,
  ] = React.useState(false);

  return (
    <>
      <AlertInfo
        title="Switching Fee"
        isOpen={isSalesChargeDisclaimerOpen}
        handleAction={() => {
          setSalesChargeDisclaimerOpen(false);
        }}
      >
        <ul style={{ marginLeft: "15px", paddingLeft: "15px" }}>
          <li>
            When you switch between classes or into another Principal Malaysia
            fund (or its classes), it's treated as a withdrawal from one and
            an investment in another.
          </li>
          <li>
            You might incur a Switching Fee, which is the difference between
            the Application Fee of the current class and the one you're
            switching into.
          </li>
          <li>
            No Switching Fee will be charged if the new class or Principal
            Malaysia fund (or its classes) has a lower Application Fee.
          </li>
          <li>There may be an administrative fee for each switch.</li>
        </ul>
        <span style={{ display: "inline-block", marginTop: "18px" }}>
          For more details, please refer to the Annexure of the respective
          class.
        </span>
      </AlertInfo>
      <div className="l-success">
        <img src={images.principalLogo} alt="principal-logo" />

        <h2 className="l-success__title">
          Switch Goal/Fund submitted for processing
        </h2>
        <p className="l-success__desc">
          We have received your request, thank you.
        </p>
        <div className="l-success__separator" />
        <div className="l-success__summary">
          <p>
            <span>Date</span>
            <span>
              {moment(transfer.date)
                .utcOffset("+0800")
                .format("DD MMM YYYY hh:mm:ss")
                .slice(0, 11)}
            </span>
          </p>
          <p>
            <span>Time</span>
            <span>
              {moment(transfer.time)
                .utcOffset("+0800")
                .format("DD MMM YYYY hh:mm:ss A")
                .slice(12, 24)}
            </span>
          </p>
          <p>
            <span>Transaction ID</span>
            <span>{transfer.transactionId}</span>
          </p>
          <p>
            <span>Reference No.</span>
            <span>{transfer.refNo}</span>
          </p>
          <p>
            <span>Transaction No.</span>
            <span>{transfer.transactionNo}</span>
          </p>
          <h3>
            <span>Total:</span>
            <span>
              RM{" "}
              <NumberFormat
                value={transfer.amount}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </span>
          </h3>
        </div>
        <div className="l-success__note">
          <img src={icons.infoPrimary} alt="Info" />
          <p>
            Switch Goal/Fund take up to 7 business days to be processed. Final
            fund value will be base on NAV selling price of the day you
            withdraw.
            <br />
            <br />A switching fee may apply. Final charges will be confirmed
            after the switch.{" "}
            <span onClick={() => setSalesChargeDisclaimerOpen(true)}>
              More info
            </span>
          </p>
        </div>
        <CustomButton
          text="Done"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          onClick={() => {
            history.push("/dashboard");
          }}
        />
      </div>
    </>
  );
};

const withConnect = connect(state => ({
  transfer: state.switchingReducer.transfer,
}));

export default compose(
  withRouter,
  withConnect
)(Success);
