import axios from "axios";

export const API_URL = {
  // dev:
  //   "https://sze1zslj59.execute-api.ap-southeast-1.amazonaws.com/mini/api/v1/smp"
  // local: "https://9de7-103-163-39-24.ngrok.io/api/v1",
  local:
    "https://cpy5w7t0dj.execute-api.ap-southeast-1.amazonaws.com/dev/api/v1",
  mp:
    process.env.REACT_APP_ENV.trim() === "development"
      ? process.env.REACT_APP_MP_SIT.trim()
      : process.env.REACT_APP_ENV.trim() === "uat"
      ? process.env.REACT_APP_MP_UAT.trim()
      : process.env.REACT_APP_MP_PROD.trim(),
  ppg:
    process.env.REACT_APP_ENV.trim() === "production"
      ? process.env.REACT_APP_PPG_PROD.trim()
      : process.env.REACT_APP_PPG_UAT.trim(),
  ppgLocal: `https://2e9b-103-163-39-24.ngrok.io/api/v2/service/checkout`,
  backURL:
    process.env.REACT_APP_ENV.trim() === "development"
      ? process.env.REACT_APP_BACKURL_SIT.trim()
      : process.env.REACT_APP_ENV.trim() === "uat"
      ? process.env.REACT_APP_BACKURL_UAT.trim()
      : process.env.REACT_APP_BACKURL_PROD.trim()
};

let BASE_URL;
// On Windows there's a trailing space that causes this IF to fail, so trim the stupid thing.
if ((process.env.REACT_APP_ENV || "").trim() === "development") {
  BASE_URL = API_URL.dev;
} else if ((process.env.REACT_APP_ENV || "").trim() === "sit") {
  BASE_URL = API_URL.sit;
} else if ((process.env.REACT_APP_ENV || "").trim() === "staging") {
  BASE_URL = API_URL.uat;
} else if ((process.env.REACT_APP_ENV || "").trim() === "production") {
  BASE_URL = API_URL.production;
}

var instance = null;

function makeInstance() {
  if (instance) {
    return instance;
  } else {
    instance = axios.create({
      baseURL: BASE_URL
    });
    return instance;
  }
}

var axiosInstance = makeInstance();

export const setAuthHeader = accessToken => {
  // axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
};

export const resetAuthHeader = () => {
  delete axiosInstance.defaults.headers.common.Authorization;
};

export default axiosInstance;
