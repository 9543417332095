import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";

import { StepLabel, Message } from "../../components";
import styles from "./styles";

const Inbox = props => {
  const { history, classes } = props;

  const handleClickMessage = () => {
    let random = Math.floor(Math.random() * 100);

    history.push(`/inbox/${random}`);
  };

  return (
    <div>
      <div className={`${classes.row} ${classes.nav}`}>
        <StepLabel title="Inbox" onClick={() => history.back()} />
        <Typography className={`${classes.baseFont} ${classes.mark}`}>
          Mark All as Read
        </Typography>
      </div>
      <>
        <Message isRead={false} onClick={handleClickMessage} />
        <Message isRead={false} onClick={handleClickMessage} />
        <Message isRead={true} onClick={handleClickMessage} />
        <Message isRead={true} onClick={handleClickMessage} />
      </>
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles)
)(Inbox);
