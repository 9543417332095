import {
  GET_SUBACOCUNT_DETAIL,
  GET_SUBACOCUNT_DETAIL_SUCCESS,
  GET_SUBACOCUNT_DETAIL_FAILED,
  RESET_SUBACCOUNT_STATE
} from "./constant";

export function getSubAccount(data) {
  return {
    type: GET_SUBACOCUNT_DETAIL,
    payload: data
  };
}

export function getSubAccountSuccess(data) {
  return {
    type: GET_SUBACOCUNT_DETAIL_SUCCESS,
    payload: data
  };
}

export function getSubAccountFailed(data) {
  return {
    type: GET_SUBACOCUNT_DETAIL_FAILED,
    payload: data
  };
}

export function resetSubAccountState() {
  return {
    type: RESET_SUBACCOUNT_STATE
  };
}
