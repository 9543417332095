import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import { icons } from "../../constants";
import styles from "./styles";

const Message = ({ isRead, onClick, classes }) => {
  return (
    <div className={classes.column} onClick={onClick}>
      <div
        className={`${classes.row} ${classes.inboxContent} ${!isRead &&
          classes.unreadInbox}`}
      >
        <div className={classes.inbox}>
          <div className={classes.row}>
            {!isRead && (
              <img
                src={icons.notificationUnread}
                alt="notification-unread"
                style={{ marginRight: "8px" }}
              />
            )}
            <Typography className={`${classes.baseFont} ${classes.inboxTitle}`}>
              Lorem ipsum dolor sit amet
            </Typography>
          </div>
          <Typography className={`${classes.baseFont} ${classes.content}`}>
            Lorem ipsum dolor sit amet, autem splendide evertitur est ex. An
            putent possit argumentum mea.
          </Typography>
          <Typography className={`${classes.baseFont} ${classes.date}`}>
            14 Sep 2021 • 06.00PM
          </Typography>
        </div>
        <div>
          <img src={icons.arrowRight} alt="arrow-right" />
        </div>
      </div>
      <div className={classes.line} />
    </div>
  );
};

export default compose(withStyles(styles))(Message);
