import React, { useEffect } from "react";

const withProfileRedirect = WrappedComponent => {
  return function WithProfileRedirect(props) {
    const { profile, history } = props;
    const storageList = [
      "reqTokenDate",
      "persist:root",
      "mpUserInfo",
      "isNewUser",
      "currentTab",
      "mpAccessToken",
      "mpUserStatus",
    ];
    const cleanStorage = () => {
      for (let i = 0; i < storageList.length; i++) {
        localStorage.removeItem(storageList[i]);
      }
    };

    useEffect(() => {
      if (profile === null) {
        cleanStorage();
        history.push("/");
      }
    }, [profile, history]);

    if (profile === null) {
      return null;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withProfileRedirect;
