import {
  GET_ACCOUNT_STATUS_V2,
  GET_ACCOUNT_STATUS_V2_SUCCESS,
  GET_ACCOUNT_STATUS_V2_FAILED,
  GET_ACC_STS_SUCCESS,
  GET_ACC_STS_FAILED,
  GET_CUSTOMER_PORTFOLIO,
  GET_CUSTOMER_PORTFOLIO_SUCCESS,
  GET_CUSTOMER_PORTFOLIO_FAILED,
  GET_SUBACCOUNT_INVEST,
  GET_SUBACCOUNT_INVEST_SUCCESS,
  GET_SUBACCOUNT_INVEST_FAILED,
  RESET_INVEST_SUBACCOUNT
} from "./constant";

const initialState = {
  customerPortfolio: [],
  loadingCustomerPortfolio: false,
  subAccStsInvestLoading: false,
  subAccStsInvest: {}
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOMER_PORTFOLIO:
      return {
        ...state,
        loadingCustomerPortfolio: true
      };
    case GET_CUSTOMER_PORTFOLIO_SUCCESS:
      return {
        ...state,
        customerPortfolio: action.payload,
        loadingCustomerPortfolio: false
      };
    case GET_CUSTOMER_PORTFOLIO_FAILED:
      return {
        ...state,
        loadingCustomerPortfolio: false
      };
    case GET_ACCOUNT_STATUS_V2:
      return {
        ...state,
        accountStatusLoading: true
      };
    case GET_ACCOUNT_STATUS_V2_SUCCESS:
      return {
        ...state,
        accountStatus: action.payload,
        accountStatusLoading: false
      };
    case GET_ACCOUNT_STATUS_V2_FAILED:
      return {
        ...state,
        accountStatusLoading: false
      };
    case GET_ACC_STS_SUCCESS:
      return {
        ...state,
        accStatusRun: action.payload
      };
    case GET_ACC_STS_FAILED:
      return {
        ...state,
        accStatusRun: action.payload
      };
    case GET_SUBACCOUNT_INVEST:
      return {
        ...state,
        subAccStsInvestLoading: true
      };
    case GET_SUBACCOUNT_INVEST_SUCCESS:
      return {
        ...state,
        subAccStsInvest: action.payload,
        subAccStsInvestLoading: false
      };
    case GET_SUBACCOUNT_INVEST_FAILED:
      return {
        ...state,
        subAccStsInvest: {},
        subAccStsInvestLoading: false
      };
    case RESET_INVEST_SUBACCOUNT:
      return {
        ...state,
        subAccStsInvest: {},
        subAccStsInvestLoading: false
      };

    default:
      return state;
  }
}
