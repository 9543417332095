import { select } from "redux-saga/effects";

//if want use this function, make sure already have timestampe state in their own reducer
export default function* isWithinTimeframe(dateNow, key) {
  const minute = process.env.REACT_APP_PROMO_LIST_TIMESTAMP;

  const lastRequestTimestamp = yield select(
    state => state.timestampReducer[key] || 0
  );

  const timeDifference = dateNow - lastRequestTimestamp;
  const timeIntervalInMillis = parseInt(minute, 10) * 60 * 1000;

  if (timeDifference >= timeIntervalInMillis) {
    return false;
  }
  return true;
}
