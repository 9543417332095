import React from "react";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import "./styles.scss";
export default function FooterSaveInvest(props) {
  const { list = [], children } = props;

  const handleNavigate = (link, name) => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      `Get Started - ${name} button`
    );
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(link),
    });
  };

  const openApp = (value, name) => {
    dataLayerGTM("click_tag", "Click", "Button", `Get Started - ${name}`);

    window.my.call("openAppLink", {
      url: value,
    });
  };
  return (
    <div className="footer-save-invest">
      <div>
        <ul>
          {list.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                item.action === "url"
                  ? handleNavigate(item.link, item.name)
                  : openApp(item.link, item.name);
              }}
            >
              {item.name}
            </li>
          ))}
        </ul>
        {children}
      </div>
    </div>
  );
}
