export const AUTH_USER = "AUTH_USER";
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_SIGN_UP = "AUTH_SIGN_UP";
export const UNAUTH_USER = "UNAUTH_USER";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const AUTH_ERROR = "AUTH_ERROR";
export const REFRESH_ACCESS_TOKEN_ENPOINT = "REFRESH_ACCESS_TOKEN_ENPOINT";

export const GET_TOKEN = "GET_TOKEN";
export const GET_TOKEN_SUCCESS = "GET_TOKEN_SUCCESS";
export const GET_TOKEN_FAILED = "GET_TOKEN_FAILED";
