import {
  GET_PROJECTED_RETURN,
  GET_PROJECTED_RETURN_SUCCESS,
  GET_PROJECTED_RETURN_FAILED,
  SET_PROFILE_INFOV2,
  STEP_STARTED,
  GET_TOTAL_UNIT_FUNDS,
  GET_TOTAL_UNIT_FUNDS_SUCCESS,
  GET_TOTAL_UNIT_FUNDS_FAILED,
  PROJECTED_RETURN_INVEST,
  PROJECTED_RETURN_INVEST_SUCCESS,
  PROJECTED_RETURN_INVEST_FAILED,
  UPDATE_CUSTOMER_DUPLICATE,
  UPDATE_CUSTOMER_DUPLICATE_SUCCESS,
  UPDATE_CUSTOMER_DUPLICATE_FAILED
} from "./constant";

export function updateCustomerDuplicate(payload, callback) {
  return {
    type: UPDATE_CUSTOMER_DUPLICATE,
    payload,
    callback
  };
}
export function updateCustomerDuplicateSuccess() {
  return {
    type: UPDATE_CUSTOMER_DUPLICATE_SUCCESS
  };
}

export function updateCustomerDuplicateFailed() {
  return {
    type: UPDATE_CUSTOMER_DUPLICATE_FAILED
  };
}

export function getTotalUnitFunds(payload) {
  return {
    type: GET_TOTAL_UNIT_FUNDS,
    payload
  };
}
export function getTotalUnitFundsSuccess(payload) {
  return {
    type: GET_TOTAL_UNIT_FUNDS_SUCCESS,
    payload
  };
}

export function getTotalUnitFundsFailed() {
  return {
    type: GET_TOTAL_UNIT_FUNDS_FAILED
  };
}

export function getProjectedReturn() {
  return {
    type: GET_PROJECTED_RETURN
  };
}

export function getProjectedReturnSuccess(data) {
  return {
    type: GET_PROJECTED_RETURN_SUCCESS,
    payload: data
  };
}

export function getProjectedReturnFailed(data) {
  return {
    type: GET_PROJECTED_RETURN_FAILED,
    payload: data
  };
}

export function setProfileInfoV2(payload) {
  return {
    type: SET_PROFILE_INFOV2,
    payload
  };
}

export function setStepStarted(payload) {
  return {
    type: STEP_STARTED,
    payload
  };
}

export function getProjectedReturnInvest() {
  return {
    type: PROJECTED_RETURN_INVEST
  };
}

export function getProjectedReturnInvestSuccess(data) {
  return {
    type: PROJECTED_RETURN_INVEST_SUCCESS,
    payload: data
  };
}

export function getProjectedReturnInvestFailed(data) {
  return {
    type: PROJECTED_RETURN_INVEST_FAILED,
    payload: data
  };
}
