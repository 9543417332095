import React, { useState, useEffect } from "react";
import { Typography, FormControl, TextField } from "@material-ui/core";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import { updateProfile, hideError } from "../../redux/action";

import {
  StepLabel,
  CustomButton,
  DefaultLoader,
  ErrorAlert
} from "../../../components";
import styles from "../styles";

const UpdatePostCode = props => {
  const {
    loading,
    isModalOpen,
    errorMessage,
    profile,
    updateProfile,
    hideError,
    history
  } = props;

  const [userInfo, setUserInfo] = useState(null);
  const [postCode, setPostCode] = useState("");
  const [isTax, setIsTax] = useState(false);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setPostCode(
        userInfo.contacts &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).postCode
          ? userInfo.contacts.find(
              contact => contact.contactTypeCd === "PERMANENT"
            ).postCode
          : ""
      );
      setIsTax(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? true
          : false
      );
    }
  }, [userInfo]);

  const handleUpdate = () => {
    // window.consoleLog(city);

    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: userInfo.investmentObjCd,
      employerName: userInfo.employerName,
      identities:
        isTax === true
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).citizenship //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              }
            ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        postCode: postCode, //R the updated
        city: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship //R
      }
    };

    // window.consoleLog(payload);
    updateProfile({ payloadData: payload, from: PROFILE });
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="Update postal code"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            Postal code
          </Typography>
          <TextField
            placeholder="Postal code"
            type="number"
            variant="outlined"
            fullWidth
            value={postCode}
            onChange={e => setPostCode(e.target.value)}
            error={!postCode || postCode.length > 6 ? true : false}
            helperText={
              !postCode
                ? "Postal Code is required"
                : postCode.length > 6
                ? "Maximum length for postal code is 6"
                : ""
            }
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" }
            }}
            FormHelperTextProps={{ style: { ...styles.baseFont } }}
          />
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!postCode || postCode.length > 6 ? true : false}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile
  }),
  {
    updateProfile,
    hideError
  }
);

export default compose(
  withRouter,
  withConnect
)(UpdatePostCode);
