import React from "react";
import {
  // Button,
  Dialog,
  // DialogActions,
  //DialogContent,
  // DialogTitle,
  //Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "./style.scss";
import { images } from "../../constants";

const StyledDialog = withStyles({
  scrollPaper: {
    padding: "0 16px",
  },
  paperFullWidth: {
    minWidth: "100%",
  },
  paperScrollPaper: {
    maxHeight: "100%",
    backgroundColor: "transparent",
  },
  root: {
    maxWidth: "23.5rem",
    marginInline: "auto",
  },
})(Dialog);

const GiveawayAlert = ({ handleClose, isOpen, sourceImg }) => {
  return (
    <StyledDialog
      open={isOpen}
      fullWidth
      PaperProps={{ style: { borderRadius: "0", boxShadow: "none" } }}
    >
      <div
        style={{
          position: "absolute",
          right: "0.5rem",
          top: "0.5rem",
          cursor: "pointer",
        }}
      >
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClose}
        >
          <path
            opacity="0.9"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0H32V32H0V0Z"
            fill="#032954"
          />
          <path
            d="M25.3337 6.6665L6.66699 25.3332"
            stroke="#DDF75B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.66699 6.6665L25.3337 25.3332"
            stroke="#DDF75B"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        {/* <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onClick={handleClose}
        >
          <path
            d="M20 4 4 20M4 4l16 16"
            stroke="#fff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
      </div>

      {/* <div className="c-giveaway-alert">
        <h1 className="c-giveaway-alert__title">Double Wow Giveaways</h1>
        <p className="c-giveaway-alert__desc">
          Deposit <span>RM500</span> or more in a single transaction for a
          chance to win exciting prizes such as an <span>iPhone 14</span>,{" "}
          <span>PlayStation 5</span> console, or a{" "}
          <span>RM200 JDSports Gift Card</span>.
          <br />
          <br />
          Don't miss out on this opportunity!
        </p>

        <div className="c-giveaway-alert__wrapper">
          <img src={images.bgGiveaways} alt="Giveways" />
        </div>
      </div> */}
      {/* <div className="c-improved-way">
        <h3 className="c-improved-way__title">
          An improved way to communicate our investment returns.
        </h3>
        <p className="c-improved-way__desc">
          We have enhanced how we communicate our returns for asset Class D
          funds. From now on, we will display the fund’s average total returns
          (capital appreciation and income distribution) since inception. We
          will update the returns monthly to give you a more accurate view of
          the performance. Please click 'Fund Info' to for more fund
          information.
        </p>
        <div className="c-improved-way__ok">
          <p onClick={handleClose}>OK</p>
        </div>
      </div> */}

      <div className="c-CNY">
        <img src={images[sourceImg]} alt="Popup Banner" />
      </div>
    </StyledDialog>
  );
};

export default GiveawayAlert;
