import { combineReducers } from "redux";
import { dropDownDataReducer } from "./dropdownDataReducers";
import { loadingReducer } from "./loadingReducer";
import { pagingReducer } from "./pagingReducer";
import { globalDataReducer } from "./globalReducer";

const globalReducer = combineReducers({
  dropDownDataReducer,
  loadingReducer,
  page: pagingReducer,
  globalData: globalDataReducer
});

export default globalReducer;
