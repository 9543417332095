import React, { useMemo, useState } from "react";
import { Icon } from "@material-ui/core";

const Rate = ({ count, rating, color, onRating }) => {
  const [hoverRating, setHoverRating] = useState(0);

  const getColor = index => {
    if (hoverRating >= index) {
      return color.filled;
    } else if (!hoverRating && rating >= index) {
      return color.filled;
    }

    return color.unfilled;
  };

  const starRating = useMemo(() => {
    return Array(count)
      .fill(0)
      .map((_, i) => i + 1)
      .map(idx => (
        <Icon
          key={idx}
          className="material-icons-outlined"
          onClick={() => onRating(idx)}
          style={{
            color: getColor(idx),
            fontSize: "40px",
            margin: "0 10px"
          }}
          onMouseEnter={() => setHoverRating(idx)}
          onMouseLeave={() => setHoverRating(0)}
        >
          star
        </Icon>
      ));
  }, [count, rating, hoverRating]);

  return <>{starRating}</>;
};

Rate.defaultProps = {
  count: 5,
  rating: 0,
  color: {
    filled: "#f5eb3b",
    unfilled: "#DCDCDC"
  }
};

export default Rate;
