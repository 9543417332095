import {
  SEND_RISK_ASSESSMENT,
  SEND_RISK_ASSESSMENT_SUCCESS,
  SEND_RISK_ASSESSMENT_FAILED,
  GET_RISK_ASSESSMENT,
  GET_RISK_ASSESSMENT_SUCCESS,
  GET_RISK_ASSESSMENT_FAILED,
  SEND_RISK_PROFILE,
  SEND_RISK_PROFILE_SUCCESS,
  SEND_RISK_PROFILE_FAILED,
  GET_RESULT_ASSESSMENT,
  GET_RESULT_ASSESSMENT_SUCCESS,
  GET_RESULT_ASSESSMENT_FAILED
} from "./constant";

const initialState = {
  questionnaire: [],
  riskProfileResult: {}
};

export default function riskAssessmentReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SEND_RISK_ASSESSMENT:
      return {
        ...state,
        sendriskAssessmentLoading: true
      };
    case SEND_RISK_ASSESSMENT_SUCCESS:
      return {
        ...state,
        riskProfileResult: payload,
        sendriskAssessmentLoading: false
      };
    case SEND_RISK_ASSESSMENT_FAILED:
      return {
        ...state,
        riskProfileResult: payload,
        sendriskAssessmentLoading: false
      };
    case GET_RISK_ASSESSMENT:
      return {
        ...state,
        getRiskAssessmentLoading: true
      };
    case GET_RISK_ASSESSMENT_SUCCESS:
      return {
        ...state,
        questionnaire: payload,
        getRiskAssessmentLoading: false
      };
    case GET_RISK_ASSESSMENT_FAILED:
      return {
        ...state,
        getRiskAssessmentLoading: false
      };
    case SEND_RISK_PROFILE:
      return {
        ...state,
        sendriskProfileLoading: true
      };
    case SEND_RISK_PROFILE_SUCCESS:
      return {
        ...state,
        sendriskProfileLoading: false,
        riskProfileResult: payload
      };
    case SEND_RISK_PROFILE_FAILED:
      return {
        ...state,
        sendriskProfileLoading: false
      };
    case GET_RESULT_ASSESSMENT:
      return {
        ...state,
        resultAssessmentLoading: true
      };
    case GET_RESULT_ASSESSMENT_SUCCESS:
      return {
        ...state,
        riskProfileResult: payload,
        resultAssessmentLoading: false
      };
    case GET_RESULT_ASSESSMENT_FAILED:
      return {
        ...state,
        riskProfileResult: {},
        resultAssessmentLoading: false
      };
    default:
      return state;
  }
}
