import { images } from "../../constants";

const styles = {
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  container: {
    flex: 1,
    minHeight: "100vh"
  },
  background: {
    backgroundImage: `url(${images.bgSavingDetails})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingBottom: "24px"
  },
  navigation: {
    justifyContent: "space-between",
    margin: "16px 16px 8px"
  },
  fundInfoContainer: {
    display: "flex",
    alignItems: "flex-start"
  },
  fundInfo: {
    marginLeft: "6px",
    color: "#fff"
  },
  saving: { margin: "24px 16px 0" },
  savingImg: {
    width: "56px",
    height: "56px",
    borderRadius: "4px",
    objectFit: "cover"
  },
  savingNameContainer: { marginLeft: "16px" },
  savingName: {
    fontWeight: "900",
    fontSize: "20px",
    lineHeight: 1.4,
    color: "#fff"
  },
  linearBar: {
    marginTop: "16px",
    borderRadius: "4px",
    height: "8px"
  },
  periodContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "4px"
  },
  periodMonth: {
    fontSize: "14px",
    lineHeight: 1.57
  },
  periodPercent: {
    fontWeight: "900",
    fontSize: "14px",
    lineHeight: 1.57
  },
  savingDetails: {
    margin: "16px",
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "8px"
  },
  promotion: {
    margin: "0 16px",
    padding: "8px 16px",
    backgroundColor: "#ddf75b",
    borderRadius: "4px"
  },
  promotionText: {
    fontSize: "14px",
    lineHeight: 1.31
  },
  btn: {
    position: "sticky",
    width: "100%",
    bottom: 0,
    padding: "16px",
    boxShadow: "0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    marginTop: "auto"
  },
  dotIcon: {
    fontSize: "16px",
    color: "#084c95",
    borderRadius: "100px",
    border: "solid 1px #084c95",
    marginLeft: "16px",
    padding: "16px",
    width: "48px",
    height: "48px"
  },
  popUpContainer: {
    padding: "16px 0 24px"
  },
  popUpTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    color: "#262d58",
    margin: "0 16px 16px"
  },
  popUpOptionContainer: {
    justifyContent: "space-between",
    padding: "16px 20px 14px"
  },
  popUpOption: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    letterSpacing: "0.32px"
  },
  heavy: {
    fontWeight: "900"
  },
  bg: {
    backgroundColor: "#f6f6f8",
    flex: 1,
    display: "flex",
    flexDirection: "column"
  },
  emptyContainer: {
    textAlign: "center"
  },
  emptyText: {
    lineHeight: 1.38,
    color: "#616367",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto 0"
  },
  allowRSP: {
    fontFamily: "FSElliotPro",
    lineHeight: 1.5,
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.32px",
    color: "#333333"
  },
  disableRSP: {
    fontFamily: "FSElliotPro",
    lineHeight: 1.5,
    fontSize: "16px",
    fontWeight: "700",
    letterSpacing: "0.32px",
    color: "#CDCDCD"
  }
};

export default styles;
