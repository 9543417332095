const checkENV = () => {
  if (process.env.REACT_APP_ENV.trim() === "development") {
    return process.env.REACT_APP_MP_SIT.trim();
  }
  if (process.env.REACT_APP_ENV.trim() === "uat") {
    return process.env.REACT_APP_MP_UAT.trim();
  }
  if (process.env.REACT_APP_ENV.trim() === "production") {
    return process.env.REACT_APP_MP_PROD.trim();
  }
};
const blacklist = [
  `${checkENV()}/smp/product-category/MPInvest`,
  `${checkENV()}/smp/sys-config/getting_started_projected_return`,
  `${checkENV()}/smp/sys-config/getting_started_projected_return_invest`
  // `${checkENV()}/smp/risk-profile/score-list`
];
export const checkBlacklistEndPoint = endpoint => {
  if (blacklist.includes(endpoint)) {
    return false;
  } else {
    return true;
  }
};
