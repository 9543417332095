import React, { useEffect, useState } from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { getCustomerGoals, hideError } from "../redux/action";
import { setCommingFrom, setBlockRequest } from "../../globalRedux/actions";
import {
  SavingItem,
  DefaultLoader,
  Dropdown,
  ErrorAlert,
} from "../../components";
import { icons } from "../../constants";
import styles from "./styles";
import "../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { setStatus } from "../../utils/functions/goalCombineTrx";
const GoalList = props => {
  const {
    loading,
    customerGoals,
    getCustomerGoals,
    history,
    classes,
    setCommingFrom,
  } = props;

  const [isOpenBottom, setOpenBottom] = useState(false);
  const [savingGoal, setSavingGoal] = useState([]);

  /**
   *  Get saving goal list from API
   */
  useEffect(() => {
    getCustomerGoals();
  }, []);

  /** Save saving goal into local state */
  useEffect(() => {
    if (customerGoals && customerGoals.length > 0) {
      sortGoalsByCreatedDate(setStatus(customerGoals));
    }
  }, [customerGoals]);

  const sortGoalsByCreatedDate = goals => {
    setSavingGoal([
      ...goals.sort((a, b) => moment(b.dateCreated) - moment(a.dateCreated)),
    ]);
  };

  /** Sort by most recent date */
  const handleSortRecent = () => {
    sortGoalsByCreatedDate(savingGoal);
    setOpenBottom(false);
  };

  /** Sort by completed */
  const handleSortCompleted = () => {
    setSavingGoal(prev => [
      ...prev.sort(
        (a, b) => Number(b.targetPercentage) - Number(a.targetPercentage)
      ),
    ]);
    setOpenBottom(false);
  };

  return (
    <div className={`column ${classes.container}`}>
      {loading && <DefaultLoader />}

      {/* Sort*/}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight / 3}
        isOpen={isOpenBottom}
        handleDismiss={() => setOpenBottom(false)}
      >
        <div className={`column ${classes.sortBy}`}>
          <Typography className={`${classes.baseFont} ${classes.popUpTitle}`}>
            Sort by
          </Typography>
          <div className={`${classes.row} ${classes.popUpOptionContainer}`}>
            <Typography
              color="textPrimary"
              className={`${classes.baseFont} ${classes.popUpOption}`}
              onClick={handleSortRecent}
            >
              Most recent
            </Typography>
            <img src={icons.arrowRight} alt="arrow-right" />
          </div>
          <div className={`${classes.row} ${classes.popUpOptionContainer}`}>
            <Typography
              color="textPrimary"
              className={`${classes.baseFont} ${classes.popUpOption}`}
              onClick={handleSortCompleted}
            >
              Completed
            </Typography>
            <img src={icons.arrowRight} alt="arrow-right" />
          </div>
        </div>
      </Dropdown>

      <div className={`${classes.row} ${classes.navigation}`}>
        <div className={classes.row}>
          <Icon
            className="material-icons-outlined"
            style={{ cursor: "pointer", color: "#0091DA" }}
            onClick={() => history.push("/dashboard")}
          >
            arrow_back
          </Icon>
          <Typography
            color="secondary"
            className={` ${classes.baseFont} ${classes.navigationLabel}`}
          >
            Financial goals
          </Typography>
        </div>
        <div
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Button", "Goal Filter");
            setOpenBottom(true);
          }}
        >
          <img src={icons.sliders} alt="sliders" />
        </div>
      </div>

      {/* Saving Item */}
      {savingGoal.length > 0 &&
        savingGoal.map(
          (
            {
              planAlias,
              targetAmount,
              totalCashIn,
              portfolio,
              id,
              cashInStatus,
              targetPercentage,
              product: { image },
              plan: { retailFlag },
              currentValue,
            },
            index
          ) => (
            <SavingItem
              key={index}
              cashInSts={cashInStatus}
              retailFlag={retailFlag}
              savingName={planAlias}
              totalSaving={totalCashIn}
              savingTarget={targetAmount}
              savingMonthBar={
                targetPercentage
                  ? Number(targetPercentage)
                  : ((portfolio.unitHeld * portfolio.fund.nav) / targetAmount) *
                    100
              }
              image={image}
              portfolio={portfolio}
              currentValue={currentValue}
              onClick={() => {
                dataLayerGTM("click_tag", "Click", "Link", `${planAlias}`);
                props.setBlockRequest(false);
                setCommingFrom("/goal-list");
                history.push(`/goal-list/${id}`);
              }}
            />
          )
        )}
    </div>
  );
};

const withConnect = connect(
  state => ({
    ...state.goal,
  }),
  { getCustomerGoals, hideError, setCommingFrom, setBlockRequest }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(GoalList);
