import {
  GET_FPX_BANK_LIST,
  SET_FPX_BANK_LIST,
  SET_FPX_LOADING,
  SET_SELECTED_BANK,
  SET_AMOUNT,
  POST_FPX_PAYMENT,
  SET_PAYMENT_SUCCESS,
  SET_SUCCESS,
  CREATE_SIGNATURE,
  POST_PAYMENT_REQUEST,
  SET_COSTUMER_DATA,
  SET_TRX_REF_NO,
  SET_PAY_CAT
} from "./constant";

export const getBankList = callback => {
  return {
    type: GET_FPX_BANK_LIST,
    callback
  };
};

export const setBankListToReducer = payload => {
  return {
    type: SET_FPX_BANK_LIST,
    payload
  };
};

export const setFpxLoading = payload => {
  return {
    type: SET_FPX_LOADING,
    payload
  };
};

export const setSelectedBank = payload => {
  return {
    type: SET_SELECTED_BANK,
    payload
  };
};

export const setUserAmount = payload => {
  return {
    type: SET_AMOUNT,
    payload
  };
};

export const postPaymentRequest = payload => {
  return {
    type: POST_FPX_PAYMENT,
    payload
  };
};

export const setPaymentSuccess = payload => {
  return {
    type: SET_PAYMENT_SUCCESS,
    payload
  };
};

export const setSuccess = payload => {
  return {
    type: SET_SUCCESS,
    payload
  };
};

export const paymentRequest = payload => {
  return {
    type: POST_PAYMENT_REQUEST,
    payload
  };
};

export const createSignature = payload => {
  return {
    type: CREATE_SIGNATURE
  };
};

export const setCostumerData = payload => {
  return {
    type: SET_COSTUMER_DATA,
    payload
  };
};

export const setTrxRefNo = payload => {
  return {
    type: SET_TRX_REF_NO,
    payload
  };
};

export const setPayCat = payload => {
  return {
    type: SET_PAY_CAT,
    payload
  };
};
