import React from "react";
import "./style.scss";

const RiskInfo = ({ handleClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={handleClick}
    >
      <path
        fillRule="evenodd"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        clipRule="evenodd"
      />
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M12 17v-4"
      />
      <circle cx="12" cy="8" r="2" fill="#fff" />
    </svg>
  );
};

const RiskCard = ({ level, riskIndex, handleRiskInfo }) => {
  return (
    <div className={`c-risk-card c-risk-card--${riskIndex}`}>
      <div>
        <p className="c-risk-card__title">Your Risk Profile</p>
        <h3 className="c-risk-card__investor">{level} investor</h3>
      </div>
      <RiskInfo riskIndex={riskIndex} handleClick={handleRiskInfo} />
    </div>
  );
};

export default RiskCard;
