import { Icon } from "@material-ui/core";
import * as React from "react";
import { InvestorCard, DefaultLoader } from "../../components";
import "./style.scss";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getScoreList } from "../fundSelection/redux/action";
import { sendRiskProfile } from "../riskAssessment/redux/action";
import { getResultAssessment } from "../riskAssessment/redux/action";

const RiskProfile = props => {
  const {
    sendRiskProfile,
    getScoreList,
    history,
    scoreList,
    scoreListLoading,
    sendriskProfileLoading,
    getResultAssessment
  } = props;
  const [risks, setRisks] = React.useState([]);

  React.useEffect(() => {
    getScoreList();
  }, []);

  React.useEffect(() => {
    const scoreListMapped = scoreList.map(curr => curr.nameEn);
    setRisks(scoreListMapped);
  }, [scoreList]);

  const handleSubmit = riskLevel => () => {
    const score = scoreList.find(score => score.nameEn.includes(riskLevel));
    const from = localStorage.getItem("from");

    if (from === "rsp") {
      getResultAssessment();
      localStorage.removeItem("from");
      history.go(-2);

      return;
    }

    if (score) {
      sendRiskProfile(score.lowerBound, directTo => history.push(directTo));
    }
  };

  if (scoreListLoading || sendriskProfileLoading) return <DefaultLoader />;

  return (
    <div className="wrapper">
      <div className="back-btn-wrapper">
        <Icon
          className="material-icons-outlined"
          onClick={() => history.back()}
        >
          arrow_back
        </Icon>
        <h2
          style={{ fontSize: "1.25rem", fontWeight: "700", marginLeft: "8px" }}
        >
          Pick your investment profile
        </h2>
      </div>
      <div className="risk-card-wrapper">
        {risks.map(item => (
          <InvestorCard
            key={item}
            riskLevel={item}
            riskIndex={risks.indexOf(item)}
            desc={scoreList.find(score => score.nameEn === item).descEn}
            handleSubmit={handleSubmit(item)}
          />
        ))}
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({ ...state.riskAssessmentReducer, ...state.fundSelectionReducer }),
  {
    getScoreList,
    getResultAssessment,
    sendRiskProfile
  }
);

export default compose(
  withRouter,
  withConnect
)(RiskProfile);
