import {
  CREATE_GOAL_DL,
  CREATE_GOAL_DL_SUCCESS,
  CREATE_GOAL_DL_FAILED,
  GET_CGOAL_DL,
  GET_CGOAL_DL_SUCCESS,
  GET_CGOAL_DL_FAILED,
  SET_DL_AMOUNT,
  DL_CHECKOUT,
  DL_CHECKOUT_SUCCESS,
  DL_CHECKOUT_FAILED,
  CLEAR_DL_CHECKOUT_ERROR,
} from "./constants";

const initialState = {
  createGoalDLLoading: false,
  deeplinkGeneralSaving: {},
  deeplinkCgoalList: [],
  deeplinkAmount: "",
  checkoutDLLoading: false,
  checkouDLError: false,
};

export default function deeplinkReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_GOAL_DL:
      return {
        ...state,
        createGoalDLLoading: true,
      };
    case CREATE_GOAL_DL_SUCCESS:
      return {
        ...state,
        deeplinkGeneralSaving: payload,
        createGoalDLLoading: false,
      };
    case CREATE_GOAL_DL_FAILED:
      return {
        ...state,
        createGoalDLLoading: false,
      };
    case GET_CGOAL_DL:
      return {
        ...state,
        createGoalDLLoading: true,
      };
    case GET_CGOAL_DL_SUCCESS:
      return {
        ...state,
        deeplinkCgoalList: payload,
        createGoalDLLoading: false,
      };
    case GET_CGOAL_DL_FAILED:
      return {
        ...state,
        createGoalDLLoading: false,
      };
    case SET_DL_AMOUNT:
      return {
        ...state,
        deeplinkAmount: payload,
      };
    case DL_CHECKOUT:
      return {
        ...state,
        checkoutDLLoading: true,
      };
    case DL_CHECKOUT_SUCCESS:
      return {
        ...state,
        checkoutDLLoading: true,
      };
    case DL_CHECKOUT_FAILED:
      return {
        ...state,
        checkoutDLLoading: false,
        checkouDLError: true,
      };
    case CLEAR_DL_CHECKOUT_ERROR:
      return {
        ...state,
        checkouDLError: false,
      };
    default:
      return state;
  }
}
