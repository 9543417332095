import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1278CC"
    },
    secondary: {
      main: "#262d58"
    },
    error: {
      main: "#be0712"
    },
    text: {
      primary: "#333"
    }
  },
  typography: {
    useNextVariants: true
  }
});

export default theme;
