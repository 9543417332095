const styles = {
  menu: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "32px 16px"
  },
  title: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    textTransform: "none"
  }
};

export default styles;
