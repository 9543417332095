import React from "react";
import { Icon } from "@material-ui/core";
import { icons } from "../../constants";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import "./styles.scss";

export default function DLItemCard(props) {
  const {
    itemName,
    link,
    annualReturn,
    assetClass,
    productType,
    details,
  } = props;

  return (
    <div className="dl-item-box">
      <div className="dl-item-name-ic">
        <div style={{ width: "90%" }}>
          <p className="dl-i-n-n">{itemName}</p>
        </div>
        <div
          className="dl-i-ic"
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Button",
              "Deeplink - Fund info"
            );
            window.my.navigateTo({
              url: "/pages/link/link?url=" + encodeURIComponent(link),
            });
          }}
        >
          <Icon
            className="material-icons-outlined"
            color="primary"
            style={{ fontSize: "20px" }}
          >
            info
          </Icon>
        </div>
      </div>

      {((Array.isArray(details) && details.length > 0) || annualReturn) && (
        <ul className="dl-item-detail">
          {annualReturn && (
            <li className="c-fund-card__item c-fund-card__item--first">
              {assetClass === "Mixed" ? (
                <>
                  <span className="dl-item-detail-percent">{annualReturn}</span>{" "}
                  {details[0]?.detail}
                </>
              ) : (
                <>
                  {productType?.toLowerCase() === "invest" && "Up to"}{" "}
                  <span className="dl-item-detail-percent">{annualReturn}</span>{" "}
                  returns per annum*
                  <img
                    src={icons.trendingUpGreen}
                    alt="stock up"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginLeft: "5px",
                      position: "relative",
                      top: "2px",
                    }}
                  />
                  <br />
                  *Gross returns excluding fees
                </>
              )}
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
