import { all, takeLatest, call, put } from "redux-saga/effects";
import { GET_REWARDS } from "./constants";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";
import { getRewardsSuccess, getRewardsFailed } from "./action";

function* getRewardsSaga({ payload }) {
  try {
    const { page = 1, size = 7, cifId } = payload;
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/campaigns/rewards/${cifId}`,
      {
        params: {
          page,
          size
        }
      }
    );
    const { data } = res;
    yield put(getRewardsSuccess(data));
  } catch (error) {
    yield put(getRewardsFailed());
  }
}

export function* rewardsSagaWorker() {
  yield all([takeLatest(GET_REWARDS, getRewardsSaga)]);
}
