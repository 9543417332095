const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  titleContainer: { padding: "24px 16px 0px" },
  title: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4, //28px
    color: "#000"
  },
  descContainer: { padding: "8px 16px 16px" },
  description: {
    fontSize: "16px",
    color: "#333"
  },
  link: { marginTop: "16px" },
  actionContainer: { padding: "0px 16px 24px", margin: 0 },
  cancel: {
    color: "#0076CF",
    marginTop: "16px",
    padding: 0,
    textTransform: "capitalize",
    lineHeight: "20px"
  },
  fullWidthBtn: {
    width: "100%",
    backgroundColor: "#0076CF",
    border: "none",
    borderRadius: "100px",
    color: "white",
    padding: "12px 0",
    marginTop: "16px",
    boxShadow: "0 12px 24px -10px rgba(16, 109, 186, 0.6)"
  }
};

export default styles;
