const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "8px",
    borderRadius: "4px",
    border: "solid 1px rgba(255, 255, 255, 0.05)",
    backgroundColor: "rgba(255, 255, 255, 0.08)"
  },
  labelContainer: { display: "flex", alignItems: "center" },
  label: {
    fontFamily: "FSElliotPro",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: 1.5,
    color: "#fff"
  },
  detail: {
    fontFamily: "FSElliotPro",
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: 1.31,
    color: "#fff"
  }
};

export default styles;
