import { ordinal } from "./ordinal";

/**
 * @typedef {object} Transaction
 * @property {string} amount
 * @property {string} type
 * @property {string} rspDate
 */

/**
 *
 * @param {Transaction} item
 * @param {"save" | "invest"} productType
 * @returns formatted processing text
 */
export const formatProcessingText = (item, productType) => {
  const { amount = "", type = "", rspDate = "" } = item;

  const expectedBusinessDayCopywriting =
    productType === "invest" ? "7 business days (T+7)." : "2 business days (T+2)."; 
  
  // eslint-disable-next-line default-case
  switch (type) {
    case "PENDING_DDA":
      return "Auto Cash In request is being processed and may take up to 2 business days.";
    case "rsp":
      return `Auto deposit of RM${amount} by ${ordinal(
        parseFloat(rspDate)
      )}/month`;
    case "Buy":
      return `RM${amount} Cash In is being processed and may take up to 2 business days.`;
    case "Sell":
      return `RM${amount} Cash Out is being processed and may take up to ${expectedBusinessDayCopywriting}`;
    case "FullSell":
      return `RM${amount} Cash Out is being processed and may take up to ${expectedBusinessDayCopywriting}`;
  }
};
