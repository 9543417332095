const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  form: {
    margin: "0 16px"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  label: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.43,
    marginBottom: "8px"
  },
  inputField: {
    padding: "12px 16px 12px 0",
    borderColor: "rgba(51, 51, 51, 0.2)"
  },
  helper: {
    margin: "4px 0 0 0",
    color: "#616367",
    fontSize: "14px",
    lineHeight: 1.57
  },
  btnContainer: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "fixed",
    width: "100%",
    bottom: 0
  },
  btnContainerV2: {
    padding: "0px 16px",
    width: "100%"
  },
  incorectMobileNo: {
    display: "flex",
    justifyContent: "center",
    margin: "24px 0px"
  },
  verificationResendContainer: {
    display: "flex",
    alignItems: "center",
    margin: "24px 0px"
  },
  resendBtn: {
    fontWeight: "700",
    color: "#0076CF"
  },
  btn: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "16px",
    display: "block",
    marginTop: "auto",
    backgroundColor: "#fff"
  },
  btnText: {
    color: "#0076CF",
    marginTop: "16px",
    padding: 0,
    textTransform: "capitalize",
    lineHeight: "20px"
  },
  prefix: {
    fontWeight: "700"
  },
  RM: {
    paddingLeft: "16px"
  },
  alertTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    color: "#000"
  },
  withdrawalDetails: {
    justifyContent: "space-between"
  },
  cashOutInfo: {
    display: "flex",
    flexDirection: "column",
    margin: "22px 16px 16px",
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.5,
    paddingLeft: "16px",
    paddingRight: "16px",
    color: "#616367"
  },
  cashOutText: {
    fontSize: "14px",
    lineHeight: "22px",
    color: "#333"
  },
  termCondition: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "8px 16px 16px"
  },
  otp: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: 1.25,
    color: "#262d58",
    width: "48px",
    height: "64px",
    borderRadius: "4px",
    border: "solid 1px rgba(51, 51, 51, 0.2)"
  },
  dropdownTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4
  }
};

export default styles;
