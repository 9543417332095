const styles = {
  column: {
    display: "flex",
    flexDirection: "column"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "4px",
    boxShadow: "0 1px 5px 3px rgba(0, 0, 0, 0.04)",
    marginBottom: "16px"
  },
  image: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    width: "100%",
    height: "173px",
    objectFit: "cover"
  },
  title: {
    color: "#000",
    marginBottom: "4px"
  },
  time: {
    fontSize: "12px",
    lineHeight: 1.83,
    color: "rgba(0, 0, 0, 0.4)"
  }
};

export default styles;
