import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";

const MessageItem = ({ classes }) => {
  return (
    <>
      <div className={classes.mediaContainer}>
        {/* <img
              src={images.dummy}
              alt="dummy"
              style={{ width: "100%", height: "100%", borderRadius: "4px" }}
            /> */}
        <video
          src="https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
          className={classes.media}
          controls
        />
      </div>
      <Typography className={`${classes.baseFont} ${classes.contentText}`}>
        Lorem ipsum dolor sit amet, autem splendide evertitur est ex. An putent
        possit argumentum mea. Cum brute graece pericula id, eam offendit
        sapientem te.
      </Typography>
      <Typography className={`${classes.baseFont} ${classes.contentText}`}>
        Lorem ipsum dolor sit amet, autem splendide evertitur est ex. An putent
        possit argumentum mea. Cum brute graece pericula id, eam offendit
        sapientem te.
      </Typography>
      <Typography className={`${classes.baseFont} ${classes.contentText}`}>
        Lorem ipsum dolor sit amet, autem splendide evertitur est ex. An putent
        possit argumentum mea. Cum brute graece pericula id, eam offendit
        sapientem te.
      </Typography>
    </>
  );
};

export default compose(withStyles(styles))(MessageItem);
