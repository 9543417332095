const styles = {
  container: {
    marginBottom: "4px",
    marginLeft: "16px",
    marginRight: "16px"
  },
  form: { marginBottom: "16px" },
  inputField: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    padding: "12px 16px",
    borderColor: "rgba(51, 51, 51, 0.2)"
  },
  period: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    padding: "12px 16px 12px 0",
    borderColor: "rgba(51, 51, 51, 0.2)"
  },
  error: {
    margin: "4px 0 0 0",
    color: "error",
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.57
  },
  label: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.43,
    marginBottom: "8px"
  },
  prefix: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5
  }
};

export default styles;
