import React from "react";
import "./style.scss";
import { CustomButton } from "..";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
const risk = ["Low", "Medium", "High"];

/**
 *
 * @param {{isPrimary?: boolean; className?: string; onClick?: () => void;}}
 */
export const InfoIcon = ({ isPrimary, className, onClick = () => {} }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 16V12"
        stroke="#ffffff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="8" r="1" fill="#ffffff" />
    </svg>
  );
};

const FormatMYRBold = ({ detail }) => {
  const words = detail.split(" ");
  const last = words[words.length - 1];
  const rewords = words.filter(w => w !== last).join(" ");

  return (
    <>
      {rewords}{" "}
      <span style={{ fontWeight: "bold" }}>{last}</span>
    </>
  );
};

const FundCard = ({
  index,
  isRecommended,
  isAwardWinning,
  isSyariah,
  isNewFund,
  annualReturn,
  fundName,
  fundInfoUrl,
  assetClass,
  onSelect,
  details,
}) => {
  const handleNavigate = link => {
    dataLayerGTM("click_tag", "Click", "Link", `Fund Listing - Fund info`);
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(link),
    });
  };
  const avgCopywriting = ["Mixed", "Mixed Assets", "Bond", "Equity"];
  return (
    <div className="c-fund-card">
      <div
        className={`c-fund-card__header c-fund-card__header--${index} ${
          isAwardWinning || isRecommended
            ? "c-fund-card__header--extra-top-padding"
            : ""
        }`}
      >
        {isRecommended && (
          <span className="c-fund-card__label">Top Recommended</span>
        )}
        {isAwardWinning && (
          <span className="c-fund-card__label">Award Winning Fund</span>
        )}
        <h3 className="c-fund-card__title">{fundName}</h3>
        <div className="c-fund-card__tablet-list">
          {isNewFund && (
            <span className="c-fund-card__tablet c-fund-card__tablet--new-fund">
              New Fund
            </span>
          )}
          {isSyariah && (
            <span className="c-fund-card__tablet c-fund-card__tablet--syariah">
              Shariah Compliant
            </span>
          )}
          <span
            className={`c-fund-card__tablet c-fund-card__tablet--risk-${index}`}
          >
            {risk[index]} Risk
          </span>
        </div>
      </div>
      {((Array.isArray(details) && details.length > 0) || annualReturn) && (
        <ul className="c-fund-card__body">
          {annualReturn && (
            <li className="c-fund-card__item c-fund-card__item--first">
              {avgCopywriting.includes(assetClass) ? (
                <>
                  <span className="c-fund-card__annual_return">
                    {annualReturn}
                  </span>{" "}
                  {details[0]?.detail}
                </>
              ) : (
                <>
                  Up to{" "}
                  <span className="c-fund-card__annual_return">
                    {annualReturn}
                  </span> returns per annum*{" "}
                  {"\n"}*Gross returns excluding fees
                </>
              )}
            </li>
          )}
          {/* first item need to sliced because we already showed that if there's annual return and asset class is in `avgCopywriting` var */}
          {(annualReturn && avgCopywriting.includes(assetClass)
            ? details.slice(1)
            : details
          )
            .sort((a, b) => a.index - b.index)
            .map((item, idx) => (
              <li key={idx} className="c-fund-card__item">
                {item.detail.includes("(MYR)") ? (
                  <FormatMYRBold detail={item.detail} />
                ) : (
                  item.detail
                )}
              </li>
            ))}
        </ul>
      )}

      <div className="c-fund-card__footer">
        <div
          onClick={() => {
            handleNavigate(fundInfoUrl);
          }}
        >
          <InfoIcon isPrimary className="c-fund-card__info-icon" />
          Fund Info
        </div>
        <CustomButton
          text="Select"
          color="#1278CC"
          backgroundColor="#fff"
          variant="outlined"
          style={{ maxWidth: "10rem", height: "3rem" }}
          onClick={onSelect}
        />
      </div>
    </div>
  );
};

export default FundCard;
