import React, { useState } from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { CATEGORY_SAVING, GENERAL_SAVING } from "../redux/constant";
import {
  getWithdrawalBankList,
  getWithdrawalBankAccount,
  getTransactionHistory,
  hideError,
  getWithdrawalResult,
  resetCategorySaving,
  resetGeneralSaving,
  resetMaxAmount,
} from "../redux/action";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import {
  StepLabel,
  CustomButton,
  CustomInput,
  Dropdown,
  DefaultLoader,
  Alert,
} from "../../components";
import styles from "./styles";
import { useEffect } from "react";
import { sendFundsCode } from "../fundSelection/redux/action";

const BankInformation = props => {
  const {
    profile,
    savingGoalDetails,
    generalSavingDetails,
    isFullRedemption,
    savingType,
    withdrawalBankList,
    withdrawalBankAccount,
    getWithdrawalBankAccount,
    getTransactionHistory,
    getWithdrawalResult,
    transactionHistory,
    history,
    match: {
      params: { amount },
    },
    classes,
    fund,
    sendFundsCode,
    resetMaxAmount,
    resetCategorySaving,
    resetGeneralSaving,
    loadingWithdrawalBankAccount,
    loadingTransactionHistory,
    getWithdrawalBankList,
    loadingWithdrawalBankList,
  } = props;

  // const profile = JSON.parse(localStorage.getItem("mpUserInfo"));
  const [bank, setBank] = useState({
    bankCd: "",
    bankName: "",
    accountNumber: "",
    fee: 0,
  });
  const [isBankListOpen, setBankListOpen] = useState(false);
  const [goalDetails, setGoalDetails] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [apiError, setApiError] = useState({
    message: "",
    isError: false,
  });
  const [disabled, setDisabled] = useState(false);
  const fee = withdrawalBankList.length > 0 ? withdrawalBankList[0].fee : 0;

  useEffect(() => {
    getTransactionHistory({ size: -1 });
    getWithdrawalBankList({ rsp: false });
  }, []);

  useEffect(() => {
    if (profile) {
      getWithdrawalBankAccount(profile.masterId);
    }
  }, [profile]);
  // }, []);

  useEffect(() => {
    if (withdrawalBankAccount.length > 0) {
      const lastTransaction = transactionHistory[0];
      if (lastTransaction) {
        const findedBankAccount = withdrawalBankAccount.find(
          el =>
            el.bankCode === lastTransaction.bankCd &&
            el.accountNo === lastTransaction.bankAccountNo
        );
        if (findedBankAccount) {
          setBankAccount(findedBankAccount);
        } else {
          setBankAccount(
            withdrawalBankAccount[withdrawalBankAccount.length - 1]
          );
        }
      }
    }
  }, [withdrawalBankAccount, transactionHistory]);

  useEffect(() => {
    if (bankAccount && withdrawalBankList.length > 0) {
      let bankInfo = withdrawalBankList.find(
        bank => bank.bankCd === bankAccount.bankCode
      );

      if (bankInfo) {
        setBank(prevState => ({
          ...prevState,
          bankCd: bankInfo.bankCdOrigin,
          bankName: bankInfo.name,
          fee: bankInfo.fee,
          accountNumber: bankAccount.accountNo,
        }));
      }
    }
  }, [bankAccount, withdrawalBankList]);

  /** Save saving goal data */
  useEffect(() => {
    if (savingType === CATEGORY_SAVING && savingGoalDetails) {
      setGoalDetails(savingGoalDetails);
    }
  }, [savingType, savingGoalDetails]);

  useEffect(() => {
    if (savingType === GENERAL_SAVING && generalSavingDetails) {
      setGoalDetails(generalSavingDetails);
    }
  }, [savingType, generalSavingDetails]);

  useEffect(() => {
    window.eventManager.on("WITHDRAWAL_SUCCESS", res => {
      resetCategorySaving();
      resetGeneralSaving();
      resetMaxAmount();
      sendFundsCode([]);
      getWithdrawalResult(res);
      history.push("/make-withdrawal/success");
    });

    window.eventManager.on("WITHDRAWAL_ERROR", res => {
      if (res) {
        setApiError(prev => ({ ...prev, message: res.message, isError: true }));
      }
      resetMaxAmount();
      setDisabled(false);
    });

    window.eventManager.on("WITHDRAWAL_PIN_ERROR", () => {
      setApiError(prev => ({
        ...prev,
        message: "Please try again",
        isError: true,
      }));
      setDisabled(false);
    });
    window.eventManager.on("WITHDRAWAL_PIN_CANCEL", () => setDisabled(false));
    window.eventManager.on("WITHDRAWAL_PIN_FAILED", () => setDisabled(false));
    window.eventManager.on("WITHDRAWAL_LOCK_FAILED", () => setDisabled(false));

    window.eventManager.on("WITHDRAWAL_PENDING", data => {
      /**
       * @remark see comment in file src/pages/withdrawal/pending.js on line 36
       */
      history.push("/make-withdrawal/pending", data);
    });

    return () => {
      window.eventManager.clearAllEvents();
    };
  }, []);

  const handleChangeBank = e => {
    const { name, value } = e.target;

    setBank(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    setDisabled(true);

    const payload = {
      cifId: profile.masterId,
      cifPlanId: (goalDetails && goalDetails.id) || (fund && fund.cifPlanId),
      bankCd: bank.bankCd, //bankCdOrigin
      bankAccountNo: bank.accountNumber,
      amountCcy: "MYR",
      fee: bank.fee || fee,
      feeCcy: "MYR",
      fundCd: fund && fund.code,
    };

    if (isFullRedemption) {
      payload.percentage = 100;
      payload.amount = 0;

      /**
       * @remark see comment in file src/pages/withdrawal/pending.js on line 36
       */
      payload.maxAmount = parseFloat(amount.replace(/[^0-9\.]/gi, ""));
    } else {
      payload.amount = parseFloat(amount.replace(/[^0-9\.]/gi, ""));
    }

    window.verifyUser({
      api: "call",
      event: "verifyUser",
      payload,
    });
  };

  const handleBack = () => {
    history.back();
  };

  if (
    loadingTransactionHistory ||
    loadingWithdrawalBankAccount ||
    loadingWithdrawalBankList
  )
    return <DefaultLoader />;

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Alert
        title="Something went wrong"
        description={apiError.message}
        isOpen={apiError.isError}
        isCancel={false}
        handleAction={() => setApiError(prev => ({ ...prev, isError: false }))}
      />

      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={isBankListOpen}
        handleDismiss={() => setBankListOpen(false)}
      >
        <div style={{ padding: "16px 16px 0" }}>
          <Typography
            color="secondary"
            style={{ ...styles.baseFont, ...styles.dropdownTitle }}
          >
            Bank list
          </Typography>
          {withdrawalBankList &&
            withdrawalBankList.length > 0 &&
            withdrawalBankList.map((bankItem, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setBank(prevState => ({
                      ...prevState,
                      bankCd: bankItem.bankCdOrigin,
                      bankName: bankItem.name,
                      fee: bankItem.custFee,
                    }));
                    setBankListOpen(false);
                  }}
                  style={{
                    ...styles.row,
                    justifyContent: "space-between",
                    margin: "32px 0",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    style={{ ...styles.baseFont, fontWeight: "700" }}
                  >
                    {bankItem.name}
                  </Typography>
                  {bank.bankName === bankItem.name && (
                    <Icon
                      className="material-icons-outlined"
                      style={{ cursor: "pointer", color: "#0091DA" }}
                    >
                      done
                    </Icon>
                  )}
                </div>
              );
            })}
        </div>
      </Dropdown>

      <div className={classes.container}>
        <StepLabel
          title="Insert bank information"
          containerStyles={{ margin: "16px 16px 22px" }}
          onClick={handleBack}
        />
        <form style={{ margin: "0 16px" }}>
          <CustomInput
            label="Bank name"
            name="bankName"
            value={bank.bankName}
            isDropdown={true}
            isEdit={true}
            onClick={() => setBankListOpen(true)}
          />
          <CustomInput
            label="Bank account number"
            name="accountNumber"
            type="number"
            value={bank.accountNumber}
            isEdit={true}
            onChange={handleChangeBank}
          />
        </form>

        {/* Button */}
        <div className={classes.btn}>
          <CustomButton
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278cc"
            disabled={(!bank.bankCd && !bank.accountNumber) || disabled}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    profile: state.goal.profile,
    savingType: state.goal.savingType,
    savingGoalDetails: state.goal.savingGoalDetails,
    generalSavingDetails: state.goal.generalSavingDetails,
    withdrawalBankList: state.goal.withdrawalBankList,
    withdrawalBankAccount: state.goal.withdrawalBankAccount,
    transactionHistory: state.goal.transactionHistory.data,
    isFullRedemption: state.goal.isFullRedemption,
    fund: state.fundSelectionReducer.fundsCode[0],
    loadingTransactionHistory: state.goal.loadingTransactionHistory,
    loadingWithdrawalBankAccount: state.goal.loadingWithdrawalBankAccount,
    loadingWithdrawalBankList: state.goal.loadingWithdrawalBankList,
  }),
  {
    getWithdrawalBankAccount,
    getTransactionHistory,
    getWithdrawalResult,
    hideError,
    sendFundsCode,
    resetMaxAmount,
    resetCategorySaving,
    resetGeneralSaving,
    getWithdrawalBankList,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withProfileRedirect
)(BankInformation);
