import React from "react";
import { Typography, Icon, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";

const GetStartedMenu = ({ text, link, isMP, handleNavigate, classes }) => {
  return (
    <Button
      className={`${classes.container}`}
      onClick={() => {
        dataLayerGTM("click_tag", "Click", "Link", `Get Started ${text}`);
        isMP
          ? handleNavigate()
          : link &&
            window.my.navigateTo({
              url: "/pages/link/link?url=" + encodeURIComponent(link),
            });
      }}
    >
      <Typography color="textPrimary" className={`${classes.text}`}>
        {text}
      </Typography>
      <Icon color="primary">keyboard_arrow_right</Icon>
    </Button>
  );
};

export default compose(withStyles(styles))(GetStartedMenu);
