import React, { useState, useEffect } from "react";
import "./style.scss";
export default function InputBasic(props) {
  const {
    title,
    btnOption,
    onChange,
    value,
    onButton,
    placeholder = "",
    type,
    isCurrency,
    isPromo,
    listPromoAction,
    error,
    errorMessage,
    promoValue,
    textButton,
  } = props;

  const additionalStyle =
    promoValue && !textButton
      ? { padding: "8px 0px 0px", border: "none" }
      : {
          padding: "8px 12px 8px 8px",
          border: "1px solid rgba(0, 0, 0, 0.23)",
        };

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    if (value === "" || value === 0 || !value) {
      setAmount(0);
      return;
    }
    setAmount(value);
  }, [value]);

  return (
    <>
      {(title || isPromo) && (
        <div className="ib-header">
          <p className="ib-title">{title}</p>
          {isPromo && (
            <p onClick={listPromoAction} className="ib-promo">
              view available promotion
            </p>
          )}
        </div>
      )}

      <div className="ib-container" style={additionalStyle}>
        <div className="ib-container__in">
          {!promoValue ? (
            <div
              className="ib-container__in__container"
              style={!isPromo ? { width: "100%" } : { width: "auto" }}
            >
              {isCurrency && (
                <p
                  className="ib-container__in__container__ccy"
                  style={{ color: amount === 0 ? "#9D9EA4" : "#333333" }}
                >
                  RM
                </p>
              )}

              <input
                value={value}
                className={`ib-container__in__container__${
                  !isPromo ? "input-np" : "input"
                }`}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
              />
            </div>
          ) : (
            <p className="ib-container__text-value">{promoValue.name}</p>
          )}

          {btnOption && (
            <button
              className={
                !textButton
                  ? "ib-container__in__btn"
                  : "ib-container__in__btn-promo"
              }
              onClick={onButton}
            >
              {btnOption}
            </button>
          )}
        </div>
      </div>
      {error && (
        <p className="ib-error">
          {errorMessage || "please enter the correct value!"}
        </p>
      )}
    </>
  );
}
