import {
  GET_SUBACOCUNT_DETAIL,
  GET_SUBACOCUNT_DETAIL_SUCCESS,
  GET_SUBACOCUNT_DETAIL_FAILED,
  RESET_SUBACCOUNT_STATE
} from "./constant";

const initialState = {};

export default function goalDetailReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBACOCUNT_DETAIL:
      return {
        ...state,
        subAccountLoading: true
      };
    case GET_SUBACOCUNT_DETAIL_SUCCESS:
      return {
        ...state,
        subAccountDetail: action.payload,
        subAccountLoading: false
      };
    case GET_SUBACOCUNT_DETAIL_FAILED:
      return {
        ...state,
        subAccountDetail: action.payload,
        subAccountLoading: false
      };
    case RESET_SUBACCOUNT_STATE:
      return {
        ...state,
        subAccountDetail: false,
        subAccountLoading: false
      };

    default:
      return state;
  }
}
