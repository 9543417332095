import { SET_INVEST_AMOUNT, SET_LAST_AMOUNT_INVEST } from "./constant";

const initialState = {
  investAmount: 0,
  totalFundsAmount: 0,
  fundsPayload: [],
  lastInvestAmount: ""
};

export default function investPortfolioReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_INVEST_AMOUNT:
      return {
        ...state,
        investAmount: payload.investAmount,
        totalFundsAmount: payload.totalFundsAmount,
        fundsPayload: payload.funds
      };
    case SET_LAST_AMOUNT_INVEST:
      return {
        ...state,
        lastInvestAmount: payload
      };

    default:
      return state;
  }
}
