import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  InvestorCard,
  RiskCard,
  DefaultLoader,
  AlertInfo,
  BackButton,
} from "../../components";

import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";

import {
  getFundList,
  getScoreList,
  resetErrorCreateFund,
} from "./redux/action";
import { resetInvestSubAccount } from "../dashboard/redux/actions";

import { resetSelectedPromo } from "../promoList/redux/action";
import { setLastAmountInvest } from "../fundSelectionStep2/redux/actions";
import { compose } from "underscore";

import { useIsFirstRender } from "../../utils/functions/useIsFirstRender";

import { groupFunds } from "../../utils/functions/groupFunds";
import "./style.scss";

const FundSelection = props => {
  const {
    history,
    scoreList,
    fundList = [],
    getFundList,
    getScoreList,
    fundListLoading,
    scoreListLoading,
    riskProfileResult,
    sendriskProfileLoading,
    resetInvestSubAccount,
    children,
    createFundLoadingV2,
    errorCreateFund,
    resetErrorCreateFund,
    resetSelectedPromo,
    setLastAmountInvest,
  } = props;
  const [tabs, setTabs] = useState(["conservative", "moderate", "aggressive"]);
  const [tab, setTab] = React.useState({
    current: "conservative",
    riskResult: "",
  });
  const [show, setShow] = React.useState({
    fundChangeAlert: false,
    fundInfoAlert: false,
  });

  const [funds, setFunds] = React.useState({
    conservative: [],
    moderate: [],
    aggresive: [],
  });

  const firstRender = useIsFirstRender();

  const desc = React.useRef("");

  useEffect(() => {
    getFundList();
    getScoreList();
    resetInvestSubAccount();
    resetSelectedPromo();
    setLastAmountInvest();
    sessionStorage.removeItem("stopRsp");
  }, []);

  useEffect(() => {
    const groupedFunds = groupFunds({
      funds: fundList,
      scoreList,
    });

    setTabs(Object.keys(groupedFunds));
    setFunds(groupedFunds);
  }, [scoreList, fundList]);

  useEffect(() => {
    const score = scoreList.find(
      score => score.nameEn.toLowerCase() === tab.riskResult
    );

    if (score) {
      desc.current = score.descEn;
    }
  }, [tab, scoreList]);

  useEffect(() => {
    let level = "conservative";
    const paths = history.location.pathname.split("/");
    const currentPath = paths[paths.length - 1];
    if (
      !sendriskProfileLoading &&
      Object.entries(riskProfileResult).length === 0
    ) {
      history.push("/invest-introduction");
      return;
    }
    if ("resultNameEn" in riskProfileResult) {
      level = riskProfileResult.resultNameEn;
    }

    setTab({
      current: currentPath,
      riskResult: level.toLowerCase(),
    });
  }, [riskProfileResult, sendriskProfileLoading]);

  const handleSubmitInvestorInfoPopup = () => {
    setShow(prev => ({ ...prev, fundInfoAlert: false }));
  };
  if (firstRender) return null;

  if (fundListLoading || scoreListLoading || createFundLoadingV2)
    return <DefaultLoader />;
  return (
    <>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCreateFund}
        isTimeout
        handleAction={() => {
          resetErrorCreateFund();
        }}
      />
      <InvestorCard
        riskIndex={tabs.indexOf(tab.riskResult)}
        riskLevel={tab.riskResult}
        desc={desc.current}
        handleSubmit={handleSubmitInvestorInfoPopup}
        onClose={handleSubmitInvestorInfoPopup}
        isPopup
        isOpen={show.fundInfoAlert}
      />
      <div
        style={{
          backgroundColor: "#f6f6f8",
          minHeight: "100vh",
          paddingBottom: "2rem",
        }}
      >
        <div className="l-fund-selection">
          <div className="l-fund-selection__wrapper">
            <BackButton
              handleBack={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Button",
                  `Fund listing - back button`
                );

                if (
                  history?.location?.state?.from?.includes("/risk-assessment")
                )
                  return history.push("/dashboard");

                history.back();
              }}
              className="c-back-btn--small"
              title="Step 1 of 3"
            />
            <h1 className="l-fund-selection__title">
              Pick your preferred fund
            </h1>
          </div>
          <RiskCard
            riskIndex={tabs.indexOf(tab.riskResult)}
            level={tab.riskResult}
            handleRiskInfo={() => {
              dataLayerGTM(
                "click_tag",
                "Click",
                "Link",
                `Risk Profile description link (the "i" icon)`
              );
              setShow(prev => ({ ...prev, fundInfoAlert: true }));
            }}
          />
        </div>
        <div className="c-tabs">
          {tabs.map(item => (
            <button
              className={`c-tabs__btn ${
                item === tab.current ? "active" : ""
              }`}
              onClick={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Link",
                  `Fund Listing - ${item} link`
                );
                setTab(prev => ({ ...prev, current: item }));
                history.replace(`/fund-selection/${item}`);
              }}
              key={item}
            >
              {item}
            </button>
          ))}
        </div>
        {children({
          funds,
          fundList,
          riskProfileResult,
          currentTab: tab.current,
        })}
        <div className="l-fund-selection__disclaimer">
          <h4>Disclaimer:</h4>
          <p>
            Performance figures are based on the fund’s average total returns
            (capital appreciation with income distribution) since inception.
            You may view the Fund Documents by clicking on ‘Fund Info’. Please
            note that past performance is not an indication of future
            performance.
            <br />
            <br />
            The listed holdings are not a recommendation for buying or
            selling. Other cash and derivative positions are not included in
            the top holdings list.
            <br />
            <br />
            Any application received after 1:00 p.m. will be processed on the
            next Business Day.
          </p>
        </div>
      </div>
    </>
  );
};

const withConnect = connect(
  state => ({
    ...state.fundSelectionReducer,
    ...state.riskAssessmentReducer,
  }),
  {
    getFundList,
    getScoreList,
    resetInvestSubAccount,
    resetErrorCreateFund,
    resetSelectedPromo,
    setLastAmountInvest,
  }
);

export default compose(
  withRouter,
  withConnect
)(FundSelection);
