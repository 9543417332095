import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import NumberFormat from "react-number-format";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";

import { CustomButton, DefaultLoader } from "../../components";
import { images } from "../../constants";
import styles from "./styles";
import { ordinal } from "../../utils/functions/ordinal";

const AutoDepositSuccess = props => {
  const { history, classes, rspDetail, loadingRspDetail } = props;

  if (loadingRspDetail) return <DefaultLoader />;

  return (
    <div className={classes.container}>
      {/* logo */}
      <div style={{ marginTop: "16px", marginLeft: "16px" }}>
        <img src={images.principalLogo} alt="principal-logo" />
      </div>

      {/* Submitted text */}
      <div style={{ margin: "24px 16px" }}>
        <Typography
          className={`${classes.baseFont}`}
          style={{
            fontSize: "20px",
            fontWeight: "700",
            color: "#00A29B",
            marginBottom: "16px"
          }}
        >
          Monthly auto Cash In submitted for processing...
        </Typography>
        <Typography
          color="textPrimary"
          className={classes.baseFont}
          style={{ marginBottom: "16px" }}
        >
          Your monthly auto Cash In request will be processed within 2 business
          days. Thank you.
        </Typography>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Auto Cash In date
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {ordinal(Number(rspDetail.dateOfMonth)) + " of each month"}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{
            justifyContent: "space-between",
            padding: "0 0 24px",
            borderBottom: "1px solid #9d9ea4"
          }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Auto Cash In amount
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            RM{" "}
            <NumberFormat
              value={rspDetail.amount}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
      </div>

      {/* Submitted details */}
      <div style={{ margin: "0 16px" }}>
        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Cash In Via
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            FPX Online Banking
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Date
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {moment().format("DD MMM YYYY")}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Time
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {moment().format("hh:mm:ss A")}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Reference Number
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            12094PMD100011
          </Typography>
        </div>
      </div>

      {/* Button */}
      <div
        style={{
          bottom: "0",
          padding: "16px 16px 24px",
          position: "sticky",
          marginTop: "auto"
        }}
      >
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          onClick={() => history.push("/dashboard")}
        />
      </div>
    </div>
  );
};

const withConnect = connect(state => ({
  withdrawalResult: state.goal.withdrawalResult,
  ...state.autoDepositReducer
}));

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(AutoDepositSuccess);
