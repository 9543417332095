import {
  SET_AUTO_DEPOSIT,
  SET_AUTO_DEPOSIT_SUCCESS,
  SET_DISABLE_RSP_FAILED,
  SET_DISABLE_RSP,
  SET_DISABLE_RSP_SUCCESS,
  CLEAR_ERROR_RSP,
  SET_EDIT_RSP,
  SET_EDIT_RSP_SUCCESS,
  SET_EDIT_RSP_FAILED,
  GET_RSP_DETAIL,
  GET_RSP_DETAIL_SUCCESS,
  GET_RSP_DETAIL_FAILED,
  SET_BACK_PAYLOAD,
  GET_RSP_MIN_AMOUNT,
  GET_RSP_MIN_AMOUNT_SUCCESS,
  GET_RSP_MIN_AMOUNT_FAILED,
  SET_RSP_PAYLOAD,
  MAKE_DEPOSIT,
  CLEAR_ERROR_CASHIN,
  SET_DEPOSIT_SUMMARY,
  SET_LAST_AMOUNT_SAVE
} from "./contant";

export const setRSPPayload = payload => {
  return {
    type: SET_RSP_PAYLOAD,
    payload
  };
};

export const setbackPayloadRsp = payload => {
  return {
    type: SET_BACK_PAYLOAD,
    payload
  };
};
export const getRspDetail = payload => {
  return {
    type: GET_RSP_DETAIL,
    payload
  };
};
export const getRspDetailSuccess = payload => {
  return {
    type: GET_RSP_DETAIL_SUCCESS,
    payload
  };
};

export const getRspDetailFailed = payload => {
  return {
    type: GET_RSP_DETAIL_FAILED,
    payload
  };
};

export const setEditRSP = (payload, callback) => {
  return {
    type: SET_EDIT_RSP,
    payload,
    callback
  };
};

export const setEditRSPSUccess = payload => {
  return {
    type: SET_EDIT_RSP_SUCCESS,
    payload
  };
};
export const setEditRSPFailed = payload => {
  return {
    type: SET_EDIT_RSP_FAILED,
    payload
  };
};

export const setAutoDeposit = payload => {
  return {
    type: SET_AUTO_DEPOSIT,
    payload
  };
};

export const setAutoDepositSuccess = payload => {
  return {
    type: SET_AUTO_DEPOSIT_SUCCESS,
    payload
  };
};
export const setDisableRSP = (payload, callback) => {
  return {
    type: SET_DISABLE_RSP,
    payload,
    callback
  };
};

export const setDisableRSPSuccess = payload => {
  return {
    type: SET_DISABLE_RSP_SUCCESS,
    payload
  };
};

export const setDisableRSPFailed = () => {
  return {
    type: SET_DISABLE_RSP_FAILED
  };
};

export const clearErrorRsp = () => {
  return {
    type: CLEAR_ERROR_RSP
  };
};

export const setUpLoadingRSP = () => {
  return {
    type: "rsp/setup-loading"
  };
};
export function getRSPMinAmount() {
  return {
    type: GET_RSP_MIN_AMOUNT
  };
}
export function getRSPMinAmountSuccess(data) {
  return {
    type: GET_RSP_MIN_AMOUNT_SUCCESS,
    payload: data
  };
}

export function getRSPMinAmountFailed() {
  return {
    type: GET_RSP_MIN_AMOUNT_FAILED
  };
}

export const makeDeposit = payload => {
  return {
    type: MAKE_DEPOSIT,
    payload
  };
};

export const clearErrorCashIn = () => {
  return {
    type: CLEAR_ERROR_CASHIN
  };
};

export const setDepositSummary = payload => {
  return {
    type: SET_DEPOSIT_SUMMARY,
    payload
  };
};

export const setLastAmountSave = payload => {
  return {
    type: SET_LAST_AMOUNT_SAVE,
    payload: !payload ? null : payload
  };
};
