import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";

import {
  StepLabel,
  StepCaption,
  MessageItem,
  CustomButton
} from "../../../components";
import styles from "../styles";

const InboxItem = props => {
  const { history, classes } = props;

  return (
    <div className={`${classes.column} ${classes.container}`}>
      <div style={{ margin: "16px" }}>
        <StepLabel onClick={() => history.back()} />
      </div>
      <div className={classes.content}>
        <StepCaption
          text="Lorem ipsum dolor sit amet"
          containerStyles={{ margin: "0" }}
        />
        <Typography className={`${classes.baseFont} ${classes.date}`}>
          14 Sep 2021 • 06.00PM
        </Typography>
        <div>
          <MessageItem />
        </div>
      </div>
      <div className={classes.btn}>
        <CustomButton
          text="Deposit Now"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
        />
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles)
)(InboxItem);
