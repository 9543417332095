import { Step, StepContent, StepLabel, Typography } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { DoneIcon, FailedIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";
import { formatUtcOffset, getStatus } from "./utils";

const Campaign = ({
  status,
  amount,
  unit,
  completedDate,
  details
}) => {
  const textStatus = getStatus(status);

  const isCompleted = textStatus === "Completed";
  const isFailed = textStatus === "Failed";

  return (
    <TransactionHistoryBase>
      <Step active>
        <StepLabel
          icon={isFailed ? <FailedIcon active /> : <DoneIcon active />}
        >
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: "#000000"
            }}
          >
            {textStatus}
          </Typography>
        </StepLabel>
        <StepContent>
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {isFailed
              ? "Your campaign bonus is unsuccessful."
              : "Your campaign bonus distribution is available."}
          </Typography>
          {isFailed ? (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {completedDate !== null && formatUtcOffset(completedDate)}
            </Typography>
          ) : (
            <div
              style={{
                marginTop: "8px",
                display: "flex"
              }}
            >
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit: {unit}
              </Typography>
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit Price: RM
                <NumberFormat
                  value={
                    details.length > 0 && details[0].actualUnitPrice !== null
                      ? details[0].actualUnitPrice
                      : parseFloat(amount / unit)
                  }
                  thousandSeparator
                  displayType="text"
                  decimalScale={4}
                  fixedDecimalScale
                />
              </Typography>
            </div>
          )}
          {isCompleted && (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {formatUtcOffset(completedDate)}
            </Typography>
          )}
        </StepContent>
      </Step>
    </TransactionHistoryBase>
  );
};

export default Campaign;
