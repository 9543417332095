import { all, takeLatest, call, put, select } from "redux-saga/effects";
import cogoToast from "cogo-toast";
import * as constant from "./constant";

import {
  getOccupationsSuccess,
  getOccupationsFailed,
  getNOBV2Success,
  getNOBV2Failed,
  getAnnualIncomeV2Success,
  getAnnualIncomeFailed,
  getCountryV2Success,
  getCountryV2Failed,
  getRaceV2Success,
  getRaceV2Failed,
  getStateV2Success,
  getStateV2Failed,
  getSourceOfFundV2Success,
  getSourceOfFundV2Failed,
  getSourceOfWealthSuccess,
  getSourceOfWealthFailed,
  getEstimatedNetWorthSuccess,
  getEstimatedNetWorthFailed,
  getNumberOfTransactionSuccess,
  getNumberOfTransactionFailed,
  getVolumeOfTransactionSuccess,
  getVolumeOfTransactionFailed,
  getMaritalStatusV2Success,
  getMaritalStatusV2Failed,
  getInvestmentObjectiveFailed,
  getInvestmentObjectiveSuccess
} from "./action";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

function* getRaceV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/race`);
    const { data } = res;

    yield put(getRaceV2Success(data));
  } catch (error) {
    yield put(getRaceV2Failed(error.message));
  }
}

function* getInvestmentObjective() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/investment-objective`);
    const { data } = res;

    yield put(getInvestmentObjectiveSuccess(data));
  } catch (error) {
    yield put(getInvestmentObjectiveFailed(error.message));
  }
}

function* getOccupationsSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/occupation`);
    const { data } = res;
    yield put(getOccupationsSuccess(data));
  } catch (error) {
    yield put(getOccupationsFailed(error.message));
  }
}

function* getNatureOfBusinessV2() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/nature-of-business`);
    const { data } = res;
    yield put(getNOBV2Success(data));
  } catch (error) {
    yield put(getNOBV2Failed(error.message));
  }
}

function* getAnnualIncomeV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/annual-income`);
    const { data } = res;
    yield put(getAnnualIncomeV2Success(data));
  } catch (error) {
    yield put(getAnnualIncomeFailed(error.message));
  }
}

function* getCountryV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/country`);
    const { data } = res;

    let result = [...data];
    const index = result.findIndex(e => e.code === "MY");
    result.unshift(result.splice(index, 1)[0]);
    yield put(getCountryV2Success(result));
  } catch (error) {
    yield put(getCountryV2Failed(error.message));
  }
}

function* getStateV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/state`);
    const { data } = res;
    yield put(getStateV2Success(data));
  } catch (error) {
    yield put(getStateV2Failed(error.message));
  }
}
function* getSourceOfFundV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/source-of-fund`);
    const { data } = res;

    yield put(getSourceOfFundV2Success(data));
  } catch (error) {
    yield put(getSourceOfFundV2Failed(error.message));
  }
}

function* getSourceOfWealthSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/source-of-wealth`);
    const { data } = res;
    yield put(getSourceOfWealthSuccess(data));
  } catch (error) {
    yield put(getSourceOfWealthFailed(error.message));
  }
}

function* getEstimatedNetWorthSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/estimated-net-worth`);
    const { data } = res;
    yield put(getEstimatedNetWorthSuccess(data));
  } catch (error) {
    yield put(getEstimatedNetWorthFailed(error.message));
  }
}

function* getNumberOfTransactionSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/number-of-transaction`);
    const { data } = res;
    yield put(getNumberOfTransactionSuccess(data));
  } catch (error) {
    yield put(getNumberOfTransactionFailed(error.message));
  }
}

function* getVolumeOfTransactionSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/volume-of-transaction`);
    const { data } = res;
    yield put(getVolumeOfTransactionSuccess(data));
  } catch (error) {
    yield put(getVolumeOfTransactionFailed(error.message));
  }
}

function* getMaritalStsV2Saga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/marital-status`);
    const { data } = res;

    yield put(getMaritalStatusV2Success(data));
  } catch (error) {
    yield put(getMaritalStatusV2Failed());
  }
}

export function* onBoardingWorkerSaga() {
  yield all([
    takeLatest(constant.GET_OCCUPATIONS, getOccupationsSaga),
    takeLatest(constant.GET_NATURE_BUSSINESS, getNatureOfBusinessV2),
    takeLatest(constant.GET_ANNUAL_INCOME_V2, getAnnualIncomeV2Saga),
    takeLatest(constant.GET_COUNTRY_V2, getCountryV2Saga),
    takeLatest(constant.GET_RACE_V2, getRaceV2Saga),
    takeLatest(constant.GET_STATE_V2, getStateV2Saga),
    takeLatest(constant.GET_SOURCE_OF_FUND_V2, getSourceOfFundV2Saga),
    takeLatest(constant.GET_SOURCE_OF_WEALTH, getSourceOfWealthSaga),
    takeLatest(constant.GET_ESTIMATED_NET_WORTH, getEstimatedNetWorthSaga),
    takeLatest(constant.GET_NUMBER_OF_TRANSACTION, getNumberOfTransactionSaga),
    takeLatest(constant.GET_VOLUME_OF_TRANSACTION, getVolumeOfTransactionSaga),
    takeLatest(constant.GET_MARITAL_STATUSV2, getMaritalStsV2Saga),
    takeLatest(constant.GET_INVESTMENT_OBJECTIVE, getInvestmentObjective)
  ]);
}
