export const fetchPost = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      throw response;
    }

    return response;
  } catch (e) {
    return e;
  }
};

export const fetchPostRSP = async (url, body, count) => {
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "x-counterReq": count
        //"x-dateReq": new Date()
      },
      body: JSON.stringify(body)
    });
    if (!response.ok) {
      throw response;
    }

    return response;
  } catch (e) {
    return e;
  }
};
