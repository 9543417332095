import {
  GET_FUND_LIST,
  GET_FUND_LIST_SUCCESS,
  GET_FUND_LIST_FAILED,
  GET_SCORE_LIST,
  GET_SCORE_LIST_SUCCESS,
  GET_SCORE_LIST_FAILED,
  SEND_FUNDS_CODE,
  SET_INVEST_MIN_AMOUNT,
  SELECTED_FUND_CUST,
  CREATE_FUND_V2,
  CREATE_FUND_SUCCESS_V2,
  CREATE_FUND_FAILED_V2,
  RESET_ERROR_CREATE_FUND,
  GET_FUND_DETAIL,
  GET_FUND_DETAIL_SUCCESS,
  GET_FUND_DETAIL_FAILED,
  SET_SELECTED_FUND_DETAIL
} from "./constant";


export function setSelectedFundDetail(payload) {
  return {
    type: SET_SELECTED_FUND_DETAIL,
    payload
  };
}


export function getFundDetail(payload, callback) {
  return {
    type: GET_FUND_DETAIL,
    payload,
    callback
  };
}

export function getFundDetailSuccess(data) {
  return {
    type: GET_FUND_DETAIL_SUCCESS,
    payload: data,
  };
}

export function getFundDetailFailed() {
  return {
    type: GET_FUND_DETAIL_FAILED,
  };
}

export const setIvestMinAmount = payload => {
  return {
    type: SET_INVEST_MIN_AMOUNT,
    payload,
  };
};

export function getFundList(callback) {
  return {
    type: GET_FUND_LIST,
    callback,
  };
}

export function getFundListSuccess(data) {
  return {
    type: GET_FUND_LIST_SUCCESS,
    payload: data,
  };
}

export function getFundListFailed() {
  return {
    type: GET_FUND_LIST_FAILED,
  };
}

export function getScoreList(callback) {
  return {
    type: GET_SCORE_LIST,
    callback,
  };
}

export function getScoreListSuccess(data) {
  return {
    type: GET_SCORE_LIST_SUCCESS,
    payload: data,
  };
}

export function getScoreListFailed() {
  return {
    type: GET_SCORE_LIST_FAILED,
  };
}

export function sendFundsCode(payload) {
  return {
    type: SEND_FUNDS_CODE,
    payload,
  };
}

export const setSelectedFundCust = payload => {
  return {
    type: SELECTED_FUND_CUST,
    payload,
  };
};

export const createFundV2 = (payload, callback) => {
  return {
    type: CREATE_FUND_V2,
    payload,
    callback,
  };
};

export const createFundSuccessV2 = payload => {
  return {
    type: CREATE_FUND_SUCCESS_V2,
    payload,
  };
};

export const createFundFailedV2 = () => {
  return {
    type: CREATE_FUND_FAILED_V2,
  };
};

export const resetErrorCreateFund = () => {
  return {
    type: RESET_ERROR_CREATE_FUND,
  };
};
