import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { BottomNav, Alert, Tabs } from "../../components";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { getCampaign } from "../redux/action";
import PullToRefresh from "react-simple-pull-to-refresh";
import withProfileRedirect from "../../containers/App/withProfileRedirect";
import "../styles/index.scss"; // global styles
import "./style.scss";
import styles from "./styles";
import { setCommingFrom, setStep } from "../../globalRedux/actions";
import { setbackPayloadRsp } from "../deposit/redux/action";
import { resetSubAccountState } from "../goalDetails/redux/actions";
import {
  checkEcddStatus,
  getNewsFeed,
  resetData,
  resetCategorySaving,
  resetGeneralSaving,
  resetMaxAmount,
} from "../redux/action";
import { sendFundsCode } from "../fundSelection/redux/action";
import { useEcddChecking } from "../../utils/hooks";

const tabs = [
  {
    id: 1,
    name: "Save",
  },
  {
    id: 2,
    name: "Invest",
  },
];

const Dashboard = props => {
  const {
    profile,
    history,
    loading,
    newsFeed,
    ecddStatus,
    getNewsFeed,
    checkEcddStatus,
    setStep,
    setbackPayloadRsp,
    resetSubAccountState,
    setCommingFrom,
    sendFundsCode,
    resetCategorySaving,
    resetGeneralSaving,
    getCampaign,
    resetMaxAmount,
    campaignList = { save: [], invest: [] },
    children,
  } = props;
  // const profile = JSON.parse(localStorage.getItem("mpUserInfo"));

  const [isLoading, setIsLoading] = useState({
    render: true,
    subAccountSts: false,
  });
  const [currentTab, setCurrentTab] = useState(() => {
    const local = JSON.parse(localStorage.getItem("currentTab"));
    if (local) return local;
    return { id: 1, name: "Save" };
  });
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
  });

  const masterIdRef = useRef(null);
  const firstRender = useRef(true);

  useEffect(() => {
    if (profile && profile.masterId) {
      if (
        masterIdRef.current !== null &&
        masterIdRef.current !== profile.masterId
      ) {
        resetData();
        localStorage.clear();
        history.push("/");
      } else {
        if (firstRender.current === false) {
          setIsLoading({ ...isLoading, render: false });
        }
        firstRender.current = false;
      }

      masterIdRef.current = profile.masterId;
    }
  }, [profile]);
  // }, []);

  useEffect(() => {
    localStorage.setItem("currentTab", JSON.stringify(currentTab));
  }, [currentTab]);

  useEffect(() => {
    checkEcddStatus(profile.masterId);
    getNewsFeed();
    setStep("/dashboard");
    resetSubAccountState();
    resetCategorySaving();
    resetGeneralSaving();
    resetMaxAmount();
    sendFundsCode([]);
    getCampaign();
  }, []);

  const handleRefresh = async () => {
    await new Promise(resolve => setTimeout(resolve, 500));

    localStorage.removeItem("lastNotifyGiveaway");
    localStorage.removeItem("persist:root");
    window.location.reload();
  };

  const handleViewAllNews = () => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Link",
      "Dashboard article view all button"
    );
    history.push("/news-list");
  };

  return (
    <>
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />

      <div
        style={{
          ...styles.column,
          backgroundColor: "#F6F6F8",
          minHeight: "100vh",
        }}
      >
        {" "}
        <PullToRefresh
          onRefresh={handleRefresh}
          pullDownThreshold={44}
          className="pd-refresh"
        >
          <Tabs className="c-tab--bg-blueish c-tab--with-label">
            {tabs.map((tab, idx) => (
              <Tabs.Item
                onClick={() => {
                  setCurrentTab(tab);
                  history.push(`/dashboard/${tab.name}`);
                }}
                selected={tab.id === currentTab.id}
                key={idx}
              >
                {tab.id === 2 ? <span>{tab.name}</span> : tab.name}
              </Tabs.Item>
            ))}
          </Tabs>
          {children({
            profile,
            loading,
            newsFeed,
            setbackPayloadRsp,
            setCommingFrom,
            handleViewAllNews,
            handleCheckEcdd,
            campaignsSave: campaignList.save,
            campaignsInvest: campaignList.invest,
          })}
        </PullToRefresh>
        <div
          style={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            display: "inline-block",
            marginTop: "auto",
            zIndex: "3",
          }}
        >
          <BottomNav />
        </div>
      </div>
    </>
  );
};

const withConnect = connect(
  state => ({
    profile: state.goal.profile,
    loading: state.goal.loading,
    newsFeed: state.goal.newsFeed,
    ecddStatus: state.goal.ecddStatus,
    campaignList: state.goal.campaignList,
  }),
  {
    resetData,
    getNewsFeed, // API
    setStep,
    checkEcddStatus, // API
    setbackPayloadRsp,
    resetSubAccountState,
    setCommingFrom,
    resetCategorySaving,
    resetGeneralSaving,
    resetMaxAmount,
    sendFundsCode,
    getCampaign, //API
  }
);

export default compose(
  withRouter,
  withConnect,
  withProfileRedirect
)(Dashboard);
