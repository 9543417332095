import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Icon,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import NumberFormat from "react-number-format";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import BonusDistribution from "./BonusDistribution";
import Buy from "./Buy";
import Campaign from "./Campaign";
import CoolingOff from "./CoolingOff";
import Dividend from "./Dividend";
import Sell from "./Sell";
import Transfer from "./Transfer";
import SwitchTrx from "./Switch";
import styles from "./styles";
import {
  decideAmount,
  getStatus,
  getStatusSellCollOff,
  getTransactionType,
  getRefundStatusText,
} from "./utils";

const StyledExpansionPanel = withStyles({
  root: {
    border: 0,
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
})(ExpansionPanel);

const StyledExpansionPanelSummary = withStyles({
  root: {
    margin: 0,
    padding: "0 16px",
  },
  expanded: {},
  content: {
    gap: "15px",
    backgroundColor: "#fff",
    borderRadius: "4px",
    justifyContent: "space-between",
    "&$expanded": {
      margin: "16px 0",
    },
  },
  expandIcon: {
    marginLeft: "8px",
    padding: 0,
    position: "absolute",
    top: "23px",
    right: 16,
    "&$expanded": {
      top: "28px",
    },
  },
})(ExpansionPanelSummary);

const getRefundName = val => {
  if (Array.isArray(val) && val.length > 0) {
    let refundName = "-";
    const switchOutDetail = val.find(detail => detail.type === "SwitchOut");
    const switchAllDetail = val.find(detail => detail.type === "SwitchAll");

    if (switchOutDetail) {
      refundName = switchOutDetail.planAlias;
    }
    if (switchAllDetail) {
      refundName = switchAllDetail.planAlias;
    }
    return refundName;
  }
};

const getExpansionSummary = ({
  amount,
  submittedDate,
  processingDate,
  completedDate,
  method,
  fee,
  unit,
  status,
  details,
  coolOffAmt,
  salesChargeAmount,
  salesChargeCcy,
  salesChargePercent,
  cashOutamtSell,
  goalName,
}) => {
  return {
    Dividend: (
      <Dividend
        status={status}
        amount={amount}
        unit={unit}
        completedDate={completedDate}
        details={details}
      />
    ),
    Bonus: (
      <BonusDistribution
        status={status}
        amount={amount}
        unit={unit}
        completedDate={completedDate}
        details={details}
      />
    ),
    CoolOff: (
      <CoolingOff
        status={status}
        method={method}
        amount={amount}
        coolOffAmt={coolOffAmt}
        fee={fee}
        unit={unit}
        completedDate={completedDate}
        details={details}
      />
    ),
    Sell: (
      <Sell
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        details={details}
        cashOutamtSell={cashOutamtSell}
      />
    ),
    ManSell: (
      <Sell
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        details={details}
        cashOutamtSell={cashOutamtSell}
        isManSell
      />
    ),
    FullSell: (
      <Sell
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        details={details}
        cashOutamtSell={cashOutamtSell}
        isFullSell
      />
    ),
    Campaign: (
      <Campaign
        status={status}
        amount={amount}
        unit={unit}
        completedDate={processingDate}
        details={details}
      />
    ),
    Buy: (
      <Buy
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
      />
    ),
    RSPBuy: (
      <Buy
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        details={details}
        isRsp
      />
    ),
    TransferOut: (
      <Transfer
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
      />
    ),
    TransferIn: (
      <Transfer
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
        isTransferIn
      />
    ),
    SwitchIn: (
      <SwitchTrx
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
        isSwitchIn
        planName={getRefundName(details)}
      />
    ),
    SwitchOut: (
      <SwitchTrx
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
        planName={goalName}
      />
    ),
    SwitchAll: (
      <SwitchTrx
        status={status}
        method={method}
        amount={amount}
        fee={fee}
        unit={unit}
        submittedDate={submittedDate}
        processingDate={processingDate}
        completedDate={completedDate}
        salesChargeAmount={salesChargeAmount}
        salesChargeCcy={salesChargeCcy}
        salesChargePercent={salesChargePercent}
        details={details}
        planName={goalName}
      />
    ),
  };
};

const TransactionHistoryDate = ({
  transactionType,
  goalName,
  amount,
  submittedDate,
  processingDate,
  completedDate,
  method,
  fee,
  unit,
  status,
  details,
  coolOffAmt,
  actualAmount,
  salesChargeAmount,
  salesChargeCcy,
  salesChargePercent,
  cashOutamtSell,
}) => {
  const isPlusTransaction =
    transactionType === "Dividend" ||
    transactionType === "Bonus" ||
    transactionType === "Buy" ||
    transactionType === "RSPBuy" ||
    transactionType === "Campaign" ||
    transactionType === "TransferIn" ||
    transactionType === "SwitchIn";

  const isGreenIsh =
    transactionType === "Buy" ||
    transactionType === "RSPBuy" ||
    transactionType === "TransferIn" ||
    transactionType === "SwitchIn";
  const isBlueIsh =
    transactionType === "Dividend" ||
    transactionType === "Bonus" ||
    transactionType === "Campaign";

  const isCashout =
    transactionType === "Sell" ||
    transactionType === "FullSell" ||
    transactionType === "ManSell";

  const isTransfer =
    transactionType === "TransferIn" || transactionType === "TransferOut";

  const isSwitch =
    transactionType === "SwitchIn" ||
    transactionType === "SwitchOut" ||
    transactionType === "SwitchAll";
  const totalTransactionInRinggit = `${isPlusTransaction ? "+" : "-"} RM`;
  const isFailedSwitchIn = transactionType === "SwitchIn" && status === "9";
  const expansionSummary = getExpansionSummary({
    amount,
    submittedDate,
    processingDate,
    completedDate,
    method,
    fee,
    unit,
    status,
    details,
    coolOffAmt,
    salesChargeAmount,
    salesChargeCcy,
    salesChargePercent,
    cashOutamtSell,
    goalName,
  });

  const RefundStatus = () => {
    return (
      <span style={{ ...styles.refundStatus }}>
        {getRefundStatusText(status, transactionType, details)}
      </span>
    );
  };

  return (
    <div
      style={{ marginBottom: "16px" }}
      onClick={() =>
        dataLayerGTM(
          "click_tag",
          "Click",
          "Expand",
          `Expand of ${getTransactionType(transactionType)}`
        )
      }
    >
      <div style={{ width: "100%" }}>
        <StyledExpansionPanel>
          <StyledExpansionPanelSummary
            expandIcon={
              <Icon
                className="material-icons-outlined"
                style={{ cursor: "pointer", color: "#0091da" }}
              >
                expand_more
              </Icon>
            }
          >
            <div style={{ ...styles.column }}>
              <Typography
                style={{ ...styles.baseFont, ...styles.transactionType }}
              >
                {getTransactionType(transactionType)}
              </Typography>
              <Typography
                style={{ ...styles.baseFont, ...styles.transactionName }}
              >
                {goalName}
              </Typography>
            </div>
            {/* heading */}
            <div
              style={{
                textAlign: "right",
                minWidth: "150px",
                whiteSpace: "nowrap",
              }}
            >
              <Typography
                style={{
                  ...styles.baseFont,
                  fontWeight: "700",
                  color: isGreenIsh ? "#18a29a" : isBlueIsh && "#1278CC",
                }}
              >
                {isFailedSwitchIn ? (
                  "-"
                ) : (
                  <>
                    {" "}
                    {totalTransactionInRinggit}
                    <NumberFormat
                      value={decideAmount({
                        transactionType,
                        details,
                        amount,
                        fee,
                        actualAmount,
                        cashOutamtSell,
                        status,
                      })}
                      thousandSeparator
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale={true}
                    />
                  </>
                )}
              </Typography>
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.transactionStatus,
                  color: (status === "9" || status === "11") && "#BE0712",
                }}
              >
                {isPlusTransaction || isTransfer || isSwitch
                  ? getStatus(status)
                  : getStatusSellCollOff({ status, isCashout })}
              </Typography>

              {isSwitch && status === "9" && <RefundStatus />}
            </div>
          </StyledExpansionPanelSummary>
          {expansionSummary[transactionType]}
        </StyledExpansionPanel>
      </div>
    </div>
  );
};

export default TransactionHistoryDate;
