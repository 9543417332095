import {
  put,
  call,
  all,
  takeLatest,
  takeEvery,
  select
} from "redux-saga/effects";
import { setProfileInfoV2 } from "../../../pages/getStarted/redux/actions";
import * as endpoints from "../../../utils/endpoints";
import * as api from "../../../utils/api";
import * as actions from "./actions";
import { startLoading, stopLoading } from "../../../globalRedux/actions";
import { signIn as signInConstant } from "../../../globalRedux/constants/loader";
import { showLoader, hideLoader } from "../../../store/actions/loader";
import { API_URL } from "../../../utils/axios";
import { setCostumerData, setPayCat } from "../../CPG/redux/action";
// import { requestClients } from "../../Clients/redux/actions";
// import { requestFunds } from "../../FundList/redux/actions";

const getToken = state => state.auth.accessToken;

function* signIn({ payload }) {
  // yield put(actions.resetFailedRequestProps());
  try {
    console.log(payload, "payload");
    yield put(startLoading());
    yield put(showLoader(signInConstant));
    const params = new URLSearchParams();
    params.append("xxx", "xxx");
    params.append("xxx", "xxx");
    params.append("xxx", "xxx");
    params.append("xxx", "xxx");
    const response = yield call(api.post, endpoints.SIGN_IN_ENDPOINT, params, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    const payloadSignin = {
      ...response.data
    };
    yield put(actions.userSignedIn(payloadSignin));

    const custData = yield call(api.get, `${API_URL.dev}/invoice/${payload}`);
    const { data } = custData;
    console.log(data);
    yield put(setCostumerData(data));

    const payCat = yield call(
      api.get,
      `${API_URL.dev}/payment-channel/${data.customerChannelCd}`
    );
    console.log(payCat);
    yield put(setPayCat(payCat.data));
  } catch (error) {
    yield put(actions.signInRequestFailedAction());
  } finally {
    yield put(hideLoader(signInConstant));
    yield put(stopLoading());
  }
}

// function* signIn({ payload }) {
//   // yield put(actions.resetFailedRequestProps());
//   try {
//     yield put(showLoader(signInConstant));
//     const params = new URLSearchParams();
//     params.append("username", payload.email);
//     params.append("password", payload.password);
//     params.append("grant_type", "password");
//     params.append("client_id", "b2c");
//     const response = yield call(api.post, endpoints.SIGN_IN_ENDPOINT, params, {
//       headers: {
//         "Content-Type": "application/x-www-form-urlencoded"
//       }
//     });

//     const decode = jwtDecode(response.data.access_token);
//     const { preferred_username = "" } = decode;

//     const passwordCreated = yield call(
//       api.get,
//       "/api/v1/users/notify-password-expiration",
//       {
//         headers: {
//           Authorization: `Bearer ${response.data.access_token}`
//         }
//       }
//     );

//     const payloadSignin = {
//       ...response.data,
//       passwordCreatedAt: passwordCreated.data.createdDate
//     };

//     yield put(actions.userSignedIn(payloadSignin));
//     yield put(stopLoading());
//     const response2 = yield call(
//       api.get,
//       `/api/v1/user/last-login?username=${preferred_username}`
//     );
//     yield put(actions.userLastLogin(response2.data));

//     // if (response && response.data && response.data.id) {
//     //   // yield put(requestClients(1, 10, "firstLoading"));
//     //   // yield put(stopLoading());
//     //   // yield put(requestFunds());
//     //   yield call(setAccessTokenToHeader);
//     //   yield put(requestDropDownData());
//     // }
//     // // yield put(stopLoading());
//   } catch (error) {
//     yield put(actions.signInRequestFailedAction());
//   } finally {
//     yield put(hideLoader(signInConstant));
//   }
// }

function* signUp({ payload }) {
  try {
    const response = yield call(api.post, endpoints.SIGN_UP_ENDPOINT, payload);
    yield put(actions.userSignedIn(response.data));
    yield call(setAccessTokenToHeader);
  } catch (error) {
    console.log("error: ", error.response);
    yield put(actions.signUpRequestFailedAction());
  }
}

function* signOut() {
  yield put(actions.userSignedOut());
  yield call(api.resetAuthHeader);
}

function* testAuth() {
  try {
    const token = yield call(setAccessTokenToHeader);
    if (token) {
      const response = yield call(api.get, endpoints.TEST_AUTH_ENDPOINT);
      yield put(actions.userSignedIn(response.data));
    }
  } catch (error) {
    console.log(error.response);
    yield put(actions.testAuthRequestFailedAction());
  }
}

function* setAccessTokenToHeader() {
  const token = yield select(getToken);
  yield call(api.setAuthHeader, token);
  return token;
}

function* getNewTokenSaga() {
  try {
    let oldToken = yield select(state => state.auth.MPToken);
    if (!oldToken) {
      throw new Error("fail get last token");
    }

    const response = yield call(
      api.get,
      `${API_URL.mp}/authorizations/applytoken`,
      {
        authCode: oldToken
      }
    );
    const { data } = response;
    localStorage.setItem("mpUserInfo", JSON.stringify(data.customerInfo));
    localStorage.setItem("mpToken", JSON.stringify(data.bearerToken));
    yield put(setProfileInfoV2(data.customerInfo));
    yield put(actions.getNewTokenSuccess(data.bearerToken));
  } catch (error) {
    yield put(actions.getNewTokenFailed());
  }
}

export default function* authSagas() {
  yield all([
    takeLatest(actions.signInAction, signIn),
    // takeLatest(actions.FpxSignInAction, fpxSignin),
    takeLatest(actions.signUpAction, signUp),
    takeLatest(actions.signOutAction, signOut),
    takeEvery(actions.testAuthAction, testAuth),
    takeEvery(actions.getNewToken, getNewTokenSaga)
  ]);
}
