import React from "react";
import { Typography, Step, StepLabel, StepContent } from "@material-ui/core";
import NumberFormat from "react-number-format";
import moment from "moment";
import styles from "./styles";
import { DoneIcon, FailedIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";

const BonusDistribution = ({
  status,
  amount,
  unit,
  completedDate,
  details
}) => {
  const detailsExist = details && Array.isArray(details) && details.length > 0;
   const isFailed = status === "9";

  return (
    <TransactionHistoryBase>
      {/* Completed */}
      <Step active>
        <StepLabel
          icon={isFailed ? <FailedIcon active /> : <DoneIcon active />}
        >
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: "#000000"
            }}
          >
            {isFailed ? "Failed" : "Completed"}
          </Typography>
        </StepLabel>
        <StepContent>
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {isFailed
              ? "Your Bonus Distribution is unsuccessful."
              : "Your Bonus Distribution is available."}
          </Typography>
          {isFailed ? (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {completedDate !== null &&
                moment(completedDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
            </Typography>
          ) : (
            <div
              style={{
                marginTop: "8px",
                display: "flex"
              }}
            >
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit: {unit}
              </Typography>
              <Typography style={{ ...styles.baseFont, ...styles.content }}>
                Unit Price: RM
                <NumberFormat
                  value={
                    detailsExist && details[0].actualUnitPrice !== null
                      ? details[0].actualUnitPrice
                      : parseFloat(amount / unit)
                  }
                  thousandSeparator
                  displayType="text"
                  decimalScale={4}
                  fixedDecimalScale
                />
              </Typography>
            </div>
          )}

          {(isFailed || status === "5") && (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {detailsExist &&
                moment(details[0].navDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
            </Typography>
          )}
        </StepContent>
      </Step>
    </TransactionHistoryBase>
  );
};

export default BonusDistribution;
