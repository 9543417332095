import { SET_COMMING_FROM, BLOCK_REQUEST, SET_CURRENT_ECDD_STATUS } from "../constants";

const initialState = { commingFrom: "/", blockRequest: false, isEcddCompleted: false };

export default function commingFromReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_COMMING_FROM:
      return {
        ...state,
        commingFrom: payload
      };
    case BLOCK_REQUEST:
      return {
        ...state,
        blockRequest: payload
      };
    case SET_CURRENT_ECDD_STATUS:
      return {
        ...state,
        isEcddCompleted: payload
      }
    default:
      return state;
  }
}
