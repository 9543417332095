// API REQUEST
export const GET_GOAL_REQUEST = "GET_GOAL_REQUEST";
export const GET_GOAL_SUCCESS = "GET_GOAL_SUCCESS";
export const GET_GOAL_FAILURE = "GET_GOAL_FAILURE";
export const GET_GOAL_PERIODS_REQUEST = "GET_GOAL_PERIODS_REQUEST";
export const GET_GOAL_PERIODS_SUCCESS = "GET_GOAL_PERIODS_SUCCESS";
export const GET_GOAL_PERIODS_FAILURE = "GET_GOAL_PERIODS_FAILURE";
export const GET_INSTALLMENT_REQUEST = "GET_INSTALLMENT_REQUEST";
export const GET_INSTALLMENT_SUCCESS = "GET_INSTALLMENT_SUCCESS";
export const GET_INSTALLMENT_FAILURE = "GET_INSTALLMENT_FAILURE";
export const GOAL_CREATION_REQUEST = "GOAL_CREATION_REQUEST";
export const GOAL_CREATION_SUCCESS = "GOAL_CREATION_SUCCESS";
export const GOAL_CREATION_FAILURE = "GOAL_CREATION_FAILURE";
export const GET_CUSTOMER_GOALS_REQUEST = "GET_CUSTOMER_GOALS_REQUEST";
export const GET_CUSTOMER_GOALS_SUCCESS = "GET_CUSTOMER_GOALS_SUCCESS";
export const GET_CUSTOMER_GOALS_FAILURE = "GET_CUSTOMER_GOALS_FAILURE";
export const GET_CUSTOMER_GOAL_DETAIL_REQUEST =
  "GET_CUSTOMER_GOAL_DETAIL_REQUEST";
export const GET_CUSTOMER_GOAL_DETAIL_SUCCESS =
  "GET_CUSTOMER_GOAL_DETAIL_SUCCESS";
export const GET_CUSTOMER_GOAL_DETAIL_FAILURE =
  "GET_CUSTOMER_GOAL_DETAIL_FAILURE";
export const DEPOSIT_REQUEST = "DEPOSIT_REQUEST";
export const DEPOSIT_SUCCESS = "DEPOSIT_SUCCESS";
export const DEPOSIT_FAILURE = "DEPOSIT_FAILURE";
export const WITHDRAWAL_REQUEST = "WITHDRAWAL_REQUEST";
export const WITHDRAWAL_SUCCESS = "WITHDRAWAL_SUCCESS";
export const WITHDRAWAL_FAILURE = "WITHDRAWAL_FAILURE";
export const GET_PPG_TOKEN_REQUEST = "GET_PPG_TOKEN_REQUEST";
export const GET_PPG_TOKEN_SUCCESS = "GET_PPG_TOKEN_SUCCESS";
export const GET_PPG_TOKEN_FAILURE = "GET_PPG_TOKEN_FAILURE";
export const GET_WITHDRAWAL_BANK_LIST_REQUEST =
  "GET_WITHDRAWAL_BANK_LIST_REQUEST";
export const GET_WITHDRAWAL_BANK_LIST_SUCCESS =
  "GET_WITHDRAWAL_BANK_LIST_SUCCESS";
export const GET_WITHDRAWAL_BANK_LIST_FAILURE =
  "GET_WITHDRAWAL_BANK_LIST_FAILURE";
export const GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST =
  "GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST";
export const GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS =
  "GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS";
export const GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE =
  "GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE";
export const GET_NEWS_FEED_REQUEST = "GET_NEWS_FEED_REQUEST";
export const GET_NEWS_FEED_SUCCESS = "GET_NEWS_FEED_SUCCESS";
export const GET_NEWS_FEED_FAILURE = "GET_NEWS_FEED_FAILURE";
export const GET_ONBOARDING_REQUEST = "GET_ONBOARDING_REQUEST";
export const GET_ONBOARDING_SUCCESS = "GET_ONBOARDING_SUCCESS";
export const GET_ONBOARDING_FAILURE = "GET_ONBOARDING_FAILURE";
export const SEND_OTP_EMAIL_REQUEST = "SEND_OTP_EMAIL_REQUEST";
export const SEND_OTP_EMAIL_SUCCESS = "SEND_OTP_EMAIL_SUCCESS";
export const SEND_OTP_EMAIL_FAILURE = "SEND_OTP_EMAIL_FAILURE";
export const VERIFY_OTP_EMAIL_REQUEST = "VERIFY_OTP_EMAIL_REQUEST";
export const VERIFY_OTP_EMAIL_SUCCESS = "VERIFY_OTP_EMAIL_SUCCESS";
export const VERIFY_OTP_EMAIL_FAILURE = "VERIFY_OTP_EMAIL_FAILURE";
export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const GET_OCCUPATION_REQUEST = "GET_OCCUPATION_REQUEST";
export const GET_OCCUPATION_SUCCESS = "GET_OCCUPATION_SUCCESS";
export const GET_OCCUPATION_FAILURE = "GET_OCCUPATION_FAILURE";
export const GET_BUSINESS_NATURE_REQUEST = "GET_BUSINESS_NATURE_REQUEST";
export const GET_BUSINESS_NATURE_SUCCESS = "GET_BUSINESS_NATURE_SUCCESS";
export const GET_BUSINESS_NATURE_FAILURE = "GET_BUSINESS_NATURE_FAILURE";
export const GET_ANNUAL_INCOME_REQUEST = "GET_ANNUAL_INCOME_REQUEST";
export const GET_ANNUAL_INCOME_SUCCESS = "GET_ANNUAL_INCOME_SUCCESS";
export const GET_ANNUAL_INCOME_FAILURE = "GET_ANNUAL_INCOME_FAILURE";
export const GET_RACE_REQUEST = "GET_RACE_REQUEST";
export const GET_RACE_SUCCESS = "GET_RACE_SUCCESS";
export const GET_RACE_FAILURE = "GET_RACE_FAILURE";
export const GET_MARITAL_STATUS_REQUEST = "GET_MARITAL_STATUS_REQUEST";
export const GET_MARITAL_STATUS_SUCCESS = "GET_MARITAL_STATUS_SUCCESS";
export const GET_MARITAL_STATUS_FAILURE = "GET_MARITAL_STATUS_FAILURE";
export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST";
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS";
export const GET_COUNTRY_FAILURE = "GET_COUNTRY_FAILURE";
export const GET_STATE_REQUEST = "GET_STATE_REQUEST";
export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAILURE = "GET_STATE_FAILURE";
export const GET_SOURCE_OF_FUND_REQUEST = "GET_SOURCE_OF_FUND_REQUEST";
export const GET_SOURCE_OF_FUND_SUCCESS = "GET_SOURCE_OF_FUND_SUCCESS";
export const GET_SOURCE_OF_FUND_FAILURE = "GET_SOURCE_OF_FUND_FAILURE";
export const GET_SOURCE_OF_INCOME_REQUEST = "GET_SOURCE_OF_INCOME_REQUEST";
export const GET_SOURCE_OF_INCOME_SUCCESS = "GET_SOURCE_OF_INCOME_SUCCESS";
export const GET_SOURCE_OF_INCOME_FAILURE = "GET_SOURCE_OF_INCOME_FAILURE";
export const GET_SUPPORTING_DOCS_REQUEST = "GET_SUPPORTING_DOCS_REQUEST";
export const GET_SUPPORTING_DOCS_SUCCESS = "GET_SUPPORTING_DOCS_SUCCESS";
export const GET_SUPPORTING_DOCS_FAILURE = "GET_SUPPORTING_DOCS_FAILURE";
export const GET_TRX_HISTORY_REQUEST = "GET_TRX_HISTORY_REQUEST";
export const GET_TRX_HISTORY_SUCCESS = "GET_TRX_HISTORY_SUCCESS";
export const GET_TRX_HISTORY_FAILURE = "GET_TRX_HISTORY_FAILURE";
export const ECDD_REQUEST = "ECDD_REQUEST";
export const ECDD_SUCCESS = "ECDD_SUCCESS";
export const ECDD_FAILURE = "ECDD_FAILURE";
export const SEND_CONTACT_MESSAGE_REQUEST = "SEND_CONTACT_MESSAGE_REQUEST";
export const SEND_CONTACT_MESSAGE_SUCCESS = "SEND_CONTACT_MESSAGE_SUCCESS";
export const SEND_CONTACT_MESSAGE_FAILURE = "SEND_CONTACT_MESSAGE_FAILURE";
export const SEND_FEEDBACK_REQUEST = "SEND_FEEDBACK_REQUEST";
export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";
export const CHECK_ECDD_STATUS_REQUEST = "CHECK_ECDD_STATUS_REQUEST";
export const CHECK_ECDD_STATUS_SUCCESS = "CHECK_ECDD_STATUS_SUCCESS";
export const CHECK_ECDD_STATUS_FAILURE = "CHECK_ECDD_STATUS_FAILURE";
export const SMS_OTP_REQUEST = "SMS_OTP_REQUEST";
export const SMS_OTP_SUCCESS = "SMS_OTP_SUCCESS";
export const SMS_OTP_FAILURE = "SMS_OTP_FAILURE";
export const VERIFY_OTP_SMS_REQUEST = "VERIFY_OTP_SMS_REQUEST";
export const VERIFY_OTP_SMS_SUCCESS = "VERIFY_OTP_SMS_SUCCESS";
export const VERIFY_OTP_SMS_FAILURE = "VERIFY_OTP_SMS_FAILURE";
export const CHECK_GOAL_NAME_REQUEST = "CHECK_GOAL_NAME_REQUEST";
export const CHECK_GOAL_NAME_SUCCESS = "CHECK_GOAL_NAME_SUCCESS";
export const CHECK_GOAL_NAME_FAILURE = "CHECK_GOAL_NAME_FAILURE";
export const GET_GOAL_SUMMARY_REQUEST = "GET_GOAL_SUMMARY_REQUEST";
export const GET_GOAL_SUMMARY_SUCCESS = "GET_GOAL_SUMMARY_SUCCESS";
export const GET_GOAL_SUMMARY_FAILURE = "GET_GOAL_SUMMARY_FAILURE";
export const GET_GOAL_INTEREST_REQUEST = "GET_GOAL_INTEREST_REQUEST";
export const GET_GOAL_INTEREST_SUCCESS = "GET_GOAL_INTEREST_SUCCESS";
export const GET_GOAL_INTEREST_FAILURE = "GET_GOAL_INTEREST_FAILURE";
export const CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST =
  "CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST";
export const CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS =
  "CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS";
export const CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE =
  "CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE";
export const GET_CUSTOMER_REQUEST = "GET_CUSTOMER_REQUEST";
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS";
export const GET_CUSTOMER_FAILURE = "GET_CUSTOMER_FAILURE";
export const GET_CAMPAIGN = "GET_CAMPAIGN";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILED = "GET_CAMPAIGN_FAILED";
export const GET_WITHDRAWAL_RESULT = "GET_WITHDRAWAL_RESULT";

// SET
export const SET_SAVING_TYPE = "SET_SAVING_TYPE";
export const SET_SELECTED_GOAL = "SET_SELECTED_GOAL";
export const SET_GOAL_DETAILS = "SET_GOAL_DETAILS";
export const SET_GENERAL_SAVING_DETAILS = "SET_GENERAL_SAVING_DETAILS";
export const SET_SAVING_GOAL_DETAILS = "SET_SAVING_GOAL_DETAILS";
export const SET_USER = "SET_USER";
export const SET_PROFILE_INFO = "SET_PROFILE_INFO";
export const SET_BANK_INFO = "SET_BANK_INFO";
export const DELETE_SAVING_GOAL_FORM = "DELETE_SAVING_GOAL_FORM";
export const SHOW_ERROR = "SHOW_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";
export const ACTIVATE_AUTO_DEPOSIT = "ACTIVATE_AUTO_DEPOSIT";
export const SET_FULL_REDEMPTION = "SET_FULL_REDEMPTION";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const CLEAR_SMS_OTP = "CLEAR_SMS_OTP";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_REJECT_ALERT = "SET_REJECT_ALERT";
export const SET_GOAL_IS_EXIST = "SET_GOAL_IS_EXIST";
export const SET_FAILURE_ALERT = "SET_FAILURE_ALERT";
export const SET_SHOW_ERROR = "SET_SHOW_ERROR";
export const RESET_DATA = "RESET_DATA";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_IS_FIRST_OPEN_APP = "SET_IS_FIRST_OPEN_APP";

// CONSTANT
export const GENERAL_SAVING = "GENERAL_SAVING";
export const CATEGORY_SAVING = "CATEGORY_SAVING";
export const NEW_USER = "NEW_USER";
export const ONBOARDING_USER = "ONBOARDING_USER";
export const OLD_USER = "OLD_USER";
export const GOAL_CREATION = "GOAL_CREATION";
export const GOALDETAILS = "GOALDETAILS";
export const DASHBOARD = "DASHBOARD";
export const GET_STARTED = "GET_STARTED";
export const ONBOARDING = "ONBOARDING";
export const PROFILE = "PROFILE";
export const ECDD = "ECDD";
export const LEAD = "LEAD";
export const ACTIVE = "ACTIVE";
export const RESET_GENERAL_SAVING = "RESET_GENERAL_SAVING";
export const RESET_CATEGORY_SAVING = "RESET_CATEGORY_SAVING";
export const FUNDDETAILS = "FUNDDETAILS";
export const FUNDSELECTION = "FUNDSELECTION";
export const CREATE_GOAL_RSP = "CREATE_GOAL_RSP";
export const CREATE_GOAL_RSP_SUCCESS = "CREATE_GOAL_RSP_SUCCESS";
export const CREATE_GOAL_RSP_FAILED = "CREATE_GOAL_RSP_FAILED";
export const RESET_CUSTOMER_GOALS = " RESET_CUSTOMER_GOALS";
export const RESET_PROFILE = " RESET_PROFILE";
export const SET_CIF_PLAN_ID = "SET_CIF_PLAN_ID";
export const RESET_MAX_AMOUNT = "RESET_MAX_AMOUNT";
export const DEEPLINK = "DEEPLINK";
export const INVEST = "INVEST";
export const SAVE = "SAVE";
