import { all, takeLatest, call, put } from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";
import {
  SET_AUTO_DEPOSIT,
  SET_AUTO_DEPOSIT_SUCCESS,
  SET_AUTO_DEPOSIT_FAILED,
  SET_DISABLE_RSP,
  SET_EDIT_RSP,
  SET_EDIT_RSP_SUCCESS,
  SET_EDIT_RSP_FAILED,
  GET_RSP_DETAIL,
  GET_RSP_MIN_AMOUNT,
  MAKE_DEPOSIT,
  MAKE_DEPOSIT_SUCCESS,
  MAKE_DEPOSIT_FAILED
} from "./contant";
import { sendLogError } from "../../../utils/api";
import * as api from "../../../utils/api";
// import { fetchPostRSP } from "../../../utils/fetch";
import { API_URL } from "../../../utils/axios";
import { setStep } from "../../../globalRedux/actions";
import {
  setDisableRSPSuccess,
  setDisableRSPFailed,
  getRspDetailSuccess,
  getRspDetailFailed,
  setbackPayloadRsp,
  getRspDetail,
  getRSPMinAmountSuccess,
  getRSPMinAmountFailed,
} from "./action";
import { deleteGoalForm } from "../../redux/action";
import { setLastAmountSave } from "./action";
let count = 0;
export function resetCount() {
  count = 0;
}
let logError = {};

function* setAutodepositSaga({ payload }) {
  try {
    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/goal-creation/rsp`,
      payload
    );
    const { data } = res;

    if (
      res instanceof Error ||
      (typeof res === "object" &&
        (!("status" in res) || (res.status === 200 && res.data === "")))
    ) {
      throw new Error(res);
    }

    if (data.errorCode || res.status === 204) {
      logError.api = res.url || window.location.pathname;
      logError.message = data.message || "---";
      logError.errorResponse = true;
      logError.status = res.status || "Not Found";
      logError.errorCode = data.errorCode;

      sendLogError(logError);
      throw new Error("failSetupRSP");
    }
    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "rspform";
    myForm.action = ``;

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        if (key === "fpx_url") {
          myForm.action = data[key];
        } else {
          const element = data[key];
          const myInput = document.createElement("input");
          myInput.type = "hidden";
          myInput.name = key;
          myInput.id = key;
          myInput.value = element;
          myForm.appendChild(myInput);
        }
      }
    }
    document.body.appendChild(myForm);

    yield put({ type: SET_AUTO_DEPOSIT_SUCCESS, payload: data });
    yield put(deleteGoalForm());
    yield put(setStep(null));
    yield put(setbackPayloadRsp({}));

    myForm.submit();
  } catch (error) {
    if (
      error.message !== "failedToCreateGoal" ||
      error.message !== "failSetupRSP"
    ) {
      logError.payload = payload;
      logError.api = "/smp/goal-creation/rsp";
      logError.message = error.message;
      sendLogError(logError);
    }

    yield put({ type: SET_AUTO_DEPOSIT_FAILED, payload: true });
  }
}

function* setDisableRSPsaga(action) {
  try {
    const { payload } = action;
    let newPayload;
    if (typeof payload === "string") {
      newPayload = {
        cifPlanId: payload
      };
    } else {
      newPayload = {
        cifPlanId: payload.cifPlanId,
        fundCd: payload.fundCd
      };
    }

    const res = yield call(
      api.put,
      `${API_URL.mp}/smp/goal-creation/rsp/deactivate`,
      newPayload
    );
    const { data } = res;
    if (data.errorCode) {
      throw new Error("failCancelRSP");
    }

    if (!data.trxRefNo || data.trxRefNo === "") {
      data.trxRefNo = "Not Found";
    }
    yield put(setDisableRSPSuccess(data));
    action.callback();
  } catch (error) {
    yield put(setDisableRSPFailed());
  }
}

function* setEditRspSaga(action) {
  try {
    const { payload } = action;
    const res = yield call(
      api.put,
      `${API_URL.mp}/smp/goal-creation/rsp`,
      payload
    );
    let logErrorEdit;
    const { data } = res;
    if (data.errorCode) {
      logErrorEdit = {
        api: res.config.url || window.location.pathname,
        message: data.message || "---",
        errorResponse: true,
        status: res.status || "Not Found",
        errorCode: data.errorCode
      };
      sendLogError(logErrorEdit);
      throw new Error("failEditRSP");
    }

    yield put({ type: SET_EDIT_RSP_SUCCESS, payload: data });
    if (res.status === 200 && !data.fpx_url) {
      // history.push("/dashboard");
      yield put(
        "fundCd" in payload
          ? getRspDetail({
              cifPlanId: payload.cifPlanId,
              fundCd: payload.fundCd,
            })
          : getRspDetail(payload.cifPlanId)
      );
      action.callback();
    }
    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "rspform";
    myForm.action = ``;

    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        if (key === "fpx_url") {
          myForm.action = data[key];
        } else {
          const element = data[key];
          const myInput = document.createElement("input");
          myInput.type = "hidden";
          myInput.name = key;
          myInput.id = key;
          myInput.value = element;
          myForm.appendChild(myInput);
        }
      }
    }
    document.body.appendChild(myForm);
    if (data.fpx_url) {
      myForm.submit();
    }
  } catch (error) {
    yield put({ type: SET_EDIT_RSP_FAILED, payload: true });
  }
}

function* getRspDetailSaga({ payload }) {
  try {
    let { cifPlanId, fundCd } = payload;
    let params = ``;
    if (typeof payload === "string") {
      params = `${API_URL.mp}/smp/goal-creation/rsp/${payload}`;
    } else {
      params = `${
        API_URL.mp
      }/smp/goal-creation/rsp/${cifPlanId}?fundCd=${fundCd}`;
    }
    const res = yield call(api.get, params);

    const { data } = res;
    if (data.errorCode) {
      throw new Error("failGetRSPDetail");
    }

    yield put(getRspDetailSuccess(data));
  } catch (error) {
    yield put(getRspDetailFailed(false));
  }
}

function* getRspMinAmountSaga() {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/sys-config/rsp_min_amount`
    );
    if (!Object.keys(res.data).length) {
      throw new Error("Something wrong!");
    }
    if (res.data === "Not Found") {
      throw new Error("Something wrong!");
    }

    const { data } = res;

    if (!data.value || parseFloat(data.value) == NaN) {
      throw new Error("Something wrong!");
    }
    yield put(getRSPMinAmountSuccess(data));
  } catch (error) {
    yield put(getRSPMinAmountFailed());
  }
}

function* makeDepositSaga({ payload }) {
  try {
    const {
      customerChannelCode,
      trxRefNo,
      amount,
      ccy,
      name,
      email,
      cifID,
      cifPlanId,
      campaignCode,
      productId
    } = payload;

    payload.productId = undefined;
    payload.cifPlanId = undefined;
    payload.campaignCode = undefined;

    const res = yield call(
      api.post,
      `${API_URL.ppg}/payment/generate`,
      payload
    );
    payload.productId = productId;
    payload.cifPlanId = cifPlanId;

    const { data } = res;

    const dummy = {
      customerChannelCode,
      payCatCd: null,
      trxRefNo,
      amount,
      ccy,
      cifID,
      refId: trxRefNo.slice(2, trxRefNo.length),
      name,
      email,
      lang: "en",
      signature: data.signature,
      resUrl: `${API_URL.mp}/webhooks/res-url`, // to redirect back to mp
      backUrl: `${API_URL.backURL}`
    };
    const requestCheckout = {
      transactionHostRefNo: uuidv4(), // primary key unique
      productId, //goal id
      cifPlanId,
      campaignCode,
      trxRefNo,
      accountNo: "",
      trxType: "FPX",
      amountCcy: "MYR",
      amount: parseFloat(amount),
      unit: 0,
      feePercent: 0,
      feeCcy: "MYR",
      feeAmount: 0,
      sourceOfFund: "",
      bankCd: "",
      bankAccountNo: "",
      bankAccountName: "",
      paymentTypeCd: "",
      ppgRefNo: "",
      pgRefNo: "",
      recipientBank: "",
      type: "",
      approveTime: new Date().toString(),
      trxUnitPaymentProvider: "",
      trxUnitPaymentType: "",
      status: ""
    };

    const resCheckout = yield call(
      api.post,
      `${API_URL.mp}/checkout`,
      requestCheckout
    );

    yield put(setStep(null)); // since success, no need to redirect back to goal creation again

    yield put(deleteGoalForm());
    // Data input to ppg
    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    myForm.action = `${API_URL.ppg}/entry`;
    // myForm.action = `${API_URL.ppgLocal}`; // try on local
    for (const key in dummy) {
      if (Object.hasOwnProperty.call(dummy, key)) {
        const element = dummy[key];
        const myInput = document.createElement("input");
        myInput.type = "hidden";
        myInput.name = key;
        myInput.id = key;
        myInput.value = element;
        myForm.appendChild(myInput);
      }
    }
    document.body.appendChild(myForm);
    localStorage.setItem(
      "currentTab",
      JSON.stringify({
        id: 1,
        name: "Save"
      })
    );
    yield put({ type: MAKE_DEPOSIT_SUCCESS });
    yield put(setLastAmountSave());
    sessionStorage.removeItem("stopRsp");
    myForm.submit();
  } catch (error) {
    yield put({ type: MAKE_DEPOSIT_FAILED });
  }
}

export function* AutoDepositWorkerSaga() {
  yield all([
    takeLatest(SET_AUTO_DEPOSIT, setAutodepositSaga),
    takeLatest(SET_DISABLE_RSP, setDisableRSPsaga),
    takeLatest(SET_EDIT_RSP, setEditRspSaga),
    takeLatest(GET_RSP_DETAIL, getRspDetailSaga),
    takeLatest(GET_RSP_MIN_AMOUNT, getRspMinAmountSaga),
    takeLatest(MAKE_DEPOSIT, makeDepositSaga)
  ]);
}
