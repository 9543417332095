import { ExpansionPanelDetails, Stepper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";

const StyledExpansionPanelDetails = withStyles({
  root: {
    padding: "0 16px 16px"
  }
})(ExpansionPanelDetails);

const TransactionHistoryBase = ({ children, manualAdjustment }) => (
  <StyledExpansionPanelDetails>
    <Stepper
      orientation="vertical"
      style={{ padding: 0 }}
      connector={null}
    >
      {children}
      {manualAdjustment}
    </Stepper>
  </StyledExpansionPanelDetails>
);

export default TransactionHistoryBase;
