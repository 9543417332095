import activity from "../assets/icons/activity.png";
import activitySvg from "../assets/icons/activity.svg";
import discount from "../assets/icons/discount.png";
import paperFail from "../assets/icons/paper-fail.png";
import squareTick from "../assets/icons/square-tick.png";
import squareTickSvg from "../assets/icons/square-tick.svg";
import filter from "../assets/icons/filter.png";
import sliders from "../assets/icons/sliders.png";
import trendingUp from "../assets/icons/trending-up.png";
import creditCard from "../assets/icons/credit-card.png";
import target from "../assets/icons/target.png";
import arrowRight from "../assets/icons/chevron-right.png";
import deposit from "../assets/icons/deposit.svg";
import withdraw from "../assets/icons/withdraw.svg";
import home from "../assets/icons/home.png";
import homeSvg from "../assets/icons/home.svg";
import homeActive from "../assets/icons/home-active.png";
import homeNewSvg from "../assets/icons/home-new.svg";
import homeNewActiveSvg from "../assets/icons/home-new-active.svg";
import homeActiveSvg from "../assets/icons/home-active.svg";
import inbox from "../assets/icons/inbox.png";
import inboxActive from "../assets/icons/inbox-active.png";
import profile from "../assets/icons/profile.png";
import profileSvg from "../assets/icons/profile.svg";
import profileActive from "../assets/icons/profile-active.png";
import profileActiveSvg from "../assets/icons/profile-active.svg";
import rewards from "../assets/icons/rewards.png";
import rewardsSvg from "../assets/icons/rewards.svg";
import rewardsActive from "../assets/icons/rewards-active.png";
import rewardsActiveSvg from "../assets/icons/rewards-active.svg";
import transaction from "../assets/icons/transaction.png";
import transactionSvg from "../assets/icons/transaction.svg";
import transactionActive from "../assets/icons/transaction-active.png";
import transactionActiveSvg from "../assets/icons/transaction-active.svg";
import notificationLabel from "../assets/icons/notification-label.png";
import notificationUnread from "../assets/icons/notification-unread.png";
import fpx from "../assets/icons/fpx.png";
import eWallet from "../assets/icons/eWallet.png";
import info from "../assets/icons/info.png";
import infoSvg from "../assets/icons/info.svg";
import lock from "../assets/icons/lock.png";
import lockSvg from "../assets/icons/lock.svg";
import lockColor from "../assets/icons/lock-color.png";
import userCheck from "../assets/icons/user-check.png";
import checkSquare from "../assets/icons/check-square.png";
import sunrise from "../assets/icons/sunrise.svg";
import gift from "../assets/icons/gift.svg";
import arrowRightDisabled from "../assets/icons/arrowRightDisabled.svg";
import calendar from "../assets/icons/calendar.svg";
import autoCashIn from "../assets/icons/auto-cash-in.svg";
import fundInfo from "../assets/icons/fund-info.svg";
import shieldIcon from "../assets/icons/icon_white_shield.svg";
import infoPrimary from "../assets/icons/info-primary.svg";
import speaker from "../assets/icons/speaker.svg";
import close from "../assets/icons/close.svg";
import check from "../assets/icons/check.svg";
import switching from "../assets/icons/switching.svg";
import trendingUpGreen from "../assets/icons/trending-up-green.svg";
const icons = {
  arrowRightDisabled,
  activity,
  activitySvg,
  discount,
  paperFail,
  squareTick,
  squareTickSvg,
  filter,
  trendingUp,
  creditCard,
  target,
  sliders,
  arrowRight,
  deposit,
  withdraw,
  home,
  homeSvg,
  homeActive,
  homeActiveSvg,
  homeNewSvg,
  homeNewActiveSvg,
  inbox,
  inboxActive,
  profile,
  profileSvg,
  profileActive,
  profileActiveSvg,
  rewards,
  rewardsSvg,
  rewardsActive,
  rewardsActiveSvg,
  transaction,
  transactionSvg,
  transactionActive,
  transactionActiveSvg,
  notificationLabel,
  notificationUnread,
  fpx,
  eWallet,
  info,
  infoSvg,
  lock,
  lockSvg,
  lockColor,
  userCheck,
  checkSquare,
  sunrise,
  gift,
  calendar,
  autoCashIn,
  fundInfo,
  shieldIcon,
  infoPrimary,
  speaker,
  close,
  check,
  switching,
  trendingUpGreen,
};

export default icons;
