import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  Icon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { CATEGORY_SAVING, GOAL_CREATION } from "../redux/constant";
import { getGoalInterest } from "../redux/action";
import { setStep } from "../../globalRedux/actions";
import { createGoal, resetErrorGoalCreation } from "./redux/action";
import { resetSelectedPromo } from "../promoList/redux/action";
import {
  StepNumber,
  StepCaption,
  CustomButton,
  NumberFormatCustom,
  DefaultLoader,
  InfoModal,
  AlertInfo,
} from "../../components";
import { images } from "../../constants";
import styles from "./styles";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { setLastAmountSave } from "../deposit/redux/action";
const StepThree = props => {
  const {
    selectedGoal,
    loading,
    savingGoalDetails,
    savingType,
    setStep,
    createGoal,
    goalIsExist,
    getGoalInterest,
    goalInterest,
    history,
    classes,
    location,
    goalCreationLoading,
    goalCreationRSPLoading,
    createGoalLoading,
    errorCreateGoal,
    resetErrorGoalCreation,
    tempCifPlanId,
    resetSelectedPromo,
    setLastAmountSave,
  } = props;

  const [goalDetails, setGoalDetails] = useState(null);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [backPayload, setBackPayload] = useState({});
  const alreadySubmit = useRef(null);

  /** Save current step into redux */
  useEffect(() => {
    setLastAmountSave();
    resetSelectedPromo();
    getGoalInterest(selectedGoal.refId);
    setStep("/step-three");
    if (location.state) {
      setBackPayload({
        cifPlanId: location.state.cifPlanId,
        productId: location.state.productId,
      });
    }
    sessionStorage.removeItem("stopRsp");
  }, []);

  /** Save category saving data into local state */
  useEffect(() => {
    if (savingType === CATEGORY_SAVING && savingGoalDetails) {
      setGoalDetails(savingGoalDetails);
    }
  }, [savingGoalDetails]);

  useEffect(() => {
    if (goalIsExist.isExist) {
      setIsExist(true);
      alert("Goal name is already exists");
    } else {
      setIsExist(false);
    }
  }, [goalIsExist]);

  const handleCreateGoal = () => {
    if (alreadySubmit.current) return;
    alreadySubmit.current = true;

    const payload = {
      productId: goalDetails.productId,
      invsPlanId: goalDetails.invsPlanId,
      term: goalDetails.frequencyMonth,
      targetAmount:
        goalDetails.targetAmount &&
        parseFloat(
          goalDetails.targetAmount.replace(/[^0-9\.]/gi, "")
        ).toString(),
      planAlias: goalDetails.planAlias,
    };

    if (tempCifPlanId) {
      history.push(`/make-deposit/${GOAL_CREATION}`);
    } else {
      createGoal(payload, () => history.push(`/make-deposit/${GOAL_CREATION}`));
    }
  };

  return (
    <div className={classes.container}>
      {createGoalLoading && <DefaultLoader />}
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCreateGoal}
        isTimeout
        handleAction={() => {
          resetErrorGoalCreation();
          alreadySubmit.current = false;
        }}
      />
      <InfoModal
        open={isInfoOpen}
        handleClose={() => setIsInfoOpen(false)}
        title="Projected Net Return"
        message="Projected Net Return is the estimated return on your investment over the next 12 months. It is calculated based on the current gross yield of the Portfolio, less the fees (which comprises of both the Management and Trustee fees) during the period when the projection was made."
      />
      <StepNumber
        onClick={() => history.push("/step-two")}
        text="Step 3 of 3"
      />
      <StepCaption text="Your goal summary" />
      <div className={classes.imgContainer}>
        <img
          src={
            goalDetails && goalDetails.image
              ? goalDetails.image
              : images.generalSavings
          }
          alt={goalDetails && goalDetails.planAlias}
          className={classes.img}
        />
        <p className={classes.imgLabel}>
          {goalDetails && goalDetails.planAlias}
        </p>
      </div>
      <div className={classes.info}>
        {goalDetails && goalDetails.targetAmount && (
          <FormControl>
            <Typography className={classes.label}>Savings Target</Typography>
            <TextField
              value={
                goalDetails.targetAmount
                  ? parseFloat(
                      goalDetails.targetAmount.replace(/[^0-9\.]/gi, "")
                    )
                  : 0
              }
              InputProps={{
                inputComponent: NumberFormatCustom,
                readOnly: true,
                className: classes.inputField,
                startAdornment: (
                  <InputAdornment position="start">
                    <Typography color="textPrimary" className={classes.prefix}>
                      RM
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
        )}
        <FormControl>
          <Typography className={classes.label}>Savings Period</Typography>
          <TextField
            value={
              goalDetails && goalDetails.frequencyMonth
                ? `${goalDetails.frequencyMonth} Months`
                : "0 Months"
            }
            InputProps={{ readOnly: true, className: classes.inputField }}
          />
        </FormControl>
        <FormControl>
          <Typography className={classes.label}>
            Projected Net Return
          </Typography>
          <TextField
            value={(goalDetails && goalDetails.projectedReturn) || 0}
            InputProps={{
              inputComponent: NumberFormatCustom,
              readOnly: true,
              className: classes.inputField,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography color="textPrimary" className={classes.prefix}>
                    RM
                  </Typography>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="material-icons-outlined"
                    color="primary"
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      dataLayerGTM(
                        "click_tag",
                        "Click",
                        "Info",
                        "Projected Net Return"
                      );
                      setIsInfoOpen(true);
                    }}
                  >
                    info
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl>
          <Typography className={classes.label}>
            Projected Return Rate
          </Typography>
          <TextField
            value={
              goalInterest && goalInterest.expPerf
                ? `${goalInterest.expPerf.toFixed(2)}%`
                : 0
            }
            InputProps={{
              readOnly: true,
              className: classes.inputField,
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    className="material-icons-outlined"
                    color="primary"
                    style={{ fontSize: "20px" }}
                    onClick={() => {
                      dataLayerGTM(
                        "click_tag",
                        "Click",
                        "Info",
                        "Projected Return Rate"
                      );
                      setIsInfoOpen(true);
                    }}
                  >
                    info
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
      </div>
      <div className={classes.footer}>
        <Typography className={classes.depositLabel}>
          Monthly Cash In
        </Typography>
        <Typography color="textPrimary" className={classes.deposit}>
          RM{" "}
          <NumberFormat
            value={goalDetails ? goalDetails.regularInvsAmt : ""}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />{" "}
          <span className={classes.month}>/ month</span>
        </Typography>
      </div>
      <div className={classes.btn}>
        <CustomButton
          text="Make First Cash In"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Button",
              "Make First Deposit Button"
            );
            handleCreateGoal();
          }}
          disabled={
            isExist ||
            goalCreationLoading ||
            goalCreationRSPLoading ||
            createGoalLoading
          }
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    savingGoalDetails: state.goal.savingGoalDetails,
    savingType: state.goal.savingType,
    goalIsExist: state.goal.goalIsExist,
    selectedGoal: state.goal.selectedGoal,
    goalInterest: state.goal.goalInterest,
    goalCreationLoading: state.goal.goalCreationLoading,
    goalCreationRSPLoading: state.goal.goalCreationRSPLoading,
    ...state.stepThreeReducer,
  }),
  {
    setStep,
    createGoal,
    getGoalInterest,
    resetErrorGoalCreation,
    resetSelectedPromo,
    setLastAmountSave,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(StepThree);
