const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  headerSelect: {
    fontFamily: "FSElliotPro",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    margin: "18px 24px 0"
  },
  selectOption: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    margin: "24px 24px"
  },
  depositLabel: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.43,
    color: "#616367"
  },
  depositAmount: {
    fontFamily: "FSElliotPro",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: 1,
    marginTop: "8px"
  },
  projectedReturn: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: '22px'
  },
  generalSavingFooter: {
    position: "fixed",
    bottom: 0,
    width: "100%",
    padding: "16px"
  },
  termContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start"
  },
  btn: { marginTop: "24px" },
  btnGeneral: { marginTop: "30px" }
};

export default styles;
