import React, { useState, useEffect, useRef } from "react";
import styles from "./styles";
import "./styles.css";
import { Typography, TextField, InputAdornment, Icon } from "@material-ui/core";
import NumberFormatCustom from "../../components/NumberFormatCustom";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { resetCount } from "./redux/saga";
import {
  StepLabel,
  CustomInput,
  DefaultLoader,
  Dropdown,
  CustomButton,
  AlertInfo,
  AlertAccount,
  Alert,
} from "../../components";
import withProfileRedirect from "../../containers/App/withProfileRedirect";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import {
  getRSPMinAmount,
  setAutoDeposit,
  setUpLoadingRSP,
  setEditRSP,
  clearErrorRsp,
  setDisableRSP,
} from "./redux/action";
import { getWithdrawalBankList } from "../redux/action";
import { ordinal } from "../../utils/functions/ordinal";
import { useEcddChecking } from "../../utils/hooks";
import moment from "moment";

function generateDates() {
  const arr = [];

  for (let i = 1; i < 32; i++) {
    arr.push(i);
  }
  return arr;
}
const BANK_ACCOUNT_NUMBER = "0123456789"; // temporary hard code
const INVEST_RSP = "INVEST_RSP";

export const AutoDepositV2 = props => {
  const {
    classes,
    history,
    match: { params },
    getRSPMinAmount,
    rspMinAmount,
    rspMinAmountLoading,
    withdrawalBankList,
    loading,
    loadingAutoDeposit,
    rspPayload,
    setAutoDeposit,
    setUpLoadingRSP,
    loadingRspDetail,
    rspDetail,
    errorRSP,
    setEditRSP,
    clearErrorRsp,
    setDisableRSP,
    subAccStsInvest,
    profile,
    ecddStatus,
    riskProfileResult,
    loadingPPGToken,
    loadingWithdrawalBankList,
    getWithdrawalBankList,
  } = props;

  const from = params.from;
  const setup = params.setup;
  const isEditingRsp =
    setup === "editRSP" && Object.values(rspDetail).length > 0;

  const [fields, setFields] = useState({
    isRSPEnable: "Enable",
    totalAmount: "",
    preferredDate: "",
    bank: {
      bankCd: "",
      bankName: "",
      accountNumber: "",
    },
  });
  const [fieldError, setFieldError] = useState({
    amount: false,
    dateOfMonth: false,
    bankName: false,
    bankAccountNo: false,
  });
  const [amountHelper, setAmounthelper] = useState({
    text: "This field is required",
    minValue: rspMinAmount.value,
  });
  const [isDateListOpen, setDateListOpen] = useState(false);
  const [isBankListOpen, setBankListOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [isSubmitDisabled, setIsSumittedDisabled] = useState(false);
  const [errorRspUi, setErrorRspUi] = useState(false);
  const [isRiskProfileExpired, setRiskProfileExpired] = useState(false);
  const initialFieldsValue = useRef({});
  const [subaccountStatus, setSubaccountStatus] = useState({
    allow: true,
    open: false,
  });

  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
    shouldNotPromptOnEffect: true,
  });
  const isAutoDisable = fields.isRSPEnable === "Disable";
  const isAutoEnable = fields.isRSPEnable === "Enable";

  const updateRspStatus =
    rspDetail &&
    typeof rspDetail === "object" &&
    "rspStatus" in rspDetail &&
    (rspDetail.rspStatus === "5" || rspDetail.rspStatus === "12");

  const isCanceledRsp = fields.isRSPEnable === "Disable" && updateRspStatus;

  // this tuple could be either be used for blocking user to do enroll or allow user to do update or disabled rsp
  const notAllowedEnroll = ["Closed", "Suspended", "Frozen", "Closing"];

  useEffect(() => {
    setUpLoadingRSP(false);
    getRSPMinAmount();
    getWithdrawalBankList({ rsp: true });
  }, []);

  const knowSubAccInvest = () => {
    if (!subAccStsInvest) {
      return;
    }
    // if edit and subaccount suspended let user continue
    if (updateRspStatus && subAccStsInvest.sts === "Suspended") return;

    // if sts is in `notAlowedEnroll` then block the user
    if (notAllowedEnroll.includes(subAccStsInvest.sts)) {
      setSubaccountStatus({ open: false, allow: false });
    }
  };

  useEffect(() => {
    knowSubAccInvest();
  }, [subAccStsInvest, rspDetail]);

  const getBank = bankCd => {
    const existBank = withdrawalBankList.find(
      item => item.bankCdOrigin === bankCd
    );

    return existBank;
  };

  const removeZeroDate = val => {
    if (!val) {
      return null;
    }
    if (val.length > 0) {
      return parseInt(val);
    }
    return val;
  };

  const getFields = () => {
    let { amount = "", dateOfMonth } = rspDetail;

    let newObj = {
      totalAmount: "",
      preferredDate: "",
      isRSPEnable: "Enable",
      bank: {
        bankCd: "",
        bankName: "",
        accountNumber: "",
      },
    };
    if (Array.isArray(withdrawalBankList)) {
      const existBank = getBank(rspDetail.lastBankCd || rspDetail.bankCd || "");
      if (existBank) {
        newObj.bank = {
          bankCd: existBank.bankCdOrigin,
          bankName: existBank.name,
          accountNumber: "",
        };
      }
    }
    if (
      subAccStsInvest &&
      "sts" in subAccStsInvest &&
      subAccStsInvest.sts === "Closing"
    ) {
      initialFieldsValue.current.isRSPEnable = "Disable";
      newObj.isRSPEnable = "Disable";
      setIsSumittedDisabled(true);
    }

    if (setup === "editRSP") {
      initialFieldsValue.current = {
        totalAmount: rspDetail.amount || "",
        bankCd: rspDetail.bankCd,
        bankAccountNo: rspDetail.bankAccountNo,
        preferredDate: rspDetail.dateOfMonth
          ? parseInt(rspDetail.dateOfMonth)
          : "",
      };
      if (
        rspDetail.rspStatus === "0" ||
        rspDetail.rspStatus === "26" ||
        rspDetail.rspStatus === "21"
      ) {
        initialFieldsValue.current.isRSPEnable = "Disable";
        newObj.isRSPEnable = "Disable";
      }
      newObj.totalAmount = amount;
      newObj.preferredDate = removeZeroDate(dateOfMonth);
    }
    setFields(newObj);
  };

  useEffect(() => {
    getFields();
  }, [withdrawalBankList, rspDetail]);

  const checkSubmited = (initial, edited) => {
    let rspDetailRef;
    if (!rspDetail) {
      rspDetailRef = {};
    } else {
      rspDetailRef = rspDetail;
    }
    let { rspStatus = "" } = rspDetailRef;
    switch (true) {
      case (rspStatus === "0" || rspStatus === "21" || rspStatus === "26") &&
        fields.isRSPEnable === "Disable":
        setIsSumittedDisabled(true);
        return;
      case (rspStatus === "0" || rspStatus === "21" || rspStatus === "26") &&
        fields.isRSPEnable === "Enable":
        setIsSumittedDisabled(false);
        return;
      case (rspStatus === "5" ||
        rspStatus === "16" ||
        rspStatus === "11" ||
        rspStatus === "12") &&
        fields.isRSPEnable === "Enable" &&
        initial === edited:
        setIsSumittedDisabled(true);
        return;
      default:
        setIsSumittedDisabled(false);
        return;
    }
  };

  useEffect(() => {
    if (
      isEditingRsp &&
      typeof rspDetail === "object" &&
      Object.entries(rspDetail).length > 0
    ) {
      const {
        isRSPEnable,
        preferredDate: editedPreferredDate,
        ...rest
      } = fields;

      const {
        preferredDate: initialPreferredDate,
        ...initialRest
      } = initialFieldsValue.current;

    // 1. allow user if they have status rsp of 5 | 12 and choose the radio button to disabled (deactive)
    // 2. allow user if they have active subaccount and status rsp of 0 | 21 | 26 and choose the radio button to enable (reactive)
      if (
        (updateRspStatus && isAutoDisable) ||
        (subAccStsInvest.sts === "Active" &&
          ["0", "21", "26"].includes(rspDetail.rspStatus) &&
          isAutoEnable)
      ) {
        setSubaccountStatus({ allow: true, open: false });
      } 

      //! order of property is important

      const initial = JSON.stringify({
        totalAmount: initialRest.totalAmount.toString(),
        bankCd: initialRest.bankCd,

        preferredDate: initialPreferredDate,
      });

      const edited = JSON.stringify({
        totalAmount: rest.totalAmount.toString(),
        bankCd: fields.bank.bankCd,

        preferredDate: editedPreferredDate,
      });

      checkSubmited(initial, edited);
    }
  }, [fields, rspDetail, subAccStsInvest]);

  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf",
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === "isRSPEnable") {
      dataLayerGTM("click_tag", "Click", "Button", `Click ${value} button`);
    }
    // revert to initial value when user try to deactivated active rsp
    if (value === "Disable" && updateRspStatus) {
      const existBank = getBank(rspDetail.bankCd);
      setFields({
        bank: {
          bankCd: existBank.bankCdOrigin,
          bankName: existBank.name,
        },
        totalAmount: rspDetail.amount || "",
        bankAccountNo: rspDetail.bankAccountNo,
        preferredDate: rspDetail.dateOfMonth
          ? parseInt(rspDetail.dateOfMonth)
          : "",
        isRSPEnable: value,
      });
    } else {
      setFields(prevFields => ({ ...prevFields, [name]: value }));
    }

    if (value !== initialFieldsValue.current.isRSPEnable) {
      setIsSumittedDisabled(false);
    } else {
      setIsSumittedDisabled(true);
    }
    setFieldError({
      amount: false,
      dateOfMonth: false,
      bankName: false,
      bankAccountNo: false,
    });
  };
  const handleAmountChange = ({ target: { value } }) => {
    setFields(prevFields => ({ ...prevFields, totalAmount: value }));

    if (value >= parseFloat(amountHelper.minValue)) {
      setFieldError({ ...fieldError, amount: false });
    }
  };

  const amountPlaceHolder = () => {
    let text = `0 (Enter an amount, min RM500)`;
    if (!amountHelper.minValue || amountHelper.minValue === "") {
      return text;
    } else {
      text = `0 (Enter an amount, min RM${amountHelper.minValue})`;
    }
    return text;
  };

  const validationRSPfield = val => {
    const tempError = {
      amount: false,
      dateOfMonth: false,
      bankName: false,
      // bankAccountNo: false
    };

    let requireField = {
      amount: val.amount,
      dateOfMonth: val.dateOfMonth,
      bankName: val.bankCd,
      bankAccountNo: val.bankAccountNo,
    };

    for (const key in requireField) {
      if (requireField[key].length === 0) {
        tempError[key] = true;
      }
    }

    if (val.amount < parseFloat(amountHelper.minValue) || val.amount > 30000) {
      tempError.amount = true;
    }

    if (tempError.amount) {
      if (val.amount < parseFloat(amountHelper.minValue)) {
        setAmounthelper({
          ...amountHelper,
          text: `Minimum RM ${parseFloat(amountHelper.minValue).toFixed(2) ||
            "10.00"} `,
        });
      } else if (val.amount > 30000) {
        setAmounthelper({
          ...amountHelper,
          text:
            "The maximum Cash In amount per transaction is RM30,000. You may proceed with your additional Cash In into multiple transactions.",
        });
      } else {
        setAmounthelper({ ...amountHelper, text: "This field is required" });
      }
    }
    if (!isAutoDisable) {
      setFieldError(prevState => ({
        ...prevState,
        amount: tempError.amount,
        dateOfMonth: tempError.dateOfMonth,
        bankName: tempError.bankName,
        bankAccountNo: tempError.bankAccountNo,
      }));
    }

    for (const key in tempError) {
      if (tempError[key] === true) {
        return false;
      }
    }
    if (
      val.cifPlanId.length === 0 ||
      !val.cifPlanId ||
      val.cifPlanId === null
    ) {
      setErrorRspUi(true);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    const editDisableRsp = isEditingRsp && fields.isRSPEnable === "Disable";

    const { cifPlanId, fundCd, target } = rspPayload;
    const payload = {
      cifPlanId,
      fundCd,
      amount: fields.totalAmount.toString(),
      dateOfMonth: String(fields.preferredDate),
      bankCd: fields.bank.bankCd,
      bankAccountNo: BANK_ACCOUNT_NUMBER,
      currency: "MYR",
    };

    let validation = validationRSPfield(payload);

    if (!validation && !isAutoDisable) {
      return;
    }

    if (!subaccountStatus.allow) {
      setSubaccountStatus(prev => ({ allow: prev.allow, open: true }));
      return;
    }

    if (
      !editDisableRsp &&
      "expiredDate" in riskProfileResult &&
      new Date() >=
        moment(riskProfileResult.expiredDate)
          .startOf("date")
          .toDate()
    ) {
      localStorage.setItem("from", "rsp");
      setRiskProfileExpired(true);
      return;
    }

    if (target !== INVEST_RSP) {
      delete payload.fundCd;
    }

    if (isAutoDisable) {
      let disablePayload = {
        cifPlanId: payload.cifPlanId,
        fundCd: payload.fundCd,
      };
      setDisableRSP(disablePayload, () =>
        history.push(
          `/make-deposit/${from}/success/auto-deposit/${setup}/success-cancel`
        )
      );
    } else {
      if (setup === "editRSP") {
        let { rspStatus = "" } = rspDetail;
        const { totalAmount, preferredDate } = initialFieldsValue.current;
        if (rspStatus === "0" || rspStatus === "21" || rspStatus === "26") {
          handleSubmitSucceess(payload, "setup");
        } else if (
          fields.preferredDate !== preferredDate ||
          fields.totalAmount < totalAmount
        ) {
          handleSubmitSucceess(payload, "edit go to dashboard");
        } else {
          handleSubmitSucceess(payload, "edit go to fpx");
        }
        return;
      }
      handleSubmitSucceess(payload, "setup");
    }
  };

  const handleSubmitSucceess = (payload, action) => {
    if (action === "setup") {
      setAutoDeposit(payload);
    }

    if (action === "edit go to fpx") {
      setEditRSP(payload);
    }
    if (action === "edit go to dashboard") {
      setEditRSP(payload, () => {
        history.push(`/make-deposit/${from}/success/rsp/success`);
      });
    }
  };

  const longText = (
    <>
      (a) Understand and agree to the{" "}
      <span
        style={{ color: "#0076cf" }}
        onClick={() =>
          handleOpenPDF(
            "https://www.principal.com.my/sites/default/files/pdf-uploads/FPX%20Direct%20Debit%20Service%20Terms%20and%20Conditions.pdf"
          )
        }
      >
        Terms & Conditions
      </span>{" "}
      of Direct Debit service.
      <br />
      (b) To be bound by{" "}
      <span
        style={{ color: "#0076cf" }}
        onClick={() =>
          handleOpenPDF(
            "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf"
          )
        }
      >
        Principal’s Terms & Conditions.
      </span>{" "}
      <br />
      (c) The fees charges will incur directly and indirectly when investing in
      the relevant fund.
    </>
  );
  const shortText = (
    <>
      (a) Understand and agree to the{" "}
      <span
        style={{ color: "#0076cf" }}
        onClick={() =>
          handleOpenPDF(
            "https://www.principal.com.my/sites/default/files/pdf-uploads/FPX%20Direct%20Debit%20Service%20Terms%20and%20Conditions.pdf"
          )
        }
      >
        terms and conditions{" "}
      </span>
      of Direct Debit service....
    </>
  );

  if (
    rspMinAmountLoading ||
    loading ||
    loadingAutoDeposit ||
    loadingRspDetail ||
    loadingWithdrawalBankList
  ) {
    return <DefaultLoader />;
  }
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <AlertAccount
        title={`Account ${
          subAccStsInvest.sts === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          subAccStsInvest.sts === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setSubaccountStatus(prev => ({
                  allow: prev.allow,
                  open: false,
                }));

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={subaccountStatus.open}
        handleClose={() => {
          setSubaccountStatus(prev => ({
            allow: prev.allow,
            open: false,
          }));

          const shouldStaysOnRSPSubaccountStatus =
            subAccStsInvest &&
            ["Frozen", "Closing", "Closed"].includes(subAccStsInvest.sts);

          if (shouldStaysOnRSPSubaccountStatus && updateRspStatus) return;

          history.back();
        }}
      />
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorRSP || errorRspUi}
        isTimeout={true}
        handleAction={() => {
          clearErrorRsp();
          resetCount();
          setErrorRspUi(false);

          history.back();
        }}
      />
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={isRiskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={() => {
          history.push("/invest-introduction");
        }}
        handleCancel={() => setRiskProfileExpired(false)}
      />
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={isDateListOpen}
        handleDismiss={() => setDateListOpen(false)}
      >
        <div style={{ padding: "16px 16px 0" }}>
          <Typography
            color="secondary"
            style={{ ...styles.baseFont, ...styles.dropdownTitle }}
          >
            Date of month
          </Typography>
          {generateDates().map(item => {
            return (
              <div
                key={item}
                onClick={() => {
                  setFields(prevFields => ({
                    ...prevFields,
                    preferredDate: item,
                  }));
                  setFieldError({ ...fieldError, dateOfMonth: false });
                  setDateListOpen(false);
                }}
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  margin: "32px 0",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  {ordinal(item)} of each month
                </Typography>
                {fields.preferredDate === item && (
                  <Icon
                    className="material-icons-outlined"
                    style={{ cursor: "pointer", color: "#0091DA" }}
                  >
                    done
                  </Icon>
                )}
              </div>
            );
          })}
        </div>
      </Dropdown>
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={isBankListOpen}
        handleDismiss={() => setBankListOpen(false)}
      >
        <div style={{ padding: "16px 16px 0" }}>
          <Typography
            color="secondary"
            style={{ ...styles.baseFont, ...styles.dropdownTitle }}
          >
            Bank list
          </Typography>
          {withdrawalBankList.length > 0 &&
            withdrawalBankList.map((bankItem, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setFields(prevFields => ({
                      ...prevFields,
                      bank: {
                        bankCd: bankItem.bankCdOrigin,
                        bankName: bankItem.name,
                      },
                    }));
                    setFieldError({ ...fieldError, bankName: false });
                    setBankListOpen(false);
                  }}
                  style={{
                    ...styles.row,
                    justifyContent: "space-between",
                    margin: "32px 0",
                  }}
                >
                  <Typography
                    color="textPrimary"
                    style={{ ...styles.baseFont, fontWeight: "700" }}
                  >
                    {bankItem.name}
                  </Typography>
                  {fields.bank.bankName === bankItem.name && (
                    <Icon
                      className="material-icons-outlined"
                      style={{ cursor: "pointer", color: "#0091DA" }}
                    >
                      done
                    </Icon>
                  )}
                </div>
              );
            })}
        </div>
      </Dropdown>
      <div style={styles.container}>
        <StepLabel
          title="Insert Bank Information"
          containerStyles={{ margin: "16px 16px 22px" }}
          onClick={() => history.back()}
        />
        <form style={{ margin: "0 16px" }}>
          <CustomInput
            label="Enable monthly auto Cash In"
            name="isRSPEnable"
            isRadio={true}
            isEdit={true}
            rspDisable={setup === "setupRSP" ? true : false}
            value={fields.isRSPEnable}
            onChange={handleChange}
          />
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isAutoDisable && "#9D9EA4",
            }}
          >
            Please enter an amount
          </Typography>
          <TextField
            variant="outlined"
            name="totalAmount"
            placeholder={amountPlaceHolder()}
            style={{ width: "100%", marginBottom: "16px" }}
            value={fields.totalAmount}
            error={fieldError.amount}
            helperText={fieldError.amount && amountHelper.text}
            FormHelperTextProps={{ className: classes.helper }}
            onChange={handleAmountChange}
            disabled={isAutoDisable}
            inputProps={{
              style: {
                padding: "12px 16px 12px 4px",
              },
              className: { ...styles.baseFont },
              maxLength: 16, // only work for type other than number
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: NumberFormatCustom,
              style: { paddingLeft: "16px" },
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    color="textPrimary"
                    // className={{ ...styles.prefix }}
                    style={{
                      fontWeight: "700",
                      color: isAutoDisable && "#9D9EA4",
                    }}
                  >
                    RM
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <CustomInput
            label="Your preferred auto Cash In date"
            disabledRSPInput={isAutoDisable}
            name="dateOfMonth"
            placeholder="Auto Cash In date"
            value={
              fields.preferredDate
                ? `${ordinal(fields.preferredDate)} of each month`
                : ""
            }
            isEdit
            isDropdown
            isDate
            error={fieldError.dateOfMonth}
            onClick={() => {
              isAutoEnable && setDateListOpen(true);
            }}
            disabled={isAutoDisable}
          />
          <CustomInput
            label="Your preferred bank"
            disabledRSPInput={isAutoDisable}
            name="bankName"
            placeholder="Bank name"
            value={fields.bank.bankName}
            isEdit={true}
            isDropdown={true}
            error={fieldError.bankName}
            onClick={() => {
              isAutoEnable && setBankListOpen(true);
            }}
            disabled={isAutoDisable}
          />
          <Typography
            style={{
              ...styles.baseFont,
              fontSize: "14px",
              lineHeight: 1.57,
              fontWeight: "700",
              marginBottom: "16px",
            }}
          >
            RM1 will be charged to ensure the account is active and valid. RM1
            will automatically refund by Principal subject to the Terms &
            Condition.
          </Typography>
        </form>
        <div
          style={{
            position: showMore && "sticky",
            bottom: 0,
            width: "100%",
            display: "block",
            marginTop: "auto",
            padding: "16px 16px 24px",
            backgroundColor: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <Typography
              color="secondary"
              style={{
                ...styles.baseFont,
                fontWeight: "700",
                color: "#616367",
              }}
            >
              By proceeding, I have read, understood and accepted:
            </Typography>
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Typography
              style={{
                ...styles.baseFont,
                fontSize: "14px",
                lineHeight: 1.57,
                color: "#616367",
              }}
            >
              {showMore ? (
                longText
              ) : (
                <>
                  {shortText}

                  <span
                    style={{
                      color: "#0076cf",
                      fontSize: "16px",
                      marginLeft: "4px",
                    }}
                    onClick={() => {
                      dataLayerGTM(
                        "click_tag",
                        "Click",
                        "Button",
                        "Click Show More Button"
                      );

                      setShowMore(true);
                    }}
                  >
                    Show More
                  </span>
                </>
              )}
            </Typography>
            <Typography
              style={{
                ...styles.baseFont,
                lineHeight: 1.25,
                color: "#0076cf",
                marginTop: "8px",
              }}
              onClick={() => setShowMore(!showMore)}
            >
              {showMore && "Show Less"}
            </Typography>
          </div>
          <CustomButton
            disabled={loadingAutoDeposit || isSubmitDisabled ? true : false}
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={
              isCanceledRsp
                ? handleSubmit
                : handleCheckEcdd({
                    cb: handleSubmit,
                    ecddExpiredCb: () => history.push("/onBoarding/ecdd/RSP"),
                    gtm: {
                      event: "click_tag",
                      action: "Click",
                      category: "Button",
                      label: "Click Next - Insert Bank Information button",
                    },
                  })
            }
          />
        </div>
      </div>
    </div>
  );
};
const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    withdrawalBankList: state.goal.withdrawalBankList,
    subAccStsInvest: state.dashboardReducer.subAccStsInvest,
    ecddStatus: state.goal.ecddStatus,
    profile: state.goal.profile,
    riskProfileResult: state.riskAssessmentReducer.riskProfileResult,
    loadingWithdrawalBankList: state.goal.loadingWithdrawalBankList,
    loadingPPGToken: state.goal.loadingPPGToken,
    ...state.autoDepositReducer,
  }),
  {
    getRSPMinAmount,
    setAutoDeposit,
    setUpLoadingRSP,
    setEditRSP,
    clearErrorRsp,
    setDisableRSP,
    getWithdrawalBankList,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withProfileRedirect
)(AutoDepositV2);
