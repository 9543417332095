import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Icon,
  InputAdornment,
  RadioGroup,
  Radio,
  FormControlLabel
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import MDSpinner from "react-md-spinner";

import styles from "./styles";

const Input = ({
  label,
  name,
  type,
  placeholder,
  value,
  error,
  helperText,
  onChange,
  handleBlur,
  onClick,
  isEdit,
  isRequired,
  isDropdown,
  isPhone,
  isRadio,
  maxLength,
  classes,
  countryCode,
  disabled,
  rspDisable,
  disabledRSPInput,
  isDate,
  className,
  isLoading,
  inputRef
}) => {
  return (
    <>
      <Typography
        color="textPrimary"
        className={`${classes.baseFont} ${classes.label}`}
        style={{ color: disabled && "#9D9EA4" }}
      >
        {label}
        {isRequired && "*"}
      </Typography>
      {!isRadio ? (
        <TextField
          variant="outlined"
          type={type ? type : "text"}
          placeholder={placeholder}
          fullWidth
          name={name}
          value={value}
          onChange={!isDropdown ? onChange : null}
          onBlur={handleBlur && handleBlur}
          onClick={isDropdown && onClick}
          error={error}
          helperText={
            error && helperText ? helperText : error && "This field is required"
          }
          style={{ marginBottom: "14px" }}
          disabled={!isEdit || disabled}
          FormHelperTextProps={{ className: classes.error }}
          inputRef={inputRef}
          inputProps={{
            style: {
              padding: !isPhone ? "12px 16px" : "12px 16px 12px 4px",
              backgroundColor: !isEdit && "#f6f6f8",

              color: disabledRSPInput ? "rgb(174, 174, 174)" : "#333333"
            },
            className: `${classes.baseFont} ${className}`,
            maxLength: maxLength // only work for type other than number
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            readOnly: !isEdit || isDropdown ? true : false,
            style: {
              backgroundColor: !isEdit && "#f6f6f8",
              padding: 0,
              paddingLeft: isPhone && "16px"
            },
            startAdornment: isPhone && (
              <InputAdornment
                position="start"
                style={{ padding: 0, margin: 0 }}
              >
                <Typography
                  color="textPrimary"
                  className={`${classes.baseFont} ${classes.prefix}`}
                >
                  +{countryCode ? countryCode : "60"}
                </Typography>
              </InputAdornment>
            ),
            endAdornment: isDropdown && (
              <InputAdornment
                position="end"
                style={{ padding: 0, paddingRight: "16px", margin: 0 }}
              >
                {isDate ? (
                  <Icon
                    className="material-icons-outlined"
                    style={{
                      color:
                        !isEdit || disabledRSPInput ? "#C5C6C5" : "#0091DA",
                      fontSize: "18px"
                    }}
                  >
                    calendar_today
                  </Icon>
                ) : isLoading ? (
                  <div className="spinner-blueish" />
                ) : (
                  <Icon
                    color="primary"
                    style={{
                      color: !isEdit || disabledRSPInput ? "#C5C6C5" : "#0091DA"
                    }}
                  >
                    expand_more
                  </Icon>
                )}
              </InputAdornment>
            )
          }}
        />
      ) : (
        <>
          {name === "isRSPEnable" ? (
            <RadioGroup
              name={name}
              value={value}
              onChange={onChange}
              className={`${classes.radio}`}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <FormControlLabel
                value="Enable"
                control={<Radio color="primary" />}
                label="Enable"
              />
              <FormControlLabel
                value="Disable"
                disabled={rspDisable}
                control={<Radio color="primary" />}
                label="Disable"
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              name={name}
              value={value}
              onChange={onChange}
              className={`${classes.row} ${classes.radio}`}
            >
              <FormControlLabel
                value="No"
                control={<Radio color="primary" />}
                label="No"
              />
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
            </RadioGroup>
          )}
        </>
      )}
    </>
  );
};

export default compose(withStyles(styles))(Input);
