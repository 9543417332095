import { all, takeLatest, call, put } from "redux-saga/effects";
import { GET_PROMO } from "./constants";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";
import { getPromoSuccess, getPromoFailed } from "./action";
const INVEST = "INVEST";

function filterCampaignsByWrapperType(list, wrapperType) {
  const filteredCampaigns = list.filter(campaign =>
    campaign.eligibilityRules.wrapperTypes.includes(wrapperType)
  );
  return filteredCampaigns;
}

function* getPromoSaga({ payload, callback }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/campaigns?hierarchyId=${
        payload.hierarchyId
      }&instrumentId=${payload.instrumentId}`,
      // {},
      // "promoReducer.promoList"
    );

    const { data } = res;

    const allowedStatus = ["Active", "Limit", "Suspended"];

    const promoListFiltered = (data || []).filter(
      item =>
        item.eligibilityRules.codes.some(code => code.isPublic) &&
        allowedStatus.includes(item.status) &&
        item.status === "Active"
    );
    let wrapperType =
      payload.target === INVEST
        ? "PersonalPortfolioMPInvest"
        : "PersonalPortfolioMPSave";

    let byWrapperType = filterCampaignsByWrapperType(
      promoListFiltered,
      wrapperType
    );

    yield put(getPromoSuccess(byWrapperType || []));

    if (callback) {
      callback(byWrapperType || []);
    }
  } catch (error) {
    yield put(getPromoFailed());
  }
}

export function* promoSagaWorker() {
  yield all([takeLatest(GET_PROMO, getPromoSaga)]);
}
