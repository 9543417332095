import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { sendLogError } from "../api";
import { AlertInfo, DefaultLoader } from "../../components";
import { sendPersistLog } from "../api";
//! important note:
//! errror boundary do not catch error inside event handlers
//! only error in the render cycle
export const withErrorBoundary = ComponentThatMayError => {
  const ComponentWithError = props => {
    const [alertBoundary, setAlertBoundary] = useState(true);

    const handlePersistLog = async ({ message, stack, deviceInfo }) => {
      try {
        const log = localStorage.getItem("persist:root");

        await sendPersistLog({
          root: JSON.parse(log),
          message,
          stack,
          deviceInfo,
        });

        localStorage.removeItem("isNewUser");
        localStorage.removeItem("persist:root");
        setAlertBoundary(false);
        window.location.reload();
      } catch (err) {
        setAlertBoundary(false);
        localStorage.removeItem("isNewUser");
        localStorage.removeItem("persist:root");
        window.location.reload();
      }
    };

    return (
      <ErrorBoundary
        FallbackComponent={errorInfo => {
          return (
            <div role="alert">
              {alertBoundary ? (
                <AlertInfo
                  description="We are facing some technical issue, we're sorry for the inconvenience."
                  isTimeout={true}
                  isOpen
                  fullWidthBtn={true}
                  handleAction={() => {
                    if ("my" in window && typeof window.my === "object") {
                      window.my.call(
                        "getSystemInfo",
                        {},
                        async deviceInfo =>
                          await handlePersistLog({
                            message: errorInfo.error.message,
                            stack: errorInfo.error.stack,
                            deviceInfo,
                          })
                      );
                    } else {
                      handlePersistLog({
                        message: errorInfo.error.message,
                        stack: errorInfo.error.stack,
                      });
                    }
                  }}
                />
              ) : (
                <DefaultLoader />
              )}
            </div>
          );
        }}
        onError={(error, info) => {
          sendLogError({
            message: error.message,
            errorType: error.name,
            path: window.location.pathname,
            stack: info.componentStack,
          });
        }}
      >
        <ComponentThatMayError {...props} />
      </ErrorBoundary>
    );
  };

  return ComponentWithError;
};
