import React, { useState, useEffect } from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  getGoalPeriods,
  getInstallment,
  setGeneralSavingDetails,
  setSavingGoalDetails,
  deleteGoalForm,
  hideError,
  setGoalIsExist,
  checkGoalName,
  setShowError,
} from "../redux/action";
import { setStep } from "../../globalRedux/actions";
import { setbackPayloadRsp } from "../deposit/redux/action";
import { resetCifPlanTemp } from "../step3/redux/action";
import {
  StepNumber,
  StepCaption,
  CustomButton,
  SavingGoalForm,
  DefaultLoader,
  Dropdown,
  InfoModal,
  Alert,
  AlertInfo,
} from "../../components";
import styles from "./styles";
import "./styles.scss";
import "../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";

const StepTwo = props => {
  const {
    loading,
    selectedGoal,
    goalPeriodList,
    savingGoalDetails,
    installment,
    getGoalPeriods,
    getInstallment,
    setStep,
    setSavingGoalDetails,
    deleteGoalForm,
    setGoalIsExist,
    checkGoalName,
    setShowError,
    error,
    showError,
    history,
    classes,
    setbackPayloadRsp,
    resetCifPlanTemp,
    createGoalLoading,
  } = props;

  const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));

  const [saving, setSaving] = useState({
    name: "",
    target: "",
    period: "",
    interest: "",
    invsPlanId: "",
  });
  const [savingError, setSavingError] = useState({
    name: false,
    target: false,
    period: false,
  });
  const [minAmtErr, setMinAmtErr] = useState(false);
  const [isDisable, setDisable] = useState(true);
  const [goalPeriod, setGoalPeriod] = useState([]);
  const [monthlyDeposit, setMonthlyDeposit] = useState(0);
  const [projectReturn, setProjectReturn] = useState(0);
  const [isOpenBottom, setOpenBottom] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [isExist, setIsExist] = useState(false);

  /** @constant format regex of special characters */
  // let format = /[!-\/:-@[-`{-~]/;
  let format = /^[a-zA-Z0-9 ]+$/;

  /**
   * Get month list from API
   * Save current step into redux
   */
  useEffect(() => {
    getGoalPeriods();
    setStep("/step-two");
    setbackPayloadRsp({});
    resetCifPlanTemp();
  }, []);

  useEffect(() => {
    setGoalIsExist(false);
  }, []);

  /** Save goal period into local state */
  useEffect(() => {
    if (goalPeriodList && selectedGoal) {
      let period = goalPeriodList.filter(
        period => period.planId === selectedGoal.refId
      );
      period.sort((a, b) => {
        return a.frequencyMonth < b.frequencyMonth ? -1 : 1;
      });
      setGoalPeriod(period);
    }
  }, [goalPeriodList, selectedGoal]);

  /** Auto fill goal name from redux data */
  useEffect(() => {
    if (selectedGoal) {
      setSaving(prevState => ({
        ...prevState,
        name: selectedGoal.name,
      }));
    }
  }, [selectedGoal]);

  /** Auto fill form from redux */
  useEffect(() => {
    if (savingGoalDetails && savingGoalDetails.invsPlanId) {
      setSaving(prevState => ({
        ...prevState,
        name: savingGoalDetails.planAlias ? savingGoalDetails.planAlias : "",
        target: savingGoalDetails.targetAmount
          ? savingGoalDetails.targetAmount
          : "",
        period: savingGoalDetails.frequencyMonth
          ? savingGoalDetails.frequencyMonth
          : "",
        interest: savingGoalDetails.initExpPerf
          ? savingGoalDetails.initExpPerf
          : "",
        invsPlanId: savingGoalDetails.invsPlanId
          ? savingGoalDetails.invsPlanId
          : "",
      }));
      setMonthlyDeposit(
        savingGoalDetails.regularInvsAmt ? savingGoalDetails.regularInvsAmt : 0
      );
      setProjectReturn(
        savingGoalDetails.projectedReturn
          ? savingGoalDetails.projectedReturn
          : 0
      );
    }
  }, [savingGoalDetails]);

  /** Get monthly deposit from API
   *  once there is goal target and month
   */
  useEffect(() => {
    let { target, period, interest } = saving;
    target = parseFloat(target.replace(/[^0-9\.]/gi, ""));
    period = parseInt(period);
    interest = parseFloat(interest);

    if (target > 0 && period > 0 && interest > 0) {
      getInstallment({ target, period, interest });
    } else {
      setMonthlyDeposit(0);
      setProjectReturn(0);
    }
  }, [saving.target, saving.period, saving.interest]);

  useEffect(() => {
    handleSetForm();
  }, [saving.period, saving.interest, saving.invsPlanId]);

  /** Save monthly deposit into local state */
  useEffect(() => {
    const targetNum = parseFloat(saving.target.replace(/[^0-9\.]/gi, ""));

    if (installment && !isNaN(targetNum) && targetNum > 0) {
      setMonthlyDeposit(installment.installmentAmt);
      // setProjectReturn(installment.projectedReturn);
      setProjectReturn(
        installment.projectedReturn[installment.projectedReturn.length - 1]
          .target
      );
    }
  }, [installment, saving.target]);

  // validate form on category saving case
  useEffect(() => {
    const { name, target, period, interest, invsPlanId } = saving;

    const payload = {
      name,
      target,
      period,
      interest,
      invsPlanId,
      monthlyDeposit,
      projectReturn,
    };

    const isEmpty = Object.values(payload).some(x => !x);

    if (!isEmpty && !savingError.name && !savingError.target) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [saving, monthlyDeposit, projectReturn, savingError]);

  /** Handle on change event form */
  const handleChangeSaving = (field, value) => {
    if ((field === "target" && value === "") || value === "0") {
      setSaving(prevState => {
        prevState[field] = "";
        return { ...prevState };
      });
    } else {
      setSaving(prevState => {
        prevState[field] = value;
        return { ...prevState };
      });
    }
  };

  const handleChangeSaving2 = value => {
    const updateData = {
      invsPlanId: value.id,
      period: value.frequencyMonth,
      interest: value.expPerf,
    };
    setSaving(prevState => {
      return { ...prevState, ...updateData };
    });
  };

  /** Handle error on change event */
  const handleSavingError = (field, value) => {
    setSavingError(prevState => {
      prevState[field] = value;
      return { ...prevState };
    });
  };

  /** Validate if there is special characters */
  const isSpecialChar = () => {
    if (saving.name) {
      if (format.test(saving.name)) {
        setSavingError({
          ...savingError,
          name: false,
        });
      } else {
        setSavingError({
          ...savingError,
          name: true,
        });
      }
    } else {
      setSavingError({
        ...savingError,
        name: true,
      });
    }
  };

  /** Save form data into global state (redux) */
  const handleSetForm = () => {
    let data;

    data = {
      productId: selectedGoal ? selectedGoal.id.toString() : "",
      planAlias: saving ? saving.name.toString() : "",
      targetAmount: saving ? saving.target : "",
      frequencyMonth: saving ? saving.period.toString() : "",
      initExpPerf: saving ? saving.interest : "",
      invsPlanId: saving ? saving.invsPlanId.toString() : "",
      regularInvsAmt: monthlyDeposit ? monthlyDeposit : "",
      projectedReturn: projectReturn ? projectReturn : "",
      image: selectedGoal ? selectedGoal.image.toString() : "",
    };

    setSavingGoalDetails(data);
  };

  /**
   *  Handle submit event
   *  Navigate into step three screen
   */
  const handleSubmit = () => {
    if (parseInt(monthlyDeposit) < 10) {
      setMinAmtErr(true);
      return;
    }
    dataLayerGTM("click_tag", "Click", "Button", "Step 2 Next Button");
    let data;
    data = {
      productId: selectedGoal.id.toString(),
      planAlias: saving.name.toString(),
      targetAmount: saving.target,
      frequencyMonth: saving.period.toString(),
      initExpPerf: saving.interest,
      invsPlanId: saving.invsPlanId.toString(),
      regularInvsAmt: monthlyDeposit,
      projectedReturn: projectReturn,
      image: selectedGoal.image.toString(),
    };

    setSavingGoalDetails(data);

    checkGoalName({
      cifId: userInfo.masterId,
      planAlias: saving.name.toString(),
    });
  };

  /**
   * Navigate back to previous screen
   * Delete form data
   */
  const handleBack = () => {
    deleteGoalForm();
    history.push("/step-one");
  };

  // window.consoleLog(saving);

  return (
    <div className={`bg-white ${classes.container}`}>
      {(loading || createGoalLoading) && <DefaultLoader />}
      <AlertInfo
        title="Your goal name is already exists"
        description={error}
        isOpen={showError}
        isCancel={false}
        handleAction={() => {
          setIsExist(true);
          setSavingError(prevState => ({
            ...prevState,
            name: true,
          }));
          setShowError(false);
        }}
      />
      <AlertInfo
        title="Ops, the minimum Monthly Deposit amount is RM10"
        description={
          "please in crease your total save amount of shorter the timing you want to achieve your goal "
        }
        isOpen={minAmtErr}
        isCancel={false}
        handleAction={() => setMinAmtErr(false)}
      />
      <InfoModal
        open={isInfoOpen}
        handleClose={() => setIsInfoOpen(false)}
        title="Projected Net Return"
        message="Projected Net Return is the estimated return on your investment over the next 12 months. It is calculated based on the current gross yield of the Portfolio, less the fees (which comprises of both the Management and Trustee fees) during the period when the projection was made."
      />
      <StepNumber onClick={handleBack} text="Step 2 of 3" />
      <StepCaption text="Set up your financial goal" />
      <SavingGoalForm
        saving={saving}
        savingError={savingError}
        handleChangeSaving={handleChangeSaving}
        handleSavingError={handleSavingError}
        handleSetForm={handleSetForm}
        checkSymbol={isSpecialChar}
        setOpenBottom={setOpenBottom}
        isExist={isExist}
        setIsExist={setIsExist}
      />
      <Dropdown
        isOpen={isOpenBottom}
        handleDismiss={() => setOpenBottom(false)}
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
      >
        <Typography color="secondary" className={classes.headerSelect}>
          How long do you plan to achieve your savings goal?
        </Typography>
        {goalPeriod.map((item, index) => {
          return (
            <Typography
              key={index}
              color="textPrimary"
              className={classes.selectOption}
              onClick={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Dropdown",
                  `${item.frequencyMonth} Month`
                );
                handleChangeSaving2(item);
                setOpenBottom(false);
                // handleSetForm();
              }}
            >
              {item.frequencyMonth}{" "}
              {item.frequencyMonth > 1 ? "Months" : "Month"}
            </Typography>
          );
        })}
      </Dropdown>
      <div className="depositContainer">
        <Typography
          className={classes.depositLabel}
          style={{ fontWeight: "bold" }}
        >
          Monthly Cash In
        </Typography>
        <Typography color="textPrimary" className={classes.depositAmount}>
          RM{" "}
          <NumberFormat
            value={monthlyDeposit ? monthlyDeposit : 0}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />{" "}
          <span className="monthLabel">/ month</span>
        </Typography>
        <Typography
          style={{
            marginTop: "4px",
            display: "flex",
            alignItems: "center",
          }}
          className={classes.projectedReturn}
        >
          Projected Net Return: RM
          <NumberFormat
            value={projectReturn ? projectReturn : "0.00"}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            style={{ marginLeft: "0.1rem" }}
          />
          <Icon
            className="material-icons-outlined"
            color="primary"
            style={{ fontSize: "20px", marginLeft: "0.5rem" }}
            onClick={() => {
              dataLayerGTM(
                "click_tag",
                "Click",
                "Info",
                "Projected Net Return"
              );
              setIsInfoOpen(true);
            }}
          >
            info
          </Icon>
        </Typography>
      </div>
      <div className="btnContainer">
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          disabled={isDisable || createGoalLoading}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    ...state.goal,
    ...state.stepThreeReducer,
  }),
  {
    setStep,
    getGoalPeriods,
    getInstallment,
    setSavingGoalDetails,
    setGeneralSavingDetails,
    deleteGoalForm,
    setGoalIsExist,
    hideError,
    checkGoalName,
    setShowError,
    setbackPayloadRsp,
    resetCifPlanTemp,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(StepTwo);
