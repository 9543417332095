import {
  SET_REWARD_DETAIL,
  GET_REWARDS,
  GET_REWARDS_FAILED,
  GET_REWARDS_SUCCESS
} from "./constants";

export const setRewardDetail = payload => {
  return {
    type: SET_REWARD_DETAIL,
    payload
  };
};

export const getRewards = payload => {
  return {
    type: GET_REWARDS,
    payload
  };
};

export const getRewardsSuccess = payload => {
  return {
    type: GET_REWARDS_SUCCESS,
    payload
  };
};

export const getRewardsFailed = payload => {
  return {
    type: GET_REWARDS_FAILED,
    payload
  };
};
