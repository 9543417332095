import React from "react";
import { BottomSheet } from "react-spring-bottom-sheet";

const Dropdown = ({
  bottomHeight,
  isOpen,
  handleDismiss,
  isHeightBasedOnContent = false,
  children,
  ...props
}) => {
  if (isHeightBasedOnContent) {
    return (
      <BottomSheet
        open={isOpen}
        onDismiss={handleDismiss}
        initialFocusRef={false}
        {...props}
      >
        {children}
      </BottomSheet>
    );
  }

  return (
    <BottomSheet
      open={isOpen}
      onDismiss={handleDismiss}
      defaultSnap={bottomHeight}
      snapPoints={({ maxHeight }) => [
        maxHeight - maxHeight / 10, //highest
        maxHeight / 4, // smallest
        maxHeight * 0.4, // smallest
        maxHeight * 0.6 // medium
      ]}
      initialFocusRef={false}
    
      {...props}
    >
      {children}
    </BottomSheet>
  );
};
export default Dropdown;
