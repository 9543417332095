const styles = {
  info: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "8px 16px"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  label: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.57,
    width: "256px"
  }
};

export default styles;
