const styles = {
    helper: {
      fontFamily: "FSElliotPro",
      fontSize: "14px",
      fontWeight: "normal",
      lineHeight: 1.43,
      color: "#616367",
      marginLeft: "0",
      marginTop: "4px"
    },
    inputField: {
      fontFamily: "FSElliotPro",
      fontSize: "16px",
      fontWeight: "normal",
      lineHeight: 1.5,
      padding: "12px 16px 12px 0",
      borderColor: "rgba(51, 51, 51, 0.2)"
    },
    prefix: {
      fontFamily: "FSElliotPro",
      fontSize: "16px",
      lineHeight: 1.5
    },
    btnText: {
      fontFamily: "FSElliotPro",
      fontSize: "16px",
      fontWeight: "normal",
      color: "#0076CF",
      marginTop: "16px",
      padding: 0,
      textTransform: "capitalize",
      lineHeight: "20px"
    }
  };
  export default styles;
  