const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "32px"
  },
  country: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5
  }
};

export default styles;
