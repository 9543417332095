import { all, takeLatest, call, put, select } from "redux-saga/effects";
import * as constant from "./constants";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";
import { setSavingGoalDetails } from "../../redux/action";
import { setCifPlanTemp } from "./action";
import { sendLogError } from "../../../utils/api";

function* createGoalSaga({ payload, callback }) {
  try {
    const image = yield select(state => {
      if (state.goal.savingGoalDetails && state.goal.savingGoalDetails.image) {
        return state.goal.savingGoalDetails.image;
      }
      return null;
    });
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    let payloadValue = { ...payload };
    payloadValue.cifId = userInfo && userInfo.masterId;

    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/goal-creation`,
      payloadValue
    );
    const { data } = res;
    data.image = image;
    if (!data.accountNo || data.accountNo === "") {
      let logError = {
        api: res.url || window.location.pathname,
        message: "accountNo not exist",
        response: data || {},
        errorResponse: true,
        status: res.status || "Not Found"
      };
      sendLogError(logError);
      throw new Error("accountNoErrorNull");
    }

    yield put(setSavingGoalDetails(data));
    yield put({ type: constant.CREATE_GOAL_SUCCESS });
    yield put(setCifPlanTemp(data.id));
    callback();
  } catch (error) {
    yield put({ type: constant.CREATE_GOAL_FAILED });
  }
}

export function* stepThreeSagaWorker() {
  yield all([takeLatest(constant.CREATE_GOAL, createGoalSaga)]);
}
