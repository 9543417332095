import React from "react";
import {
  FormControl,
  Typography,
  TextField,
  InputAdornment,
  Icon
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";

import NumberFormatCustom from "../NumberFormatCustom";
import styles from "./styles";

const SavingGoalForm = ({
  saving,
  savingError,
  handleChangeSaving,
  handleSavingError,
  handleSetForm,
  checkSymbol,
  setOpenBottom,
  isExist,
  setIsExist,
  classes
}) => {
  return (
    <div className={classes.container}>
      <FormControl fullWidth className={classes.form}>
        <Typography color="textPrimary" className={classes.label}>
          Your goal
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Wedding Day"
          type="text"
          value={saving.name}
          onChange={e => {
            setIsExist(false);
            handleChangeSaving("name", e.target.value);
            checkSymbol();
          }}
          onBlur={e => {
            handleChangeSaving("name", e.target.value);
            handleSetForm();
            checkSymbol();
          }}
          error={savingError.name}
          helperText={
            savingError.name
              ? isExist
                ? "Goal name is already exists"
                : "Please enter a valid goal name"
              : null
          }
          inputProps={{
            className: classes.inputField,
            maxLength: 30
          }}
          FormHelperTextProps={{ className: classes.error }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.form}>
        <Typography color="textPrimary" className={classes.label}>
          Total amount you want to save
        </Typography>
        <TextField
          variant="outlined"
          placeholder="10.00"
          value={saving.target}
          onChange={e => {
            handleChangeSaving("target", e.target.value);
          }}
          onBlur={e => {
            handleChangeSaving("target", e.target.value);
            handleSavingError(
              "target",
              e.target.value < 10 || e.target.value === "" ? true : false
            );
            handleSetForm();
          }}
          error={savingError.target}
          helperText={
            (saving.target < 10 && saving.target !== "") ||
            (savingError.target && saving.target === "")
              ? "Minimum RM 10.00"
              : null
          }
          FormHelperTextProps={{ className: classes.error }}
          inputProps={{
            className: classes.period,
            inputMode: "numeric",
            maxLength: 16
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            style: { paddingLeft: "16px" },
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textPrimary" className={classes.prefix}>
                  RM
                </Typography>
              </InputAdornment>
            )
          }}
        />
      </FormControl>
      <FormControl fullWidth className={classes.form}>
        <Typography color="textPrimary" className={classes.label}>
          You want to achieve your goal in
        </Typography>
        <TextField
          variant="outlined"
          placeholder="12 Months"
          value={
            saving.period
              ? `${saving.period} ${saving.period > 1 ? "Months" : "Month"}`
              : ""
          }
          onClick={() => setOpenBottom(true)}
          error={savingError.period}
          helperText={savingError.period && "Please select period"}
          InputProps={{
            readOnly: true,
            endAdornment: <Icon color="primary">expand_more</Icon>
          }}
          inputProps={{ className: classes.inputField }}
          FormHelperTextProps={{ className: classes.error }}
        />
      </FormControl>
    </div>
  );
};

export default compose(withStyles(styles))(SavingGoalForm);
