import { Checkbox } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import {
  AlertInfo,
  BackButton,
  CustomButton,
  DefaultLoader,
} from "../../components";
import { useSwitching } from "../../contexts/SwitchingContext";
import { setTransfer, resetTransferError } from "./redux/action";
import "./style.summary.scss";

function isSave(switched) {
  if (!("cifid" in switched) || !switched.cifid) return false;

  for (let fromValue of Object.values(switched.from)) {
    if (!fromValue) return false;
  }

  for (let toValue of Object.values(switched.to)) {
    if (!toValue) return false;
  }

  return true;
}

const Summary = ({
  history,
  setTransfer,
  transferError,
  transferLoading,
  resetTransferError,
}) => {
  const { switched } = useSwitching();
  const [isCheck, setCheck] = React.useState(false);
  const [
    isSalesChargeDisclaimerOpen,
    setSalesChargeDisclaimerOpen,
  ] = React.useState(false);

  const principalTnC = () => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf:
        "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf",
      event: "openPdf",
    });
  };

  const handleNext = () => {
    const payload = {
      cifId: switched.cifid,
      from: {
        cifPlanId: switched.from.cifPlanId,
        amount: +switched.amount,
        amountCcy: "MYR",
        fundCd: switched.from.fundCd,
      },
      to: {
        cifPlanId: switched.to.cifPlanId,
        amount: +switched.amount,
        amountCcy: "MYR",
        fundCd: switched.to.fundCd,
      },
    };

    if (switched.maxAmount && +switched.amount >= switched.maxAmount) {
      payload.from.percentage = 100;
      payload.to.percentage = 100;
    }

    setTransfer(payload, () => {
      history.push("/switching/success");
    });
  };

  if (
    !isSave({
      cifid: switched.cifid,
      from: {
        cifPlanId: switched.from.cifPlanId,
        fundCd: switched.from.fundCd,
      },
      to: {
        cifPlanId: switched.to.cifPlanId,
        fundCd: switched.to.fundCd,
      },
    })
  ) {
    history.back();
    return null;
  }

  if (transferLoading) return <DefaultLoader />;

  return (
    <>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={Boolean(transferError)}
        isTimeout
        handleAction={() => {
          resetTransferError();
        }}
      />
      <AlertInfo
        title="Switching Fee"
        isOpen={isSalesChargeDisclaimerOpen}
        handleAction={() => {
          setSalesChargeDisclaimerOpen(false);
        }}
      >
        <ul style={{ marginLeft: "15px", paddingLeft: "15px" }}>
          <li>
            When you switch between classes or into another Principal Malaysia
            fund (or its classes), it's treated as a withdrawal from one and an
            investment in another.
          </li>
          <li>
            You might incur a Switching Fee, which is the difference between the
            Application Fee of the current class and the one you're switching
            into.
          </li>
          <li>
            No Switching Fee will be charged if the new class or Principal
            Malaysia fund (or its classes) has a lower Application Fee.
          </li>
          <li>There may be an administrative fee for each switch.</li>
        </ul>
        <span style={{ display: "inline-block", marginTop: "18px" }}>
          For more details, please refer to the Annexure of the respective
          class.
        </span>
      </AlertInfo>
      <div className="l-summary">
        <BackButton
          handleBack={() => {
            history.back();
          }}
          title="Your Switch Summary"
          className="c-back-btn--space-left"
        />
        <div className="l-summary__content">
          <h5>Switch From:</h5>

          <h5>{switched.from.name}</h5>
          <div className="l-summary__separator" />
          <h5>Switch To:</h5>

          <h5>{switched.to.name}</h5>

          <p>
            A switching fee may apply. Final charges will be confirmed after the
            switch.{" "}
            <span onClick={() => setSalesChargeDisclaimerOpen(true)}>
              More info
            </span>
          </p>
        </div>

        <div className="l-summary__amount">
          <h4>Total Switch Amount</h4>
          <h1>
            RM{" "}
            <NumberFormat
              value={switched.amount}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </h1>
        </div>

        <div className="l-summary__footer">
          <div className="l-summary__terms">
            <Checkbox
              checked={isCheck}
              onChange={() => setCheck(!isCheck)}
              color="primary"
              style={{ margin: 0, padding: 0 }}
            />
            <div>
              <p style={{ marginBottom: "8px" }}>
                I have read and agreed to{" "}
                <span onClick={principalTnC}>
                  Principal’s Terms & Conditions
                </span>
                , and the Unit Trust Loan Financing{" "}
                <span
                  onClick={() => {
                    window.my.navigateTo({
                      url:
                        "/pages/link/link?url=" +
                        encodeURIComponent(
                          "https://www.principal.com.my/en/ut-loan-financing-risk-my/mv"
                        ),
                    });
                  }}
                >
                  Risk Disclosure Statement
                </span>
                .
              </p>
              <p>
                I acknowledge the investment risk. and aware that the fund is
                not E-money, deposit, or capital guaranteed/protected by PIDM.
                Losses are not covered by the Capital Market Compensation Fund.
              </p>
            </div>
          </div>

          <CustomButton
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            disabled={!isCheck || transferLoading}
            onClick={handleNext}
          />
        </div>
      </div>
    </>
  );
};

const withConnect = connect(
  state => ({
    transferError: state.switchingReducer.transferError,
    transferLoading: state.switchingReducer.transferLoading,
  }),
  {
    setTransfer,
    resetTransferError,
  }
);

export default compose(
  withRouter,
  withConnect
)(Summary);
