const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  nav: {
    justifyContent: "space-between",
    padding: "16px 16px 0"
  },
  navLabel: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    marginLeft: "16px"
  },
  filterLabel: {
    lineHeight: 1.25,
    marginRight: "4px"
  },
  formLabel: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.57,
    textTransform: "capitalize"
  },
  navigationLabel: {
    fontFamily: "FSElliotPro",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: 1.4,
    textTransform: "none"
  }
};

export default styles;
