import { GET_STARTED } from "../pages/redux/constant";
let lastFlag = null;

export const getAuthTNG = flag => {
  if (flag === GET_STARTED) {
    window.my.postMessage({
      api: "getAuthCode",
      scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
      event: "getAccessToken",
    });
  } else {
    if (flag !== lastFlag) {
      lastFlag = flag;
      window.my.postMessage({
        api: "getAuthCode",
        scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
        event: "getAccessToken",
      });
    }
  }
};
