const styles = {
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro"
  },
  container: {
    flex: 1,
    backgroundColor: "#f6f6f8",
    minHeight: "100vh"
  },
  navigation: {
    justifyContent: "space-between",
    margin: "16px 16px 8px"
  },
  navigationLabel: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    marginLeft: "16px"
  },
  popUpTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    color: "#262d58",
    margin: "0 16px 16px"
  },
  popUpOptionContainer: {
    justifyContent: "space-between",
    padding: "16px 20px 14px"
  },
  popUpOption: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    fontWeight: "700",
    letterSpacing: "0.32px"
  },
  sortBy: {
    padding: "16px 0 24px"
  },
  icon: {
    cursor: "pointer",
    color: "#0091DA"
  },
  text: {
    marginLeft: "8px",
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.71
  }
};

export default styles;
