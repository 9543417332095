import {
  SET_TRANSFER,
  SET_TRANSFER_FAILED,
  SET_TRANSFER_SUCCESS,
  RESET_TRANSFER_ERROR,
  SET_RESOURCE,
} from "./constant";

export function setTransfer(payload, callback) {
  return {
    type: SET_TRANSFER,
    payload,
    callback,
  };
}

export function setTransferSuccess(data) {
  return {
    type: SET_TRANSFER_SUCCESS,
    payload: data,
  };
}

export function setTransferFailed(error) {
  return {
    type: SET_TRANSFER_FAILED,
    payload: error,
  };
}

export function resetTransferError() {
  return {
    type: RESET_TRANSFER_ERROR,
  };
}

export function setResource(data) {
  return {
    type: SET_RESOURCE,
    payload: data,
  };
}
