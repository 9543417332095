export function groupFunds({ funds = [], scoreList = [] }) {
  const groupedFunds = scoreList.reduce((prev, curr) => {
    prev[curr.nameEn.toLowerCase()] = [];

    funds.forEach(fund => {
      if (
        parseInt(fund.riskProfileCode) >= curr.lowerBound &&
        parseInt(fund.riskProfileCode) <= curr.upperBound
      ) {
        prev[curr.nameEn.toLowerCase()].push(fund);
      }
    });

    return prev;
  }, {});

  return groupedFunds;
}
