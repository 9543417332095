import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { DoneIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";
import { decideTransactionMethod, getCompletedRelated } from "./utils";

const ManualAdjustment = ({ details }) => {
  return (
    <Stepper orientation="vertical" style={{ padding: 0 }}>
      {details[0].adjustments
        .reverse()
        .map(({ completeUnit, completeUnitPrice, navDate }, index) => (
          <Step active key={index}>
            <StepLabel icon={<DoneIcon active />}>
              <Typography
                color="textPrimary"
                style={{
                  ...styles.baseFont,
                  ...styles.label
                }}
              >
                Unit Price Adjustment
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content
                }}
              >
                Unit Price is adjusted to reflect the fund's value.
              </Typography>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Unit: {completeUnit}
                </Typography>

                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Unit Price: RM
                  <NumberFormat
                    value={parseFloat(completeUnitPrice)}
                    thousandSeparator
                    displayType="text"
                    decimalScale={4}
                    fixedDecimalScale
                  />
                </Typography>
              </div>
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content,
                  ...styles.date,
                  marginTop: "8px"
                }}
              >
                {moment(navDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
              </Typography>
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};

const SalesCharge = ({
  salesChargeAmount,
  salesChargeCcy,
  salesChargePercent,
  amount,
  fee
}) => {
  return (
    <>
      <div
        style={{
          marginTop: "3px",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          Total Cash In
        </Typography>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          RM{" "}
          <NumberFormat
            value={amount}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </div>
      <div
        style={{
          marginTop: "3px",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          Administration Fee
        </Typography>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          RM{" "}
          <NumberFormat
            value={fee}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </div>

      <div
        style={{
          marginTop: "3px",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          Sales charge ({salesChargePercent || 0}%)
        </Typography>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content
          }}
        >
          RM{" "}
          <NumberFormat
            value={salesChargeAmount || 0}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </div>
      <div
        style={{
          marginTop: "3px",
          display: "flex",
          justifyContent: "space-between",
          fontWeight: "700",
          color: "#616367"
        }}
      >
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content,
            fontWeight: "inherit",
            color: "inherit"
          }}
        >
          Net amount
        </Typography>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.content,
            fontWeight: "inherit",
            color: "inherit"
          }}
        >
          RM{" "}
          <NumberFormat
            value={amount - fee - salesChargeAmount}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale
          />
        </Typography>
      </div>
    </>
  );
};

const Buy = ({
  status,
  method,
  amount,
  unit,
  fee,
  submittedDate,
  processingDate,
  completedDate,
  details,
  isRsp,
  salesChargeAmount,
  salesChargeCcy,
  salesChargePercent
}) => {
  const completed = getCompletedRelated({
    status,
    isDoneIconActive: status === "5"
  });
  const frontText = isRsp ? "Your Auto" : "Your";

  const isFailed = status === "9";

  const isSubmitted =
    status === "3" ||
    status === "4" ||
    status === "5" ||
    status === "6" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isProcessing =
    status === "4" ||
    status === "5" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isCompleted =
    status === "5" || status === "9" || status === "10" || status === "11";

  return (
    <TransactionHistoryBase
      manualAdjustment={
        details.length > 0 && details[0].adjustments.length > 0 ? (
          <ManualAdjustment details={details} />
        ) : null
      }
    >
      {/* Submitted */}
      <Step active={isSubmitted}>
        <StepLabel icon={<DoneIcon active={isSubmitted} />}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.label }}
          >
            Submitted
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isSubmitted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {frontText} Cash In amount has been successfully submitted.
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Transaction Method: {decideTransactionMethod({ method })}
          </Typography>
          {/* only show this if is rsp OR not rsp and status is not 5 means its either submitted or processing */}
          {(isRsp || (!isRsp && status !== "5")) && (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                marginTop: "8px"
              }}
            >
              Administration Fee: RM
              <NumberFormat
                value={fee}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
              />
            </Typography>
          )}
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {moment(isRsp ? processingDate : submittedDate)
              .utcOffset("+0800")
              .format(isRsp ? "DD MMM YYYY" : "DD MMM YYYY, h:mm a")}
          </Typography>
        </StepContent>
      </Step>

      {/* Processing */}
      <Step active={isProcessing} style={{ marginTop: "24px" }}>
        <StepLabel icon={<DoneIcon active={isProcessing} />}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isProcessing ? "#000000" : "#9D9EA4"
            }}
          >
            Processing
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isProcessing && "#0091DA",
            borderWidth: 2,
            height: "100%",
            marginTop: "0"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {frontText} Cash In amount is being processed
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {processingDate !== null &&
              moment(processingDate)
                .utcOffset("+0800")
                .format(isRsp ? "DD MMM YYYY" : "DD MMM YYYY, h:mm a")}
          </Typography>
        </StepContent>
      </Step>

      {/* Completed */}
      <Step
        active={isCompleted}
        style={{
          marginTop: "24px",
          paddingBottom:
            details.length > 0 && details[0].adjustments.length > 0 && "24px"
        }}
      >
        <StepLabel icon={completed.icon}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color:
                status === "5" || status === "9" || status === "10"
                  ? "#000000"
                  : "#9D9EA4"
            }}
          >
            {completed.text}
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isCompleted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {isFailed
              ? `${frontText} Cash In is unsuccessful.`
              : `${frontText} Cash In amount is successful and has been reflected in your account`}
          </Typography>
          {isFailed ? (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {completedDate !== null &&
                moment(completedDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
            </Typography>
          ) : (
            <>
              {!isRsp && (
                <SalesCharge
                  amount={amount}
                  fee={fee}
                  salesChargeAmount={salesChargeAmount}
                  salesChargeCcy={salesChargeCcy}
                  salesChargePercent={salesChargePercent}
                />
              )}
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography style={{ ...styles.baseFont, ...styles.content }}>
                  Unit: {unit}
                </Typography>
                <Typography style={{ ...styles.baseFont, ...styles.content }}>
                  Unit Price: RM
                  <NumberFormat
                    value={
                      details.length > 0 && details[0].actualUnitPrice !== null
                        ? details[0].actualUnitPrice
                        : parseFloat(amount / unit)
                    }
                    thousandSeparator
                    displayType="text"
                    decimalScale={4}
                    fixedDecimalScale
                  />
                </Typography>
              </div>
            </>
          )}

          {isFailed ||
            (status === "5" && (
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content,
                  ...styles.date,
                  marginTop: "8px"
                }}
              >
                {moment(completedDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
              </Typography>
            ))}
        </StepContent>
      </Step>
    </TransactionHistoryBase>
  );
};

export default Buy;
