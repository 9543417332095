import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography
} from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { DoneIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";
import { decideTransactionMethod, getCompletedRelated } from "./utils";

const ManualAdjustment = ({ details, amount, fee }) => {
  return (
    <Stepper orientation="vertical" style={{ padding: 0 }}>
      {details[0].adjustments
        .reverse()
        .map(({ completeUnit, completeUnitPrice, navDate }, index) => (
          <Step active key={index}>
            <StepLabel icon={<DoneIcon active />}>
              <Typography
                color="textPrimary"
                style={{
                  ...styles.baseFont,
                  ...styles.label
                }}
              >
                Unit Price Adjustment
              </Typography>
            </StepLabel>
            <StepContent>
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content
                }}
              >
                Unit Price is adjusted to reflect the fund's value.
              </Typography>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Unit: {completeUnit}
                </Typography>

                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Unit Price: RM
                  <NumberFormat
                    value={parseFloat(completeUnitPrice)}
                    thousandSeparator
                    displayType="text"
                    decimalScale={4}
                    fixedDecimalScale
                  />
                </Typography>
              </div>
              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Request Cash out (RM):
                </Typography>
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  RM {(amount - fee).toFixed(2)}
                </Typography>
              </div>

              <div
                style={{
                  marginTop: "8px",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  Adjusted Cash out (RM):
                </Typography>
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content
                  }}
                >
                  RM
                  {Number(details[0].adjustmentAmount - fee).toFixed(2)}
                </Typography>
              </div>
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content,
                  ...styles.date,
                  marginTop: "8px"
                }}
              >
                {moment(navDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
              </Typography>
            </StepContent>
          </Step>
        ))}
    </Stepper>
  );
};

const Sell = ({
  status,
  method,
  amount,
  unit,
  fee,
  submittedDate,
  processingDate,
  completedDate,
  details,
  isFullSell,
  isManSell,
  cashOutamtSell
}) => {
  const completed = getCompletedRelated({
    status,
    isDoneIconActive: status === "10"
  });

  const successText = isFullSell
    ? "Full Cash Out has been initiated due to the balance of units are below the minimum unit holding balance threshold."
    : "Your Cash Out payment is successful and has been reflected in your account";

  const isSubmitted =
    status === "3" ||
    status === "4" ||
    status === "5" ||
    status === "6" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isProcessing =
    status === "4" ||
    status === "5" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isCompleted = status === "9" || status === "10" || status === "11";

  return (
    <TransactionHistoryBase
      manualAdjustment={
        details.length > 0 && details[0].adjustments.length > 0 ? (
          <ManualAdjustment details={details} amount={amount} fee={fee} />
        ) : null
      }
    >
      {/* Submitted */}
      <Step active={isSubmitted}>
        <StepLabel icon={<DoneIcon active={isSubmitted} />}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.label }}
          >
            Submitted
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isSubmitted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Cash Out amount has been successfully submitted
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Transaction Method:{" "}
            {decideTransactionMethod({ transactionType: "Sell", method })}
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Cash Out Amount: RM
            <NumberFormat
              value={cashOutamtSell}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>

          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px"
            }}
          >
            Administration Fee: RM
            <NumberFormat
              value={fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
            />
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {moment(submittedDate)
              .utcOffset("+0800")
              .format(`DD MMM YYYY${isManSell ? "" : ", h:mm a"}`)}
          </Typography>
        </StepContent>
      </Step>

      {/* Processing */}
      <Step active={isProcessing} style={{ marginTop: "24px" }}>
        <StepLabel icon={<DoneIcon active={isProcessing} />}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isProcessing ? "#000000" : "#9D9EA4"
            }}
          >
            Processing
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isProcessing && "#0091DA",
            borderWidth: 2,
            height: "100%",
            marginTop: "0"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Cash Out is being processed
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px"
            }}
          >
            {processingDate !== null &&
              moment(processingDate)
                .utcOffset("+0800")
                .format(`DD MMM YYYY${isManSell ? "" : ", h:mm a"}`)}
          </Typography>
        </StepContent>
      </Step>

      {/* Completed */}
      <Step
        active={isCompleted}
        style={{
          marginTop: "24px",
          paddingBottom:
            details.length > 0 && details[0].adjustments.length > 0 && "24px"
        }}
      >
        <StepLabel icon={completed.icon}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color:
                status === "9" || status === "10" || status === "11"
                  ? "#000000"
                  : "#9D9EA4"
            }}
          >
            {completed.text}
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isCompleted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%"
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {status === "9" && "Your Cash Out is unsuccessful"}
            {status === "10" && successText}
            {status === "11" && (
              <>
                invalid account number, please update your bank account with
                our{" "}
                <span
                  onClick={() =>
                    window.my.call("openAppLink", {
                      url: "https://wa.me/60162999792"
                    })
                  }
                  style={{ color: "#0076CF" }}
                >
                  live agent on WhatsApp
                </span>
              </>
            )}
          </Typography>
          {status === "10" && (
            <>
              {isFullSell && (
                <Typography
                  style={{
                    ...styles.baseFont,
                    ...styles.content,
                    marginTop: "8px"
                  }}
                >
                  Your Cash Out payment is successful and has been reflected
                  in your account.
                </Typography>
              )}
              <div
                style={{
                  marginTop: "8px",
                  display: "flex"
                }}
              >
                <Typography style={{ ...styles.baseFont, ...styles.content }}>
                  Unit: {unit}
                </Typography>
                <Typography style={{ ...styles.baseFont, ...styles.content }}>
                  Unit Price: RM
                  <NumberFormat
                    value={
                      details.length > 0 &&
                      (details[0].actualUnitPrice ||
                        parseFloat(amount / unit))
                    }
                    thousandSeparator
                    displayType="text"
                    decimalScale={4}
                    fixedDecimalScale
                  />
                </Typography>
              </div>
            </>
          )}

          {(status === "9" || status === "10" || status === "11") && (
            <Typography
              style={{
                ...styles.baseFont,
                ...styles.content,
                ...styles.date,
                marginTop: "8px"
              }}
            >
              {moment(completedDate)
                .utcOffset("+0800")
                .format("DD MMM YYYY")}
            </Typography>
          )}
        </StepContent>
      </Step>
    </TransactionHistoryBase>
  );
};

export default Sell;
