export const SEND_RISK_ASSESSMENT = "SEND_RISK_ASSESSMENT";
export const SEND_RISK_ASSESSMENT_SUCCESS = "SEND_RISK_ASSESSMENT_SUCCESS";
export const SEND_RISK_ASSESSMENT_FAILED = "SEND_RISK_ASSESSMENT_FAILED";

export const GET_RISK_ASSESSMENT = "GET_RISK_ASSESSMENT";
export const GET_RISK_ASSESSMENT_SUCCESS = "GET_RISK_ASSESSMENT_SUCCESS";
export const GET_RISK_ASSESSMENT_FAILED = "GET_RISK_ASSESSMENT_FAILED";

export const SEND_RISK_PROFILE = "SEND_RISK_PROFILE";
export const SEND_RISK_PROFILE_SUCCESS = "SEND_RISK_PROFILE_SUCCESS";
export const SEND_RISK_PROFILE_FAILED = "SEND_RISK_PROFILE_FAILED";

export const GET_RESULT_ASSESSMENT = "GET_RESULT_ASSESSMENT";
export const GET_RESULT_ASSESSMENT_SUCCESS = "GET_RESULT_ASSESSMENT_SUCCESS";
export const GET_RESULT_ASSESSMENT_FAILED = "GET_RESULT_ASSESSMENT_FAILED";
