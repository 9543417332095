import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { DASHBOARD, GOALDETAILS } from "../redux/constant";
import "./styles.scss";
import { CustomButton } from "../../components";
import { images } from "../../constants";
import styles from "./styles";

const AutoDepositCancel = props => {
  const {
    history,
    match: { params },
    rspDeadActive = {}
  } = props;
  let { disableRSPStatus = {} } = rspDeadActive;

  const [trxRefNo, setTrxRefNo] = useState("Not Found");
  const from = params.from;

  useEffect(() => {
    setTrxRefNo(disableRSPStatus.trxRefNo);
  }, [rspDeadActive]);
  return (
    <div style={styles.container}>
      <div style={{ marginTop: "16px", marginLeft: "16px" }}>
        <img src={images.principalLogo} alt="principal-logo" />
      </div>

      <div style={{ margin: "24px 16px 0" }}>
        <Typography
          color="textPrimary"
          style={{ ...styles.baseFont, fontSize: "20px", fontWeight: "700" }}
        >
          You have successfully cancelled monthly auto Cash In
        </Typography>
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            color: "#1d1d1d",
            margin: "16px 0 24px"
          }}
        >
          Your monthly auto Cash In request will be canceled on the following
          month. Thank you.
        </Typography>
        <hr className="separated-line" />
        <div className="reference-no-container">
          <p>Reference number</p>
          <p>{trxRefNo}</p>
        </div>
      </div>

      <div
        style={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          display: "block",
          marginTop: "auto",
          padding: "0 16px 24px"
        }}
      >
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          onClick={() => history.push("/dashboard")}
        />
      </div>
    </div>
  );
};

const withConnect = connect(state => ({
  rspDeadActive: state.autoDepositReducer
}));

export default compose(
  withRouter,
  withConnect
)(AutoDepositCancel);
