import React from "react";
import { CustomButton } from "..";
import { Dialog } from "@material-ui/core";
import "./style.scss";
import { withStyles } from "@material-ui/core/styles";

const riskDesc = [
  "Search for the preservation and stability of its own capital. Invests primarily in low risk assets.",
  "Interested in obtaining higher return than conservative profile despite having less protection from possible losses.",
  "Looking to achieve maximum potential returns. However, you are prepared to lose part of your capital and able to make good any losses that exceed your main investment amount."
];

const riskColor = ["DDF75B", "5FFEF4", "F76800"];

const risk = ["minimal", "medium", "high"];

function InvestorIcon({ riskIndex }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="49"
      fill="none"
      viewBox="0 0 30 49"
    >
      <path
        fill={`#${riskColor[riskIndex]}`}
        stroke="#282828"
        d="M3.998 26.2l4.64-4.779V45.56c0 1.604 1.277 2.942 2.87 2.942h.559c1.593 0 2.87-1.338 2.87-2.942V33.763h.141V45.56c0 1.604 1.277 2.942 2.871 2.942h.559c1.593 0 2.87-1.338 2.87-2.942V21.435l4.624 4.762a1.555 1.555 0 002.237 0l.806-.83a1.633 1.633 0 000-2.262l-.858-.884v.002l-7.383-7.603a2.49 2.49 0 00-.343-.353l-.046-.047s0 0 0 0a1.554 1.554 0 00-.889-.453 2.359 2.359 0 00-.66-.101H11.15a2.38 2.38 0 00-.645.097c-.33.042-.659.188-.918.458l-.001.001-.117.12c-.064.06-.123.12-.18.186l-8.333 8.58h0a1.633 1.633 0 000 2.261h0l.806.83a1.555 1.555 0 002.237 0z"
      />
      <circle cx="15" cy="7" r="6.5" fill="#fff" stroke="#282828" />
    </svg>
  );
}

const StyledDialog = withStyles({
  scrollPaper: {
    margin: "0 1rem"
  },
  paperFullWidth: {
    minWidth: "100%"
  }
})(Dialog);

const InvestorCard = ({
  riskLevel,
  riskIndex,
  handleSubmit,
  onClose,
  isPopup,
  isOpen,
  desc,
  isLoading
}) => {
  if (isPopup) {
    return (
      <StyledDialog open={isOpen} onClose={onClose} fullWidth>
        <div className="c-investor-card c-investor-card--popup">
          <div className="c-investor-card__bg" />
          <div className="c-investor-card__container">
            <div className="c-investor-card__wrapper">
              <div>
                <p>You are a</p>
                <h3 className="c-investor-card__title">
                  <span>{riskLevel}</span> Investor
                </h3>
              </div>
            </div>
            <h4>Willing to accept a {risk[riskIndex]} level of risk</h4>
            <p className="c-investor-card__desc">
              {desc || riskDesc[riskIndex]}
            </p>
            <CustomButton
              text={isLoading ? "Please wait..." : "Explore Funds"}
              variant="contained"
              color="#1278CC"
              backgroundColor="#fff"
              disabled={isLoading}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </StyledDialog>
    );
  }

  return (
    <div className="c-investor-card">
      <div className="c-investor-card__bg c-investor-card__bg--rounded" />
      <div className="c-investor-card__container">
        <div className="c-investor-card__wrapper">
          <div>
            <p>You are a</p>
            <h3 className="c-investor-card__title">
              <span>{riskLevel}</span> Investor
            </h3>
          </div>
          <InvestorIcon riskIndex={riskIndex} />
        </div>
        <h4>Willing to accept a {risk[riskIndex]} level of risk</h4>
        <p className="c-investor-card__desc">{desc || riskDesc[riskIndex]}</p>
        <CustomButton
          text="Select profile & explore funds"
          variant="contained"
          color="#1278CC"
          backgroundColor="#fff"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default InvestorCard;
