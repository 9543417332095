import React, { useState, useEffect, useRef } from "react";
import { Typography, Icon, Checkbox } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { getTransactionHistory, hideError } from "../redux/action";
import { setStep } from "../../globalRedux/actions";
import { images } from "../../constants";

import {
  BottomNav,
  TransactionHistory,
  Dropdown,
  CustomButton,
} from "../../components";
import { icons } from "../../constants";
import styles from "./styles";
import "../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { useIsFirstRender } from "../../utils/functions/useIsFirstRender";
import { groupTransactionHistoryByMonth } from "../../utils/functions/groupTransactionHistoryByMonth";

const LoaderTrxinfinite = () => {
  return (
    <div className="loader-infinite-container">
      <p className="loader-infinite-container__text">Loading...</p>
      <div className="loader-infinite-container__icon">
        <img src={images.loadingInfiniteScroll} alt="principal-logo" />
      </div>
    </div>
  );
};

const DEFAULT_SIZE = 10;

const Transaction = props => {
  const {
    loadingTransactionHistory,
    transactionHistory,
    getTransactionHistory,
    classes,
  } = props;
  const firstRender = useIsFirstRender();

  const [openFilter, setOpenFilter] = useState(false);
  const [timePeriod, setTimePeriod] = useState({
    from: null,
    to: null,
  });
  const [options, setOptions] = useState({
    type: {
      buy: false, // deposit
      transfer: false, // transfer
      sell: false, // withdrawal,
      rspbuy: false, // rsp
    },
    category: {
      MPInvest: false,
      MPSave: false,
    },
  });

  const [transactions, setTransactions] = useState([]);
  const [filterBtn, setFilterBtn] = useState(false);
  const [previousTransactions, setPreviousTransactions] = useState([]);
  const [payload, setPayload] = useState({
    from: "",
    to: "",
    type: "",
    category: "",
    size: DEFAULT_SIZE,
    page: 1,
  });

  const pageNumber = useRef(1);

  const { numPages, totalPages } = transactionHistory;

  const callTrx = page => {
    const { from, to, type, size, category } = payload;

    getTransactionHistory({
      page,
      size,
      from,
      to,
      type,
      category,
    });
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
    callTrx(pageNumber.current);
  }, []);

  useEffect(() => {
    setTransactions(groupTransactionHistoryByMonth(previousTransactions));
  }, [previousTransactions]);

  useEffect(() => {
    if (firstRender) return;

    setPreviousTransactions(prevData => [
      ...prevData,
      ...transactionHistory.data,
    ]);
  }, [transactionHistory]);

  useEffect(() => {
    if (timePeriod.from instanceof Date && timePeriod.to instanceof Date) {
      if (timePeriod.from.getTime() > timePeriod.to.getTime()) {
        setFilterBtn(true);
      } else {
        setFilterBtn(false);
      }
    }
  }, [timePeriod]);

  const handleChangeCheck = field => ({ target: { name } }) => {
    dataLayerGTM("click_tag", "Click", "Checkbox", `Check ${name}`);
    setOptions(prevState => {
      prevState[field] = {
        ...prevState[field],
        [name]: !prevState[field][name],
      };
      return { ...prevState };
    });
  };

  /**
   *
   * get keys of options, filter the `true` one and join it with `,`
   *
   * @param {"category" | "type"} field
   * @returns concatenation of checked option
   */
  const getCheckedOptions = field => {
    return Object.keys(options[field])
      .filter(key => options[field][key])
      .join(",");
  };

  const handleApplyFilter = () => {
    setPreviousTransactions([]);

    const endMonthExist = moment(timePeriod.to)
      .endOf("month")
      .format("YYYY-MM-DD");

    pageNumber.current = 1;

    const payload = {
      from: timePeriod.from ? moment(timePeriod.from).format("YYYY-MM-DD") : "",
      to: timePeriod.to ? endMonthExist : "",
      category: getCheckedOptions("category"),
      type: getCheckedOptions("type"),
      size: DEFAULT_SIZE,
      page: pageNumber.current,
    };

    setPayload(payload);
    getTransactionHistory(payload);
    setOpenFilter(false);
  };

  return (
    <div style={{ backgroundColor: "#f6f6f8" }}>
      {/* Dropdown */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
        isOpen={openFilter}
        handleDismiss={() => {
          setOpenFilter(false);
        }}
        footer={
          <CustomButton
            text="Apply Filter"
            variant="contained"
            color="#fff"
            backgroundColor="#1278cc"
            onClick={handleApplyFilter}
            disabled={filterBtn}
          />
        }
      >
        <div style={{ margin: "24px 16px" }}>
          <Typography
            color="secondary"
            style={{
              ...styles.baseFont,
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: 1.4,
            }}
          >
            Transaction Filter
          </Typography>
        </div>
        <div style={{ ...styles.column, flex: 1, margin: "0 16px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.formLabel }}
          >
            Time Period
          </Typography>
          <div
            style={{
              ...styles.row,
              justifyContent: "space-between",
              margin: "8px 0 14px",
            }}
          >
            <div style={{ ...styles.column, marginRight: "8px" }}>
              <Typography
                color="textPrimary"
                style={{ ...styles.baseFont, ...styles.formLabel }}
              >
                From
              </Typography>
              <div className="inputField">
                <DatePicker
                  selected={timePeriod.from}
                  onChange={date => {
                    setTimePeriod({
                      from: date,
                      to: timePeriod.to,
                    });
                  }}
                  onFocus={e => e.target.blur()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  popperPlacement="bottom-start"
                  className="date"
                  maxDate={new Date()}
                />
                <Icon
                  className="material-icons-outlined"
                  style={{ color: "#1278cc", fontSize: "18px" }}
                >
                  calendar_today
                </Icon>
              </div>
            </div>
            <div style={{ ...styles.column, marginLeft: "8px" }}>
              <Typography
                color="textPrimary"
                style={{ ...styles.baseFont, ...styles.formLabel }}
              >
                To
              </Typography>
              <div className="inputField">
                <DatePicker
                  selected={timePeriod.to}
                  onChange={date =>
                    setTimePeriod({ from: timePeriod.from, to: date })
                  }
                  onFocus={e => e.target.blur()}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  popperPlacement="bottom-end"
                  className="date"
                  maxDate={new Date()}
                />
                <Icon
                  className="material-icons-outlined"
                  style={{ color: "#1278cc", fontSize: "18px" }}
                >
                  calendar_today
                </Icon>
              </div>
            </div>
          </div>
          <div>
            <Typography
              color="textPrimary"
              style={{ ...styles.baseFont, ...styles.formLabel }}
            >
              Asset Type
            </Typography>
            <div style={{ marginTop: "8px" }}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  border: "solid 1px rgba(51, 51, 51, 0.2)",
                  padding: "0 16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  Savings
                </Typography>
                <Checkbox
                  checked={options.category.MPSave}
                  name="MPSave"
                  onChange={handleChangeCheck("category")}
                  style={{ color: "#0091da" }}
                />
              </div>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  border: "solid 1px rgba(51, 51, 51, 0.2)",
                  padding: "0 16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  Invest
                </Typography>
                <Checkbox
                  checked={options.category.MPInvest}
                  name="MPInvest"
                  onChange={handleChangeCheck("category")}
                  style={{ color: "#0091da" }}
                />
              </div>
            </div>
          </div>
          <div>
            <Typography
              color="textPrimary"
              style={{ ...styles.baseFont, ...styles.formLabel }}
            >
              Transaction Type
            </Typography>
            <div style={{ marginTop: "8px" }}>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  border: "solid 1px rgba(51, 51, 51, 0.2)",
                  padding: "0 16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  Auto Cash In
                </Typography>
                <Checkbox
                  checked={options.type.rspbuy}
                  name="rspbuy"
                  onChange={handleChangeCheck("type")}
                  style={{ color: "#0091da" }}
                />
              </div>
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  border: "solid 1px rgba(51, 51, 51, 0.2)",
                  padding: "0 16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  Cash In
                </Typography>
                <Checkbox
                  checked={options.type.buy}
                  name="buy"
                  onChange={handleChangeCheck("type")}
                  style={{ color: "#0091da" }}
                />
              </div>
              {/* <div
                  style={{
                    ...styles.row,
                    justifyContent: "space-between",
                    borderRadius: "4px",
                    border: "solid 1px rgba(51, 51, 51, 0.2)",
                    padding: "0 16px",
                    marginBottom: "16px"
                  }}
                >
                  <Typography
                    color="textPrimary"
                    style={{ ...styles.baseFont, fontWeight: "700" }}
                  >
                    Transfer
                  </Typography>
                  <Checkbox
                    checked={isChecked.Transfer}
                    onChange={() => handleChangeCheck("Transfer")}
                    style={{ color: "#0091da" }}
                  />
                </div> */}
              <div
                style={{
                  ...styles.row,
                  justifyContent: "space-between",
                  borderRadius: "4px",
                  border: "solid 1px rgba(51, 51, 51, 0.2)",
                  padding: "0 16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, fontWeight: "700" }}
                >
                  Cash Out
                </Typography>
                <Checkbox
                  checked={options.type.sell}
                  name="sell"
                  onChange={handleChangeCheck("type")}
                  style={{ color: "#0091da" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dropdown>

      <div style={{ ...styles.row, ...styles.nav }}>
        <Typography color="secondary" className={classes.navigationLabel}>
          Transaction history
        </Typography>
        <div
          style={{ ...styles.row }}
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Button",
              "Transaction Filter Button"
            );
            setOpenFilter(true);
          }}
        >
          <img src={icons.filter} alt="filter" />
          <Typography
            color="primary"
            style={{ ...styles.baseFont, ...styles.filterLabel }}
          >
            Filter
          </Typography>
        </div>
      </div>

      {/* Content */}

      <div
        style={{
          ...styles.column,
          margin: "auto 16px",
          minHeight: "calc(100vh - 104px)",
        }}
      >
        <InfiniteScroll
          dataLength={previousTransactions.length}
          next={() =>
            window.scrollY === 0 ? {} : callTrx((pageNumber.current += 1))
          }
          hasMore={numPages < totalPages}
          loader={
            loadingTransactionHistory &&
            pageNumber.current !== 1 && <LoaderTrxinfinite />
          }
          style={{ overflowX: "hidden" }}
        >
          <TransactionHistory
            transactions={transactions}
            loading={loadingTransactionHistory && pageNumber.current === 1}
          />
        </InfiniteScroll>
      </div>

      {/* Bottom Navigation */}
      <div style={{ position: "sticky", bottom: 0, width: "100%" }}>
        <BottomNav />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    transactionHistory: state.goal.transactionHistory,
    loadingTransactionHistory: state.goal.loadingTransactionHistory,
  }),
  {
    getTransactionHistory,
    setStep,
    hideError,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(Transaction);
