const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  leading: {
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: 1.33,
    color: "#f1fafe"
  },
  caption: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    color: "#e5f4fb",
    margin: "4px 0 24px"
  },
  icon: {
    width: "24px",
    height: "24px"
  },
  featureLabel: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.57,
    color: "#fff",
    marginLeft: "16px"
  },
  footerLabel: {
    fontWeight: "normal",
    fontSize: "9px",
    lineHeight: "10.94px",
    color: "rgba(0,0,0,0.73)",
    marginBottom: "4px"
  },
  btn: {
    margin: "24px 0"
  },
  infoTitle: {
    marginLeft: "16px",
    marginBottom: "8px",
    fontSize: "20px",
    fontWeight: "900",
    lineHeight: 1.4
  },
  rowSpaceBetween: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  bold: {
    fontFamily: "FSElliotPro",
    fontWeight: "bold"
  },
  bolder: {
    fontFamily: "FSElliotPro",
    fontWeight: "900"
  },
  subLabel: {
    fontSize: "12px"
  },
  link: {
    cursor: "pointer",
    textDecoration: "underline"
  },
  featureLabelCampaign: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: 1.57,
    color: "#fff",
    marginLeft: "16px"
  }
};
export default styles;
