export const CREATE_GOAL_DL = "CREATE_GOAL_DL";
export const CREATE_GOAL_DL_SUCCESS = "CREATE_GOAL_DL_SUCCESS";
export const CREATE_GOAL_DL_FAILED = "CREATE_GOAL_DL_FAILED";

export const GET_CGOAL_DL = "GET_CGOAL_DL";
export const GET_CGOAL_DL_SUCCESS = "GET_CGOAL_DL_SUCCESS";
export const GET_CGOAL_DL_FAILED = "GET_CGOAL_DL_FAILED";

export const DL_CHECKOUT = "DL_CHECKOUT";
export const DL_CHECKOUT_SUCCESS = "DL_CHECKOUT_SUCCESS";
export const DL_CHECKOUT_FAILED = "DL_CHECKOUT_FAILED";

export const SET_DL_AMOUNT = "SET_DL_AMOUNT";
export const CLEAR_DL_CHECKOUT_ERROR = "CLEAR_DL_CHECKOUT_ERROR";
