import React from "react";
import { icons } from "../../constants";
import "./style.scss";

const MenuBar = ({
  handleDeposit,
  handleWithdraw,
  handleAutoDeposit,
  handleSwitching,
  isBackgroundGrayed = false,
  rspText
}) => {
  return (
    <div
      className={`c-menu-bar ${isBackgroundGrayed ? "c-menu-bar--grayed" : ""}`}
    >
      <div className="c-menu-bar__wrapper" onClick={handleAutoDeposit}>
        <img
          src={icons.autoCashIn}
          alt="auto deposit"
          className="c-menu-bar__icon"
        />
        <p className="c-menu-bar__title">{rspText || "Auto Cash In"}</p>
      </div>

      <div className="c-menu-bar__wrapper" onClick={handleWithdraw}>
        <img src={icons.withdraw} alt="withdraw" className="c-menu-bar__icon" />
        <p className="c-menu-bar__title">Cash Out</p>
      </div>

      <div className="c-menu-bar__wrapper" onClick={handleSwitching}>
        <img
          src={icons.switching}
          alt="switching"
          className="c-menu-bar__icon"
        />
        <p className="c-menu-bar__title">Switch</p>
      </div>

      <div className="c-menu-bar__wrapper" onClick={handleDeposit}>
        <img src={icons.deposit} alt="deposit" className="c-menu-bar__icon" />
        <p className="c-menu-bar__title">Cash In</p>
      </div>
    </div>
  );
};

export default MenuBar;
