import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@material-ui/core";
import NumberFormat from "react-number-format";
import {
  TermCondition,
  CustomButton,
  DefaultLoader,
  AlertInfo
} from "../../components";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { makeDeposit, clearErrorCashIn } from "../deposit/redux/action";
import { resetCifPlanTemp } from "../step3/redux/action";
import { setRejectAlert, clearError } from "../redux/action";
import "./styles.scss";

function DepositSummary(props) {
  const {
    savingGoalDetails,
    depositSummaryPayload,
    history,
    makeDeposit,
    makeDepositLoading,
    loading,
    clearError,
    setRejectAlert,
    errorCashIn,
    clearErrorCashIn,
    selectedPromo
  } = props;

  const [isCheck, setCheck] = useState(false);
  const isSubmitted = useRef(false)

  useEffect(() => {
    if (!depositSummaryPayload) {
      history.push("/dashboard");
      return;
    }
  }, []);

  const getCampaignCode = () => {
    let promoPayload = "";
    if (Object.keys(selectedPromo).length === 0) {
      return promoPayload;
    }
    if (selectedPromo.selectedReferralCode) {
      promoPayload = selectedPromo.selectedReferralCode;
      return promoPayload;
    }
    if (Array.isArray(selectedPromo.eligibilityRules.codes)) {
      const getPromoPayload = selectedPromo.eligibilityRules.codes.filter(
        code => code.isPublic
      );
      if (Array.isArray(getPromoPayload) && getPromoPayload.length > 0) {
        promoPayload = getPromoPayload[0].code;
      }
    }
    return promoPayload;
  };

  const handleMakeDeposit = () => {
    if(isSubmitted.current) return;

    isSubmitted.current = true;

    makeDeposit({
      ...depositSummaryPayload,
      campaignCode: getCampaignCode()
    });
  };

  if (loading || makeDepositLoading)
    return (
      <div className="center">
        <DefaultLoader />
      </div>
    );

  return (
    <>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCashIn}
        isTimeout
        handleAction={() => {
          clearErrorCashIn();
          clearError();
          setRejectAlert(false);
          resetCifPlanTemp();
          history.push("/dashboard");
        }}
      />
      <div className="deposit-wrapper">
        <div className="back-btn-wrapper">
          <Icon
            className="material-icons-outlined"
            onClick={() => {
              history.back();
            }}
          >
            arrow_back
          </Icon>
          <h4 className="investment-summary">Your Investment Summary</h4>
        </div>
      </div>

      <div>
        <div className="investment-summary-box">
          <h2>{savingGoalDetails.planAlias}</h2>
          <div>
            <h4>
              <span>Cash In</span>
              <span>
                RM
                <NumberFormat
                  value={depositSummaryPayload.amount}
                  thousandSeparator
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </span>
            </h4>
            <p>
              <span>Inclusive sales charge of</span> <span>0%</span>
            </p>
            <h4>
              {Object.keys(selectedPromo).length > 0 && (
                <>
                  <span>{selectedPromo.name}</span>
                  <span>-</span>{" "}
                </>
              )}
            </h4>
          </div>
        </div>
      </div>
      <div className="deposit-summary-value-box">
        <p className="deposit-summary-value-box__cahsin-label">Total Cash In</p>
        <p className="deposit-summary-value-box__total-cashin">
          RM{" "}
          <NumberFormat
            value={depositSummaryPayload.amount}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </div>
      <div className="deposit-term">
        <TermCondition
          deposit
          checked={isCheck}
          onChange={() => setCheck(!isCheck)}
        />
      </div>
      <div className="invest-summary-confirm">
        <CustomButton
          text="Cash In"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!isCheck}
          onClick={handleMakeDeposit}
        />
      </div>
    </>
  );
}

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    savingGoalDetails: state.goal.savingGoalDetails,
    ...state.autoDepositReducer,
    ...state.promoReducer
  }),
  {
    makeDeposit,
    clearErrorCashIn,
    resetCifPlanTemp,
    clearError,
    setRejectAlert
  }
);

export default compose(
  withRouter,
  withConnect
)(DepositSummary);
