import {
  GET_ALLOW_PROFILE,
  GET_ALLOW_PROFILE_FAILED,
  GET_ALLOW_PROFILE_SUCCESS
} from "./constans";

const initialState = { allowUpdateProfile: { value: false }, loading: false };

export default function profileReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case GET_ALLOW_PROFILE:
      return {
        ...state,
        loading: true
      };
    case GET_ALLOW_PROFILE_SUCCESS:
      return {
        ...state,
        allowUpdateProfile: payload,
        loading: false
      };
    case GET_ALLOW_PROFILE_FAILED:
      return {
        ...state,
        allowUpdateProfile: { value: false },
        loading: false
      };

    default:
      return state;
  }
}
