const styles = {
  btn: {
    borderRadius: "100px",
    padding: "14px 24px",
    fontFamily: "FSElliotPro",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: 1.25,
    letterSpacing: "0.32px",
    textTransform: "none",
    width: "100%"
  },
  disabledBtn: {
    boxShadow: "0 12px 24px -10px #9d9ea4"
  },
  activeBtn: {
    boxShadow: "0 12px 24px -10px rgba(16, 109, 186, 0.6)"
  }
};

export default styles;
