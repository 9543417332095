const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  container: {
    flex: 1,
    minHeight: "100vh"
  },
  nav: {
    margin: "16px 16px 24px",
    justifyContent: "space-between"
  },
  mark: {
    color: "#0076cf",
    lineHeight: 1.25
  },
  content: { margin: "0 16px" },
  date: {
    fontSize: "12px",
    lineHeight: 1.83,
    color: "#9d9ea4"
  },
  btn: {
    display: "block",
    marginTop: "auto",
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "16px"
  }
};

export default styles;
