import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import languageProviderReducer from "../../containers/LanguageProvider/reducer";
import globalReducer from "../../globalRedux/reducer";
import authReducer from "../../containers/Authentication/redux/reducer";
import loaderReducer from "./loader";
import goalReducer from "../../pages/redux/reducer";
import onBoardingReducer from "../../pages/onBoarding/redux/reducer";
import autoDepositReducer from "../../pages/deposit/redux/reducer";
import dashboardReducer from "../../pages/dashboard/redux/reducer";
import goalDetailReducer from "../../pages/goalDetails/redux/reducer";
import commingFromReducer from "../../globalRedux/reducer/commingFromReducer";
import getStartedReducer from "../../pages/getStarted/redux/reducer";
import riskAssessmentReducer from "../../pages/riskAssessment/redux/reducer";
import fundSelectionReducer from "../../pages/fundSelection/redux/reducer";
import investPortfolioReducer from "../../pages/fundSelectionStep2/redux/reducer";
import investCheckoutReducer from "../../pages/fundSelectionStep3/redux/reducer";
import custinvestReducer from "../../pages/investList/redux/reducer";
import custFundDetailReducer from "../../pages/fundDetails/redux/reducer";
import promoReducer from "../../pages/promoList/redux/reducer";
import rewardReducer from "../../pages/rewards/redux/reducer";
import stepThreeReducer from "../../pages/step3/redux/reducer";
import timestampReducer from "../../utils/timestampReducer";
import switchingReducer from "../../pages/switching/redux/reducer";
import profileReducer from "../../pages/profile/redux/reducer";
import deeplinkReducer from "../../pages/DeepLink/redux/reducer";
const combineReducer = combineReducers({
  auth: authReducer,
  form,
  globalReducer,
  language: languageProviderReducer,
  loaderReducer,
  goal: goalReducer,
  onBoardingReducer,
  autoDepositReducer,
  dashboardReducer,
  goalDetailReducer,
  commingFromReducer,
  getStartedReducer,
  riskAssessmentReducer,
  fundSelectionReducer,
  investPortfolioReducer,
  investCheckoutReducer,
  custinvestReducer,
  custFundDetailReducer,
  promoReducer,
  rewardReducer,
  stepThreeReducer,
  timestampReducer,
  switchingReducer,
  profileReducer,
  deeplinkReducer,
});

const reducers = (state, action) => {
  if (action.type === "RESET_ALL_STATE") {
    state = undefined;
  }
  return combineReducer(state, action);
};

export default reducers;
