import React, { useState, useEffect } from "react";
import { Typography, Icon, TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import { updateProfile, hideError } from "../../redux/action";

import {
  StepLabel,
  CustomInput,
  Dropdown,
  CountryItem,
  CustomButton,
  DefaultLoader
} from "../../../components";
import styles from "../styles";

const TaxInfo = props => {
  const {
    loading,
    isModalOpen,
    errorMessage,
    profile,
    countryList,
    updateProfile,
    hideError,
    history
  } = props;

  let alphaNumericFormat = /^[a-zA-Z0-9]{0,20}$/;

  const [taxCountryList, setTaxCountryList] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [taxInfo, setTaxInfo] = useState({
    otherMalaysia: false,
    taxIdNo: "",
    taxCountry: ""
  });
  const [isTaxCountryOpen, setTaxCountryOpen] = useState(false);
  const [selectedTaxCountry, setSelectedTaxCountry] = useState("");
  const [taxCountrySearch, setTaxCountrySearch] = useState("");
  const [taxCountrySearchOutput, setTaxCountrySearchOutput] = useState([]);

  useEffect(() => {
    if (countryList) {
      let sortedName = countryList.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      let sorted = sortedName.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
      setTaxCountryList(sorted);
    }
  }, [countryList]);

  useEffect(() => {
    setTaxCountrySearchOutput([]);
    taxCountryList.filter(country => {
      if (country.name.toLowerCase().includes(taxCountrySearch.toLowerCase())) {
        setTaxCountrySearchOutput(prevState => [...prevState, country]);
      }
    });
  }, [taxCountrySearch]);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setTaxInfo({
        ...taxInfo,
        otherMalaysia:
          userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
            ? "Yes"
            : "No",
        taxIdNo:
          userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
            ? userInfo.identities.find(identity => identity.idTypeCd === "TAX")
                .idNo
            : ""
      });
      setSelectedTaxCountry(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? userInfo.identities.find(identity => identity.idTypeCd === "TAX")
              .citizenship
          : ""
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (selectedTaxCountry && taxCountryList) {
      setTaxInfo(prevState => ({
        ...prevState,
        taxCountry: taxCountryList.find(
          countryItem => countryItem.code === selectedTaxCountry
        )
          ? taxCountryList.find(
              countryItem => countryItem.code === selectedTaxCountry
            ).name
          : ""
      }));
    }
  }, [selectedTaxCountry, taxCountryList]);

  const handleChangeTaxInfo = e => {
    const { name, value } = e.target;

    setTaxInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectTaxCountry = id => {
    setSelectedTaxCountry(id);

    const ctry = taxCountryList.find(item => item.code === id);
    setTaxInfo({ ...taxInfo, taxCountry: ctry.name });

    let temp = [...taxCountryList];

    temp.forEach((item, index) => {
      if (item.code === id) {
        temp.splice(index, 1);
        temp.unshift(item);
      }
    });

    setTaxCountryList(temp);
    setTaxCountrySearch("");
    setTaxCountryOpen(false);
  };

  const handleUpdate = () => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: userInfo.investmentObjCd,
      employerName: userInfo.employerName,
      identities:
        taxInfo.otherMalaysia === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              },
              {
                idTypeCd: "TAX",
                idNo: taxInfo.taxIdNo ? taxInfo.taxIdNo : "",
                citizenship: selectedTaxCountry ? selectedTaxCountry : ""
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              }
            ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        line2: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line2,
        line3: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line3,
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R
        city: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship //R
      }
    };

    // window.consoleLog(payload);
    updateProfile({ payloadData: payload, from: PROFILE });
  };

  const handleChangeTaxId = value => {
    if (alphaNumericFormat.test(value) || value === "") {
      setTaxInfo(prevState => ({
        ...prevState,
        taxIdNo: value
      }));
    }
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}
      {/* Tax Country List */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
        isOpen={isTaxCountryOpen}
        handleDismiss={() => setTaxCountryOpen(false)}
      >
        <div style={{ margin: "16px 16px 24px" }}>
          <Typography
            color="secondary"
            style={{ ...styles.baseFont, ...styles.dropdownTitle }}
          >
            Update Tax Country
          </Typography>
          {/* Search */}
          <div style={{ marginTop: "16px" }}>
            <TextField
              placeholder="Type country name here"
              variant="outlined"
              fullWidth
              value={taxCountrySearch}
              onChange={e => setTaxCountrySearch(e.target.value)}
              inputProps={{
                style: {
                  ...styles.baseFont,
                  padding: "12px 16px",
                  color: "#333"
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <Icon
                      className="material-icons-outlined"
                      style={{ cursor: "pointer", color: "#0091DA" }}
                    >
                      search
                    </Icon>
                  </InputAdornment>
                )
              }}
            />
          </div>
          {/* Country List */}
          <div style={{ marginTop: "24px" }}>
            {taxCountrySearch.length > 0 ? (
              <>
                <CountryItem
                  country={taxInfo.taxCountry}
                  isSelected={selectedTaxCountry ? true : false}
                  containerStyles={{
                    borderBottom: "1px solid #C4C6C5",
                    paddingBottom: "24px"
                  }}
                />

                {taxCountrySearchOutput.map((item, index) => {
                  return (
                    <CountryItem
                      key={index}
                      country={item.name}
                      onClick={() => handleSelectTaxCountry(item.code)}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {taxCountryList.map((item, index) => {
                  return (
                    <CountryItem
                      key={index}
                      country={item.name}
                      isSelected={selectedTaxCountry === item.code}
                      onClick={() => handleSelectTaxCountry(item.code)}
                      containerStyles={
                        selectedTaxCountry &&
                        index === 0 && {
                          borderBottom: "1px solid #C4C6C5",
                          paddingBottom: "24px"
                        }
                      }
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>
      </Dropdown>

      <StepLabel
        title="Update Tax Resident Info"
        containerStyles={{
          margin: "16px 16px 24px",
          alignItems: "flex-start"
        }}
        onClick={() => history.back()}
      />
      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Are you a tax resident of any country other than Malaysia"
          name="otherMalaysia"
          value={taxInfo.otherMalaysia}
          onChange={handleChangeTaxInfo}
          isEdit={true}
          isRequired={false}
          isRadio={true}
        />
        <CustomInput
          label="Tax Country"
          name="taxCountry"
          placeholder="Please select a country"
          value={taxInfo.otherMalaysia === "Yes" ? taxInfo.taxCountry : ""}
          onClick={() => setTaxCountryOpen(true)}
          isEdit={taxInfo.otherMalaysia === "Yes" ? true : false}
          isRequired={true}
          isDropdown={true}
        />
        <CustomInput
          label="Tax ID Number"
          name="taxIdNo"
          value={taxInfo.otherMalaysia === "Yes" ? taxInfo.taxIdNo : ""}
          onChange={e => handleChangeTaxId(e.target.value)}
          isEdit={taxInfo.otherMalaysia === "Yes" ? true : false}
          isRequired={true}
        />
      </div>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={
            taxInfo.otherMalaysia === "Yes"
              ? selectedTaxCountry.length > 0 &&
                taxInfo.taxIdNo.length > 0 &&
                taxInfo.taxIdNo.length <= 20
                ? false
                : true
              : false
          }
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile,
    countryList: state.goal.countryList
  }),
  {
    updateProfile,
    hideError
  }
);

export default compose(
  withRouter,
  withConnect
)(TaxInfo);
