import {
  CREATE_GOAL,
  RESET_ERROR_GOAL_CREATION,
  SET_CIFPLAN_TEMP,
  RESET_CIFPLAN_TEMP
} from "./constants";

export const createGoal = (payload, callback) => {
  return {
    type: CREATE_GOAL,
    payload,
    callback
  };
};

export const resetErrorGoalCreation = () => {
  return {
    type: RESET_ERROR_GOAL_CREATION
  };
};

export const setCifPlanTemp = payload => {
  return {
    type: SET_CIFPLAN_TEMP,
    payload
  };
};

export const resetCifPlanTemp = () => {
  return {
    type: RESET_CIFPLAN_TEMP
  };
};
