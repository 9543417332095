import React from "react";
import { Typography } from "@material-ui/core";

import styles from "./styles";

const Info = ({
  label,
  value,
  isEdit,
  containerStyle,
  valueStyle,
  onClick
}) => {
  return (
    <div style={{ ...styles.info, ...containerStyle, flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          color="textPrimary"
          style={{ ...styles.baseFont, ...styles.label }}
        >
          {label}
        </Typography>

        {isEdit && (
          <Typography color="primary" style={styles.baseFont} onClick={onClick}>
            Edit
          </Typography>
        )}
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            ...valueStyle,
            wordWrap: "break-word",
            paddingRight: "40px"
          }}
        >
          {value}
        </Typography>
      </div>
    </div>
  );
};

export default Info;
