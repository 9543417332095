import React from "react";
import { Typography, Checkbox } from "@material-ui/core";

const TermCondition = ({ message, checked, onChange, deposit }) => {
  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf"
    });
  };

  return (
    <>
      <Checkbox
        checked={checked}
        onChange={onChange}
        color="primary"
        style={{ margin: 0, padding: 0 }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "16px"
        }}
      >
        <Typography
          style={{
            fontFamily: "FSElliotPro",
            fontSize: deposit ? "12px" : "14px",
            fontWeight: "normal",
            lineHeight: 1.29,
            color: "#787878"
          }}
        >
          I have read and agreed to{" "}
          <span
            style={{ color: "#0076cf" }}
            onClick={() =>
              handleOpenPDF(
                "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf"
              )
            }
          >
            Principal’s Terms & Conditions
          </span>
          , the{" "}
          <span
            style={{ color: "#0076cf" }}
            onClick={() =>
              handleOpenPDF(
                `${
                  process.env.REACT_APP_ENV.trim() === "production"
                    ? "https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Money_Market_Fund_PROP.pdf"
                    : "https://www.test.principal.com.my/sites/default/files/fund-documents/Malaysia Site/en_Principal_Islamic_Money_Market_Fund_1st_SUPP_PROP.pdf"
                }`
              )
            }
          >
            Prospectus
          </span>
          , the{" "}
          <span
            style={{ color: "#0076cf" }}
            onClick={() =>
              handleOpenPDF(
                "https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal%20Islamic_Money_Market_Fund_1st_SUPP_PROP.pdf"
              )
            }
          >
            Supplemental Prospectus
          </span>
          , the{" "}
          <span
            style={{ color: "#0076cf" }}
            onClick={() =>
              handleOpenPDF(
                `${
                  process.env.REACT_APP_ENV.trim() === "production"
                    ? "https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Money_Market_Fund_PHS.pdf"
                    : "https://www.test.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Money_Market_Fund_PHS.pdf"
                }`
              )
            }
          >
            Product Highlight Sheet
          </span>{" "}
          and the{" "}
          <span
            style={{ color: "#0076cf" }}
            onClick={() =>
              window.my.navigateTo({
                url:
                  "/pages/link/link?url=" +
                  encodeURIComponent(
                    "https://www.principal.com.my/en/ut-loan-financing-risk-my/mv"
                  )
              })
            }
          >
            Unit Trust Loan Financing Risk Disclosure Statement
          </span>
          .
        </Typography>
        <Typography
          style={{
            fontFamily: "FSElliotPro",
            fontSize: "14px",
            fontWeight: "normal",
            lineHeight: 1.29,
            marginTop: "8px",
            color: "#505050"
          }}
        >
          {message}
        </Typography>
      </div>
    </>
  );
};

export default TermCondition;
