import { all, takeLatest, call, put } from "redux-saga/effects";
import * as constant from "./constants";
import { getCustInvestSuccess, getCustInvestFailed } from "./actions";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

function* getCustInvestSaga({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer-portfolio/${payload}`
    );
    const { data } = res;
    yield put(getCustInvestSuccess(data));
  } catch (error) {
    yield put(getCustInvestFailed(error.message));
  }
}

export function* custInvestSagaWorker() {
  yield all([takeLatest(constant.GET_CUST_INVEST, getCustInvestSaga)]);
}
