const styles = {
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  navigationLabel: {
    fontFamily: "FSElliotPro",
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: 1.4,
    marginLeft: "16px",
    textTransform: "none"
  },
  icon: {
    cursor: "pointer",
    color: "#0091DA"
  },
  text: {
    marginLeft: "8px",
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: 1.71
  },
  fundInfo: {
    marginLeft: "6px"
  }
};

export default styles;
