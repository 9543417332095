import {
  GET_REWARDS,
  GET_REWARDS_SUCCESS,
  GET_REWARDS_FAILED,
  SET_REWARD_DETAIL
} from "./constants";

const initialState = {
  rewards: { data: [] },
  rewardDetail: {}
};

export default function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_REWARDS:
      return {
        ...state,
        rewardLoading: true
      };
    case GET_REWARDS_SUCCESS:
      return {
        ...state,
        rewards: action.payload,
        rewardLoading: false
      };
    case GET_REWARDS_FAILED:
      return {
        ...state,
        rewardLoading: false
      };
    case SET_REWARD_DETAIL:
      return {
        ...state,
        rewardDetail: action.payload
      };
    default:
      return state;
  }
}
