import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  TextField,
  Button,
  InputAdornment,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
//import { v4 as uuidv4 } from "uuid";
import NumberFormat from "react-number-format";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  CATEGORY_SAVING,
  GENERAL_SAVING,
  GOAL_CREATION,
  DASHBOARD,
  GOALDETAILS,
} from "../redux/constant";
import {
  hideError,
  setRejectAlert,
  setFailureAlert,
  clearError,
  getCustomer,
} from "../redux/action";
import { setStep } from "../../globalRedux/actions";
import {
  clearErrorCashIn,
  setDepositSummary,
  setLastAmountSave,
} from "./redux/action";
import { resetCifPlanTemp } from "../step3/redux/action";
import {
  StepLabel,
  CustomButton,
  NumberFormatCustom,
  // DecimalFormatCustom,
  DefaultLoader,
  Alert,
  AlertInfo,
  InputBasic,
} from "../../components";
import styles from "./styles";
import "../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import crypto from "crypto";
import { deleteGoalForm } from "../redux/action";
import {
  getPromo,
  setSelectedPromo,
  resetSelectedPromo,
} from "../promoList/redux/action";
import { getSelectedPromoByCode } from "../../utils/functions/getSelectedPromoByCode";

const SAVE = "SAVE";
const MakeDeposit = props => {
  const {
    loading,
    savingType,
    savingGoalDetails,
    generalSavingDetails,
    setStep,
    setRejectAlert,
    isRejectAlert,
    setFailureAlert,
    isFailureAlert,
    clearError,
    getCustomer,
    history,
    match: { params },
    classes,
    deleteGoalForm,
    backPayloadRsp,
    rspDetail,
    errorCashIn,
    resetCifPlanTemp,
    clearErrorCashIn,
    getPromo,
    promoList = [],
    setSelectedPromo,
    promoListLoading,
    selectedPromo,
    resetSelectedPromo,
    setDepositSummary,
    depositSummaryPayload,
    setLastAmountSave,
    lastISaveAmount,
    profile,
  } = props;

  const from = params.from;
  const [deposit, setDeposit] = useState("");
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [goalDetails, setGoalDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rspAlert, setRspAlert] = useState(false);
  const [isErrorPromo, setIsErrorPromo] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [errorValidation, setErrorValidation] = useState({
    textError: "Minimum RM 10.00",
    error: false,
  });
  const alreadySubmit = useRef(null);
  const getPromoByCode = getSelectedPromoByCode(promoList);

  /** Navigate back to previous screen */

  useEffect(() => {
    // get the latest amlStatus
    getCustomer();
    setStep(`/make-deposit/${from}`);
  }, []);

  useEffect(() => {
    if (!goalDetails) return;
    getPromo({
      target: SAVE,
      hierarchyId: goalDetails.accountNo,
      instrumentId:
        from !== GOAL_CREATION
          ? goalDetails.plan.planFund[0].fundCd
          : goalDetails.fundCode,
    });
  }, [goalDetails]);

  useEffect(() => {
    if (deposit) {
      if (deposit < 10) {
        setErrorValidation({
          ...errorValidation,
          error: true,
          textError: "Minimum RM 10.00",
        });
      } else if (deposit > 30000) {
        setErrorValidation({
          ...errorValidation,
          error: true,
          textError:
            "The maximum Cash In amount per transaction is RM30,000. You may proceed with your additional Cash In into multiple transactions.",
        });
      } else {
        setErrorValidation({
          ...errorValidation,
          error: false,
          textError: "",
        });
      }
    }
  }, [deposit]);

  const setRspButton = rspStatus => {
    let rspSession = sessionStorage.getItem("stopRsp");

    if (rspSession) {
      setRspAlert(false);
      return false;
    }
    if (
      !rspStatus ||
      (typeof rspStatus === "string" &&
        (rspStatus === "1" || rspStatus === "6" || rspStatus === "16"))
    ) {
      setRspAlert(true);
      return true;
    }
    return false;
  };

  const setDefaultAmt = () => {
    if (lastISaveAmount && Object.keys(selectedPromo).length > 0) {
      setDeposit(lastISaveAmount);
      return;
    }
    setDeposit(savingGoalDetails.regularInvsAmt);
  };

  /** Save saving goal data */
  useEffect(() => {
    if (savingGoalDetails) {
      setGoalDetails(savingGoalDetails);
      setDefaultAmt();
      let { rspStatus = "" } = savingGoalDetails;
      setRspButton(rspStatus);
    }
  }, [savingType, savingGoalDetails]);

  /** Open alert */
  const handleOpenAlert = () => setOpenAlert(true);

  const handleMakeDeposit = () => {
    let matchingPromoCode = [];

    // we check if `selectedPromo` not exist if so then get the matching promo code else put `selectedPromo` inside matchingPromoCode
    if (!Object.keys(selectedPromo).length) {
      if (Array.isArray(promoList)) {
        matchingPromoCode = getPromoByCode(promoCode);
      }
    } else {
      matchingPromoCode = [selectedPromo];
    }

    while (
      deposit.length <= 0 ||
      (promoCode && matchingPromoCode.length === 0)
    ) {
      if (deposit.length <= 0) {
        setErrorValidation({
          ...errorValidation,
          error: true,
          textError: "This field is required",
        });
      }

      if (promoCode && matchingPromoCode.length === 0) {
        setIsErrorPromo(true);
      }

      return;
    }

    // in case matchingPromoCode is empty array
    // we dont want to have undefined here
    const promo = matchingPromoCode.length ? matchingPromoCode[0] : {};

    setSelectedPromo(promo);

    // save `is promo selected` reference here
    const isPromoSelected = Object.keys(promo).length > 0;

    const isAmountLessThanPromoEligibilityRule =
      isPromoSelected &&
      parseInt(deposit) < parseInt(promo.eligibilityRules.minimumSingleDeposit);

    if (isAmountLessThanPromoEligibilityRule) {
      setErrorValidation({
        ...errorValidation,
        error: true,
        textError: `Minimum campaign cash in RM${
          promo.eligibilityRules.minimumSingleDeposit
        } not met`,
      });
      return;
    }

    if (!isPromoSelected && parseInt(deposit) < 10) {
      setErrorValidation({
        ...errorValidation,
        error: true,
        textError: "Minimum RM 10.00",
      });
      return;
    }
    if (alreadySubmit.current) return;
    alreadySubmit.current = true;
    dataLayerGTM("click_tag", "Click", "Button", `Cash In Button ${from}`);

    const payload = {
      productId: goalDetails.productId,
      cifPlanId: goalDetails.id,
      customerChannelCode: "Mini Program",
      amount: deposit.toString(),
      cifID: profile && profile.masterId,
      trxRefNo: crypto.randomBytes(14).toString("hex"),
      ccy: "MYR",
      name:
        process.env.REACT_APP_ENV.trim() === "production"
          ? profile.fullName
          : "PnN@m3()/PyN .-&B''UYER", // need to change to user fullname later,
      email: profile && profile.email,
    };

    if (savingType === CATEGORY_SAVING && from !== GOAL_CREATION) {
      payload.productId = goalDetails.product.id;
    }
    if (savingType === GENERAL_SAVING && from === GOAL_CREATION) {
      payload.productId = goalDetails.productId;
    }
    if (savingType === GENERAL_SAVING && from !== GOAL_CREATION) {
      payload.productId = goalDetails.product.id;
    }

    setDepositSummary(payload);
    history.push("/deposit-summary");
  };

  const setupAutoCashIn = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Setup Auto Cash In");
    setRspAlert(false);

    let rspSet;
    if (rspDetail && "rspStatus" in rspDetail) {
      let { rspStatus = "" } = rspDetail;
      if (rspStatus === "0") {
        rspSet = "edit"; // if rsp 0 means rsp was active and have been cancel, so direct to edit rsp
      } else {
        rspSet = "setupRSP"; // check setRspButton function
      }
    } else {
      rspSet = "setupRSP";
    }

    history.push(`/make-deposit/${from}/success/auto-deposit/${rspSet}`);
  };
  const hasMatchingPromo = val => {
    let matchingPromoCode;
    if (Array.isArray(promoList)) {
      matchingPromoCode = getPromoByCode(val);
    }

    if (matchingPromoCode.length > 0) {
      setSelectedPromo(matchingPromoCode[0]);
    } else {
      setIsErrorPromo(true);
    }
  };
  const handlePromo = () => {
    if (checkSelectedPromo().promo) {
      resetSelectedPromo();
      setErrorValidation({
        ...errorValidation,
        error: false,
        textError: "",
      });
      setPromoCode("");
      return;
    }
    if (promoCode === "" || !promoCode) {
      setIsErrorPromo(true);
      return;
    }
    hasMatchingPromo(promoCode);
  };

  const goPromoList = () => {
    if (
      Object.keys(selectedPromo).length > 0 &&
      selectedPromo.selectedReferralCode
    ) {
      history.push("/promo-list", {
        referralCode: selectedPromo.selectedReferralCode,
      });
      return;
    }
    if (
      Object.keys(selectedPromo).length > 0 &&
      !selectedPromo.selectedReferralCode
    ) {
      history.push("/promo-list", { promoId: selectedPromo.campaignId });
      return;
    }
    history.push("/promo-list");
  };

  const checkSelectedPromo = () => {
    return Object.keys(selectedPromo).length === 0
      ? { message: "Apply", styleBtn: true, promo: false }
      : { message: "Cancel", styleBtn: false, promo: selectedPromo };
  };

  if (loading || isLoading || promoListLoading)
    return (
      <div className="center">
        <DefaultLoader />
      </div>
    );

  return (
    <div className={`bg-white ${classes.container}`}>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={errorCashIn}
        isTimeout
        handleAction={() => {
          clearErrorCashIn();
          clearError();
          setRejectAlert(false);
          resetCifPlanTemp();
          history.push("/dashboard");
        }}
      />
      <Alert
        title="You might achieve your goal late"
        description="The Cash In amount is lesser than the proposed monthly Cash In amount. Are you sure you want to continue?"
        isOpen={isOpenAlert}
        // isCS="https://principal.com.my"
        isCancel={true}
        handleAction={() => {
          setOpenAlert(false);
          handleMakeDeposit();
        }}
        handleCancel={() => setOpenAlert(false)}
        btnTxt="Continue"
      />

      {/* <Alert
        title="Ops, Your account doesn’t meet requirement"
        description="So sorry, your account doesn’t meet our requirement."
        isOpen={isRejectAlert}
        isCS="https://principal.com.my"
        isCancel={true}
        handleAction={() => {
          setIsLoading(false);
          setRejectAlert(false);
          history.push("/dashboard");
        }}
        handleCancel={() => {
          setIsLoading(false);
          setRejectAlert(false);
          history.push("/dashboard");
        }}
      /> */}

      <Alert
        title="Something went wrong"
        description="We are facing some technical issue, we're sorry for the inconvenience. &#10;&#13; Please try again later"
        isOpen={isFailureAlert}
        isCancel={false}
        handleAction={() => {
          setIsLoading(false);
          setFailureAlert(false);
        }}
      />

      <Alert
        title="Set up monthly auto Cash In (Regular Savings Plan), a powerful way to help you achieve your goal on time"
        isOpen={rspAlert}
        isCancel={true}
        cancelTxt="Maybe Later"
        handleAction={setupAutoCashIn} //go to auto deposite form
        handleCancel={() => {
          dataLayerGTM("click_tag", "Click", "Button", "Setup RSP Maybe Later");
          sessionStorage.setItem("stopRsp", true);
          setRspAlert(false);
        }}
        btnTxt="Set Up Auto Cash In"
      />

      <StepLabel
        title="Cash In"
        containerStyles={{ margin: "16px 16px 22px" }}
        onClick={() => history.back()}
      />

      <form className={classes.form}>
        <p className="invest-form__big-label">
          How much are you willing to save?
        </p>
        <p className="goal-save-label">{savingGoalDetails.planAlias}</p>

        <TextField
          fullWidth
          variant="outlined"
          placeholder="10.00"
          value={deposit}
          onChange={e => {
            setLastAmountSave(e.target.value);
            setDeposit(e.target.value);
          }}
          error={errorValidation.error}
          helperText={Boolean(deposit) && errorValidation.textError}
          FormHelperTextProps={{ className: classes.helper }}
          inputProps={{ className: classes.inputField }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            className: classes.RM,
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textPrimary" className={classes.prefix}>
                  RM
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </form>
      <div style={{ padding: "16px 0px 0px" }}>
        <div className="border-line" />
      </div>
      <div className="fs-step2-box2">
        <InputBasic
          promoValue={checkSelectedPromo().promo}
          error={isErrorPromo}
          errorMessage="Please enter a valid Promo Code"
          title="Promo Code"
          placeholder="Enter promo or referral code"
          type="text"
          isCurrency={false}
          btnOption={checkSelectedPromo().message}
          textButton={checkSelectedPromo().styleBtn}
          isPromo={true}
          onChange={e => {
            setIsErrorPromo(false);
            setPromoCode(e.target.value);
          }}
          listPromoAction={() => goPromoList()}
          onButton={e => {
            e.preventDefault();
            handlePromo();
          }}
        />
      </div>
      <div className="border-line" />
      <div className="fs-step2-box3">
        <div>
          <p>Cash In</p>
          <p>
            RM
            <NumberFormat
              value={deposit}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
        </div>

        <div>
          <p>Inclusive of Sales Charge</p>
          <p>0%</p>
        </div>

        {Object.keys(selectedPromo).length > 0 && (
          <div className="sales-charge-promo">
            <p>{selectedPromo.name}</p>
            <p>-</p>
          </div>
        )}
      </div>
      <div className="border-line" />
      <div className="invest-form__container-total">
        <p className="invest-form__container-total__cahsin-label">
          Total Cash In
        </p>
        <p className="invest-form__container-total__total-cashin">
          RM{" "}
          <NumberFormat
            value={deposit}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </div>
      <div className="invest-form__confirm">
        <CustomButton
          text="Continue"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={deposit < 10 || deposit > 30000 ? true : false}
          onClick={
            savingType === CATEGORY_SAVING &&
            goalDetails &&
            Number(deposit) < goalDetails.regularInvsAmt
              ? handleOpenAlert
              : handleMakeDeposit
          }
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    profile: state.goal.profile,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    savingType: state.goal.savingType,
    savingGoalDetails: state.goal.savingGoalDetails,
    generalSavingDetails: state.goal.generalSavingDetails,
    isRejectAlert: state.goal.isRejectAlert,
    isFailureAlert: state.goal.isFailureAlert,
    ...state.promoReducer,
    ...state.autoDepositReducer,
  }),
  {
    setStep,
    hideError,
    deleteGoalForm,
    clearError,
    getCustomer,
    setRejectAlert,
    setFailureAlert,
    clearErrorCashIn,
    resetCifPlanTemp,
    getPromo, // API
    resetSelectedPromo,
    setSelectedPromo,
    setDepositSummary,
    setLastAmountSave,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(MakeDeposit);
