import {
  CREATE_GOAL,
  CREATE_GOAL_SUCCESS,
  CREATE_GOAL_FAILED,
  RESET_ERROR_GOAL_CREATION,
  SET_CIFPLAN_TEMP,
  RESET_CIFPLAN_TEMP
} from "./constants";

const initialState = {
  createGoalLoading: false,
  errorCreateGoal: false,
  tempCifPlanId: null
};

export default function stepThreeReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case CREATE_GOAL:
      return {
        ...state,
        createGoalLoading: true
      };
    case CREATE_GOAL_SUCCESS:
      return {
        ...state,
        createGoalLoading: false
      };
    case CREATE_GOAL_FAILED:
      return {
        ...state,
        createGoalLoading: false,
        errorCreateGoal: true
      };
    case RESET_ERROR_GOAL_CREATION:
      return {
        ...state,
        errorCreateGoal: false
      };
    case SET_CIFPLAN_TEMP:
      return {
        ...state,
        tempCifPlanId: payload
      };
    case RESET_CIFPLAN_TEMP:
      return {
        ...state,
        tempCifPlanId: null
      };
    default:
      return state;
  }
}
