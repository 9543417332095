import React from "react";
import { StepLabel, CustomButton } from "../../components";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./style.scss";
const RewardDetail = props => {
  const { history, rewardDetail = {} } = props;

  return (
    <div className="reward-detail-container">
      <div style={{ padding: "16px 16px 22px" }}>
        <StepLabel title="Back" onClick={() => history.back()} />
      </div>
      <div className="reward-detail-container__content">
        <p>{rewardDetail.campaignName}</p>
        <p>
          <span>RM{rewardDetail.rewardValue} </span>
          has been credited to your Touch 'n Go eWallet. Please check your app
          for the details.
        </p>
      </div>
      <div style={{ padding: "0px 16px" }}>
        <CustomButton
          text="OK"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={false}
          onClick={() => history.back()}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    ...state.rewardReducer
  }),
  {}
);

export default compose(
  withRouter,
  withConnect
)(RewardDetail);
