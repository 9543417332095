/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from "react";
import { Typography, TextField, InputAdornment, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import { DefaultLoader } from "../../components";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { setFullRedemption, getWithdrawalBankList } from "../redux/action";
import { StepLabel, CustomButton, TermCondition } from "../../components";
import { icons } from "../../constants";
import styles from "./styles";

const WithdrawalSummary = props => {
  const {
    withdrawalBankList,
    setFullRedemption,
    history,
    match: {
      params: { amount }
    },
    classes,
    loadingWithdrawalBankList,
    getWithdrawalBankList
  } = props;

  const Bank = "Bank";
  const eWallet = "E-Wallet";

  const [isChecked, setChecked] = useState(false);
  const [withdrawalMethod, setWithdrawalMethod] = useState(Bank);

  useEffect(() => {
    getWithdrawalBankList({ rsp: false });
  }, []);

  const handleBack = () => {
    setFullRedemption(false);
    history.back();
  };

  if (withdrawalBankList.length === 0 && loadingWithdrawalBankList)
    return <DefaultLoader />;

  const fee = withdrawalBankList.length > 0 ? withdrawalBankList[0].fee : 0;

  return (
    <div className={classes.container}>
      <StepLabel
        title="Cash Out summary"
        containerStyles={{ margin: "16px 16px 22px" }}
        onClick={handleBack}
      />

      {/* Withdrawal details */}
      <div
        style={{
          backgroundColor: "#f1fafe",
          padding: "16px",
          margin: "0 16px",
          borderRadius: "4px"
        }}
      >
        <div className={`${classes.row} ${classes.withdrawalDetails}`}>
          <Typography className={`${classes.baseFont}`} color="textPrimary">
            Cash Out amount
          </Typography>
          <Typography className={`${classes.baseFont}`} color="textPrimary">
            RM{" "}
            <NumberFormat
              value={Number(amount).toFixed(2)}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
        <div
          className={`${classes.row} ${classes.withdrawalDetails}`}
          style={{ margin: "8px 0" }}
        >
          <Typography className={`${classes.baseFont}`} color="textPrimary">
            Bank transaction fee
          </Typography>
          <Typography className={`${classes.baseFont}`} color="textPrimary">
            RM{" "}
            <NumberFormat
              value={parseFloat(fee)}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
        <div className={`${classes.row} ${classes.withdrawalDetails}`}>
          <Typography
            className={`${classes.baseFont}`}
            color="textPrimary"
            style={{ fontSize: "20px", fontWeight: "700", lineHeight: 1.4 }}
          >
            Total:
          </Typography>
          <Typography
            className={`${classes.baseFont}`}
            color="textPrimary"
            style={{ fontSize: "20px", fontWeight: "700", lineHeight: 1.4 }}
          >
            RM{" "}
            <NumberFormat
              value={parseFloat(amount - fee)}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
      </div>

      {/* Withdrawal method */}
      <div style={{ margin: "16px 16px 0px" }}>
        <Typography
          className={`${classes.baseFont}`}
          style={{
            fontSize: "20px",
            fontWeight: "700",
            lineHeight: 1.4,
            color: "#003865"
          }}
        >
          Cash Out method
        </Typography>
        <div style={{ marginTop: "16px" }}>
          <TextField
            fullWidth
            variant="outlined"
            value={Bank}
            onClick={e => setWithdrawalMethod(e.target.value)}
            style={{ marginBottom: "22px" }}
            inputProps={{
              className: classes.baseFont,
              style: {
                padding: "16px 16px 16px 0",
                color: withdrawalMethod === Bank ? "#004c97" : "#333",
                fontWeight: withdrawalMethod === Bank ? "700" : "normal"
              }
            }}
            InputProps={{
              readOnly: true,
              style: { padding: 0, paddingLeft: "16px" },
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ padding: 0, paddingRight: "16px", margin: 0 }}
                >
                  <img
                    src={icons.fpx}
                    alt="fpx"
                    style={{ margin: 0, padding: 0, width: "100%" }}
                  />
                </InputAdornment>
              ),
              endAdornment: withdrawalMethod === Bank && (
                <InputAdornment
                  position="end"
                  style={{ padding: 0, paddingRight: "16px", margin: 0 }}
                >
                  <Icon
                    className="material-icons-outlined"
                    style={{ color: "#0076CF" }}
                  >
                    done
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          {/* <TextField
            fullWidth
            variant="outlined"
            value={eWallet}
            onClick={e => setWithdrawalMethod(e.target.value)}
            inputProps={{
              className: classes.baseFont,
              style: {
                padding: "16px 16px 16px 0",
                color: withdrawalMethod === eWallet ? "#004c97" : "#333",
                fontWeight: withdrawalMethod === eWallet ? "700" : "normal"
              }
            }}
            InputProps={{
              readOnly: true,
              style: { padding: 0, paddingLeft: "16px" },
              startAdornment: (
                <InputAdornment
                  position="start"
                  style={{ padding: 0, paddingRight: "16px", margin: 0 }}
                >
                  <img
                    src={icons.eWallet}
                    alt="fpx"
                    style={{ margin: 0, padding: 0, width: "100%" }}
                  />
                </InputAdornment>
              ),
              endAdornment: withdrawalMethod === eWallet && (
                <InputAdornment
                  position="end"
                  style={{ padding: 0, paddingRight: "16px", margin: 0 }}
                >
                  <Icon
                    className="material-icons-outlined"
                    style={{ color: "#0076CF" }}
                  >
                    done
                  </Icon>
                </InputAdornment>
              )
            }}
          /> */}
        </div>
      </div>

      {/* Term conditions */}
      <div className={`${classes.row} ${classes.termCondition}`}>
        <TermCondition
          message="Withdrawal must be made with a bank account under your name (single account only). Withdrawal will be rejected if using someone else’s bank account."
          checked={isChecked}
          onChange={() => setChecked(!isChecked)}
        />
      </div>

      {/* Button */}
      <div className={classes.btn}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          disabled={withdrawalMethod && isChecked ? false : true}
          onClick={() => history.push(`/make-withdrawal/bankInfo/${amount}`)}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    isFullRedemption: state.goal.isFullRedemption,
    withdrawalBankList: state.goal.withdrawalBankList,
    loadingWithdrawalBankList: state.goal.loadingWithdrawalBankList,
    loadingPPGToken: state.goal.loadingPPGToken
  }),
  { setFullRedemption, getWithdrawalBankList }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(WithdrawalSummary);
