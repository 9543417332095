import {
  SEND_RISK_ASSESSMENT,
  SEND_RISK_ASSESSMENT_SUCCESS,
  SEND_RISK_ASSESSMENT_FAILED,
  GET_RISK_ASSESSMENT,
  GET_RISK_ASSESSMENT_SUCCESS,
  GET_RISK_ASSESSMENT_FAILED,
  SEND_RISK_PROFILE,
  SEND_RISK_PROFILE_SUCCESS,
  SEND_RISK_PROFILE_FAILED,
  GET_RESULT_ASSESSMENT,
  GET_RESULT_ASSESSMENT_SUCCESS,
  GET_RESULT_ASSESSMENT_FAILED
} from "./constant";

export const getResultAssessment = (payload, callback) => {
  return {
    type: GET_RESULT_ASSESSMENT,
    payload,
    callback
  };
};

export const getResultAssessmentSuccess = payload => {
  return {
    type: GET_RESULT_ASSESSMENT_SUCCESS,
    payload
  };
};

export const getResultAssessmentFailed = () => {
  return {
    type: GET_RESULT_ASSESSMENT_FAILED
  };
};

export const sendRiskAssessment = (payload, callback) => {
  return {
    type: SEND_RISK_ASSESSMENT,
    payload,
    callback
  };
};
export const sendRiskAssessmentSuccess = payload => {
  return {
    type: SEND_RISK_ASSESSMENT_SUCCESS,
    payload
  };
};

export const sendRiskAssessmentFailed = payload => {
  return {
    type: SEND_RISK_ASSESSMENT_FAILED,
    payload
  };
};

export const getRiskAssessment = () => {
  return {
    type: GET_RISK_ASSESSMENT
  };
};

export const getRiskAssessmentSuccess = payload => {
  return {
    type: GET_RISK_ASSESSMENT_SUCCESS,
    payload
  };
};

export const getRiskAssessmentFailed = () => {
  return {
    type: GET_RISK_ASSESSMENT_FAILED
  };
};

export const sendRiskProfile = (payload, callback) => {
  return {
    type: SEND_RISK_PROFILE,
    payload,
    callback
  };
};

export const sendRiskProfileSucces = payload => {
  return {
    type: SEND_RISK_PROFILE_SUCCESS,
    payload
  };
};

export const sendRiskProfileFailed = payload => {
  return {
    type: SEND_RISK_PROFILE_FAILED,
    payload
  };
};
