import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";
import CustomButton from "../Button";
import { icons } from "../../constants";

const StyledDialog = withStyles({
  scrollPaper: {
    padding: "0 16px"
  },
  paperFullWidth: {
    minWidth: "100%"
  },
  paperScrollPaper: {
    maxHeight: "100%"
  }
})(Dialog);

const Alert = ({
  title,
  description,
  isOpen,
  isCS,
  isCancel,
  isObject,
  handleClose,
  handleAction,
  handleCancel,
  classes,
  btnTxt,
  cancelTxt,
  swapButtonsPosition = false,
  withCloseButton = false,
  additionalContent,
  style,
  titleStyle,
  submitButtonDisabled
}) => {
  const split = text => {
    return text
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, str => str.toUpperCase());
  };

  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      onClick={handleCancel}
      style={style}
    >
      <div
        onClick={e => e.stopPropagation()}
        style={{ position: withCloseButton && "relative" }}
      >
        {withCloseButton && (
          <img
            src={icons.close}
            alt="Close modal"
            style={{ position: "absolute", right: "1rem", top: "1rem" }}
            onClick={handleCancel}
          />
        )}

        <DialogTitle
          className={classes.titleContainer}
          style={{ paddingTop: withCloseButton && "36px" }}
        >
          <Typography
            className={`${classes.baseFont} ${classes.title}`}
            style={titleStyle}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent className={classes.descContainer}>
          {!isObject ? (
            <>
              <Typography
                className={`${classes.baseFont} ${classes.description}`}
              >
                {description}
              </Typography>
              {isCS && (
                <Typography className={`${classes.baseFont} ${classes.link}`}>
                  Please contact our <strong>Customer Service</strong> for
                  assistance.
                </Typography>
              )}
            </>
          ) : (
            <>
              {Object.keys(isObject)
                .filter(key => isObject[key])
                .map(key => {
                  return (
                    <Typography
                      key={key}
                      className={`${classes.baseFont} ${classes.description}`}
                    >
                      &#183; {split(key)}
                    </Typography>
                  );
                })}
            </>
          )}
        </DialogContent>
        <DialogActions
          className={classes.actionContainer}
          style={{
            display: "flex",
            flexDirection: isCancel ? "column" : "row"
          }}
        >
          {swapButtonsPosition ? (
            <>
              <CustomButton
                text={cancelTxt ? cancelTxt : "Cancel"}
                color="#fff"
                backgroundColor="#1278cc"
                onClick={handleCancel}
              />
              <Button
                onClick={handleAction}
                className={`${classes.baseFont} ${classes.cancel}`}
                disabled={submitButtonDisabled}
              >
                {btnTxt ? btnTxt : "Done"}
              </Button>
            </>
          ) : (
            <>
              <CustomButton
                text={btnTxt ? btnTxt : "Done"}
                color="#fff"
                backgroundColor="#1278cc"
                onClick={handleAction}
                disabled={submitButtonDisabled}
              />
              {isCancel && (
                <Button
                  onClick={handleCancel}
                  className={`${classes.baseFont} ${classes.cancel}`}
                >
                  {cancelTxt ? cancelTxt : "Cancel"}
                </Button>
              )}
            </>
          )}
        </DialogActions>
        {additionalContent && (
          <DialogContent>{additionalContent}</DialogContent>
        )}
      </div>
    </StyledDialog>
  );
};

export default compose(withStyles(styles))(Alert);
