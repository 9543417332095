import { all, takeLatest, call, put, select } from "redux-saga/effects";
import * as constant from "./constant";
import axios from "axios";
import {
  getAccountStatusV2Success,
  getAccountStatusV2Failed,
  getSubAccountInvestSuccess,
  getSubAccountInvestFailed,
} from "./actions";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

export const getCustomerinfo = () => {
  const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
  const cifId = userInfo.masterId;
  return axios.get(`${API_URL.mp}/mp/customer?cifId=${cifId}`);
};

export const getCustAccSts = (item, token) => {
  return axios.get(`${API_URL.mp}/smp/customer/plan-status/${item}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

function* getAccountStatusV2Saga(action) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/plan-status/${action.payload}`
    );
    const { data } = res;
    if (data.sts) {
      yield put(getAccountStatusV2Success(data.sts));
      action.callback(data);
    } else {
      throw new Error("Fail to get Account Status");
    }
  } catch (error) {
    action.callback();
    yield put(getAccountStatusV2Failed(error.message));
  }
}

function* getCustomerPortfolioSaga(action) {
  try {
    const masterId = yield select(state => state.goal.profile.masterId);
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer-portfolio/${masterId}`
    );

    yield put({
      type: constant.GET_CUSTOMER_PORTFOLIO_SUCCESS,
      payload: res.data,
    });
    if ("callback" in action && typeof action.callback === "function") {
      action.callback(res.data);
    }
  } catch (error) {
    yield put({ type: constant.GET_CUSTOMER_PORTFOLIO_FAILED });
  }
}

function* getAccStsInvestSaga(action) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/plan-status/${action.payload}`
    );
    if (res.status === 204) {
      throw new Error("fail get sub account invest detail");
    }

    const { data } = res;
    if (typeof data === "string") {
      throw new Error("fail get sub account invest detail");
    }

    yield put(getSubAccountInvestSuccess(data));
    if ("callback" in action && typeof action.callback === "function") {
      action.callback(data);
    }
  } catch (error) {
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
    yield put(getSubAccountInvestFailed());
  }
}

export function* dashboardWorkerSaga() {
  yield all([
    takeLatest(constant.GET_ACCOUNT_STATUS_V2, getAccountStatusV2Saga),
    takeLatest(constant.GET_SUBACCOUNT_INVEST, getAccStsInvestSaga),
    takeLatest(constant.GET_CUSTOMER_PORTFOLIO, getCustomerPortfolioSaga),
  ]);
}
