import React, { useEffect, useReducer } from "react";
import { Typography, TextField, InputAdornment } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { CATEGORY_SAVING, GENERAL_SAVING } from "../redux/constant";
import { setFullRedemption, checkWithdrawalMaxAmount } from "../redux/action";

import {
  StepLabel,
  CustomButton,
  NumberFormatCustom,
  DefaultLoader,
  Alert
} from "../../components";
import styles from "./styles";
import { initState, reducer } from "./reducer";
import { sendFundsCode } from "../fundSelection/redux/action";
import { getRoundedValue } from "../../utils/functions/getRoundedValue";

const getTotalAsset = ({ unitHeld, nav }) => {
  return unitHeld * nav;
};

const MakeWithdrawal = props => {
  const {
    loading,
    savingType,
    savingGoalDetails,
    generalSavingDetails,
    customerGoals,
    checkWithdrawalMaxAmount,
    maxAmount,
    history,
    classes,
    loadingMaxAmount,
    fund,
    sendFundsCode,
    setFullRedemption
  } = props;

  const [state, dispatch] = useReducer(reducer, initState);

  /** Save saving goal data */
  useEffect(() => {
    if (savingType === CATEGORY_SAVING && savingGoalDetails) {
      handleSetGoal(savingGoalDetails);
    }
  }, [savingType, savingGoalDetails]);

  useEffect(() => {
    if (savingType === GENERAL_SAVING && generalSavingDetails) {
      handleSetGoal(generalSavingDetails);
    }
  }, [savingType, generalSavingDetails]);

  useEffect(() => {
    if (
      fund &&
      Object.entries(fund).length > 0 &&
      fund.product.productCatCd === "MPInvest"
    ) {
      checkWithdrawalMaxAmount(`${fund.cifPlanId}?fundCd=${fund.code}`);
    }
  }, [fund]);

  /**
   *
   * @param {Record<string, unknown>} goal
   */
  const handleSetGoal = goal => {
    if (goal) {
      sendFundsCode([]);

      const saving = customerGoals.find(item => item.id === goal.id);

      dispatch({ type: "SET_GOAL", payload: saving });

      checkWithdrawalMaxAmount(goal.id);
    }
  };

  /**
   *
   * @param {number} totalAsset
   */
  const handleFullRedemption = totalAsset => {
    // add guard incase max amount api is not deployed to production
    // if not deployed, it will takes the goals remaining total assets
    if (maxAmount && maxAmount.totalAmount) {
      if (maxAmount.totalAmount >= 0) {
        const amount = getRoundedValue(maxAmount.totalAmount, 2);

        dispatch({ type: "SET_WITHDRAWAL_AMOUNT", payload: amount.toString() });
      } else {
        dispatch({
          type: "RESET_WITHDRAWAL_AMOUNT"
        });
      }
    } else {
      const amount = getRoundedValue(totalAsset, 2);

      if (amount < state.saveMinAmount || amount < state.investMinAmount) {
        dispatch({ type: "SET_FULL_REDEMPTION_ERROR" });
      } else {
        dispatch({
          type: "SET_WITHDRAWAL_AMOUNT",
          payload: amount.toString()
        });
      }
    }
  };

  const handleContinue = () => {
    let totalAsset;

    if (
      fund &&
      Object.entries(fund).length > 0 &&
      fund.product.productCatCd === "MPInvest"
    ) {
      totalAsset = getTotalAsset({
        nav: fund.nav,
        unitHeld: fund.unitHeld
      });
    } else {
      totalAsset = getTotalAsset({
        nav: state.savingDetail.portfolio.unitHeld,
        unitHeld: state.savingDetail.portfolio.fund.nav
      });
    }

    const amount = getRoundedValue(
      maxAmount ? maxAmount.totalAmount : totalAsset,
      2
    );

    if (
      amount - Number(state.withdrawalAmount) > 0 &&
      (amount - Number(state.withdrawalAmount) < state.saveMinAmount ||
        amount - Number(state.withdrawalAmount) < state.saveInvestAmount)
    ) {
      dispatch({ type: "RESET_OPEN_ALERT_AND_REDEMPTION_ERROR" });
      handleFullRedemption(totalAsset);
      return;
    }

    if (Number(state.withdrawalAmount) <= amount) {
      if (amount - Number(state.withdrawalAmount) === 0) {
        setFullRedemption(true);
        history.push(`/make-withdrawal/summary/${amount}`);
      } else {
        setFullRedemption(false);
        history.push(`/make-withdrawal/summary/${state.withdrawalAmount}`);
      }
    } else {
      if (maxAmount && maxAmount.totalAmount) {
        dispatch({ type: "RESET_OPEN_ALERT_AND_SET_LOW_BALANCE_ERROR" });
        handleFullRedemption(totalAsset);
      } else {
        dispatch({ type: "RESET_OPEN_ALERT_SET_CASHOUT_ERROR" });
      }
    }
  };

  const handleBack = () => {
    setFullRedemption(false);
    history.back();
  };

  if (loadingMaxAmount || loading) return <DefaultLoader />;

  return (
    <div className={classes.container}>
      <Alert
        title="Are you sure you want to Cash Out?"
        description="You might delay on achieving your goal."
        isOpen={state.isOpenAlert}
        isCancel
        handleClose={() => dispatch({ type: "RESET_OPEN_ALERT" })}
        handleAction={handleContinue}
        handleCancel={() => dispatch({ type: "RESET_OPEN_ALERT" })}
        btnTxt="Continue"
        swapButtonsPosition
      />
      <Alert
        title="Ops, insufficient balance for Cash Out"
        description="Your financial goal has insufficient balance to cash out."
        isOpen={state.isCashOutError}
        isCancel={false}
        handleClose={() => dispatch({ type: "RESET_CASHOUT_ERROR" })}
        handleAction={() => dispatch({ type: "RESET_CASHOUT_ERROR" })}
        handleCancel={() => dispatch({ type: "RESET_CASHOUT_ERROR" })}
      />
      <Alert
        title="Ops, insufficient balance for Cash Out"
        description="Your financial goal has insufficient balance to cash out. The minimum cash out limit is RM10."
        isOpen={state.isFullRedemptionError}
        isCancel={false}
        handleClose={() => dispatch({ type: "RESET_FULL_REDEMPTION_ERROR" })}
        handleAction={() => dispatch({ type: "RESET_FULL_REDEMPTION_ERROR" })}
        handleCancel={() => dispatch({ type: "RESET_FULL_REDEMPTION_ERROR" })}
      />
      <Alert
        title="Ops, minimum financial goal balance limit"
        description="Production allocation must be equal or above minimum amount."
        isOpen={state.isLowBalanceError}
        isCancel={false}
        handleClose={() => dispatch({ type: "RESET_LOW_BALANCE_ERROR" })}
        handleAction={() => dispatch({ type: "RESET_LOW_BALANCE_ERROR" })}
        handleCancel={() => dispatch({ type: "RESET_LOW_BALANCE_ERROR" })}
      />

      <StepLabel
        title="Cash Out"
        containerStyles={{ margin: "16px 16px 22px" }}
        onClick={handleBack}
      />
      <form className={classes.form}>
        <Typography
          className={`${classes.baseFont} ${classes.label}`}
          color="textPrimary"
        >
          Please enter an amount
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="0.00"
          value={state.withdrawalAmount}
          onChange={e =>
            dispatch({ type: "SET_AMOUNT", payload: e.target.value })
          }
          error={
            state.withdrawalAmount &&
            (state.withdrawalAmount < state.saveMinAmount ||
              state.withdrawalAmount < state.investMinAmount)
          }
          helperText={
            state.withdrawalAmount &&
            (state.withdrawalAmount < state.saveMinAmount ||
              state.withdrawalAmount < state.investMinAmount) &&
            "Minimum RM 10.00"
          }
          FormHelperTextProps={{
            className: `${classes.baseFont} ${classes.helper}`
          }}
          inputProps={{
            className: `${classes.baseFont} ${classes.inputField}`
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            inputComponent: NumberFormatCustom,
            className: classes.RM,
            startAdornment: (
              <InputAdornment position="start">
                <Typography
                  color="textPrimary"
                  className={`${classes.baseFont} ${classes.prefix}`}
                >
                  RM
                </Typography>
              </InputAdornment>
            ),
            endAdornment: (
              <div
                onClick={handleFullRedemption}
                style={{
                  border: "1px solid #0091DA",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Typography
                  className={`${classes.baseFont}`}
                  style={{
                    color: "#0091DA",
                    width: "100px",
                    textAlign: "center",
                    padding: "2px",
                    fontSize: "14px"
                  }}
                >
                  Max Amount
                </Typography>
              </div>
            )
          }}
        />
      </form>
      <ul className={classes.cashOutInfo}>
        <li>
          <span style={{ fontWeight: "700" }}>Save feature:</span> Cash Out
          request for all financial goals and general savings will take up to
          2 business days (T+2) to be processed.
        </li>
        <li>
          <span style={{ fontWeight: "700" }}>Invest feature:</span> Cash Out
          request for all funds will take up to 7 business days (T+7) to be
          processed.
        </li>
        <li>
          Final fund value will be based on NAV selling price of the day you
          Cash Out.
        </li>
      </ul>
      <div className={classes.btn}>
        <CustomButton
          text="Cash Out"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={
            state.withdrawalAmount < state.saveMinAmount ||
            state.withdrawalAmount < state.investMinAmount
          }
          onClick={() => dispatch({ type: "SET_OPEN_ALERT" })}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    savingType: state.goal.savingType,
    savingGoalDetails: state.goal.savingGoalDetails,
    generalSavingDetails: state.goal.generalSavingDetails,
    customerGoals: state.goal.customerGoals,
    maxAmount: state.goal.maxAmount,
    loadingMaxAmount: state.goal.loadingMaxAmount,
    fund: state.fundSelectionReducer.fundsCode[0]
  }),
  {
    checkWithdrawalMaxAmount,
    sendFundsCode,
    setFullRedemption
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(MakeWithdrawal);
