const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: "pointer",
    padding: "16px 0",
    textTransform: "none"
  },
  text: {
    fontFamily: "FSElliotPro",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: 1.5
  }
};

export default styles;
