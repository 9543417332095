export const SET_AUTO_DEPOSIT = "SET_AUTO_DEPOSIT";
export const SET_AUTO_DEPOSIT_SUCCESS = "SET_AUTO_DEPOSIT_SUCCESS";
export const SET_AUTO_DEPOSIT_FAILED = "SET_AUTO_DEPOSIT_FAILED";

export const SET_DISABLE_RSP = "SET_DISABLE_RSP";
export const SET_DISABLE_RSP_SUCCESS = "SET_DISABLE_RSP_SUCCESS";
export const SET_DISABLE_RSP_FAILED = "SET_DISABLE_RSP_FAILED";

export const SET_EDIT_RSP = "SET_EDIT_RSP";
export const SET_EDIT_RSP_SUCCESS = "SET_EDIT_RSP_SUCCESS";
export const SET_EDIT_RSP_FAILED = "SET_EDIT_RSP_FAILED";

export const CLEAR_ERROR_RSP = "CLEAR_ERROR_RSP";

export const GET_RSP_DETAIL = "GET_RSP_DETAIL";
export const GET_RSP_DETAIL_SUCCESS = "GET_RSP_DETAIL_SUCCESS";
export const GET_RSP_DETAIL_FAILED = "GET_RSP_DETAIL_FAILED";

export const SET_BACK_PAYLOAD = "SET_BACK_PAYLOAD";

export const GET_RSP_MIN_AMOUNT = "GET_RSP_MIN_AMOUNT";
export const GET_RSP_MIN_AMOUNT_SUCCESS = "GET_RSP_MIN_AMOUNT_SUCCESS";
export const GET_RSP_MIN_AMOUNT_FAILED = "GET_RSP_MIN_AMOUNT_FAILED";

export const SET_RSP_PAYLOAD = "SET_RSP_PAYLOAD";

export const MAKE_DEPOSIT = "MAKE_DEPOSIT";
export const MAKE_DEPOSIT_SUCCESS = "MAKE_DEPOSIT_SUCCESS";
export const MAKE_DEPOSIT_FAILED = "MAKE_DEPOSIT_FAILED";

export const CLEAR_ERROR_CASHIN = "CLEAR_ERROR_CASHIN";
export const SET_DEPOSIT_SUMMARY = "SET_DEPOSIT_SUMMARY";
export const SET_LAST_AMOUNT_SAVE = "SET_LAST_AMOUNT_SAVE";
