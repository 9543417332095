import { createReducer } from "redux-act";
import axios from "axios";
import moment from "moment";

import {
  userSignedIn,
  userLastLogin,
  userSignedOut,
  resetFailedRequestProps,
  getNewTokenSuccess,
  getNewTokenFailed,
  signInRequestFailedAction,
  reqTokenOnprogress,
} from "./actions";
import { countPasswordExpiredInDays } from "../../../utils/general";

const initialState = {
  accessToken: null,
  ttl: null,
  tokenCreatedAt: null,
  userId: null,
  refreshToken: null,
  errorMessage: false,
  isLoading: false,
  expTime: null,
  MPtoken: null,
  tokenOnprogress: false,
};

const jwtDecode = require("jwt-decode");
const authReducer = createReducer(
  {
    [userSignedIn]: (state, payload) => {
      const decode = jwtDecode(payload.access_token);
      const { preferred_username = "", realm_access = {} } = decode;
      const { roles = [] } = realm_access;
      const role = roles.filter(
        o => !["offline_access", "uma_authorization"].includes(o)
      );
      axios.defaults.headers.common["Authorization"] = `Bearer ${
        payload.access_token
      }`;

      return {
        ...state,
        accessToken: payload.access_token,
        ttl: 2394723,
        tokenCreatedAt: new Date(),
        userId: preferred_username,
        role: role.join(" - "),
        expTime: moment().add(parseInt(payload.expires_in), "s"),
        passwordExpiredIn: countPasswordExpiredInDays(
          payload.passwordCreatedAt
        ),
      };

      // accessToken: payload.accessToken || state.accessToken,
      // refreshToken: payload.refreshToken || state.refreshToken,
    },
    [userLastLogin]: (state, payload) => {
      return {
        ...state,
        lastLogin: payload,
      };
    },
    [getNewTokenSuccess]: (state, payload) => {
      return { ...state, MPtoken: payload };
    },
    [getNewTokenFailed]: (state, payload) => {
      return { ...state, MPtoken: null };
    },
    [reqTokenOnprogress]: (state, payload) => {
      return { ...state, tokenOnprogress: payload };
    },
    [resetFailedRequestProps]: (state, payload) => {
      return {
        ...state,
        errorMessage: false,
        isLoading: false,
      };
    },
    [signInRequestFailedAction]: (state, payload) => {
      return {
        ...state,
        errorMessage: true,
        isLoading: false,
      };
    },
    [userSignedOut]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default authReducer;
