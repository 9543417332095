import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { Typography } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  sendSMSOtp,
  verifySMSOtp,
  clearSMSOtp,
  setShowError
} from "../redux/action";

import {
  StepLabel,
  CustomButton,
  DefaultLoader,
  Alert,
  OtpInput
} from "../../components";
import styles from "./styles";

const WithdrawalVerification = props => {
  const {
    loading,
    smsOtp,
    smsOtpVerify,
    profile,
    sendSMSOtp,
    verifySMSOtp,
    clearSMSOtp,
    setShowError,
    error,
    showError,
    history
  } = props;

  const withdrawalData = history.location.state.payload;
  const smsAmt = history.location.state.smsAmt;
  // const profile = JSON.parse(localStorage.getItem("mpUserInfo"));

  const [reqOtpCount, setReqOtpCount] = useState(0);
  const TIME_SECOND = reqOtpCount >= 3 ? 300 : 60;
  const TIME_MILISECOND = TIME_SECOND * 1000;
  const [timeLeft, setTimeLeft] = useState(
    (Number(localStorage.getItem("timer")) - Date.now()) / 1000
  );

  const firstRender = useRef(true);

  const resetTimer = () => {
    setReqOtpCount(reqOtpCount + 1);
    localStorage.setItem("timer", Date.now() + TIME_MILISECOND);
    setTimeLeft(TIME_SECOND);
    sendSMSOtp({
      cifId: profile.masterId,
      type: "withdrawal",
      amount: smsAmt.toString()
    });
    clearSMSOtp();
  };

  const [otp, setOtp] = useState("");
  const [resendOtp, setResendOtp] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [clickTimeLeft, setClickTimeLeft] = useState(3);

  useLayoutEffect(() => {
    if (localStorage.getItem("timer") === null) {
      firstRender.current = false;
      resetTimer();
    }

    if (timeLeft > 0) {
      firstRender.current = false;
    }

    if (timeLeft <= 0 && firstRender.current) {
      firstRender.current = false;
      resetTimer();
    }
  });

  useEffect(() => {
    if (smsOtpVerify === "OK") {
      setOtpError(false);
    } else if (smsOtpVerify === "Unauthorized") {
      setOtpError(true);
    }
  }, [smsOtpVerify]);

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }

    if (timeLeft < 1) {
      setIsClicked(false);
      setResendOtp(true);
    }
  }, [timeLeft]);

  // function to prevent multiple clicking on cash out
  useEffect(() => {
    if (clickTimeLeft > 0) {
      setTimeout(() => {
        setClickTimeLeft(clickTimeLeft - 1);
      }, 1000);
    }

    if (clickTimeLeft === 0) {
      setIsClicked(false);
    }
  }, [clickTimeLeft]);

  const handleChangeOtp = token => {
    setOtp(token);
  };

  const handleResendOtp = () => {
    setResendOtp(false);
    setOtpError(false);
    setOtp("");
    resetTimer();
  };

  const handleVerifySms = () => {
    if (!isClicked || clickTimeLeft === 0) {
      setIsClicked(true);
      verifySMSOtp(
        {
          email: smsOtp.email,
          token: otp
        },
        withdrawalData
      );
      setOtpError(false);

      if (smsOtpVerify === "Unauthorized") {
        setOtpError(true);
      } else {
        setOtpError(false);
      }

      // history.push("/make-withdrawal/success");
    }
  };

  const maskPhoneNumber = data => {
    let mask = data.replace(/\d(?=\d{4})/g, "*");

    return mask;
  };

  if (loading) return <DefaultLoader />;

  return (
    <div style={{ backgroundColor: "#fff" }}>

      <Alert
        title="Something went wrong"
        description={error}
        isOpen={showError}
        isCancel={false}
        handleAction={() => setShowError(false)}
      />

      <StepLabel
        title="Cash Out verification"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <div style={{ margin: "0 16px 24px", textAlign: "justify" }}>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.inputLabel,
            lineHeight: 1.29,
            color: "#505050"
          }}
        >
          A verification code was sent to{" "}
          <span style={{ fontWeight: "bold" }}>
            {maskPhoneNumber(profile.mobileNo)}
          </span>{" "}
          Please enter the verification code below to complete the withdrawal.
        </Typography>
      </div>
      <div
        style={{
          margin: "0 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <OtpInput
          value={otp}
          onChange={handleChangeOtp}
          numInputs={6}
          isInputNum={true}
          separator={<span>-</span>}
          hasErrored={false}
          className="otp"
          inputStyle={{
            ...styles.baseFont,
            ...styles.otp
          }}
          errorStyle={{ color: "#be0712", border: "solid 1px #be0712" }}
          focusStyle={{ color: "#1278cc", outline: "1px solid #0076CF" }}
        />
        {isOtpError && (
          <Typography
            style={{
              ...styles.baseFont,
              fontSize: "14px",
              lineHeight: 1.57,
              color: "#be0712",
              marginTop: "16px"
            }}
          >
            You’ve entered the wrong verification code
          </Typography>
        )}
        <div style={styles.verificationResendContainer}>
          <Typography
            style={{
              ...styles.baseFont,

              fontWeight: "bold"
            }}
          >
            Didn't get verification code?&nbsp;
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              color: timeLeft < 1 ? "#1278cc" : "#505050"
            }}
          >
            {timeLeft < 1 ? (
              <span onClick={handleResendOtp} style={styles.resendBtn}>
                Resend
              </span>
            ) : (
              `Resend (${Math.floor(timeLeft)}s)`
            )}
          </Typography>
        </div>
      </div>
      <div style={styles.btnContainerV2}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!otp || loading ? true : false}
          onClick={handleVerifySms}
        />
      </div>
      <div style={styles.incorectMobileNo}>
        <p>
          {timeLeft < 1
            ? "Not your mobile number? "
            : "Incorrect mobile number? "}
        </p>

        <span
          style={styles.resendBtn}
          onClick={() => {
            window.my.call("openAppLink", {
              url: "https://wa.me/60162999792"
            });
          }}
        >
          &nbsp;Get Help
        </span>
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    smsOtp: state.goal.smsOtp,
    smsOtpVerify: state.goal.smsOtpVerify,
    profile: state.goal.profile,
    showError: state.goal.showError,
    error: state.goal.error
  }),
  { sendSMSOtp, verifySMSOtp, clearSMSOtp, setShowError }
);

export default compose(
  withRouter,
  withConnect
)(WithdrawalVerification);
