import { all, takeLatest, call, put } from "redux-saga/effects";
import * as constant from "./constant";
import {
  getProjectedReturnSuccess,
  getProjectedReturnFailed,
  getTotalUnitFundsSuccess,
  getTotalUnitFundsFailed,
  getProjectedReturnInvestSuccess,
  getProjectedReturnInvestFailed,
  updateCustomerDuplicateSuccess,
  updateCustomerDuplicateFailed,
} from "./actions";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

function* updateCustomerDuplicateSaga({ payload, callback }) {
  try {
    Object.keys(payload).forEach(key => !payload[key] && delete payload[key]);

    const res = yield call(api.put, `${API_URL.mp}/mp/customer/delta`, payload);

    if (!Object.keys(res.data).length) {
      throw new Error("Something went wrong!");
    }

    yield put(updateCustomerDuplicateSuccess());
    callback();
  } catch (error) {
    yield put(updateCustomerDuplicateFailed());
  }
}

function* getProjectedReturnSaga() {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/sys-config/getting_started_save_card`
    );
    if (!Object.keys(res.data).length) {
      throw new Error("Something wrong!");
    }
    if (res.data === "Not Found") {
      throw new Error("Something wrong!");
    }

    const { data } = res;

    yield put(getProjectedReturnSuccess(data));
  } catch (error) {
    yield put(getProjectedReturnFailed());
  }
}

function* getTotalUnitFundsSaga({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/product-category/${payload}`
    );
    if (!Object.keys(res.data).length) {
      throw new Error("Something wrong!");
    }
    if (res.data === "Not Found") {
      throw new Error("Something wrong!");
    }
    const { data } = res;

    yield put(getTotalUnitFundsSuccess(data));
  } catch (error) {
    yield put(getTotalUnitFundsFailed());
  }
}

function* getProjectedReturnInvestSaga() {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/sys-config/getting_started_invest_card`
    );
    if (!Object.keys(res.data).length) {
      throw new Error("Something wrong!");
    }
    if (res.data === "Not Found") {
      throw new Error("Something wrong!");
    }

    const { data } = res;

    yield put(getProjectedReturnInvestSuccess(data));
  } catch (error) {
    yield put(getProjectedReturnInvestFailed());
  }
}

export function* getStartedWorkerSaga() {
  yield all([
    takeLatest(constant.GET_PROJECTED_RETURN, getProjectedReturnSaga),
    takeLatest(constant.GET_TOTAL_UNIT_FUNDS, getTotalUnitFundsSaga),
    takeLatest(constant.PROJECTED_RETURN_INVEST, getProjectedReturnInvestSaga),
    takeLatest(constant.UPDATE_CUSTOMER_DUPLICATE, updateCustomerDuplicateSaga),
  ]);
}
