const styles = {
  column: {
    display: "flex",
    flexDirection: "column",
    flex: 1
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  inboxContent: {
    padding: "0 16px",
    justifyContent: "space-between"
  },
  inbox: { padding: "16px 0" },
  inboxTitle: {
    fontWeight: "700",
    lineHeight: 1.38,
    color: "#162b3a"
  },
  content: {
    color: "#162b3a",
    opacity: 0.8,
    margin: "4px 0 8px",
    width: "304px"
  },
  date: {
    fontSize: "12px",
    lineHeight: 1.83,
    color: "#9d9ea4"
  },
  unreadInbox: { backgroundColor: "#e5f4fb" },
  line: {
    borderBottom: "solid 1px rgba(200, 200, 200, 0.4)",
    margin: "0 16px"
  }
};

export default styles;
