const styles = {
  grid: { marginBottom: "8px" },
  goalLabel: {
    marginTop: "8px",
    flexGrow: 0,
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5,
    textTransform: "capitalize",
    textAlign: "center"
  }
};

export default styles;
