import { Grid, Icon, LinearProgress, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import NumberFormat from "react-number-format";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { setBlockRequest } from "../../globalRedux/actions";
import {
  checkAcctStsCashIn,
  checkAcctStsCashOut,
} from "../../utils/functions/validationsDeposit";
import { getRspDetail, setbackPayloadRsp } from "../deposit/redux/action";
import {
  getCustomer,
  getCustomerGoalDetail,
  getTransactionHistory,
  setCifPlanId,
  setGeneralSavingDetails,
  setSavingGoalDetails,
  setSavingType,
} from "../redux/action";
import {
  CATEGORY_SAVING,
  GENERAL_SAVING,
  GOALDETAILS,
} from "../redux/constant";
import { getSubAccount } from "./redux/actions";

import {
  Alert,
  AlertAccount,
  AlertDDA,
  DefaultLoader,
  MenuBar,
  SavingDetailCard,
  Transaction,
} from "../../components";
import { images } from "../../constants";
import { setCurrentEcddStatus } from "../../globalRedux/actions";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { filterSwitchReject } from "../../utils/functions/filterSwitch";
import { getRoundedValue } from "../../utils/functions/getRoundedValue";
import { useEcddChecking } from "../../utils/hooks";
import { setLastAmountSave } from "../deposit/redux/action";
import { ddaStatus, setRspButton } from "../fundDetails/utils";
import { resetSelectedPromo } from "../promoList/redux/action";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../constants/subaccount-status";
import styles from "./styles";

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  barColorPrimary: {
    background: "linear-gradient(87deg, #bee4fb 37%, #e5f4fb 105%)",
  },
})(LinearProgress);

const GoalDetails = props => {
  const {
    loading,
    customerGoals,
    transactionHistory,
    ecddStatus,
    getTransactionHistory,
    setSavingGoalDetails,
    setGeneralSavingDetails,
    setSavingType,
    history,
    match,
    classes,
    getCustomer,
    profile,
    setbackPayloadRsp,
    resetSelectedPromo,
    getSubAccount,
    subAccountLoading,
    subAccountDetail,
    commingFrom = "/",
    blockRequest,
    loadingTransactionHistory,
    isEcddCompleted,
    setCurrentEcddStatus,
    setLastAmountSave,
    getRspDetail,
    rspDetail,
  } = props;

  const savingGoalId = match.params.id;

  const [transactions, setTransactions] = useState([]);
  const [rspSetup, setRspSetup] = useState({ edit: false, setup: false });
  const [ddaPending, setDdaPending] = useState(false);
  const [accountSts, setAccountSts] = useState({
    status: "Active",
    alert: false,
    blockedRsp: false,
    blockedCashIn: false,
    blockedCashout: false,
  });
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
    shouldNotPromptOnEffect: !isEcddCompleted,
  });

  const [savingDetail] = useState(() => {
    const goal = customerGoals.find(item => item.id === savingGoalId);

    return goal;
  });

  /**
   *  Check saving type
   *  Navigate to deposit screen
   */
  const handleDeposit = () => {
    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }

    if (savingDetail.plan.retailFlag === true) {
      setSavingType(GENERAL_SAVING);
    } else {
      setSavingType(CATEGORY_SAVING);
    }
    setSavingGoalDetails(savingDetail);
    history.push(`/make-deposit/${GOALDETAILS}`);
  };

  const handleWithdraw = () => {
    dataLayerGTM("click_tag", "Click", "Dropdown", "Cash Out");
    if (accountSts.blockedCashout) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }
    if (savingDetail.plan.retailFlag === true) {
      setSavingType(GENERAL_SAVING);
      setGeneralSavingDetails(savingDetail);
    } else {
      setSavingType(CATEGORY_SAVING);
      setSavingGoalDetails(savingDetail);
    }
    history.push("/make-withdrawal");
  };

  const myReduce = data => {
    return data.reduce(
      (acc, curr) =>
        acc + parseFloat(curr.unitHeld) * parseFloat(curr.fund.nav),
      0
    );
  };

  const mySavingValue = data => {
    const asset = data.currentValue
      ? getRoundedValue(data.currentValue, 2)
      : Array.isArray(data.portfolio)
      ? getRoundedValue(myReduce(data.portfolio), 2)
      : getRoundedValue(
          parseFloat(data.portfolio.unitHeld) *
            parseFloat(data.portfolio.fund.nav),
          2
        );

    const assetPercent = data.targetPercentage
      ? Number(data.targetPercentage)
      : (asset / parseFloat(data.targetAmount)) * 100;
    const isAssetNotComplete = asset - parseFloat(data.targetAmount) < 0;

    return { asset, assetPercent, isAssetNotComplete };
  };

  const handleRSP = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Goal detail - RSP");
    props.setBlockRequest(false);

    const ddaResult = ddaStatus(savingDetail.rspStatus);
    if (ddaResult) {
      setDdaPending(ddaResult);
      return;
    }

    if (accountSts.blockedRsp) {
      setAccountSts(prev => ({ ...prev, alert: true }));
      return;
    }

    if (savingDetail.plan.retailFlag === true) {
      setSavingType(GENERAL_SAVING);
      setGeneralSavingDetails(savingDetail);
    } else {
      setSavingType(CATEGORY_SAVING);
      setSavingGoalDetails(savingDetail);
    }
    let rspSet = "setupRSP";
    if (rspSetup.edit) {
      rspSet = "edit";
    }

    history.push(`make-deposit/GOAL_DETAIL/success/auto-deposit/${rspSet}`);
  };

  useEffect(() => {
    if (!blockRequest) {
      getCustomer();
    }
    getTransactionHistory({
      size: -1,
      cifPlanId: savingGoalId,
    });
    setbackPayloadRsp({});
    setLastAmountSave();
    resetSelectedPromo();
    sessionStorage.removeItem("stopRsp");
  }, []);

  /**
   *  Get goal detail by id & set RSP status
   */
  useEffect(() => {
    if (savingDetail && typeof savingDetail === "object") {
      getRspDetail(savingDetail.id);
      setRspSetup(setRspButton(savingDetail));

      if (
        savingDetail.accountNo &&
        typeof savingDetail.accountNo === "string"
      ) {
        getSubAccount(savingDetail.accountNo);
      }
    }
  }, [savingDetail]);

  useEffect(() => {
    if (savingDetail && transactionHistory) {
      const groupedTrxByMonth = [];
      const filterSwitch = filterSwitchReject(transactionHistory);

      for (const res of filterSwitch) {
        const month = moment(res.submittedDate).format("MMMM");
        const year = new Date(res.submittedDate).getFullYear();
        const id = groupedTrxByMonth.length + 1;

        if (
          ((res.type === "Buy" ||
            res.type === "SwitchIn" ||
            res.type === "SwitchOut" ||
            res.type === "SwitchAll") &&
            res.status !== "5") ||
          ((res.type === "Sell" || res.type === "FullSell") &&
            res.status !== "10")
        ) {
          continue;
        }

        const found = groupedTrxByMonth.some(
          value => value.month === month && value.year === year
        );
        if (found) {
          const index = groupedTrxByMonth.findIndex(
            value => value.month === month && value.year === year
          );
          groupedTrxByMonth[index].data = [
            ...groupedTrxByMonth[index].data,
            res,
          ];
        } else {
          groupedTrxByMonth.push({
            id: id,
            month: month,
            year: year,
            data: [res],
          });
        }
      }

      setTransactions(groupedTrxByMonth);
    }
  }, [transactionHistory, savingDetail]);

  /**
   * * GET subAccount status to decide can do transaction or no
   * * START
   */
  useEffect(() => {
    if (!subAccountDetail) {
      return;
    }
    let newObj = {
      status: "Active",
      alert: false,
      blockedRsp: false,
      blockedCashIn: false,
      blockedCashout: false,
    };
    if ("sts" in subAccountDetail) {
      let { sts } = subAccountDetail;
      newObj.status = sts;
      let stopCashOut = checkAcctStsCashOut(sts);
      let stopCashIn = checkAcctStsCashIn(sts);
      if (!stopCashIn) {
        newObj.blockedCashIn = true;
      }
      if (!stopCashOut) {
        newObj.blockedCashout = true;
      }

      if (
        (rspSetup.setup || ["0", "21", "26"].includes(rspDetail.rspStatus)) &&
        NOT_ALLOWED_ACCOUNT_STATUS.includes(sts)
      ) {
        newObj.blockedRsp = true;
      }
      setAccountSts(newObj);
    }
  }, [subAccountDetail, rspDetail, rspSetup]);
  /**
   ** check if subaccount status is posible to do transaction
   ** end
   */

  if (!savingDetail) return null;

  return (
    <div className={`${classes.column} ${classes.container}`}>
      {(loading || loadingTransactionHistory || subAccountLoading) && (
        <DefaultLoader />
      )}
      <AlertDDA
        title="This Goal or Fund has already enabled Auto Cash In."
        firstText="Your Auto Cash In request is being processed, please come back and check the status later."
        isOpen={ddaPending}
        handleClose={() => setDdaPending(false)}
      />
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
        }}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />

      <AlertAccount
        title={`Account ${
          accountSts.status === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountSts.status === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <p>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountSts({ ...accountSts, alert: false });

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </p>
        }
        isOpen={accountSts.alert}
        handleClose={() => setAccountSts({ ...accountSts, alert: false })}
      />

      <div className={`${classes.column} ${classes.background}`}>
        <div className={`${classes.row} ${classes.navigation}`}>
          <Icon
            className="material-icons-outlined"
            style={{ cursor: "pointer", color: "#ffffff" }}
            onClick={() => history.push(commingFrom)}
          >
            arrow_back
          </Icon>
          <div className={classes.fundInfoContainer}>
            <Icon
              className="material-icons-outlined"
              fontSize="small"
              style={{ cursor: "pointer", color: "#fff" }}
            >
              info
            </Icon>
            <Typography
              className={`${classes.baseFont} ${classes.fundInfo}`}
              onClick={() => {
                window.my.navigateTo({
                  url:
                    "/pages/link/link?url=" +
                    encodeURIComponent(
                      process.env.REACT_APP_ENV.trim() === "production"
                        ? "https://www.principal.com.my/en/immfd/mv"
                        : "https://www.test.principal.com.my/en/immfd/mv"
                    ),
                });
              }}
            >
              Fund info
            </Typography>
          </div>
        </div>

        {/* Saving Name */}
        <div className={classes.saving}>
          <div className={classes.row}>
            <img
              src={
                savingDetail.product.image
                  ? savingDetail.product.image
                  : images.generalSavings
              }
              alt={
                savingDetail.planAlias
                  ? savingDetail.planAlias
                  : "general-saving"
              }
              className={classes.savingImg}
            />
            <div className={`${classes.column} ${classes.savingNameContainer}`}>
              <Typography
                className={`${classes.baseFont} ${classes.savingName}`}
              >
                {savingDetail.planAlias}
              </Typography>
            </div>
          </div>
          {savingDetail.planAlias !== "General Savings" &&
            savingDetail.planAlias !== "General Saving" && (
              <>
                <StyledLinearProgress
                  variant="determinate"
                  value={mySavingValue(savingDetail).assetPercent}
                  className={classes.linearBar}
                />
                <div className={classes.periodContainer}>
                  <Typography
                    className={`${classes.baseFont} ${classes.periodMonth}`}
                    style={{
                      color: mySavingValue(savingDetail).isAssetNotComplete
                        ? "#fff"
                        : "#ddf75b",
                      fontWeight: "900",
                    }}
                  >
                    {mySavingValue(savingDetail).isAssetNotComplete ? (
                      <>
                        {mySavingValue(savingDetail).asset < 0 && "-"}
                        RM{" "}
                        <NumberFormat
                          value={
                            mySavingValue(savingDetail).asset > 0
                              ? mySavingValue(savingDetail).asset
                              : mySavingValue(savingDetail).asset * -1
                          }
                          thousandSeparator
                          displayType="text"
                          decimalScale={2}
                          fixedDecimalScale
                        />
                      </>
                    ) : (
                      `Completed`
                    )}
                  </Typography>
                  <Typography
                    color="textPrimary"
                    className={`${classes.baseFont} ${classes.periodPercent}`}
                    style={{
                      color:
                        mySavingValue(savingDetail).assetPercent < 100
                          ? "#fff"
                          : "#ddf75b",
                    }}
                  >
                    {mySavingValue(savingDetail)
                      .assetPercent.toFixed(2)
                      .replace(/[.,]00$/, "")}
                    %
                  </Typography>
                </div>
              </>
            )}
        </div>

        {/* Saving Details */}
        <div style={{ margin: "16px" }}>
          <Grid container spacing={8}>
            <Grid item xs={6}>
              <SavingDetailCard
                label="Projected Return Rate"
                detail={`${savingDetail.plan.expPerf.toFixed(2)}%`}
              />
            </Grid>
            <Grid item xs={6}>
              <SavingDetailCard
                label="Total Return"
                detail={
                  savingDetail.totalGain
                    ? getRoundedValue(savingDetail.totalGain, 2)
                    : `${getRoundedValue(
                        mySavingValue(savingDetail).asset -
                          savingDetail.totalCashIn,
                        2
                      )}`
                }
                isMoney={true}
              />
            </Grid>
            {savingDetail.planAlias === "General Saving" ||
            savingDetail.planAlias === "General Savings" ? (
              <Grid item xs={12}>
                <SavingDetailCard
                  label="Investment Cost"
                  detail={
                    savingDetail.totalCost
                      ? parseFloat(savingDetail.totalCost)
                      : parseFloat(savingDetail.totalCashIn)
                  }
                  isMoney={true}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={6}>
                  <SavingDetailCard
                    label="Goal Target"
                    detail={savingDetail.targetAmount}
                    isMoney={true}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SavingDetailCard
                    label="Investment Cost"
                    detail={
                      savingDetail.totalCost
                        ? parseFloat(savingDetail.totalCost)
                        : parseFloat(savingDetail.totalCashIn)
                    }
                    isMoney={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>

      {/* History transfer */}
      <div className={`${classes.bg}`}>
        {transactions && transactions.length > 0 && (
          <Typography
            style={{
              ...styles.baseFont,
              padding: "16px 16px 0",
              color: "#616367",
            }}
          >
            You can refer to your Cash In or Cash Out under processing at{" "}
            <span
              style={{ color: "#1278CC" }}
              onClick={() => history.push("/transaction")}
            >
              transaction history
            </span>
            .
          </Typography>
        )}
        {transactions && transactions.length > 0 ? (
          <div style={{ paddingBottom: "7rem" }}>
            <Transaction transactions={transactions} />
          </div>
        ) : (
          <div className={`${classes.baseFont} ${classes.emptyText}`}>
            <div>
              You can refer to your Cash In or Cash Out under processing at{" "}
              <span
                style={{ color: "#1278CC" }}
                onClick={() => history.push("/transaction")}
              >
                transaction history
              </span>
              .
              <br />
              Start Cash In now.
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          backgroundColor: "#ffffff",
          position: "fixed",
          bottom: 0,
          minHeight: "6rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px -2px 12px rgba(0, 0, 0, 0.06)",
        }}
      >
        <MenuBar
          rspText={rspSetup.edit ? "Edit" : "Auto Cash In"}
          handleSwitching={handleCheckEcdd({
            cb: () => {
              history.push("/switching/create", {
                id: savingGoalId,
                productType: "save",
              });
            },
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/GOALDETAILS"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Switching Goal Detail",
            },
          })}
          handleWithdraw={handleWithdraw}
          handleDeposit={handleCheckEcdd({
            cb: handleDeposit,
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/GOALDETAILS"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Cash In Goal Detail",
            },
          })}
          handleAutoDeposit={handleRSP}
          isBackgroundGrayed
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    customerGoals: state.goal.customerGoals,
    transactionHistory: state.goal.transactionHistory.data,
    ecddStatus: state.goal.ecddStatus,
    profile: state.goal.profile,
    rspDetail: state.autoDepositReducer.rspDetail,
    commingFrom: state.commingFromReducer.commingFrom,
    blockRequest: state.commingFromReducer.blockRequest,
    loadingTransactionHistory: state.goal.loadingTransactionHistory,
    isEcddCompleted: state.commingFromReducer.isEcddCompleted,
    ...state.goalDetailReducer,
  }),
  {
    getCustomerGoalDetail, // API
    getTransactionHistory, // API
    setSavingGoalDetails,
    setGeneralSavingDetails,
    setSavingType,
    getCustomer, // API
    setbackPayloadRsp,
    getRspDetail, // API
    getSubAccount, // API
    setBlockRequest,
    setCifPlanId,
    setCurrentEcddStatus,
    resetSelectedPromo,
    setLastAmountSave,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles),
  withProfileRedirect
)(GoalDetails);
