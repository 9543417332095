import React from "react";
import { withRouter } from "react-router";
import { compose } from "underscore";

const IntroPage = ({ history }) => {
  const handleClick = () => {
    window.my.postMessage({
      api: "getAuthCode",
      scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
      event: "getAccessToken"
    });
    history.push("/onBoarding/about");
  };

  const handleLink = link => {
    link &&
      window.my.navigateTo({
        url: "/pages/link/link?url=" + encodeURIComponent(link)
      });
  };

  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf"
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h2>Intro page</h2>
      <button onClick={handleClick}>Go to About</button>
      <a onClick={() => handleLink("https://www.principal.com.my/en/about-us")}>
        About Principal
      </a>
      <a
        onClick={() =>
          handleOpenPDF(
            "https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Conventional_Funds.pdf"
          )
        }
      >
        Open pdf
      </a>
    </div>
  );
};

export default compose(withRouter)(IntroPage);
