import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import React from "react";
import { CustomButton } from "..";
import "./style.scss";

const EmptyState = ({
  handleNext,
  faqLink,
  title,
  desc,
  tagline,
  actionText,
  illustration,
  className,
}) => {
  const faqNavigate = () => {
    dataLayerGTM("click_tag", "Click", "Link", "Dashboard empty - FAQ");
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(faqLink),
    });
  };

  return (
    <div className={`l-invest ${className}`}>
      <div className="l-invest__first-layer">
        <div>
          <h2 className="l-invest__title">{title}</h2>
          <div className="l-invest__desc">{desc}</div>
          <div>
            <CustomButton
              text={actionText}
              variant="contained"
              color="#fff"
              backgroundColor="#1278CC"
              style={{ marginTop: "1rem" }}
              onClick={handleNext}
            />
          </div>
        </div>
        <p onClick={faqNavigate} className="l-invest__faq">
          Frequently Asked Questions
        </p>
      </div>

      <div className="l-invest__second-layer" />
    </div>
  );
};

export default EmptyState;
