import React, { useState } from "react";
import "./style.scss";
import { FundCard, AlertAccount, CustomButton } from "../../components";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import {
  setIvestMinAmount,
  setSelectedFundCust,
  sendFundsCode,
  createFundV2,
} from "./redux/action";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setCustFundDetail } from "../fundDetails/redux/action";

const tabs = ["conservative", "moderate", "aggressive"];

const calculateRangeDate = dateToCompare => {
  const date1 = new Date(dateToCompare);
  const date2 = new Date();
  const diffDays = Math.ceil(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));

  return diffDays;
};

function FundSelectionList(props) {
  const {
    setIvestMinAmount,
    setSelectedFundCust,
    customerPortfolio,
    setCustFundDetail,
    scoreList,
    sendFundsCode,
    history,
    createFundV2,
    currentTab,
    funds,
    fundList,
    riskProfileResult,
  } = props;

  const [selectedFunds, setSelectedFunds] = useState({
    code: "",
    name: "",
    salesCharge: "",
  });

  const [show, setShow] = useState({
    fundChangeAlert: false,
    fundInfo: false,
  });

  const handleSelect = val => () => {
    let { code, name, salesFeePerc: salesCharge } = val;
    dataLayerGTM("click_tag", "Click", "Button", `Fund ${name} - Select`);

    const fund = fundList.find(item => item.code === code) || {};
    const customerFund =
      customerPortfolio.find(item => item.fundCd === code) || {};
    const fundPayload = { code, name, salesCharge };

    setIvestMinAmount(fund.minSubsAmt);
    setSelectedFundCust(val);
    setSelectedFunds(fundPayload);
    setCustFundDetail(customerFund);

    let result = checkChoseFund(fund.riskProfileCode);

    if (result === "HIGH_RISK") {
      setShow(prev => ({ ...prev, fundChangeAlert: true }));
    } else {
      handleNext(fundPayload);
    }
  };

  const checkChoseFund = code => {
    let onLevel = [];
    if (Array.isArray(scoreList)) {
      onLevel = scoreList.filter(item => {
        if (
          parseInt(riskProfileResult.result) >= parseInt(item.lowerBound) &&
          parseInt(riskProfileResult.result) <= parseInt(item.upperBound)
        )
          return item;
      });
    }
    if (onLevel.length > 0 && "upperBound" in onLevel[0]) {
      if (parseInt(code) >= onLevel[0].upperBound) {
        return "HIGH_RISK";
      }
      return "STANDART_RISK";
    }
  };

  const handleNext = fundsCode => {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));

    sendFundsCode([fundsCode]);

    createFundV2(
      {
        cifId: userInfo.masterId,
        fundsPayload: [{ fundCode: fundsCode.code }],
      },
      () => {
        // redux-saga action still running(not finished request yet) when user click back button
        // so when user click the button they'll go to dashboard instead of fund selection step 1
        // why go to the dashboard?, because at the moment when user click back they're still in the fund selection step 1
        // here's the illustration of browser history -> ["dashboard", "fund-selection"] -> user click back -> ["dashboard"]
        // in the dashboard there's useeffect with bunch of reset state, see line 117
        // and then when user finish the create portfolio, this callback is running meaning they go to fund selection step 2
        // this is where the error happen because now the `fundsCode` array becomes empty

        if (!history.location.pathname.includes("fund-selection")) return;
        //! important: redux-saga action is running at the top of the react tree, so when react unmounted component/page inside the redux-saga context
        //! (See Provider component at root index.js line 45), they'll still running eventhough the current page that running the sideffects is already unmounted
        //! usually when component/page unmounted the expected behaviour is for all side effects in that component/page cease to exist, ie: gone reduce to atom
        //! that's why useEffect have cleanup function to prevent something like this
        //! why dont use useEffect to cleanup this race condition?
        //! too much work that's why, we need to add guard in the saga with risked of affecting other page, ie: switching page

        history.push("/fund-selection-step-two");
      }
    );
  };

  return (
    <>
      <AlertAccount
        title="Are you sure you want to invest in higher risk fund?"
        firstText="You may potentially gain higher returns but your capital may be exposed to higher risk. Do you wish to continue?"
        isOpen={show.fundChangeAlert}
        hideDoneButton
        firstTextDialogStyle={{ marginTop: "0.5rem", paddingBottom: "0" }}
        handleClose={() =>
          setShow(prev => ({ ...prev, fundChangeAlert: false }))
        }
        titleStyle={{
          fontSize: "1.25rem",
          fontWeight: "700",
          lineHeight: "28px",
        }}
      >
        <div>
          <CustomButton
            variant="contained"
            text="Yes, Continue"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={() => handleNext(selectedFunds)}
          />
          <CustomButton
            variant="contained"
            text="Maybe other fund"
            color="#1278CC"
            backgroundColor="transparent"
            onClick={() =>
              setShow(prev => ({ ...prev, fundChangeAlert: false }))
            }
            style={{ boxShadow: "none", marginTop: "0.5rem" }}
          />
        </div>
      </AlertAccount>
      <div className="funds">
        {(funds[currentTab] || funds["conservative"] || [])
          .sort((a, b) => {
            const aEffectiveDate = calculateRangeDate(a.effectiveDate);
            const bEffectiveDate = calculateRangeDate(b.effectiveDate);

            const hasNoThreeAnnualReturnA = a.threeAnnualReturn === "0%";
            const hasNoThreeAnnualReturnB = b.threeAnnualReturn === "0%";

            // Check if the item is currently in the range between today and 7 days forward
            const inRangeA = aEffectiveDate >= 0 && aEffectiveDate <= 7;
            const inRangeB = bEffectiveDate >= 0 && bEffectiveDate <= 7;

            // Prioritize in-range items at the top
            if (inRangeA && !inRangeB) return -1;
            if (!inRangeA && inRangeB) return 1;

            // Items with no threeAnnualReturn are placed at the bottom
            if (hasNoThreeAnnualReturnA && !hasNoThreeAnnualReturnB) return 1;
            if (!hasNoThreeAnnualReturnA && hasNoThreeAnnualReturnB) return -1;

            // If both items have no threeAnnualReturn and are in the range, compare by effectiveDate
            if (
              hasNoThreeAnnualReturnA &&
              hasNoThreeAnnualReturnB &&
              inRangeA &&
              inRangeB
            ) {
              if (aEffectiveDate > bEffectiveDate) return 1;
              if (bEffectiveDate > aEffectiveDate) return -1;
            }

            // Perform sorting logic based on threeAnnualReturn
            if (a.threeAnnualReturn !== "0%" && b.threeAnnualReturn !== "0%") {
              const threeAnnualReturnDiff =
                Number(b.threeAnnualReturn.replace("%", "")) -
                Number(a.threeAnnualReturn.replace("%", ""));

              if (threeAnnualReturnDiff !== 0) {
                return threeAnnualReturnDiff;
              }
            }

            // If threeAnnualReturn is zero or not valid, compare by effectiveDate
            if (aEffectiveDate > bEffectiveDate) return 1;
            if (bEffectiveDate > aEffectiveDate) return -1;

            // If effectiveDate is also the same, compare by name
            return a.name.localeCompare(b.name);
          })
          .map((item, idx) => (
            <FundCard
              key={idx}
              index={tabs.indexOf(currentTab)}
              fundName={item.name}
              annualReturn={
                item.threeAnnualReturn &&
                item.threeAnnualReturn !== "0%" &&
                item.threeAnnualReturn
              }
              fundInfoUrl={item.fundInfoUrl}
              assetClass={item.assetClass}
              isSyariah={Number(item.isSyariahFund) > 0}
              isNewFund={calculateRangeDate(item.effectiveDate) <= 7}
              onSelect={handleSelect(item)}
              details={item.details}
            />
          ))}
      </div>
    </>
  );
}

const withConnect = connect(
  state => ({
    scoreList: state.fundSelectionReducer.scoreList,
    customerPortfolio: state.dashboardReducer.customerPortfolio,
  }),
  {
    setIvestMinAmount,
    setSelectedFundCust,
    setCustFundDetail,
    sendFundsCode,
    createFundV2,
  }
);

export default compose(
  withRouter,
  withConnect
)(FundSelectionList);
