import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { getNewsFeed, hideError } from "../redux/action";

import {
  StepLabel,
  NewsCard,
  DefaultLoader,
  BottomNav,
  ErrorAlert
} from "../../components";
import styles from "./styles";
import "../styles/index.scss"; // global styles

const NewsList = props => {
  const {
    loading,
    isModalOpen,
    errorMessage,
    history,
    classes,
    newsFeed,
    getNewsFeed
  } = props;

  /**
   * @function Navigate back to previous screen
   *    if New User go back to get started screen
   *    else redirect to dashboard screen
   */
  const handleBack = () => {
    history.back();
  };

  useEffect(() => {
    getNewsFeed();
  }, []);

  return (
    <div className={`column ${classes.container}`}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="What's New"
        containerStyles={{ margin: "16px 16px 22px" }}
        onClick={handleBack}
      />

      {newsFeed && newsFeed.length > 0 ? (
        <>
          {newsFeed
            .sort((a, b) => a.dataIndex - b.dataIndex)
            .map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ margin: "0 16px", paddingTop: "8px" }}
                >
                  <NewsCard
                    key={index}
                    image={item.imageUrl}
                    title={item.name}
                    url={item.articleUrlEn}
                    date={item.lastUpdated}
                  />
                </div>
              );
            })}
        </>
      ) : null}

      {/* Bottom Navigation */}
      <div
        style={{
          position: "sticky",
          bottom: 0,
          width: "100%",
          display: "inline-block",
          marginTop: "auto"
        }}
      >
        <BottomNav />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    isModalOpen: state.goal.isModalOpen,
    newsFeed: state.goal.newsFeed
  }),
  { getNewsFeed, hideError }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(NewsList);
