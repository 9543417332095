import { all, takeLatest, call, put, select } from "redux-saga/effects";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";
import { createGoalDLSuccess, createGoalDLFailed } from "./action";
import { CREATE_GOAL_DL, GET_CGOAL_DL, DL_CHECKOUT } from "./constants";
import {
  getcGoalDLSuccess,
  getcGoalDLFailed,
  deeplinkCheckoutSuccess,
  deeplinkCheckoutFailed,
} from "./action";
import { v4 as uuidv4 } from "uuid";

function* createGoalDL({ payload, callback }) {
  try {
    const masterId = yield select(state => {
      if (state.goal.profile && state.goal.profile.masterId) {
        return state.goal.profile.masterId;
      }
      return null;
    });
    let newPayload = { ...payload };
    newPayload.cifId = masterId;
    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/goal-creation`,
      newPayload
    );
    const { data } = res;

    yield put(createGoalDLSuccess(data));

    callback(data.accountNo);
  } catch (error) {
    yield put(createGoalDLFailed(error));
  }
}

function* getCGoalDLSaga({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/goal-creation/${payload}`
    );
    const { data } = res;

    yield put(getcGoalDLSuccess(data));
  } catch (error) {
    yield put(getcGoalDLFailed());
  }
}

function* deeplinkChekoutSaga({ payload }) {
  try {
    const {
      customerChannelCode,
      trxRefNo,
      amount,
      ccy,
      name,
      email,
      cifID,
      cifPlanId,
      campaignCode,
      productId,
    } = payload;

    payload.productId = undefined;
    payload.cifPlanId = undefined;
    payload.campaignCode = undefined;

    const res = yield call(
      api.post,
      `${API_URL.ppg}/payment/generate`,
      payload
    );
    payload.productId = productId;
    payload.cifPlanId = cifPlanId;

    const { data } = res;

    const dummy = {
      customerChannelCode,
      payCatCd: null,
      trxRefNo,
      amount,
      ccy,
      cifID,
      refId: trxRefNo.slice(2, trxRefNo.length),
      name,
      email,
      lang: "en",
      signature: data.signature,
      resUrl: `${API_URL.mp}/webhooks/res-url`, // to redirect back to mp
      backUrl: `${API_URL.backURL}`,
    };

    const requestCheckout = {
      transactionHostRefNo: uuidv4(), // primary key unique
      productId, //goal id
      cifPlanId,
      campaignCode,
      trxRefNo,
      accountNo: "",
      trxType: "FPX",
      amountCcy: "MYR",
      amount: parseFloat(amount),
      unit: 0,
      feePercent: 0,
      feeCcy: "MYR",
      feeAmount: 0,
      sourceOfFund: "",
      bankCd: "",
      bankAccountNo: "",
      bankAccountName: "",
      paymentTypeCd: "",
      ppgRefNo: "",
      pgRefNo: "",
      recipientBank: "",
      type: "",
      approveTime: new Date().toString(),
      trxUnitPaymentProvider: "",
      trxUnitPaymentType: "",
      status: "",
    };

    const resCheckout = yield call(
      api.post,
      `${API_URL.mp}/checkout`,
      requestCheckout
    );

    // Data input to ppg
    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    myForm.action = `${API_URL.ppg}/entry`;
    // myForm.action = `${API_URL.ppgLocal}`; // try on local
    for (const key in dummy) {
      if (Object.hasOwnProperty.call(dummy, key)) {
        const element = dummy[key];
        const myInput = document.createElement("input");
        myInput.type = "hidden";
        myInput.name = key;
        myInput.id = key;
        myInput.value = element;
        myForm.appendChild(myInput);
      }
    }
    document.body.appendChild(myForm);

    yield put(deeplinkCheckoutSuccess());

    myForm.submit();
  } catch (error) {
    yield put(deeplinkCheckoutFailed());
  }
}

export function* deeplinkWorkerSaga() {
  yield all([
    takeLatest(CREATE_GOAL_DL, createGoalDL),
    takeLatest(GET_CGOAL_DL, getCGoalDLSaga),
    takeLatest(DL_CHECKOUT, deeplinkChekoutSaga),
  ]);
}
