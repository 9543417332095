import React, { useState, useEffect } from "react";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  StepLabel,
  InputBasic,
  CustomButton,
  PromoCard,
  DefaultLoader,
} from "../../components";
import { setSelectedPromo } from "./redux/action";
import { getSelectedPromoByCode } from "../../utils/functions/getSelectedPromoByCode";
import "./style.scss";

function PromoList(props) {
  const {
    promoList = [],
    history,
    promoListLoading,
    setSelectedPromo,
    location,
  } = props;
  const [promoActive, setPromoActive] = useState(false);
  const [chosenPromo, setChosenPromo] = useState({});
  const [isErrorPromo, setIsErrorPromo] = useState({
    error: false,
    message: "Please enter a valid Promo Code",
  });
  const [referralCode, setReferralCode] = useState("");

  const getPromoByCode = getSelectedPromoByCode(promoList);

  // const handleChagePromo = val => {
  //   setIsErrorPromo(false);
  //   let referralCodeResult;
  //   if (Array.isArray(promoList)) {
  //     referralCodeResult = promoList.filter(item => {
  //       return item.eligibilityRules.codes.some(code => code.code === val);
  //     });
  //   }
  //   if (val && referralCodeResult.length > 0 && val.length > 0) {
  //     setPromos(referralCodeResult);
  //     setUserReferralCode(val);
  //   } else {
  //     setUserReferralCode(false);
  //     getPublicPromos(promoList);
  //   }
  // };

  const handleChagePromo = val => {
    setReferralCode(val);
  };

  const chosePromo = val => {
    if (Object.keys(chosenPromo).length > 0 && val.campaignId === promoActive) {
      setChosenPromo({});
      setSelectedPromo({});
      setPromoActive(false);
      return;
    }
    setPromoActive(val.campaignId);
    setChosenPromo(val);
  };

  useEffect(() => {
    if (location.state && location.state.promoId) {
      promoList.map(item => {
        if (item.campaignId === location.state.promoId) {
          setPromoActive(item.campaignId);
          setChosenPromo(item);
        }
      });
      return;
    }
    if (location.state && location.state.referralCode) {
      setReferralCode(location.state.referralCode);
    }
  }, [location, promoList]);

  const hasMatchingPromo = val => {
    let matchingPromoCode;
    if (Array.isArray(promoList)) {
      matchingPromoCode = getPromoByCode(val);
    }
    if (matchingPromoCode.length > 0) {
      setSelectedPromo(matchingPromoCode[0]);
      history.back();
    } else {
      setIsErrorPromo({
        ...isErrorPromo,
        error: true,
        message: "Please enter a valid Promo Code",
      });
    }
  };

  const submitPromo = () => {
    if (Object.keys(chosenPromo).length > 0 && referralCode) {
      setIsErrorPromo({
        ...isErrorPromo,
        error: true,
        message: "Please select 1 campaign code only",
      });
      return;
    }

    if (Object.keys(chosenPromo).length === 0 && referralCode) {
      hasMatchingPromo(referralCode);
      return;
    }

    let payload;
    if (Object.keys(chosenPromo).length > 0) {
      payload = chosenPromo;
    }
    if (referralCode) {
      payload.selectedReferralCode = referralCode;
    }
    setSelectedPromo(payload);
    history.back();
  };

  if (promoListLoading) return <DefaultLoader />;

  return (
    <div className="promo-list">
      <div>
        <StepLabel
          title="Campaign Promo"
          containerStyles={{ margin: "16px 16px" }}
          onClick={() => history.back()}
        />
        <div className="promo-list__search-bar">
          <InputBasic
            value={referralCode || ""}
            error={isErrorPromo.error}
            errorMessage={isErrorPromo.message}
            placeholder="Enter promo or referral code"
            type="text"
            isCurrency={false}
            onChange={e => {
              handleChagePromo(e.target.value);
              setIsErrorPromo({ ...isErrorPromo, error: false });
            }}
          />
        </div>
        <div className="promo-list__container">
          {promoList.map((item, idx) => (
            <PromoCard
              activePromo={item.campaignId === promoActive ? true : false}
              promoName={item.name}
              tNc={item.termsAndConditionUrl}
              minCashIn={item.eligibilityRules.minimumSingleDeposit}
              onClick={() => chosePromo(item)}
              key={idx}
            />
          ))}
        </div>
      </div>

      <div className="promo-list__apply-btn">
        <CustomButton
          text="Apply"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={
            Object.keys(chosenPromo).length > 0 || referralCode ? false : true
          }
          onClick={() => {
            submitPromo();
          }}
        />
      </div>
    </div>
  );
}
const withConnect = connect(
  state => ({
    ...state.promoReducer,
  }),
  { setSelectedPromo }
);

export default compose(
  withRouter,
  withConnect
)(PromoList);
