import {
  GET_ALLOW_PROFILE,
  GET_ALLOW_PROFILE_FAILED,
  GET_ALLOW_PROFILE_SUCCESS
} from "./constans";

export const getAllowProfile = () => {
  return {
    type: GET_ALLOW_PROFILE
  };
};
export const getAllowProfileSuccess = payload => {
  return {
    type: GET_ALLOW_PROFILE_SUCCESS,
    payload
  };
};
export const getAllowProfileFailed = () => {
  return {
    type: GET_ALLOW_PROFILE_FAILED
  };
};
