import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { NEW_USER, GET_STARTED } from "../redux/constant";

import { StepLabel, CustomButton } from "../../components";
import { icons, images } from "../../constants";
import styles from "./styles";
import "../styles/index.scss"; // global styles

const MoreInfo = ({ history, classes }) => {
  /**
   * @function Navigate back to previous screen
   */
  const handleBack = () => {
    history.back();
  };

  /**
   * @event GetStarted click event
   * @function getAuthCode from mini program
   * @event Save user status into local storage as New User
   * @event Navigate to create goal screen
   */
  const handleGetStarted = () => {
    window.my.postMessage({
      api: "getAuthCode",
      scopes: ["auth_base", "auth_user", "USER_INFO_EKYC", "FIN_PROFILE"],
      event: "getAccessToken"
    });
    localStorage.setItem("isNewUser", NEW_USER);
    history.push(`/step-one`);
  };

  return (
    <div className="bg-white">
      <StepLabel containerStyles={{ margin: "16px" }} onClick={handleBack} />

      <div className="center">
        <img
          src={images.principalBuilding}
          alt="principal-building"
          className="img"
        />
      </div>

      <div className="info">
        <div className="row info-detail">
          <div>
            <img src={icons.lockColor} alt="lock-color" />
          </div>
          <Typography
            className={`${classes.baseFont} ${classes.infoTitle}`}
            color="textPrimary"
          >
            Secured Investements with Principal
          </Typography>
        </div>
        <Typography className={`${classes.baseFont}`} color="textPrimary">
          Principal Asset Management, is a joint venture between Principal
          Financial Group®, a member of the FORTUNE 500® and a Nasdaq-listed
          global financial services and CIMB Group, one of Southeast Asia’s
          leading universal banking groups.
        </Typography>
        <div className="row info-detail">
          <div>
            <img src={icons.trendingUp} alt="trending-up" />
          </div>
          <Typography
            className={`${classes.baseFont} ${classes.infoTitle}`}
            color="textPrimary"
          >
            Easy and flexible Investments with Principal
          </Typography>
        </div>
        <Typography className={`${classes.baseFont}`} color="textPrimary">
          Earn returns up to 2.1%* per annum and start saving just as low as
          RM10. You can deposit and withdraw whenever you want.
        </Typography>
        <div className="row info-detail">
          <div>
            <img src={icons.userCheck} alt="user-check" />
          </div>
          <Typography
            className={`${classes.baseFont} ${classes.infoTitle}`}
            color="textPrimary"
          >
            Personalise your savings
          </Typography>
        </div>
        <Typography className={`${classes.baseFont}`} color="textPrimary">
          Save for a house downpayment, retirement or just to build wealth. Tell
          us about your financial goal and we’ll build a plan to get you there.
        </Typography>
        <div className="row info-detail">
          <div>
            <img src={icons.checkSquare} alt="check-square" />
          </div>
          <Typography
            className={`${classes.baseFont} ${classes.infoTitle}`}
            color="textPrimary"
          >
            Shariah Compliant Fund
          </Typography>
        </div>
        <Typography className={`${classes.baseFont}`} color="textPrimary">
          We advocate a broader definition of Shariah compliance by going beyond
          the investment screening process to ensure that all aspects of the
          investment management operations are Shariah-compliant.
        </Typography>
      </div>

      <div className="btn">
        <CustomButton
          text="Get Started"
          onClick={handleGetStarted}
          color="#fff"
          backgroundColor="#1278cc"
        />
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  withStyles(styles)
)(MoreInfo);
