import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import {
  updateProfile,
  getAnnualIncome,
  getBusinessNature,
  getCountry,
  getMaritalStatus,
  getRace,
  getOccupation,
  getStateList,
  getSourceOfFund,
  hideError,
  checkEcddStatus,
  clearError
} from "../../redux/action";

import {
  StepLabel,
  Info,
  Dropdown,
  ProfileDropdown,
  CountryItem,
  DefaultLoader,
  Alert,
  AlertInfo,
  ErrorAlert
} from "../../../components";
import styles from "../styles";
import { dataLayerGTM } from "../../../utils/functions/dataLayerGTM";
import { getInvestmentObjective } from "../../onBoarding/redux/action";

const ProfileInfo = props => {
  const {
    loading,
    isModalOpen,
    errorMessage,
    profile,
    occupationList,
    businessNatureList = [],
    annualIncomeList,
    raceList,
    maritalStatusList,
    countryList,
    stateList,
    getAnnualIncome,
    getBusinessNature,
    getCountry,
    getMaritalStatus,
    getOccupation,
    getRace,
    getStateList,
    getSourceOfFund,
    getInvestmentObjective,
    sourceOfFundList,
    updateProfile,
    checkEcddStatus,
    ecddStatus,
    hideError,
    clearError,
    error,
    history,
    investmentObjective = [],
    investmentObjectiveLoading,
    allowUpdateProfile
  } = props;
  const [nobfilter, setNobFilter] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [country, setCountry] = useState([]);
  const [taxCountry, setTaxCountry] = useState([]);
  const [nobListV2, setnobListV2] = useState([]);

  // dropdown state
  const [isCountryOpen, setCountryOpen] = useState(false);
  const [isStatusOpen, setStatusOpen] = useState(false);
  const [isRaceOpen, setRaceOpen] = useState(false);
  const [isOccupationOpen, setOccupationOpen] = useState(false);
  const [isBusinessNatureOpen, setBusinessNatureOpen] = useState(false);
  const [isAnnualIncomeOpen, setAnnualIncomeOpen] = useState(false);
  const [isSourceOfFundOpen, setSourceOfFundOpen] = useState(false);
  const [isStateOpen, setStateOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMaritalSts, setSelectedMaritalSts] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [selectedBusinessNature, setSelectedBusinessNature] = useState("");
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState("");
  const [selectedSourceOfFund, setSelectedSourceOfFund] = useState("");
  const [selectedTaxCountry, setSelectedTaxCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedInvestObj, setSelectedInvestObj] = useState("");
  const [pendingAlert, setPendingAlert] = useState("");

  const [idInfo, setIdInfo] = useState({
    idNumber: "", // id card number
    fullName: "",
    dateOfBirth: "",
    country: "",
    cityOfBirth: "",
    gender: "",
    maritalStatus: "",
    race: ""
  });
  const [contactInfo, setContactInfo] = useState({
    contactNumber: "",
    email: "",
    address1: "",
    address2: "",
    address3: "",
    city: "",
    state: "",
    postCode: ""
  });
  const [employmentInfo, setEmploymentInfo] = useState({
    occupation: "",
    industry: "",
    annualIncome: "",
    employerName: ""
  });
  const [otherInfo, setOtherInfo] = useState({
    cashPurpose: "",
    sourceOfFunds: "",
    isPoliticalPosition: "No",
    otherTaxResident: "Yes",
    taxCountry: "",
    taxId: ""
  });

  useEffect(() => {
    getCountry();
    getMaritalStatus();
    getRace();
    getStateList();
    getOccupation();
    getBusinessNature();
    getAnnualIncome();
    getSourceOfFund();
    getInvestmentObjective();
  }, []);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (profile) {
      if (
        profile.masterId &&
        profile.amlHistory &&
        profile.amlHistory.length > 0 &&
        profile.amlHistory[0].amlSts === "3"
      ) {
        checkEcddStatus(profile.masterId);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (
      profile.amlHistory &&
      profile.amlHistory.length > 0 &&
      profile.amlHistory[0].amlSts === "3" &&
      ecddStatus.ecddSts === "1"
    ) {
      setPendingAlert(true);
    }
  }, [profile, ecddStatus]);

  // Country
  useEffect(() => {
    if (countryList) {
      let sortedName = countryList.sort((a, b) => {
        return a.name < b.name ? -1 : 1;
      });
      let sorted = sortedName.sort((a, b) => {
        return a.index < b.index ? -1 : 1;
      });
      setCountry(sorted);
      setTaxCountry(sorted);
    }
  }, [countryList]);

  useEffect(() => {
    if (userInfo) {
      setSelectedCountry(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "NRIC")
          ? userInfo.identities.find(identity => identity.idTypeCd === "NRIC")
              .citizenship
          : ""
      );
      setSelectedTaxCountry(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? userInfo.identities.find(identity => identity.idTypeCd === "TAX")
              .citizenship
          : ""
      );
      setSelectedMaritalSts(
        userInfo.maritalStatusCd ? userInfo.maritalStatusCd : ""
      );
      setSelectedRace(userInfo.race ? userInfo.race : "");
      setSelectedState(
        userInfo.contacts.length > 0
          ? userInfo.contacts.find(
              contact => contact.contactTypeCd === "PERMANENT"
            ).state
          : ""
      );
      setSelectedOccupation(
        userInfo.occupationTypeCd ? userInfo.occupationTypeCd : ""
      );
      setSelectedBusinessNature(
        userInfo.natureOfBusinessCd ? userInfo.natureOfBusinessCd : ""
      );
      setSelectedAnnualIncome(
        userInfo.yearlyIncomeLevelCd ? userInfo.yearlyIncomeLevelCd : ""
      );
      setSelectedSourceOfFund(
        userInfo.sourceOfFundCd ? userInfo.sourceOfFundCd : ""
      );
      setSelectedInvestObj(
        userInfo.investmentObjCd ? userInfo.investmentObjCd : ""
      );

      setIdInfo(prevState => ({
        ...prevState,
        idNumber:
          userInfo.identities.length > 0 &&
          userInfo.identities.find(identity => identity.idTypeCd === "NRIC")
            ? userInfo.identities.find(identity => identity.idTypeCd === "NRIC")
                .idNo
            : "",
        fullName: userInfo.fullName ? userInfo.fullName : "",
        dateOfBirth: userInfo.dob ? userInfo.dob : "",
        cityOfBirth: userInfo.townOfBirth ? userInfo.townOfBirth : "",
        gender: userInfo.gender ? userInfo.gender : ""
      }));

      setContactInfo(prevState => ({
        ...prevState,
        contactNumber: userInfo.mobileNo ? userInfo.mobileNo : "",
        email: userInfo.email ? userInfo.email : "",
        address1:
          userInfo.contacts.length > 0 &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).line1
            ? userInfo.contacts.find(
                contact => contact.contactTypeCd === "PERMANENT"
              ).line1
            : "",
        address2:
          userInfo.contacts.length > 0 &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).line2
            ? userInfo.contacts.find(
                contact => contact.contactTypeCd === "PERMANENT"
              ).line2
            : "",
        address3:
          userInfo.contacts.length > 0 &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).line3
            ? userInfo.contacts.find(
                contact => contact.contactTypeCd === "PERMANENT"
              ).line3
            : "",
        postCode:
          userInfo.contacts.length > 0 &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).postCode
            ? userInfo.contacts.find(
                contact => contact.contactTypeCd === "PERMANENT"
              ).postCode
            : "",
        city:
          userInfo.contacts.length > 0 &&
          userInfo.contacts.find(
            contact => contact.contactTypeCd === "PERMANENT"
          ).city
            ? userInfo.contacts.find(
                contact => contact.contactTypeCd === "PERMANENT"
              ).city
            : ""
      }));

      setEmploymentInfo(prevState => ({
        ...prevState,
        employerName: userInfo.employerName ? userInfo.employerName : ""
      }));

      setOtherInfo(prevState => ({
        ...prevState,
        cashPurpose:
          userInfo.investmentObjCd &&
          investmentObjective.find(
            obj => obj.code === userInfo.investmentObjCd
          ) !== undefined
            ? investmentObjective.find(
                obj => obj.code === userInfo.investmentObjCd
              ).name
            : "",
        otherTaxResident:
          userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
            ? "Yes"
            : "No",
        taxId:
          userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
            ? userInfo.identities.find(identity => identity.idTypeCd === "TAX")
                .idNo
            : ""
      }));
    }
  }, [userInfo, investmentObjective]);

  useEffect(() => {
    if (selectedCountry && countryList) {
      if (selectedCountry === "MYS") {
        setIdInfo(prevState => ({
          ...prevState,
          country: "Malaysia"
        }));
      } else if (selectedCountry) {
        setIdInfo(prevState => ({
          ...prevState,
          country: countryList.find(
            countryItem => countryItem.code === selectedCountry
          )
            ? countryList.find(
                countryItem => countryItem.code === selectedCountry
              ).name
            : ""
        }));
      } else {
        setIdInfo(prevState => ({
          ...prevState,
          country: ""
        }));
      }
    }
  }, [selectedCountry, countryList]);

  useEffect(() => {
    if (selectedTaxCountry && countryList) {
      setOtherInfo(prevState => ({
        ...prevState,
        taxCountry: countryList.find(
          countryItem => countryItem.code === selectedTaxCountry
        )
          ? countryList.find(
              countryItem => countryItem.code === selectedTaxCountry
            ).name
          : ""
      }));
    }
  }, [selectedTaxCountry, countryList]);

  useEffect(() => {
    if (selectedMaritalSts && maritalStatusList) {
      setIdInfo(prevState => ({
        ...prevState,
        maritalStatus: maritalStatusList.find(
          maritalSts => maritalSts.code === selectedMaritalSts
        )
          ? maritalStatusList.find(
              maritalSts => maritalSts.code === selectedMaritalSts
            ).name
          : ""
      }));
    }
  }, [selectedMaritalSts, maritalStatusList]);

  useEffect(() => {
    if (selectedRace && raceList) {
      setIdInfo(prevState => ({
        ...prevState,
        race: raceList.find(race => race.code === selectedRace)
          ? raceList.find(race => race.code === selectedRace).name
          : ""
      }));
    }
  }, [selectedRace, raceList]);

  useEffect(() => {
    if (selectedState && stateList) {
      setContactInfo(prevState => ({
        ...prevState,
        state: stateList.find(stateItem => stateItem.code === selectedState)
          ? stateList.find(stateItem => stateItem.code === selectedState).name
          : ""
      }));
    }
  }, [selectedState, stateList]);
  const stopOccupations = ["23", "91", "90", "62"];

  useEffect(() => {
    let result = stopOccupations.includes(profile.occupationTypeCd);
    if (selectedOccupation && occupationList) {
      setEmploymentInfo(prevState => ({
        ...prevState,
        occupation: occupationList.find(
          occupation => occupation.code === selectedOccupation
        )
          ? occupationList.find(
              occupation => occupation.code === selectedOccupation
            ).name
          : ""
      }));
    }
    if (result) {
      setNobFilter(false);
    } else {
      setNobFilter(true);
    }
    if (!allowUpdateProfile) {
      setNobFilter(false);
    }
  }, [selectedOccupation, occupationList, allowUpdateProfile]);

  useEffect(() => {
    if (Array.isArray(businessNatureList)) {
      setnobListV2(businessNatureList.filter(item => item.code !== "99"));
    }
  }, [businessNatureList]);

  useEffect(() => {
    if (selectedBusinessNature && businessNatureList) {
      setEmploymentInfo(prevState => ({
        ...prevState,
        industry: businessNatureList.find(
          business => business.code === selectedBusinessNature
        )
          ? businessNatureList.find(
              business => business.code === selectedBusinessNature
            ).name
          : ""
      }));
    }
  }, [selectedBusinessNature, businessNatureList]);

  useEffect(() => {
    if (selectedAnnualIncome && annualIncomeList) {
      setEmploymentInfo(prevState => ({
        ...prevState,
        annualIncome: annualIncomeList.find(
          incomeItem => incomeItem.code === selectedAnnualIncome
        )
          ? annualIncomeList.find(
              incomeItem => incomeItem.code === selectedAnnualIncome
            ).description
          : ""
      }));
    }
  }, [selectedAnnualIncome, annualIncomeList]);

  useEffect(() => {
    if (selectedSourceOfFund && sourceOfFundList) {
      setOtherInfo(prevState => ({
        ...prevState,
        sourceOfFunds: sourceOfFundList.find(
          fund => fund.code === selectedSourceOfFund
        )
          ? sourceOfFundList.find(fund => fund.code === selectedSourceOfFund)
              .description
          : ""
      }));
    }
  }, [selectedSourceOfFund, sourceOfFundList]);

  const handleSelectMaritalStatus = maritalStsId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: selectedBusinessNature, //R
      maritalStatusCd: maritalStsId, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2,
        line3: contactInfo.address3,
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setStatusOpen(false);
  };

  const handleSelectRace = raceId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: raceId, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: selectedBusinessNature, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2,
        line3: contactInfo.address3,
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setRaceOpen(false);
  };

  const handleSelectState = stateId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: selectedBusinessNature, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2, //R
        line3: contactInfo.address3, //R
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: stateId, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setStateOpen(false);
  };

  const handleSelectOccupation = occupationId => {
    let result = stopOccupations.includes(occupationId);
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: occupationId, //R
      natureOfBusinessCd: result ? "99" : selectedBusinessNature, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2, //R
        line3: contactInfo.address3, //R
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setOccupationOpen(false);
  };

  const handleSelectBusinessNature = businessNatureId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: businessNatureId, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2,
        line3: contactInfo.address3,
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setBusinessNatureOpen(false);
  };

  const handleSelectAnnualIncome = annualIncomeId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: selectedBusinessNature, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: selectedSourceOfFund,
      yearlyIncomeLevelCd: annualIncomeId, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2,
        line3: contactInfo.address3,
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setAnnualIncomeOpen(false);
  };

  const handleSelectSourceOfFund = sourceOfFundId => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: contactInfo.email, //R
      mobileNo: contactInfo.contactNumber, //R
      fullName: idInfo.fullName, //R
      townOfBirth: idInfo.cityOfBirth, //R
      dob: moment(idInfo.dateOfBirth).format("YYYY-MM-DD"), //R
      race: selectedRace, //R
      occupationTypeCd: selectedOccupation, //R
      natureOfBusinessCd: selectedBusinessNature, //R
      maritalStatusCd: selectedMaritalSts, //R
      sourceOfFundCd: sourceOfFundId, //R
      yearlyIncomeLevelCd: selectedAnnualIncome, //R
      investmentObjCd: selectedInvestObj,
      employerName: employmentInfo.employerName,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: otherInfo.taxId, //R
                citizenship: selectedTaxCountry //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idInfo.idNumber, //R
                citizenship: selectedCountry //R
              }
            ],
      address: {
        line1: contactInfo.address1, //R
        line2: contactInfo.address2,
        line3: contactInfo.address3,
        postCode: contactInfo.postCode, //R
        city: contactInfo.city, //R
        state: selectedState, //R,
        country: selectedCountry //R
      }
    };

    updateProfile({ payloadData: payload, from: PROFILE });
    setSourceOfFundOpen(false);
  };

  return (
    <div style={{ backgroundColor: "#f6f6f8", ...styles.column }}>
      {(loading || investmentObjectiveLoading) && <DefaultLoader />}
      {/* <Alert
        title="Account Verification"
        description="Thank you for submitting your information. Your application is currently being processed. We will notify you once we have completed the verification process."
        isOpen={pendingAlert}
        isCS="https://principal.com.my"
        isCancel={false}
        handleAction={() => setPendingAlert(false)}
        handleCancel={() => setPendingAlert(false)}
      /> */}

      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={error === "timeoutError"}
        isTimeout={true}
        handleAction={() => {
          clearError();
          history.push("/profile");
        }}
      />

      {/* Dropdown List */}
      {/* Marital Status */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={isStatusOpen}
        handleDismiss={() => setStatusOpen(false)}
      >
        <ProfileDropdown
          title="Marital status"
          itemList={maritalStatusList}
          selectedItem={selectedMaritalSts}
          handleClick={handleSelectMaritalStatus}
        />
      </Dropdown>

      {/* Race */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={isRaceOpen}
        handleDismiss={() => setRaceOpen(false)}
      >
        <ProfileDropdown
          title="Race"
          itemList={raceList}
          selectedItem={selectedRace}
          handleClick={handleSelectRace}
        />
      </Dropdown>

      {/* State */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
        isOpen={isStateOpen}
        handleDismiss={() => setStateOpen(false)}
      >
        <ProfileDropdown
          title="State"
          itemList={stateList}
          selectedItem={selectedState}
          handleClick={handleSelectState}
        />
      </Dropdown>

      {/* Occupation */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
        isOpen={isOccupationOpen}
        handleDismiss={() => setOccupationOpen(false)}
      >
        <ProfileDropdown
          title="Occupation"
          itemList={occupationList}
          selectedItem={selectedOccupation}
          handleClick={handleSelectOccupation}
        />
      </Dropdown>

      {/* Business Nature */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
        isOpen={isBusinessNatureOpen}
        handleDismiss={() => setBusinessNatureOpen(false)}
      >
        <ProfileDropdown
          title="Business nature"
          // itemList={businessNatureList.filter(item => item.code !== "99")}
          itemList={nobListV2.length > 0 ? nobListV2 : businessNatureList}
          selectedItem={selectedBusinessNature}
          handleClick={handleSelectBusinessNature}
        />
      </Dropdown>

      {/* Annual Income */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={isAnnualIncomeOpen}
        handleDismiss={() => setAnnualIncomeOpen(false)}
      >
        <ProfileDropdown
          title="Annual income"
          itemList={annualIncomeList}
          selectedItem={selectedAnnualIncome}
          handleClick={handleSelectAnnualIncome}
        />
      </Dropdown>

      {/* Source Of Funds */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={isSourceOfFundOpen}
        handleDismiss={() => setSourceOfFundOpen(false)}
      >
        <ProfileDropdown
          title="Source of funds"
          itemList={sourceOfFundList}
          selectedItem={selectedSourceOfFund}
          handleClick={handleSelectSourceOfFund}
        />
      </Dropdown>

      {/* Content */}
      <StepLabel
        title="Profile info"
        containerStyles={{ padding: "16px 16px 22px" }}
        onClick={() => history.push("/profile")}
      />
      {/* Identification Information */}
      <div style={styles.info}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, ...styles.caption }}
        >
          Identification information
        </Typography>
      </div>
      <div style={styles.idInfo}>
        <Info label="Full name" value={idInfo.fullName} />
        <Info label="ID number" value={idInfo.idNumber} />
        <Info
          label="Date of birth"
          value={moment(idInfo.dateOfBirth).format("DD/MM/YYYY")}
        />
        <Info label="Country" value={idInfo.country} isEdit={false} />
        <Info
          label="City of birth"
          value={idInfo.cityOfBirth}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit City of Birth");
            history.push("/profile/info/updateCityOfBirth");
          }}
        />
        <Info
          label="Gender"
          value={
            idInfo.gender
              ? { F: "Female", M: "Male" }[userInfo.gender] || "Unknown"
              : ""
          }
        />
        <Info
          label="Marital status"
          value={idInfo.maritalStatus}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Marital Status");
            setStatusOpen(true);
          }}
        />
        <Info
          label="Ethnicity / Race"
          value={idInfo.race}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Race");
            setRaceOpen(true);
          }}
        />
      </div>

      {/* Contact Information */}
      <div style={styles.info}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, ...styles.caption }}
        >
          Contact information
        </Typography>
      </div>
      <div style={styles.idInfo}>
        <Info
          label="Mobile number"
          value={`+${contactInfo.contactNumber.slice(
            0,
            2
          )} ${contactInfo.contactNumber.slice(
            2,
            contactInfo.contactNumber.length
          )}`}
        />
        <Info
          label="Email"
          value={contactInfo.email}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Email");
            history.push("/profile/info/updateEmail");
          }}
        />
        <Info
          label="Address"
          value={contactInfo.address1}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Address");
            history.push("/profile/info/updateAddress");
          }}
        />
        <Info
          value={contactInfo.address2}
          containerStyle={{ padding: "0 16px" }}
        />
        <Info
          value={contactInfo.address3}
          containerStyle={{ padding: "0 16px" }}
        />

        <Info
          label="City"
          value={contactInfo.city}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit City");
            history.push(`/profile/info/updateCity`);
          }}
        />
        <Info
          label="State"
          value={contactInfo.state}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit State");
            setStateOpen(true);
          }}
        />
        <Info
          label="Postal code"
          value={contactInfo.postCode}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Postal Code");
            history.push("/profile/info/updatePostCode");
          }}
        />
      </div>

      {/* Employment Information */}
      <div style={styles.info}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, ...styles.caption }}
        >
          Employment information
        </Typography>
      </div>
      <div style={styles.idInfo}>
        <Info
          label="Occupation"
          value={employmentInfo.occupation}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Occupation");
            setOccupationOpen(true);
          }}
        />
        <Info
          label="Nature of business"
          value={employmentInfo.industry}
          isEdit={nobfilter}
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Link",
              "Edit Nature of Business"
            );
            setBusinessNatureOpen(true);
          }}
        />
        <Info
          label="Annual income"
          value={employmentInfo.annualIncome}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Annual Income");
            setAnnualIncomeOpen(true);
          }}
        />
        <Info
          label="Employer name"
          value={employmentInfo.employerName}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Employer Name");
            history.push("/profile/info/updateEmployerName");
          }}
        />
      </div>

      {/* Other Information */}
      <div style={styles.info}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, ...styles.caption }}
        >
          Other information
        </Typography>
      </div>
      <div style={styles.idInfo}>
        <Info
          label="Source of funds"
          value={otherInfo.sourceOfFunds}
          isEdit={allowUpdateProfile}
          onClick={() => setSourceOfFundOpen(true)}
        />
        <Info
          label="Your purpose of investment"
          value={otherInfo.cashPurpose}
          isEdit={allowUpdateProfile}
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Link",
              "Edit Purpose of Investment"
            );
            history.push("/profile/info/updateInvestObj");
          }}
        />
        {/* <Info
          label="Do you hold a position (or related to such persons) in any Public or Political offices, Including being its committee or council member"
          value={otherInfo.isPoliticalPosition}
          isEdit={true}
          valueStyle={{ marginTop: "4px" }}
          onClick={() => history.push("/profile/info/isPolitic")}
        /> */}

        <Info
          label="Are you a tax resident of any country other than Malaysia?"
          value={otherInfo.otherTaxResident}
          isEdit={allowUpdateProfile}
          valueStyle={{ marginTop: "4px" }}
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Edit Tax Resident");
            history.push("/profile/info/taxInfo");
          }}
        />
        {otherInfo.otherTaxResident === "Yes" && (
          <>
            <Info
              label="Tax Country"
              value={otherInfo.taxCountry}
              isEdit={true}
              onClick={() => history.push("/profile/info/taxInfo")}
            />
            <Info
              label="Tax ID Number"
              value={otherInfo.taxId}
              isEdit={true}
              onClick={() => history.push("/profile/info/taxInfo")}
            />
          </>
        )}
      </div>
      <div>
        <Typography style={styles.infoFooter}>
          For any information that cannot be edited within the app, kindly
          contact our Customer Care Centre for assistance.
        </Typography>
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile,
    countryList: state.goal.countryList,
    maritalStatusList: state.goal.maritalStatusList,
    raceList: state.goal.raceList,
    occupationList: state.goal.occupationList,
    businessNatureList: state.goal.businessNatureList,
    annualIncomeList: state.goal.annualIncomeList,
    stateList: state.goal.stateList,
    sourceOfFundList: state.goal.sourceOfFundList,
    ecddStatus: state.goal.ecddStatus,
    error: state.goal.error,
    investmentObjective: state.onBoardingReducer.investmentObjective,
    investmentObjectiveLoading:
      state.onBoardingReducer.investmentObjectiveLoading,
    allowUpdateProfile: state.profileReducer.allowUpdateProfile.value
  }),
  {
    updateProfile,
    getAnnualIncome,
    getBusinessNature,
    getSourceOfFund,
    getCountry,
    getMaritalStatus,
    getOccupation,
    getRace,
    getInvestmentObjective,
    getStateList,
    hideError,
    checkEcddStatus,
    clearError
  }
);

export default compose(
  withRouter,
  withConnect
)(ProfileInfo);
