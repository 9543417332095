export const GET_ACCOUNT_STATUS_V2 = "GET_ACCOUNT_STATUS_V2";
export const GET_ACCOUNT_STATUS_V2_SUCCESS = "GET_ACCOUNT_STATUS_V2_SUCCESS";
export const GET_ACCOUNT_STATUS_V2_FAILED = "GET_ACCOUNT_STATUS_V2_FAILED";

export const GET_ACC_STS_SUCCESS = "GET_ACC_STS_SUCCESS";
export const GET_ACC_STS_FAILED = "GET_ACC_STS_FAILED";

export const GET_CUSTOMER_PORTFOLIO = "GET_CUSTOMER_PORTFOLIO";
export const GET_CUSTOMER_PORTFOLIO_SUCCESS = "GET_CUSTOMER_PORTFOLIO_SUCCESS";
export const GET_CUSTOMER_PORTFOLIO_FAILED = "GET_CUSTOMER_PORTFOLIO_FAILED";

export const GET_SUBACCOUNT_INVEST = "GET_SUBACCOUNT_INVEST";
export const GET_SUBACCOUNT_INVEST_SUCCESS = "GET_SUBACCOUNT_INVEST_SUCCESS";
export const GET_SUBACCOUNT_INVEST_FAILED = "GET_SUBACCOUNT_INVEST_FAILED";

export const RESET_INVEST_SUBACCOUNT = "RESET_INVEST_SUBACCOUNT";
