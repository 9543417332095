import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { checkEcddStatus, getCustomer } from "../redux/action";
import { PROFILE } from "../redux/constant";
import { ProfileMenu, BottomNav, Alert, DefaultLoader } from "../../components";
import styles from "./styles";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { useEcddChecking } from "../../utils/hooks";
import { setCurrentEcddStatus } from "../../globalRedux/actions";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import { getAllowProfile } from "./redux/actions";
const Profile = props => {
  const {
    loading,
    profile,
    profileInfo,
    ecddStatus,
    checkEcddStatus,
    getCustomer,
    location,
    history,
    isEcddCompleted,
    setCurrentEcddStatus,
    loadingProfileSysconfig,
    getAllowProfile,
  } = props;
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");

  const {
    handleCheckEcdd,
    status,
    onResetStatus,
    onSetStatus,
  } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
    shouldNotPromptOnEffect: !isEcddCompleted,
  });

  useEffect(() => {
    getAllowProfile();
  }, []);

  useEffect(() => {
    getCustomer();
  }, [profileInfo]);

  useEffect(() => {
    if (profile) {
      setFullName(profile.fullName);
      setEmail(profile.email);

      if (
        profile.masterId &&
        profile.amlHistory &&
        profile.amlHistory.length > 0 &&
        profile.amlHistory[0].amlSts === "3"
      ) {
        checkEcddStatus(profile.masterId);
      }
    }
  }, [profile]);

  useEffect(() => {
    if (
      profile &&
      profile.amlHistory &&
      profile.amlHistory.length > 0 &&
      profile.amlHistory[0].amlResult === "3"
    ) {
      if (!location.state) {
        return;
      }
      if ("from" in location.state) {
        if (location.state.from === PROFILE && ecddStatus.ecddSts === "1") {
          onSetStatus("pending");
          return;
        }
        if (location.state.from === PROFILE && ecddStatus.ecddSts === "3") {
          onSetStatus("rejected");
          return;
        }
      }
    }
  }, [profile, ecddStatus]);

  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf",
    });
  };

  return (
    <div
      style={{
        ...styles.column,
        flex: 1,
        minHeight: "100vh",
        backgroundColor: "#fff",
        paddingTop: "32px",
      }}
    >
      {(loading || loadingProfileSysconfig) && <DefaultLoader />}
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
        }}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <div style={{ ...styles.row, ...styles.profileDetails }}>
        <div
          style={{
            ...styles.column,
            maxWidth: "80%",
          }}
        >
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.profileName,
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {fullName ? fullName : ""}
          </Typography>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, fontSize: "14px", lineHeight: 1.57 }}
          >
            {email ? email : ""}
          </Typography>
        </div>
        <div
          onClick={handleCheckEcdd({
            cb: () => history.push("/profile/info"),
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/PROFILEINFO"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Link",
              label: "View Profile Info",
            },
          })}
        >
          <Typography style={{ ...styles.baseFont, ...styles.profileInfoLink }}>
            View info
          </Typography>
        </div>
      </div>
      <div>
        <ProfileMenu
          title="Campaign Rewards"
          onClick={() => history.push("/rewards")}
          // link="https://www.principal.com.my/en/aboutus/mv"
        />
        <ProfileMenu
          title="About Principal"
          onClick={() => dataLayerGTM("click_tag", "Click", "Link", "About Us")}
          link="https://www.principal.com.my/en/aboutus/mv"
        />
        <ProfileMenu
          title="Frequently asked questions (EN)"
          onClick={() =>
            dataLayerGTM(
              "click_tag",
              "Click",
              "Link",
              "Frequently Asked Question (EN)"
            )
          }
          link="https://www.principal.com.my/en/invest-va-tngewallet-faq"
        />
        <ProfileMenu
          title="Frequently asked questions (BM)"
          onClick={() =>
            dataLayerGTM(
              "click_tag",
              "Click",
              "Link",
              "Frequently Asked Question (BM)"
            )
          }
          link="https://www.principal.com.my/ms/invest-va-tngewallet-faq"
        />
        <ProfileMenu
          title="Terms and conditions"
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Term and Condition");
            handleOpenPDF(
              "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf"
            );
          }}
        />
        <ProfileMenu
          title="Contact us"
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Contact Us");
            history.push("/profile/contactUs");
          }}
        />
        <ProfileMenu
          title="Feedback"
          onClick={() => {
            dataLayerGTM("click_tag", "Click", "Link", "Feedback");
            history.push("/profile/feedback");
          }}
        />
      </div>
      <div>
        <Typography style={{ ...styles.baseFont, ...styles.versionNo }}>
          Version 2.0.0
        </Typography>
      </div>

      {/* Bottom Navigation */}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
        }}
      >
        <BottomNav />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    profile: state.goal.profile,
    profileInfo: state.goal.profileInfo,
    ecddStatus: state.goal.ecddStatus,
    isEcddCompleted: state.commingFromReducer.isEcddCompleted,
    loadingProfileSysconfig: state.profileReducer.loading,
  }),
  { checkEcddStatus, getCustomer, setCurrentEcddStatus, getAllowProfile }
);

export default compose(
  withRouter,
  withConnect,
  withProfileRedirect
)(Profile);
