import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "underscore";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { Typography } from "@material-ui/core";
import {
  StepLabel,
  CustomInput,
  Dropdown,
  CustomButton,
  ProfileDropdown,
  Alert,
  DefaultLoader,
  AlertInfo,
} from "../../components";
import { getStateV2, getRaceV2, setOnboardStepOne } from "./redux/action";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { validateEmail } from "../../utils/functions/validateEmail";
import { resetData } from "../redux/action";
import { withErrorBoundary } from "../../utils/functions/withErrorBoundary";
import { checkEmptyArray, checkEmptyObject } from "./utils.js";
import "./style.scss";
function ProfileFormStepOne(props) {
  const {
    classes,
    profile = {},
    getStateV2,
    stateListV2 = [],
    getRaceV2,
    raceLIstV2 = [],
    getRaceLoading,
    getStateLoading,
    setOnboardStepOne,
    history,
    resetData,
    location,
    token,
  } = props;

  const [profileInfo, setProfileInfo] = useState({});

  const {
    fullName = "",
    identities = [],
    mobileNo = "",
    contacts = [],
    race = "",
    email = "",
  } = profileInfo;

  // useEffect(() => {
  //   if (profile && profile.masterId) {
  //     if (
  //       masterIdRef.current !== null &&
  //       masterIdRef.current !== profile.masterId
  //     ) {
  //       props.resetData();
  //       localStorage.clear();
  //       history.push("/");
  //     }

  //     masterIdRef.current = profile.masterId;
  //   }
  // }, [profile]);

  const [requireInfo, setRequireInfo] = useState({});
  const [isBlank, setIsBlank] = useState(false);

  const [value, setValue] = useState({
    idNo: "",
    state: { name: "", code: "" },
    race: { name: "", code: "" },
    email: "",
  });

  const [fieldError, setFieldError] = useState({
    email:
      location.state &&
      location.state.hasOwnProperty("invalidEmail") &&
      location.state.invalidEmail
        ? true
        : false,
    state: false,
    race: false,
  });

  const [invalidEmailPrompt, setInvalidEmailPrompt] = useState(
    fieldError.email
  );

  const emailRef = useRef(null);

  const [openDropdown, setOpenDropdown] = useState({
    state: false,
    race: false,
  });

  useEffect(() => {
    getStateV2();
    getRaceV2();
  }, []);

  const handleSelectRace = id => {
    let tempRace = { name: "", code: "" };
    const raceItem = raceLIstV2.find(item => item.code === id);
    if (raceItem) {
      tempRace = { name: raceItem.name, code: raceItem.code };
    } else {
      setOpenDropdown({ ...openDropdown, race: false });

      return;
    }

    setValue({ ...value, race: tempRace });
    setOpenDropdown({ ...openDropdown, race: false });
    setFieldError({ ...fieldError, race: false });
  };

  const handleSelectState = id => {
    let tempState = { name: "", code: "" };
    const stateItem = stateListV2.find(item => item.code === id);
    if (stateItem) {
      tempState = { name: stateItem.name, code: stateItem.code };
    } else {
      setOpenDropdown({ ...openDropdown, state: false });

      return;
    }

    setValue({ ...value, state: tempState });
    setOpenDropdown({ ...openDropdown, state: false });
    setFieldError({ ...fieldError, state: false });
  };

  const handleChangeEmail = val => {
    setValue({ ...value, email: val });
  };

  const findIdNo = () => {
    let tempidNo = "";
    let tempValue = [];
    if (Array.isArray(identities)) {
      tempValue = identities.filter(item => item.idTypeCd !== "TAX");
    }
    if (tempValue.length > 0 && "idNo" in tempValue[0]) {
      tempidNo = tempValue[0].idNo;
    }
    return tempidNo;
  };

  const findState = () => {
    let tempState = { name: "", code: "" };
    let tempContact = [];

    if (Array.isArray(contacts)) {
      tempContact = contacts.filter(item => item.contactTypeCd === "PERMANENT");
    }

    if (
      tempContact.length > 0 &&
      "state" in tempContact[0] &&
      tempContact[0].state !== null
    ) {
      if (tempContact[0].state === undefined) return tempState;
      tempState.code = tempContact[0].state;
    } else {
      return tempState;
    }

    let newStateCode = stateListV2.find(item => item.code === tempState.code);
    if (!newStateCode) return { name: "", code: "" };
    if (newStateCode) {
      if ("name" in newStateCode && "code" in newStateCode) {
        return { name: newStateCode.name, code: newStateCode.code };
      }
    }

    return tempState;
  };

  const findRace = () => {
    let tempRace = { name: "", code: "" };
    let newItem = [];
    if (Array.isArray(raceLIstV2)) {
      newItem = raceLIstV2.filter(item => item.code === race);
    } else {
      return tempRace;
    }

    if (newItem.length > 0) {
      if ("name" in newItem[0] && "code" in newItem[0]) {
        tempRace = { name: newItem[0].name, code: newItem[0].code };
      }
    }
    return tempRace;
  };

  const findEmail = () => {
    let tempEmail = "";
    if (email === null || !email) {
      return tempEmail;
    }
    tempEmail = email;
    return tempEmail;
  };

  useEffect(() => {
    if (
      typeof profile === "object" &&
      !Array.isArray(profile) &&
      profile !== null
    ) {
      setProfileInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    let checkProfileForm = checkEmptyObject(profileInfo);
    let checkStatelist = checkEmptyArray(stateListV2);
    let checkRaceList = checkEmptyArray(raceLIstV2);
    if (!checkProfileForm || !checkRaceList || !checkStatelist) {
      return;
    }

    let tempIdNo = findIdNo();
    let tempState = findState();
    let tempRace = findRace();
    let tempEmail = findEmail();
    setValue({
      idNo: tempIdNo,
      state: tempState,
      race: tempRace,
      email: tempEmail,
      mobileNo,
    });
  }, [profileInfo, stateListV2, raceLIstV2]);

  const handleValidateEmail = value => {
    const validation = validateEmail(value);

    if (validation) {
      setFieldError({ ...fieldError, email: false });
    } else {
      setFieldError({ ...fieldError, email: true });
    }
  };

  const validationValue = () => {
    let tempError = {
      state: false,
      race: false,
      email: false,
    };
    let requireField = {
      state: value.state.code,
      race: value.race.code,
      email: value.email,
    };

    for (const key in requireField) {
      if (requireField[key].length === 0) {
        tempError[key] = true;
      }
    }

    setFieldError(prevState => ({
      ...prevState,
      state: tempError.state,
      race: tempError.race,
      email: tempError.email,
    }));
    let requireInfoTemp = {};
    if (tempError.state) {
      requireInfoTemp.state = "State";
    }
    if (tempError.race) {
      requireInfoTemp.race = "Race";
    }

    if (tempError.email) {
      requireInfoTemp.email = "Email";
    }
    setRequireInfo(requireInfoTemp);
    for (const key in tempError) {
      if (tempError[key] === true) {
        return false;
      }
    }

    return true;
  };

  const submitStepOne = () => {
    let validation = validationValue();

    if (!validation) {
      setIsBlank(true);

      return;
    }
    const validationEmail = validateEmail(value.email);
    if (!validationEmail) {
      setFieldError({ ...fieldError, email: true });
      return;
    }
    setOnboardStepOne(value);
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      `Click Onboarding Submit Step 1`
    );

    if (location.state) {
      history.push("/onBoarding/form-step-two", location.state);
    } else {
      history.push("/onBoarding/form-step-two");
    }

    return;
  };

  if (getRaceLoading || getStateLoading) return <DefaultLoader />;

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Alert
        title="Please fill in required fields"
        isObject={requireInfo}
        isOpen={isBlank}
        isCancel={false}
        handleAction={() => setIsBlank(false)}
      />
      <StepLabel
        title="Profile info"
        containerStyles={{ padding: "16px 16px 22px" }}
        onClick={() => {
          resetData();
          localStorage.removeItem("mpUserInfo");
          history.push("/");
        }}
      />
      <AlertInfo
        handleAction={() => {
          setInvalidEmailPrompt(false);
          emailRef?.current?.scrollIntoView({ behavior: "smooth" });
        }}
        description="Please enter your email address to continue."
        isOpen={invalidEmailPrompt}
      />

      <div className={classes.fieldGroupStepOB}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFontStepOB, fontWeight: "700" }}
        >
          Step 1 of 2
        </Typography>
      </div>

      <div className={classes.fieldGroup}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, fontWeight: "700" }}
        >
          Identification & contact information
        </Typography>
      </div>

      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Full name"
          name="fullName"
          value={fullName}
          isEdit={false}
          isRequired={true}
        />
        <CustomInput
          label="ID Number"
          name="idNumber"
          value={value.idNo}
          isEdit={false}
          isRequired={true}
        />
        <CustomInput
          label="Contact number"
          name="contactNumber"
          value={mobileNo.length > 8 ? mobileNo.slice(2) : mobileNo}
          countryCode={
            mobileNo.length > 8 ? mobileNo.substring(0, 2) : undefined
          }
          isEdit={false}
          isRequired={true}
          isPhone={true}
        />
        <CustomInput
          label="State"
          name="state"
          value={value.state.name}
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          onClick={() => setOpenDropdown({ ...openDropdown, state: true })}
          error={fieldError.state}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
          isOpen={openDropdown.state}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, state: false })
          }
        >
          <ProfileDropdown
            title="State"
            itemList={stateListV2}
            selectedItem={value.state.code}
            handleClick={handleSelectState}
          />
        </Dropdown>
        <CustomInput
          label="Ethnicity / Race"
          name="race"
          value={value.race.name}
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          onClick={() => setOpenDropdown({ ...openDropdown, race: true })}
          error={fieldError.race}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight * 0.4}
          isOpen={openDropdown.race}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, race: false })
          }
        >
          <ProfileDropdown
            title="Race"
            itemList={raceLIstV2}
            selectedItem={value.race.code}
            handleClick={handleSelectRace}
          />
        </Dropdown>
        <CustomInput
          label="Email"
          name="email"
          placeholder="user@example.com"
          type="email"
          value={value.email}
          onChange={e => handleChangeEmail(e.target.value)}
          handleBlur={() => handleValidateEmail(value.email)}
          error={fieldError.email}
          isEdit={true}
          isRequired={true}
          maxLength={60}
          inputRef={emailRef}
          helperText={
            value.email.length > 0
              ? "Invalid email format"
              : "This field is required"
          }
        />
      </div>
      <div className="button-container-onboard">
        <button onClick={submitStepOne} className="button-onboard-step1">
          Next
        </button>
      </div>
      {/* <div className={classes.btnStep1}> */}
      {/* <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          onClick={submitStepOne}
        /> */}
      {/* </div> */}
    </div>
  );
}
const mapStateToProps = state => {
  return { ...state.onBoardingReducer, profile: state.goal.profile };
};
const mapDispatchToProps = dispatch => ({
  getStateV2: () => dispatch(getStateV2()),
  getRaceV2: () => dispatch(getRaceV2()),
  resetData: () => dispatch(resetData()),
  setOnboardStepOne: payload => dispatch(setOnboardStepOne(payload)),
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withStyles(styles),
  withErrorBoundary,
  withRouter,
  withConnect
)(ProfileFormStepOne);
