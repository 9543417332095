import { images } from "../../constants";

const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  background: {
    backgroundImage: `url(${images.bgSavingDetails})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    alignItems: "center"
  },
  netWorth: {
    fontSize: "14px",
    lineHeight: 1.57,
    opacity: 0.7,
    marginTop: "14px",
    color: "#fff"
  },
  lastUpdate: {
    fontSize: "14px",
    lineHeight: "22px",
    opacity: 0.7,
    marginTop: "14px",
    color: "#fff",
    marginBottom: "3.5rem"
  },
  menu: {
    justifyContent: "space-around",
    backgroundColor: "#fff",
    margin: "0 16px",
    padding: "8px 16px",
    transform: "translateY(-50%)",
    borderRadius: "4px"
  },
  menuLabel: {
    fontSize: "14px",
    lineHeight: 1.43
  },
  verticalLine: {
    width: "1px",
    height: "48px",
    transform: "rotate(180deg)",
    backgroundColor: "#e7e7e7"
  }
};

export default styles;
