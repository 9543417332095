export function getSelectedPromoByCode(promoList) {
  return function(code) {
    let matchingPromoCode;

    for (let i = 0; i < promoList.length; i++) {
      const promo = promoList[i];

      for (let j = 0; j < promo.eligibilityRules.codes.length; j++) {
        if (
          promo.eligibilityRules.codes[j].code.toLowerCase() ===
          code.toLowerCase()
        ) {
          matchingPromoCode = [
            {
              ...promo,
              selectedReferralCode: promo.eligibilityRules.codes[j].code,
            },
          ];
        }
      }
    }

    return matchingPromoCode || [];
  };
}
