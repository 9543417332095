const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  btn: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "16px",
    display: "inline-block",
    marginTop: "auto",
    backgroundColor: "#fff"
  }
};

export default styles;
