import ReactGA from "react-ga";

export const dataLayerGTM = (event, action, category, label) => {
  window.dataLayer.push({
    event: event,
    action: action,
    category: category,
    label: label,
  });

  window.dataLayer.push({
    action: undefined,
    category: undefined,
    label: undefined,
  });
};

// export const dataLayerPageView = (path, title, reset) => {
// const virtualPagePath = path;
// const virtualPageTitle = title;
// const virtualPageUrl = window.location.origin + virtualPagePath;
// const virtualPageViewEvent = {
//   event: "virtualPageView",
//   virtualPagePath,
//   virtualPageTitle,
//   virtualPageUrl
// };
// window.history.pushState(
//   virtualPageViewEvent,
//   virtualPageTitle,
//   virtualPageUrl
// );
// window.dataLayer.push(virtualPageViewEvent);
// window.history.pushState("dashboard", "", reset);
//   ReactGA.pageview(`${path}` + window.location.search);
// };
