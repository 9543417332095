import {
  GET_ACCOUNT_STATUS_V2,
  GET_ACCOUNT_STATUS_V2_SUCCESS,
  GET_ACCOUNT_STATUS_V2_FAILED,
  GET_ACC_STS_SUCCESS,
  GET_ACC_STS_FAILED,
  GET_CUSTOMER_PORTFOLIO,
  GET_CUSTOMER_PORTFOLIO_SUCCESS,
  GET_CUSTOMER_PORTFOLIO_FAILED,
  GET_SUBACCOUNT_INVEST,
  GET_SUBACCOUNT_INVEST_SUCCESS,
  GET_SUBACCOUNT_INVEST_FAILED,
  RESET_INVEST_SUBACCOUNT
} from "./constant";

export const resetInvestSubAccount = () => {
  return {
    type: RESET_INVEST_SUBACCOUNT
  };
};

export function getSubAccountInvest(data, callback) {
  return {
    type: GET_SUBACCOUNT_INVEST,
    payload: data,
    callback
  };
}

export function getSubAccountInvestSuccess(data) {
  return {
    type: GET_SUBACCOUNT_INVEST_SUCCESS,
    payload: data
  };
}

export function getSubAccountInvestFailed(data) {
  return {
    type: GET_SUBACCOUNT_INVEST_FAILED,
    payload: data
  };
}

export function getCustomerPortfolio(callback) {
  return {
    type: GET_CUSTOMER_PORTFOLIO,
    callback
  };
}

export function getCustomerPortfolioSuccess(data) {
  return {
    type: GET_CUSTOMER_PORTFOLIO_SUCCESS,
    payload: data
  };
}

export function getCustomerPortfolioFailed() {
  return {
    type: GET_CUSTOMER_PORTFOLIO_FAILED
  };
}

export function getAccountStatusV2(data, callback) {
  return {
    type: GET_ACCOUNT_STATUS_V2,
    payload: data,
    callback
  };
}

export function getAccountStatusV2Success(data) {
  return {
    type: GET_ACCOUNT_STATUS_V2_SUCCESS,
    payload: data
  };
}

export function getAccountStatusV2Failed(data) {
  return {
    type: GET_ACCOUNT_STATUS_V2_FAILED,
    payload: data
  };
}

export function getAccStsSuccess(data) {
  return {
    type: GET_ACC_STS_SUCCESS,
    payload: data
  };
}

export function getAccStsFailed(data) {
  return {
    type: GET_ACC_STS_FAILED,
    payload: data
  };
}
