import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography
} from "@material-ui/core";

const InfoModal = ({ open, handleClose, message, title }) => {
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        {title && (
          <Typography
            style={{
              fontFamily: "FSElliotPro",
              fontWeight: "900",
              fontSize: "20px",
              marginBottom: "1rem",
              lineHeight: "28px",
              color: "#333333"
            }}
          >
            {title}
          </Typography>
        )}
        <DialogContentText
          style={{
            fontFamily: "FSElliotPro",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: 1.5
          }}
          color="textPrimary"
        >
          {message}
          {/* Connection Error.
          <br />
          Please try again later. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          style={{
            fontFamily: "FSElliotPro",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: 1.5
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoModal;
