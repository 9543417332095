import React, { useState, useEffect } from "react";
import {
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { PROFILE } from "../../redux/constant";
import { updateProfile } from "../../redux/action";

import { StepLabel, CustomButton, DefaultLoader } from "../../../components";
import styles from "../styles";

const UpdateInvestmentObj = props => {
  const {
    loading,
    profile,
    updateProfile,
    history,
    classes,
    investmentObjective
  } = props;

  const [userInfo, setUserInfo] = useState(null);
  const [investObj, setInvestObj] = useState("");
  const [isTax, setIsTax] = useState(false);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setInvestObj(userInfo.investmentObjCd ? userInfo.investmentObjCd : "");
      setIsTax(
        userInfo.identities.length > 0 &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? true
          : false
      );
    }
  }, [userInfo]);

  const handleUpdate = () => {
    const payload = {
      masterId: userInfo.masterId, //R
      email: userInfo.email, //R
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: investObj,
      employerName: userInfo.employerName,
      identities:
        isTax === true
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).citizenship //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              }
            ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R the updated
        city: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship //R
      }
    };

    // window.consoleLog(payload);
    updateProfile({ payloadData: payload, from: PROFILE });
  };

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loading && <DefaultLoader />}

      <StepLabel
        title="Update purpose of investment"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            className={`${classes.baseFont} ${classes.inputLabel}`}
          >
            Purpose of investment
          </Typography>
          <RadioGroup
            name="investObj"
            value={investObj}
            onChange={e => setInvestObj(e.target.value)}
            className={classes.row}
          >
            {investmentObjective
              .sort((a, b) => a.code - b.code)
              .map(({ code, name }) => (
                <FormControlLabel
                  value={code}
                  control={<Radio color="primary" />}
                  label={name}
                  key={code}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={investObj ? false : true}
          onClick={handleUpdate}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loading: state.goal.loading,
    isModalOpen: state.goal.isModalOpen,
    errorMessage: state.goal.errorMessage,
    profile: state.goal.profile,
    investmentObjective: state.onBoardingReducer.investmentObjective
  }),
  { updateProfile }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(UpdateInvestmentObj);
