import React from "react";
import { Typography } from "@material-ui/core";
import moment from "moment";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import styles from "./styles";

const NewsCard = ({ image, title, url, date }) => {
  return (
    <div
      onClick={() => {
        dataLayerGTM("click_tag", "Click", "Link", `${title}`);
        window.my.navigateTo({
          url: "/pages/link/link?url=" + encodeURIComponent(url),
        });
      }}
      style={{ ...styles.column, ...styles.card }}
    >
      <img src={image} alt="postImage" style={styles.image} />
      <div style={{ margin: "16px" }}>
        <Typography style={{ ...styles.baseFont, ...styles.title }}>
          {title}
        </Typography>
        <Typography style={{ ...styles.baseFont, ...styles.time }}>
          {moment(date).fromNow()}
        </Typography>
      </div>
    </div>
  );
};

export default NewsCard;
