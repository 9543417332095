import {
  CREATE_GOAL_DL,
  CREATE_GOAL_DL_SUCCESS,
  CREATE_GOAL_DL_FAILED,
  GET_CGOAL_DL,
  GET_CGOAL_DL_SUCCESS,
  GET_CGOAL_DL_FAILED,
  SET_DL_AMOUNT,
  DL_CHECKOUT,
  DL_CHECKOUT_SUCCESS,
  DL_CHECKOUT_FAILED,
  CLEAR_DL_CHECKOUT_ERROR,
} from "./constants";

export const clearDLCheckoutErr = () => {
  return {
    type: CLEAR_DL_CHECKOUT_ERROR,
  };
};

export const deeplinkCheckout = payload => {
  return {
    type: DL_CHECKOUT,
    payload,
  };
};

export const deeplinkCheckoutSuccess = payload => {
  return {
    type: DL_CHECKOUT_SUCCESS,
    payload,
  };
};

export const deeplinkCheckoutFailed = () => {
  return {
    type: DL_CHECKOUT_FAILED,
  };
};

export const createGoalDL = (payload, callback) => {
  return {
    type: CREATE_GOAL_DL,
    payload,
    callback,
  };
};

export const createGoalDLSuccess = payload => {
  return {
    type: CREATE_GOAL_DL_SUCCESS,
    payload,
  };
};

export const createGoalDLFailed = () => {
  return {
    type: CREATE_GOAL_DL_FAILED,
  };
};

export const getcGoalDL = payload => {
  return {
    type: GET_CGOAL_DL,
    payload,
  };
};

export const getcGoalDLSuccess = payload => {
  return {
    type: GET_CGOAL_DL_SUCCESS,
    payload,
  };
};

export const getcGoalDLFailed = () => {
  return {
    type: GET_CGOAL_DL_FAILED,
  };
};

export const setDeeplinkAmount = payload => {
  return {
    type: SET_DL_AMOUNT,
    payload,
  };
};
