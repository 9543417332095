const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  baseFontStepOB: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  fieldGroup: { margin: "0 16px 16px" },
  fieldGroupStepOB: { margin: "0px 16px 4px 16px" },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxShadow: "0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    padding: "32px 24px 38px",
    margin: "0 12px",
    borderRadius: 4,
    transform: "translateY(-100%)"
  },
  title: { fontSize: "20px", fontWeight: "700", lineHeight: 1.4 },
  details: { color: "#616367", margin: "16px 0 36px" },
  arrowBtn: {
    backgroundColor: "#0091DA",
    width: "48px",
    height: "48px",
    position: "fixed",
    right: 0,
    marginRight: "40px"
  },
  dropdownTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4
  },
  alertTitle: {
    fontSize: "20px",
    fontWeight: "900",
    lineHeight: 1.4
  },
  label: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: 1.57,
    marginBottom: "8px"
  },
  prefix: {
    fontWeight: "700"
  },
  row: {
    display: "flex",
    flexDirection: "row"
  },
  radio: {
    marginBottom: "14px"
  },
  btn: {
    marginTop: "auto",
    padding: "16px",
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff",
    boxShadow: "0 -4px 8px 0 rgba(0, 0, 0, 0.1)"
  },
  btnStep1: {
    marginTop: "auto",
    padding: "16px",
    position: "sticky",
    bottom: 0,
    width: "100%",
    backgroundColor: "#fff"
  },
  termCondition: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "24px 0"
  }
};

export default styles;
