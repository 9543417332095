import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  getSourceOfIncome,
  getSupportingDocs,
  ecddRequest,
  getOccupation,
  getBusinessNature,
  getSourceOfFund,
  resetData,
} from "../redux/action";
import { ONBOARDING, PROFILE, DASHBOARD } from "../redux/constant";
import {
  getSourceOfWealth,
  getEstimatedNetWorth,
  getNumberOfTransaction,
  getVolumeOfTransaction,
} from "./redux/action";
import { setStep } from "../../globalRedux/actions";

import {
  StepLabel,
  CustomInput,
  CustomButton,
  Dropdown,
  ProfileDropdown,
  DefaultLoader,
} from "../../components";
import styles from "./styles";

function ECDD(props) {
  const {
    loading,
    ecddRequestLoading,
    profile,
    sourceOfIncomeList,
    supportingDocs,
    getSourceOfIncome,
    getSupportingDocs,
    getBusinessNature,
    getOccupation,
    getSourceOfFund,
    getSourceOfWealth,
    getEstimatedNetWorth,
    getNumberOfTransaction,
    getVolumeOfTransaction,
    ecddRequest,
    businessNatureList,
    occupationList,
    sourceOfFundList,
    resetData,
    history,
    match: { params },
    classes,
    sourceOfWealthList,
    estimatedNetWorthList,
    volumeOfTransactionList,
    numberOfTransactionList,
    setStep,
  } = props;

  const from = params.from;

  const occupationWhiteList = ["23", "62", "65", "90", "91"];

  const [isOccupationWhiteListed, setIsOccupationWhiteListed] = useState(false);
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [selectedBusinessNature, setSelectedBusinessNature] = useState("");
  const [selectedSourceOfFund, setSelectedSourceOfFund] = useState("");

  const [dropdownOpen, setDropdownOpen] = useState({
    supportingDocs: false,
    sourceOfIncome: false,
    sourceOfWealth: false,
    estimatedNetWorth: false,
    numberOfTransaction: false,
    volumeOfTransaction: false,
  });

  const [selectedDropdownValue, setSelectedDropdownValue] = useState({
    supportingDocs: {
      name: "",
      code: "",
    },
    sourceOfIncome: {
      name: "",
      code: "",
    },
    sourceOfWealth: {
      name: "",
      code: "",
    },
    estimatedNetWorth: {
      name: "",
      code: "",
    },
    numberOfTransaction: {
      name: "",
      code: "",
    },
    volumeOfTransaction: {
      name: "",
      code: "",
    },
  });

  const [employmentInfo, setEmploymentInfo] = useState({
    employerName: "",
    employerAddress1: "",
    employerAddress2: "",
    employerAddress3: "",
    sourceIncome: "",
    business: "",
    occupation: "",
    sourceOfFund: "",
  });

  const [otherInfo, setOtherInfo] = useState({
    supportingDocs: "",
    sourceOfWealth: "",
    estimatedNetWorth: "",
    numberOfTransaction: "",
    volumeOfTransaction: "",
  });

  const [fieldError, setFieldError] = useState({
    name: false,
    address: false,
    sourceOfIncome: false,
    numberOfTransaction: false,
    volumeOfTransaction: false,
    sourceOfWealth: false,
    estimatedNetWorth: false,
    supportingDocuments: false,
  });

  const masterIdRef = useRef(null);

  useEffect(() => {
    if (profile && profile.masterId) {
      if (
        masterIdRef.current !== null &&
        masterIdRef.current !== profile.masterId
      ) {
        resetData();
        localStorage.clear();
        history.push("/");
      }

      masterIdRef.current = profile.masterId;
    }
  }, [profile]);

  useEffect(() => {
    getSourceOfIncome();
    getSupportingDocs();
    getBusinessNature();
    getOccupation();
    getSourceOfWealth();
    getEstimatedNetWorth();
    getNumberOfTransaction();
    getVolumeOfTransaction();
    if (from === PROFILE) {
      setStep(`/onBoarding/ecdd/${PROFILE}`);
    }
  }, []);

  useEffect(() => {
    if (profile) {
      setEmploymentInfo(prevState => ({
        ...prevState,
        employerName: profile.employerName === "-" ? "" : profile.employerName,
      }));
    }
  }, [profile]);

  useEffect(() => {
    if (occupationWhiteList.includes(profile.occupationTypeCd)) {
      setIsOccupationWhiteListed(true);
      setEmploymentInfo(prevState => {
        return {
          ...prevState,
          employerName: "",
          employerAddress1: "",
          employerAddress2: "",
          employerAddress3: "",
        };
      });
    } else {
      setIsOccupationWhiteListed(false);
    }
  }, [profile]);

  useEffect(() => {
    if (profile && businessNatureList) {
      const nobCd =
        profile.natureOfBusinessCd.length === 1
          ? `0${profile.natureOfBusinessCd}`
          : profile.natureOfBusinessCd;
      let business = businessNatureList.find(
        businessNtr => businessNtr.code === nobCd
      );

      if (business) {
        setSelectedBusinessNature(business.code);
        setEmploymentInfo(prevState => ({
          ...prevState,
          business: business.name,
        }));
      }
    }
  }, [profile, businessNatureList]);

  useEffect(() => {
    if (profile && occupationList) {
      let occupation = occupationList.find(
        occ => occ.code === profile.occupationTypeCd
      );

      if (occupation) {
        setSelectedOccupation(occupation.code);
        setEmploymentInfo(prevState => ({
          ...prevState,
          occupation: occupation.name,
        }));
      }
    }
  }, [profile, occupationList]);

  useEffect(() => {
    if (profile && sourceOfFundList) {
      let sourceOfFund = sourceOfFundList.find(
        fund => fund.code === profile.sourceOfFundCd
      );

      if (sourceOfFund) {
        setSelectedSourceOfFund(sourceOfFund.code);
        setEmploymentInfo(prevState => ({
          ...prevState,
          sourceOfFund: sourceOfFund.description,
        }));
      }
    }
  }, [profile, sourceOfFundList]);

  const setAddressLine = (line, value) => {
    let userInputAddress = value.target.value;
    let sanitizedValue = userInputAddress.replace(
      /[^a-zA-Z0-9\x20\,\.\-\/\&\#\!\@\$\%\^\*\~\+]/g,
      ""
    );
    setEmploymentInfo({
      ...employmentInfo,
      [line]: sanitizedValue,
    });
  };

  const handleDropdown = name => () => {
    setDropdownOpen(prev =>
      Object.entries(prev).reduce((acc, [key, value]) => {
        acc[key] = false;
        if (key === name) {
          acc[name] = !value;
        }
        return acc;
      }, {})
    );
  };

  const handleSelectedDropdownValue = name => ({ code, text }) => {
    // keep prev value and assign new value to * name (name based on argument that gets passed)
    setSelectedDropdownValue(prev => ({
      ...prev,
      [name]: {
        code,
        name: text,
      },
    }));

    // eslint-disable-next-line default-case
    switch (name) {
      case "sourceOfIncome":
        const incomeSource = sourceOfIncomeList.find(
          item => item.code === code
        );
        setEmploymentInfo({
          ...employmentInfo,
          sourceIncome: incomeSource.name,
        });
        setFieldError(prevState => ({
          ...prevState,
          sourceOfIncome: incomeSource.name ? false : true,
        }));
        break;
      case "numberOfTransaction":
        const numberOfTransaction = numberOfTransactionList.find(
          item => item.code === code
        );
        setOtherInfo(prevState => ({
          ...prevState,
          numberOfTransaction: numberOfTransaction.name,
        }));
        setFieldError(prevState => ({
          ...prevState,
          numberOfTransaction: numberOfTransaction.name ? false : true,
        }));
        break;
      case "volumeOfTransaction":
        const volumeOfTransaction = volumeOfTransactionList.find(
          item => item.code === code
        );
        setOtherInfo(prevState => ({
          ...prevState,
          volumeOfTransaction: volumeOfTransaction.name,
        }));
        setFieldError(prevState => ({
          ...prevState,
          volumeOfTransaction: volumeOfTransaction.name ? false : true,
        }));
        break;
      case "sourceOfWealth":
        const sourceOfWealth = sourceOfWealthList.find(
          item => item.code === code
        );
        setOtherInfo(prevState => ({
          ...prevState,
          sourceOfWealth: sourceOfWealth.name,
        }));
        setFieldError(prevState => ({
          ...prevState,
          sourceOfWealth: sourceOfWealth.name ? false : true,
        }));
        break;
      case "estimatedNetWorth":
        const estimatedNetWorth = estimatedNetWorthList.find(
          item => item.code === code
        );

        setOtherInfo(prevState => ({
          ...prevState,
          estimatedNetWorth: estimatedNetWorth.name,
        }));
        setFieldError(prevState => ({
          ...prevState,
          estimatedNetWorth: estimatedNetWorth.name ? false : true,
        }));
        break;
      case "supportingDocs":
        const supportingDoc = supportingDocs.find(item => item.code === code);
        setOtherInfo({ ...otherInfo, supportingDocs: supportingDoc.name });
        setFieldError(prevState => ({
          ...prevState,
          supportingDocuments: supportingDoc.name ? false : true,
        }));
        break;
    }
    handleDropdown(name)();
  };

  const validateRequiredField = () => {
    const { employerAddress1, employerName, sourceIncome } = employmentInfo;
    const {
      numberOfTransaction,
      volumeOfTransaction,
      estimatedNetWorth,
      sourceOfWealth,
      supportingDocs,
    } = otherInfo;

    const payload = isOccupationWhiteListed
      ? {
          sourceOfIncome: sourceIncome,
          supportingDocuments: supportingDocs,
          numberOfTransaction,
          volumeOfTransaction,
          sourceOfWealth,
          estimatedNetWorth,
        }
      : {
          name: employerName,
          address: employerAddress1,
          sourceOfIncome: sourceIncome,
          supportingDocuments: supportingDocs,
          numberOfTransaction,
          volumeOfTransaction,
          sourceOfWealth,
          estimatedNetWorth,
        };

    const isEmpty = Object.values(payload).some(x => !x);

    setFieldError(
      Object.keys(payload).reduce((acc, key) => {
        if (payload[key].length <= 0) {
          acc[key] = true;
        } else {
          acc[key] = false;
        }
        return acc;
      }, {})
    );

    return !isEmpty;
  };

  const handleSubmit = e => {
    e.preventDefault();

    const validation = validateRequiredField();

    if (!validation) {
      return;
    }

    const {
      estimatedNetWorth,
      numberOfTransaction,
      sourceOfWealth,
      supportingDocs,
      volumeOfTransaction,
    } = otherInfo;
    const {
      employerAddress1,
      employerAddress2,
      employerAddress3,
      employerName,
      sourceIncome,
      sourceOfFund,
      occupation,
      business,
    } = employmentInfo;

    const payload = {
      cifId: profile.masterId,
      natureOfBusiness: business,
      name: employerName,
      address: {
        line1: employerAddress1,
        line2: employerAddress2,
        line3: employerAddress3,
      },
      sourceOfIncome: sourceIncome,
      supportingDocuments: supportingDocs,
      estimateNetWorth: estimatedNetWorth,
      occupation,
      sourceOfFund,
      numberOfTransaction,
      volumeOfTransaction,
      sourceOfWealth,
    };

    // setOpenModal(true);
    ecddRequest({
      payloadData: payload,
      from: from,
      deeplinkParams: history?.location?.state,
    });
    // checkEcddStatus(profile.masterId);
    // history.push("/");
  };
  const handleBack = () => {
    if (from === ONBOARDING) {
      history.push("/onBoarding/form-step-one");
    } else if (from === PROFILE) {
      setStep("/");
      history.push("/profile/info");
    } else if (from === DASHBOARD) {
      history.push("/dashboard");
    } else {
      history.back();
    }
  };

  if (loading || ecddRequestLoading) return <DefaultLoader />;

  return (
    <div style={{ backgroundColor: "#fff" }}>
      {/* Source of Income */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={dropdownOpen.sourceOfIncome}
        handleDismiss={handleDropdown("sourceOfIncome")}
      >
        <ProfileDropdown
          title="Source of Income"
          itemList={sourceOfIncomeList}
          selectedItem={selectedDropdownValue.sourceOfIncome}
          handleClick={handleSelectedDropdownValue("sourceOfIncome")}
          isEcdd
        />
      </Dropdown>

      {/* Number of Transaction */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={dropdownOpen.numberOfTransaction}
        handleDismiss={handleDropdown("numberOfTransaction")}
      >
        <ProfileDropdown
          title="Number of Transaction"
          itemList={numberOfTransactionList}
          selectedItem={selectedDropdownValue.numberOfTransaction}
          handleClick={handleSelectedDropdownValue("numberOfTransaction")}
          isEcdd
        />
      </Dropdown>

      {/* Volume of Transaction */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={dropdownOpen.volumeOfTransaction}
        handleDismiss={handleDropdown("volumeOfTransaction")}
      >
        <ProfileDropdown
          title="Volume of Transaction"
          itemList={volumeOfTransactionList}
          selectedItem={selectedDropdownValue.volumeOfTransaction}
          handleClick={handleSelectedDropdownValue("volumeOfTransaction")}
          isEcdd
        />
      </Dropdown>

      {/* Source of Wealth */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={dropdownOpen.sourceOfWealth}
        handleDismiss={handleDropdown("sourceOfWealth")}
      >
        <ProfileDropdown
          title="Source of Wealth"
          itemList={sourceOfWealthList}
          selectedItem={selectedDropdownValue.sourceOfWealth}
          handleClick={handleSelectedDropdownValue("sourceOfWealth")}
          isEcdd
        />
      </Dropdown>

      {/* Estimated Net Worth */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.4}
        isOpen={dropdownOpen.estimatedNetWorth}
        handleDismiss={handleDropdown("estimatedNetWorth")}
      >
        <ProfileDropdown
          title="Estimated Net Worth"
          itemList={estimatedNetWorthList}
          selectedItem={selectedDropdownValue.estimatedNetWorth}
          handleClick={handleSelectedDropdownValue("estimatedNetWorth")}
          isEcdd
        />
      </Dropdown>

      {/* Supporting Docs */}
      <Dropdown
        bottomHeight={({ maxHeight }) => maxHeight * 0.6}
        isOpen={dropdownOpen.supportingDocs}
        handleDismiss={handleDropdown("supportingDocs")}
      >
        <ProfileDropdown
          title="Supporting Documents"
          itemList={supportingDocs ? supportingDocs : []}
          selectedItem={selectedDropdownValue.supportingDocs}
          handleClick={handleSelectedDropdownValue("supportingDocs")}
          isEcdd
        />
      </Dropdown>

      <StepLabel
        title="Profile Info"
        containerStyles={{ padding: "16px 16px 22px" }}
        onClick={handleBack}
      />

      {/* Employment Information */}
      <div className={classes.fieldGroup}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, fontWeight: "700" }}
          className={classes.baseFont}
        >
          Employment Information
        </Typography>
      </div>
      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Employer Name"
          name="employerName"
          isEdit={!isOccupationWhiteListed}
          isRequired={!isOccupationWhiteListed}
          value={employmentInfo.employerName}
          error={fieldError.name}
          maxLength={100}
          onChange={e => {
            if (/^[a-zA-Z. ]+$/.test(e.target.value) || e.target.value === "") {
              setEmploymentInfo({
                ...employmentInfo,
                employerName: e.target.value,
              });
            }
          }}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              name: isOccupationWhiteListed
                ? false
                : employmentInfo.employerName.length > 0
                ? false
                : true,
            }));
          }}
        />
        <CustomInput
          label="Employer Address"
          name="employerAddress1"
          placeholder={`Line 1 ${!isOccupationWhiteListed ? "(Required)" : ""}`}
          isEdit={!isOccupationWhiteListed}
          isRequired={!isOccupationWhiteListed}
          value={employmentInfo.employerAddress1}
          error={fieldError.address}
          maxLength={40}
          onChange={userAddress => {
            setAddressLine("employerAddress1", userAddress);
          }}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              address: isOccupationWhiteListed
                ? false
                : employmentInfo.employerAddress1.length > 0
                ? false
                : true,
            }));
          }}
        />
        <CustomInput
          name="employerAddress2"
          placeholder="Line 2 (Optional)"
          isEdit={!isOccupationWhiteListed}
          value={employmentInfo.employerAddress2}
          maxLength={40}
          onChange={userAddress => {
            setAddressLine("employerAddress2", userAddress);
          }}
        />
        <CustomInput
          name="employerAddress3"
          placeholder="Line 3 (Optional)"
          isEdit={!isOccupationWhiteListed}
          value={employmentInfo.employerAddress3}
          maxLength={40}
          onChange={userAddress => {
            setAddressLine("employerAddress3", userAddress);
          }}
        />
        <CustomInput
          label="Source of Income"
          name="sourceIncome"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={employmentInfo.sourceIncome}
          error={fieldError.sourceOfIncome}
          onClick={handleDropdown("sourceOfIncome")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              sourceOfIncome: employmentInfo.sourceIncome ? false : true,
            }));
          }}
        />
      </div>

      {/* Activities per month */}
      <div className={classes.fieldGroup} style={{ marginTop: "24px" }}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, fontWeight: "700" }}
          className={classes.baseFont}
        >
          Anticipated Account Activities per month
        </Typography>
      </div>
      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Number of Transaction"
          name="transactionNo"
          placeholder="More than 10"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={selectedDropdownValue.numberOfTransaction.name}
          error={fieldError.numberOfTransaction}
          onClick={handleDropdown("numberOfTransaction")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              numberOfTransaction: otherInfo.numberOfTransaction === "",
            }));
          }}
        />
        <CustomInput
          label="Volume of transactions (RM)"
          name="transactionVolume"
          placeholder="Between RM 1000 and RM 5000"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={selectedDropdownValue.volumeOfTransaction.name}
          error={fieldError.volumeOfTransaction}
          onClick={handleDropdown("volumeOfTransaction")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              volumeOfTransaction: otherInfo.volumeOfTransaction === "",
            }));
          }}
        />
      </div>

      {/* Others Information */}
      <div className={classes.fieldGroup} style={{ marginTop: "24px" }}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, fontWeight: "700" }}
          className={classes.baseFont}
        >
          Others Information
        </Typography>
      </div>
      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Source of Wealth"
          name="sourceOfWealth"
          placeholder="Salary"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={selectedDropdownValue.sourceOfWealth.name}
          error={fieldError.sourceOfWealth}
          onClick={handleDropdown("sourceOfWealth")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              sourceOfWealth: otherInfo.sourceOfWealth === "",
            }));
          }}
        />
        <CustomInput
          label="Estimated Net worth (RM)"
          name="estNetWorth"
          placeholder="Between RM 600K and RM 1000K"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={selectedDropdownValue.estimatedNetWorth.name}
          error={fieldError.estimatedNetWorth}
          onClick={handleDropdown("estimatedNetWorth")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              estimateNetWorth: otherInfo.estimatedNetWorth === "",
            }));
          }}
        />
        <CustomInput
          label="Supporting Documents"
          name="supportDocs"
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          value={otherInfo.supportingDocs}
          error={fieldError.supportingDocuments}
          onClick={handleDropdown("supportingDocs")}
          handleBlur={() => {
            setFieldError(prevState => ({
              ...prevState,
              supportingDocuments: otherInfo.supportingDocs ? false : true,
            }));
          }}
        />
      </div>
      <div style={{ margin: "24px 16px" }}>
        <Typography
          color="secondary"
          style={{
            fontSize: "14px",
            lineHeight: 1.57,
            color: "#505050",
          }}
          className={classes.baseFont}
        >
          For any information that cannot be edited within the app, kindly
          contact our <span>Customer Care Centre</span> for assistance.
        </Typography>
      </div>
      <div className={classes.btn}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          onClick={handleSubmit}
          disabled={ecddRequestLoading}
        />
      </div>
    </div>
  );
}

const withConnect = connect(
  state => ({
    ecddRequestLoading: state.goal.eccdRequestLoading,
    loading: state.goal.loading,
    profile: state.goal.profile,
    sourceOfIncomeList: state.goal.sourceOfIncomeList,
    supportingDocs: state.goal.supportingDocs,
    occupationList: state.goal.occupationList,
    businessNatureList: state.goal.businessNatureList,
    sourceOfFundList: state.goal.sourceOfFundList,
    sourceOfWealthList: state.onBoardingReducer.sourceOfWealth,
    numberOfTransactionList: state.onBoardingReducer.numberOfTransaction,
    volumeOfTransactionList: state.onBoardingReducer.volumeOfTransaction,
    estimatedNetWorthList: state.onBoardingReducer.estimatedNetWorth,
  }),
  {
    getSourceOfIncome,
    getSupportingDocs,
    getOccupation,
    getBusinessNature,
    getSourceOfFund,
    getSourceOfWealth,
    getEstimatedNetWorth,
    getNumberOfTransaction,
    getVolumeOfTransaction,
    ecddRequest,
    resetData,
    setStep,
  }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(ECDD);
