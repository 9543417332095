const styles = {
  bottomNav: {
    padding: "16px 0 32px",
    backgroundColor: "#fff",
    boxShadow: "0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly"
  },
  navItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  navLabel: {
    fontFamily: "FSElliotPro",
    fontSize: "12px",
    lineHeight: 1
  }
};

export default styles;
