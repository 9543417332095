export const validateValue = (val, type) => {
  if (type === "DATE") {
    if (val === null || !val) {
      return new Date().getTime();
    }
    return val;
  } else {
    if (val === null || !val) {
      return 0;
    }
    return Math.abs(val);
  }
};

export const setRspButton = fund => {
  if (fund) {
    let { rspStatus = "" } = fund;
    switch (true) {
      case rspStatus === null || !rspStatus:
        return { edit: false, setup: true };
      case rspStatus === "1":
      case rspStatus === "2":
      case rspStatus === "3":
      case rspStatus === "4":
      case rspStatus === "6":
      case rspStatus === "7":
        return { edit: false, setup: true };
      case rspStatus === "5":
      case rspStatus === "0":
        return { edit: true, setup: false };
      case rspStatus === "16":
        return { edit: false, setup: true };
      case parseFloat(rspStatus) !== "16" &&
        parseFloat(rspStatus) >= 11 &&
        parseFloat(rspStatus) <= 17:
      case parseFloat(rspStatus) >= 21 && parseFloat(rspStatus) <= 27:
        return { edit: true, setup: false };
      default:
        return { edit: false, setup: true };
    }
  }
};
export const ddaStatus = status => {
  if (status) {
    switch (true) {
      case parseFloat(status) >= 2 && parseFloat(status) <= 4:
      case parseFloat(status) >= 13 && parseFloat(status) <= 14:
      case parseFloat(status) >= 22 && parseFloat(status) <= 24:
        return true;
      default:
        return false;
    }
  }
  return false;
};
