import React from "react";
import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import NumberFormat from "react-number-format";
import { DoneIcon } from "./StepIcons";
import TransactionHistoryBase from "./TransactionHistoryBase";
import styles from "./styles";
import {
  getCompletedRelated,
  getRefundText,
  getSuccessText,
  getRefundResult,
} from "./utils";

const SwitchTrx = ({
  status,
  method,
  amount,
  unit,
  fee,
  submittedDate,
  processingDate,
  completedDate,
  details,
  isSwitchIn,
  salesChargeAmount,
  salesChargeCcy,
  salesChargePercent,
  planName,
}) => {
  const completed = getCompletedRelated({
    status,
    isDoneIconActive: status === "5",
  });
  const middleText = isSwitchIn ? "In" : "Out";

  const isSubmitted =
    status === "3" ||
    status === "4" ||
    status === "5" ||
    status === "6" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isProcessing =
    status === "4" ||
    status === "5" ||
    status === "9" ||
    status === "10" ||
    status === "11";

  const isCompleted =
    status === "5" || status === "9" || status === "10" || status === "11";

  const isPendingRefund = status === "9";
  const restRefundText = (text, notSwitchIn) => {
    if (notSwitchIn) {
      return ".";
    }
    if (text === "Switch Out amount have refunded to") {
      return " and has been reflected in your account.";
    }
    if (text === "Switch In amount have refunded to") {
      return " and has been reflected in your account.";
    }
    return ".";
  };
  return (
    <TransactionHistoryBase manualAdjustment={null}>
      {/* Submitted */}
      <Step active={isSubmitted}>
        <StepLabel icon={<DoneIcon active={isSubmitted} />}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.label }}
          >
            Submitted
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isSubmitted && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%",
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Switch {middleText} amount has been successfully submitted.
          </Typography>

          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              marginTop: "8px",
            }}
          >
            Inclusive Sales Charge:{" "}
            {(isSwitchIn ? salesChargePercent : salesChargeAmount)|| 0}%
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px",
            }}
          >
            {moment(submittedDate)
              .utcOffset("+0800")
              .format("DD MMM YYYY")}
          </Typography>
        </StepContent>
      </Step>

      {/* Processing */}
      <Step active={isProcessing} style={{ marginTop: "24px" }}>
        <StepLabel icon={<DoneIcon active={isProcessing} />}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color: isProcessing ? "#000000" : "#9D9EA4",
            }}
          >
            Processing
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor: isProcessing && "#0091DA",
            borderWidth: 2,
            height: "100%",
            marginTop: "0",
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            Your Switch {middleText} amount is being processed.
          </Typography>
          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px",
            }}
          >
            {moment(processingDate)
              .utcOffset("+0800")
              .format("DD MMM YYYY")}
          </Typography>
        </StepContent>
      </Step>

      {/* Completed */}
      <Step
        active={isCompleted}
        style={{
          marginTop: "24px",
        }}
      >
        <StepLabel icon={completed.icon}>
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.label,
              color:
                status === "5" || status === "9" || status === "10"
                  ? "#000000"
                  : "#9D9EA4",
            }}
          >
            {completed.text}
          </Typography>
        </StepLabel>
        <StepContent
          style={{
            borderColor:
              getRefundResult(details, status, isSwitchIn) && "#0091DA",
            borderWidth: 2,
            marginTop: "0",
            height: "100%",
          }}
        >
          <Typography style={{ ...styles.baseFont, ...styles.content }}>
            {getSuccessText(status, middleText)}
          </Typography>

          <Typography
            style={{
              ...styles.baseFont,
              ...styles.content,
              ...styles.date,
              marginTop: "8px",
            }}
          >
            {moment(completedDate)
              .utcOffset("+0800")
              .format("DD MMM YYYY")}
          </Typography>
        </StepContent>
      </Step>
      {/* refund */}
      {status === "9" && (
        <Step active={isProcessing} style={{ marginTop: "24px" }}>
          <StepLabel
            icon={
              <DoneIcon
                active={getRefundResult(details, status, isSwitchIn)}
              />
            }
          >
            <Typography
              color="textPrimary"
              style={{
                ...styles.baseFont,
                ...styles.label,
                color: isPendingRefund ? "#000000" : "#9D9EA4",
              }}
            >
              {getRefundResult(details, status, isSwitchIn)
                ? "Refund Completed"
                : "Pending Refund"}
            </Typography>
          </StepLabel>
          <StepContent
            style={{
              borderColor: isProcessing && "#0091DA",
              borderWidth: 2,
              height: "100%",
              marginTop: "0",
            }}
          >
            <Typography style={{ ...styles.baseFont, ...styles.content }}>
              {`${getRefundText(isSwitchIn, details)}`}{" "}
              <span style={{ fontWeight: "700" }}>{planName}</span>
              {restRefundText(getRefundText(isSwitchIn, details), isSwitchIn)}
            </Typography>

            {getRefundResult(details, status, isSwitchIn) && (
              <Typography
                style={{
                  ...styles.baseFont,
                  ...styles.content,
                  ...styles.date,
                  marginTop: "8px",
                }}
              >
                {moment(completedDate)
                  .utcOffset("+0800")
                  .format("DD MMM YYYY")}
              </Typography>
            )}
          </StepContent>
        </Step>
      )}
    </TransactionHistoryBase>
  );
};

export default SwitchTrx;
