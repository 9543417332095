import React, { useState } from "react";
import { Typography, Badge } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";

import { icons } from "../../constants";
import { ReactComponent as HomeNewSvg } from "../../assets/icons/home-new.svg";
import { ReactComponent as RewardSvg } from "../../assets/icons/rewards.svg";
import { ReactComponent as TransactionSvg } from "../../assets/icons/transaction.svg";
import { ReactComponent as ProfileSvg } from "../../assets/icons/profile.svg";

import styles from "./styles";
import "./index.css";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";

const BottomNav = props => {
  const { location, history } = props;

  const { pathname } = location;

  const [isNewMessage, setNewMessage] = useState(true);

  const navigation = [
    {
      id: 1,
      name: "Home",
      route: "/dashboard",
    },
    // {
    //   id: 2,
    //   name: "Rewards",
    //   route: "/rewards"
    // },
    {
      id: 3,
      name: "Transaction",
      route: "/transaction",
    },
    // {
    //   id: 4,
    //   name: "Inbox",
    //   route: "/inbox"
    // },
    {
      id: 5,
      name: "Profile",
      route: "/profile",
    },
  ];

  const renderIcon = (isActive, routeName) => {
    switch (routeName) {
      case "/dashboard":
        return (
          <HomeNewSvg
            style={{
              fill: isActive ? "#084C95" : "#1793D7",
            }}
            className={`home${isActive ? " active" : ""}`}
          />
        );

      case "/rewards":
        return (
          <img
            src={isActive ? icons.rewardsActiveSvg : icons.rewardsSvg}
            alt="rewards"
          />
        );

      case "/transaction":
        return (
          <TransactionSvg
            className={`transaction${isActive ? " active" : ""}`}
          />
        );

      case "/inbox":
        return isActive ? (
          <img src={icons.inboxActive} alt="inbox" />
        ) : (
          <Badge color="primary" variant="dot" invisible={!isNewMessage}>
            <img src={icons.inbox} alt="inbox" />
          </Badge>
        );

      case "/profile":
        return (
          <ProfileSvg
            style={{
              fill: isActive ? "#084C95" : "#fff",
            }}
            className={`profile${isActive ? " active" : ""}`}
          />
        );

      default:
        return;
    }
  };

  return (
    <div style={styles.bottomNav}>
      {navigation.map((item, index) => {
        const isActive = pathname === item.route;

        return (
          <div
            style={styles.navItem}
            key={index}
            onClick={() => {
              dataLayerGTM("click_tag", "Click", "Navigation", `${item.name}`);
              history.push(item.route);
            }}
          >
            <div style={{ minHeight: "28px" }}>
              {renderIcon(isActive, item.route)}
            </div>
            <Typography
              style={{
                ...styles.navLabel,
                fontWeight: isActive ? "700" : "normal",
                color: isActive ? "#084c95" : "#616367",
                marginTop: "4px",
              }}
            >
              {item.name}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default compose(withRouter)(BottomNav);
