import React from "react";
import { Typography, FormControl, TextField, Button } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";

import { StepLabel, CustomButton } from "../../../components";
import styles from "../styles";

const UpdateEmployerAddress = props => {
  const { history } = props;

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      <StepLabel
        title="Update Employer Address"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <form style={{ margin: "16px 16px 0 16px" }}>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            Address 1
          </Typography>
          <TextField
            // placeholder="Please describe your problem"
            value="Persiaran Medini Sentral 1"
            variant="outlined"
            fullWidth
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" }
            }}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: "14px" }}>
          <Typography
            color="textPrimary"
            style={{ ...styles.baseFont, ...styles.inputLabel }}
          >
            Address 2 (Optional)
          </Typography>
          <TextField
            // placeholder="Please describe your problem"
            value="Persiaran Medini Sentral 1"
            variant="outlined"
            fullWidth
            inputProps={{
              style: { ...styles.baseFont, padding: "12px 16px" }
            }}
          />
        </FormControl>
      </form>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Save"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
        />
      </div>
    </div>
  );
};

export default compose(withRouter)(UpdateEmployerAddress);
