const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    minHeight: "100vh"
  },
  imgContainer: {
    margin: "0px 16px 24px",
    position: "relative",
    textAlign: "center"
  },
  img: {
    maxWidth: "100%",
    height: "100%",
    width: "100%",
    borderRadius: "8px"
  },
  imgLabel: {
    position: "absolute",
    bottom: 0,
    left: 0,
    color: "#fff",
    margin: "16px",
    fontFamily: "FSElliotPro",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.2
  },
  info: {
    margin: "0 16px",
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    columnGap: "16px",
    rowGap: "16px"
  },
  label: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.43,
    color: "#616367"
  },
  inputField: {
    color: "#333",
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5
  },
  depositLabel: {
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.43,
    color: "#616367"
  },
  deposit: {
    fontFamily: "FSElliotPro",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "normal",
    marginTop: "4px"
  },
  termCondition: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    margin: "24px 16px 16px"
  },
  btn: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    padding: "16px",
    display: "block",
    marginTop: "auto"
  },
  prefix: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: 1.5
  },
  footer: { margin: "24px 16px 0" },
  month: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "1.43",
    color: "rgba(51,51,51,0.7)"
  }
};

export default styles;
