/**
 *
 * Routes
 *
 */

import React, { useEffect } from "react";
import { Route } from "react-router-dom";
//import history from "../../history";
import { MuiThemeProvider } from "@material-ui/core/styles";
//import cogoToast from "cogo-toast";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import {
  getNewTokenFailed,
  getNewTokenSuccess,
  reqTokenOnprogress,
  resetFailedRequestProps,
  signOutAction,
} from "../../containers/Authentication/redux/actions";
import { setProfileInfoV2 } from "../../pages/getStarted/redux/actions";
import { resetData } from "../../pages/redux/action";

import {
  setBlockRequest,
  setUnauthorizedNotif,
  stopLoading,
} from "../../globalRedux/actions";

import { theme } from "../../constants";

import RoutesList from "./Routes";

import { hideError, resetALlState } from "../../globalRedux/actions";

import { SwitchingProvider } from "../../contexts/SwitchingContext";

import {
  Dashboard,
  FundSelection,
  FundSelectionList,
  InvestTab,
  SaveTab,
  Switching,
  SwitchingSuccess,
  SwitchingSummary,
  SwitchList,
} from "../../pages";

const deepLinkPrefix = "/deeplink/";
function RoutesComponent(props) {
  const { history, resetData } = props;

  const storageList = [
    "reqTokenDate",
    "persist:root",
    "mpUserInfo",
    "isNewUser",
    "currentTab",
    "mpAccessToken",
    "mpUserStatus",
  ];
  const cleanStorage = () => {
    for (let i = 0; i < storageList.length; i++) {
      localStorage.removeItem(storageList[i]);
    }
  };

  const checkDeeplink = path => {
    return path.startsWith(deepLinkPrefix) ? true : false;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const step = props.globalProps.globalData.step;
    const currentPath = window.location.pathname;

    if (step !== null && !checkDeeplink(currentPath)) {
      history.push(step);
    }

    const invalidTokenListener = window.eventEmitter.addListener(
      "invalid_token",
      () => {
        //if (step !== "/") {
        props.reqTokenOnprogress(false);
        props.getNewTokenFailed();
        props.setUnauthorizedNotif();
        cleanStorage();
        history.push("/");

        //props.signOutAction();
        //   setAuth(false);
        // }
      }
    );

    function handleDataCustSuccess(event) {
      const res = event.detail;

      props.setProfileInfoV2(JSON.parse(localStorage.getItem("mpUserInfo")));
      props.getNewTokenSuccess(res);
      props.reqTokenOnprogress(false);
      localStorage.setItem("reqTokenDate", Date.now());
      localStorage.setItem("mpBearerToken", res);
    }

    function handleDataCustFail() {
      // if (step !== "/") {

      props.reqTokenOnprogress(false);
      props.getNewTokenFailed();
      props.setUnauthorizedNotif();
      //  props.signOutAction();
      resetData();
      props.resetALlState();
      cleanStorage();
      history.push("/");
      // }
    }

    document.addEventListener(
      "CUSTOMER_SET_STATUS_ROOT",
      handleDataCustSuccess
    );
    document.addEventListener("CUSTOMER_SET_FAIL_ROOT", handleDataCustFail);

    return () => {
      invalidTokenListener.remove();
      document.removeEventListener(
        "CUSTOMER_SET_STATUS_ROOT",
        handleDataCustSuccess
      );
      document.removeEventListener(
        "CUSTOMER_SET_FAIL_ROOT",
        handleDataCustFail
      );
    };
  }, []);

  useEffect(() => {
    if (
      props.location.pathname === "/make-deposit/DASHBOARD" ||
      props.location.pathname === "/step-three" ||
      props.location.pathname === "/make-deposit/GOALDETAILS" ||
      props.location.pathname === "/make-deposit/GOAL_CREATION" ||
      props.location.pathname === "/dashboard"
    ) {
      props.setBlockRequest(false);
    }
    if (props.location.pathname === "/dashboard") {
      const local = JSON.parse(localStorage.getItem("currentTab"));
      if (local) {
        history.push(`/dashboard/${local.name}`);
      }
    }

    // switching dropdown have problem
    // when user have risk profile expired
    // system will prompt error message
    // and that prompt somehow causing react-spring-bottom-sheet
    // to not removing `overflow: hidden`
    // eg: `overflow: hidden` is still there when `/switching` page is unmounted (usually the `overflow: hidden` is gone when bottom-sheet is unmounted)
    // so in here we manually reset the body style so that user can scroll down & up
    if (
      props.location.pathname.includes("/fund-selection") ||
      props.location.pathname.includes("/invest-introduction") ||
      props.location.pathname.includes("/risk-assessment") ||
      props.location.pathname === "/switching/create"
    ) {
      document.body.style = "";
    }
  }, [props.location.pathname]);

  return (
    <MuiThemeProvider theme={theme}>
      {RoutesList.map(({ exact, path, component }, i) => {
        let redirectPath = "/";
        if (props.location.pathname === "/") {
          if (props.location.search) {
            const reg = new RegExp("[?&]redirect=([^&#]*)", "i");
            const string = reg.exec(props.location.search);
            if (string) {
              if (string.length > 1) {
                redirectPath = string[1];
              }
            }
          }
        }
        return (
          <React.Fragment key={`Route-${i + 1}`}>
            <Route
              exact={exact}
              path={path}
              component={component}
              key={`Route-${i + 1}`}
            />
          </React.Fragment>
        );
      })}
      <Route
        path="/fund-selection"
        render={({ match: { url } }) => (
          <FundSelection>
            {props => (
              <>
                <Route
                  path={`${url}/conservative`}
                  render={() => <FundSelectionList {...props} />}
                />
                <Route
                  path={`${url}/moderate`}
                  render={() => <FundSelectionList {...props} />}
                />
                <Route
                  path={`${url}/aggressive`}
                  render={() => <FundSelectionList {...props} />}
                />
              </>
            )}
          </FundSelection>
        )}
      />
      <Route
        path="/dashboard"
        render={({ match: { url } }) => (
          <Dashboard>
            {props => (
              <>
                <Route
                  path={`${url}/Save`}
                  render={() => <SaveTab {...props} />}
                />
                <Route
                  path={`${url}/Invest`}
                  render={() => <InvestTab {...props} />}
                />
              </>
            )}
          </Dashboard>
        )}
      />
      <Route
        path="/switching"
        render={({ match: { url } }) => (
          <SwitchingProvider>
            <Route
              path={`${url}/create`}
              render={() => (
                <Switching>
                  <Route
                    path={`${url}/create/switch-from`}
                    render={({ match: { url: switchFromUrl } }) => (
                      <SwitchList>
                        <Route
                          path={`${switchFromUrl}/goals`}
                          component={SwitchList.Content}
                        />
                        <Route
                          path={`${switchFromUrl}/funds`}
                          component={SwitchList.Content}
                        />
                      </SwitchList>
                    )}
                  />
                  <Route
                    path={`${url}/create/switch-to`}
                    render={({ match: { url: switchToUrl } }) => (
                      <SwitchList>
                        <Route
                          path={`${switchToUrl}/goals`}
                          component={SwitchList.Content}
                        />
                        <Route
                          path={`${switchToUrl}/funds`}
                          component={SwitchList.Content}
                        />
                      </SwitchList>
                    )}
                  />
                </Switching>
              )}
            />
            <Route path={`${url}/summary`} exact component={SwitchingSummary} />
            <Route path={`${url}/success`} exact component={SwitchingSuccess} />
          </SwitchingProvider>
        )}
      />
    </MuiThemeProvider>
  );
}

RoutesComponent.propTypes = {
  auth: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  location: PropTypes.object,
  globalProps: PropTypes.object.isRequired,
  signOutAction: PropTypes.func.isRequired,
  setUnauthorizedNotif: PropTypes.func.isRequired,
  setProfileInfoV2: PropTypes.func.isRequired,
  getNewTokenSuccess: PropTypes.func.isRequired,
  reqTokenOnprogress: PropTypes.func.isRequired,
  getNewTokenFailed: PropTypes.func.isRequired,
  setBlockRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    clients: state.clientReducer,
    selectedObject: state.breadCrumbsReducer,
    globalProps: state.globalReducer,
    goal: state.goal,
  };
};

const mapDispatchToProps = {
  signOutAction,
  stopLoading,
  resetFailedRequestProps,
  hideError,
  setBlockRequest,
  setUnauthorizedNotif,
  setProfileInfoV2,
  getNewTokenSuccess,
  reqTokenOnprogress,
  getNewTokenFailed,
  resetALlState,
  resetData,
};

const Routes = compose(
  withRouter,

  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(RoutesComponent);

export default Routes;
