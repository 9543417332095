import React, { useEffect, useState } from "react";
import { CustomButton, Alert, DefaultLoader } from "../../components";
import "./style.scss";
// import ReactPlayer from "react-player";
import { Icon } from "@material-ui/core";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { images } from "../../constants";
import { useEcddChecking } from "../../utils/hooks";
import { connect } from "react-redux";
import withProfileRedirect from "../../containers/App/withProfileRedirect";
import { getResultAssessment } from "../riskAssessment/redux/action";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";

// function PlayIcon() {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       fill="none"
//       viewBox="0 0 24 24"
//     >
//       <path
//         stroke="#fff"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="1.5"
//         d="M10.001 18.333a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
//         clipRule="evenodd"
//       />
//       <path
//         stroke="#fff"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="1.5"
//         d="M8.332 6.667l5 3.333-5 3.333V6.666z"
//         clipRule="evenodd"
//       />
//     </svg>
//   );
// }

// function CloseIcon({ onClick }) {
//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       width="24"
//       height="24"
//       fill="none"
//       viewBox="0 0 24 24"
//       className="l-intro__close"
//       onClick={onClick}
//     >
//       <path
//         stroke="#fff"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         strokeWidth="2"
//         d="M18 6L6 18M6 6l12 12"
//       />
//     </svg>
//   );
// }

// const InvestIntroduction = props => {
//   const { history } = props;

//   const [play, setPlay] = useState(false);

//   return play ? (
//     <div className="l-intro__standalone-video">
//       <CloseIcon onClick={() => setPlay(false)} />
//       <ReactPlayer
//         url="https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
//         playing={play}
//         //         //   loop
//         height="100%"
//         width="100%"
//       />
//     </div>
//   ) : (
//     <div className="l-intro">
//       <div className="l-intro__video">
//         <Icon
//           className="material-icons-outlined l-intro__back"
//           onClick={() => history.back()}
//         >
//           arrow_back
//         </Icon>
//         <ReactPlayer
//           url="https://www.sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
//           playing={false}
//           //   loop
//           muted
//           height="100%"
//           width="100%"
//         />
//         <div
//           className="l-intro__video-control"
//           onClick={() => setPlay(c => !c)}
//         >
//           <PlayIcon />
//           <span>Play Video</span>
//         </div>
//       </div>
//       <div className="l-intro__wrapper">
//         <h1 className="l-intro__title">
//           Principal Islamic Global Selection Series
//         </h1>
//         <p className="l-intro__desc">
//           A series of Shariah-compliant investment solutions, that each provide
//           you with a varying distribution of income according to your risk
//           profile, with the aim of boosting your income.
//         </p>
//         <div>
//           <CustomButton
//             text="Understand my risk profile"
//             variant="contained"
//             color="#1278CC"
//             backgroundColor="#fff"
//             onClick={() => history.push("/risk-assessment")}
//           />
//           <CustomButton
//             text="I know my risk profile"
//             backgroundColor="transparent"
//             color="#fff"
//             style={{ boxShadow: "none", marginTop: "0.5rem" }}
//             onClick={() => history.push("/risk-profile")}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

const InvestIntroductionWithoutVideo = ({
  history,
  ecddStatus,
  profile,
  resourceSwitching,
  location,
  getResultAssessment,
  riskProfileResult,
  resultAssessmentLoading,
}) => {
  const [pathToGo, setPathToGo] = useState("/risk-assessment");
  const [isDeeplink, setIsDeeplink] = useState(false);
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
  });

  useEffect(() => {
    if (location.state && typeof location.state === "object") {
      if (location.state.hasOwnProperty("expired") && location.state.expired)
        return;
    }

    // riskProfileResult will be used to move pages if the user already has results from the assessment or does not yet have them
    if (Object.entries(riskProfileResult).length === 0) return;
    if (!("result" in riskProfileResult)) return;
    if (!location.state) return;
    if (pathToGo === "/risk-assessment") return;

    let { type, code } = location.state;
    history.push(`/deeplink/${type}/${code}`);
  }, [riskProfileResult, pathToGo]);

  useEffect(() => {
    if (!location.state) return;

    let { type } = location.state;
    if (type.toLowerCase() !== "invest") return;
    setIsDeeplink(true);
    if (!status) {
      getResultAssessment(profile.masterId);
    }
    setPathToGo("/risk-assessment/deeplink");
  }, [location, status]);

  const handleToGo = () => {
    if (!isDeeplink) return history.push(pathToGo);

    let { type, code } = location.state;
    return history.push(pathToGo, { code, type });
  };

  const handleBack = () => {
    // this condition prevent setting up current dashboard tab to be incorrectly
    // when user is comming from switching page
    if (!resourceSwitching) {
      localStorage.setItem(
        "currentTab",
        JSON.stringify({
          id: 2,
          name: "Invest",
        })
      );
    }

    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      `Risk Profile Intro - Risk Profile back button`
    );
    const from = localStorage.getItem("from");

    if (from === "onboarding" || from === "ecdd") {
      history.push("/dashboard");
      localStorage.removeItem("from"); // remove `from` because its only used once
      //! read line 761 in src/pages/redux/saga.js
    } else {
      history.back();
    }
  };
  if (resultAssessmentLoading) return <DefaultLoader />;
  return (
    <>
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <div className="l-intro">
        <div className="l-intro__illustration-wrapper">
          <img src={images.maze} alt="investing" />
        </div>

        <div className="l-intro__wrapper">
          <h1 className="l-intro__title">
            Let’s figure out what type of investor you are!
          </h1>
          <p className="l-intro__desc">
            Are you a risk-taker or do you prefer to play it safe? We have your
            back and can help you create a personalised investment portfolio
            that is suitable for your risk profile and investment needs. Take a
            quick test to discover what type of investor you are.
          </p>
          <div>
            <CustomButton
              text="Let’s find out"
              variant="contained"
              color="#1278CC"
              backgroundColor="#fff"
              onClick={handleCheckEcdd({
                cb: () => {
                  handleToGo();
                },
                ecddExpiredCb: () =>
                  history.push("/onBoarding/ecdd/PROFILEINFO"),
                gtm: {
                  event: "click_tag",
                  action: "Click",
                  category: "Button",
                  label: "Risk Profile Intro - Let's find out",
                },
              })}
            />
            {/* <CustomButton
              text="I know my risk profile"
              backgroundColor="transparent"
              color="#fff"
              style={{ boxShadow: "none", marginTop: "0.5rem" }}
              onClick={handleCheckEcdd({
                cb: () => history.push("/risk-profile"),
                ecddExpiredCb: () =>
                  history.push("/onBoarding/ecdd/INVESTEDFUNDS"),
                gtm: {
                  event: "click_tag",
                  action: "Click",
                  category: "Button",
                  label: "Risk Profile Intro - Risk Profile back button"
                }
              })}
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

const withConnect = connect(
  state => ({
    ...state.riskAssessmentReducer,
    profile: state.goal.profile,
    ecddStatus: state.goal.ecddStatus,
    resourceSwitching: state.switchingReducer.resource,
  }),
  { getResultAssessment }
);

export default compose(
  withRouter,
  withConnect,
  withProfileRedirect
)(InvestIntroductionWithoutVideo);
