import { createAction } from "redux-act";
import { GET_TOKEN, GET_TOKEN_SUCCESS, GET_TOKEN_FAILED } from "./constans";
export const signUpAction = createAction("SIGN_UP");
export const userSignedUp = createAction("USER_SIGNED_UP");
export const signUpRequestFailedAction = createAction("SIGN_UP_REQUEST_FAILED");

export const signInAction = createAction("SIGN_IN");
export const FpxSignInAction = createAction("FPX_SIGN_IN");
export const resetFailedRequestProps = createAction("RESET");
export const fpxUserSignIn = createAction("FPX_USER_SIGNED_IN");
export const userSignedIn = createAction("USER_SIGNED_IN");
export const signInRequestFailedAction = createAction("SIGN_IN_REQUEST_FAILED");

export const userLastLogin = createAction("USER_LAST_LOGIN");

export const signOutAction = createAction("SIGN_OUT");
export const userSignedOut = createAction("USER_SIGNED_OUT");

export const initHeaderAction = createAction("INIT_HEADER");

export const accessTokenUpdatedAction = createAction("ACCESS_TOKEN_UPDATED");

export const testAuthAction = createAction("TEST_AUTH");
export const testAuthRequestFailedAction = createAction("TEST_AUTH_FAILED");
export const reqTokenOnprogress = createAction("REQUEST_TOKEN_ONPROGRESS");
export const getNewToken = createAction(GET_TOKEN);
export const getNewTokenSuccess = createAction(GET_TOKEN_SUCCESS);
export const getNewTokenFailed = createAction(GET_TOKEN_FAILED);
// export function getNewToken(payload) {
//   return {
//     type: GET_TOKEN,
//     payload
//   };
// }
// export function getNewTokenSuccess(payload) {
//   console.log("PAYLOAD TOKEN", payload);
//   return {
//     type: GET_TOKEN_SUCCESS,
//     payload
//   };
// }
// export function getNewTokenFailed() {
//   return {
//     type: GET_TOKEN_FAILED
//   };
// }
