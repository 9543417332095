import React, { useState, useEffect } from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import cogoToast from "cogo-toast";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import { sendContactMessage } from "../../redux/action";

import { StepLabel, CustomButton } from "../../../components";
import styles from "../styles";
import { dataLayerGTM } from "../../../utils/functions/dataLayerGTM";
import { validateEmail } from "../../../utils/functions/validateEmail";

const ContactUs = props => {
  const { history, profile, sendContactMessage } = props;

  const [userInfo, setUserInfo] = useState(null);
  const [idInfo, setIdInfo] = useState({
    fullName: "",
    mobileNo: "",
    email: "",
  });
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState(false);

  const handleSendMessage = () => {
    dataLayerGTM("click_tag", "Click", "Button", "Submit Button");
    cogoToast.success("Your message has been sent");
    sendContactMessage({
      name: idInfo.fullName,
      mobileNo: idInfo.mobileNo,
      email: idInfo.email,
      message: message,
    });
    setMessage("");
  };

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setIdInfo({
        fullName: userInfo.fullName ? userInfo.fullName : "",
        mobileNo: userInfo.mobileNo ? userInfo.mobileNo : "",
        email: userInfo.email ? userInfo.email : "",
      });
    }
  }, [userInfo]);

  const handleChange = (field, value) => {
    if (field === "email") {
      setEmailError(!validateEmail(value));
    }
    setIdInfo(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  return (
    <div
      style={{
        ...styles.column,
        flex: 1,
        minHeight: "100vh",
        backgroundColor: "#fff",
        justifyContent: "space-between",
      }}
    >
      <div>
        <StepLabel
          title="Contact us"
          onClick={() => history.back()}
          containerStyles={{ padding: "16px 16px 22px" }}
        />
        {/* <div style={styles.versionCard}>
        <Typography
          color="textPrimary"
          style={{
            ...styles.baseFont,
            fontSize: "14px",
            lineHeight: 1.57
          }}
        >
          Principal Go Save v1.1.0
        </Typography>
      </div> */}
        {/* Text Area */}
        <div style={{ margin: "16px 16px 0 16px" }}>
          {!userInfo && (
            <>
              <Typography
                color="textPrimary"
                style={{ ...styles.baseFont, ...styles.inputLabel }}
              >
                Full Name
              </Typography>
              <TextField
                placeholder="Full Name"
                variant="outlined"
                onChange={e => handleChange("fullName", e.target.value)}
                value={idInfo.fullName}
                fullWidth
                inputProps={{
                  style: { ...styles.baseFont, padding: "12px 16px" },
                }}
              />
              <Typography
                color="textPrimary"
                style={{
                  ...styles.baseFont,
                  ...styles.inputLabel,
                  marginTop: "16px",
                }}
              >
                Contact Number
              </Typography>
              <TextField
                placeholder="Contact Number"
                type="number"
                variant="outlined"
                onChange={e => handleChange("mobileNo", e.target.value)}
                value={idInfo.mobileNo}
                fullWidth
                inputProps={{
                  style: { ...styles.baseFont, padding: "12px 16px" },
                }}
              />
              <Typography
                color="textPrimary"
                style={{
                  ...styles.baseFont,
                  ...styles.inputLabel,
                  marginTop: "16px",
                }}
              >
                Email Address
              </Typography>
              <TextField
                placeholder="Email"
                variant="outlined"
                onChange={e => handleChange("email", e.target.value)}
                value={idInfo.email}
                fullWidth
                inputProps={{
                  style: { ...styles.baseFont, padding: "12px 16px" },
                }}
              />
            </>
          )}
          <Typography
            color="textPrimary"
            style={{
              ...styles.baseFont,
              ...styles.inputLabel,
              marginTop: "16px",
            }}
          >
            Message
          </Typography>
          <TextField
            placeholder="Please describe your problem"
            variant="outlined"
            multiline
            rows="4"
            fullWidth
            value={message}
            inputProps={{ style: { ...styles.baseFont } }}
            InputProps={{ style: { padding: "12px 16px" } }}
            onChange={e => setMessage(e.target.value)}
          />
        </div>
      </div>

      {/* Button */}
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          bottom: 0,
        }}
      >
        <div style={styles.btn}>
          <CustomButton
            text="Submit"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={handleSendMessage}
            disabled={
              message.length === 0 ||
              idInfo.fullName.length === 0 ||
              idInfo.mobileNo.length === 0 ||
              idInfo.email.length === 0 ||
              emailError
                ? true
                : false
            }
          />
        </div>
        <button
          style={{
            fontFamily: "FSElliotPro",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: 1.5,
            color: "#0076CF",
            marginTop: "16px",
            marginBottom: "24px",
            padding: 0,
            border: 0,
            background: "none",
          }}
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Link",
              "Chat us via WhatsApp button"
            );
            window.my.call("openAppLink", {
              url: "https://wa.me/60162999792",
            });
          }}
        >
          Chat with our live agent on WhatsApp
        </button>
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    profile: state.goal.profile,
  }),
  { sendContactMessage }
);

export default compose(
  withRouter,
  withConnect
)(ContactUs);
