import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  InvestedFundCard,
  DefaultLoader,
  CustomButton,
  AlertAccount,
  Alert,
} from "../../components";
import withProfileRedirect from "../../containers/App/withProfileRedirect";

import { getCustomerPortfolio } from "../dashboard/redux/actions";
import { Icon } from "@material-ui/core";
import "./style.scss";
import { setStatus } from "../../utils/functions/goalCombineTrx";
import {
  checkAcctStsCashIn,
  checkAcctStsCashOut,
} from "../../utils/functions/validationsDeposit";
import moment from "moment";
import { useEcddChecking } from "../../utils/hooks";
import { setCurrentEcddStatus } from "../../globalRedux/actions";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
const InvestedFunds = props => {
  const {
    customerPortfolio,
    getCustomerPortfolio,
    loadingCustomerPortfolio,
    riskProfileResult,
    history,
    subAccStsInvest,
    profile,
    ecddStatus,
    isEcddCompleted,
    setCurrentEcddStatus,
  } = props;

  const [accountSts, setAccountSts] = useState({
    status: "Active",
    blockedCashIn: false,
    blockedCashout: false,
    alert: false,
  });
  const [isRiskProfileExpired, setRiskProfileExpired] = useState(false);
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
    shouldNotPromptOnEffect: !isEcddCompleted,
  });

  useEffect(() => {
    getCustomerPortfolio();
  }, []);

  const goToWhichFundTabs = () => {
    let pathToGo = "/fund-selection/conservative";
    if ("resultNameEn" in riskProfileResult) {
      let level = riskProfileResult.resultNameEn;
      pathToGo = `/fund-selection/${level.toLowerCase()}`;
    }
    return pathToGo;
  };

  const handleInvest = () => {
    if (accountSts.blockedCashIn) {
      setAccountSts({ ...accountSts, alert: true });
      return;
    }
    if (Object.entries(riskProfileResult).length === 0) {
      history.push("/risk-assessment");
      return;
    }

    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }

    let directFundTabs = goToWhichFundTabs();
    history.push(directFundTabs);
  };

  /**
   ** check if subaccount status is posible to do transaction
   ** start
   */
  const checkAccStsinvest = () => {
    let newObj = {
      status: "Active",
      alert: false,
      blockedCashIn: false,
      blockedCashout: false,
    };
    if ("sts" in subAccStsInvest) {
      let { sts } = subAccStsInvest;
      newObj.status = sts;
      let stopCashIn = checkAcctStsCashIn(sts);
      let stopCashOut = checkAcctStsCashOut(sts);
      if (!stopCashIn) {
        newObj.blockedCashIn = true;
      }
      if (!stopCashOut) {
        newObj.blockedCashout = true;
      }
      setAccountSts(newObj);
    }
  };

  useEffect(() => {
    checkAccStsinvest();
  }, [subAccStsInvest]);

  /**
   ** check if subaccount status is posible to do transaction
   ** end
   */

  if (loadingCustomerPortfolio) return <DefaultLoader />;

  return (
    <div className="l-invested-funds">
      <AlertAccount
        title={`Account ${
          accountSts.status === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountSts.status === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountSts({ ...accountSts, alert: false });

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={accountSts.alert}
        handleClose={() => setAccountSts({ ...accountSts, alert: false })}
      />
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={isRiskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={() => {
          history.push("/invest-introduction");
        }}
        handleCancel={() => setRiskProfileExpired(false)}
      />
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => {
          setCurrentEcddStatus(false);
          onResetStatus();
        }}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />
      <div className="l-invested-funds__wrapper">
        <div className="l-invested-funds__back-btn">
          <Icon
            className="material-icons-outlined"
            style={{ cursor: "pointer", color: "#0091DA" }}
            onClick={() => history.back()}
          >
            arrow_back
          </Icon>
          <h2>Invested Funds</h2>
        </div>
        <div className="l-invested-funds__list">
          {setStatus(customerPortfolio).map((item, idx) => (
            <InvestedFundCard
              key={idx}
              fundName={item.fund.name}
              totalAsset={item.currentValue || 0}
              cashInStatus={item.cashInStatus}
              unrealisedGain={item.unrealisedGain}
              totalCost={item.totalCost}
              percentage={!item.unrealisedGain ? false : true}
              handleClick={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Link",
                  `Invested fund ${item.fund.name}`
                );
                history.push(`/invested-funds/${item.fundCd}`);
              }}
            />
          ))}
        </div>
      </div>

      <div />
      <div className="l-invested-funds__invest-btn">
        <CustomButton
          text="Invest New Funds"
          variant="outlined"
          color="#1278CC"
          backgroundColor="#fff"
          onClick={handleCheckEcdd({
            cb: handleInvest,
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/INVESTFUNDS"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Invested fund listing - +invest funds button",
            },
          })}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loadingCustomerPortfolio: state.dashboardReducer.loadingCustomerPortfolio,
    customerPortfolio: state.dashboardReducer.customerPortfolio,
    riskProfileResult: state.riskAssessmentReducer.riskProfileResult,
    subAccStsInvest: state.dashboardReducer.subAccStsInvest,
    ecddStatus: state.goal.ecddStatus,
    profile: state.goal.profile,
    isEcddCompleted: state.commingFromReducer.isEcddCompleted,
  }),
  {
    getCustomerPortfolio,
    setCurrentEcddStatus,
  }
);

export default compose(
  withConnect,
  withRouter,
  withProfileRedirect
)(InvestedFunds);
