import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import OtpInput from "react-otp-input";
import moment from "moment";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";
import {
  sendEmailOtp,
  verifyEmailOtp,
  updateProfile
} from "../../../redux/action";
import { PROFILE } from "../../../redux/constant";

import { StepLabel, CustomButton, DefaultLoader } from "../../../../components";
import styles from "../../styles";
import "./index.css";

const VerifyEmail = props => {
  const {
    otp,
    sendEmailOtp,
    verifyEmailOtp,
    updateProfile,
    profile,
    loadingUpdateProfile,
    history,
    match: { params }
  } = props;

  const email = params.email;

  const [emailOtp, setEmailOtp] = useState(null);
  const [resendOtp, setResendOtp] = useState(false);
  const [isOtpError, setOtpError] = useState(false);
  const [timeLeft, setTimeLeft] = useState(180);

  const [userInfo, setUserInfo] = useState(null);
  const [isTax, setIsTax] = useState(false);
  const submitted = useRef(false);

  const handleChangeOtp = token => {
    setEmailOtp(token);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
    }

    if (timeLeft === 0) {
      setResendOtp(true);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (profile) {
      setUserInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    if (userInfo) {
      setIsTax(
        userInfo.identities &&
          userInfo.identities.find(identity => identity.idTypeCd === "TAX")
          ? true
          : false
      );
    }
  }, [userInfo]);

  const handleVerifyEmail = () => {
    if(submitted.current) return;
    submitted.current = true;

    const payload = {
      masterId: userInfo.masterId, //R
      email: email, //R the updated
      mobileNo: userInfo.mobileNo, //R
      fullName: userInfo.fullName, //R
      townOfBirth: userInfo.townOfBirth, //R
      dob: moment(userInfo.dob).format("YYYY-MM-DD"), //R
      race: userInfo.race, //R
      natureOfBusinessCd: userInfo.natureOfBusinessCd, //R
      occupationTypeCd: userInfo.occupationTypeCd, //R
      maritalStatusCd: userInfo.maritalStatusCd, //R
      yearlyIncomeLevelCd: userInfo.yearlyIncomeLevelCd, //R
      sourceOfFundCd: userInfo.sourceOfFundCd,
      investmentObjCd: userInfo.investmentObjCd,
      employerName: userInfo.employerName,
      identities:
        isTax === true
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              },
              {
                idTypeCd: "TAX", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "TAX"
                ).citizenship //R
              }
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).idNo, //R
                citizenship: userInfo.identities.find(
                  identity => identity.idTypeCd === "NRIC"
                ).citizenship //R
              }
            ],
      address: {
        line1: userInfo.contacts.find(
          contact => contact.contactTypeCd === "PERMANENT"
        ).line1, //R
        postCode: userInfo.contacts.find(contact => contact.postCode).postCode, //R
        city: userInfo.contacts.find(contact => contact.postCode).city, //R
        state: userInfo.contacts.find(contact => contact.state).state, //R,
        country: userInfo.identities.find(
          identity => identity.idTypeCd === "NRIC"
        ).citizenship //R
      }
    };

    setOtpError(false);
    if (emailOtp != otp) {
      setOtpError(true);
    } else {
      setOtpError(false);
      // make sure to reset the submit button
      verifyEmailOtp({
        email: email,
        token: emailOtp.toString()
      }, (err) => {
        if(err) {
          submitted.current = false;
        }

        updateProfile({
          payloadData: payload,
          from: PROFILE
        }, () => (submitted.current = false));
      });
    }
  };

  const handleResendOtp = () => {
    setTimeLeft(180);
    setResendOtp(false);
    setOtpError(false);
    setEmailOtp("");
    sendEmailOtp({ email: email, fullName: profile.fullName });
  };

  // window.consoleLog(userInfo);

  return (
    <div style={{ backgroundColor: "#fff", ...styles.container }}>
      {loadingUpdateProfile && <DefaultLoader />}
      <StepLabel
        title="Verify Email"
        onClick={() => history.back()}
        containerStyles={{ margin: "16px 16px 22px" }}
      />
      <div style={{ margin: "0 16px 24px" }}>
        <Typography
          style={{
            ...styles.baseFont,
            ...styles.inputLabel,
            lineHeight: 1.29,
            color: "#505050"
          }}
        >
          <span style={{ fontWeight: "normal" }}>
            An email with a 6 digit verification code has been sent to
          </span>{" "}
          {email}.
        </Typography>
      </div>
      <div
        style={{
          margin: "0 16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <OtpInput
          value={emailOtp}
          onChange={handleChangeOtp}
          numInputs={6}
          isInputNum={true}
          separator={<span>-</span>}
          hasErrored={false}
          className="otp"
          inputStyle={{ ...styles.baseFont, ...styles.otp }}
          errorStyle={{ color: "#be0712", border: "solid 1px #be0712" }}
          // focusStyle={{ color: "#1278cc", border: "solid 1px #1278cc" }}
        />
        {isOtpError && (
          <Typography
            style={{
              ...styles.baseFont,
              fontSize: "14px",
              lineHeight: 1.57,
              color: "#be0712",
              marginTop: "16px"
            }}
          >
            You’ve entered the wrong verification code
          </Typography>
        )}

        <Typography
          style={{
            ...styles.baseFont,
            marginTop: "24px",
            fontWeight: "bold"
          }}
        >
          Didn't get verification code?
        </Typography>
        <Typography
          style={{
            ...styles.baseFont,
            color: !resendOtp ? "#505050" : "#1278cc"
          }}
        >
          {timeLeft > 0 && `Resend (${timeLeft}s)`}
          {resendOtp && (
            <span onClick={handleResendOtp}>Click Here to Resend</span>
          )}
        </Typography>
      </div>
      <div style={styles.btnContainer}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!emailOtp ? true : false}
          onClick={handleVerifyEmail}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loadingUpdateProfile: state.goal.loadingUpdateProfile,
    otp: state.goal.otp,
    profile: state.goal.profile
  }),
  { sendEmailOtp, verifyEmailOtp, updateProfile }
);

export default compose(
  withRouter,
  withConnect
)(VerifyEmail);
