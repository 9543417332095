import {
  GET_PROMO,
  GET_PROMO_SUCCESS,
  GET_PROMO_FAILED,
  SET_SELECTED_PROMO,
  RESET_SELECTED_PROMO,
} from "./constants";

const initialState = {
  promoListLoading: false,
  promoList: [],
  selectedPromo: {},
};

export default function promoReducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROMO:
      return {
        ...state,
        promoListLoading: true,
      };
    case GET_PROMO_SUCCESS:
      return {
        ...state,
        promoList: payload,
        promoListLoading: false,
      };
    case GET_PROMO_FAILED:
      return {
        ...state,
        promoList: [],
        promoListLoading: false,
      };
    case SET_SELECTED_PROMO:
      return {
        ...state,
        selectedPromo: payload,
      };
    case RESET_SELECTED_PROMO:
      return {
        ...state,
        selectedPromo: {},
      };
    default:
      return state;
  }
}
