import { all, takeLatest, call, put, select } from "redux-saga/effects";
import * as constant from "./constant";
import {
  getRiskAssessmentSuccess,
  getRiskAssessmentFailed,
  sendRiskAssessmentSuccess,
  sendRiskAssessmentFailed,
  getResultAssessmentSuccess,
  getResultAssessmentFailed,
} from "./action";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

function checkJson(data) {
  if (data.length < 1) return false;
  if (
    /^[\],:{}\s]*$/.test(
      data
        .replace(/\\["\\\/bfnrtu]/g, "@")
        .replace(
          /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
          "]"
        )
        .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
    )
  ) {
    return true;
  } else {
    return false;
  }
}

function* getRiskAssessmentSaga() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/risk-profile/current`);
    const { data } = res;

    if (Array.isArray(data)) {
      data.map((item, index) => {
        if ("questionId" in item) {
          // for temporary using indonesian, later will be malaysia/melayu
          let isJson = checkJson(item.questionId);
          if (isJson) {
            data[index].questionsIdnAfterParse = JSON.parse(item.questionId);
          }
        }
        if ("questionEn" in item) {
          let isJson = checkJson(item.questionEn);
          if (isJson) {
            data[index].questionsEndAfterParse = JSON.parse(item.questionEn);
          }
        }
      });
    }
    yield put(getRiskAssessmentSuccess(data));
  } catch (error) {
    yield put(getRiskAssessmentFailed());
  }
}

function* sendRiskAssessmentSaga({ payload, callback }) {
  try {
    const userInfo = yield select(state => state.goal.profile);
    const cifId = userInfo.masterId;
    const newPayload = Object.assign(payload, {
      cifId,
    });

    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/risk-profile/customer`,
      newPayload
    );
    const { data } = res;

    yield put(sendRiskAssessmentSuccess(data));
    let pathToGo = "/fund-selection/conservative";
    if ("resultNameEn" in data) {
      let level = data.resultNameEn;
      pathToGo = `/fund-selection/${level.toLowerCase()}`;
    }
    callback(pathToGo);
  } catch (error) {
    // If the error occurs before the API request runs, the component cannot detect the error
    // this needs to be handled from the component side
    yield put(sendRiskAssessmentFailed(false));
  }
}

function* sendRiskProfileSaga({ payload, callback }) {
  try {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    const cifId = userInfo.masterId;
    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/risk-profile/customer/select`,
      {
        cifId,
        riskProfileResult: payload,
      }
    );

    const { data } = res;

    yield put({
      type: constant.SEND_RISK_PROFILE_SUCCESS,
      payload: data,
    });
    let pathToGo = "/fund-selection/conservative";
    if ("resultNameEn" in data) {
      let level = data.resultNameEn;
      pathToGo = `/fund-selection/${level.toLowerCase()}`;
    }
    callback(pathToGo);
  } catch (error) {
    yield put({ type: constant.SEND_RISK_PROFILE_FAILED });
  }
}

function* getResultAssesmentSaga(action) {
  try {
    const masterId = yield select((state) => state.goal.profile.masterId)
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/risk-profile/customer/${masterId}`
    );

    const { data } = res;

    if ("errorCode" in data && "message" in data) {
      throw new Error("Fail Get Data");
    }

    if ("data" in data) {
      if (data.data === null) {
        throw new Error("empty");
      }
    }
    if (data === null) {
      throw new Error("empty");
    }
    yield put(getResultAssessmentSuccess(data));
  } catch (error) {
    yield put(getResultAssessmentFailed({}));
  } finally {
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  }
}

export function* riskAssessmentWorkerSaga() {
  yield all([
    takeLatest(constant.GET_RISK_ASSESSMENT, getRiskAssessmentSaga),
    takeLatest(constant.SEND_RISK_ASSESSMENT, sendRiskAssessmentSaga),
    takeLatest(constant.SEND_RISK_PROFILE, sendRiskProfileSaga),
    takeLatest(constant.GET_RESULT_ASSESSMENT, getResultAssesmentSaga),
  ]);
}
