import {
  OnBoarding,
  ProfileForm,
  ECDD,
  IntroPage,
  AboutPage,
  GetStartedStep,
  GetStarted,
  MoreInfo,
  StepOne,
  StepTwo,
  StepThree,
  MakeDeposit,
  DepositSuccessModal,
  AutoDeposit,
  AutoDepositSuccess,
  AutoDepositCancel,
  MakeWithdrawal,
  WithdrawalSummary,
  BankInfo,
  WithdrawalVerification,
  WithdrawalSuccess,
  GoalList,
  GoalDetails,
  NewsList,
  Gamification,
  Transaction,
  Inbox,
  InboxItem,
  Profile,
  ProfileInfo,
  ContactUs,
  Feedback,
  FAQ,
  UpdateEmail,
  VerifyEmail,
  UpdateCityOfBirth,
  UpdateAddress,
  UpdateCity,
  UpdateState,
  UpdatePostCode,
  UpdateEmployerName,
  UpdateEmployerAddress,
  UpdateInvesmentObj,
  PoliticalPosition,
  TaxInfo,
  RiskProfile,
  RiskAssessment,
  FundSelectionStep2,
  //FundSelection,
  FundSelectionStep3,
  InvestedFunds,
  FundDetails,
  InvestIntroduction,
  ProfileFormStepOne,
  ProfileFormStepTwo,
  AutoDepositV2,
  WithdrawalPending,
  Rewards,
  PromoList,
  RewardDetail,
  DepositSummary,
  DeepLink,
  DeeplinkSummary,
  DeeplinkLP,
} from "../../pages";

const routes = [
  {
    path: "/onBoarding",
    name: "On Boarding",
    component: OnBoarding,
    exact: true,
  },
  {
    path: "/onBoarding/form",
    name: "On Boarding Form",
    component: ProfileForm,
    exact: true,
  },
  {
    path: "/onBoarding/form-step-one",
    name: "On Boarding Form Step One",
    component: ProfileFormStepOne,
    exact: true,
  },
  {
    path: "/onBoarding/form-step-two",
    name: "On Boarding Form Step Two",
    component: ProfileFormStepTwo,
    exact: true,
  },
  {
    path: "/onBoarding/ecdd/:from",
    name: "On Boarding ECDD",
    component: ECDD,
    exact: true,
  },
  // {
  //   path: "/onBoarding/intro",
  //   name: "On Boarding Intro",
  //   component: IntroPage,
  //   exact: true
  // },
  // {
  //   path: "/onBoarding/about",
  //   name: "On Boarding About",
  //   component: AboutPage,
  //   exact: true
  // },
  {
    path: "/version/:version",
    name: "Get Started Version",
    component: GetStartedStep,
    exact: true,
  },
  {
    path: "/",
    name: "Get Started",
    component: GetStarted,
    exact: true,
  },
  {
    path: "/getStartedInfo",
    name: "Get Started More Info",
    component: MoreInfo,
    exact: true,
  },
  {
    path: "/step-one",
    name: "Step One",
    component: StepOne,
    exact: true,
  },
  {
    path: "/step-two",
    name: "Step Two",
    component: StepTwo,
    exact: true,
  },
  {
    path: "/step-three",
    name: "Step Three",
    component: StepThree,
    exact: true,
  },
  {
    path: "/make-deposit/:from",
    name: "Make a Deposit",
    component: MakeDeposit,
    exact: true,
  },
  {
    path: "/make-deposit/:from/success",
    name: "Deposit Success",
    component: DepositSuccessModal,
    exact: true,
  },
  // {
  //   path: "/make-deposit/:from/success/auto-deposit/:setup",
  //   name: "Auto Deposit Edit",
  //   component: AutoDeposit,
  //   exact: true
  // },
  {
    path: "/make-deposit/:from/success/auto-deposit/:setup",
    name: "Auto Deposit Setup",
    component: AutoDeposit,
    exact: true,
  },

  {
    path: "/make-deposit/:form/success/rsp/success",
    name: "Auto Deposit Success",
    component: AutoDepositSuccess,
    exact: true,
  },
  {
    path: "/make-deposit/:from/success/auto-deposit/:setup/success-cancel",
    name: "Auto Deposit Cancel",
    component: AutoDepositCancel,
    exact: true,
  },
  // {
  //   path: "/make-deposit/:from/success/auto-deposit/cancel",
  //   name: "Auto Deposit Cancel",
  //   component: AutoDepositCancel,
  //   exact: true
  // },
  {
    path: "/make-deposit/:from/success/auto-deposit-v2/:setup",
    name: "Auto Deposit Setup V2",
    component: AutoDepositV2,
    exact: true,
  },
  {
    path: "/make-withdrawal",
    name: "Make a Withdrawal",
    component: MakeWithdrawal,
    exact: true,
  },
  {
    path: "/make-withdrawal/summary/:amount",
    name: "Withdrawal Summary",
    component: WithdrawalSummary,
    exact: true,
  },
  {
    path: "/make-withdrawal/bankInfo/:amount",
    name: "Bank Info",
    component: BankInfo,
    exact: true,
  },
  {
    path: "/make-withdrawal/verification",
    name: "Withdrawal Verification",
    component: WithdrawalVerification,
    exact: true,
  },
  {
    path: "/make-withdrawal/success",
    name: "Withdrawal Success",
    component: WithdrawalSuccess,
    exact: true,
  },
  {
    path: "/make-withdrawal/pending",
    name: "Withdrawal Pending",
    component: WithdrawalPending,
    exact: true,
  },
  {
    path: "/goal-list",
    name: "Goal List",
    component: GoalList,
    exact: true,
  },
  {
    path: "/goal-list/:id",
    name: "Goal Details",
    component: GoalDetails,
    exact: true,
  },
  {
    path: "/news-list",
    name: "News List",
    component: NewsList,
    exact: true,
  },
  {
    path: "/transaction",
    name: "Transaction",
    component: Transaction,
    exact: true,
  },
  {
    path: "/gamification",
    name: "Gamification",
    component: Gamification,
    exact: true,
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: Inbox,
    exact: true,
  },
  {
    path: "/inbox/:id",
    name: "Inbox Item",
    component: InboxItem,
    exact: true,
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    exact: true,
  },
  {
    path: "/profile/info",
    name: "Profile Info",
    component: ProfileInfo,
    exact: true,
  },
  {
    path: "/profile/info/updateEmail",
    name: "Update Email",
    component: UpdateEmail,
    exact: true,
  },
  {
    path: "/profile/info/updateEmail/verify/:email",
    name: "Verify Email",
    component: VerifyEmail,
    exact: true,
  },
  {
    path: "/profile/info/updateCityOfBirth",
    name: "Update City of Birth",
    component: UpdateCityOfBirth,
    exact: true,
  },
  {
    path: "/profile/info/updateAddress",
    name: "Update Address",
    component: UpdateAddress,
    exact: true,
  },
  {
    path: "/profile/info/updateCity",
    name: "Update City",
    component: UpdateCity,
    exact: true,
  },
  {
    path: "/profile/info/updateState",
    name: "Update State",
    component: UpdateState,
    exact: true,
  },
  {
    path: "/profile/info/updatePostCode",
    name: "Update Post Code",
    component: UpdatePostCode,
    exact: true,
  },
  {
    path: "/profile/info/updateEmployerName",
    name: "Update Employer Name",
    component: UpdateEmployerName,
    exact: true,
  },
  {
    path: "/profile/info/updateEmployerAddress",
    name: "Update Employer Address",
    component: UpdateEmployerAddress,
    exact: true,
  },
  {
    path: "/profile/info/updateInvestObj",
    name: "Update Investment Objective",
    component: UpdateInvesmentObj,
    exact: true,
  },
  {
    path: "/profile/info/isPolitic",
    name: "Political Position",
    component: PoliticalPosition,
    exact: true,
  },
  {
    path: "/profile/info/taxInfo",
    name: "Tax Resident",
    component: TaxInfo,
    exact: true,
  },
  {
    path: "/profile/contactUs",
    name: "Contact Us",
    component: ContactUs,
    exact: true,
  },
  {
    path: "/profile/feedback",
    name: "Feedback",
    component: Feedback,
    exact: true,
  },
  {
    path: "/profile/faq",
    name: "FAQ",
    component: FAQ,
    exact: true,
  },
  {
    path: "/risk-profile",
    name: "Risk Profile",
    component: RiskProfile,
    exact: true,
  },
  {
    path: "/risk-assessment",
    name: "Risk Assessment",
    component: RiskAssessment,
    exact: true,
  },
  {
    path: "/fund-selection-step-two",
    name: "Fund Selection Step Two",
    component: FundSelectionStep2,
  },
  {
    path: "/fund-selection-step-three",
    name: "Fund Selection Step Three",
    component: FundSelectionStep3,
  },
  {
    path: "/invested-funds",
    name: "Invested Funds",
    component: InvestedFunds,
    exact: true,
  },
  {
    path: "/invested-funds/:id",
    name: "Fund Details",
    component: FundDetails,
    exact: true,
  },
  {
    path: "/invest-introduction",
    name: "Invest Introduction",
    component: InvestIntroduction,
    exact: true,
  },
  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards,
    exact: true,
  },
  {
    path: "/rewards/detail",
    name: "Rewards Detail",
    component: RewardDetail,
    exact: true,
  },
  {
    path: "/promo-list",
    name: "Promo List",
    component: PromoList,
    exact: true,
  },
  {
    path: "/deposit-summary",
    name: "Deposit Summary",
    component: DepositSummary,
    exact: true,
  },
  {
    path: "/deeplink/:type/:code",
    name: "Deeplink",
    component: DeepLink,
    exact: true,
  },
  {
    path: "/deeplink/:type/:code/summary/:from",
    name: "Deeplink summary",
    component: DeeplinkSummary,
    exact: true,
  },
  {
    path: "/deeplink/status/:type/:code",
    name: "Deeplink Status",
    component: DeeplinkLP,
    exact: true,
  },
  {
    path: "/deeplink/invest-introduction",
    name: "Deeplink Invest Introduction",
    component: InvestIntroduction,
    exact: true,
  },
  {
    path: "/risk-assessment/:from",
    name: "Deeplink Risk Assessment",
    component: RiskAssessment,
    exact: true,
  },
];

export default routes;
