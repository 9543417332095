import React, { useEffect, useState } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { connect } from "react-redux";

import { CustomButton } from "../../components";
import { icons, images } from "../../constants";
import { clearSMSOtp } from "../redux/action";
import styles from "./styles";

const WithdrawalSuccess = props => {
  const { withdrawalResult, history, classes, clearSMSOtp } = props;

  const [withdrawalData, setWithdrawalData] = useState({
    date: "",
    time: "",
    transactionId: "",
    refNo: "",
    amount: "",
    fee: ""
  });

  useEffect(() => {
    localStorage.removeItem("timer");
    clearSMSOtp();
  }, []);

  useEffect(() => {
    if (withdrawalResult) {
      setWithdrawalData(prevState => ({
        ...prevState,
        date: moment(withdrawalResult.date)
          .utcOffset("+0800")
          .format("DD MMM YYYY hh:mm:ss")
          .slice(0, 11),
        time: moment(withdrawalResult.date)
          .utcOffset("+0800")
          .format("DD MMM YYYY hh:mm:ss A")
          .slice(12, 24),
        transactionId: withdrawalResult.transactionId,
        refNo: withdrawalResult.refNo,
        amount: withdrawalResult.amount,
        fee: withdrawalResult.fee
      }));
    }
  }, [withdrawalResult]);

  return (
    <div className={classes.container}>
      {/* logo */}
      <div style={{ marginTop: "16px", marginLeft: "16px" }}>
        <img src={images.principalLogo} alt="principal-logo" />
      </div>

      {/* Submitted text */}
      <div style={{ margin: "24px 16px" }}>
        <Typography
          color="textPrimary"
          className={`${classes.baseFont}`}
          style={{
            fontSize: "20px",
            fontWeight: "700"
          }}
        >
          Cash Out submitted for processing...
        </Typography>
        <Typography
          color="textPrimary"
          className={classes.baseFont}
          style={{
            padding: "16px 0 24px",
            borderBottom: "1px solid #9d9ea4"
          }}
        >
          Your Cash Out request will be processed within 2 business days (T+2)
          for the Save feature and 7 business days (T+7) for the Invest
          feature.
        </Typography>
      </div>

      {/* Submitted details */}
      <div style={{ margin: "0 16px" }}>
        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Cash Out Amount
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            RM{" "}
            <NumberFormat
              value={withdrawalData.amount}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Bank Transaction Fee
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            RM{" "}
            <NumberFormat
              value={withdrawalData.fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Cash Out Via
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            FPX Online Banking
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Date
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.date}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Time
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.time}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Reference No
          </Typography>
          <Typography
            color="textPrimary"
            className={classes.baseFont}
            style={{ textAlign: "right" }}
          >
            {withdrawalData.refNo}
          </Typography>
        </div>

        {/* <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Transaction No.
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            1234567890
          </Typography>
        </div> */}

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginBottom: "8px" }}
        >
          <Typography color="textPrimary" className={classes.baseFont}>
            Transaction ID
          </Typography>
          <Typography color="textPrimary" className={classes.baseFont}>
            {withdrawalData.transactionId}
          </Typography>
        </div>

        <div
          className={classes.row}
          style={{ justifyContent: "space-between", marginTop: "16px" }}
        >
          <Typography
            className={classes.baseFont}
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: 1.4,
              color: "#084c95"
            }}
          >
            Total:
          </Typography>
          <Typography
            className={classes.baseFont}
            style={{
              fontSize: "20px",
              fontWeight: "700",
              lineHeight: 1.4,
              color: "#084c95"
            }}
          >
            RM{" "}
            <NumberFormat
              value={withdrawalData.amount - withdrawalData.fee}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </Typography>
        </div>
      </div>

      {/* Withdrawal info */}
      {/* <div style={{ margin: "24px 16px" }}>
        <Typography className={classes.baseFont}>
          Cash Out will take up to 3 business days to be processed. Final fund
          value will be based on NAV selling price of the day you Cash Out.
        </Typography>
      </div> */}

      {/* Button */}
      <div className={classes.btn}>
        <CustomButton
          text="Done"
          variant="contained"
          color="#fff"
          backgroundColor="#1278cc"
          onClick={() => history.push("/dashboard")}
        />
      </div>
    </div>
  );
};

const withConnect = connect(
  state => ({
    withdrawalResult: state.goal.withdrawalResult
  }),
  { clearSMSOtp }
);

export default compose(
  withRouter,
  withConnect,
  withStyles(styles)
)(WithdrawalSuccess);
