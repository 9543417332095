import React from "react";
import { Typography } from "@material-ui/core";

import { icons } from "../../constants";
import styles from "./styles";

const ProfileMenu = ({ title, link, onClick }) => {
  return (
    <div
      onClick={() => {
        !link
          ? onClick()
          : window.my.navigateTo({
              url: "/pages/link/link?url=" + encodeURIComponent(link)
            });
      }}
      style={styles.menu}
    >
      <Typography color="textPrimary" style={styles.title}>
        {title}
      </Typography>
      <img src={icons.arrowRight} alt="arrow-right" />
    </div>
  );
};

export default ProfileMenu;
