import React from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import "./styles.scss";

const StepNumber = ({ onClick, text, classes, gtm }) => {
  return (
    <div className="container">
      <div className={classes.row}>
        <Icon
          className={`material-icons-outlined ${classes.icon}`}
          onClick={onClick}
        >
          arrow_back
        </Icon>
        <Typography color="secondary" className={classes.text}>
          {text}
        </Typography>
      </div>
      <div className={classes.row}>
        <Icon
          className="material-icons-outlined"
          color="primary"
          style={{ fontSize: "20px" }}
        >
          info
        </Icon>
        <Typography
          color="primary"
          className={`${classes.baseFont} ${classes.fundInfo}`}
          onClick={() => {
            if (gtm) {
              dataLayerGTM("click_tag", "Click", "Button", gtm);
            }
            window.my.navigateTo({
              url:
                "/pages/link/link?url=" +
                encodeURIComponent(
                  process.env.REACT_APP_ENV.trim() === "production"
                    ? "https://www.principal.com.my/en/immfd/mv"
                    : "https://www.test.principal.com.my/en/immfd/mv"
                ),
            });
          }}
        >
          Fund info
        </Typography>
      </div>
    </div>
  );
};

export default compose(withStyles(styles))(StepNumber);
