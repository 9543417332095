import React from "react";
import { icons } from "../../constants";

import "./style.scss";

export default function PromoCard({
  promoName,
  minCashIn,
  tNc,
  activePromo,
  onClick,
}) {
  return (
    <div className="promo-card">
      <div onClick={onClick}>
        <div className="promo-card__topbar-title">
          <p>{promoName}</p>
          {activePromo && <img src={icons.check} alt="check" />}
        </div>

        <p>Minimum Cash In RM{minCashIn}</p>
      </div>
      <p
        className="promo-card__tnc"
        onClick={() =>
          window.my.navigateTo({
            url: "/pages/link/link?url=" + encodeURIComponent(tNc),
          })
        }
      >
        Term & Conditions
      </p>
    </div>
  );
}
