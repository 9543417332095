import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";
import "./styles.scss";

const GoalItem = ({ onClick, name, image, classes }) => {
  return (
    <Grid item xs={6} onClick={onClick} className={classes.grid}>
      <img src={image} alt={name} className="goal-image" />
      <Typography color="textPrimary" className={classes.goalLabel}>
        {name}
      </Typography>
    </Grid>
  );
};

export default compose(withStyles(styles))(GoalItem);
