const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  titleContainer: { padding: "24px 16px 0px" },
  title: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4, //28px
    color: "#000"
  },
  descContainer: { padding: "8px 16px 16px" },
  description: {
    fontSize: "16px",
    color: "#333"
  },
  link: { marginTop: "16px" },
  actionContainer: { padding: "0px 16px 24px", margin: 0 },
  cancel: {
    color: "#0076CF",
    marginTop: "16px",
    padding: 0,
    textTransform: "capitalize",
    lineHeight: "20px"
  },
  descContainerAccountBox: {
    flex: "1 1 auto",
    padding: "0 16px 24px",
    overflowY: "auto"
  },
  titleDDAContainer: {
    paddingBottom: "16px"
  }
};

export default styles;
