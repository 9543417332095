import * as DOMPurify from "dompurify";

export function formatCampaignContent(text) {
  const title = "Special Raya Offer!";
  const firstMessage =
    "Earn up to 4.88%* p.a. when you Cash in and maintain RM500 - RM1,000.";
  const secondMessage =
    "In addition, get extra 4.88%* p.a. for 90 days when you activate monthly Auto Cash In.";
  const injectedText = "extra";

  text = text
    .replace(title, `<h3 class="campaign-title">${title}</h3>`)
    .replace(
      firstMessage,
      `<ul class="campaign-messages"><li>${firstMessage}</li>`
    )
    .replace(secondMessage, `<li>${secondMessage}</li></ul>`)
    .replace(
      injectedText,
      `<span class='campaign-content-v3'>${injectedText}</span>`
    );

  const pattern = new RegExp(/\b\d+(?:%|percent\b)/g);
  const pattern2 = /\d+(?:%|percent)[*][p.a]+/g;
  const pattern3 = /\d+(?:%|percent)[*]+/g;
  const pattern4 = /\d*\.\d*(?:%|percent)[*][p.a]+/g;
  const pattern5 = /\d*\.\d*(?:%|percent)[*]+/g;
  const pattern6 = new RegExp(/\d*\.\d*(?:%|percent\b)/g);

  let paternRemove = /[a-z][.][a-z][.]|[a-z][.][a-z]/;
  const arrText = text.split(" ");
  let matchedPattern = text.match(pattern);
  let matchedPattern2 = text.match(pattern2);
  let matchedPattern3 = text.match(pattern3);
  let matchedPattern4 = text.match(pattern4);
  let matchedPattern5 = text.match(pattern5);
  let matchedPattern6 = text.match(pattern6);

  if (matchedPattern === null || !matchedPattern) {
    for (let y = 0; y < arrText.length; y++) {
      if (arrText[y] === "p.a") {
        arrText[y] = `<span class="campaign-content">p.a</span>`;
      }
    }
    return arrText.join(" ");
  }

  if (matchedPattern2 !== null && matchedPattern2.length > 0) {
    for (let j = 0; j < arrText.length; j++) {
      for (let k = 0; k < matchedPattern2.length; k++) {
        if (arrText[j] === matchedPattern2[k]) {
          arrText[j] = `<span class="campaign-content-v2">${matchedPattern2[
            k
          ].replace(
            /[p.a]+/,
            ""
          )}<span class="campaign-content-pattern-2">p.a.</span></span>`;
        }
      }
    }
  }

  if (matchedPattern3 !== null && matchedPattern3.length > 0) {
    for (let l = 0; l < arrText.length; l++) {
      for (let m = 0; m < matchedPattern3.length; m++) {
        if (arrText[l] === matchedPattern3[m]) {
          arrText[l] = `<span class="campaign-content-v2">${
            matchedPattern3[m]
          }</span>`;
        }
      }
    }
  }

  if (matchedPattern4 !== null && matchedPattern4.length > 0) {
    for (let n = 0; n < arrText.length; n++) {
      for (let o = 0; o < matchedPattern4.length; o++) {
        if (arrText[n] === matchedPattern4[o]) {
          let display = `<span class="campaign-content-v2">${
            matchedPattern4[o]
          }</span>`;
          let removePA = matchedPattern4[o].match(paternRemove);
          if (removePA.length > 0) {
            display = `<span class="campaign-content-v2">${matchedPattern4[
              o
            ].replace(
              paternRemove,
              ""
            )}<span class="campaign-content-pattern-2">p.a.</span></span>`;
          }

          arrText[n] = display;
        }
      }
    }
  }

  if (matchedPattern5 !== null && matchedPattern5.length > 0) {
    for (let p = 0; p < arrText.length; p++) {
      for (let q = 0; q < matchedPattern3.length; q++) {
        if (arrText[p] === matchedPattern5[q]) {
          arrText[p] = `<span class="campaign-content-v2">${
            matchedPattern5[q]
          }</span>`;
        }
      }
    }
  }

  for (let i = 0; i < arrText.length; i++) {
    if (arrText[i] === "p.a" || arrText[i] === "p.a.") {
      arrText[i] = `<span class="campaign-content">${arrText[i]}</span>`;
    }
    for (let x = 0; x < matchedPattern.length; x++) {
      if (arrText[i] === matchedPattern[x]) {
        arrText[i] = `<span class="campaign-content-v2">${
          matchedPattern[x]
        }</span>`;
      }
    }
    if (matchedPattern6 !== null && matchedPattern6.length > 0) {
      for (let x1 = 0; x1 < matchedPattern6.length; x1++) {
        if (arrText[i] === matchedPattern6[x1]) {
          arrText[i] = `<span class="campaign-content-v2">${
            matchedPattern6[x1]
          }</span>`;
        }
      }
    }
  }

  return arrText.join(" ");
}

/**
 * Safely modified string to inject wrapper around desired words
 * @param {{text: string, regex: RegExpMatchArray | string, classNames: Record<string, string>}} obj
 * @returns Original text with wrapper around the desired word
 */
export function injectHtmlToSpecificSentence({ text, regex, classNames }) {
  return DOMPurify.sanitize(
    text.replace(
      regex,
      match => `<span class=${classNames[match]}>${match}</span>`
    ),
    { ALLOWED_TAGS: ["span"] }
  );
}
