import React, { useEffect, useState, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { compose } from "underscore";
import styles from "./styles";
import { withRouter } from "react-router-dom";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { clearError, updateProfile, resetData } from "../redux/action";
import { ONBOARDING, DEEPLINK } from "../redux/constant";
import { withErrorBoundary } from "../../utils/functions/withErrorBoundary";

import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Icon,
  TextField,
  InputAdornment,
  Checkbox,
} from "@material-ui/core";
import {
  StepLabel,
  CustomInput,
  Dropdown,
  CustomButton,
  ProfileDropdown,
  Alert,
  CountryItem,
  DefaultLoader,
  AlertInfo,
} from "../../components";
import {
  getOccupations,
  getNOBV2,
  getAnnualIncomeV2,
  getSourceOfFundV2,
  getCountryV2,
  getMaritalStatusV2,
  getInvestmentObjective,
} from "./redux/action";

import moment from "moment";

import {
  findValue,
  findOccupation,
  manipulateCountry,
  knowTheGender,
  findAddress,
  findIdentities,
  handleNotExist,
  checkEmptyArray,
  autoPopulateForm,
  validationFromStepOne,
} from "./utils.js";
const stopOccupations = ["23", "91", "90", "62"];
const occupationWhiteList = ["23", "62", "65", "90", "91"];
const searchByName = "name";
const searchByDescription = "description";
const employerNameFormat = /^[a-zA-Z. ]+$/;

function ProfileFormStepTwo(props) {
  const {
    classes,
    history,
    onboardStepOne = {},
    getOccupations,
    occupationV2List = [],
    getOccupationsLoading,
    profile = {},
    getNOBLoading,
    natureOfBusinessV2List = [],
    getNOBV2,
    getAnnualIncomeV2,
    annualIncomeV2list = [],
    getAnnualIncomeV2Loading,
    getSourceOfFundV2,
    sourceOfFundListV2 = [],
    getSOFLoading,
    getCountryV2,
    getCountryV2Loading,
    countryListV2,
    getMaritalStatusV2,
    maritalStatusV2 = [],
    getMaritalStsLoading,
    getInvestmentObjective,
    investmentObjectiveLoading,
    investmentObjective = [],
    resetData,
    error,
    updateProfile,
    loading,
    location,
  } = props;
  const {
    idNo = "",
    state = {},
    race = {},
    email = "",
    mobileNo = "",
  } = onboardStepOne;

  const [profileInfo, setProfileInfo] = useState({});
  const [isBlank, setIsBlank] = useState(false);
  const [requireInfo, setRequireInfo] = useState({});
  const [PEPalert, setPEPalert] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  const [openDropdown, setOpenDropdown] = useState({
    occupation: false,
    business: false,
    annualIncome: false,
    sourceOfFund: false,
    taxtResident: false,
  });
  const [address, setAddress] = useState({
    city: "",
    country: "",
    line1: "",
    line2: "",
    line3: "",
    postCode: "",
    state: "",
  });
  const [value, setValue] = useState({
    occupation: { name: "", code: "" },
    business: { name: "", code: "" },
    annualIncome: { name: "", code: "" },
    sourceOfFund: { name: "", code: "" },
    investmentObj: { name: "", code: "" },
    blockedNob: false,
    employerName: "",
    employerNameEdited: true,
    isPoliticalPosition: "No",
    otherTaxResident: "No",
    taxCountry: "",
    taxCountrySearch: "",
    selectedTaxCountry: { name: "", code: "" },
    taxIdNo: "",
    countryOfBirth: { name: "", code: "" },
    maritalSts: { name: "", code: "" },
  });
  //const masterIdRef = useRef(null);

  // useEffect(() => {
  // if (profile && profile.masterId) {
  //   if (
  //     masterIdRef.current !== null &&
  //     masterIdRef.current !== profile.masterId
  //   ) {
  //     props.resetData();
  //     localStorage.clear();
  //     history.push("/");
  //   }

  //   masterIdRef.current = profile.masterId;
  // }
  // }, [profile]);

  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf",
    });
  };
  const handleError = (field, value) => {
    setFieldError(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const [fieldError, setFieldError] = useState({
    occupation: false,
    business: false,
    annualIncome: false,
    sourceOfFund: false,
    taxCountry: false,
    taxIdNo: false,
  });

  const {
    occupationTypeCd = "",
    natureOfBusinessCd = "",
    yearlyIncomeLevelCd = "",
    sourceOfFundCd = "",
    countryOfBirth = "",
    dob = "",
    fullName = "",
    gender = "",
    lang = "",
    maritalStatusCd = "",
    masterId = "",
    contacts = [],
    townOfBirth = "",
    identities = [],
    pepFlag = "",
  } = profileInfo;

  useEffect(() => {
    let tempDataStepOne = onboardStepOne;
    if (!profile) {
      resetData();
      localStorage.removeItem("mpUserInfo");
      return history.push("/");
    }
    tempDataStepOne.masterId = profile.masterId;
    let checkStepOne = validationFromStepOne(onboardStepOne);
    if (!checkStepOne) {
      resetData();
      localStorage.removeItem("mpUserInfo");
      return history.push("/");
    }
  }, [onboardStepOne]);

  useEffect(() => {
    if (
      typeof profile === "object" &&
      !Array.isArray(profile) &&
      profile !== null
    ) {
      setProfileInfo(profile);
    }
  }, [profile]);

  useEffect(() => {
    getOccupations();
    getNOBV2();
    getAnnualIncomeV2();
    getSourceOfFundV2();
    getCountryV2();
    getMaritalStatusV2();
    getInvestmentObjective();
  }, []);

  const findCountry = e => {
    let filterCountry = countryListV2.filter(val =>
      val.name.toLowerCase().includes(e.toLowerCase())
    );

    setCountryList(filterCountry);
  };
  const handleSelectTaxCountry = val => {
    const { name, code } = val;
    let tempCountry = { name, code };
    let result = [...countryListV2];
    const index = result.findIndex(e => e.code === code);
    result.unshift(result.splice(index, 1)[0]);

    setCountryList(result);
    setValue({
      ...value,
      selectedTaxCountry: tempCountry,
      taxCountrySearch: "",
    });
    setOpenDropdown({ ...openDropdown, taxtResident: false });
    setFieldError({ ...fieldError, taxCountry: false });
  };

  const handleSelectOccupation = id => {
    let tempOccupation = { name: "", code: "" };
    let blockedNob = false;
    let employerNameEdited = true;
    let occupationItem = {};
    if (Array.isArray(occupationV2List)) {
      occupationItem = occupationV2List.find(item => item.code === id);
    }

    if (occupationItem) {
      tempOccupation = {
        name: occupationItem.name,
        code: occupationItem.code,
      };
    } else {
      setOpenDropdown({ ...openDropdown, occupation: false });

      return;
    }
    if (stopOccupations.includes(id)) {
      blockedNob = true;
    }
    if (occupationWhiteList.includes(id)) {
      employerNameEdited = false;
    }
    setValue({
      ...value,
      occupation: tempOccupation,
      blockedNob,
      employerNameEdited,
    });
    setOpenDropdown({ ...openDropdown, occupation: false });
    setFieldError({ ...fieldError, occupation: false, business: false });
  };

  const handleSelectBusinessNature = id => {
    let tempNob = { name: "", code: "" };
    let nobItem = {};

    if (Array.isArray(natureOfBusinessV2List)) {
      nobItem = natureOfBusinessV2List.find(item => item.code === id);
    }
    if (nobItem) {
      tempNob = { name: nobItem.name, code: nobItem.code };
    } else {
      setOpenDropdown({ ...openDropdown, business: false });

      return;
    }
    setValue({ ...value, business: tempNob });
    setOpenDropdown({ ...openDropdown, business: false });
    setFieldError({ ...fieldError, business: false });
  };

  const handleSelectAnnualIncome = id => {
    let tempAnnualIncome = { name: "", code: "" };
    let annualIncomeItem = {};
    if (Array.isArray(annualIncomeV2list)) {
      annualIncomeItem = annualIncomeV2list.find(item => item.code === id);
    }
    if (annualIncomeItem) {
      tempAnnualIncome = {
        name: annualIncomeItem.description,
        code: annualIncomeItem.code,
      };
    } else {
      setOpenDropdown({ ...openDropdown, annualIncome: false });

      return;
    }
    setValue({ ...value, annualIncome: tempAnnualIncome });
    setOpenDropdown({ ...openDropdown, annualIncome: false });
    setFieldError({ ...fieldError, annualIncome: false });
  };

  const handleSelectSOF = id => {
    let tempSOF = { name: "", code: "" };
    let SOFitem = {};
    if (Array.isArray(sourceOfFundListV2)) {
      SOFitem = sourceOfFundListV2.find(item => item.code === id);
    }
    if (SOFitem) {
      tempSOF = {
        name: SOFitem.description,
        code: SOFitem.code,
      };
    } else {
      setOpenDropdown({ ...openDropdown, sourceOfFund: false });

      return;
    }
    setValue({ ...value, sourceOfFund: tempSOF });
    setOpenDropdown({ ...openDropdown, sourceOfFund: false });
    setFieldError({ ...fieldError, sourceOfFund: false });
  };

  const handleChangeEmployer = value => {
    if (employerNameFormat.test(value) || value === "") {
      setValue(prev => ({ ...prev, employerName: value }));
    }
  };

  const handleChangePEP = val => {
    const { name, value } = val.target;

    setValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeOtherTaxt = val => {
    const { name, value } = val.target;

    setValue(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    let checkCountryList = checkEmptyArray(countryListV2);
    let checkOccupationList = checkEmptyArray(occupationV2List);
    let checkNOBV2List = checkEmptyArray(natureOfBusinessV2List);
    let checkAnnualIncomelist = checkEmptyArray(annualIncomeV2list);
    let checksourceOfFundList = checkEmptyArray(sourceOfFundListV2);
    let checkMaritalStatusList = checkEmptyArray(maritalStatusV2);
    let tempPepFlag = "No";
    let lovResult = autoPopulateForm(
      checkCountryList,
      checkOccupationList,
      checkNOBV2List,
      checkAnnualIncomelist,
      checksourceOfFundList,
      checkMaritalStatusList
    );
    if (!lovResult) return;

    let tempCountry = manipulateCountry(countryListV2);
    let tempOccupation = findOccupation(
      occupationV2List,
      occupationTypeCd,
      searchByName
    );
    let tempNob = findValue(
      natureOfBusinessV2List,
      natureOfBusinessCd,
      searchByName
    );
    let tempAnnualIncome = findValue(
      annualIncomeV2list,
      yearlyIncomeLevelCd,
      searchByDescription
    );
    let tempSourceOfFund = findValue(
      sourceOfFundListV2,
      sourceOfFundCd,
      searchByDescription
    );
    let tempCountryBirth = findValue(
      countryListV2,
      countryOfBirth,
      searchByName
    );
    let tempMaritalSts = findValue(
      maritalStatusV2,
      maritalStatusCd,
      searchByName
    );

    if ("pepFlag" in profileInfo && pepFlag === "1") {
      tempPepFlag = "Yes";
    }

    setValue({
      ...value,
      annualIncome: tempAnnualIncome,
      occupation: { name: tempOccupation.name, code: tempOccupation.code },
      sourceOfFund: {
        name: tempSourceOfFund.name,
        code: tempSourceOfFund.code,
      },
      business: tempOccupation.blockedNob ? { name: "", code: "" } : tempNob,
      blockedNob: tempOccupation.blockedNob,
      employerNameEdited: tempOccupation.employerNameEdited,
      countryOfBirth: tempCountryBirth,
      maritalSts: tempMaritalSts,
      isPoliticalPosition: tempPepFlag,
    });
    setCountryList(tempCountry);
  }, [
    profileInfo,
    occupationV2List,
    natureOfBusinessV2List,
    annualIncomeV2list,
    sourceOfFundListV2,
    countryListV2,
  ]);

  useEffect(() => {
    let tempAddress = findAddress(contacts, state.code);
    setAddress(tempAddress);
  }, [profileInfo, onboardStepOne]);

  const setIdentitiesPayload = () => {
    const identity = findIdentities(identities, idNo);
    let withoutTax = [
      {
        idTypeCd: identity.idTypeCd, //R
        idNo: identity.idNo,
        citizenship: identity.citizenship, //R
      },
    ];
    let withTax = [
      {
        idTypeCd: identity.idTypeCd, //R
        idNo: identity.idNo,
        citizenship: identity.citizenship, //R
      },
      {
        idTypeCd: "TAX",
        idNo: value.taxIdNo,
        citizenship: value.selectedTaxCountry.code,
      },
    ];
    if (value.otherTaxResident.toLocaleLowerCase() === "yes") {
      return withTax;
    } else {
      return withoutTax;
    }
  };

  const requireValidation = val => {
    const tempError = {
      occupation: false,
      business: false,
      income: false,
      sourceOfFund: false,
      investmentObj: false,
      taxCountry: false,
      taxIdNo: false,
    };
    let requireField = {
      occupation: val.occupationTypeCd,
      business: val.natureOfBusinessCd,
      income: val.yearlyIncomeLevelCd,
      sourceOfFund: val.sourceOfFundCd,
      investmentObj: val.investmentObjCd,
    };
    if (value.otherTaxResident.toLocaleLowerCase() === "yes") {
      requireField.taxCountry = val.identities[1].citizenship;
      requireField.taxIdNo = val.identities[1].idNo;
    }

    for (const key in requireField) {
      if (requireField[key].length === 0) {
        tempError[key] = true;
      }
    }
    if (value.otherTaxResident.toLocaleLowerCase() === "yes") {
      if (requireField.taxIdNo.length >= 20) {
        tempError.taxIdNo = true;
      }
    }
    setFieldError(prevState => ({
      ...prevState,
      occupation: tempError.occupation,
      business: tempError.business,
      annualIncome: tempError.income,
      sourceOfFund: tempError.sourceOfFund,
      taxCountry: tempError.taxCountry,
      taxIdNo: tempError.taxIdNo,
    }));
    let requireInfoTemp = {};
    if (tempError.occupation) {
      requireInfoTemp.occupation = "Occupation";
    }
    if (tempError.business) {
      requireInfoTemp.natureOfBusiness = "Nature of business";
    }

    if (tempError.income) {
      requireInfoTemp.annualIncome = "Annual Income";
    }

    if (tempError.sourceOfFund) {
      requireInfoTemp.sourceOfFund = "Source Of Funds";
    }

    if (tempError.investmentObj) {
      requireInfoTemp.purposeOfInvestment = "Purpose Of Invesment";
    }

    if (tempError.taxCountry) {
      requireInfoTemp.taxCountry = "Tax Country";
    }

    if (tempError.taxIdNo) {
      requireInfoTemp.taxIdNo = "Tax Id No";
    }
    setRequireInfo(requireInfoTemp);

    for (const key in tempError) {
      if (tempError[key] === true) {
        return false;
      }
    }

    return true;
  };

  const handleSubmit = () => {
    if (value.isPoliticalPosition.toLocaleLowerCase() !== "no") {
      setPEPalert(true);
      return;
    }
    let payload = {
      natureOfBusinessCd: value.business.code,
      occupationTypeCd: value.occupation.code,
      yearlyIncomeLevelCd: value.annualIncome.code,
      sourceOfFundCd: value.sourceOfFund.code,
      investmentObjCd: value.investmentObj.code,
      identities: setIdentitiesPayload(),
      countryOfBirth: value.countryOfBirth.code,
      dob: dob ? moment(dob).format("YYYY-MM-DD") : "",
      employerName: value.employerName === "" ? "-" : value.employerName,
      fullName,
      gender: knowTheGender(gender),
      lang,
      maritalStatusCd: value.maritalSts.code,
      masterId,
      mobileNo,
      race: race.code,
      email,
      address: address,
      townOfBirth: handleNotExist(townOfBirth),
    };

    if (value.blockedNob) {
      payload.natureOfBusinessCd = "99";
    }

    let validation = requireValidation(payload);
    if (!validation) {
      setIsBlank(true);
      return;
    }

    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      `Click Onboarding Submit Step 2`
    );

    if (location.state) {
      const { type = "NOTSET", code = "NOTSET" } = location.state;
      updateProfile({
        payloadData: payload,
        from: { from: DEEPLINK, value: { type, code } },
      });
    } else {
      updateProfile({ payloadData: payload, from: ONBOARDING });
    }
  };

  if (
    getOccupationsLoading ||
    getNOBLoading ||
    getAnnualIncomeV2Loading ||
    getSOFLoading ||
    getCountryV2Loading ||
    getMaritalStsLoading ||
    investmentObjectiveLoading ||
    loading
  ) {
    return <DefaultLoader />;
  }

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Alert
        title="Please fill in required fields"
        isObject={requireInfo}
        isOpen={isBlank}
        isCancel={false}
        handleAction={() => setIsBlank(false)}
      />
      <Alert
        title="Account doesn’t meet requirement"
        description="So sorry, your account doesn’t meet our requirement."
        isOpen={PEPalert}
        isCS="https://principal.com.my"
        isCancel={false}
        handleAction={() => {
          resetData();
          history.push("/");
          setPEPalert(false);
        }}
      />
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={error === "timeoutError"}
        isTimeout={true}
        handleAction={() => {
          clearError();
          resetData();
          history.push("/");
        }}
      />
      <StepLabel
        title="Profile info"
        containerStyles={{ padding: "16px 16px 22px" }}
        onClick={() => {
          history.push("/onBoarding/form-step-one");
        }}
      />

      <div className={classes.fieldGroupStepOB}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFontStepOB, fontWeight: "700" }}
        >
          Step 2 of 2
        </Typography>
      </div>
      <div className={classes.fieldGroup}>
        <Typography
          color="secondary"
          style={{ ...styles.baseFont, fontWeight: "700" }}
        >
          Employment information
        </Typography>
      </div>
      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Occupation"
          name="occupation"
          value={value.occupation.name}
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          onClick={() => setOpenDropdown({ ...openDropdown, occupation: true })}
          error={fieldError.occupation}
          helperText={"This field is required"}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
          isOpen={openDropdown.occupation}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, occupation: false })
          }
        >
          <ProfileDropdown
            title="Occupation"
            itemList={occupationV2List}
            selectedItem={value.occupation.code}
            handleClick={handleSelectOccupation}
          />
        </Dropdown>
        <CustomInput
          label="Nature of business"
          name="business"
          value={value.blockedNob ? "Not Applicable" : value.business.name}
          isEdit={!value.blockedNob}
          isRequired={true}
          isDropdown={true}
          helperText={"This field is required"}
          onClick={() => {
            setOpenDropdown({ ...openDropdown, business: !value.blockedNob });
          }}
          error={fieldError.business}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
          isOpen={openDropdown.business}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, business: false })
          }
        >
          <ProfileDropdown
            title="Business nature"
            itemList={
              !Array.isArray(natureOfBusinessV2List) ||
              natureOfBusinessV2List === null ||
              !natureOfBusinessV2List
                ? []
                : natureOfBusinessV2List.filter(item => item.code !== "99")
            }
            selectedItem={value.blockedNob ? "99" : value.business.code}
            handleClick={handleSelectBusinessNature}
          />
        </Dropdown>
        <CustomInput
          label="Annual income"
          name="income"
          value={value.annualIncome.name}
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          helperText={"This field is required"}
          onClick={() =>
            setOpenDropdown({ ...openDropdown, annualIncome: true })
          }
          error={fieldError.annualIncome}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight * 0.6}
          isOpen={openDropdown.annualIncome}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, annualIncome: false })
          }
        >
          <ProfileDropdown
            title="Annual income"
            itemList={annualIncomeV2list}
            selectedItem={value.annualIncome.code}
            handleClick={handleSelectAnnualIncome}
          />
        </Dropdown>
        <CustomInput
          label="Employer name"
          name="employerName"
          value={value.employerName}
          onChange={e => {
            handleChangeEmployer(e.target.value);
          }}
          isEdit={value.employerNameEdited}
          isRequired={false}
        />
      </div>
      <div style={{ marginTop: "38px" }} className={classes.fieldGroup}>
        <Typography
          color="secondary"
          style={{ fontWeight: "700" }}
          className={classes.baseFont}
        >
          Other information
        </Typography>
      </div>

      <div style={{ margin: "0 16px" }}>
        <CustomInput
          label="Source of Funds"
          name="sourceOfFund"
          value={value.sourceOfFund.name}
          isEdit={true}
          isRequired={true}
          isDropdown={true}
          helperText={"This field is required"}
          onClick={() =>
            setOpenDropdown({ ...openDropdown, sourceOfFund: true })
          }
          error={fieldError.sourceOfFund}
        />
        <Dropdown
          bottomHeight={({ maxHeight }) => maxHeight * 0.6}
          isOpen={openDropdown.sourceOfFund}
          handleDismiss={() =>
            setOpenDropdown({ ...openDropdown, sourceOfFund: false })
          }
        >
          <ProfileDropdown
            title="Source Of Funds"
            itemList={sourceOfFundListV2}
            selectedItem={value.sourceOfFund.code}
            handleClick={handleSelectSOF}
          />
        </Dropdown>
        <Typography
          color="textPrimary"
          className={`${classes.baseFont} ${classes.label}`}
          style={{ marginTop: "14px" }}
        >
          What is the purpose of your investment?*
        </Typography>
        <FormControl>
          <RadioGroup
            className={` ${classes.radio}`}
            name="cashPurpose"
            value={value.investmentObj.code}
            onChange={e =>
              setValue({
                ...value,
                investmentObj: { name: "", code: e.target.value },
              })
            }
          >
            {investmentObjective
              .sort((a, b) => a.code - b.code)
              .map(({ code, name }) => (
                <FormControlLabel
                  key={code}
                  value={code}
                  control={<Radio color="primary" />}
                  label={
                    <Typography className={`${classes.baseFont}`}>
                      {name}
                    </Typography>
                  }
                />
              ))}
          </RadioGroup>
        </FormControl>
        <CustomInput
          label="Do you hold a position (or related to such persons) in any Public or Political offices, Including being its committee or council member"
          name="isPoliticalPosition"
          value={value.isPoliticalPosition}
          onChange={handleChangePEP}
          isEdit={true}
          isRequired={false}
          isRadio={true}
        />
        <CustomInput
          label="Are you a tax resident of any country other than Malaysia?"
          name="otherTaxResident"
          value={value.otherTaxResident}
          onChange={handleChangeOtherTaxt}
          isEdit={true}
          isRequired={false}
          isRadio={true}
        />
        {value.otherTaxResident.toLocaleLowerCase() === "yes" && (
          <>
            <CustomInput
              label="Tax Country"
              name="taxCountry"
              placeholder="Please select a country"
              value={value.selectedTaxCountry.name}
              onClick={() =>
                setOpenDropdown({ ...openDropdown, taxtResident: true })
              }
              isEdit={true}
              isRequired={true}
              isDropdown={true}
              error={fieldError.taxCountry}
            />

            <Dropdown
              bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
              isOpen={openDropdown.taxtResident}
              handleDismiss={() =>
                setOpenDropdown({ ...openDropdown, taxtResident: false })
              }
            >
              <div style={{ margin: "16px 16px 24px" }}>
                <Typography
                  color="secondary"
                  style={{ ...styles.baseFont, ...styles.dropdownTitle }}
                >
                  Update country
                </Typography>
                {/* Search */}
                <div style={{ marginTop: "16px" }}>
                  <TextField
                    placeholder="Type country name here"
                    variant="outlined"
                    fullWidth
                    value={value.taxCountrySearch}
                    onChange={e => {
                      setValue({ ...value, taxCountrySearch: e.target.value });
                      findCountry(e.target.value);
                    }}
                    inputProps={{
                      style: {
                        ...styles.baseFont,
                        padding: "12px 16px",
                        color: "#333",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <Icon
                            className="material-icons-outlined"
                            style={{ cursor: "pointer", color: "#0091DA" }}
                          >
                            search
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* Country List */}
                <div style={{ marginTop: "24px" }}>
                  {countryList.map((item, index) => {
                    return (
                      <CountryItem
                        key={index}
                        country={item.name}
                        isSelected={value.selectedTaxCountry.code === item.code}
                        onClick={() => handleSelectTaxCountry(item)}
                        containerStyles={
                          value.selectedTaxCountry.code &&
                          index === 0 && {
                            borderBottom: "1px solid #C4C6C5",
                            paddingBottom: "24px",
                          }
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </Dropdown>

            <CustomInput
              label="Tax ID Number"
              name="taxIdNo"
              value={value.taxIdNo}
              onChange={e => setValue({ ...value, taxIdNo: e.target.value })}
              handleBlur={() => {
                handleError(
                  "taxIdNo",
                  value.taxIdNo.length <= 20 ? false : true
                );
              }}
              isEdit={true}
              isRequired={true}
              error={fieldError.taxIdNo}
              helperText={
                value.taxIdNo.length >= 20
                  ? "Maximum length for tax id number is 20"
                  : "This field is required"
              }
            />
          </>
        )}
      </div>
      <div style={{ margin: "24px 16px" }}>
        <div className={classes.termCondition}>
          <Checkbox
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            color="primary"
            style={{ margin: 0, padding: 0 }}
          />
          <div style={{ marginLeft: "10px" }}>
            <Typography
              style={{
                fontFamily: "FSElliotPro",
                fontSize: "14px",
                fontWeight: "normal",
                lineHeight: 1.29,
                color: "#787878",
                marginBottom: "8px",
              }}
            >
              I have read and agreed to{" "}
              <span
                style={{ color: "#0076cf" }}
                onClick={() =>
                  handleOpenPDF(
                    "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf"
                  )
                }
              >
                Principal's Terms & Conditions
              </span>{" "}
              and{" "}
              <span
                style={{ color: "#0076cf" }}
                onClick={() =>
                  window.my.navigateTo({
                    url:
                      "/pages/link/link?url=" +
                      encodeURIComponent(
                        "https://www.principal.com.my/en/privacy-notice-my/mv"
                      ),
                  })
                }
              >
                Privacy Notice
              </span>
            </Typography>
            <Typography
              color="secondary"
              style={{
                fontSize: "14px",
                lineHeight: 1.57,
                color: "#505050",
              }}
              className={classes.baseFont}
            >
              For any information that cannot be edited within the app, kindly
              contact our Customer Care Centre for assistance.
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.btnStep1}>
        <CustomButton
          text="Next"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!isChecked ? true : false}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.onBoardingReducer,
    profile: state.goal.profile,
    loading: state.goal.loading,
    error: state.goal.error,
  };
};
const mapDispatchToProps = dispatch => ({
  getNOBV2: () => dispatch(getNOBV2()),
  getOccupations: () => dispatch(getOccupations()),
  getAnnualIncomeV2: () => dispatch(getAnnualIncomeV2()),
  getCountryV2: () => dispatch(getCountryV2()),
  getSourceOfFundV2: () => dispatch(getSourceOfFundV2()),
  getMaritalStatusV2: () => dispatch(getMaritalStatusV2()),
  getInvestmentObjective: () => dispatch(getInvestmentObjective()),
  updateProfile: data => dispatch(updateProfile(data)),
  resetData: () => dispatch(resetData()),
});
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withStyles(styles),
  withErrorBoundary,
  withRouter,
  withConnect
)(ProfileFormStepTwo);
