import moment from "moment";

export const groupTransactionHistoryByMonth = transactions => {
  const groupedTrxByMonth = [];

  for (const transaction of transactions) {
    const date = moment(
      transaction.submittedDate ? transaction.submittedDate : transaction.completedDate
    ).utcOffset("+0800").format("DD MMM YYYY");
    const month = moment(
      transaction.submittedDate ? transaction.submittedDate : transaction.completedDate
    ).format("MMMM");
    const year = new Date(
      transaction.submittedDate ? transaction.submittedDate : transaction.completedDate
    ).getFullYear();
    const id = groupedTrxByMonth.length + 1;

    if (transaction.status === "1" || transaction.status === "2") {
      continue;
    }

    if (transaction.status === "9") {
      if (
        (transaction.processingDate === null || transaction.completedDate === null) &&
        transaction.type !== "CoolOff"
      ) {
        continue;
      }
    }

    const found = groupedTrxByMonth.some(
      value => value.month === month && value.year === year
    );
    if (found) {
      const index = groupedTrxByMonth.findIndex(
        value => value.month === month && value.year === year
      );

      const foundDate = groupedTrxByMonth[index].data.some(
        value => value.date === date
      );

      if (foundDate) {
        const dateIndex = groupedTrxByMonth[index].data.findIndex(
          value => value.date === date
        );

        groupedTrxByMonth[index].data[dateIndex].data = [
          ...groupedTrxByMonth[index].data[dateIndex].data,
          transaction
        ];
      } else {
        groupedTrxByMonth[index].data = [
          ...groupedTrxByMonth[index].data,
          { date: date, data: [transaction] }
        ];
      }
    } else {
      groupedTrxByMonth.push({
        id: id,
        month: month,
        year: year,
        data: [
          {
            date: date,
            data: [transaction]
          }
        ]
      });
    }
  }

  return groupedTrxByMonth;
};
