const styles = {
  column: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    color: "#333333"
  },
  container: {
    flex: 1,
    minHeight: "100vh",
    backgroundColor: "#f6f6f8"
  },
  image: {
    justifyContent: "center",
    margin: "16px"
  },
  leading: {
    alignItems: "flex-start",
    margin: "0 16px",
    paddingBottom: "24px"
  },
  leadingTitle: {
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4
  },
  actionItem: {
    backgroundColor: "#fff",
    padding: "16px",
    borderRadius: "4px",
    boxShadow: "0 12px 24px -10px #d8d8d8",
    marginBottom: "8px"
  },
  actionItemContent: {
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "22px",
    marginLeft: "16px"
  },
  bottomNav: {
    position: "sticky",
    bottom: 0,
    width: "100%",
    display: "block",
    marginTop: "auto"
  },
  showMore: {
    cursor: "pointer",
    color: "#0076CF"
  },
  bold: {
    fontWeight: "bold"
  }
};

export default styles;
