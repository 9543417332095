import moment from "moment";
import React from "react";

import { DoneIcon, FailedIcon, WarningIcon } from "./StepIcons";

function formatUtcOffset(date) {
  if (typeof date !== "string") {
    return date;
  }

  const formatted = moment(date)
    .utcOffset("+0800")
    .format("DD MMM YYYY");

  return formatted;
}

function getTransactionType(type) {
  let transactionType = "";

  switch (type) {
    case "Buy":
      transactionType = "Cash In";
      break;
    case "RSPBuy":
      transactionType = "Auto Cash In";
      break;
    case "Dividend":
      transactionType = "Income Distribution";
      break;
    case "Bonus":
      transactionType = "Bonus Distribution";
      break;
    case "CoolOff":
      transactionType = "Cooling Off";
      break;
    case "Campaign":
      transactionType = "Campaign Return";
      break;
    case "TransferIn":
      transactionType = "Switch In";
      break;
    case "TransferOut":
      transactionType = "Switch Out";
      break;
    case "SwitchIn":
      transactionType = "Switch In";
      break;
    case "SwitchOut":
      transactionType = "Switch Out";
      break;
    case "SwitchAll":
      transactionType = "Switch Out";
      break;
    default:
      transactionType = "Cash out";
      break;
  }
  return transactionType;
}

function getStatus(status) {
  let result;
  switch (status) {
    case "3":
    case "6":
      result = "Submitted";
      break;
    case "4":
      result = "Processing";
      break;
    case "5":
    case "10":
      result = "Completed";
      break;
    case "9":
    case "11":
      result = "Failed";
      break;
    default:
      result = "";
      break;
  }

  return result;
}

function decideAmount({
  transactionType,
  details,
  amount,
  fee,
  actualAmount,
  cashOutamtSell,
  status,
}) {
  let result;
  const isSwitch =
    transactionType === "SwitchIn" ||
    transactionType === "SwitchOut" ||
    transactionType === "SwitchAll";
  const isBuy = transactionType === "Buy";
  const iRSPBuy = transactionType === "RSPBuy";
  const isDividendOrBonusOrCoolOff =
    transactionType === "Dividend" ||
    transactionType === "Bonus" ||
    transactionType === "CoolOff";
  const isCashout = transactionType === "Sell" || transactionType === "ManSell";
  const isFullsale = transactionType === "FullSell";
  if (isSwitch) {
    return amount;
  }
  if (isBuy) {
    result = amount;
  } else if (isDividendOrBonusOrCoolOff) {
    result = amount;
  } else if (details.length > 0 && details[0].adjustments.length > 0) {
    result = details[0].adjustmentAmount - fee;
  } else if (iRSPBuy) {
    result = amount + fee;
  } else if (isCashout) {
    result = cashOutamtSell - fee;
  } else if (isFullsale) {
    result = status !== "10" ? amount - fee : amount;
  } else {
    result = amount;
  }

  return result;
}

function decideTransactionMethod({ transactionType, method }) {
  let result;
  switch (true) {
    case (transactionType === "Sell" &&
      (method === "FPX" || method === "BankAccount")) ||
      method === "FPX" ||
      transactionType === "RSPBuy":
      result = "Online Banking";
      break;
    case method === "TnG":
      result = "Touch 'n Go eWallet";
      break;
    default:
      result = method;
      break;
  }
  return result;
}

function getStatusSellCollOff({ status, isCashout }) {
  let result;
  switch (status) {
    case "3":
    case "6":
      result = "Submitted";
      break;
    case "4":
    case "5":
      result = "Processing";
      break;
    case "10":
      result = "Completed";
      break;
    case "9":
    case "11":
      if (isCashout && status === "11") {
        result = "Pending Payment";
      } else {
        result = "Failed";
      }
      break;
    default:
      result = "";
      break;
  }

  return result;
}

const getCompletedRelated = ({ status, isDoneIconActive }) => {
  switch (status) {
    case "9":
      return {
        icon: <FailedIcon active />,
        text: "Failed",
      };
    case "11":
      return {
        icon: <WarningIcon active />,
        text: "Pending Payment",
      };
    default:
      return {
        icon: <DoneIcon active={isDoneIconActive} />,
        text: "Completed",
      };
  }
};

const getRefundText = (type, details) => {
  if (!type) {
    return `Switch Out amount have refunded to`;
  }

  const hasSwitchOutWithAdjustments = details.some(item => {
    if (item.type === "SwitchOut" && item.status === "9") {
      return true;
    }
    if (item.type === "SwitchAll" && item.status === "9") {
      return true;
    }
    if (item.type === "SwitchOut" && item.adjustments.length > 0) {
      return true;
    }
    if (item.type === "SwitchAll" && item.adjustments.length > 0) {
      return true;
    }
    return false;
  });

  if (hasSwitchOutWithAdjustments) {
    return `Switch In amount have refunded to`;
  } else {
    return `pending refund to`;
  }
};

const getSuccessText = (status, middleText) => {
  if (status === "9") {
    return `Switch ${middleText} request failed`;
  } else {
    return `Your Switch ${middleText} amount is successful and has been reflected
    in your account.`;
  }
};

const getRefundStatusText = (status, type, details) => {
  let result;
  if (type === "SwitchOut" && status === "9") {
    return "Refund Completed";
  }
  if (type === "SwitchAll" && status === "9") {
    return "Refund Completed";
  }
  if (type === "SwitchIn") {
    if (status === "9") {
      result = details.some(item => {
        if (item.type === "SwitchOut" && item.status === "9") {
          return true;
        }
        if (item.type === "SwitchAll" && item.status === "9") {
          return true;
        }
        if (item.type === "SwitchOut" && item.adjustments.length > 0) {
          return true;
        }
        if (item.type === "SwitchAll" && item.adjustments.length > 0) {
          return true;
        }
        return false;
      });
      if (result) {
        return "Refund Completed";
      } else {
        return "Pending Refund";
      }
    } else {
      return "Pending Refund";
    }
  } else {
    return "";
  }
};

const getRefundResult = (details, status, trxtype) => {
  let result;
  if (status === "9" && !trxtype) {
    result = true;
    return result;
  }
  result = details.some(item => {
    if (item.type === "SwitchOut" && item.status === "9") {
      return true;
    }
    if (item.type === "SwitchAll" && item.status === "9") {
      return true;
    }
    if (item.type === "SwitchOut" && item.adjustments.length > 0) {
      return true;
    }
    if (item.type === "SwitchAll" && item.adjustments.length > 0) {
      return true;
    }
    return false;
  });

  return result;
};

export {
  decideAmount,
  decideTransactionMethod,
  getStatus,
  getTransactionType,
  formatUtcOffset,
  getStatusSellCollOff,
  getCompletedRelated,
  getRefundText,
  getSuccessText,
  getRefundStatusText,
  getRefundResult,
};
