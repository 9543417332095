import React from "react";
import MDSpinner from "react-md-spinner";
import "./styles.scss";

const spinnerDefaultColor = "#1278CC";

const DefaultLoader = props => (
  <div className="loading">
    <MDSpinner singleColor={spinnerDefaultColor} {...props} />
  </div>
);

export default DefaultLoader;
