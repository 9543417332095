import moment from "moment";

const FROM_PROMO = "FROM_PROMO";
const SAVE = "SAVE";

export const usePromoHandlerSave = (
  hasGeneralSaving,
  checkPromoAvailabilityAfterGoalCreation,
  checkSelectedPromo,
  resetSelectedPromo,
  setPromoCode,
  setIsErrorPromo,
  promoCode,
  hasMatchingPromo,
  handleCheckAccountStatus
) => {
  const handlePromoSave = () => {
    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;

    if (!hasGeneralSaving.isGeneralSaving) {
      handleNoGeneralSaving();
      return;
    }

    if (checkSelectedPromo().promo) {
      handleSelectedPromo();
      return;
    }

    if (!promoCode) {
      handleEmptyPromoCode();
      return;
    }

    handleMatchingPromo(promoCode);
  };

  const handleNoGeneralSaving = () => {
    checkPromoAvailabilityAfterGoalCreation();
  };

  const handleSelectedPromo = () => {
    resetSelectedPromo();
    setPromoCode("");
  };

  const handleEmptyPromoCode = () => {
    setIsErrorPromo(true);
  };

  const handleMatchingPromo = code => {
    hasMatchingPromo(code);
  };

  return handlePromoSave;
};

export const useGoalWithPromoHandler = (
  isGeneralSaving,
  getPromo,
  accountNo,
  code,
  handleClickGeneralSaving,
  history,
  handleCheckAccountStatus
) => {
  const handleGoalWithPromo = () => {
    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;

    if (isGeneralSaving) {
      handleGeneralSavingWithPromo();
    } else {
      handleNoGeneralSaving();
    }
  };

  const handleGeneralSavingWithPromo = () => {
    getPromo(
      {
        target: SAVE,
        hierarchyId: accountNo,
        instrumentId: code,
      },
      () => history.push("/promo-list")
    );
  };

  const handleNoGeneralSaving = () => {
    handleClickGeneralSaving(FROM_PROMO);
  };

  return { handleGoalWithPromo };
};

export const useCallPromoAfterCreateGoal = (getPromo, history, code) => {
  const callPromoAfterCreateGoal = cbAccNo => {
    getPromo(
      {
        target: SAVE,
        hierarchyId: cbAccNo,
        instrumentId: code,
      },
      () => history.push("/promo-list")
    );
  };

  return { callPromoAfterCreateGoal };
};

export const usePromoHandlerInvest = (
  checkSelectedPromo,
  resetSelectedPromo,
  setPromoCode,
  setIsErrorPromo,
  promoCode,
  checkPromoAvailabilityAfterGoalCreation,
  handleCheckAccountStatus,
  setRiskProfileExpired,
  riskProfileResult
) => {
  const handlePromoInvest = () => {
    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }
    
    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;

    if (checkSelectedPromo().promo) {
      resetSelectedPromo();
      setPromoCode("");
      return;
    }

    if (!promoCode) {
      handleEmptyPromoCode();
      return;
    }
    handleAvaibilityFund();
  };
  const handleAvaibilityFund = () => {
    checkPromoAvailabilityAfterGoalCreation();
  };

  const handleEmptyPromoCode = () => {
    setIsErrorPromo(true);
  };
  return handlePromoInvest;
};
