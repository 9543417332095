const styles = {
  caption: {
    fontFamily: "FSElliotPro",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: 1.4,
    margin: "12px 16px 24px",
    textAlign: "left"
  }
};

export default styles;
