import React, { useState } from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import { InfoModal } from "../index";
import NumberFormat from "react-number-format";

import styles from "./styles";


const info = {
  "Projected Return Rate": {
    title: "Projected Return Rate",
    message:
      "Projected gross return (excludes management and Trustee fees) by Principal Malaysia for the next 12 months. It may vary depending on market performance."
  },
  "Total Return": {
    title: "Total Return",
    message:
      "Total Return is the sum of your daily return accumulated from the day of your initial Cash In."
  },
  "Investment Cost": {
    title: "Investment Cost",
    message:
      "Investment Cost is the Total cost of your units accumulated from your Cash In. It is the average cost of a unit multiplied with your total units held"
  }
};

const SavingDetailCard = ({
  label,
  detail,
  icon,
  isMoney,
  classes,
  needNumberFormat,
  decimalSet,
  planAlias
}) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  return (
    <div className={classes.container}>
      <InfoModal
        open={isInfoOpen}
        handleClose={() => setIsInfoOpen(false)}
        title={typeof info[label] === "object" && info[label].title}
        message={typeof info[label] === "object" && info[label].message}
      />
      <div className={classes.labelContainer}>
        {icon && <img src={icon} alt="saving-detail" />}
        <Typography
          className={classes.label}
          style={{ marginLeft: icon ? "8px" : 0 }}
        >
          {label}
        </Typography>
      </div>
      {label === "Projected Return Rate" ||
      label === "Total Return" ||
      label === "Investment Cost" ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {!isMoney ? (
            <Typography className={classes.detail}>{detail}</Typography>
          ) : (
            <Typography className={classes.detail}>
              {detail < 0 && "-"}RM{" "}
              <NumberFormat
                value={detail > 0 ? detail : detail * -1}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          )}
          <Icon
            className="material-icons-outlined"
            fontSize="small"
            style={{ cursor: "pointer", color: "#fff" }}
            onClick={() => setIsInfoOpen(true)}
          >
            info
          </Icon>
        </div>
      ) : (
        <>
          {!isMoney ? (
            needNumberFormat ? (
              <Typography className={classes.detail}>
                <NumberFormat
                  value={detail}
                  thousandSeparator
                  displayType="text"
                  decimalScale={decimalSet ? decimalSet : 2}
                  fixedDecimalScale={true}
                />
              </Typography>
            ) : (
              <Typography className={classes.detail}>{detail}</Typography>
            )
          ) : (
            <Typography className={classes.detail}>
              RM{" "}
              <NumberFormat
                value={detail}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default compose(withStyles(styles))(SavingDetailCard);
