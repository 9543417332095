export function checkAcctStsCashIn(code) {
  if (code === "Closed") {
    return false;
  } else if (code === "Suspended") {
    return false;
  } else if (code === "Frozen") {
    return false;
  } else if (code === "Closing") {
    return false;
  } else {
    return true;
  }
}
export function checkAcctStsCashOut(code) {
  if (code === "Closed") {
    return false;
  } else if (code === "Suspended") {
    return true;
  } else if (code === "Frozen") {
    return false;
  } else if (code === "Closing") {
    return true;
  } else {
    return true;
  }
}

export function checkAcctStsRSP(code) {
  if (code === "Closed") {
    return false;
  } else if (code === "Suspended") {
    return false;
  } else if (code === "Frozen") {
    return false;
  } else if (code === "Closing") {
    return false;
  } else {
    return true;
  }
}
