import React, { useEffect, useRef, useState } from "react";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { TextField, InputAdornment, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";
import moment from "moment";
import {
  NumberFormatCustom,
  InputBasic,
  CustomButton,
  DLItemCard,
  DefaultLoader,
  Alert,
  AlertAccount,
} from "../../components";
import {
  createGoalDL,
  getcGoalDL,
  setDeeplinkAmount,
  createGoalDLSuccess,
  clearDLCheckoutErr,
  setLastAmount,
} from "./redux/action";
import {
  getPromo,
  resetSelectedPromo,
  setSelectedPromo,
} from "../promoList/redux/action";
import { getProjectedReturn } from "../getStarted/redux/actions";
import {
  getFundDetail,
  setSelectedFundDetail,
} from "../fundSelection/redux/action";
import { getGoals } from "../redux/action";
import { GENERAL_SAVING, GOAL_CREATION, INVEST, SAVE } from "../redux/constant";
import { getSelectedPromoByCode } from "../../utils/functions/getSelectedPromoByCode";
import { useEcddChecking } from "../../utils/hooks";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../constants/subaccount-status";
import { getSubAccount } from "../goalDetails/redux/actions";
import { createFundV2 } from "../fundSelection/redux/action";
import { getCustomerPortfolio } from "../dashboard/redux/actions";
import { clearInvestCheckoutLoading } from "../fundSelectionStep3/redux/actions";
import { showError } from "../../globalRedux/actions";
import {
  usePromoHandlerSave,
  useGoalWithPromoHandler,
  useCallPromoAfterCreateGoal,
  usePromoHandlerInvest,
} from "./utils";
import styles from "./styles";
import "./styles.scss";

let PAYLOAD_DEEPLINK_CREATE_GOAL = {
  invsPlanId: "120",
  term: "1",
  targetAmount: "0",
  planAlias: "General Savings",
};

const FROM_PROMO = "FROM_PROMO";
const FROM_PROMO_WITHOUT_APPLY = "FROM_PROMO_WITHOUT_APPLY";
function DeepLink(props) {
  const {
    classes,
    match: { params },
    getFundDetail,
    fundDetail = {},
    fundDetailLoading,
    createGoalDL,
    getGoals,
    listGoalLoading,
    goalList,
    createGoalDLLoading,
    deeplinkCgoalList,
    history,
    getcGoalDL,
    profile,
    createGoalDLSuccess,
    setDeeplinkAmount,
    getPromo,
    deeplinkGeneralSaving,
    promoListLoading,
    selectedPromo,
    resetSelectedPromo,
    promoList = [],
    clearDLCheckoutErr,
    setSelectedPromo,
    ecddStatus,
    getSubAccount,
    subaccountDetail,
    createFundV2,
    createFundLoadingV2,
    getCustomerPortfolio,
    loadingCustomerPortfolio,
    clearInvestCheckoutLoading,
    fundResultFromCreating,
    getProjectedReturn,
    getStartedLoading,
    saveProjectedReturnValue,
    customerPortfolio,
    setSelectedFundDetail,
    selectedFundDetail,
    riskProfileResult,
    deeplinkAmount,
    showError,
  } = props;

  const { code = "NOTSET", type = "NOTSET" } = params;
  const alreadySubmit = useRef(false);
  const [accountStatus, setAccountStatus] = useState("");
  const [generalSaving, setGeneralSaving] = useState({});
  const [hasGeneralSaving, setHasGeneralSaving] = useState({
    isGeneralSaving: false,
    withPromo: false,
  });
  const [isErrorPromo, setIsErrorPromo] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [inputValue, setInputValue] = useState({
    cashIn: deeplinkAmount,
    error: false,
    helperText: "Minimum RM 10.00",
  });
  const { handleCheckEcdd, status, onResetStatus } = useEcddChecking({
    ecddStatus,
    aml: profile &&
      profile.amlHistory.length > 0 &&
      typeof profile.amlHistory[0] === "object" && {
        result: profile.amlHistory[0].amlResult,
        status: profile.amlHistory[0].amlSts,
      },
  });
  const [isRiskProfileExpired, setRiskProfileExpired] = useState(false);

  const getPromoByCode = getSelectedPromoByCode(promoList);
  const hasCalledGetSubaccount = React.useRef(false);

  const generalSavingIsExist = val => {
    createGoalDLSuccess(val);
  };

  const checkSelectedPromo = () => {
    return Object.keys(selectedPromo).length === 0
      ? { message: "Apply", styleBtn: true, promo: false }
      : { message: "Cancel", styleBtn: false, promo: selectedPromo };
  };

  const hasMatchingPromo = (promoCodeParams, cbPromoList) => {
    let matchingPromoCode;
    if (cbPromoList && Array.isArray(cbPromoList)) {
      const getPromoByCodeFromCb = getSelectedPromoByCode(cbPromoList);
      matchingPromoCode = getPromoByCodeFromCb(promoCodeParams);
    } else {
      if (Array.isArray(promoList)) {
        matchingPromoCode = getPromoByCode(promoCodeParams);
      }
    }

    if (matchingPromoCode.length > 0) {
      setSelectedPromo(matchingPromoCode[0]);
      return { valid: true, promoMatch: matchingPromoCode[0] };
    } else {
      setIsErrorPromo(true);
      return { valid: false };
    }
  };

  const createFundWithCondition = async () => {
    if (Object.keys(fundResultFromCreating).length > 0) {
      return fundResultFromCreating;
    }

    const tempFundResult = await new Promise(resolve => {
      createFundV2(
        { cifId: profile.masterId, fundsPayload: [{ fundCode: code }] },
        result => {
          resolve(result);
        }
      );
    });
    return tempFundResult;
  };

  /**
   * Checks the availability of a promo code after creating a goal.
   * Because the goal doesn't exist yet, the function ensures that the promo code is in the promo list.
   * The user has to create a goal first and then check whether the promo code is in the promo list.
   * @param {string} promoCode - The promo code to be checked.
   * @param {function} createGoal - The function to create a goal.
   * @param {function} checkPromoList - The function to check the promo list.
   * @returns {boolean} True if the promo code is available after creating the goal, otherwise false.
   */

  const checkPromoAvailabilityAfterGoalCreation = () => {
    PAYLOAD_DEEPLINK_CREATE_GOAL.productId = generalSaving.id;
    createGoalDL(PAYLOAD_DEEPLINK_CREATE_GOAL, cbAccNo => {
      setHasGeneralSaving({ isGeneralSaving: true, withPromo: true });
      getPromo(
        {
          target: SAVE,
          hierarchyId: cbAccNo,
          instrumentId: code,
        },
        cbPromoList => hasMatchingPromo(promoCode, cbPromoList)
      );
    });
  };

  const checkPromoAvailabilityAfterCreateFund = async () => {
    const callCreateFund = await createFundWithCondition();

    const { accountNo } = callCreateFund;
    getPromo(
      {
        target: "INVEST",
        hierarchyId: accountNo,
        instrumentId: code,
      },
      cbPromoList => hasMatchingPromo(promoCode, cbPromoList)
    );
  };

  const handleCheckAccountStatus = () => {
    const headAccountIsObjectAndItsStatusExist =
      "headAccount" in profile &&
      typeof profile.headAccount === "object" &&
      "sts" in profile.headAccount;

    if (
      !hasGeneralSaving.isGeneralSaving &&
      headAccountIsObjectAndItsStatusExist &&
      NOT_ALLOWED_ACCOUNT_STATUS.includes(profile.headAccount.sts)
    ) {
      setAccountStatus(profile.headAccount.sts);
      return "blocked";
    }

    if (NOT_ALLOWED_ACCOUNT_STATUS.includes(subaccountDetail?.sts)) {
      setAccountStatus(subaccountDetail?.sts);
      return "blocked";
    }
  };

  /**
   * Handle promo action.
   * If a promo is selected, it resets the selected promo and clears the promo code.
   * If no promo is selected, it checks if promoCode is empty or not provided and sets an error flag accordingly.
   */

  const handlePromoSave = usePromoHandlerSave(
    hasGeneralSaving,
    checkPromoAvailabilityAfterGoalCreation,
    checkSelectedPromo,
    resetSelectedPromo,
    setPromoCode,
    setIsErrorPromo,
    promoCode,
    hasMatchingPromo,
    handleCheckAccountStatus
  );

  const handlePromoInvests = usePromoHandlerInvest(
    checkSelectedPromo,
    resetSelectedPromo,
    setPromoCode,
    setIsErrorPromo,
    promoCode,
    checkPromoAvailabilityAfterCreateFund,
    handleCheckAccountStatus,
    setRiskProfileExpired,
    riskProfileResult
  );

  useEffect(() => {
    if (hasGeneralSaving.withPromo) return;
    if (
      hasGeneralSaving.isGeneralSaving &&
      Object.keys(deeplinkGeneralSaving).length > 0
    ) {
      getPromo({
        target: SAVE,
        hierarchyId: deeplinkGeneralSaving.accountNo,
        instrumentId: code,
      });
    }
  }, [hasGeneralSaving, deeplinkGeneralSaving]);

  /**
   * Perform necessary actions on component mount.
   * clearDLCheckoutErr() => Clears the error value in the deeplink checkout reducer if the user navigates back from the device without confirming their alert on the summary page.
   */
  useEffect(() => {
    getFundDetail(code);

    clearDLCheckoutErr();
    clearInvestCheckoutLoading();

    if (type.toLowerCase() === "invest") {
      getCustomerPortfolio(funds => {
        const found = funds?.find(({ fundCd }) => code === fundCd);

        if (!found) return;

        setSelectedFundDetail(found);
      });
    } else if (type.toLowerCase() === "save") {
      // goal list
      getGoals();
      // customer goals
      getcGoalDL(profile.masterId);
      getProjectedReturn();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(goalList)) {
      // filtering general saving from goal list for creating goal
      // because need the id
      const generalSavingDetail = goalList.find(
        goal => goal.productType === "Retail"
      );
      setGeneralSaving(generalSavingDetail || {}); // GET /smp/goals
    }
  }, [goalList]);

  useEffect(() => {
    if (Array.isArray(deeplinkCgoalList)) {
      const retailValue = deeplinkCgoalList.find(
        retail => retail.planId === "P9"
      );

      if (retailValue) {
        generalSavingIsExist(retailValue);
      }

      setHasGeneralSaving({ isGeneralSaving: !!retailValue, withPromo: false });
    }
  }, [deeplinkCgoalList]);

  useEffect(() => {
    if (hasCalledGetSubaccount.current) return;

    if (
      deeplinkGeneralSaving &&
      deeplinkGeneralSaving.hasOwnProperty("accountNo") &&
      deeplinkGeneralSaving.accountNo
    ) {
      hasCalledGetSubaccount.current = true;
      getSubAccount(deeplinkGeneralSaving.accountNo);
    } else if (
      (Array.isArray(customerPortfolio) && customerPortfolio.length > 0)
    ) {
      hasCalledGetSubaccount.current = true;
      getSubAccount(customerPortfolio[0].accountNo);
    }
  }, [
    deeplinkGeneralSaving,
    customerPortfolio,
    hasCalledGetSubaccount.current,
  ]);

  useEffect(() => {
    handleCheckAccountStatus();
  }, [subaccountDetail]);

  const checkInputValue = () => {
    if (!inputValue.cashIn || inputValue.cashIn === "0") {
      setInputValue(prevState => ({ ...prevState, error: true }));
      return false;
    }

    if (+inputValue.cashIn > 30000) {
      setInputValue(prevState => ({
        ...prevState,
        error: true,
        helperText:
          "The maximum Cash In amount per transaction is RM30,000. You may proceed with your additional Cash In into multiple transactions.",
      }));
      return false;
    }

    if (
      fundDetail.minSubsAmt &&
      typeof fundDetail.minSubsAmt === "number" &&
      parseInt(inputValue.cashIn) < fundDetail.minSubsAmt
    ) {
      setInputValue(prevState => ({
        ...prevState,
        error: true,
        helperText: `Minimum RM ${(
          Math.round(fundDetail.minSubsAmt * 100) / 100
        ).toFixed(2)}`,
      }));
      return false;
    }

    return true;
  };

  const handleContinueSaveWithPromoWithoutApply = (
    promoMatch,
    promoWithoutApply
  ) => {
    const isPromoSelected = Object.keys(promoMatch).length > 0;
    const { cashIn } = inputValue;
    const minPromoDepo =
      promoMatch &&
      promoMatch.eligibilityRules &&
      promoMatch.eligibilityRules.minimumSingleDeposit;

    if (isPromoSelected && parseInt(cashIn) < parseInt(minPromoDepo)) {
      setInputValue(prevState => ({
        ...prevState,
        error: true,
        helperText: `Minimum campaign cash in RM${minPromoDepo} not met`,
      }));
      return;
    }

    if (promoWithoutApply === FROM_PROMO_WITHOUT_APPLY) {
      history.push(`/deeplink/${type}/${code}/summary/${GOAL_CREATION}`);
      return;
    }

    history.push(`/deeplink/${type}/${code}/summary/${GENERAL_SAVING}`, {
      withPromo: hasGeneralSaving.withPromo,
    });
  };

  const handleContinueSave = () => {
    if (!hasGeneralSaving.isGeneralSaving && promoCode.length === 0) {
      handleClickGeneralSaving();
      return;
    }

    if (
      !hasGeneralSaving.isGeneralSaving &&
      promoCode.length > 0 &&
      Object.keys(selectedPromo).length === 0
    ) {
      handleClickGeneralSaving(FROM_PROMO_WITHOUT_APPLY);
      return;
    }

    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;
    if (promoCode.length > 0 && Object.keys(selectedPromo).length === 0) {
      const isValidPromo = hasMatchingPromo(promoCode);
      if (!isValidPromo.valid) return;
      handleContinueSaveWithPromoWithoutApply(isValidPromo.promoMatch);
      return;
    }
    const isPromoSelected = Object.keys(selectedPromo).length > 0;
    const { cashIn } = inputValue;
    const minPromoDepo =
      selectedPromo &&
      selectedPromo.eligibilityRules &&
      selectedPromo.eligibilityRules.minimumSingleDeposit;

    if (isPromoSelected && parseInt(cashIn) < parseInt(minPromoDepo)) {
      setInputValue(prevState => ({
        ...prevState,
        error: true,
        helperText: `Minimum campaign cash in RM${minPromoDepo} not met`,
      }));
      return;
    }

    history.push(`/deeplink/${type}/${code}/summary/${GENERAL_SAVING}`, {
      withPromo: hasGeneralSaving.withPromo,
    });
  };

  const handleContinueInvest = goPromoList => {
    if (
      new Date() >=
      moment(riskProfileResult.expiredDate)
        .startOf("date")
        .toDate()
    ) {
      setRiskProfileExpired(true);
      return;
    }

    const callback = () => {
      if (
        typeof customerPortfolio === "object" &&
        Array.isArray(customerPortfolio) &&
        customerPortfolio.length > 0
      ) {
        if (goPromoList) {
          const { accountNo } = customerPortfolio[0];
          getPromo(
            {
              target: "INVEST",
              hierarchyId: accountNo,
              instrumentId: code,
            },
            () => history.push("/promo-list")
          );
          return;
        }

        if (!selectedFundDetail) {
          createFundV2(
            { cifId: profile.masterId, fundsPayload: [{ fundCode: code }] },
            () => history.push(`/deeplink/${type}/${code}/summary/${INVEST}`)
          );
          return;
        }

        history.push(`/deeplink/${type}/${code}/summary/${INVEST}`);
        return;
      }

      createFundV2(
        { cifId: profile.masterId, fundsPayload: [{ fundCode: code }] },
        result => {
          // for clicking available promo to go to promo list
          if (goPromoList) {
            const { accountNo } = result;
            getPromo(
              {
                target: "INVEST",
                hierarchyId: accountNo,
                instrumentId: code,
              },
              () => history.push("/promo-list")
            );
          } else {
            history.push(`/deeplink/${type}/${code}/summary/${INVEST}`);
          }
        }
      );
    };

    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;

    if (promoCode.length > 0 && Object.keys(selectedPromo).length === 0) {
      const validateCampaignCb = () => {
        const isValidPromo = hasMatchingPromo(promoCode);

        if (!isValidPromo.valid) return;

        history.push(`/deeplink/${type}/${code}/summary/${INVEST}`);
      };

      if (
        typeof customerPortfolio === "object" &&
        Array.isArray(customerPortfolio) &&
        customerPortfolio.length > 0
      ) {
        const { accountNo } = customerPortfolio[0];
        getPromo(
          {
            target: "INVEST",
            hierarchyId: accountNo,
            instrumentId: code,
          },
          validateCampaignCb
        );

        return;
      }

      createFundV2(
        { cifId: profile.masterId, fundsPayload: [{ fundCode: code }] },
        result => {
          const { accountNo } = result;
          getPromo(
            {
              target: "INVEST",
              hierarchyId: accountNo,
              instrumentId: code,
            },
            validateCampaignCb
          );
        }
      );

      return;
    }

    const isPromoSelected = Object.keys(selectedPromo).length > 0;
    const { cashIn } = inputValue;
    const minPromoDepo =
      selectedPromo &&
      selectedPromo.eligibilityRules &&
      selectedPromo.eligibilityRules.minimumSingleDeposit;

    if (isPromoSelected && parseInt(cashIn) < parseInt(minPromoDepo)) {
      setInputValue(prevState => ({
        ...prevState,
        error: true,
        helperText: `Minimum campaign cash in RM${minPromoDepo} not met`,
      }));
      return;
    }
    callback();
  };

  const handleClick = () => {
    if (!checkInputValue()) return;
    setDeeplinkAmount(inputValue.cashIn);
    type.toLowerCase() === "invest"
      ? handleContinueInvest()
      : handleContinueSave();
  };

  const { callPromoAfterCreateGoal } = useCallPromoAfterCreateGoal(
    getPromo,
    history,
    code
  );

  const handleClickGeneralSaving = promoAction => {
    if (
      promoAction !== FROM_PROMO &&
      promoAction !== FROM_PROMO_WITHOUT_APPLY
    ) {
      if (!checkInputValue() || alreadySubmit.current) return;
    }

    const isAccountBlocked = handleCheckAccountStatus();

    if (isAccountBlocked) return;

    alreadySubmit.current = true;

    if(Object.values(generalSaving).length === 0) {
      showError("We are facing some technical issues, and we are working very hard to resolve the issue.")
      return;
    };

    PAYLOAD_DEEPLINK_CREATE_GOAL.productId = generalSaving.id;

    createGoalDL(PAYLOAD_DEEPLINK_CREATE_GOAL, cbAccNo => {
      if (promoAction === FROM_PROMO) {
        /**
         * Call 'get promo' after successful goal creation to navigate to the promo list page.
         * @param {string} cbAccNo - The callback account number.
         */
        callPromoAfterCreateGoal(cbAccNo);
      } else if (promoAction === FROM_PROMO_WITHOUT_APPLY) {
        setHasGeneralSaving({ isGeneralSaving: true, withPromo: true });
        checkPromoAfterCreateGoal(cbAccNo);
      } else {
        history.push(`/deeplink/${type}/${code}/summary/${GOAL_CREATION}`);
      }
    });
  };

  const { handleGoalWithPromo } = useGoalWithPromoHandler(
    hasGeneralSaving.isGeneralSaving,
    getPromo,
    deeplinkGeneralSaving.accountNo,
    code,
    handleClickGeneralSaving,
    history,
    handleCheckAccountStatus
  );

  const checkPromoAfterCreateGoal = cbAccNo => {
    const getResultPromoValidator = cbPromoList => {
      const resultPromoValidator = hasMatchingPromo(promoCode, cbPromoList);

      handleContinueSaveWithPromoWithoutApply(
        resultPromoValidator.promoMatch,
        FROM_PROMO_WITHOUT_APPLY
      );
    };

    getPromo(
      {
        target: SAVE,
        hierarchyId: cbAccNo,
        instrumentId: code,
      },
      cbPromoList => getResultPromoValidator(cbPromoList)
    );
  };

  if (
    fundDetailLoading ||
    listGoalLoading ||
    createGoalDLLoading ||
    promoListLoading ||
    createFundLoadingV2 ||
    loadingCustomerPortfolio ||
    getStartedLoading
  ) {
    return <DefaultLoader />;
  }

  return (
    <div style={{ padding: "16px 0px" }} className="dl-container">
      <Alert
        title="Your risk profile is expired"
        description="Risk Profile has expired, please perform Risk Assessment"
        isOpen={isRiskProfileExpired}
        isCancel
        btnTxt="Ok"
        handleAction={() => {
          history.push("/invest-introduction", {
            type: params.type,
            code: params.code,
            expired: true,
          });
        }}
        handleCancel={() => setRiskProfileExpired(false)}
      />
      <Alert
        title="Account Verification"
        description="Thank you for submitting your information. We need to perform further verification to activate your account."
        isOpen={status === "pending"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={() => {
          onResetStatus();
        }}
      />
      <Alert
        title="Your account is deactivated"
        description="So sorry, your account is deactivated. We need to perform a verification to activate your account."
        isOpen={status === "rejected"}
        isCS="https://principal.com.my"
        isCancel={false}
        btnTxt="Chat with us on WhatsApp"
        handleAction={() => {
          onResetStatus();
          window.my.call("openAppLink", {
            url: "https://wa.me/60162996213",
          });
        }}
        handleCancel={onResetStatus}
      />

      <AlertAccount
        title={`Account ${
          accountStatus === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountStatus === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountStatus("");

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={NOT_ALLOWED_ACCOUNT_STATUS.includes(accountStatus)}
        handleClose={() => setAccountStatus("")}
      />
      <DLItemCard
        annualReturn={
          type?.toLowerCase() === "invest"
            ? fundDetail.threeAnnualReturn &&
              fundDetail.threeAnnualReturn !== "0%" &&
              fundDetail.threeAnnualReturn
            : saveProjectedReturnValue
        }
        itemName={fundDetail.name}
        link={fundDetail.fundInfoUrl}
        assetClass={fundDetail.assetClass}
        details={fundDetail.details}
        productType={type}
      />

      <div className="fs-step2-box1">
        <p className="invest-form__big-label">
          How much are you willing to invest?
        </p>

        {type.toLowerCase() === "save" && (
          <p className="invest-form__label">General Saving</p>
        )}

        <TextField
          fullWidth
          variant="outlined"
          placeholder="10.00"
          value={inputValue.cashIn}
          onChange={e =>
            setInputValue({
              ...inputValue,
              cashIn: e.target.value,
              error: false,
            })
          }
          error={inputValue.error}
          helperText={inputValue.error && inputValue.helperText}
          FormHelperTextProps={{ className: classes.helper }}
          inputProps={{ className: classes.inputField }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            startAdornment: (
              <InputAdornment position="start">
                <Typography color="textPrimary" className={classes.prefix}>
                  RM
                </Typography>
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="border-line" />
      <div className="fs-step2-box2">
        <InputBasic
          promoValue={checkSelectedPromo().promo}
          error={isErrorPromo}
          value={promoCode}
          errorMessage="Please enter a valid Promo Code"
          title="Promo Code"
          placeholder="Enter promo or referral code"
          type="text"
          isCurrency={false}
          btnOption={checkSelectedPromo().message}
          textButton={checkSelectedPromo().styleBtn}
          isPromo={true}
          onChange={e => {
            setIsErrorPromo(false);
            setPromoCode(e.target.value);
          }}
          listPromoAction={handleCheckEcdd({
            cb: () => {
              setDeeplinkAmount(inputValue.cashIn);
              if (type.toLowerCase() === "invest") {
                handleContinueInvest(FROM_PROMO);
              } else {
                handleGoalWithPromo();
              }
            },
            ecddExpiredCb: () => {
              setDeeplinkAmount(inputValue.cashIn);
              history.push("/onBoarding/ecdd/DEEPLINK", params);
            },
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "View Available Promo Button - Deeplink",
            },
          })}
          onButton={handleCheckEcdd({
            cb: () => {
              setDeeplinkAmount(inputValue.cashIn);

              if (type.toLowerCase() === "invest") {
                handlePromoInvests();
              } else {
                handlePromoSave();
              }
            },
            ecddExpiredCb: () => {
              setDeeplinkAmount(inputValue.cashIn);
              history.push("/onBoarding/ecdd/DEEPLINK", params);
            },
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Apply Promo Button - Deeplink",
            },
          })}
        />
      </div>
      <div className="border-line" />
      <div className="invest-form__container-total">
        <p className="invest-form__container-total__cahsin-label">
          Total Cash In
        </p>
        <p className="invest-form__container-total__total-cashin">
          RM{" "}
          <NumberFormat
            value={inputValue.cashIn === "" ? "0" : inputValue.cashIn}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </div>
      <div className="invest-form__confirm">
        <CustomButton
          text="Continue"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={false}
          onClick={handleCheckEcdd({
            cb: handleClick,
            ecddExpiredCb: () =>
              history.push("/onBoarding/ecdd/DEEPLINK", params),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Cash In Button Deeplink",
            },
          })}
        />
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  ...state.promoReducer,
  createGoalDLLoading: state.deeplinkReducer.createGoalDLLoading,
  deeplinkAmount: state.deeplinkReducer.deeplinkAmount,
  goalList: state.goal.goalList,
  listGoalLoading: state.goal.listGoalLoading,
  fundDetail: state.fundSelectionReducer.fundDetailByCode,
  createFundLoadingV2: state.fundSelectionReducer.createFundLoadingV2,
  fundDetailLoading: state.fundSelectionReducer.fundDetailLoading,
  deeplinkCgoalList: state.deeplinkReducer.deeplinkCgoalList,
  deeplinkGeneralSaving: state.deeplinkReducer.deeplinkGeneralSaving,
  profile: state.goal.profile,
  subaccountDetail: state.goalDetailReducer.subAccountDetail,
  loadingCustomerPortfolio: state.dashboardReducer.loadingCustomerPortfolio,
  customerPortfolio: state.dashboardReducer.customerPortfolio,
  getStartedLoading: state.getStartedReducer.getStartedLoading,
  ecddStatus: state.goal.ecddStatus,
  fundResultFromCreating: state.fundSelectionReducer.fundResult,
  saveProjectedReturnValue: state.getStartedReducer.saveProjectedReturnValue,
  selectedFundDetail: state.fundSelectionReducer.selectedFundDetail,
  riskProfileResult: state.riskAssessmentReducer.riskProfileResult,
});

const mapDispatchToProps = {
  getFundDetail,
  createGoalDL,
  getGoals,
  getcGoalDL,
  setDeeplinkAmount,
  createGoalDLSuccess,
  getPromo,
  resetSelectedPromo,
  setSelectedPromo,
  clearDLCheckoutErr,
  getSubAccount,
  createFundV2,
  getCustomerPortfolio,
  clearInvestCheckoutLoading,
  getProjectedReturn,
  setSelectedFundDetail,
  showError,
};

export default compose(
  withStyles(styles),
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(DeepLink);
