export const GET_FUND_LIST = "GET_FUND_LIST";
export const GET_FUND_LIST_SUCCESS = "GET_FUND_LIST_SUCCESS";
export const GET_FUND_LIST_FAILED = "GET_FUND_LIST_FAILED";

export const GET_SCORE_LIST = "GET_SCORE_LIST";
export const GET_SCORE_LIST_SUCCESS = "GET_SCORE_LIST_SUCCESS";
export const GET_SCORE_LIST_FAILED = "GET_SCORE_LIST_FAILED";

export const CREATE_FUND_V2 = "CREATE_FUND_V2";
export const CREATE_FUND_SUCCESS_V2 = "CREATE_FUND_SUCCESS_V2";
export const CREATE_FUND_FAILED_V2 = "CREATE_FUND_FAILED_V2";

export const SEND_FUNDS_CODE = "SEND_FUNDS_CODE";

export const SET_SELECTED_FUND_DETAIL = "SET_SELECTED_FUND_DETAIL";

export const SET_INVEST_MIN_AMOUNT = "SET_INVEST_MIN_AMOUNT";

export const SELECTED_FUND_CUST = "SELECTED_FUND_CUST";

export const RESET_ERROR_CREATE_FUND = "RESET_ERROR_CREATE_FUND";
export const GET_FUND_DETAIL = "GET_FUND_DETAIL";
export const GET_FUND_DETAIL_SUCCESS = "GET_FUND_DETAIL_SUCCESS";
export const GET_FUND_DETAIL_FAILED = "GET_FUND_DETAIL_FAILED";
