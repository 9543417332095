import { Typography } from "@material-ui/core";
import { DefaultLoader } from "../../components";
import * as React from "react";
import TransactionHistoryDate from "./TransactionHistoryDate";
import styles from "./styles";

function currentYearIsNotEqualToTransactionYear(transaction) {
  return Number(transaction.year) !== new Date().getFullYear();
}

// 3 = Received / Submitted
// 4 = Settled / Submitted + Processing
// 5 = Processing / Submitted + Processing + (Completed for buy)
// 6 = Rejected / Submitted + Processing + Failed, not hit FNZ yet
// 9 = Failed / Submitted + Processing + Failed
// 10 = Completed / Submitted + Processing + Completed

//! for cooloff and sell status 5 is processing

const statusMap = {
  "3": true,
  "4": true,
  "5": true,
  "6": true,
  "9": true,
  "10": true,
  "11": true
};

const TransactionHistory = ({ transactions, loading }) => {
  if (loading)
    return (
      <div className="center">
        <DefaultLoader />
      </div>
    );

  if (transactions.length === 0)
    return (
      <div
        style={{
          minHeight: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography
          color="primary"
          style={{
            ...styles.baseFont,
            lineHeight: 1.38,
            color: "#616367",
            textAlign: "center",
            margin: "auto 0"
          }}
        >
          No history to show. <br />
          Every transaction that has been made will automatically be recorded
          here.
        </Typography>
      </div>
    );

  return (
    <div style={{ marginBottom: "8px" }}>
      {transactions.map((transaction, index) => {
        return (
          <div key={index}>
            <Typography
              style={{
                margin: "24px 0 8px",
                color: "#333333",
                fontWeight: "bold",
                fontSize: "16px"
              }}
            >
              {transaction.month}{" "}
              {currentYearIsNotEqualToTransactionYear(transaction) &&
                transaction.year}
            </Typography>
            {transaction.data && transaction.data.length > 0 ? (
              transaction.data.map((trxDate, index) => {
                return (
                  <div key={index}>
                    <Typography style={{ margin: "8px 0", color: "#616367" }}>
                      {trxDate.date}
                    </Typography>
                    {trxDate.data &&
                      transaction.data.length > 0 &&
                      trxDate.data.map(
                        (
                          {
                            type,
                            plan: { planAlias },
                            compAmt,
                            actualAmt,
                            amount,
                            submittedDate,
                            processingDate,
                            completedDate,
                            trxType,
                            feeAmount,
                            compUnit,
                            actualUnit,
                            unit,
                            status,
                            details,
                            salesChargeAmount,
                            salesChargeCcy,
                            salesChargePercent
                          },
                          index
                        ) =>
                          statusMap[status] ? (
                            <div key={index}>
                              <TransactionHistoryDate
                                key={index}
                                transactionType={type}
                                goalName={planAlias}
                                amount={
                                  compAmt
                                    ? Number(compAmt)
                                    : actualAmt
                                    ? Number(actualAmt)
                                    : Number(amount)
                                }
                                cashOutamtSell={
                                  actualAmt
                                    ? Number(actualAmt)
                                    : amount
                                    ? Number(amount)
                                    : "0"
                                }
                                actualAmount={
                                  actualAmt ? Number(actualAmt) : null
                                }
                                submittedDate={submittedDate}
                                processingDate={processingDate}
                                completedDate={completedDate}
                                method={trxType}
                                fee={feeAmount}
                                unit={
                                  compUnit
                                    ? Number(compUnit)
                                    : actualUnit
                                    ? Number(actualUnit)
                                    : Number(unit)
                                }
                                status={status}
                                details={details}
                                coolOffAmt={Number(amount)}
                                salesChargeAmount={salesChargeAmount}
                                salesChargeCcy={salesChargeCcy}
                                salesChargePercent={salesChargePercent}
                              />
                            </div>
                          ) : null
                      )}
                  </div>
                );
              })
            ) : (
              <div className="center">
                <DefaultLoader />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TransactionHistory);
