import React from "react";
import { compose } from "underscore";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import styles from "./styles";

import { withStyles } from "@material-ui/core/styles";
import "./alertAccount.scss";
function AlertDDA(props) {
  const {
    handleClose,
    isOpen,
    classes,
    firstText,
    title,

    hideDoneButton,
    children
  } = props;

  const StyledDialog = withStyles({
    scrollPaper: {
      padding: "0 16px"
    },
    paperFullWidth: {
      minWidth: "100%"
    }
  })(Dialog);
  return (
    <StyledDialog
      fullWidth
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className="popup-DDA-container">
        <DialogTitle style={{ padding: "10px" }}>
          <Typography className={`${classes.baseFont} ${classes.title}`}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent
          className={classes.descContainerAccountBox}
          style={{ padding: "0px 10px 30px" }}
        >
          <Typography className={`${classes.baseFont} ${classes.description}`}>
            {firstText}
          </Typography>
        </DialogContent>

        <DialogActions style={{ margin: "0px", padding: "0px" }}>
          {hideDoneButton ? null : (
            <div>
              <button className="button-action-dda" onClick={handleClose}>
                OK
              </button>
            </div>
          )}
          {children}
        </DialogActions>
      </div>
    </StyledDialog>
  );
}
export default compose(withStyles(styles))(AlertDDA);
