export const blacklist = [
  "form",
  "loaderReducer",
  "commingFromReducer",
  "goal.goalCreationLoading",
  "goal.loading",
  "goal.transactionHistory",
  "autoDepositReducer.loadingRspDetail",
  "autoDepositReducer.makeDepositLoading",
  "stepThreeReducer",
  "fundSelectionReducer.errorCreateFund",
  "fundSelectionReducer.createFundLoadingV2",
  "fundSelectionReducer.fundCustSelected",
  "fundSelectionReducer.fundResult",
  "promoReducer.promoListLoading",
  "promoReducer.selectedPromo",
  "globalReducer.globalData.isModalErrorOpen",
  "auth.tokenOnprogress",
  "dashboardReducer.loadingCustomerPortfolio",
  "goal.loadingCustomerGoals",
  "dashboardReducer.subAccStsInvestLoading",
  "getStartedReducer.fundsUnitloading",
  "riskAssessmentReducer.resultAssessmentLoading",
  "dashboardReducer.accountStatusLoading",
  "switchingReducer.transferLoading",
  "switchingReducer.transferError",
  "fundSelectionReducer.fundListLoading",
  "fundSelectionReducer.scoreListLoading",
  "fundSelectionReducer.fundDetailByCode",
  "fundSelectionReducer.fundDetailLoading",
  "deeplinkReducer.checkoutDLLoading",
  "deeplinkReducer.createGoalDLLoading",
];
