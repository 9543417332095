export const GET_OCCUPATIONS = "GET_OCCUPATIONS";
export const GET_OCCUPATIONS_SUCCESS = "GET_OCCUPATIONS_SUCCESS";
export const GET_OCCUPATIONS_FAILED = "GET_OCCUPATIONS_FAILED";

export const GET_NATURE_BUSSINESS = "GET_NATURE_BUSSINESS ";
export const GET_NATURE_BUSSINESS_SUCCESS = "GET_NATURE_BUSSINESS_SUCCESS";
export const GET_NATURE_BUSSINESS_FAILED = "GET_NATURE_BUSSINESS_FAILED";

export const GET_ANNUAL_INCOME_V2 = "GET_ANNUAL_INCOME_V2";
export const GET_ANNUAL_INCOME_V2_SUCCESS = "GET_ANNUAL_INCOME_V2_SUCCESS";
export const GET_ANNUAL_INCOME_V2_FAILED = "GET_ANNUAL_INCOME_V2_FAILED";

export const GET_COUNTRY_V2 = "GET_COUNTRY_V2";
export const GET_COUNTRY_V2_SUCCESS = "GET_COUNTRY_V2_SUCCESS";
export const GET_COUNTRY_V2_FAILED = "GET_COUNTRY_V2_FAILED";

export const GET_RACE_V2 = "GET_RACE_V2";
export const GET_RACE_V2_SUCCESS = "GET_RACE_V2_SUCCESS";
export const GET_RACE_V2_FAILED = "GET_RACE_V2_FAILED";

export const GET_STATE_V2 = "GET_STATE_V2";
export const GET_STATE_V2_SUCCESS = "GET_STATE_V2_SUCCESS";
export const GET_STATE_V2_FAILED = "GET_STATE_V2_FAILED";

export const GET_SOURCE_OF_FUND_V2 = "GET_SOURCE_OF_FUND_V2";
export const GET_SOURCE_OF_FUND_V2_SUCCESS = "GET_SOURCE_OF_FUND_V2_SUCCESS";
export const GET_SOURCE_OF_FUND_V2_FAILED = "GET_SOURCE_OF_FUND_V2_FAILED";

export const GET_SOURCE_OF_WEALTH = "GET_SOURCE_OF_WEALTH";
export const GET_SOURCE_OF_WEALTH_SUCCESS = "GET_SOURCE_OF_WEALTH_SUCCESS";
export const GET_SOURCE_OF_WEALTH_FAILED = "GET_SOURCE_OF_WEALTH_FAILED";

export const GET_ESTIMATED_NET_WORTH = "GET_ESTIMATED_NET_WORTH";
export const GET_ESTIMATED_NET_WORTH_SUCCESS =
  "GET_ESTIMATED_NET_WORTH_SUCCESS";
export const GET_ESTIMATED_NET_WORTH_FAILED = "GET_ESTIMATED_NET_WORTH_FAILED";

export const GET_NUMBER_OF_TRANSACTION = "GET_NUMBER_OF_TRANSACTION";
export const GET_NUMBER_OF_TRANSACTION_SUCCESS =
  "GET_NUMBER_OF_TRANSACTION_SUCCESS";
export const GET_NUMBER_OF_TRANSACTION_FAILED =
  "GET_NUMBER_OF_TRANSACTION_FAILED";

export const GET_VOLUME_OF_TRANSACTION = "GET_VOLUME_OF_TRANSACTION";
export const GET_VOLUME_OF_TRANSACTION_SUCCESS =
  "GET_VOLUME_OF_TRANSACTION_SUCCESS";
export const GET_VOLUME_OF_TRANSACTION_FAILED =
  "GET_VOLUME_OF_TRANSACTION_FAILED";

export const SET_ONBOARD_ONE = "SET_ONBOARD_ONE";

export const GET_MARITAL_STATUSV2 = "GET_MARITAL_STATUSV2";
export const GET_MARITAL_STATUSV2_SUCCESS = "GET_MARITAL_STATUSV2_SUCCESS";
export const GET_MARITAL_STATUSV2_FAILED = "GET_MARITAL_STATUSV2_FAILED";

export const GET_INVESTMENT_OBJECTIVE = "GET_INVESTMENT_OBJECTIVE";
export const GET_INVESTMENT_OBJECTIVE_SUCCESS = "GET_INVESTMENT_OBJECTIVE_SUCCESS";
export const GET_INVESTMENT_OBJECTIVE_FAILED = "GET_INVESTMENT_OBJECTIVE_FAILE";