import React, { useState, useEffect, useRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import moment from "moment";
import {
  StepLabel,
  CustomInput,
  CustomButton,
  Dropdown,
  ProfileDropdown,
  DefaultLoader,
  CountryItem,
  Alert,
  AlertInfo,
  ProfileMenu,
} from "../../components";
import {
  getOccupations,
  getNOBV2,
  getAnnualIncomeV2,
  getCountryV2,
  getRaceV2,
  getSourceOfFundV2,
  getStateV2,
} from "./redux/action";
import { ONBOARDING } from "../redux/constant";
import {
  clearError,
  updateProfile,
  resetData,
  getMaritalStatus,
} from "../redux/action";
import { compose } from "underscore";
import styles from "./styles";
import {
  Typography,
  Checkbox,
  RadioGroup,
  Radio,
  FormControl,
  Icon,
  TextField,
  InputAdornment,
  FormControlLabel,
} from "@material-ui/core";
import { setProfileInfoV2 } from "../getStarted/redux/actions";

import { validateEmail } from "../../utils/functions/validateEmail";

function ProfileForm(props) {
  const {
    classes,
    history,
    clearError,
    updateProfile,
    error,
    getOccupations,
    getNOBV2,
    getAnnualIncomeV2,
    occupationV2List = [],
    natureOfBusinessV2List = [],
    annualIncomeV2list = [],
    getCountryV2,
    getRaceV2,
    raceLIstV2 = [],
    countryListV2 = [],
    profile = {},
    getAnnualIncomeV2Loading,
    getNOBLoading,
    getOccupationsLoading,
    loading,
    getStateLoading,
    getRaceLoading,
    getStateV2,
    stateListV2 = [],
    getSourceOfFundV2,
    sourceOfFundListV2 = [],
    getMaritalStatus,
    maritalStatusList,
    setProfileInfoV2,
  } = props;

  const {
    masterId = "",
    fullName = "",
    identities = [],
    mobileNo = "",
    lang = "",
    countryOfBirth = "",
    townOfBirth = "",
    dob = "",
    maritalStatusCd = "",
    sourceOfFundCd = "",
    race = "",
    gender = "",
    contacts = [],
    email = "",
    occupationTypeCd = "",
    natureOfBusinessCd = "",
    yearlyIncomeLevelCd = "",
    investmentObjCd = "",
  } = JSON.parse(localStorage.getItem("mpUserInfo"));

  let employerNameFormat = /^[a-zA-Z. ]+$/;
  let alphaNumericFormat = /^[a-zA-Z0-9]{0,20}$/;
  const stopOccupations = ["23", "91"];
  const occupationWhiteList = ["23", "62", "65", "90", "91"];

  const [stepOnboarding, setStepOnboarding] = useState(1);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [stopNob, setStopNob] = useState(false);
  const [PEPalert, setPEPAlert] = useState(false);
  const [isBlank, setIsBlank] = useState(false);
  const [idNo, setIdNo] = useState("");
  const [citizenshipDefault, setCitizenshipDefault] = useState("");
  const [isOccupationOpen, setOccupationOpen] = useState(false);
  const [isRaceOpen, setRaceOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedRace, setSelectedRace] = useState("");
  const [isBusinessNatureOpen, setBusinessNatureOpen] = useState(false);
  const [isAnnualIncomeOpen, setAnnualIncomeOpen] = useState(false);
  const [selectedBusinessNature, setSelectedBusinessNature] = useState("");
  const [selectedAnnualIncome, setSelectedAnnualIncome] = useState("");
  const [selectedOccupation, setSelectedOccupation] = useState("");
  const [isTaxCountryOpen, setTaxCountryOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isStateOpen, setStateOpen] = useState(false);
  const [disabledUpdate, setDisableUpdate] = useState(false);
  const [taxCountrySearch, setTaxCountrySearch] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [addressName, setAddressName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [raceName, setRaceName] = useState("");
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState("");
  const [selectedTaxCountry, setSelectedTaxCountry] = useState("");
  const [isOccupationWhiteListed, setIsOccupationWhiteListed] = useState(false);
  const [autoBusiness, setAutoBusiness] = useState({ business: "" });
  const [autoIncome, setAutoIncome] = useState({ income: "" });
  const [requireInfo, setRequireInfo] = useState({});
  const [selectedInvsObj, setSelectedInvsObj] = useState("01");
  let [selectedSourceOfFund, setSelectedSourceOfFund] = useState("");
  let [selectedSourceOfFundName, setSelectedSourceOfFundName] = useState("");
  const [sourceOfFundOpen, setSourceOfFundOpen] = useState(false);
  const [stepOneData, setStepOneData] = useState({
    state: "",
    race: "",
    email: "",
  });

  const [invsObj] = useState([
    {
      id: "01",
      name: "Investment",
    },
    {
      id: "02",
      name: "Protection",
    },
    {
      id: "03",
      name: "Retirement",
    },
    {
      id: "04",
      name: "Daily Usage",
    },
    {
      id: "05",
      name: "Earnings",
    },
  ]);
  const [employmentInfo, setEmploymentInfo] = useState({
    occupation: "",
    income: "",
    employerName: "",
  });

  const [fieldError, setFieldError] = useState({
    occupation: false,
    business: false,
    income: false,
    taxIdNo: false,
    cashPurpose: false,
    email: false,
    taxCountry: false,
    state: false,
    race: false,
    sourceOfFund: false,
  });

  const [otherInfo, setOtherInfo] = useState({
    cashPurpose: "",
    sourceOfFunds: "",
    isPoliticalPosition: "No",
    otherTaxResident: "No",
    taxCountry: "",
    taxIdNo: "",
  });

  const [onBoardingPayload, setOnBoardingPayload] = useState({
    occupationTypeCd: "",
    natureOfBusinessCd: "",
    yearlyIncomeLevelCd: "",
    employerName: "",
    cashPurpose: "01",
    race: "",
    email: email === null || email === undefined ? "" : email,
  });

  const masterIdRef = useRef(null);

  // useEffect(() => {
  //   if (profile && profile.masterId) {
  //     if (
  //       masterIdRef.current !== null &&
  //       masterIdRef.current !== profile.masterId
  //     ) {
  //       props.resetData();
  //       setProfileInfoV2(null);
  //       localStorage.clear();
  //       history.push("/");
  //     }

  //     masterIdRef.current = profile.masterId;
  //   }
  // }, [profile]);

  useEffect(() => {
    getCountryV2();
    getOccupations();
    getNOBV2();
    getAnnualIncomeV2();
    getRaceV2();
    getStateV2();
    getSourceOfFundV2();
    getMaritalStatus();
  }, []);

  useEffect(() => {
    if (occupationWhiteList.includes(selectedOccupation)) {
      setIsOccupationWhiteListed(true);
      setEmploymentInfo(prevState => {
        return {
          ...prevState,
          employerName: "",
        };
      });
    } else {
      setIsOccupationWhiteListed(false);
    }
  }, [selectedOccupation]);

  useEffect(() => {
    if (
      countryOfBirth === null ||
      countryOfBirth === undefined ||
      countryOfBirth.length === 0
    ) {
      return;
    }

    const countryCode = countryListV2.find(
      item => item.code === countryOfBirth
    );
    if (countryCode) {
      setSelectedCountry(countryCode.code);
    }
  }, [profile, countryListV2]);

  useEffect(() => {
    if (maritalStatusList) {
      let marital = maritalStatusList.find(
        mrtSts => mrtSts.code === maritalStatusCd
      );

      if (marital) {
        setSelectedMaritalStatus(marital.code);
      }
    }
  }, [profile, maritalStatusList]);

  //source of fund

  useEffect(() => {
    if (
      sourceOfFundCd === null ||
      sourceOfFundCd === undefined ||
      sourceOfFundCd.length === 0
    ) {
      return;
    }

    const sourceOfFund = sourceOfFundListV2.find(
      item => item.code === sourceOfFundCd
    );
    if (sourceOfFund) {
      setSelectedSourceOfFund(sourceOfFund.code);
      setSelectedSourceOfFundName(sourceOfFund.description);
    }
  }, [profile, sourceOfFundListV2]);

  useEffect(() => {
    if (
      investmentObjCd === null ||
      investmentObjCd === undefined ||
      investmentObjCd.length === 0
    ) {
      return;
    }
    const result = invsObj.find(item => item.id === investmentObjCd);

    if (result) {
      setSelectedInvsObj(result.id);
    }
  }, [profile]);

  useEffect(() => {
    if (race === null || race === undefined || race.length === 0) {
      return;
    }
    const result = raceLIstV2.find(item => item.code === race);
    if (result) {
      setSelectedRace(result.code);
      setOnBoardingPayload({ ...onBoardingPayload, race: result.name });
      setRaceName(result.name);
    }
  }, [profile, raceLIstV2]);

  useEffect(() => {
    if (contacts.length > 0) {
      let address = contacts[0];
      let newState = "";
      let userState = contacts.find(
        contact => contact.contactTypeCd === "PERMANENT"
      ).state;
      let newStateCode = stateListV2.find(item => item.code === userState);
      if (newStateCode) {
        newState = newStateCode;
      }

      let newAddress = {
        line1: address.line1 ? address.line1 : "",
        line2: address.line2 ? address.line2 : "",
        line3: address.line3 ? address.line3 : "",
        postCode: address.postCode ? address.postCode : "",
        city: address.city ? address.city : "",
        state: newState ? newState.name : "",
        country: address.country ? address.country : "",
      };
      setAddressName(newState ? newState.name : "");
      setSelectedState(newState ? newState.code : "");
      setSelectedAddress(newAddress);
    }
  }, [profile, stateListV2]);
  //}, []);

  useEffect(() => {
    if (identities && identities.length > 0) {
      setIdNo(identities[0].idNo);
      setCitizenshipDefault(identities[0].citizenship);
    }
  }, [profile]);

  useEffect(() => {
    setCountryList(countryListV2);
  }, [countryListV2]);

  useEffect(() => {
    if (stopNob) {
      setFieldError({ ...fieldError, business: false });
    }
  }, [selectedOccupation]);

  useEffect(() => {
    if (
      yearlyIncomeLevelCd === null ||
      yearlyIncomeLevelCd === undefined ||
      yearlyIncomeLevelCd.length === 0
    ) {
      return;
    }

    const income = annualIncomeV2list.find(
      item => item.code === yearlyIncomeLevelCd
    );
    if (income) {
      setAutoIncome({ ...autoIncome, income: income.description });
      setOnBoardingPayload({
        ...onBoardingPayload,
        yearlyIncomeLevelCd: income.code,
      });
      setSelectedAnnualIncome(income.code);
    }
  }, [profile, annualIncomeV2list]);

  useEffect(() => {
    if (
      natureOfBusinessCd === null ||
      natureOfBusinessCd === undefined ||
      natureOfBusinessCd.length === 0
    ) {
      return;
    }
    if (stopOccupations.includes(occupationTypeCd)) {
      return;
    }
    const business = natureOfBusinessV2List.find(
      item => item.code === natureOfBusinessCd
    );
    if (business) {
      setAutoBusiness({ ...autoBusiness, business: business.name });
      setOnBoardingPayload({
        ...onBoardingPayload,
        natureOfBusinessCd: business.code,
      });
      setSelectedBusinessNature(business.code);
    }
  }, [profile, natureOfBusinessV2List]);

  useEffect(() => {
    if (
      occupationTypeCd === null ||
      occupationTypeCd === undefined ||
      occupationTypeCd.length === 0
    ) {
      return;
    }
    const occupation = occupationV2List.find(
      item => item.code === occupationTypeCd
    );
    if (occupation) {
      setEmploymentInfo({ ...employmentInfo, occupation: occupation.name });
      setOnBoardingPayload({
        ...onBoardingPayload,
        occupationTypeCd: occupation.code,
      });
      setSelectedOccupation(occupation.code);
    }

    let result = stopOccupations.includes(occupationTypeCd);
    if (result) {
      setStopNob(true);
    } else {
      setStopNob(false);
    }
  }, [profile, occupationV2List]);

  const handleSelectRace = id => {
    setSelectedRace(id);
    const race = raceLIstV2.find(item => item.code === id);
    setOnBoardingPayload({ ...onBoardingPayload, race: race.name });
    setRaceName(race.name);
    setRaceOpen(false);
    setFieldError({ ...fieldError, race: false });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stepOnboarding]);

  const handleSelectState = id => {
    setSelectedState(id);
    const stateItem = stateListV2.find(item => item.code === id);
    setSelectedAddress({ ...selectedAddress, state: stateItem.name });
    setAddressName(stateItem.name);
    setStateOpen(false);
    setFieldError({ ...fieldError, state: false });
  };

  const handleSelectTaxCountry = id => {
    setSelectedTaxCountry(id);
    const ctry = countryList.find(item => item.code === id);
    setOtherInfo({ ...otherInfo, taxCountry: ctry.name });
    let result = [...countryListV2];
    const index = result.findIndex(e => e.code === id);
    result.unshift(result.splice(index, 1)[0]);

    setCountryList(result);
    setTaxCountrySearch("");
    setTaxCountryOpen(false);
    setFieldError({ ...fieldError, taxCountry: false });
  };

  const findCountry = e => {
    let filterCountry = countryListV2.filter(val =>
      val.name.toLowerCase().includes(e.toLowerCase())
    );

    setCountryList(filterCountry);
  };

  const handleSelectOccupation = id => {
    let result = stopOccupations.includes(id);
    if (result) {
      setStopNob(true);
    } else {
      setStopNob(false);
    }
    setSelectedOccupation(id);
    setOnBoardingPayload({ ...onBoardingPayload, occupationTypeCd: id });
    const occupation = occupationV2List.find(item => item.code === id);
    setEmploymentInfo({ ...employmentInfo, occupation: occupation.name });
    setOccupationOpen(false);
    setFieldError({ ...fieldError, occupation: false });
  };

  const handleSelectBusinessNature = id => {
    setSelectedBusinessNature(id);
    setOnBoardingPayload({ ...onBoardingPayload, natureOfBusinessCd: id });
    const business = natureOfBusinessV2List.find(item => item.code === id);
    if (business) {
      setAutoBusiness({ ...autoBusiness, business: business.name });
    }

    setBusinessNatureOpen(false);
    setFieldError({ ...fieldError, business: false });
  };

  const handleSelectAnnualIncome = id => {
    setSelectedAnnualIncome(id);
    setOnBoardingPayload({ ...onBoardingPayload, yearlyIncomeLevelCd: id });

    const income = annualIncomeV2list.find(item => item.code === id);
    if (income) {
      setAutoIncome({ ...autoIncome, income: income.description });
    }

    setAnnualIncomeOpen(false);
    setFieldError({ ...fieldError, income: false });
  };

  const handleChangeEmployer = value => {
    if (employerNameFormat.test(value) || value === "") {
      setEmploymentInfo(prevState => ({
        ...prevState,
        employerName: value,
      }));
      setOnBoardingPayload({ ...onBoardingPayload, employerName: value });
    }
  };
  const handleChangeOtherInfo = e => {
    const { name, value } = e.target;

    setOtherInfo(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeTaxId = value => {
    if (alphaNumericFormat.test(value) || value === "") {
      setOtherInfo(prevState => ({
        ...prevState,
        taxIdNo: value,
      }));
    }
  };
  const handleError = (field, value) => {
    setFieldError(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleOpenPDF = pdfUrl => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: pdfUrl,
      event: "openPdf",
    });
  };

  const handleValidateEmail = value => {
    const validation = validateEmail(value);

    if (validation) {
      setFieldError({ ...fieldError, email: false });
    } else {
      setFieldError({ ...fieldError, email: true });
    }
  };
  const handleSelectSourceOfFund = id => {
    setSelectedSourceOfFund(id);

    const tempSourceOfFund = sourceOfFundListV2.find(item => item.code === id);
    if (tempSourceOfFund) {
      setSelectedSourceOfFundName(tempSourceOfFund.description);
    }

    setSourceOfFundOpen(false);
    setFieldError({ ...fieldError, sourceOfFund: false });
  };

  const stepOneValidation = val => {
    let tempError = {
      state: false,
      race: false,
      email: false,
    };

    let requireField = {
      state: val.selectedState,
      race: val.selectedRace,
      email: val.email,
    };

    for (const key in requireField) {
      if (requireField[key].length === 0) {
        tempError[key] = true;
      }
    }

    setFieldError(prevState => ({
      ...prevState,
      state: tempError.state,
      race: tempError.race,
      email: tempError.email,
    }));
    let requireInfoTemp = {};
    if (tempError.state) {
      requireInfoTemp.state = "State";
    }
    if (tempError.race) {
      requireInfoTemp.race = "Race";
    }

    if (tempError.email) {
      requireInfoTemp.email = "Email";
    }

    setRequireInfo(requireInfoTemp);
    for (const key in tempError) {
      if (tempError[key] === true) {
        return false;
      }
    }

    return true;
  };

  const stepOneGo = val => {
    let validation = stepOneValidation(val);
    if (!validation) {
      setIsBlank(true);
      return;
    }
    const validationEmail = validateEmail(val.email);
    if (!validationEmail) {
      setFieldError({ ...fieldError, email: true });
      return;
    }

    setStepOnboarding(2);
  };

  const handleChangeEmail = val => {
    setOnBoardingPayload({
      ...onBoardingPayload,
      email: val,
    });
  };

  const requireValidation = val => {
    const tempError = {
      occupation: false,
      business: false,
      income: false,
      taxIdNo: false,
      taxCountry: false,
      sourceOfFund: false,
    };
    let requireField = {
      occupation: val.occupationTypeCd,
      business: val.natureOfBusinessCd,
      income: val.yearlyIncomeLevelCd,
      sourceOfFund: val.sourceOfFundCd,
      taxCountry:
        otherInfo.otherTaxResident === "Yes"
          ? val.identities[1].citizenship
          : "",
      taxIdNo:
        otherInfo.otherTaxResident === "Yes" ? val.identities[1].idNo : "",
    };

    for (const key in requireField) {
      if (requireField[key].length === 0) {
        tempError[key] = true;
      }
    }

    setFieldError(prevState => ({
      ...prevState,
      occupation: tempError.occupation,
      sourceOfFund: tempError.sourceOfFund,
      business: tempError.business,
      income: tempError.income,
      taxIdNo: otherInfo.otherTaxResident === "Yes" ? tempError.taxIdNo : false,
      taxCountry:
        otherInfo.otherTaxResident === "Yes" ? tempError.taxCountry : false,
    }));
    let requireInfoTemp = {};
    if (tempError.occupation) {
      requireInfoTemp.occupation = "Occupation";
    }
    if (tempError.business) {
      requireInfoTemp.natureOfBusiness = "Nature of business";
    }
    if (tempError.income) {
      requireInfoTemp.annualIncome = "Annual Income";
    }
    if (tempError.sourceOfFund) {
      requireInfoTemp.sourceOfFund = "Source Of Fund";
    }
    if (otherInfo.otherTaxResident === "Yes" && tempError.taxIdNo) {
      requireInfoTemp.taxIdNumber = "Tax ID Number";
    }
    if (otherInfo.otherTaxResident === "Yes" && tempError.taxCountry) {
      requireInfoTemp.taxCountry = "Tax Country";
    }
    if (otherInfo.otherTaxResident === "No") {
      delete tempError.taxCountry;
      delete tempError.taxIdNo;
    }
    setRequireInfo(requireInfoTemp);
    for (const key in tempError) {
      if (tempError[key] === true) {
        return false;
      }
    }

    return true;
  };

  const setGender = code => {
    switch (code) {
      case "F":
        return "F";
      case "M":
        return "M";
      case "Female":
        return "F";
      case "female":
        return "F";
      case "Male":
        return "M";
      case "male":
        return "M";
      case "":
        return "Unknown";
      default:
        return "Unknown";
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (otherInfo.isPoliticalPosition !== "No") {
      setPEPAlert(true);
      return;
    }

    let payloadAddress = selectedAddress;
    delete payloadAddress.state;
    payloadAddress.state =
      stepOneData.state.length === 0 ? selectedState : stepOneData.state;
    payloadAddress.line1.slice(0, 40);
    payloadAddress.line2.slice(0, 40);
    payloadAddress.line3.slice(0, 40);
    payloadAddress.city.slice(0, 40);
    const payload = {
      masterId,
      mobileNo,
      fullName,
      maritalStatusCd: selectedMaritalStatus ? selectedMaritalStatus : "",
      countryOfBirth: selectedCountry ? selectedCountry : "",
      townOfBirth: townOfBirth ? townOfBirth : "",
      dob: dob ? moment(dob).format("YYYY-MM-DD") : "", //R
      email:
        stepOneData.email.length === 0
          ? onBoardingPayload.email
          : stepOneData.email,
      occupationTypeCd: onBoardingPayload.occupationTypeCd,
      natureOfBusinessCd: selectedBusinessNature,
      yearlyIncomeLevelCd: selectedAnnualIncome,
      employerName: onBoardingPayload.employerName,
      investmentObjCd: selectedInvsObj ? selectedInvsObj : "01",
      sourceOfFundCd: selectedSourceOfFund ? selectedSourceOfFund : "",
      race: stepOneData.race.length === 0 ? selectedRace : stepOneData.race,
      gender: setGender(gender),
      lang: lang ? lang : "",
      address: payloadAddress,
      identities:
        otherInfo.otherTaxResident === "Yes"
          ? [
              {
                idTypeCd: "NRIC", //R
                idNo: idNo ? idNo : "",
                citizenship: citizenshipDefault ? citizenshipDefault : "", //R
              },
              {
                idTypeCd: "TAX",
                idNo: otherInfo.taxIdNo ? otherInfo.taxIdNo : "",
                citizenship: selectedTaxCountry ? selectedTaxCountry : "",
              },
            ]
          : [
              {
                idTypeCd: "NRIC", //R
                idNo: idNo ? idNo : "",
                citizenship: citizenshipDefault ? citizenshipDefault : "", //R
              },
            ],
    };

    if (stopNob) {
      payload.natureOfBusinessCd = "99";
    }

    let validation = requireValidation(payload);
    if (!validation) {
      setIsBlank(true);
      return;
    }

    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      `Click Onboarding Submit Step 2`
    );

    updateProfile({ payloadData: payload, from: ONBOARDING });
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Alert
        title="Account doesn’t meet requirement"
        description="So sorry, your account doesn’t meet our requirement."
        isOpen={PEPalert}
        isCS="https://principal.com.my"
        isCancel={false}
        handleAction={() => {
          setProfileInfoV2(null);
          history.push("/");
        }}
      />
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={error === "timeoutError"}
        // isOpen={false}
        isTimeout={true}
        handleAction={() => {
          clearError();
          setProfileInfoV2(null);
          history.push("/");
        }}
      />
      <Alert
        title="Please fill in required fields"
        isObject={requireInfo}
        isOpen={isBlank}
        isCancel={false}
        handleAction={() => setIsBlank(false)}
      />
      <StepLabel
        title="Profile info"
        containerStyles={{ padding: "16px 16px 22px" }}
        onClick={() => {
          if (stepOnboarding === 1) {
            setProfileInfoV2(null); // profile info set to NULL so if go on the getStarted screen not redirect to this screen
            history.push("/");
          } else {
            setStepOnboarding(1);
          }
        }}
      />
      {stepOnboarding === 1 ? (
        <>
          {(getRaceLoading || getStateLoading) && <DefaultLoader />}
          <div className={classes.fieldGroupStepOB}>
            <Typography
              color="secondary"
              style={{ ...styles.baseFontStepOB, fontWeight: "700" }}
            >
              Step 1 of 2
            </Typography>
          </div>

          <div className={classes.fieldGroup}>
            <Typography
              color="secondary"
              style={{ ...styles.baseFont, fontWeight: "700" }}
            >
              Identification & contact information
            </Typography>
          </div>

          <div style={{ margin: "0 16px" }}>
            <CustomInput
              label="Full name"
              name="fullName"
              value={fullName}
              isEdit={false}
              isRequired={true}
            />
            <CustomInput
              label="ID Number"
              name="idNumber"
              value={idNo}
              isEdit={false}
              isRequired={true}
            />
            <CustomInput
              label="Contact number"
              name="contactNumber"
              value={mobileNo.length > 8 ? mobileNo.slice(2) : mobileNo}
              countryCode={
                mobileNo.length > 8 ? mobileNo.substring(0, 2) : undefined
              }
              isEdit={false}
              isRequired={true}
              isPhone={true}
            />
            <CustomInput
              label="State"
              name="state"
              value={addressName}
              isEdit={true}
              isRequired={true}
              isDropdown={true}
              onClick={() => setStateOpen(true)}
              error={fieldError.state}
            />
            <Dropdown
              bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
              isOpen={isStateOpen}
              handleDismiss={() => setStateOpen(false)}
            >
              <ProfileDropdown
                title="State"
                itemList={stateListV2}
                selectedItem={selectedState}
                handleClick={handleSelectState}
              />
            </Dropdown>
            <CustomInput
              label="Ethnicity / Race"
              name="race"
              value={raceName}
              isEdit={true}
              isRequired={true}
              isDropdown={true}
              onClick={() => setRaceOpen(true)}
              error={fieldError.race}
            />

            <Dropdown
              bottomHeight={({ maxHeight }) => maxHeight * 0.4}
              isOpen={isRaceOpen}
              handleDismiss={() => setRaceOpen(false)}
            >
              <ProfileDropdown
                title="Race"
                itemList={raceLIstV2}
                selectedItem={selectedRace}
                handleClick={handleSelectRace}
              />
            </Dropdown>
            <CustomInput
              label="Email"
              name="email"
              placeholder="user@example.com"
              type="email"
              value={onBoardingPayload.email}
              onChange={e => handleChangeEmail(e.target.value)}
              handleBlur={() => handleValidateEmail(onBoardingPayload.email)}
              error={fieldError.email}
              isEdit={true}
              isRequired={true}
              maxLength={60}
              helperText={
                onBoardingPayload.email.length > 0
                  ? "Invalid email format"
                  : "This field is required"
              }
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.fieldGroupStepOB}>
            <Typography
              color="secondary"
              style={{ ...styles.baseFontStepOB, fontWeight: "700" }}
            >
              Step 2 of 2
            </Typography>
          </div>
          <div className={classes.fieldGroup}>
            <Typography
              color="secondary"
              style={{ ...styles.baseFont, fontWeight: "700" }}
            >
              Employment information test
            </Typography>
          </div>
          {getAnnualIncomeV2Loading ||
          getNOBLoading ||
          getOccupationsLoading ||
          loading ? (
            <DefaultLoader />
          ) : (
            <div style={{ margin: "0 16px" }}>
              <CustomInput
                label="Occupation"
                name="occupation"
                value={employmentInfo.occupation}
                isEdit={true}
                isRequired={true}
                isDropdown={true}
                onClick={() => setOccupationOpen(true)}
                error={fieldError.occupation}
                helperText={"This field is required"}
              />
              {/* set dropdown value */}
              <Dropdown
                bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
                isOpen={isOccupationOpen}
                handleDismiss={() => setOccupationOpen(false)}
              >
                <ProfileDropdown
                  title="Occupation"
                  itemList={occupationV2List}
                  selectedItem={selectedOccupation}
                  handleClick={handleSelectOccupation}
                />
              </Dropdown>
              <CustomInput
                label="Nature of business"
                name="business"
                value={stopNob ? "Not Applicable" : autoBusiness.business}
                isEdit={stopNob ? false : true}
                isRequired={true}
                isDropdown={true}
                helperText={"This field is required"}
                onClick={() => {
                  if (!stopNob) {
                    setBusinessNatureOpen(true);
                  }
                }}
                error={fieldError.business}
              />
              <Dropdown
                bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
                isOpen={isBusinessNatureOpen}
                handleDismiss={() => setBusinessNatureOpen(false)}
              >
                <ProfileDropdown
                  title="Business nature"
                  itemList={natureOfBusinessV2List.filter(
                    item => item.code !== "99"
                  )}
                  selectedItem={stopNob ? "99" : selectedBusinessNature}
                  handleClick={handleSelectBusinessNature}
                />
              </Dropdown>
              <CustomInput
                label="Annual income"
                name="income"
                value={autoIncome.income}
                isEdit={true}
                isRequired={true}
                isDropdown={true}
                helperText={"This field is required"}
                onClick={() => setAnnualIncomeOpen(true)}
                error={fieldError.income}
              />
              <Dropdown
                bottomHeight={({ maxHeight }) => maxHeight * 0.6}
                isOpen={isAnnualIncomeOpen}
                handleDismiss={() => setAnnualIncomeOpen(false)}
              >
                <ProfileDropdown
                  title="Annual income"
                  itemList={annualIncomeV2list}
                  selectedItem={selectedAnnualIncome}
                  handleClick={handleSelectAnnualIncome}
                />
              </Dropdown>
              <CustomInput
                label="Employer name"
                name="employerName"
                value={employmentInfo.employerName}
                onChange={e => handleChangeEmployer(e.target.value)}
                isEdit={!isOccupationWhiteListed}
                isRequired={false}
              />
            </div>
          )}

          <div style={{ marginTop: "38px" }} className={classes.fieldGroup}>
            <Typography
              color="secondary"
              style={{ fontWeight: "700" }}
              className={classes.baseFont}
            >
              Other information
            </Typography>
          </div>
          <div style={{ margin: "0 16px" }}>
            <CustomInput
              label="Source of Funds"
              name="sourceOfFund"
              value={selectedSourceOfFundName}
              isEdit={true}
              isRequired={true}
              isDropdown={true}
              helperText={"This field is required"}
              onClick={() => setSourceOfFundOpen(true)}
              error={fieldError.sourceOfFund}
            />
            <Dropdown
              bottomHeight={({ maxHeight }) => maxHeight * 0.5}
              isOpen={sourceOfFundOpen}
              handleDismiss={() => setSourceOfFundOpen(false)}
            >
              <ProfileDropdown
                title="Business nature"
                itemList={sourceOfFundListV2}
                selectedItem={selectedSourceOfFund}
                handleClick={handleSelectSourceOfFund}
              />
            </Dropdown>
            <Typography
              color="textPrimary"
              className={`${classes.baseFont} ${classes.label}`}
              style={{ marginTop: "14px" }}
            >
              What is the purpose of your investment?*
            </Typography>

            <FormControl>
              <RadioGroup
                className={` ${classes.radio}`}
                name="cashPurpose"
                value={selectedInvsObj}
                onChange={e => setSelectedInvsObj(e.target.value)}
              >
                {invsObj.map(obj => (
                  <FormControlLabel
                    key={obj.id}
                    value={obj.id}
                    control={<Radio color="primary" />}
                    label={
                      <Typography className={`${classes.baseFont}`}>
                        {obj.name}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <CustomInput
              label="Do you hold a position (or related to such persons) in any Public or Political offices, Including being its committee or council member?"
              name="isPoliticalPosition"
              value={otherInfo.isPoliticalPosition}
              onChange={handleChangeOtherInfo}
              isEdit={true}
              isRequired={false}
              isRadio={true}
            />
            <CustomInput
              label="Are you a tax resident of any country other than Malaysia?"
              name="otherTaxResident"
              value={otherInfo.otherTaxResident}
              onChange={handleChangeOtherInfo}
              isEdit={true}
              isRequired={false}
              isRadio={true}
            />
            {otherInfo.otherTaxResident === "Yes" && (
              <>
                <CustomInput
                  label="Tax Country"
                  name="taxCountry"
                  placeholder="Please select a country"
                  value={otherInfo.taxCountry}
                  onClick={() => setTaxCountryOpen(true)}
                  isEdit={true}
                  isRequired={true}
                  isDropdown={true}
                  error={fieldError.taxCountry}
                />

                <Dropdown
                  bottomHeight={({ maxHeight }) => maxHeight - maxHeight / 10}
                  isOpen={isTaxCountryOpen}
                  handleDismiss={() => setTaxCountryOpen(false)}
                >
                  <div style={{ margin: "16px 16px 24px" }}>
                    <Typography
                      color="secondary"
                      style={{ ...styles.baseFont, ...styles.dropdownTitle }}
                    >
                      Update country
                    </Typography>
                    {/* Search */}
                    <div style={{ marginTop: "16px" }}>
                      <TextField
                        placeholder="Type country name here"
                        variant="outlined"
                        fullWidth
                        value={taxCountrySearch}
                        onChange={e => {
                          setTaxCountrySearch(e.target.value);
                          findCountry(e.target.value);
                        }}
                        inputProps={{
                          style: {
                            ...styles.baseFont,
                            padding: "12px 16px",
                            color: "#333",
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment>
                              <Icon
                                className="material-icons-outlined"
                                style={{ cursor: "pointer", color: "#0091DA" }}
                              >
                                search
                              </Icon>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                    {/* Country List */}
                    <div style={{ marginTop: "24px" }}>
                      {countryList.map((item, index) => {
                        return (
                          <CountryItem
                            key={index}
                            country={item.name}
                            isSelected={selectedTaxCountry === item.code}
                            onClick={() => handleSelectTaxCountry(item.code)}
                            containerStyles={
                              selectedTaxCountry &&
                              index === 0 && {
                                borderBottom: "1px solid #C4C6C5",
                                paddingBottom: "24px",
                              }
                            }
                          />
                        );
                      })}
                    </div>
                  </div>
                </Dropdown>

                <CustomInput
                  label="Tax ID Number"
                  name="taxIdNo"
                  value={otherInfo.taxIdNo}
                  onChange={e => handleChangeTaxId(e.target.value)}
                  handleBlur={() => {
                    handleError(
                      "taxIdNo",
                      otherInfo.taxIdNo && otherInfo.taxIdNo.length <= 20
                        ? false
                        : true
                    );
                  }}
                  isEdit={true}
                  isRequired={true}
                  error={fieldError.taxIdNo}
                  helperText={
                    otherInfo.taxIdNo.length > 20
                      ? "Maximum length for tax id number is 20"
                      : "This field is required"
                  }
                />
              </>
            )}
          </div>
          <div style={{ margin: "24px 16px" }}>
            <div className={classes.termCondition}>
              <Checkbox
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
                color="primary"
                style={{ margin: 0, padding: 0 }}
              />
              <div style={{ marginLeft: "12px" }}>
                <Typography
                  style={{
                    fontFamily: "FSElliotPro",
                    fontSize: "14px",
                    fontWeight: "normal",
                    lineHeight: 1.29,
                    color: "#787878",
                    marginBottom: "8px",
                  }}
                >
                  I have read and agreed to{" "}
                  <span
                    style={{ color: "#0076cf" }}
                    onClick={() =>
                      handleOpenPDF(
                        "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf"
                      )
                    }
                  >
                    Principal's Terms & Conditions
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "#0076cf" }}
                    onClick={() =>
                      window.my.navigateTo({
                        url:
                          "/pages/link/link?url=" +
                          encodeURIComponent(
                            "https://www.principal.com.my/en/privacy-notice-my/mv"
                          ),
                      })
                    }
                  >
                    Privacy Notice
                  </span>
                </Typography>
                <Typography
                  color="secondary"
                  style={{
                    fontSize: "14px",
                    lineHeight: 1.57,
                    color: "#505050",
                  }}
                  className={classes.baseFont}
                >
                  For any information that cannot be edited within the app,
                  kindly contact our Customer Care Centre for assistance.
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={classes.btnStep1}>
        {stepOnboarding === 1 ? (
          <CustomButton
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={() => {
              setStepOneData({
                state: selectedState,
                race: selectedRace,
                email: onBoardingPayload.email,
              });
              stepOneGo({
                selectedRace,
                selectedState,
                email: onBoardingPayload.email,
              });
              dataLayerGTM(
                "click_tag",
                "Click",
                "Button",
                `Click Onboarding Submit Step 2`
              );
            }}
          />
        ) : (
          <CustomButton
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            disabled={disabledUpdate || !isChecked ? true : false}
            onClick={handleSubmit}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.goal.profile,
    ...state.onBoardingReducer,
    error: state.goal.error,
    loading: state.goal.loading,
    maritalStatusList: state.goal.maritalStatusList,
  };
};

const mapDispatchToProps = dispatch => ({
  getOccupations: data => dispatch(getOccupations(data)),
  getNOBV2: data => dispatch(getNOBV2(data)),
  getAnnualIncomeV2: data => dispatch(getAnnualIncomeV2(data)),
  getCountryV2: data => dispatch(getCountryV2(data)),
  clearError: () => dispatch(clearError()),
  updateProfile: data => dispatch(updateProfile(data)),
  getRaceV2: () => dispatch(getRaceV2()),
  getStateV2: () => dispatch(getStateV2()),
  resetData: () => dispatch(resetData()),
  getSourceOfFundV2: () => dispatch(getSourceOfFundV2()),
  getMaritalStatus: () => dispatch(getMaritalStatus()),
  setProfileInfoV2: data => dispatch(setProfileInfoV2(data)),
});

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);
export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(ProfileForm);
