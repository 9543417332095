import { SET_INVEST_AMOUNT, SET_LAST_AMOUNT_INVEST } from "./constant";

export const setInvestAmount = payload => {
  return {
    type: SET_INVEST_AMOUNT,
    payload
  };
};

export const setLastAmountInvest = payload => {
  return {
    type: SET_LAST_AMOUNT_INVEST,
    payload: !payload ? null : payload
  };
};
