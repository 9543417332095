// SET
export const SET_FPX_BANK_LIST = "SET_FPX_BANK_LIST";
export const SET_FPX_LOADING = "SET_FPX_LOADING";
export const SET_SELECTED_BANK = "SET_SELECTED_BANK";
export const SET_AMOUNT = "SET_AMOUNT";
export const SET_SUCCESS = "SET_SUCCESS";

// GET
export const GET_FPX_BANK_LIST = "GET_FPX_BANK_LIST";

// POST
export const POST_FPX_PAYMENT = "POST_FPX_PAYMENT";
export const POST_PAYMENT_REQUEST = "POST_PAYMENT_REQUEST";
export const SET_PAYMENT_SUCCESS = "SET_PAYMENT_SUCCESS";

// POST
export const CREATE_SIGNATURE = "CREATE_SIGNATURE";
export const SET_COSTUMER_DATA = "SET_COSTUMER_DATA";
export const SET_TRX_REF_NO = "SET_TRX_REF_NO";
export const SET_PAY_CAT = "SET_PAY_CAT";
