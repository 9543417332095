import { SET_CUST_FUND_DETAIL } from "./constant";

const initialState = {
  custFundDetail: {}
};

export default function custFundDetailReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_CUST_FUND_DETAIL:
      return {
        ...state,
        custFundDetail: payload
      };

    default:
      return state;
  }
}
