import { Icon, InputAdornment, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import {
  AlertAccount,
  Alert,
  BackButton,
  CustomButton,
  CustomInput,
  DefaultLoader,
  Dropdown,
  NumberFormatCustom,
  RiskCard,
  Tabs,
} from "../../components";
import { SwitchListProvider } from "../../contexts/SwitchListContext";
import { useSwitching } from "../../contexts/SwitchingContext";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { dataMapping } from "../../utils/functions/dataMappingSwitching";
import { getRoundedValue } from "../../utils/functions/getRoundedValue";
import { groupFunds } from "../../utils/functions/groupFunds";
import {
  getAccountStatusV2,
  getCustomerPortfolio,
  getSubAccountInvest,
} from "../dashboard/redux/actions";
import {
  createFundV2,
  getFundList,
  getScoreList,
} from "../fundSelection/redux/action";
import { checkWithdrawalMaxAmount, getCustomerGoals } from "../redux/action";
import { getResultAssessment } from "../riskAssessment/redux/action";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../constants/subaccount-status";
import "./style.switch.scss";

const MAX_AMOUNT = 30000;
const MIN_AMOUNT = 10;

const switchTabs = [
  { id: 1, name: "Financial Goals", path: "goals" },
  { id: 2, name: "Unit Trust Funds", path: "funds" },
];

const getInitialTab = () => {
  const currentDashboardTab = JSON.parse(
    localStorage.getItem("currentTab") ||
      JSON.stringify({
        id: 1,
        name: "Save",
      })
  );

  return currentDashboardTab.id === 1 ? "goals" : "funds";
};

const Switching = ({
  getCustomerPortfolio,
  getCustomerGoals,
  getFundList,
  getScoreList,
  getAccountStatusV2,
  getSubAccountInvest,
  checkWithdrawalMaxAmount,
  getResultAssessment,
  createFundV2,

  history,
  loadingCustomerPortfolio,
  loadingCustomerGoals,
  loadingRiskProfile,
  fundListLoading,
  scoreListLoading,
  createFundLoadingV2,
  subaccountInvestLoading,
  subaccountSaveLoading,
  loadingMaxAmount,
  maxAmount,
  customerGoals,
  customerPortfolio,
  fundList,
  riskProfile,
  scoreList,
  createdPortfolio,
  children,
}) => {
  const {
    switched,
    setSwitched,
    screenHistory,
    handleInvestIntro,
    resource, // redux state
    setResource, // saga action
    isAlreadyRequestedCreatePortfolio,
  } = useSwitching();
  const [formStatus, setFormStatus] = React.useState("idle");

  const [subaccount, setSubaccount] = React.useState({
    "from-status": "",
    "to-status": "",
    open: false,
  });

  const [target, setTarget] = React.useState({
    open: false,
    name: "",
  });

  const [show, setShow] = React.useState({
    riskInfo: false,
    riskProfileExpired: false,
  });

  const [
    depositLowerThanMinThresholdWarning,
    setDepositLowerThanMinThresholdWarning,
  ] = React.useState(false);

  /**
   * @description to prevent fetch again when mp still fetching or when user click tabs or button multiple time
   * we can prevent that by using this
   */
  const isFetching = React.useRef({
    from: false,
    to: false,
    onTabChanged: false,
    onSwitched: false,
  });

  // important condition
  // we based the state of the form based on this
  // wether its error or not
  const isSubmitting = formStatus === "submitting";

  const maxSwitchableAmount = React.useMemo(
    () => getRoundedValue(maxAmount.totalAmount, 2),
    [maxAmount]
  );

  const lessThan = Boolean(switched.amount < MIN_AMOUNT);
  const biggerThan = Boolean(switched.amount > MAX_AMOUNT);
  const biggerThanMaxAmount = Boolean(
    !lessThan && getRoundedValue(switched.amount, 2) > maxSwitchableAmount
  );

  const isLoadingAfterSelecting =
    subaccountInvestLoading ||
    subaccountSaveLoading ||
    loadingMaxAmount ||
    createFundLoadingV2;

  const isFromLoading =
    loadingCustomerGoals ||
    loadingCustomerPortfolio ||
    scoreListLoading ||
    loadingRiskProfile;

  const isToLoading =
    loadingCustomerGoals ||
    fundListLoading ||
    scoreListLoading ||
    loadingRiskProfile;

  /**
   * @description id and product type to get the actual selected goal/fund
   * @type {{id: string; productType: "invest" | "save"}}
   */
  const preselectResource = React.useMemo(() => {
    if (!history.location.state || resource) return null;

    const id = history.location.state.id;
    const productType = history.location.state.productType;

    return {
      id,
      productType,
    };
  }, [history.location.state, resource]);

  const initialTab = React.useMemo(() => getInitialTab(), []);

  /**
   * @description determine current tab
   * @type {"funds" | "goals"}
   */
  const currentTab = React.useMemo(() => {
    const paths = history.location.pathname.split("/");

    // return empty string as default value when user still in root page
    // `/switching/create` we're here
    if (paths.length === 3) return "";

    return paths[paths.length - 1];
  }, [history.location.pathname]);

  
  const goalListShouldShowLoading = currentTab === "goals" && (loadingCustomerGoals || loadingRiskProfile);
  const fundListShouldShowLoading = currentTab === "funds" && (loadingCustomerPortfolio || fundListLoading || scoreListLoading)

  /**
   * @description indicator for showing which products to user
   * eg: when user click `from` or `to` it'll show either `funds` or `goals`
   * @type {"invest" | "save"}
   */
  const currentProductType = React.useMemo(
    () => (currentTab === "funds" ? "invest" : "save"),
    [currentTab]
  );

  const filterSameAccountSameFund = React.useCallback(
    resource => {
      const portfolioCifPlanId = (customerPortfolio[0] || {}).cifPlanId;

      const data = dataMapping({
        ...resource,
        cifPlanId: portfolioCifPlanId,
        productType: currentProductType,
        type: target.name,
      });

      return target.name === "to" && switched.from.name
        ? !(
            switched.from.cifPlanId === data.cifPlanId &&
            switched.from.fundCd === data.fundCd
          )
        : target.name === "from" && switched.to.name
        ? !(
            switched.to.cifPlanId === data.cifPlanId &&
            switched.to.fundCd === data.fundCd
          )
        : resource;
    },
    [switched, currentProductType, target]
  );

  const filteredCustomerGoals = React.useMemo(
    () => customerGoals.filter(filterSameAccountSameFund),
    [customerGoals, switched, currentProductType, target]
  );

  // conditionaly use `fundList` if user is in `to` dropdown
  // For requirement, Ethan said the user can select funds that he or she has never invested in.
  const tabsResource = React.useMemo(
    () =>
      ({
        save: filteredCustomerGoals,
        invest: groupFunds({
          scoreList,
          funds: (target.name === "from" ? customerPortfolio : fundList).filter(
            filterSameAccountSameFund
          ),
        }),
      }[currentProductType]),
    [
      target,
      currentProductType,
      scoreList,
      customerPortfolio,
      customerGoals,
      fundList,
    ]
  );

  const isSubaccountSuspended = React.useMemo(
    () =>
      subaccount["from-status"] === "Suspended" ||
      subaccount["to-status"] === "Suspended",
    [subaccount]
  );

  const forbiddenSubaccount = React.useMemo(() => {
    // checking sequence -> `from` -> `to`

    const fromForbiddenStatus = NOT_ALLOWED_ACCOUNT_STATUS.indexOf(
      subaccount["from-status"]
    );

    const toForbiddenStatus = NOT_ALLOWED_ACCOUNT_STATUS.indexOf(
      subaccount["to-status"]
    );

    return fromForbiddenStatus > -1 || toForbiddenStatus > -1;
  }, [subaccount]);

  // for empty state indicator
  const resourceLength = React.useMemo(
    () =>
      ({
        "save-length": filteredCustomerGoals.length,
        "invest-length":
          target.name === "from" ? customerPortfolio.length : fundList.length,
      }[`${currentProductType}-length`]),
    [target, currentProductType, customerPortfolio, customerGoals, fundList]
  );

  const handleNext = () => {
    if (formStatus === "submitted") return;

    if (
      lessThan ||
      biggerThan ||
      biggerThanMaxAmount ||
      !Boolean(switched.from.name) ||
      !Boolean(switched.to.name)
    ) {
      return setFormStatus("submitting");
    }

    const depositDiff = maxSwitchableAmount - switched.amount;

    if (depositDiff > 0 && depositDiff < MIN_AMOUNT) {
      setDepositLowerThanMinThresholdWarning(true);
      return;
    }

    if (forbiddenSubaccount) {
      setSubaccount(prev => ({ ...prev, open: true }));
      return;
    }

    setFormStatus("submitted");

    setResource(null);

    setSwitched(prev => ({
      ...prev,
      maxAmount: maxSwitchableAmount,
    }));

    history.push("/switching/summary");
  };

  const handleMaxAmount = () => {
    if (!Boolean(switched.from.name)) return;

    setSwitched(prev => ({
      ...prev,
      amount: maxSwitchableAmount,
      maxAmount: maxSwitchableAmount,
    }));
  };

  const handleDismissDropdown = () => {
    setTarget(prev => ({ ...prev, open: false }));

    history.back();
  };

  const handleDismissDepositLowerThanMinThresholdPrompt = () => {
    setDepositLowerThanMinThresholdWarning(false);
    setSwitched(prev => ({ ...prev, amount: maxSwitchableAmount }));
  };

  const handleSwitched = ({
    type,
    fundCd,
    cifPlanId,
    name,
    productType,
    accountNo,
    switchableAmount,
    fee,
  }) => () => {
    if (isFetching.current.onSwitched) return;

    isFetching.current.onSwitched = true;

    if (target.open && (target.name === "to" || target.name === "from") && currentTab === "funds") {
      const riskProfileLength = Object.values(riskProfile).length;

      // user doesnt have risk profile
      if (riskProfileLength === 0) {
        handleInvestIntro();
        isFetching.current.onSwitched = false;
        return;
      }

      // user risk profile is expired
      if (
        riskProfileLength > 0 &&
        new Date() >=
          moment(riskProfile.expiredDate)
            .startOf("date")
            .toDate()
      ) {
        setShow(prev => ({ ...prev, riskProfileExpired: true }));
        isFetching.current.onSwitched = false;
        return;
      }

      isFetching.current.onSwitched = false;
    }

    const callback = freshlyCreatedPortfolio => {
      if (freshlyCreatedPortfolio) {
        isAlreadyRequestedCreatePortfolio.current = true;
      }

      const portfolio = customerPortfolio[0] || freshlyCreatedPortfolio || createdPortfolio || {};

      const isProductTypeMPSAVE = productType === "save";

      const payload =
        type === "from"
          ? {
              from: {
                fundCd,
                cifPlanId,
                name,
                productType,
                switchableAmount,
                accountNo,
                fee,
              },
            }
          : {
              to: {
                fundCd,
                cifPlanId: isProductTypeMPSAVE
                  ? cifPlanId
                  : portfolio.cifPlanId, // invest cifplanid is same for all fund
                name,
                productType,
                accountNo: isProductTypeMPSAVE
                  ? accountNo
                  : portfolio.accountNo, // invest accountno is same for all fund
              },
            };

      setSwitched(prev => ({
        ...prev,
        [type]: payload[type],
      }));

      if (productType === "invest") {
        // prevent fetching subaccount mpinvest
        // if we already fetched it in `from` or `to`
        // because we know that mpinvest subaccount is same for all funds
        if (
          switched.from.productType === "invest" ||
          switched.to.productType === "invest"
        ) {
          if (type === "to") return onSwitched();

          return checkWithdrawalMaxAmount(
            `${cifPlanId}?fundCd=${fundCd}`,
            onSwitched
          );
        }

        getSubAccountInvest(portfolio.accountNo, subaccountData => {
          setSubaccount(prev => ({
            ...prev,
            [`${type}-status`]: subaccountData?.sts,
          }));
          if (type === "to") return onSwitched();

          // check max amount only in `from`
          checkWithdrawalMaxAmount(`${cifPlanId}?fundCd=${fundCd}`, onSwitched);
        });
      } else {
        getAccountStatusV2(accountNo, subaccountData => {
          setSubaccount(prev => ({
            ...prev,
            [`${type}-status`]: subaccountData?.sts,
          }));
          if (type === "to") return onSwitched();

          // same
          checkWithdrawalMaxAmount(cifPlanId, onSwitched);
        });
      }
    };

    if (
      !isAlreadyRequestedCreatePortfolio.current && 
      productType === "invest" &&
      type === "to" &&
      customerPortfolio.length === 0
    ) {
      if(createFundLoadingV2) return;

      // need to set name here
      // so that when user click fund it'll be sync with the loading
      setSwitched(prev => ({ ...prev, [type]: { ...prev[type], name } }));

      // after come back from risk assessment
      // we can safely assume that this is `to` with `fundList`
      // in here we immediately create portfolio

        createFundV2(
        {
          cifId: switched.cifid,
          fundsPayload: [{ fundCode: fundCd }],
        },
        callback
      );

      return;
    }

    callback();
  };

  const onTabChanged = path => () => {
    const destination = `/switching/create/switch-${target.name}/${path}`;

    // this doesnt count as `PUSH`
    // so we need to manually push
    history.replace(destination);

    // return on still fetching
    // return on subsequent visit
    if (
      isFetching.current.onTabChanged ||
      (screenHistory.current.length > 0 &&
        screenHistory.current.includes(destination))
    )
      return;

    isFetching.current.onTabChanged = true;

    const reset = () => {
      screenHistory.current.push(destination);
      isFetching.current.onTabChanged = false;
    };

    // get another resources in case user changed tab

    // we know that based on `initialTab`
    // that whatever it is, it will be fetched already
    // so in here we double check that
    // if `initialTab` is equal to `funds` then we just need to add
    // `&&` and reverse the checking
    // if current tab is equal to goals then we fetch goals
    // same as the other way around
    if (initialTab === "funds" && path === "goals") {
      // if we already visited `from` goals
      // then we dont need to fetch `customerGoals` again
      if (
        screenHistory.current.length > 0 &&
        screenHistory.current.includes("/switching/create/switch-from/goals")
      )
        return reset();

      getCustomerGoals(reset);
      return;
    }

    if (initialTab === "goals" && path === "funds") {
      // action cannot be awaited
      // so in order to reset the `isFetching` variable properly
      // we need to do callbacks
      //TODO find a cleaner way to do this without callbacks
      if (target.open && target.name === "from") {
        getCustomerPortfolio(() => {
          // if we already visited `to` funds
          // then we dont need to fetch `scoreList` again
          if (
            screenHistory.current.length > 0 &&
            screenHistory.current.includes("/switching/create/switch-to/funds")
          )
            return reset();

          getScoreList(reset);
        });
      } else {
        getFundList(() => {
          const hasVisitedSwitchFrom =
            screenHistory.current.length > 0 &&
            screenHistory.current.includes(
              "/switching/create/switch-from/funds"
            );

          // if we already visited `from` funds
          // then we dont need to fetch `scoreList` again
          if (hasVisitedSwitchFrom) return reset();

          // for switching
          // we know that `fundList` dont have `cifPlanId` and `accountNo` (for payload)
          // so we need to check wether or not user has visited the `customerPortfolio` tab
          // if havent visited yet then we need to fetch that
          return getCustomerPortfolio(() => getScoreList(reset));
        });
      }

      return;
    }
  };

  const onFrom = () => {
    const destination = `/switching/create/switch-from/${initialTab}`;

    setTarget(prev => ({ ...prev, name: "from" }));

    // already have preselect
    // user already click on `from` input meaning we already got the resources
    // user already have the resource to switch from
    if (
      preselectResource ||
      (screenHistory.current.length > 0 &&
        screenHistory.current.includes(destination)) ||
      Object.values(switched.from).filter(item => Boolean(item)).length > 0
    ) {
      // should be safe to assume we already get the necessary resources to display the preselect item
      // so in here we just call the dropdown
      setTarget(prev => ({ ...prev, open: true }));

      history.push(destination);
      return;
    }

    if (isFetching.current.from) return;

    isFetching.current.from = true;

    const cb = () => {
      setTarget(prev => ({ ...prev, open: true }));

      history.push(destination);
      isFetching.current.from = false;
    };

    /**
     * @description If this variable is equal to true, Means we already visited the `to` `funds`
     *  we dont need to fetch `scoreList` again
     * @type {boolean}
     */
    const isToFundsVisited =
      screenHistory.current.length > 0 &&
      screenHistory.current.includes("/switching/create/switch-to/funds");

    /**
     * @description If this variable is equal to true, Means we already visited the `to` `goals`
     *  we dont need to fetch `riskProfile` nor `customerGoals` again
     * @type {boolean}
     */
    const isToGoalsVisited =
      screenHistory.current.length > 0 &&
      screenHistory.current.includes("/switching/create/switch-to/goals");

    // only get resources based on what user initially want
    // this `initialTab` is based on "save" | "invest" dashboard
    // if user comming from `dashboard/save` its value will be `goals`
    // same as the other way around
    if (initialTab === "funds") {
      getCustomerPortfolio(() => (isToFundsVisited ? cb() : getScoreList(cb)));

      return;
    }

    if (isToGoalsVisited) return cb();

    getCustomerGoals(() => getResultAssessment(switched.cifid, cb));
  };

  const onTo = () => {
    const destination = `/switching/create/switch-to/${initialTab}`;

    setTarget(prev => ({ ...prev, name: "to" }));

    // user already click on `to` input meaning we already got the resources
    // user already have the resource to switch from
    if (
      (screenHistory.current.length > 0 &&
        screenHistory.current.includes(destination)) ||
      Object.values(switched.to).filter(item => Boolean(item)).length > 0
    ) {
      setTarget(prev => ({ ...prev, open: true }));

      history.push(destination);

      return;
    }

    if (isFetching.current.to) return;

    isFetching.current.to = true;

    const cb = () => {
      setTarget(prev => ({ ...prev, open: true }));

      history.push(destination);
      isFetching.current.to = false;
    };

    /**
     * @description If this variable is equal to true, Means we already visited the `from` `funds`
     *  we dont need to fetch `scoreList` again
     * @type {boolean}
     */
    const isFromFundsVisited =
      screenHistory.current.length > 0 &&
      screenHistory.current.includes("/switching/create/switch-from/funds");

    /**
     * @description If this variable is equal to true, Means we already visited the `from` `goals`
     *  we dont need to fetch `riskProfile` nor `customerGoals` again
     * @type {boolean}
     */
    const isFromGoalsVisited =
      screenHistory.current.length > 0 &&
      screenHistory.current.includes("/switching/create/switch-from/goals");

    if (initialTab === "funds") {
      getFundList(() => (isFromFundsVisited ? cb() : getScoreList(cb)));

      return;
    }

    // `from` goals already visited or
    // if `preselectResource` is exist `&&` havent touch `from` input(which will be open initially showing customer goals)
    // then we know that `customerGoals` and `riskProfile` already fetched
    // if thats the case then we just need to call the dropdown
    if (
      isFromGoalsVisited ||
      (initialTab === "goals" && preselectResource && !isFromGoalsVisited)
    )
      return cb();

    getCustomerGoals(() => getResultAssessment(switched.cifid, cb));
  };

  const onSwitched = () => {
    // wait until max amount and subaccount fetched
    if (isLoadingAfterSelecting) return;

    isFetching.current.onSwitched = false;

    handleDismissDropdown();
  };

  React.useEffect(() => {
    const unlisten = history.listen(({ action, location }) => {
      if (action === "PUSH") {
        screenHistory.current.push(location.pathname);
      }

      if (action === "POP") {
        // set resource back to null prevent fullscreen loading when user come from goal/fund detail
        // this also resetting the input when user leave the `/switching` page
        setResource(null);
      }
    });

    if (
      history.action === "POP" &&
      history.location.pathname.includes("switch-")
    ) {
      const paths = history.location.pathname.split("/");
      if (paths.length > 2) {
        // need to open up dropdown after go back from invest intro page
        // `/switching/create/switch-to/"funds"|"goals"` we're here

        // we're splitting this part -> `**/switch-to
        // and getting the second one which is
        // "from" | "to"
        setTarget({ open: true, name: paths[paths.length - 2].split("-")[1] });
        setSwitched(resource);
      }
    }

    return unlisten;
  }, []);

  React.useEffect(() => {
    // dont fetch if there's no preselect resource or
    // on subsequent screen changed
    if (!preselectResource || screenHistory.current.length > 0) return;

    // get necessary resources to display the preselect item
    if (preselectResource.productType === "invest") {
      getCustomerPortfolio();
    } else {
      getCustomerGoals();
    }
  }, [preselectResource]);

  React.useEffect(() => {
    // return if there's no preselect resource
    // return  on subsequent screen changed
    if (!preselectResource || screenHistory.current.length > 0) return;

    const { id, productType } = preselectResource;

    let result = null;

    const isInvest = productType === "invest";

    if (isInvest) {
      result = customerPortfolio.find(portfolio => portfolio.fundCd === id);
    } else {
      result = customerGoals.find(goal => goal.id === id);
    }

    if (!result) return;

    const { type, ...data } = dataMapping({
      ...result,
      type: "from",
      productType,
    });

    setSwitched(prev => ({ ...prev, from: data }));

    const determineToFetchRiskProfile = () => {
      if (initialTab === "goals") {
        getResultAssessment(switched.cifid);
      }
    };

    const getSubaccount = {
      invest: getSubAccountInvest,
      save: getAccountStatusV2,
    };

    getSubaccount[productType](data.accountNo, subaccountData => {
      setSubaccount(prev => ({ ...prev, "from-status": subaccountData?.sts }));
      checkWithdrawalMaxAmount(
        isInvest ? `${data.cifPlanId}?fundCd=${data.fundCd}` : data.cifPlanId,
        determineToFetchRiskProfile
      );
    });
  }, [history]);

  // we only show loading if user is not yet fetched the resources and already have preselect resource
  // meaning user go to this screen via goal/fund detail
  // this condition solve the problem when user click `from` input its should not showing this again
  if (
    screenHistory.current.length === 0 &&
    preselectResource &&
    (loadingCustomerGoals ||
      loadingCustomerPortfolio ||
      loadingMaxAmount ||
      loadingRiskProfile)
  )
    return <DefaultLoader />;

  return (
    <>
      <AlertAccount
        title={`Account ${isSubaccountSuspended ? "Suspended" : "Inactive"}`}
        firstText={
          isSubaccountSuspended
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={subaccount.open}
        handleClose={() => {
          setSubaccount(prev => ({ ...prev, open: false }));
        }}
      />
      <Alert
        title="Ops, insufficient balance for Switch out"
        description="Your financial goal has insufficient balance to switch out. The minimum switch out limit is RM10."
        isOpen={depositLowerThanMinThresholdWarning}
        isCancel={false}
        handleClose={handleDismissDepositLowerThanMinThresholdPrompt}
        handleAction={handleDismissDepositLowerThanMinThresholdPrompt}
        handleCancel={handleDismissDepositLowerThanMinThresholdPrompt}
      />
      <Dropdown
        isOpen={target.open}
        handleDismiss={handleDismissDropdown}
        snapPoints={({ maxHeight }) => maxHeight - maxHeight / 28}
        header={
          <>
            <div className="l-switch__title">
              <Icon
                className="material-icons-outlined"
                onClick={() => {
                  history.back();
                  setTarget(prev => ({
                    ...prev,
                    open: false,
                  }));
                }}
                style={{ cursor: "pointer", color: "#0091DA" }}
              >
                arrow_back
              </Icon>
              <h1>Select a goal or fund you want to switch {target.name}</h1>
            </div>

            <Tabs>
              {switchTabs.map((tab, idx) => (
                <Tabs.Item
                  onClick={onTabChanged(tab.path)}
                  selected={history.location.pathname.includes(tab.path)}
                  key={idx}
                >
                  {tab.name}
                </Tabs.Item>
              ))}
            </Tabs>
            {currentProductType === "invest" &&
              !(
                fundListLoading &&
                scoreListLoading &&
                loadingCustomerPortfolio
              ) && (
                <InvestHeader
                  tabsResource={tabsResource}
                  history={history}
                  riskProfile={riskProfile}
                  setShow={setShow}
                />
              )}
          </>
        }
      >
        {goalListShouldShowLoading || fundListShouldShowLoading ? (
          <div className="spinner-blueish spinner-blueish--center" />
        ) : (
          <SwitchListProvider
            value={{
              tabsResource,
              resourceLength,
              isLoadingAfterSelecting,
              currentProductType,
              handleSwitched,

              show,
              setShow,
              setSubaccount,
              forbiddenSubaccount,
              customerPortfolio,
              fundList,
              switchedType: target.name,
            }}
          >
            {children}
          </SwitchListProvider>
        )}
      </Dropdown>

      <div className="l-switch">
        <BackButton
          handleBack={() => {
            history.back();
          }}
          title="Switch"
        />
        <div>
          <CustomInput
            label="From"
            name="from"
            placeholder="Select a Goal or fund"
            value={switched.from.name}
            isEdit={true}
            isDropdown={true}
            onClick={onFrom}
            error={isSubmitting && !Boolean(switched.from.name)}
            className="c-input--ellipsis"
            isLoading={target.name === "from" && isFromLoading}
            // need to disabled if user open `from` input
            // because if it doesnt
            // it will have race condition
            // and all sorts of problem
            disabled={target.name === "to" && isToLoading}
          />
          <CustomInput
            label="To"
            name="to"
            placeholder="Select a Goal or fund"
            value={switched.to.name}
            isEdit={true}
            isDropdown={true}
            onClick={onTo}
            error={isSubmitting && !Boolean(switched.to.name)}
            className="c-input--ellipsis"
            isLoading={target.name === "to" && isToLoading}
            // same as loc 874
            disabled={target.name === "from" && isFromLoading}
          />

          <div className="l-switch__separator" />

          <label className="c-input__label">Enter Amount</label>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="0.00 (minimum RM10)"
            value={switched.amount === "0" ? "" : switched.amount}
            onChange={({ target: { value } }) => {
              setSwitched(prev => ({ ...prev, amount: value }));
            }}
            error={
              isSubmitting && (lessThan || biggerThan || biggerThanMaxAmount)
            }
            helperText={
              isSubmitting &&
              (lessThan
                ? "Minimum RM 10.00"
                : biggerThan
                ? "Maximum RM 30.000"
                : biggerThanMaxAmount &&
                  "Switched amount cannot exceeded your max amount")
            }
            FormHelperTextProps={{
              className: "c-input__text",
            }}
            inputProps={{
              className: "c-input c-input--outline",
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: NumberFormatCustom,
              className: "c-input__wrapper",

              startAdornment: (
                <InputAdornment position="start">
                  <p className="c-input__start-adornment-text">RM</p>
                </InputAdornment>
              ),
              endAdornment: (
                <div
                  className="c-input__end-adornment"
                  onClick={handleMaxAmount}
                >
                  <p className="c-input__end-adornment-text">Max Amount</p>
                </div>
              ),
            }}
          />
          <h4 className="l-switch__switchable-amount">
            Switchable amount:{" "}
            <span>
              RM{" "}
              <NumberFormat
                value={maxSwitchableAmount || 0}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
              />
            </span>
          </h4>
          <p className="l-switch__info">
            Full Cash Out will be initiated if the balance of units are below
            the minimum unit holding balance threshold.
          </p>
        </div>

        <div
          style={{
            position: "sticky",
            bottom: 0,
            width: "100%",
            display: "block",
            marginTop: "auto",
          }}
        >
          <CustomButton
            text="Next"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={handleNext}
          />
        </div>
      </div>
    </>
  );
};

const InvestHeader = ({ tabsResource, riskProfile, setShow, history }) => {
  const { handleInvestIntro } = useSwitching();
  const resourcesKeys = Object.keys(tabsResource);
  const riskProfileExist = Object.values(riskProfile).length > 0;

  if (history.location.pathname.includes("/switch-from")) return null;

  if (riskProfileExist && resourcesKeys.length > 0) {
    return (
      <RiskCard
        riskIndex={Object.keys(tabsResource).indexOf(
          riskProfile.resultNameEn.toLowerCase()
        )}
        level={riskProfile.resultNameEn.toLowerCase()}
        handleRiskInfo={() => {
          dataLayerGTM(
            "click_tag",
            "Click",
            "Link",
            `Risk Profile description link (the "i" icon)`
          );
          setShow(prev => ({ ...prev, riskInfo: true }));
        }}
      />
    );
  }

  return (
    <div className="l-switch__new-invest-user">
      <h3>Please Complete the risk assessment to switch to unit trust funds</h3>
      <span onClick={handleInvestIntro}>Lets Start</span>
    </div>
  );
};

const withConnect = connect(
  state => ({
    loadingCustomerPortfolio: state.dashboardReducer.loadingCustomerPortfolio,
    loadingCustomerGoals: state.goal.loadingCustomerGoals,
    fundListLoading: state.fundSelectionReducer.fundListLoading,
    createFundLoadingV2: state.fundSelectionReducer.createFundLoadingV2,
    createdPortfolio: state.fundSelectionReducer.fundResult,
    customerGoals: state.goal.customerGoals || [],

    // need riskprofilecode to be on root object
    // for grouping funds
    // also salesfee for sales charge
    customerPortfolio: (state.dashboardReducer.customerPortfolio || []).map(
      ({ fund, ...portfolio }) => ({
        fund,
        riskProfileCode: fund.riskProfileCode,
        salesFeePerc: fund.salesFeePerc,
        ...portfolio,
      })
    ),
    //Non tradeable Fund - Cant perform Transfer To / Switch To
    fundList: (state.fundSelectionReducer.fundList || []).filter(
      fund => fund.isActive === "1"
    ),
    subaccountInvestLoading: state.dashboardReducer.subAccStsInvestLoading,
    subaccountSaveLoading: state.dashboardReducer.accountStatusLoading,
    loadingMaxAmount: state.goal.loadingMaxAmount,
    maxAmount: state.goal.maxAmount || {},
    riskProfile: state.riskAssessmentReducer.riskProfileResult,
    loadingRiskProfile: state.riskAssessmentReducer.resultAssessmentLoading,
    scoreList: state.fundSelectionReducer.scoreList || [],
    scoreListLoading: state.fundSelectionReducer.scoreListLoading,
  }),
  {
    getCustomerGoals,
    getCustomerPortfolio,
    getFundList,
    getScoreList,
    getAccountStatusV2,
    getSubAccountInvest,
    getResultAssessment,
    checkWithdrawalMaxAmount,
    createFundV2,
  }
);

export default compose(
  withRouter,
  withConnect
)(Switching);
