import {
  call,
  put as putSaga,
  put,
  select,
  all,
  takeLatest
} from "redux-saga/effects";
import {
  receiveCountries,
  failedCountries,
  receiveGenders,
  failedGenders,
  receiveDoctypes,
  failedDoctypes,
  receiveMaritalStatus,
  failedMaritalStatus,
  receiveProvinces,
  failedProvinces,
  receivePostcode,
  failedPostcode,
  receiveOccupations,
  failedOccupations,
  receiveMonthlyIncomes,
  failedMonthlyIncomes,
  receiveIncomeSources,
  failedIncomeSources,
  receiveBanks,
  receiveNationalities,
  failedBanks,
  fundCategoriesReceived,
  receiveDistricts,
  showError
} from "../actions";
import {
  GET_ANNUAL_INCOME_REQUEST,
  GET_ANNUAL_INCOME_SUCCESS,
  GET_ANNUAL_INCOME_FAILURE,
  GET_BUSINESS_NATURE_REQUEST,
  GET_BUSINESS_NATURE_SUCCESS,
  GET_BUSINESS_NATURE_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_MARITAL_STATUS_REQUEST,
  GET_MARITAL_STATUS_SUCCESS,
  GET_MARITAL_STATUS_FAILURE,
  GET_OCCUPATION_REQUEST,
  GET_OCCUPATION_SUCCESS,
  GET_OCCUPATION_FAILURE,
  GET_RACE_REQUEST,
  GET_RACE_SUCCESS,
  GET_RACE_FAILURE
} from "../constants";
import * as endpoints from "../../utils/endpoints";
import * as api from "../../utils/api";
import { get } from "../../utils/api";
import { API_URL } from "../../utils/axios";
import history from "../../history";

const config = {
  headers: {
    "Content-Language": "en"
    // userid: "f2d4b229-6981-483f-ae4b-6501ac51b2eb"
  }
};

function* getOccupation() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/occupation`);
    const { data } = res;

    yield put({ type: GET_OCCUPATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_OCCUPATION_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}

function* getBusinessNature() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/nature-of-business`);
    const { data } = res;

    yield put({ type: GET_BUSINESS_NATURE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_NATURE_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}

function* getAnnualIncome() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/annual-income`);
    const { data } = res;

    yield put({ type: GET_ANNUAL_INCOME_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ANNUAL_INCOME_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}

function* getRace() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/race`);
    const { data } = res;

    yield put({ type: GET_RACE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_RACE_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}
function* getMaritalStatus() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/marital-status`);
    const { data } = res;

    yield put({ type: GET_MARITAL_STATUS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_MARITAL_STATUS_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}
function* getCountry() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/country`);
    const { data } = res;

    yield put({ type: GET_COUNTRY_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_COUNTRY_FAILURE, payload: error.message });
    yield put(showError("Connection Error.\nPlease try again later."));
  }
}

export function* countriesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/countries?fields=abbr%2Ccode%2Cname",
      config
    );
    yield putSaga(receiveCountries(response.data.data));
  } catch (error) {
    yield putSaga(failedCountries(error));
  }
}

export function* nationalitiesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/nationalities?fields=abbr%2Ccode%2Cname",
      config
    );
    yield putSaga(receiveNationalities(response.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* gendersWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/genders?fields=code%2Cname",
      config
    );
    yield putSaga(receiveGenders(response.data.data));
  } catch (error) {
    yield putSaga(failedGenders(error));
  }
}

export function* banksWorkerSaga() {
  try {
    // const language = yield select(state => state.globalReducer.selectedLanguage);
    const response = yield call(
      get,
      "/customer/api/v1/banks?fields=code,name,nameEn,nameTh",
      config
    );
    yield putSaga(receiveBanks(response.data.data));
  } catch (error) {
    console.log(error);
    yield putSaga(failedBanks());
  }
}

export function* doctypesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/documentTypes?fields=code%2Cname",
      config
    );

    yield putSaga(receiveDoctypes(response.data.data));
  } catch (error) {
    yield putSaga(failedDoctypes(error));
  }
}

export function* maritalStatusesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/maritalStatuses?fields=code%2Cname",
      config
    );

    yield putSaga(receiveMaritalStatus(response.data.data));
  } catch (error) {
    yield putSaga(failedMaritalStatus(error));
  }
}

export function* requestDistrictsSaga({ payload }) {
  try {
    const { provinceCode, type } = payload;
    const { data: response } = yield call(
      get,
      `/customer/api/v1/districts?fields=provinceCode%2Ccode%2Cname&provinceCode=${provinceCode}`,
      config
    );
    yield putSaga(receiveDistricts({ data: response.data, type }));
  } catch (error) {
    console.log("requestDistrictSaga", error);
    // yield putSaga(failedDistricts(error));
  }
}

export function* provincesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/provinces?fields=code%2Cname",
      config
    );

    yield putSaga(receiveProvinces(response.data.data));
  } catch (error) {
    yield putSaga(failedProvinces(error));
  }
}

export function* postcodesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage ? action.selectedLanguage : yield select(state => state.globalReducer.selectedLanguage);
    let provinceCode = action.provinceCode;
    let districtCode = action.districtCode;
    if (!provinceCode && action.form) {
      provinceCode = yield select(
        state => state.form[action.form].values.province.code
      );
    }
    // call the function, pass in param
    const response = yield call(
      get,
      "/customer/api/v1/postCodes?fields=code&provinceCode=" +
        provinceCode +
        "&districtCode=" +
        districtCode,
      config
    );
    if (response.data) {
      yield putSaga(receivePostcode(response.data.data));
    }
  } catch (error) {
    yield putSaga(failedPostcode(error));
  }
}

export function* occupationsWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/occupations?fields=code%2Cname",
      config
    );

    yield putSaga(receiveOccupations(response.data.data));
  } catch (error) {
    yield putSaga(failedOccupations(error));
  }
}

export function* monthlyIncomesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/monthlyIncomes?fields=code%2Cname",
      config
    );

    yield putSaga(receiveMonthlyIncomes(response.data.data));
  } catch (error) {
    yield putSaga(failedMonthlyIncomes(error));
  }
}

export function* incomeSourcesWorkerSaga(action) {
  try {
    // let language = action.selectedLanguage;
    const response = yield call(
      get,
      "/customer/api/v1/sourceOfIncomes?fields=code%2Cname",
      config
    );

    yield putSaga(receiveIncomeSources(response.data.data));
  } catch (error) {
    yield putSaga(failedIncomeSources(error));
  }
}

export function* fundCategoriesWorkerSaga() {
  try {
    const enCall = yield call(get, endpoints.FUND_CATEGORIES, config);
    const thCall = yield call(get, endpoints.FUND_CATEGORIES, {
      ...config,
      headers: {
        "Content-Language": "th"
      }
    });

    const [
      {
        data: { data: enData }
      },
      {
        data: { data: thData }
      }
    ] = yield all([enCall, thCall]);

    yield putSaga(
      fundCategoriesReceived({
        en: enData,
        th: thData
      })
    );
  } catch (error) {
    console.log("error", error);
  }
}

export function* GlobalSaga() {
  yield all([
    takeLatest(GET_OCCUPATION_REQUEST, getOccupation),
    takeLatest(GET_BUSINESS_NATURE_REQUEST, getBusinessNature),
    takeLatest(GET_ANNUAL_INCOME_REQUEST, getAnnualIncome),
    takeLatest(GET_RACE_REQUEST, getRace),
    takeLatest(GET_MARITAL_STATUS_REQUEST, getMaritalStatus),
    takeLatest(GET_COUNTRY_REQUEST, getCountry)
  ]);
}
