import React from "react";
import { withRouter } from "react-router";
import { compose } from "underscore";

const AboutPage = ({ history }) => {
  const accessToken = localStorage.getItem("mpAccessToken");

  const handleClick = () => {
    history.push("/onBoarding/form-step-one");
  };

  return (
    <>
      <h2>About</h2>
      <button onClick={handleClick}>Go to Profile form</button>
      <p>token onMessage: {accessToken ? accessToken : "no token"}</p>
    </>
  );
};

export default compose(withRouter)(AboutPage);
