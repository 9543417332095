const styles = {
  baseFont: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5
  },
  label: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: 1.57,
    marginBottom: "8px"
  },
  prefix: {
    fontWeight: "700"
  },
  row: {
    display: "flex",
    flexDirection: "column"
  },
  radio: {
    marginBottom: "14px"
  },
  error: {
    margin: "4px 0 0 0",
    color: "error",
    fontFamily: "FSElliotPro",
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: 1.57
  },
  form: { marginBottom: "16px" },
  inputField: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    padding: "12px 16px",
    borderColor: "rgba(51, 51, 51, 0.2)"
  },
  period: {
    fontFamily: "FSElliotPro",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: 1.5,
    padding: "12px 16px 12px 0",
    borderColor: "rgba(51, 51, 51, 0.2)"
  }
};

export default styles;
