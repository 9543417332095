import React from "react";
import NumberFormat from "react-number-format";
import { Typography } from "@material-ui/core";
import { API_URL } from "../../utils/axios";
import "./style.scss";
import { icons } from "../../constants";

const getRoundedValue = (val, digit) => {
  const value = parseFloat(val);
  return Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit);
};

const levelColor = [
  "linear-gradient(54.17deg, #1793D7 35.22%, #65C8FF 100%)",
  "linear-gradient(61.78deg, #084C95 31.24%, #1793D7 88.69%)",
  "linear-gradient(54.17deg, #013369 35.22%, #0060C7 100%)"
];

export const RiskInfo = ({ handleClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onClick={handleClick}
    >
      <path
        fillRule="evenodd"
        stroke="#0D629F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        clipRule="evenodd"
      />
      <path
        stroke="#0D629F"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M12 17v-4"
      />
      <circle cx="12" cy="8" r="2" fill="#0D629F" />
    </svg>
  );
};

const OverviewCard = ({
  totalAsset,
  totalReturn,
  lastUpdate,
  type,
  projectedReturn = "3.70%",
  riskLevel,
  riskIndex,
  handleShowInvestorPopup
}) => {
  return (
    <>
      <div className={`c-card-overview c-card-overview--${type}`}>
        <p>
          {type === "invest"
            ? "Total Investment Assets"
            : "Total Saving Assets"}
        </p>

        <div className="c-card-overview__total-assets">
          <span>{totalAsset < 0 && "-"}RM </span>
          <NumberFormat
            value={
              totalAsset > 0
                ? getRoundedValue(totalAsset, 2)
                : getRoundedValue(totalAsset, 2) * -1
            }
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </div>
        <Typography
          style={{
            fontFamily: "FSElliotPro",
            fontSize: "16px",
            fontWeight: "normal",
            lineHeight: 1.5,
            color: "#ddf75b",
            margin: "0.5rem 0"
          }}
        >
          Total returns:{" "}
          <span style={{ fontWeight: 700 }}>
            {totalReturn < 0 ? "-" : "+"}RM{" "}
            <NumberFormat
              value={
                totalReturn > 0
                  ? getRoundedValue(totalReturn, 2)
                  : getRoundedValue(totalReturn, 2) * -1
              }
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </span>
        </Typography>

        <Typography
          style={{
            fontFamily: "FSElliotPro",
            fontWeight: "normal",
            fontSize: "14px",
            lineHeight: "22px",
            opacity: 0.7,
            color: "#fff",
            width: "100%",
            textAlign: "center"
          }}
        >
          {lastUpdate !== "Invalid date" && `Last update: ${lastUpdate}`}
          <br />
          {API_URL.mp === "https://mpsit.principal.com.my/api/v1"
            ? "SIT (for debugging)"
            : ""}
        </Typography>
      </div>

      {type === "save" && (
        <div className="c-card-overview--extended">
          <div>
            <div>
              <span
                style={{
                  color: "#1793D7",
                  fontWeight: 900,
                  fontSize: "1.2rem"
                }}
              >
                {`${projectedReturn} p.a`}
              </span>
              <span
                style={{
                  color: "#29B9D5",
                  position: "relative",
                  bottom: "0.5rem",
                  marginLeft: "0.3rem",
                  fontSize: "0.8rem",
                  fontWeight: 700
                }}
              >
                new{" "}
                <svg
                  width="18"
                  height="16"
                  viewBox="0 0 18 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.3327 4L9.99935 10.3333L6.66602 7L1.66602 12"
                    stroke="#29B9D5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.334 4H16.334V8"
                    stroke="#29B9D5"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
            <span
              style={{
                color: "#333333",
                fontSize: "0.813rem",
                display: "inline-block",
                marginTop: "0.5rem"
              }}
            >
              Gross return excluding fees
            </span>
          </div>
          <div
            style={{
              color: "#1278CC",
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              window.my.navigateTo({
                url:
                  "/pages/link/link?url=" +
                  encodeURIComponent(
                    process.env.REACT_APP_ENV.trim() === "production"
                      ? "https://www.principal.com.my/en/immfd/mv"
                      : "https://www.test.principal.com.my/en/immfd/mv"
                  )
              });
            }}
          >
            <img
              src={icons.infoPrimary}
              alt="info primary"
              style={{ width: "1.2rem", height: "1.2rem" }}
            />
            <span style={{ fontSize: "0.813rem", marginLeft: "0.4rem" }}>
              Fund info
            </span>
          </div>
        </div>
      )}

      {type === "invest" && (
        <div className="c-card-overview--extended">
          <div>
            <p style={{ fontSize: "12px" }}>Your risk profile</p>
            <div className="c-card-overview__extended-title">
              <h3
                style={{
                  background: levelColor[riskIndex],
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  backgroundClip: "text",
                  fontSize: "1.1rem",
                  fontWeight: 700,
                  marginTop: "0.2rem"
                }}
              >
                <span style={{ textTransform: "capitalize" }}>
                  {riskLevel}{" "}
                </span>
                investor
              </h3>
            </div>
          </div>
          <RiskInfo
            handleClick={handleShowInvestorPopup}
          />
        </div>
      )}
    </>
  );
};

export default OverviewCard;
