import React, { useState } from "react";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  StepNumber,
  CustomButton,
  DefaultLoader,
  AlertInfo,
} from "../../components";
import NumberFormat from "react-number-format";
import { Checkbox } from "@material-ui/core";
import crypto from "crypto";
import { deeplinkCheckout, clearDLCheckoutErr } from "../DeepLink/redux/action";
import { investCheckout } from "../fundSelectionStep3/redux/actions";
import { GENERAL_SAVING, GOAL_CREATION, INVEST } from "../redux/constant";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import "./styles.scss";

const riskDisclosure =
  "https://www.principal.com.my/en/ut-loan-financing-risk-my/mv";
const principalTnCPDF =
  "https://www.principal.com.my/sites/default/files/pdf-uploads/Principal%20Mini%20Program%20%28IMMF%20Class%20D%29%20Terms%20and%20Conditions.pdf";

const DeeplinkSummary = props => {
  const {
    history,
    deeplinkGeneralSaving = {},
    deeplinkAmount,
    profile,
    match: { params },
    checkoutDLLoading,
    fundDetail,
    deeplinkCheckout,
    selectedPromo,
    checkouDLError,
    clearDLCheckoutErr,
    location,
    fundResult = {},
    investCheckout,
    investCheckoutLoading,
    selectedFundDetail
  } = props;

  const { from = GENERAL_SAVING } = params;
  const [isCheck, setCheck] = useState(false);

  const getPromoPayload = () => {
    let promoPayload = "";
    if (Object.keys(selectedPromo).length === 0) {
      return promoPayload;
    }
    if (selectedPromo.selectedReferralCode) {
      promoPayload = selectedPromo.selectedReferralCode;
      return promoPayload;
    }
    if (Array.isArray(selectedPromo.eligibilityRules.codes)) {
      const getPromoPayload = selectedPromo.eligibilityRules.codes.filter(
        code => code.isPublic
      );
      if (Array.isArray(getPromoPayload) && getPromoPayload.length > 0) {
        promoPayload = getPromoPayload[0].code;
      }
    }
    return promoPayload;
  };

  const generateRandomHex = length =>
    crypto.randomBytes(length).toString("hex");

  const createPayload = (
    params,
    deeplinkAmount,
    profile,
    promoCode,
    from,
    location,
    fundResult,
    deeplinkGeneralSaving
  ) => {
    let productId, cifPlanId;

    switch (from) {
      case INVEST:
        productId = fundResult.productId;
        cifPlanId = fundResult.cifPlanId;
        break;
      case GENERAL_SAVING:
        productId = location.state?.withPromo
          ? deeplinkGeneralSaving.productId
          : deeplinkGeneralSaving.product.id;
        cifPlanId = deeplinkGeneralSaving.id;
        break;
      case GOAL_CREATION:
        productId = deeplinkGeneralSaving.productId;
        cifPlanId = deeplinkGeneralSaving.id;
        break;
      default:
        productId = deeplinkGeneralSaving.productId;
        cifPlanId = deeplinkGeneralSaving.id;
        break;
    }
    let payload = {
      campaignCode: promoCode,
      cifPlanId,
      productId,
      funds: [
        {
          fundCd: params.code,
          amount: parseInt(deeplinkAmount),
        },
      ],
      customerChannelCode: "Mini Program",
      trxRefNo: generateRandomHex(14),
      amount: deeplinkAmount.toString(),
      ccy: "MYR",
      cifId: profile?.masterId || "",
      name:
        process.env.REACT_APP_ENV.trim() === "production"
          ? profile?.fullName
          : "PnN@m3()/PyN .-&B''UYER",
      email: profile?.email || "",
    };

    if (from === GENERAL_SAVING || from === GOAL_CREATION) {
      payload.cifID = profile?.masterId || "";
      delete payload.cifId;
      delete payload.funds;
    }
    return payload;
  };

  const handleCheckout = () => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      "Deeplink Cash In Summary - Continue"
    );
    let promoCode = getPromoPayload();
    const payload = createPayload(
      params,
      deeplinkAmount,
      profile,
      promoCode,
      from,
      location,
      selectedFundDetail || fundResult,
      deeplinkGeneralSaving
    );

    if (from === INVEST) {
      investCheckout(payload);
    } else {
      deeplinkCheckout(payload);
    }
  };

  const principalTnC = () => {
    window.my.showLoading();
    window.my.postMessage({
      api: "downloadFile",
      pdf: principalTnCPDF,
      event: "openPdf",
    });
  };

  const handleNavigate = url => {
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(url),
    });
  };

  if (checkoutDLLoading || investCheckoutLoading) {
    return <DefaultLoader />;
  }
  return (
    <>
      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={checkouDLError}
        isTimeout
        handleAction={() => {
          clearDLCheckoutErr();
        }}
      />
      <StepNumber
        onClick={() => {
          history.back();
        }}
        gtm="Deeplink Summary - Fund info"
      />
      <div className="dl-container-summary">
        <div className="dl-container-summary__box-name">
          <p className="dl-container-summary__fund-name">{fundDetail.name}</p>
          <p className="dl-container-summary__goal-name">Inclusive sales charge of {fundDetail?.salesFeePerc || "0"}%</p>
        </div>
      </div>
      <div className="dl-summary-value-box">
        <p className="dl-summary-value-box__cahsin-label">Total Cash In</p>
        <p className="dl-summary-value-box__total-cashin">
          RM
          <NumberFormat
            value={deeplinkAmount}
            thousandSeparator
            displayType="text"
            decimalScale={2}
            fixedDecimalScale={true}
          />
        </p>
      </div>
      <div className="investment-summery-term">
        <Checkbox
          checked={isCheck}
          onChange={() => {
            setCheck(!isCheck);
          }}
          color="primary"
          style={{ margin: 0, padding: 0 }}
        />
        <div className="investment-summery-term__text">
          <p style={{ marginBottom: "8px" }}>
            I have read and agreed to{" "}
            <span onClick={principalTnC}>Principal’s Terms & Conditions</span>,
            and the Unit Trust Loan Financing{" "}
            <span onClick={() => handleNavigate(riskDisclosure)}>
              Risk Disclosure Statement
            </span>
            .
          </p>
          <p>
            I acknowledge the investment risk. and aware that the fund is not
            E-money, deposit, or capital guaranteed/protected by PIDM. Losses
            are not covered by the Capital Market Compensation Fund.
          </p>
        </div>
      </div>
      <div className="invest-summary-confirm">
        <CustomButton
          text="Cash In"
          variant="contained"
          color="#fff"
          backgroundColor="#1278CC"
          disabled={!isCheck}
          onClick={handleCheckout}
        />
      </div>
    </>
  );
};

const withConnect = connect(
  state => ({
    ...state.promoReducer,
    ...state.deeplinkReducer,
    profile: state.goal.profile,
    fundDetail: state.fundSelectionReducer.fundDetailByCode,
    fundResult: state.fundSelectionReducer.fundResult,
    investCheckoutLoading: state.investCheckoutReducer.investCheckoutLoading,
    selectedFundDetail: state.fundSelectionReducer.selectedFundDetail,
  }),
  { deeplinkCheckout, clearDLCheckoutErr, investCheckout }
);

export default compose(
  withRouter,
  withConnect
)(DeeplinkSummary);
