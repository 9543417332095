import { put as sagaPut, call, select } from "redux-saga/effects";
import api, { setAuthHeader, resetAuthHeader } from "../utils/axios";
import { REFRESH_ACCESS_TOKEN_ENPOINT } from "./endpoints";
import * as actions from "../containers/Authentication/redux/actions";
import { showError } from "../globalRedux/actions";
import { API_URL } from "../utils/axios";
import { checkBlacklistEndPoint } from "./functions/endpointBlacklistAuth";
import { addAuthorizationHeader, checkLastTokenReq } from "./performAuthorized";
import { getAuthTNG } from "./getAuthTNG";
import isWithinTimeframe from "./functions/apiRequestTimestamp";

let LOOPING_FLAG = false;
const getMpUserInfo = () => {
  let infoUser = JSON.parse(localStorage.getItem("mpUserInfo"));
  if (!infoUser) {
    return {};
  }
  return infoUser;
};

const getRefreshToken = state => state.auth.refreshToken;

/**
 * * send log error to backend
 * * START
 */
export const sendLogError = async payload => {
  let infoUser = getMpUserInfo();
  payload.fullName = infoUser.fullName || "Not Found";
  payload.masterId = infoUser.masterId || "Not Found";
  payload.mobileNo = infoUser.mobileNo || "Not Found";
  const res = await api.post(`${API_URL.mp}/logger/log`, payload);
  console.log(res);
};
/**
 * * send log error to backend
 * * END
 */

function* setShowError(error) {
  let errorMessageDefault =
    "We are facing some technical issues, and we are working very hard to resolve the issue.";

  if (typeof error === "object" && "data" in error && "message" in error.data) {
    if (process.env.REACT_APP_ENV.trim() !== "production") {
      errorMessageDefault = error.data.message;
    }
  }
  yield sagaPut(showError(errorMessageDefault));
}

const setLastRequestTimestamp = payload => ({
  type: "SET_LAST_REQUEST",
  payload,
});

function checkResponse(res) {
  let result = true;
  let payload = {
    api: res.config.url || window.location.pathname,
    message: "API response is empty",
    status: res.status || "Not Found",
  };

  if (res.status === 200) {
    const data = res.data;
    if (
      data === undefined ||
      data === null ||
      (typeof data === "string" && data.length == 0)
    ) {
      result = false;
    }

    if (typeof data === "object" && "errorCode" in data) {
      payload.message = data;
      result = false;
    }

    if (!result) {
      if (res.config.url !== `${API_URL.mp}/logger/log`) {
        sendLogError(payload);
      }
    }

    return result;
  }
}

api.interceptors.request.use(
  async config => {
    let count = 1;

    const actionLoop = async () => {
      if (
        checkLastTokenReq() &&
        localStorage.getItem("reqTokenDate") !== null &&
        checkBlacklistEndPoint(config.url) &&
        count < 15
      ) {
        if (!LOOPING_FLAG) {
          getAuthTNG(count);
          LOOPING_FLAG = true;
        }
        count++;
        await new Promise(resolve => setTimeout(resolve, 1000));
        await actionLoop();
      }
    };

    const setNewToken = event => {
      if (localStorage.getItem("reqTokenDate") !== null) {
        config.headers["Authorization"] = `Bearer ${event.detail}`;
      }
    };
    document.addEventListener("CUSTOMER_SET_TOKEN_UTILS", setNewToken);
    await actionLoop();
    LOOPING_FLAG = false;
    config.metadata = { startTime: new Date() };
    document.removeEventListener("CUSTOMER_SET_TOKEN_UTILS", setNewToken);
    return config;
  },
  error => {
    LOOPING_FLAG = false;
    return Promise.reject(error);
  }
);

const networkErrors = [];

window.addEventListener("online", () => {
  for (let i = networkErrors.length - 1; i >= 0; i--) {
    const error = networkErrors[i];
    window.log({
      path: error?.config?.url,
      status: "408",
      res: error?.message,
      req: {
        params: error?.config?.params,
        body: error?.config?.data || error?.request?.body,
      },
      requestDate: error?.config?.metadata?.startTime,
      responseDate: error?.config?.metadata?.endTime,
      responseTime: `${error?.duration}ms`,
    });
    networkErrors.splice(i);
  }
});

api.interceptors.response.use(
  response => {
    let responseResult = checkResponse(response);
    response.config.metadata.endTime = new Date();
    response.duration =
      response.config.metadata.endTime - response.config.metadata.startTime;

    if (!responseResult || response.status === 204) {
      window.log({
        path: response.config.url,
        status: String(response.status || ""),
        res: response.data,
        req: response?.config?.params || response?.request?.body,
        requestDate: response.config.metadata.startTime,
        responseDate: response.config.metadata.endTime,
        responseTime: `${response.duration}ms`,
      });
      sendLogError({
        path: response.config.url,
        status: String(response.status || ""),
        res: response.data,
        req: response?.config?.params || response?.request?.body,
        requestDate: response.config.metadata.startTime,
        responseDate: response.config.metadata.endTime,
        responseTime: `${response.duration}ms`,
      });
      throw new Error("API Response is empty");
    }
    return response;
  },
  error => {
    error.config.metadata.endTime = new Date();

    error.duration =
      error.config.metadata.endTime - error.config.metadata.startTime;

    if (
      error instanceof Error &&
      "message" in error &&
      error.message.includes("Network Error")
    ) {
      networkErrors.push({
        config: error.config,
        message: error.message,
        duration: error.duration,
        request: error.request,
      });
    }

    if (!error.config.url.includes("logger")) {
      window.log({
        path: error?.config?.url,
        status: String(error?.response?.status || ""),
        res: error?.response?.data,
        req: {
          params: error?.config?.params,
          body: error?.config?.data || error?.request?.body,
        },
        requestDate: error?.config?.metadata?.startTime,
        responseDate: error?.config?.metadata?.endTime,
        responseTime: `${error.duration}ms`,
      });
    }

    if (error.response) {
      let payload = {
        api: error.config.url || window.location.pathname,
        message: error.response.data.message || "---",
        errorResponse: true,
        status: error.response.status || "Not Found",
      };
      if (error.config.url !== `${API_URL.mp}/logger/log`) {
        sendLogError(payload);
      }

      if (error.response.status === 401) {
        window.eventEmitter.emit("invalid_token");
      }
    }
    return Promise.reject(error.response);
  }
);

export function* get(endpoint, options, statePath) {
  try {
    LOOPING_FLAG = false;
    let token = yield select(state => state.auth.MPtoken);

    const config = addAuthorizationHeader(options, token);
    if (statePath) {
      const cacheExpired = yield call(isWithinTimeframe, Date.now(), endpoint);

      if (!cacheExpired) {
        const res = yield call(api.get, endpoint, config || {});
        yield sagaPut(
          setLastRequestTimestamp({
            timestamp: Date.now(),
            key: endpoint,
          })
        );
        return res;
      }

      const [reducer, data] = statePath.split(".");

      return yield select(state => ({
        data: state[reducer][data],
      }));
    } else {
      return yield call(api.get, endpoint, config || {});
    }
  } catch (error) {
    LOOPING_FLAG = false;
    yield call(setShowError, error);
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* post(endpoint, data, options) {
  try {
    LOOPING_FLAG = false;
    let token = yield select(state => state.auth.MPtoken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.post, endpoint, data, config || {});
  } catch (error) {
    LOOPING_FLAG = false;
    // summary transfer/switching
    // error is already handle by its own
    // no need to prompt error
    if (!endpoint.includes("/transfer")) {
      yield call(setShowError, error);
    }
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* put(endpoint, data, options) {
  try {
    LOOPING_FLAG = false;
    let token = yield select(state => state.auth.MPtoken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.put, endpoint, data, config || {});
  } catch (error) {
    LOOPING_FLAG = false;
    yield call(setShowError, error);
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}
export function* patch(endpoint, data, options) {
  try {
    LOOPING_FLAG = false;
    let token = yield select(state => state.auth.MPtoken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.patch, endpoint, data, config || {});
  } catch (error) {
    LOOPING_FLAG = false;
    yield call(setShowError, error);
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

export function* del(endpoint, data, options) {
  try {
    LOOPING_FLAG = false;
    let token = yield select(state => state.auth.MPtoken);
    const config = addAuthorizationHeader(options, token);
    return yield call(api.delete, endpoint, data, config || {});
  } catch (error) {
    LOOPING_FLAG = false;
    yield call(setShowError, error);
    return yield call(checkForTokenError, error, retryRequest, "accessToken");
  }
}

function* retryRequest(error) {
  const newToken = yield call(refreshAccessToken);
  const newError = { ...error };
  newError.config.headers.Authorization = `Bearer ${newToken}`;
  return yield call(api.request, newError.config);
}

function checkForTokenError(error, onAccessTokeErrorCallback, paramToCheck) {
  // if (error.response) {
  //   console.log(error.response);
  //   const accessTokenError = error.response.data.find(
  //     e => e.param === paramToCheck
  //   );
  //   if (accessTokenError) {
  //     return yield call(onAccessTokeErrorCallback, error);
  //   }
  //
  //   throw error.response;
  // } else if (error.request) {
  //   throw createSimpleErrorObject("Server does not respond");
  // }
  //
  // throw createSimpleErrorObject("Please, check your internet connection");
  throw createSimpleErrorObject(error);
}

function* refreshAccessToken() {
  try {
    const refreshToken = yield select(getRefreshToken);
    const response = yield call(api.post, REFRESH_ACCESS_TOKEN_ENPOINT, {
      refreshToken,
    });
    setAuthHeader(response.data.accessToken);
    yield sagaPut(actions.accessTokenUpdatedAction(response.data));
    return response.data.accessToken;
  } catch (error) {
    return yield call(
      checkForTokenError,
      error,
      onRefreshTokenError,
      "refreshToken"
    );
  }
}

function* onRefreshTokenError(error) {
  yield sagaPut(actions.signOutAction());
  throw error;
}

function createSimpleErrorObject(error) {
  if (
    error.code === "ECONNABORTED" ||
    (error.message && error.message.includes("timeout"))
  ) {
    return {
      message: "timeoutError",
    };
  }
  return {
    response: error?.response?.data,
  };
}

export const sendPersistLog = async payload => {
  try {
    let infoUser = getMpUserInfo();
    payload.fullName = infoUser.fullName || "Not Found";
    payload.masterId = infoUser.masterId || "Not Found";
    payload.mobileNo = infoUser.mobileNo || "Not Found";
    return await api.post(`${API_URL.mp}/logger/log`, payload);
  } catch (err) {
    return err;
  }
};

export { resetAuthHeader, setAuthHeader };
