import {
  SELECTED_LANGUAGE,
  SET_STEP,
  SHOW_ERROR,
  HIDE_ERROR,
  UNAUTHORIZED_NOTIF,
  CLEAR_UNAUTHORIZED_NOTIF,
  SET_AUTH
} from "../constants/index";

const initialState = {
  lang: "en",
  step: null,
  isModalErrorOpen: false,
  unauthorizedNotif: false,
  TNGToken: null,
  errorMessage:
    "We are facing some technical issues, and we are working very hard to resolve the issue."
};

export const globalDataReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_LANGUAGE:
      return {
        ...state,
        lang: payload
      };

    case SET_STEP:
      return {
        ...state,
        step: payload
      };

    case SHOW_ERROR:
      return {
        ...state,
        isModalErrorOpen: true,
        errorMessage: payload
      };

    case HIDE_ERROR:
      return {
        ...state,
        isModalErrorOpen: false
      };

    case UNAUTHORIZED_NOTIF:
      return {
        ...state,
        unauthorizedNotif: true
      };

    case CLEAR_UNAUTHORIZED_NOTIF:
      return {
        ...state,
        unauthorizedNotif: false
      };
    case SET_AUTH:
      return {
        ...state,
        TNGToken: payload
      };
    default:
      return state;
  }
};
