export const GET_PROJECTED_RETURN = "GET_PROJECTED_RETURN";
export const GET_PROJECTED_RETURN_SUCCESS = "GET_PROJECTED_RETURN_SUCCESS";
export const GET_PROJECTED_RETURN_FAILED = "GET_PROJECTED_RETURN_FAILED";

export const SET_PROFILE_INFOV2 = "SET_PROFILE_INFOV2";
export const STEP_STARTED = "STEP_STARTED";

export const ENV_INVEST = "ENV_INVEST";
export const ENV_SAVE = "ENV_SAVE";

export const GET_TOTAL_UNIT_FUNDS = "GET_TOTAL_UNIT_FUNDS";
export const GET_TOTAL_UNIT_FUNDS_SUCCESS = "GET_TOTAL_UNIT_FUNDS_SUCCESS";
export const GET_TOTAL_UNIT_FUNDS_FAILED = "GET_TOTAL_UNIT_FUNDS_FAILED";

export const PROJECTED_RETURN_INVEST = "PROJECTED_RETURN_INVEST";
export const PROJECTED_RETURN_INVEST_SUCCESS =
  "PROJECTED_RETURN_INVEST_RETURN_SUCCESS";
export const PROJECTED_RETURN_INVEST_FAILED = "PROJECTED_RETURN_INVEST_FAILED";

export const UPDATE_CUSTOMER_DUPLICATE = "UPDATE_CUSTOMER_DUPLICATE";
export const UPDATE_CUSTOMER_DUPLICATE_SUCCESS =
  "UPDATE_CUSTOMER_DUPLICATE_SUCCESS";
export const UPDATE_CUSTOMER_DUPLICATE_FAILED =
  "UPDATE_CUSTOMER_DUPLICATE_FAILED";
