import { all, takeLatest, call, put } from "redux-saga/effects";
import { INVEST_CHECKOUT, CREATE_FUND } from "./constant";
import * as api from "../../../utils/api";
import { API_URL } from "../../../utils/axios";
import { v4 as uuidv4 } from "uuid";
import { setStep } from "../../../globalRedux/actions";
import { investCheckoutSuccess, investCheckoutFailed } from "./actions";
import { setRSPPayload } from "../../deposit/redux/action";
import { resetSelectedPromo } from "../../promoList/redux/action";
import { setLastAmountInvest } from "../../fundSelectionStep2/redux/actions";
function* investCheckoutSaga({ payload }) {
  try {
    const {
      customerChannelCode,
      trxRefNo,
      amount,
      ccy,
      cifId,
      name,
      email,
      funds = [],
      cifPlanId,
      productId,
      campaignCode,
    } = payload;

    let generate = { ...payload };

    generate.cifID = cifId;
    delete generate.funds;
    delete generate.fundsPayload;
    delete generate.cifId;
    delete generate.cifPlanId;
    delete generate.productId;
    delete generate.campaignCode;
    const resGenerate = yield call(
      api.post,
      `${API_URL.ppg}/payment/generate`,
      generate
    );

    const { data } = resGenerate;

    const requestCheckout = {
      transactionHostRefNo: uuidv4(),
      cifPlanId,
      productId,
      trxRefNo,
      funds,
      campaignCode,
      accountNo: "",
      trxType: "FPX",
      amountCcy: "MYR",
      amount: parseFloat(amount),
      unit: 0,
      feePercent: 0,
      feeCcy: "MYR",
      feeAmount: 0,
      sourceOfFund: "",
      bankCd: "",
      bankAccountNo: "",
      bankAccountName: "",
      paymentTypeCd: "",
      ppgRefNo: "",
      pgRefNo: "",
      recipientBank: "",
      type: "",
      approveTime: new Date().toString(),
      trxUnitPaymentProvider: "",
      trxUnitPaymentType: "",
      status: "",
    };

    const resCheckout = yield call(
      api.post,
      `${API_URL.mp}/checkout`,
      requestCheckout
    );

    yield put(investCheckoutSuccess(resCheckout.data));

    let cifID = cifId;

    const ppgPostDirect = {
      customerChannelCode,
      payCatCd: null,
      trxRefNo,
      amount,
      ccy,
      cifID,
      refId: trxRefNo.slice(2, trxRefNo.length),
      name,
      email,
      lang: "en",
      signature: data.signature,
      resUrl: `${API_URL.mp}/webhooks/res-url`,
      backUrl: `${API_URL.backURL}`,
    };

    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    myForm.action = `${API_URL.ppg}/entry`;

    for (const key in ppgPostDirect) {
      if (Object.hasOwnProperty.call(ppgPostDirect, key)) {
        const element = ppgPostDirect[key];
        const myInput = document.createElement("input");
        myInput.type = "hidden";
        myInput.name = key;
        myInput.id = key;
        myInput.value = element;
        myForm.appendChild(myInput);
      }
    }
    document.body.appendChild(myForm);
    yield put(setStep(null));
    localStorage.setItem(
      "currentTab",
      JSON.stringify({
        id: 2,
        name: "Invest",
      })
    );
    yield put(setLastAmountInvest());
    yield put(resetSelectedPromo());
    myForm.submit();
  } catch (error) {
    yield put(investCheckoutFailed(error.message));
  }
}

export function* investCheckoutWorker() {
  yield all([takeLatest(INVEST_CHECKOUT, investCheckoutSaga)]);
}
