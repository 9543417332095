/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
import { addLocaleData } from "react-intl";
import enLocaleData from "react-intl/locale-data/en";
import thLocaleData from "react-intl/locale-data/th";

import {
  DEFAULT_LOCALE,
  EN_LOCALE,
  THAI_LOCALE
} from "./containers/App/constants"; // eslint-disable-line
import enTranslationMessages from "./translations/en";
import thaiTranslationMessages from "./translations/th";

export const appLocales = [EN_LOCALE, THAI_LOCALE];

addLocaleData(enLocaleData);

addLocaleData(thLocaleData);

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  return Object.keys(messages).reduce((formattedMessages, key) => {
    let message = messages[key];
    if (!message && locale !== DEFAULT_LOCALE) {
      message = defaultFormattedMessages[key];
    }
    return Object.assign(formattedMessages, { [key]: message });
  }, {});
};

export const translationMessages = {
  [EN_LOCALE]: formatTranslationMessages(EN_LOCALE, enTranslationMessages),
  [THAI_LOCALE]: formatTranslationMessages(THAI_LOCALE, thaiTranslationMessages)
};
