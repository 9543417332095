import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  TextField,
  InputAdornment,
} from "@material-ui/core";
import "./index.scss";
import styles from "./styles";
import NumberFormat from "react-number-format";
import {
  CustomButton,
  NumberFormatCustom,
  DefaultLoader,
  AlertInfo,
  Alert,
  InputBasic,
  StepLabel,
} from "../../components";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setInvestAmount, setLastAmountInvest } from "./redux/actions";
import { setStep } from "../../globalRedux/actions";
import { setRSPPayload } from "../deposit/redux/action";
import { FUNDDETAILS } from "../redux/constant";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
import { clearErrorInvestCheckout } from "../fundSelectionStep3/redux/actions";
import {
  getPromo,
  setSelectedPromo,
  resetSelectedPromo,
} from "../promoList/redux/action";
import { getSelectedPromoByCode } from "../../utils/functions/getSelectedPromoByCode";

const INVEST = "INVEST";
function FundSelectionStep2(props) {
  const {
    classes,
    fundsCode,
    history,
    investPortfolioLoading,
    investPortfolioError,
    setInvestAmount,
    setStep,
    minAmount,
    custFundDetail,
    clearErrorInvestCheckout,
    investCheckoutError,
    selectedPromo,
    resetSelectedPromo,
    getPromo,
    promoListLoading,
    fundResult = {},
    fundCustSelected,
    promoList = [],
    setSelectedPromo,
    setLastAmountInvest,
    lastInvestAmount,
    createFundLoadingV2,
  } = props;

  const [errorFund, setErrorFund] = useState(investCheckoutError);
  const [rspAlert, setRspAlert] = useState({ allowRsp: true, alert: false });
  const [totalFundsAmount, setTotalFundsAmount] = useState(0);
  const [isErrorPromo, setIsErrorPromo] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [value, setValue] = useState({
    amount: !lastInvestAmount ? "" : lastInvestAmount,
    textError: "Minimum RM 10.00",
    error: false,
    createError: false,
  });

  const getPromoByCode = getSelectedPromoByCode(promoList);

  const didError = useRef(false);

  const setRspDisplay = rspStatus => {
    let rspSession = sessionStorage.getItem("stopRsp");

    if (rspSession) {
      return false;
    }

    if (
      !rspStatus ||
      (typeof rspStatus === "string" &&
        (rspStatus === "1" || rspStatus === "6" || rspStatus === "16"))
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    setStep("/fund-selection-step-two");
  }, [])

  useEffect(() => {
    if(createFundLoadingV2) return;

    getPromo({
      target: INVEST,
      hierarchyId: fundResult.accountNo,
      instrumentId: fundCustSelected.id,
    });

  }, [createFundLoadingV2]);

  useEffect(() => {
    const { rspStatus = "" } = custFundDetail;
    setRspAlert({ ...rspAlert, alert: setRspDisplay(rspStatus) });
  }, [custFundDetail]);

  useEffect(() => {
    if (investPortfolioError) {
      setValue({ ...value, createError: true });
    }
  }, [investPortfolioError]);

  useEffect(() => {
    if (investCheckoutError) {
      didError.current = true;
      setRspAlert({ ...rspAlert, alert: false });
    }
    setErrorFund(investCheckoutError);
  }, [investCheckoutError]);

  const handleChange = amount => {
    setValue({ ...value, amount, error: false });
    setLastAmountInvest(amount);
  };

  useEffect(() => {
    if (value.amount) {
      if (value.amount < 10) {
        setValue({
          ...value,
          error: true,
          textError: "Minimum RM 10.00",
        });
      } else if (value.amount > 30000) {
        setValue({
          ...value,
          error: true,
          textError:
            "The maximum Cash In amount per transaction is RM30,000. You may proceed with your additional Cash In into multiple transactions.",
        });
      } else {
        setValue({
          ...value,
          error: false,
          textError: "",
        });
      }
    }

    if (Array.isArray(fundsCode)) {
      let totalFund = fundsCode.length;
      let totalAmount = value.amount * totalFund;
      setTotalFundsAmount(totalAmount);
    }
  }, [value.amount]);

  const checkSelectedPromo = () => {
    return Object.keys(selectedPromo).length === 0
      ? { message: "Apply", styleBtn: true, promo: false }
      : { message: "Cancel", styleBtn: false, promo: selectedPromo };
  };

  const hasMatchingPromo = val => {
    let matchingPromoCode;
    if (Array.isArray(promoList)) {
      matchingPromoCode = getPromoByCode(val);
    }

    if (matchingPromoCode.length > 0) {
      setSelectedPromo(matchingPromoCode[0]);
    } else {
      setIsErrorPromo(true);
    }
  };

  const goPromoList = () => {
    if (
      Object.keys(selectedPromo).length > 0 &&
      selectedPromo.selectedReferralCode
    ) {
      history.push("/promo-list", {
        referralCode: selectedPromo.selectedReferralCode,
      });
      return;
    }
    if (
      Object.keys(selectedPromo).length > 0 &&
      !selectedPromo.selectedReferralCode
    ) {
      history.push("/promo-list", { promoId: selectedPromo.campaignId });
      return;
    }
    history.push("/promo-list");
  };

  const handlePromo = () => {
    if (checkSelectedPromo().promo) {
      resetSelectedPromo();
      setValue({
        ...value,
        error: false,
        textError: "Minimum RM 10.00",
      });
      setPromoCode("");
      return;
    }
    if (promoCode === "" || !promoCode) {
      setIsErrorPromo(true);
      return;
    }
    hasMatchingPromo(promoCode);
  };

  const handleSubmit = () => {
    let matchingPromoCode = [];

    // we check if `selectedPromo` not exist if so then get the matching promo code else put `selectedPromo` inside matchingPromoCode
    if (!Object.keys(selectedPromo).length) {
      if (Array.isArray(promoList)) {
        matchingPromoCode = getPromoByCode(promoCode);
      }
    } else {
      matchingPromoCode = [selectedPromo];
    }

    while (
      value.amount.length <= 0 ||
      (promoCode && matchingPromoCode.length === 0)
    ) {
      if (value.amount.length <= 0) {
        setValue({
          ...value,
          error: true,
          textError: "This field is required",
        });
      }

      if (promoCode && matchingPromoCode.length === 0) {
        setIsErrorPromo(true);
      }

      return;
    }

    // in case matchingPromoCode is empty array
    // we dont want to have undefined here
    const promo = matchingPromoCode.length ? matchingPromoCode[0] : {};

    setSelectedPromo(promo);

    // save `is promo selected` reference here
    const isPromoSelected = Object.keys(promo).length > 0;

    const isAmountLessThanPromoEligibilityRule =
      isPromoSelected &&
      parseInt(value.amount) <
        parseInt(promo.eligibilityRules.minimumSingleDeposit);

    if (isAmountLessThanPromoEligibilityRule) {
      setValue({
        ...value,
        error: true,
        textError: `Minimum campaign cash in RM${
          promo.eligibilityRules.minimumSingleDeposit
        } not met`,
      });
      return;
    }

    if (!isPromoSelected && parseInt(value.amount) < parseInt(minAmount)) {
      setValue({
        ...value,
        error: true,
        textError: `Minimum RM ${(Math.round(minAmount * 100) / 100).toFixed(
          2
        )}`,
      });
      return;
    }

    let getfunds = [];

    if (Array.isArray(fundsCode)) {
      fundsCode.map(item => {
        getfunds.push({ fundCode: item.code });
      });
    }

    setInvestAmount({
      investAmount: value.amount,
      totalFundsAmount,
      funds: getfunds,
    });
    history.push("/fund-selection-step-three");
  };

  const setUpAutoDeposite = () => {
    history.push(
      `/make-deposit/${FUNDDETAILS}/success/auto-deposit-v2/setupRSP`
    );
  };

  if (investPortfolioLoading || promoListLoading || createFundLoadingV2)
    return <DefaultLoader />;

  return (
    <>
      <Alert
        title="Set up monthly auto Cash In (Regular Savings Plan), a powerful way to help you achieve your goal on time"
        isOpen={rspAlert.alert}
        isCancel={true}
        cancelTxt="Maybe Later"
        handleAction={() => setUpAutoDeposite()} //go to auto deposite form
        handleCancel={() => {
          dataLayerGTM("click_tag", "Click", "Button", "Setup RSP Maybe Later");
          sessionStorage.setItem("stopRsp", true);
          setRspAlert({ ...rspAlert, alert: false });
        }}
        btnTxt="Set Up Auto Cash In"
        style={{ display: didError.current ? "none" : "" }}
      />

      <AlertInfo
        description="We are facing some technical issues, and we are working very hard to resolve the issue."
        isOpen={value.createError || errorFund}
        isTimeout
        handleAction={() => {
          clearErrorInvestCheckout();
          setValue({ ...value, createError: false });
        }}
      />
      <div className="deposit-wrapper">
        <StepLabel
          title="Cash In"
          containerStyles={{ margin: "0px" }}
          onClick={() => {
            dataLayerGTM(
              "click_tag",
              "Click",
              "Button",
              `Fund Cash In - back button`
            );
            history.back();
          }}
        />
      </div>
      <form className="invest-form">
        <div className="fs-step2-box1">
          <p className="invest-form__big-label">
            How much are you willing to invest?
          </p>

          <p className="invest-form__label">{fundsCode[0].name}</p>

          <TextField
            fullWidth
            // style={{ minWidth: "100%", maxHeight: "48px" }}
            variant="outlined"
            placeholder={(Math.round(minAmount * 100) / 100).toFixed(2)}
            value={value.amount}
            onChange={e => handleChange(e.target.value)}
            error={value.error}
            helperText={value.error && value.textError}
            FormHelperTextProps={{ className: classes.helper }}
            inputProps={{ className: classes.inputField }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  <Typography color="textPrimary" className={classes.prefix}>
                    RM
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="border-line" />
        <div className="fs-step2-box2">
          <InputBasic
            promoValue={checkSelectedPromo().promo}
            error={isErrorPromo}
            errorMessage="Please enter a valid Promo Code"
            title="Promo Code"
            placeholder="Enter promo or referral code"
            type="text"
            isCurrency={false}
            btnOption={checkSelectedPromo().message}
            textButton={checkSelectedPromo().styleBtn}
            isPromo={true}
            onChange={e => {
              setIsErrorPromo(false);
              setPromoCode(e.target.value);
            }}
            listPromoAction={() => goPromoList()}
            onButton={e => {
              e.preventDefault();
              handlePromo();
            }}
          />
        </div>
        <div className="border-line" />
        <div className="fs-step2-box3">
          <div>
            <p>Cash In</p>
            <p>
              RM
              <NumberFormat
                value={totalFundsAmount}
                thousandSeparator
                displayType="text"
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </p>
          </div>

          <div>
            <p>Inclusive of Sales Charge</p>
            <p>{fundsCode[0].salesCharge || "0"}%</p>
          </div>
          {Object.keys(selectedPromo).length > 0 && (
            <div className="sales-charge-promo">
              <p>{selectedPromo.name}</p>
              <p>-</p>
            </div>
          )}
        </div>
        <div className="border-line" />
        <div className="invest-form__container-total">
          <p className="invest-form__container-total__cahsin-label">
            Total Cash In
          </p>
          <p className="invest-form__container-total__total-cashin">
            RM{" "}
            <NumberFormat
              value={totalFundsAmount}
              thousandSeparator
              displayType="text"
              decimalScale={2}
              fixedDecimalScale={true}
            />
          </p>
        </div>
        <div className="invest-form__confirm">
          <CustomButton
            text="Continue"
            variant="contained"
            color="#fff"
            backgroundColor="#1278CC"
            disabled={investPortfolioLoading || value.error}
            onClick={() => {
              dataLayerGTM(
                "click_tag",
                "Click",
                "Button",
                `Fund Cash In - Continue`
              );
              dataLayerGTM(
                "click_tag",
                "Input",
                "Amount that user enter",
                `Fund Cash In ${value.amount}`
              );
              handleSubmit();
            }}
          />
        </div>
      </form>
    </>
  );
}

const withConnect = connect(
  state => ({
    custFundDetail: state.custFundDetailReducer.custFundDetail,
    investCheckoutError: state.investCheckoutReducer.investCheckoutError,
    ...state.riskAssessmentReducer,
    ...state.fundSelectionReducer,
    ...state.investPortfolioReducer,
    ...state.promoReducer,
  }),
  {
    setInvestAmount,
    setStep,
    setRSPPayload,
    clearErrorInvestCheckout,
    resetSelectedPromo,
    getPromo,
    setSelectedPromo,
    setLastAmountInvest,
  }
);

export default compose(
  withStyles(styles),
  withRouter,
  withConnect
)(FundSelectionStep2);
