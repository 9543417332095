import * as React from "react";
import { connect } from "react-redux";
import { compose } from "underscore";

import { withRouter } from "react-router";
import { setResource } from "../pages/switching/redux/action";
import { createCtx } from "../utils/functions/createCtx";

const [useSwitching, Provider] = createCtx();

const withConnect = connect(
  state => ({
    profile: state.goal.profile || {},
    resource: state.switchingReducer.resource
  }),
  { setResource }
);

const SwitchingProvider = compose(
  withRouter,
  withConnect
)(({ children, profile, setResource, resource, history }) => {
  const [switched, setSwitched] = React.useState({
    from: {
      cifPlanId: "",
      fundCd: "",
      name: "",
      switchableAmount: "",
      productType: "",
      accountNo: "",
      fee: ""
    },
    to: {
      cifPlanId: "",
      fundCd: "",
      name: "",
      productType: "",
      accountNo: ""
    },
    cifid: profile.masterId,
    amount: "",
    maxAmount: ""
  });

  /**
   * for tracking user route
   * to determine wether or not we fetch, etc
   */
  const screenHistory = React.useRef([]);

  const isAlreadyRequestedCreatePortfolio = React.useRef(false);

  const handleInvestIntro = () => {
    // backup data
    // when user go to different route
    // other than `/switching`
    setResource({
      from: {
        cifPlanId: switched.from.cifPlanId,
        fundCd: switched.from.fundCd,
        name: switched.from.name,
        switchableAmount: switched.from.switchableAmount,
        productType: switched.from.productType,
        accountNo: switched.from.accountNo,
        fee: switched.from.fee
      },
      to: {
        cifPlanId: switched.to.cifPlanId,
        fundCd: switched.to.fundCd,
        name: switched.to.name,
        productType: switched.to.productType,
        accountNo: switched.to.accountNo
      },
      amount: switched.amount,
      maxAmount: switched.maxAmount,
      cifid: switched.cifid
    });

    history.push("/invest-introduction");
  };

  const value = React.useMemo(
    () => ({
      setSwitched,
      switched,
      screenHistory,
      handleInvestIntro,
      resource,
      setResource,
      isAlreadyRequestedCreatePortfolio
    }),
    [switched]
  );

  return <Provider value={value}>{children}</Provider>;
});

export { SwitchingProvider, useSwitching };
