export const validateEmail = email => {
  // Regex email
  // const pattern = /(.+)@(.+){2,}\.(.+){2,}/;
  const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (pattern.test(String(email).toLocaleLowerCase())) {
    return true;
  } else {
    return false;
  }
};
