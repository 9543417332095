/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import "babel-polyfill";

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import App from "./containers/App";
import "react-spring-bottom-sheet/dist/style.css";
//import * as eruda from "eruda";

// Import Language Provider
import { LanguageProvider } from "./containers/LanguageProvider";

import { store, persistor } from "./store/index";

// Import i18n messages
import { translationMessages } from "./i18n";

import { API_URL } from "./utils/axios";

import "./index.css";

//eruda.init();

const MOUNT_NODE = document.getElementById("root");

const state = store.getState();

const { EventEmitter } = require("fbemitter");
window.eventEmitter = new EventEmitter();

const render = messages => {
  ReactDOM.render(
    <Provider store={store}>
      <LanguageProvider messages={messages} locale={state.language.locale}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </LanguageProvider>
    </Provider>,
    MOUNT_NODE
  );
};

if (!("log" in window)) {
  window.log = async ({ url, status, res, req, ...rest }) => {
    await fetch(`${API_URL.mp}/logger/log-mp`, {
      method: "POST",
      body: JSON.stringify({
        path: url,
        status,
        module: "mp-frontend",
        responseBody: JSON.stringify(res || {}),
        requestBody: JSON.stringify(req || {}),
        page: window.location.pathname,
        ...rest,
      }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  };
}

/** prevent blank screen when accessing `my` variable */
if (!("my" in window)) {
  window.my = {
    postMessage: () => {},
    hideLoading: () => {},
    onMessage: res => {
      if (res.event && window.eventHandler[res.event]) {
        window.eventHandler[res.event](res);
      }
    },
  };
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./i18n", "./containers/App"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import("intl"));
  })
    .then(() => Promise.all([import("intl/locale-data/jsonp/en.js")]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
  require("offline-plugin/runtime").install(); // eslint-disable-line global-require
}
