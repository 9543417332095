import bgImage from "../assets/images/bg-image.png";
import bgSavingDetails from "../assets/images/bg-savingDetails.jpg";
import principal from "../assets/images/principal.png";
import principalSvg from "../assets/images/principal.svg";
import principalLogo from "../assets/images/principal-logo.png";
import principalTransparent from "../assets/images/principal-transparent.png";
import principalTransparentSvg from "../assets/images/principal-transparent.svg";
import principalBuilding from "../assets/images/principal-building.png";
import dummy from "../assets/images/dummy.png";
import generalSavings from "../assets/images/general-savings.png";
import postImage from "../assets/images/post-image.png";
import rewards from "../assets/images/rewards.png";
import tngd from "../assets/images/tngd.png";
import tngdSvg from "../assets/images/tngd.svg";
import startedLogo from "../assets/icons/started-logo.svg";
import checkCircle from "../assets/icons/check-circle.svg";
import award from "../assets/icons/award.svg";
import checkCircleGreen from "../assets/icons/check-circle-green.svg";
import awardGreen from "../assets/icons/award-green.svg";
import investmentBg from "../assets/images/investing-illustration.svg";
import triangle from "../assets/icons/triangle.svg";
import triangledown from "../assets/icons/triangledown.svg";
import dashIcon from "../assets/icons/dashIcon.svg";
import loadingInfiniteScroll from "../assets/icons/gif/loadingTrx.gif";
import maze from "../assets/images/maze.svg";
import userCheck from "../assets/icons/user-check.svg"
import goalInvestmentIllustration from "../assets/images/goal-investment-illustration.svg";
import campaignMerdekaBg from "../assets/images/campaign-merdeka.png";
import freshDroppedFunds from "../assets/images/fresh-dropped-funds.png";
import laburiaBg from "../assets/images/laburia-banner.png";
const images = {
  campaignMerdekaBg,
  bgImage,
  bgSavingDetails,
  principal,
  principalSvg,
  principalLogo,
  principalTransparent,
  principalTransparentSvg,
  principalBuilding,
  dummy,
  generalSavings,
  postImage,
  rewards,
  tngd,
  tngdSvg,
  startedLogo,
  checkCircle,
  award,
  checkCircleGreen,
  awardGreen,
  investmentBg,
  triangle,
  triangledown,
  dashIcon,
  loadingInfiniteScroll,
  maze,
  userCheck,
  goalInvestmentIllustration,
  freshDroppedFunds,
  laburiaBg
};

export default images;
