import { fork, all, take } from "redux-saga/effects";
import authSaga from "../../containers/Authentication/redux/saga";
// import { fundsWorkerSaga } from "../../containers/FundList/redux/saga";
// import { FPXSaga } from "../../containers/CPG/redux/saga";
import { GoalSaga } from "../../pages/redux/saga";
import { GlobalSaga } from "../../globalRedux/saga";
import { onBoardingWorkerSaga } from "../../pages/onBoarding/redux/saga";
import { AutoDepositWorkerSaga } from "../../pages/deposit/redux/saga";
import { dashboardWorkerSaga } from "../../pages/dashboard/redux/saga";
import { goalDetailWorkerSaga } from "../../pages/goalDetails/redux/saga";
import { getStartedWorkerSaga } from "../../pages/getStarted/redux/saga";
import { riskAssessmentWorkerSaga } from "../../pages/riskAssessment/redux/saga";
import { fundSelectionWorkerSaga } from "../../pages/fundSelection/redux/saga";
import { investCheckoutWorker } from "../../pages/fundSelectionStep3/redux/saga";
import { custInvestSagaWorker } from "../../pages/investList/redux/saga";
import { stepThreeSagaWorker } from "../../pages/step3/redux/saga";
import { promoSagaWorker } from "../../pages/promoList/redux/saga";
import { rewardsSagaWorker } from "../../pages/rewards/redux/saga";
import { switchingWorkerSaga } from "../../pages/switching/redux/saga";
import { profileWorkerSaga } from "../../pages/profile/redux/saga";
import { deeplinkWorkerSaga } from "../../pages/DeepLink/redux/saga";
import { REHYDRATE } from "redux-persist/lib/constants";

export default function* rootSaga() {
  // Don't run sagas yet until redux-persist has reloaded everything into store
  // useful for when opening website again in a previously closed browser.
  // This should prevent sagas from trying to run with empty auth store.
  yield take(REHYDRATE);

  yield all([
    fork(authSaga),
    GoalSaga(),
    GlobalSaga(),
    onBoardingWorkerSaga(),
    AutoDepositWorkerSaga(),
    dashboardWorkerSaga(),
    goalDetailWorkerSaga(),
    getStartedWorkerSaga(),
    riskAssessmentWorkerSaga(),
    fundSelectionWorkerSaga(),
    investCheckoutWorker(),
    custInvestSagaWorker(),
    stepThreeSagaWorker(),
    promoSagaWorker(),
    rewardsSagaWorker(),
    switchingWorkerSaga(),
    profileWorkerSaga(),
    deeplinkWorkerSaga(),
  ]);
}
