import React from "react";
import { images } from "../../constants";
import "./styles.scss";
import { CustomButton } from "../../components";
import moment from "moment";

export default function StartedCard(props) {
  const {
    color = "#1793D7",
    headerTitle,
    headerDesc,
    risk = "",
    percent = "",
    investFrom,
    firstIcon,
    secondIcon,
    setAction,
    text1 = "",
    text2 = "",
    inceptionDate,
    additionalTitles,
  } = props;

  return (
    <div className="started-card-box">
      <div
        className="started-card-box__header"
        style={{ backgroundColor: color }}
      >
        <img src={images.startedLogo} alt="principal-icon" />
        <div className="started-card-header">
          <p className="started-card-box__header__title">{headerTitle}</p>
          {additionalTitles && (
            <p className="additionalTitles"> {additionalTitles}</p>
          )}
        </div>

        <div className="started-card-box__header__desc">
          <p>{headerDesc}</p>
          <span>{risk}</span>
        </div>
      </div>

      <div className="started-card-box__content">
        <span>Up to</span>
        <p className="started-card-box__content__first">
          <span style={{ color }}>{percent}</span>{" "}
          {headerTitle === "Save"
            ? "return per annum*"
            : "average Total Returns"}
        </p>
        {headerTitle === "Save" ? (
          <p className="started-card-box__content__second">
            Gross return excluding fees
          </p>
        ) : (
          <p className="started-card-box__content__second">
            (Since inception until {moment(inceptionDate).format("D MMM YYYY")})
          </p>
        )}

        <div className="started-card-box__content__info">
          <div>
            <img src={firstIcon} alt="tngd-logo" />
            <p>{text1}</p>
          </div>
          <div>
            <img src={secondIcon} alt="tngd-logo" />
            <p>{text2}</p>
          </div>
        </div>
        <div className="started-card-box__content__action">
          <p>
            Invest from <span>{investFrom}</span>
          </p>
          <CustomButton
            style={{ width: "120px", height: "48px", padding: "12px 16px" }}
            text="Next"
            variant="outlined"
            color="#fff"
            backgroundColor="#1278CC"
            onClick={setAction}
          />
        </div>
      </div>
    </div>
  );
}
