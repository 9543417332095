const initState = {
  isOpenAlert: false,
  isCashOutError: false,
  isFullRedemptionError: false,
  isLowBalanceError: false,
  withdrawalAmount: null,
  goalDetail: null,
  savingDetail: null,
  saveMinAmount: 10,
  investMinAmount: 10,
};

function reducer(state = initState, action) {
  switch (action.type) {
    case "SET_GOAL":
      return {
        ...state,
        savingDetail: action.payload
      };
    case "SET_WITHDRAWAL_AMOUNT":
      return {
        ...state,
        withdrawalAmount: action.payload
      };
    case "RESET_WITHDRAWAL_AMOUNT":
      return {
        ...state,
        withdrawalAmount: "0"
      };
    case "RESET_FULL_REDEMPTION_ERROR":
      return {
        ...state,
        isFullRedemptionError: false
      };
    case "SET_OPEN_ALERT":
      return {
        ...state,
        isOpenAlert: true
      };
    case "RESET_OPEN_ALERT":
      return {
        ...state,
        isOpenAlert: false
      };
    case "SET_LOW_BALANCE_ERROR":
      return {
        ...state,
        isLowBalanceError: true
      };
    case "RESET_LOW_BALANCE_ERROR":
      return {
        ...state,
        isLowBalanceError: false
      };
    case "SET_CASHOUT_ERROR":
      return {
        ...state,
        isCashOutError: true
      };
    case "RESET_CASHOUT_ERROR":
      return {
        ...state,
        isCashOutError: false
      };
    case "SET_AMOUNT":
      return {
        ...state,
        withdrawalAmount: action.payload
      };
    case "RESET_OPEN_ALERT_AND_REDEMPTION_ERROR":
      return { ...state, isOpenAlert: false, isFullRedemptionError: true };
    case "RESET_OPEN_ALERT_AND_SET_LOW_BALANCE_ERROR":
      return { ...state, isOpenAlert: false, isLowBalanceError: true };
    case "RESET_OPEN_ALERT_SET_CASHOUT_ERROR":
      return { ...state, isOpenAlert: false, isCashOutError: true };
    default:
      return state;
  }
}

export { reducer, initState };
