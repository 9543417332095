import React, { useState, useEffect } from "react";
import "./style.scss";
import NumberFormat from "react-number-format";
import { formatProcessingText } from "../../utils/functions/formatProcessingText";
import { images } from "../../constants";
const InvestedFundCard = ({
  fundName,
  totalAsset,
  cashInStatus,
  unrealisedGain,
  totalCost,
  percentage,
  handleClick
}) => {
  const [styleWriting, setStyleWriting] = useState({
    color: "#18A29A",
    icon: images.triangle,
    iconRM: "+RM",
    iconIndicate: ""
  });

  useEffect(() => {
    if (parseFloat(unrealisedGain) === 0) {
      setStyleWriting({
        color: "#616367",
        icon: images.dashIcon,
        iconRM: "+RM",
        iconIndicate: "+"
      });
      return;
    }
    if (parseFloat(unrealisedGain) >= 0) {
      setStyleWriting({
        color: "#18A29A",
        icon: images.triangle,
        iconRM: "+RM",
        iconIndicate: "+"
      });
      return;
    }
    if (parseFloat(unrealisedGain) <= 0) {
      setStyleWriting({
        color: "#BE0712",
        icon: images.triangledown,
        iconRM: "-RM",
        iconIndicate: ""
      });
      return;
    }
  }, []);

  const getPercentage = (unrealised, cost) => {
    if (
      !unrealised ||
      !cost ||
      parseFloat(unrealised) === 0 ||
      parseFloat(cost) === 0
    ) {
      let temp = 0;
      return temp.toFixed(2);
    }
    let percent = (parseFloat(unrealised) / parseFloat(cost)) * 100;
    return percent.toFixed(2);
  };

  return (
    <div className="c-invested-fund-card" onClick={handleClick}>
      <h1 className="c-invested-fund-card__name">{fundName}</h1>
      <p
        className="c-invested-fund-card__price"
        style={{ padding: !percentage ? " 0 0 8px" : "0 0 4px" }}
      >
        RM{" "}
        <NumberFormat
          value={totalAsset}
          thousandSeparator
          displayType="text"
          decimalScale={2}
          fixedDecimalScale={true}
        />
      </p>
      {percentage && (
        <span
          className="c-invested-fund-card__percentage"
          style={{ color: styleWriting.color }}
        >
          <span>
            {" "}
            {`${styleWriting.iconRM} ${Math.abs(
              parseFloat(unrealisedGain)
            ).toFixed(2)}`}
          </span>
          <span>{`(${styleWriting.iconIndicate}${getPercentage(
            unrealisedGain,
            totalCost
          )}%)`}</span>
          <img src={styleWriting.icon} alt="icon" />
        </span>
      )}

      {cashInStatus.map((item, index) => (
        <span className="c-invested-fund-card__status" key={index}>
          {formatProcessingText(item, "invest")}
        </span>
      ))}
    </div>
  );
};

export default InvestedFundCard;
