import React from "react";
import { Typography, Icon } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";

function ProfileDropdown({
  title,
  itemList,
  selectedItem,
  handleClick,
  classes,
  isEcdd = false
}) {
  return (
    <div className={classes.container}>
      <Typography
        color="textPrimary"
        className={`${classes.baseFont} ${classes.title}`}
      >
        {title}
      </Typography>
      {itemList.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => isEcdd ? handleClick({ code: item.code, text: item.name }) : handleClick(item.code)}
            className={`${classes.row} ${classes.itemList}`}
          >
            <Typography
              color="textPrimary"
              className={`${classes.baseFont} ${classes.bold}`}
            >
              {item.name ? item.name : item.description}
            </Typography>
            {(isEcdd ? selectedItem.code === item.code : selectedItem === item.code)  && (
              <Icon
                className="material-icons-outlined"
                style={{ cursor: "pointer", color: "#0091DA" }}
              >
                done
              </Icon>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default compose(withStyles(styles))(ProfileDropdown);
