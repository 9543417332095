import React, { useState, useEffect } from "react";
import "./styles.scss";
import { CustomButton, StepLabel, DefaultLoader } from "../../components";
import { compose } from "underscore";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getRiskAssessment, sendRiskAssessment } from "./redux/action";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import { dataLayerGTM } from "../../utils/functions/dataLayerGTM";
function RiskAssessment(props) {
  let {
    getRiskAssessment,
    getRiskAssessmentLoading,
    history,
    questionnaire,
    sendRiskAssessment,
    sendriskAssessmentLoading,
    resourceSwitching,
    location,
    match: { params },
  } = props;

  const [requiredFields, setRequiredFileds] = useState([]);
  const [questionsOn, setQuestionsOn] = useState([]);
  const [riskProfileAnswer, setRiskProfileAnswer] = useState([]);
  const [lang, setLang] = useState("english");

  useEffect(() => {
    getRiskAssessment();
  }, []);

  const questionManipulate = data => {
    let activeQuestions = [...data];
    if (Array.isArray(activeQuestions)) {
      activeQuestions.map((item, index) => {
        if (lang === "english") {
          if ("questionsEndAfterParse" in item)
            activeQuestions[index] = {
              ...item,
              activeQuestion: item.questionsEndAfterParse,
            };
        } else {
          if ("questionsIdnAfterParse" in item)
            activeQuestions[index] = {
              ...item,
              activeQuestion: item.questionsIdnAfterParse,
            };
        }
      });
    }
    setQuestionsOn(activeQuestions);
  };

  const riskProfileAnswerLength = data => {
    let tempArray = [];
    for (let i = 0; i < data.length; i++) {
      tempArray.push("");
    }
    setRiskProfileAnswer(tempArray);
  };

  useEffect(() => {
    if (Array.isArray(questionsOn)) {
      questionsOn.map(item => {
        if ("activeQuestion" in item && Array.isArray(item.activeQuestion)) {
          riskProfileAnswerLength(item.activeQuestion);
        }
      });
    }
  }, [questionsOn]);

  useEffect(() => {
    questionManipulate(questionnaire);
  }, [questionnaire, lang]);

  const handleChangeAswer = (answer, index) => {
    let newArray = [...riskProfileAnswer];
    newArray[index] = answer;
    setRiskProfileAnswer(newArray);

    let tempError = [...requiredFields];
    tempError[index] = false;

    setRequiredFileds(tempError);
  };

  const validationAnswer = data => {
    let tempError = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i] === "") {
        tempError.push(true);
      } else {
        tempError.push(false);
      }
    }
    setRequiredFileds(tempError);
    for (let j = 0; j < tempError.length; j++) {
      if (tempError[j] === true) {
        return false;
      }
    }
    return "OK";
  };

  const handleAnswer = code => {
    let resultValidations = validationAnswer(riskProfileAnswer);

    if (resultValidations !== "OK" || code.length === 0 || !code) {
      return;
    }
    let payload = {
      riskProfileQuestionGroup: code.toString(),
      riskProfileAnswer,
    };
    const comeFrom = localStorage.getItem("from");

    sendRiskAssessment(payload, directTo => {
      if (params.from === "deeplink" && location.state) {
        let { type, code: codeDL } = location.state;
        history.push(`/deeplink/${type}/${codeDL}`);
      } else if (comeFrom === "rsp" || resourceSwitching) {
        localStorage.removeItem("from");

        // jump two pages
        // we're in the last item (/risk-assessment) -> [/switching/** | /auto-cashin/**, "/invest-introduction", "/risk-assessment"]
        // we go back to -> `/switching/**` | `/auto-cashin/**`
        history.go(-2);
      } else {
        history.push(directTo, { from: "/risk-assessment" });
      }
    });
  };

  const formControl = val => {
    const Options = Object.keys(val).map((key, index) => (
      <FormControlLabel
        style={{
          marginLeft: "0px",
          padding: "6px 0px",
        }}
        key={index}
        value={key}
        control={
          <Radio
            style={{ padding: "0px", marginRight: "16px" }}
            color="primary"
          />
        }
        label={
          <Typography
            style={{
              color: "#333333",
              fontSize: "16px",
              fontFamily: "FSElliotPro",
            }}
          >
            {val[key]}
          </Typography>
        }
      />
    ));
    return Options;
  };

  if (getRiskAssessmentLoading || sendriskAssessmentLoading) {
    return <DefaultLoader />;
  }

  return (
    <div className="risk-assessment">
      <StepLabel
        title="Risk assessment"
        containerStyles={{ marginLeft: "-4px" }}
        onClick={() => {
          dataLayerGTM(
            "click_tag",
            "Click",
            "Button",
            `iSAF questionnaire back button`
          );
          history.back();
        }}
      />
      {/* <button
        onClick={() => setLang(lang === "english" ? "indonesia" : "english")}
      >
        CHANGE LANG
      </button> */}
      <p className="risk-assessment__title">Identification information</p>
      {questionsOn.map((item, idx) => (
        <form key={idx}>
          {item.activeQuestion.map((item2, index) => (
            <div className="risk-assessment__questions" key={index}>
              <p className="question">{item2.q}</p>
              {requiredFields[index] && (
                <p
                  style={{
                    color: "#BE0712",
                    fontSize: "14px",
                    margin: "-8px 0 4px 0",
                  }}
                >
                  required
                </p>
              )}

              <FormControl>
                <RadioGroup
                  name="questionnaire"
                  value={riskProfileAnswer[index]}
                  onChange={e => handleChangeAswer(e.target.value, index)}
                >
                  {formControl(item2.o)}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
          <div className="risk-assessment__confirm">
            <CustomButton
              text="See profile & explore funds"
              variant="contained"
              color="#fff"
              backgroundColor="#1278CC"
              disabled={false}
              onClick={() => {
                dataLayerGTM(
                  "click_tag",
                  "Click",
                  "Button",
                  `iSAF see Profile & Explore funds`
                );
                handleAnswer(item.cd);
              }}
            />
          </div>
        </form>
      ))}
      <p style={{ marginTop: "0.5rem" }}>
        At Principal we are committed to putting our clients first by delivering
        services that are guided by fairness, empathy, and a genuine
        understanding of client’s needs. If you find yourself experiencing
        personal challenges or difficulties that may require further assistance,
        please let us know by visiting{" "}
        <span
          style={{ textDecoration: "underline", color: "blue" }}
          onClick={() => {
            window.my.navigateTo({
              url:
                "/pages/link/link?url=" +
                encodeURIComponent("https://www.principal.com.my/en/vc"),
            });
          }}
        >
          www.principal.com.my/en/vc
        </span>
      </p>
    </div>
  );
}

const withConnect = connect(
  state => ({
    ...state.riskAssessmentReducer,
    resourceSwitching: state.switchingReducer.resource,
  }),
  {
    getRiskAssessment, // API GET
    sendRiskAssessment, // API POST
  }
);

export default compose(
  withRouter,
  withConnect
)(RiskAssessment);
