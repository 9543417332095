import React from "react";
import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { compose } from "underscore";
import styles from "./styles";

const StepCaption = ({ text, containerStyles, classes }) => {
  return (
    <Typography
      color="secondary"
      className={classes.caption}
      style={containerStyles}
    >
      {text}
    </Typography>
  );
};

export default compose(withStyles(styles))(StepCaption);
