import { all, takeLatest, call, put } from "redux-saga/effects";
import * as constant from "./constant";
import { getSubAccountSuccess, getSubAccountFailed } from "./actions";
import { API_URL } from "../../../utils/axios";
import * as api from "../../../utils/api";

function* getSubAccountDetailSaga({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/plan-status/${payload}`
    );
    if (res.status === 204) {
      throw new Error("fail get sub account detail");
    }
    const { data } = res;
    yield put(getSubAccountSuccess(data));
  } catch (error) {
    yield put(getSubAccountFailed(false));
  }
}

export function* goalDetailWorkerSaga() {
  yield all([
    takeLatest(constant.GET_SUBACOCUNT_DETAIL, getSubAccountDetailSaga)
  ]);
}
