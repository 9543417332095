import React, { useState } from "react";
import { Typography, Icon } from "@material-ui/core";

import { BottomNav } from "../../components";
import { images } from "../../constants";
import styles from "./styles";
import "./index.css";

const Gamification = () => {
  const [actionList, setActionList] = useState([
    {
      id: 1,
      name: "Make First Deposit with TNG eWallet",
      stamp: 1,
      active: false
    },
    {
      id: 2,
      name: "Enable Auto Deposit",
      stamp: 2,
      active: false
    },
    {
      id: 3,
      name: "Deposit RM150 to your finacial Goal with TNG eWallet",
      stamp: 5,
      active: false
    }
  ]);

  const [showMoreOpen, setShowMoreOpen] = useState(false);

  const handleChangeAction = index => {
    const copyAction = [...actionList];
    copyAction[index] = {
      ...copyAction[index],
      active: !copyAction[index].active
    };
    setActionList(copyAction);
  };

  return (
    <div style={{ ...styles.column, ...styles.container }}>
      <div style={{ backgroundColor: "#fff" }}>
        <div style={{ ...styles.row, ...styles.image }}>
          <img src={images.rewards} alt="rewards" />
        </div>

        <div style={{ ...styles.column, ...styles.leading }}>
          <Typography
            color="secondary"
            style={{ ...styles.baseFont, ...styles.leadingTitle }}
          >
            TnG The Goal Hunter
          </Typography>
          <Typography
            style={{ ...styles.baseFont, color: "#162b3a", marginTop: "8px" }}
            className="expandable-text"
          >
            Enjoy 1 reward voucher with every 2 stamps. Collect a stamp with
            every action you’ve completed.
            {showMoreOpen && (
              <>
                <br />
                <br />
                <span
                  style={{
                    ...styles.baseFont,
                    ...styles.bold,
                    color: "#162b3a"
                  }}
                >
                  Grand Prize
                </span>
                <span style={{ ...styles.baseFont, color: "#162b3a" }}>
                  Macbook air X1
                </span>
                <br />
                <span
                  style={{
                    ...styles.baseFont,
                    ...styles.bold,
                    color: "#162b3a"
                  }}
                >
                  Consolation Prize
                </span>
                <span style={{ ...styles.baseFont, color: "#162b3a" }}>
                  7 Eleven 20% cashback X4 <br />
                  Domino Pizza RM5 cashback voucher X4
                </span>
              </>
            )}
            <span className={showMoreOpen ? "dot active" : "dot"}>..</span>{" "}
            <span
              style={{ ...styles.showMore }}
              className={showMoreOpen ? "showmore active" : "showmore inactive"}
              onClick={() => setShowMoreOpen(!showMoreOpen)}
            >
              {showMoreOpen ? "Show Less" : "Show More"}
            </span>
          </Typography>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "#f6f6f8",
          padding: "16px 16px 24px",
          marginBottom: "16px"
        }}
      >
        <Typography
          color="secondary"
          style={{
            ...styles.baseFont,
            fontWeight: "700",
            marginBottom: "16px"
          }}
        >
          Action List (38)
        </Typography>
        {actionList.map((item, index) => {
          return (
            <div
              style={styles.actionItem}
              key={index}
              onClick={() => handleChangeAction(index)}
            >
              <div style={{ ...styles.column }}>
                <Typography
                  color="textPrimary"
                  style={{ ...styles.baseFont, ...styles.actionItemContent }}
                >
                  {item.name}
                </Typography>
                {item.active && (
                  <Typography
                    color="textPrimary"
                    style={{
                      ...styles.baseFont,
                      ...styles.actionItemContent,
                      marginTop: "4px",
                      color: "#616367"
                    }}
                  >
                    {item.stamp} Stamps
                  </Typography>
                )}
              </div>
            </div>
          );
        })}
      </div>

      {/* Bottom Navigation */}
      <div style={styles.bottomNav}>
        <BottomNav />
      </div>
    </div>
  );
};

export default Gamification;
