import {
  GET_OCCUPATIONS,
  GET_OCCUPATIONS_FAILED,
  GET_OCCUPATIONS_SUCCESS,
  GET_NATURE_BUSSINESS,
  GET_NATURE_BUSSINESS_SUCCESS,
  GET_NATURE_BUSSINESS_FAILED,
  GET_ANNUAL_INCOME_V2,
  GET_ANNUAL_INCOME_V2_SUCCESS,
  GET_ANNUAL_INCOME_V2_FAILED,
  GET_COUNTRY_V2,
  GET_COUNTRY_V2_SUCCESS,
  GET_COUNTRY_V2_FAILED,
  GET_RACE_V2,
  GET_RACE_V2_SUCCESS,
  GET_RACE_V2_FAILED,
  GET_STATE_V2,
  GET_STATE_V2_SUCCESS,
  GET_STATE_V2_FAILED,
  GET_SOURCE_OF_FUND_V2,
  GET_SOURCE_OF_FUND_V2_SUCCESS,
  GET_SOURCE_OF_FUND_V2_FAILED,
  GET_SOURCE_OF_WEALTH,
  GET_SOURCE_OF_WEALTH_FAILED,
  GET_SOURCE_OF_WEALTH_SUCCESS,
  GET_ESTIMATED_NET_WORTH,
  GET_ESTIMATED_NET_WORTH_SUCCESS,
  GET_ESTIMATED_NET_WORTH_FAILED,
  GET_NUMBER_OF_TRANSACTION,
  GET_NUMBER_OF_TRANSACTION_SUCCESS,
  GET_NUMBER_OF_TRANSACTION_FAILED,
  GET_VOLUME_OF_TRANSACTION,
  GET_VOLUME_OF_TRANSACTION_SUCCESS,
  GET_VOLUME_OF_TRANSACTION_FAILED,
  SET_ONBOARD_ONE,
  GET_MARITAL_STATUSV2,
  GET_MARITAL_STATUSV2_SUCCESS,
  GET_MARITAL_STATUSV2_FAILED,
  GET_INVESTMENT_OBJECTIVE,
  GET_INVESTMENT_OBJECTIVE_SUCCESS,
  GET_INVESTMENT_OBJECTIVE_FAILED
} from "./constant";

const initialState = {
  sourceOfWealthList: [],
  estimatedNetWorthList: [],
  volumeOfTransactionList: [],
  numberOfTransactionList: [],
  onboardStepOne: {},
  maritalStatusV2: [],
  countryListV2: [],
  occupationV2List: [],
  natureOfBusinessV2List: [],
  annualIncomeV2list: [],
  sourceOfFundListV2: [],
  investmentObjective: []
};

export default function onBoardingReducer(state = initialState, action) {
  switch (action.type) {
    case GET_INVESTMENT_OBJECTIVE:
      return {
        ...state,
        investmentObjectiveLoading: true
      };
    case GET_INVESTMENT_OBJECTIVE_SUCCESS:
      return {
        ...state,
        investmentObjective: action.payload,
        investmentObjectiveLoading: false
      };
    case GET_INVESTMENT_OBJECTIVE_FAILED:
      return {
        ...state,
        investmentObjectiveLoading: false
      };
    case GET_VOLUME_OF_TRANSACTION:
      return {
        ...state,
        getVOTLoading: true
      };
    case GET_VOLUME_OF_TRANSACTION_SUCCESS:
      return {
        ...state,
        volumeOfTransaction: action.payload,
        getVOTLoading: false
      };
    case GET_VOLUME_OF_TRANSACTION_FAILED:
      return {
        ...state,
        getVOTLoading: false
      };
    case GET_NUMBER_OF_TRANSACTION:
      return {
        ...state,
        getNOTLoading: true
      };
    case GET_NUMBER_OF_TRANSACTION_SUCCESS:
      return {
        ...state,
        numberOfTransaction: action.payload,
        getNOTLoading: false
      };
    case GET_NUMBER_OF_TRANSACTION_FAILED:
      return {
        ...state,
        getNOTLoading: false
      };
    case GET_ESTIMATED_NET_WORTH:
      return {
        ...state,
        getENWLoading: true
      };
    case GET_ESTIMATED_NET_WORTH_SUCCESS:
      return {
        ...state,
        estimatedNetWorth: action.payload,
        getENWLoading: false
      };
    case GET_ESTIMATED_NET_WORTH_FAILED:
      return {
        ...state,
        getENWLoading: false
      };
    case GET_SOURCE_OF_WEALTH:
      return {
        ...state,
        getSOWLoading: true
      };
    case GET_SOURCE_OF_WEALTH_SUCCESS:
      return {
        ...state,
        sourceOfWealth: action.payload,
        getSOWLoading: false
      };
    case GET_SOURCE_OF_WEALTH_FAILED:
      return {
        ...state,
        getSOWLoading: false
      };
    case GET_SOURCE_OF_FUND_V2:
      return {
        ...state,
        getSOFLoading: true
      };
    case GET_SOURCE_OF_FUND_V2_SUCCESS:
      return {
        ...state,
        sourceOfFundListV2: action.payload,
        getSOFLoading: false
      };
    case GET_SOURCE_OF_FUND_V2_FAILED:
      return {
        ...state,
        getSOFLoading: false
      };
    case GET_STATE_V2:
      return {
        ...state,
        getStateLoading: true
      };
    case GET_STATE_V2_SUCCESS:
      return {
        ...state,
        stateListV2: action.payload,
        getStateLoading: false
      };
    case GET_STATE_V2_FAILED:
      return {
        ...state,
        getStateLoading: false
      };
    case GET_RACE_V2:
      return {
        ...state,
        getRaceLoading: true
      };
    case GET_RACE_V2_SUCCESS:
      return {
        ...state,
        raceLIstV2: action.payload,
        getRaceLoading: false
      };
    case GET_RACE_V2_FAILED:
      return {
        ...state,

        getRaceLoading: false
      };
    case GET_COUNTRY_V2:
      return {
        ...state,
        getCountryV2Loading: true
      };
    case GET_COUNTRY_V2_SUCCESS:
      return {
        ...state,
        countryListV2: action.payload,
        getCountryV2Loading: false
      };
    case GET_COUNTRY_V2_FAILED:
      return {
        ...state,

        getCountryV2Loading: false
      };
    case GET_ANNUAL_INCOME_V2:
      return {
        ...state,
        getAnnualIncomeV2Loading: true
      };
    case GET_ANNUAL_INCOME_V2_SUCCESS:
      return {
        ...state,
        annualIncomeV2list: action.payload,
        getAnnualIncomeV2Loading: false
      };
    case GET_ANNUAL_INCOME_V2_FAILED:
      return {
        ...state,

        getAnnualIncomeV2Loading: false
      };
    case GET_OCCUPATIONS:
      return {
        ...state,
        getOccupationsLoading: true
      };
    case GET_OCCUPATIONS_SUCCESS:
      return {
        ...state,
        occupationV2List: action.payload,
        getOccupationsLoading: false
      };
    case GET_OCCUPATIONS_FAILED:
      return {
        ...state,

        getOccupationsLoading: false
      };
    case GET_NATURE_BUSSINESS:
      return {
        ...state,
        getNobLoading: true
      };
    case GET_NATURE_BUSSINESS_SUCCESS:
      return {
        ...state,
        natureOfBusinessV2List: action.payload,
        getNobLoading: false
      };
    case GET_NATURE_BUSSINESS_FAILED:
      return {
        ...state,

        getNobLoading: false
      };
    case SET_ONBOARD_ONE:
      return {
        ...state,
        onboardStepOne: action.payload
      };

    case GET_MARITAL_STATUSV2:
      return {
        ...state,
        getMaritalStsLoading: true
      };
    case GET_MARITAL_STATUSV2_SUCCESS:
      return {
        ...state,
        maritalStatusV2: action.payload,
        getMaritalStsLoading: false
      };
    case GET_MARITAL_STATUSV2_FAILED:
      return {
        ...state,

        getMaritalStsLoading: false
      };

    default:
      return state;
  }
}
