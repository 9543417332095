import { all, takeLatest, call, put, select } from "redux-saga/effects";
import cogoToast from "cogo-toast";
import { sendLogError } from "../../utils/api";
import {
  GET_GOAL_REQUEST,
  GET_GOAL_SUCCESS,
  GET_GOAL_FAILURE,
  GET_GOAL_PERIODS_REQUEST,
  GET_GOAL_PERIODS_SUCCESS,
  GET_GOAL_PERIODS_FAILURE,
  GET_INSTALLMENT_REQUEST,
  GET_INSTALLMENT_SUCCESS,
  GET_INSTALLMENT_FAILURE,
  GOAL_CREATION_REQUEST,
  GOAL_CREATION_SUCCESS,
  GOAL_CREATION_FAILURE,
  GET_CUSTOMER_GOALS_REQUEST,
  GET_CUSTOMER_GOALS_SUCCESS,
  GET_CUSTOMER_GOALS_FAILURE,
  GET_CUSTOMER_GOAL_DETAIL_REQUEST,
  GET_CUSTOMER_GOAL_DETAIL_SUCCESS,
  GET_CUSTOMER_GOAL_DETAIL_FAILURE,
  GET_NEWS_FEED_REQUEST,
  GET_NEWS_FEED_SUCCESS,
  GET_NEWS_FEED_FAILURE,
  GET_ONBOARDING_REQUEST,
  GET_ONBOARDING_SUCCESS,
  GET_ONBOARDING_FAILURE,
  GET_ANNUAL_INCOME_REQUEST,
  GET_ANNUAL_INCOME_SUCCESS,
  GET_ANNUAL_INCOME_FAILURE,
  GET_BUSINESS_NATURE_REQUEST,
  GET_BUSINESS_NATURE_SUCCESS,
  GET_BUSINESS_NATURE_FAILURE,
  GET_COUNTRY_REQUEST,
  GET_COUNTRY_SUCCESS,
  GET_COUNTRY_FAILURE,
  GET_MARITAL_STATUS_REQUEST,
  GET_MARITAL_STATUS_SUCCESS,
  GET_MARITAL_STATUS_FAILURE,
  GET_OCCUPATION_REQUEST,
  GET_OCCUPATION_SUCCESS,
  GET_OCCUPATION_FAILURE,
  GET_RACE_REQUEST,
  GET_RACE_SUCCESS,
  GET_RACE_FAILURE,
  GET_STATE_REQUEST,
  GET_STATE_SUCCESS,
  GET_STATE_FAILURE,
  GET_SOURCE_OF_FUND_REQUEST,
  GET_SOURCE_OF_FUND_SUCCESS,
  GET_SOURCE_OF_FUND_FAILURE,
  GET_SOURCE_OF_INCOME_REQUEST,
  GET_SOURCE_OF_INCOME_SUCCESS,
  GET_SOURCE_OF_INCOME_FAILURE,
  GET_SUPPORTING_DOCS_REQUEST,
  GET_SUPPORTING_DOCS_SUCCESS,
  GET_SUPPORTING_DOCS_FAILURE,
  GET_TRX_HISTORY_REQUEST,
  GET_TRX_HISTORY_SUCCESS,
  GET_TRX_HISTORY_FAILURE,
  DEPOSIT_REQUEST,
  DEPOSIT_SUCCESS,
  DEPOSIT_FAILURE,
  WITHDRAWAL_REQUEST,
  WITHDRAWAL_SUCCESS,
  WITHDRAWAL_FAILURE,
  ECDD_REQUEST,
  ECDD_SUCCESS,
  ECDD_FAILURE,
  GET_PPG_TOKEN_REQUEST,
  GET_PPG_TOKEN_SUCCESS,
  GET_PPG_TOKEN_FAILURE,
  GET_WITHDRAWAL_BANK_LIST_REQUEST,
  GET_WITHDRAWAL_BANK_LIST_SUCCESS,
  GET_WITHDRAWAL_BANK_LIST_FAILURE,
  GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS,
  GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST,
  GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE,
  SEND_OTP_EMAIL_REQUEST,
  SEND_OTP_EMAIL_SUCCESS,
  SEND_OTP_EMAIL_FAILURE,
  VERIFY_OTP_EMAIL_REQUEST,
  VERIFY_OTP_EMAIL_SUCCESS,
  VERIFY_OTP_EMAIL_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  SEND_CONTACT_MESSAGE_SUCCESS,
  SEND_CONTACT_MESSAGE_FAILURE,
  SEND_CONTACT_MESSAGE_REQUEST,
  SEND_FEEDBACK_REQUEST,
  SEND_FEEDBACK_SUCCESS,
  SEND_FEEDBACK_FAILURE,
  CHECK_ECDD_STATUS_REQUEST,
  CHECK_ECDD_STATUS_SUCCESS,
  CHECK_ECDD_STATUS_FAILURE,
  SMS_OTP_REQUEST,
  SMS_OTP_SUCCESS,
  SMS_OTP_FAILURE,
  VERIFY_OTP_SMS_REQUEST,
  VERIFY_OTP_SMS_SUCCESS,
  VERIFY_OTP_SMS_FAILURE,
  GOAL_CREATION,
  ONBOARDING,
  PROFILE,
  GENERAL_SAVING,
  CATEGORY_SAVING,
  ECDD,
  SET_REJECT_ALERT,
  CHECK_GOAL_NAME_REQUEST,
  CHECK_GOAL_NAME_SUCCESS,
  CHECK_GOAL_NAME_FAILURE,
  GET_GOAL_SUMMARY_SUCCESS,
  GET_GOAL_SUMMARY_FAILURE,
  GET_GOAL_SUMMARY_REQUEST,
  ONBOARDING_USER,
  NEW_USER,
  GET_GOAL_INTEREST_FAILURE,
  GET_GOAL_INTEREST_SUCCESS,
  GET_GOAL_INTEREST_REQUEST,
  SET_FAILURE_ALERT,
  CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST,
  CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS,
  CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE,
  GET_CUSTOMER_REQUEST,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAILURE,
  CREATE_GOAL_RSP,
  CREATE_GOAL_RSP_SUCCESS,
  CREATE_GOAL_RSP_FAILED,
  GET_CAMPAIGN,
  GET_CAMPAIGN_FAILED,
  GET_CAMPAIGN_SUCCESS,
  SET_CIF_PLAN_ID,
  DEEPLINK,
} from "./constant";
import {
  setFullRedemption,
  deleteGoalForm,
  depositRequest,
  getWithdrawalBankList,
  withdrawalRequest,
  checkEcddStatus,
} from "./action";
import { setProfileInfoV2 } from "../getStarted/redux/actions";
import { setbackPayloadRsp } from "../deposit/redux/action";
import { OLD_USER } from "./constant"; // manage redirect of route
import { setStep } from "../../globalRedux/actions";
import { API_URL } from "../../utils/axios";
import * as api from "../../utils/api";
import history from "../../history";
import { v4 as uuidv4 } from "uuid";
import crypto from "crypto";
import { fetchPost } from "../../utils/fetch";
import { ENV_SAVE } from "../getStarted/redux/constant";
import { setCurrentEcddStatus } from "../../globalRedux/actions";
import { validationAddress } from "../onBoarding/utils";

const savingType = state => state.goal.savingType;

function* getGoalList() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/goals`);
    const { data } = res;
    yield put({ type: GET_GOAL_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_GOAL_FAILURE, payload: error.message });
  }
}

function* getGoalPeriods() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/investment-plans`);
    const { data } = res;

    yield put({ type: GET_GOAL_PERIODS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_GOAL_PERIODS_FAILURE, payload: error.message });
  }
}

function* getInstallment({ payload }) {
  const { target, period, interest } = payload;

  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/installment?fv=${target}&i=${interest}&n=${period}`
    );
    const { data } = res;
    yield put({ type: GET_INSTALLMENT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_INSTALLMENT_FAILURE, payload: error.message });
  }
}

function* createGoal({ payload }) {
  try {
    const { payloadData, depositAmt } = payload;
    const userInfo = yield select(state => state.goal.profile);
    let type = yield select(savingType);

    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/goal-creation`,
      payloadData
    );
    const { data } = res;
    // const res = yield fetchPost(`${API_URL.mp}/smp/goal-creation`, payloadData);

    // let data;

    // if (res.status !== 200) {
    //   const error = yield res.json();
    //   let logError = {
    //     api: res.url || window.location.pathname,
    //     message: error.message || "---",
    //     errorResponse: true,
    //     status: res.status || "Not Found"
    //   };
    //   sendLogError(logError);
    //   throw new Error("failedToCreateGoal");
    // }

    // data = yield res.json();

    if (!data.accountNo || data.accountNo === "")
      throw new Error("accountNoErrorNull");

    if (res.status === 200) {
      localStorage.setItem("isNewUser", OLD_USER);
      yield put({ type: GOAL_CREATION_SUCCESS });

      if (type === CATEGORY_SAVING && depositAmt) {
        // yield put(setSavingGoalDetails(data)); // save cifPlanId to be used on goal details
        // history.push(`/make-deposit/${GOAL_CREATION}`);

        // deposit request
        const payloadDeposit = {
          productId: payloadData.productId,
          cifPlanId: data.id,
          customerChannelCode: "Mini Program",
          trxRefNo: crypto.randomBytes(14).toString("hex"),
          amount: depositAmt.toString(),
          ccy: "MYR",
          cifID: userInfo && userInfo.masterId,
          name:
            process.env.REACT_APP_ENV.trim() === "production"
              ? userInfo.fullName
              : "PnN@m3()/PyN .-&B''UYER", // need to change to user fullname later,
          email: userInfo && userInfo.email,
        };

        yield put(depositRequest(payloadDeposit));
      } else if (type === CATEGORY_SAVING) {
        // maybe later
        history.push(`/dashboard`);
      } else if (type === GENERAL_SAVING && depositAmt) {
        // deposit request
        const payloadDeposit = {
          productId: payloadData.productId,
          cifPlanId: data.id,
          customerChannelCode: "Mini Program",
          trxRefNo: crypto.randomBytes(14).toString("hex"),
          amount: depositAmt.toString(),
          ccy: "MYR",
          cifID: userInfo && userInfo.masterId,
          name:
            process.env.REACT_APP_ENV.trim() === "production"
              ? userInfo.fullName
              : "PnN@m3()/PyN .-&B''UYER", // need to change to user fullname later,
          email: userInfo && userInfo.email,
        };

        yield put(depositRequest(payloadDeposit));
      } else if (type === GENERAL_SAVING) {
        // maybe later
        history.push(`/dashboard`);
      } else {
        return;
      }
    }
  } catch (error) {
    if (error.message === "accountNoErrorNull") {
      forceError({
        url: `${API_URL.mp}/smp/goal-creation`,
        message: "account no error",
        status: error.status || 200,
      });
      yield put(deleteGoalForm());
      yield put({ type: GOAL_CREATION_FAILURE, payload: "timeoutError" });
    } else if (error.message === "failedToCreateGoal") {
      yield put(deleteGoalForm());
      yield put({ type: GOAL_CREATION_FAILURE, payload: "failedToCreateGoal" });
    } else {
      yield put({ type: GOAL_CREATION_FAILURE, payload: error.message });
    }
  }
}

function* getCustomerGoals(payload) {
  try {
    // const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    // const cifId = userInfo.masterId;
    const cifId = yield select(state => state.goal.profile.masterId);

    const res = yield call(api.get, `${API_URL.mp}/smp/goal-creation/${cifId}`);
    const { data } = res;

    yield put({ type: GET_CUSTOMER_GOALS_SUCCESS, payload: data });

    if ("callback" in payload && typeof payload.callback === "function") {
      payload.callback();
    }
  } catch (error) {
    yield put({ type: GET_CUSTOMER_GOALS_FAILURE, payload: error.message });
  }
}

function* getCustomerGoalDetail({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/goal-detail/${payload}`
    );
    const { data } = res;

    yield put({ type: GET_CUSTOMER_GOAL_DETAIL_SUCCESS, payload: data });
  } catch (error) {
    yield put({
      type: GET_CUSTOMER_GOAL_DETAIL_FAILURE,
      payload: error.message,
    });
  }
}

function* sagaDepositRequest({ payload }) {
  try {
    const {
      customerChannelCode,
      trxRefNo,
      amount,
      ccy,
      name,
      email,
      cifID,
      cifPlanId,
      productId,
    } = payload;

    payload.productId = undefined;
    payload.cifPlanId = undefined;

    const res = yield call(
      api.post,
      `${API_URL.ppg}/payment/generate`,
      payload
    );

    payload.productId = productId;
    payload.cifPlanId = cifPlanId;

    const { data } = res;

    const dummy = {
      customerChannelCode,
      payCatCd: null,
      trxRefNo,
      amount,
      ccy,
      cifID,
      refId: trxRefNo.slice(2, trxRefNo.length),
      name,
      email,
      lang: "en",
      signature: data.signature,
      resUrl: `${API_URL.mp}/webhooks/res-url`, // to redirect back to mp
      // backUrl: `${API_URL.mp}/webhooks/back-url` // backend url to check payment
      backUrl: `${API_URL.backURL}`,
    };

    const requestCheckout = {
      transactionHostRefNo: uuidv4(), // primary key unique
      productId, //goal id
      cifPlanId,
      trxRefNo,
      accountNo: "",
      trxType: "FPX",
      amountCcy: "MYR",
      amount: parseFloat(amount),
      unit: 0,
      feePercent: 0,
      feeCcy: "MYR",
      feeAmount: 0,
      sourceOfFund: "",
      bankCd: "",
      bankAccountNo: "",
      bankAccountName: "",
      paymentTypeCd: "",
      ppgRefNo: "",
      pgRefNo: "",
      recipientBank: "",
      type: "",
      approveTime: new Date().toString(),
      trxUnitPaymentProvider: "",
      trxUnitPaymentType: "",
      status: "",
    };

    const resCheckout = yield call(
      api.post,
      `${API_URL.mp}/checkout`,
      requestCheckout
    );

    yield put(setStep(null)); // since success, no need to redirect back to goal creation again

    yield put(deleteGoalForm());
    // Data input to ppg
    const myForm = document.createElement("form");
    myForm.method = "POST";
    myForm.id = "Payform";
    myForm.action = `${API_URL.ppg}/entry`;
    // myForm.action = `${API_URL.ppgLocal}`; // try on local
    for (const key in dummy) {
      if (Object.hasOwnProperty.call(dummy, key)) {
        const element = dummy[key];
        const myInput = document.createElement("input");
        myInput.type = "hidden";
        myInput.name = key;
        myInput.id = key;
        myInput.value = element;
        myForm.appendChild(myInput);
      }
    }
    document.body.appendChild(myForm);
    localStorage.setItem(
      "currentTab",
      JSON.stringify({
        id: 1,
        name: "Save",
      })
    );
    myForm.submit();

    // if (resCheckout.data.accountNo !== "") {
    //   yield put(deleteGoalForm());
    //   // Data input to ppg
    //   const myForm = document.createElement("form");
    //   myForm.method = "POST";
    //   myForm.id = "Payform";
    //   myForm.action = `${API_URL.ppg}/entry`;
    //   // myForm.action = `${API_URL.ppgLocal}`; // try on local
    //   for (const key in dummy) {
    //     if (Object.hasOwnProperty.call(dummy, key)) {
    //       const element = dummy[key];
    //       const myInput = document.createElement("input");
    //       myInput.type = "hidden";
    //       myInput.name = key;
    //       myInput.id = key;
    //       myInput.value = element;
    //       myForm.appendChild(myInput);
    //     }
    //   }
    //   document.body.appendChild(myForm);
    //   myForm.submit();
    // } else {
    //   throw new Error("null accountNoError");
    // }

    // const isAutoDeposit = yield select(getAutoDepositStatus);
    yield put({ type: DEPOSIT_SUCCESS });
  } catch (error) {
    yield put({
      type: DEPOSIT_FAILURE,
      payload: error.message,
    });
    yield put({
      type: SET_REJECT_ALERT,
      payload: true,
    });
  }
}

function* checkWithdrawalMaxAmount(action) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/goal-creation/max-amount/${action.payload}`
    );

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: CHECK_WITHDRAWAL_MAX_AMOUNT_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: CHECK_WITHDRAWAL_MAX_AMOUNT_FAILURE,
      payload: error.message,
    });
  } finally {
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  }
}

function* sagaWithdrawalRequest({ payload }) {
  try {
    const res = yield call(api.post, `${API_URL.mp}/withdrawal`, payload);
    const { data } = res;
    if (res.status === 200) {
      history.push("/make-withdrawal/success");
      yield put(setFullRedemption(false));
      yield put({ type: WITHDRAWAL_SUCCESS, payload: data });
      yield put({ type: VERIFY_OTP_SMS_FAILURE, payload: "Unauthorized" });
    }
  } catch (error) {
    yield put({
      type: WITHDRAWAL_FAILURE,
      payload: error.response.message,
    });
  }
}

function* getPPGToken({ payload }) {
  try {
    const details = {
      xxx: "xxx",
      xxx: "xxx",
      xxx: "xxx",
      xxx: "xxx",
    };
    let formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    const res = yield call(api.post, `${API_URL.mp}/login`, formBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    });

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: GET_PPG_TOKEN_SUCCESS });
      if (data.access_token) {
        let bankSetup = { token: data.access_token, rsp: false };
        if (payload === "rsp") {
          bankSetup.rsp = true;
        }

        yield put(getWithdrawalBankList(bankSetup));
      }
    }
  } catch (error) {
    yield put({
      type: GET_PPG_TOKEN_FAILURE,
      payload: error.message,
    });
  }
}

function* getWithdrawalBankListSaga({ payload }) {
  try {
    const { rsp = false } = payload;
    const link = rsp
      ? "/payment/payment-type-status?payCatCd=FPX&isSell=0&isBuy=1&channelCd=Mini Program"
      : "/payment/payment-type-status?payCatCd=FPX&isSell=1&channelCd=Mini Program";

    const res = yield call(api.get, `${API_URL.ppg}${link}`);
    const { data } = res;

    if (res.status === 200) {
      yield put({ type: GET_WITHDRAWAL_BANK_LIST_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: GET_WITHDRAWAL_BANK_LIST_FAILURE,
      payload: error.message,
    });
  }
}

function* getWithdrawalBankAccount({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/bank-accounts?cifId=${payload}`
    );

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: GET_WITHDRAWAL_BANK_ACCOUNT_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({
      type: GET_WITHDRAWAL_BANK_ACCOUNT_FAILURE,
      payload: error.message,
    });
  }
}

function* getNewsFeed() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/feed/list`);
    // const res = yield call(api.get, `${API_URL.dev}/feed/list`);
    const { data } = res;

    yield put({ type: GET_NEWS_FEED_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_NEWS_FEED_FAILURE, payload: error.message });
  }
}

function* getOnboarding() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/landing-pages`);
    const { data } = res;

    yield put({ type: GET_ONBOARDING_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ONBOARDING_FAILURE, payload: error.message });
  }
}

function* sendEmailOtp({ payload, cb }) {
  try {
    const { email, fullName } = payload;

    const res = yield call(
      api.post,
      `${API_URL.mp}/email/resend-verification`,
      { email: email, name: fullName }
    );

    const { data } = res;

    if (data) {
      yield put({ type: SEND_OTP_EMAIL_SUCCESS, payload: data });
      history.push(`/profile/info/updateEmail/verify/${email}`);
    }
  } catch (error) {
    yield put({ type: SEND_OTP_EMAIL_FAILURE, payload: error.message });
  } finally {
    typeof cb === "function" && cb();
  }
}

function* verifyEmailOtp({ payload,cb }) {
  let err;
  try {
    const res = yield call(api.post, `${API_URL.mp}/email/verify`, {
      email: payload.email,
      token: payload.token,
    });

    const { data } = res;

    if (data === "OK") {
      yield put({ type: VERIFY_OTP_EMAIL_SUCCESS });

      // yield call(updateProfile, {
      //   payloadData: payload.payloadData,
      //   from: payload.from
      // });
      // history.push("/profile/info");
      // cogoToast.success("You have successfully updated your email!");
    }
  } catch (error) {
    err = error;
    yield put({ type: VERIFY_OTP_EMAIL_FAILURE, payload: error.message });
  } finally {
    typeof cb === "function" && cb(err);
  }
}

function* updateProfile(action) {
  try {
    const { payload, callback } = action;
    // Get the value of allowUpdate from the state
    const allowUpdate = yield select(
      state => state.profileReducer.allowUpdateProfile.value
    );

    // Check if the update comes from profile and update permission is granted
    if (payload.from === PROFILE && !allowUpdate) {
      throw new Error("timeoutError");
    }

    // Get environment feature information
    const envFeature = yield select(
      state => state.getStartedReducer.startedStep
    );
    // Get ECDD status from the goal reducer
    const ecddStatus = yield select(state => state.goal.ecddStatus);
    // Get user information from local storage
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    const { payloadData, from } = payload;

    // Validate address parameters
    let paramValidation = {
      postCode: payloadData.address.postCode,
      state: payloadData.address.state,
    };

    let resultValidation = validationAddress(paramValidation);
    if (!resultValidation) {
      // Raise error if validation fails
      forceError({
        api: `${API_URL.mp}/mp/customer`,
        payloadData,
      });
      throw new Error("timeoutError");
    }

    // Call API to update profile
    const res = yield call(api.put, `${API_URL.mp}/mp/customer`, payloadData, {
      timeout: 30000,
    });

    // Get data from response
    const { data } = res;

    // Handle situation when no AML data is found
    if (!data.amlHistory) {
      forceError({
        api: `${API_URL.mp}/mp/customer`,
        message: data.amlHistory || "Not Found",
        status: res.status || 200,
      });
      throw new Error("timeoutError");
    }

    // Handle AML data if found
    if (data.amlHistory.length > 0) {
      if ("errorCode" in data.amlHistory[0] || data.amlHistory.length === 0) {
        forceError({
          api: `${API_URL.mp}/mp/customer`,
          message: data.amlHistory,
          status: res.status || 200,
        });
        throw new Error("timeoutError");
      }
    }

    // Update profile and save to local storage
    yield put(setProfileInfoV2(data));
    localStorage.setItem("mpUserInfo", JSON.stringify(data));

    // Handle logic based on AML status
    if (res.status === 200) {
      if (data.amlHistory && data.amlHistory[0].amlSts === "5") {
        forceError({
          url: `${API_URL.mp}/mp/customer`,
          message: `Aml Status = ${data.amlHistory[0].amlSts} `,
          status: res.status || 200,
        });
        throw new Error("timeoutError");
      }

      // Handle logic based on different AML status
      if (data.amlHistory && data.amlHistory[0].amlSts === "6") {
        if (from === ONBOARDING) {
          localStorage.setItem("isNewUser", GOAL_CREATION);
          return history.push(`/step-one`);
        }
        if (typeof from === "object") {
          const { code = "NOTSET", type = "NOTSET" } = from.value;
          if (type.toLowerCase() === "invest") {
            localStorage.setItem("from", "onboarding");
            return history.push(`/deeplink/invest-introduction`, {
              type,
              code,
            });
          }
          return history.push(`/deeplink/${type}/${code}`);
        }
        localStorage.setItem("isNewUser", OLD_USER);
        return history.push(`/profile`);
      }

      // Handle situation when AML status is 3 or 4
      if (
        data.amlHistory &&
        (data.amlHistory[0].amlSts === "3" ||
          data.amlHistory[0].amlSts === "4" ||
          data.amlHistory[0].amlResult === "3")
      ) {
        if (from === ONBOARDING) {
          localStorage.setItem("isNewUser", ONBOARDING_USER);
          return history.push(`/onBoarding/ecdd/${ONBOARDING}ECDD`);
        } else if (from === PROFILE) {
          if (ecddStatus === "OK") {
            localStorage.setItem("isNewUser", OLD_USER);
            return history.push(`/onBoarding/ecdd/${PROFILE}`);
          } else {
            yield put(checkEcddStatus(userInfo.masterId));
          }
        } else if (typeof from === "object") {
          localStorage.setItem("isNewUser", ONBOARDING_USER);
          return history.push(`/onBoarding/ecdd/${from.from}`, from.value);
        } else {
          return;
        }
      }

      // Handle redirection based on page source
      if (from === ONBOARDING) {
        localStorage.setItem("isNewUser", GOAL_CREATION);
        localStorage.setItem("from", "onboarding");
        return history.push(
          envFeature === ENV_SAVE ? `/step-one` : "/invest-introduction"
        );
      } else if (from === PROFILE) {
        localStorage.setItem("isNewUser", OLD_USER);
        return history.push(`/profile`);
      } else if (typeof from === "object") {
        const { code = "NOTSET", type = "NOTSET" } = from.value;
        if (type.toLowerCase() === "invest") {
          localStorage.setItem("from", "onboarding");
          return history.push(`/deeplink/invest-introduction`, {
            type,
            code,
          });
        }
        return history.push(`/deeplink/${type}/${code}`);
      } else {
        return;
      }
    }
    if ("callback" in action && typeof action.callback === "function") {
      action.callback();
    }
  } catch (error) {
    // Handle errors that occur during profile update process
    if (error.message === "timeoutError") {
      yield put({ type: UPDATE_PROFILE_FAILURE, payload: "timeoutError" });
    } else {
      yield put({ type: UPDATE_PROFILE_FAILURE, payload: error.message });
    }
  }
}

function* getOccupation() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/occupation`);
    const { data } = res;

    yield put({ type: GET_OCCUPATION_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_OCCUPATION_FAILURE, payload: error.message });
  }
}

function* getBusinessNature() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/nature-of-business`);
    const { data } = res;

    yield put({ type: GET_BUSINESS_NATURE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_BUSINESS_NATURE_FAILURE, payload: error.message });
  }
}

function* getAnnualIncome() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/annual-income`);
    const { data } = res;

    yield put({ type: GET_ANNUAL_INCOME_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_ANNUAL_INCOME_FAILURE, payload: error.message });
  }
}

function* getRace() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/race`);
    const { data } = res;

    yield put({ type: GET_RACE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_RACE_FAILURE, payload: error.message });
  }
}

function* getMaritalStatus() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/marital-status`);
    const { data } = res;

    yield put({ type: GET_MARITAL_STATUS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_MARITAL_STATUS_FAILURE, payload: error.message });
  }
}

function* getCountry() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/country`);
    const { data } = res;

    yield put({ type: GET_COUNTRY_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_COUNTRY_FAILURE, payload: error.message });
  }
}

function* getState() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/state`);
    const { data } = res;

    yield put({ type: GET_STATE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_STATE_FAILURE, payload: error.message });
  }
}

function* getSourceOfFund() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/source-of-fund`);
    const { data } = res;

    yield put({ type: GET_SOURCE_OF_FUND_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_SOURCE_OF_FUND_FAILURE, payload: error.message });
  }
}

function* getSourceOfIncome() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/source-of-income`);
    const { data } = res;

    yield put({ type: GET_SOURCE_OF_INCOME_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_SOURCE_OF_INCOME_FAILURE, payload: error.message });
  }
}

function* getSupportingDocs() {
  try {
    const res = yield call(api.get, `${API_URL.mp}/mp/supporting-document`);
    const { data } = res;

    yield put({ type: GET_SUPPORTING_DOCS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_SUPPORTING_DOCS_FAILURE, payload: error.message });
  }
}

function* getTransactionHistory({ payload }) {
  try {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    const cifId = userInfo.masterId;

    const {
      page = 1,
      size = 5,
      from = "",
      to = "",
      type = "",
      fundCd = "",
      cifPlanId = "",
      category = "",
      showAll = "",
    } = payload;
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/transaction-history/${cifId}`,
      {
        params: {
          page,
          size,
          from,
          to,
          type,
          fundCd,
          cifPlanId,
          category,
          showAll,
        },
      }
    );
    const { data } = res;
    yield put({
      type: GET_TRX_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({ type: GET_TRX_HISTORY_FAILURE, payload: error.message });
  }
}

function* ecddRequest({ payload }) {
  try {
    // const profile = JSON.parse(localStorage.getItem("mpUserInfo"))
    const profile = yield select(state => state.goal.profile);
    const startedStep = yield select(
      state => state.getStartedReducer.startedStep
    );
    const newAml = [...profile.amlHistory];
    newAml[0] = { ...profile.amlHistory[0], amlSts: "4" };

    const newData = { ...profile, amlHistory: newAml };

    const { payloadData, from, deeplinkParams } = payload;

    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/customer/ecdd`,
      payloadData
    );

    // const { data } = res;

    if (res.status === 200) {
      yield put({ type: ECDD_SUCCESS });
      yield put(checkEcddStatus(profile.masterId));

      switch (from) {
        case DEEPLINK:
          yield put({
            type: UPDATE_PROFILE_SUCCESS,
            payload: newData,
          });
          localStorage.setItem("mpUserInfo", JSON.stringify(newData));
          history.push(
            `/deeplink/${deeplinkParams.type}/${deeplinkParams.code}`
          );
          break;
        case ONBOARDING:
          yield put({
            type: UPDATE_PROFILE_SUCCESS,
            payload: newData,
          });

          localStorage.setItem("isNewUser", GOAL_CREATION);
          localStorage.setItem("mpUserInfo", JSON.stringify(newData));
          localStorage.setItem("from", "ecdd");
          history.replace(
            startedStep === ENV_SAVE ? "/step-one" : "/invest-introduction"
          );
          break;
        case `${ONBOARDING}ECDD`:
          yield put({
            type: UPDATE_PROFILE_SUCCESS,
            payload: newData,
          });

          localStorage.setItem("isNewUser", GOAL_CREATION);
          localStorage.setItem("mpUserInfo", JSON.stringify(newData));
          localStorage.setItem("from", "ecdd");
          history.replace(
            startedStep === ENV_SAVE ? "/dashboard" : "/invest-introduction"
          );
          break;
        case "INVESTFUNDS":
        case "FUNDDETAILS":
        case "GOALDETAILS":
          localStorage.setItem("isNewUser", OLD_USER);
          yield put(setCurrentEcddStatus(true));
          history.back();
          break;
        case "PROFILEINFO":
        case "PROFILE":
          localStorage.setItem("isNewUser", OLD_USER);
          yield put(setCurrentEcddStatus(true));
          history.push("/profile");
          break;
        case "RSP":
          localStorage.setItem("isNewUser", OLD_USER);
          yield put(setCurrentEcddStatus(true));
          history.go(-2);
          break;
        default:
          localStorage.setItem("isNewUser", OLD_USER);
          history.back();
          break;
      }
    }
  } catch (error) {
    yield put({ type: ECDD_FAILURE, payload: error.message });
  }
}

function* sendContactMessage({ payload }) {
  try {
    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/customer/contact-us`,
      payload
    );

    if (res.status === 200) {
      yield put({ type: SEND_CONTACT_MESSAGE_SUCCESS });
    }
  } catch (error) {
    yield put({ type: SEND_CONTACT_MESSAGE_FAILURE, payload: error.message });
  }
}

function* sendFeedback({ payload }) {
  try {
    const res = yield call(api.post, `${API_URL.mp}/feedbacks`, payload);

    const { data } = res;

    if (res.status === 200) {
      if (data.errorCode) {
        alert(data.message);
        yield put({ type: SEND_FEEDBACK_FAILURE, payload: data.message });
      } else {
        cogoToast.success("Feedback sent!");
        yield put({ type: SEND_FEEDBACK_SUCCESS });
      }

      history.push("/dashboard");
    }
  } catch (error) {
    yield put({ type: SEND_FEEDBACK_FAILURE, payload: error.message });
  }
}

function* checkEcddStatusSaga({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/mp/customer/ecdd-status?cifId=${payload}`
    );

    const { data } = res;

    if (res.status === 200) {
      if (Object.keys(data).length === 0 && data.constructor === Object) {
        yield put({ type: CHECK_ECDD_STATUS_SUCCESS, payload: "OK" });
      } else if (Object.keys(data).length > 0 && data.constructor === Object) {
        yield put({ type: CHECK_ECDD_STATUS_SUCCESS, payload: data });
      } else {
        let errorLog = {
          api: res.url || window.location.pathname,
          message: `this is response from ecdd: ${data}` || "---",
          errorResponse: false,
          status: res.status || "Not Found",
        };
        sendLogError(errorLog);
        yield put({ type: CHECK_ECDD_STATUS_SUCCESS, payload: data });
      }
    }
  } catch (error) {
    yield put({ type: CHECK_ECDD_STATUS_FAILURE, payload: error.message });
  }
}

function* sendSMSOtp({ payload }) {
  try {
    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/sms/send-verification`,
      payload
    );

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: SMS_OTP_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: SMS_OTP_FAILURE, payload: error.message });
  }
}

function* verifySMSOtp({ payload, withdrawalData }) {
  try {
    // window.consoleLog("otp payload: " + JSON.stringify(payload));
    const res = yield call(api.post, `${API_URL.mp}/email/verify`, {
      email: payload.email,
      token: payload.token,
    });

    const { data } = res;
    // window.consoleLog("verify data: " + JSON.stringify(data));
    if (data === "OK") {
      yield put({ type: VERIFY_OTP_SMS_SUCCESS, payload: data });
      yield put(withdrawalRequest(withdrawalData));
    } else if (data.hasOwnProperty("errorCode")) {
      yield put({ type: VERIFY_OTP_SMS_FAILURE, payload: "Unauthorized" });
    } else {
      yield put({ type: VERIFY_OTP_SMS_FAILURE, payload: data });
    }
  } catch (error) {
    // window.consoleLog("verify error: " + error);
    yield put({ type: VERIFY_OTP_SMS_FAILURE, payload: error.message });
  }
}

function* checkGoalName({ payload }) {
  try {
    const { cifId, planAlias } = payload;

    const res = yield call(
      api.get,
      `${
        API_URL.mp
      }/smp/goal-creation/check-alias?cifId=${cifId}&planAlias=${planAlias}`
    );

    const { data } = res;

    if (res.status === 200 && !Array.isArray(data)) {
      if (data.isExist) {
        yield put({
          type: CHECK_GOAL_NAME_FAILURE,
          payload: "Please enter a different name for your new goal",
        });
      } else {
        yield put({ type: CHECK_GOAL_NAME_SUCCESS, payload: data });
        history.push("/step-three");
      }
    } else {
      yield put({ type: CHECK_GOAL_NAME_SUCCESS, payload: false });
    }
  } catch (error) {
    yield put({ type: CHECK_GOAL_NAME_FAILURE, payload: error.message });
  }
}

function* getGoalSummary({ payload }) {
  try {
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/goal-creation/summary/${payload.cifId}?type=${
        payload.type
      }`
    );

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: GET_GOAL_SUMMARY_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: GET_GOAL_SUMMARY_FAILURE, payload: error.message });
  }
}

function* getGoalInterest({ payload }) {
  try {
    const res = yield call(api.get, `${API_URL.mp}/smp/plans/${payload}`);

    const { data } = res;

    if (res.status === 200) {
      yield put({ type: GET_GOAL_INTEREST_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: GET_GOAL_INTEREST_FAILURE, payload: error.message });
  }
}

function* getCustomer({ payload }) {
  try {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    const cifId = userInfo.masterId;

    const res = yield call(api.get, `${API_URL.mp}/mp/customer?cifId=${cifId}`);

    const { data } = res;

    yield put({ type: GET_CUSTOMER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_CUSTOMER_FAILURE, payload: error.message });
  }
}

function* createGoalRsp(action) {
  try {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    let type = yield select(savingType);

    const res = yield call(
      api.post,
      `${API_URL.mp}/smp/goal-creation`,
      action.payload
    );
    const { data } = res;

    // const res = yield fetchPost(
    //   `${API_URL.mp}/smp/goal-creation`,
    //   action.payload
    // );
    // let data;

    // if (res.status !== 200) {
    //   const error = yield res.json();

    //   let logError = {
    //     api: res.url || window.location.pathname,
    //     message: error.message || "---",
    //     errorResponse: true,
    //     status: res.status || "Not Found"
    //   };
    //   sendLogError(logError);
    //   throw new Error("failedToCreateGoal");
    // }

    //data = yield res.json();

    if (!data.accountNo || data.accountNo === "")
      throw new Error("accountNoErrorNull");
    if (res.status === 200) {
      localStorage.setItem("isNewUser", OLD_USER);
      yield put({ type: GOAL_CREATION_SUCCESS });
      yield put(
        setbackPayloadRsp({ cifPlanId: data.id, productId: data.productId })
      );
      yield put({ type: SET_CIF_PLAN_ID, payload: data.id });
      action.callback({ cifPlanId: data.id, productId: data.productId });
    }
  } catch (error) {
    if (error.message === "accountNoErrorNull") {
      forceError({
        url: `${API_URL.mp}/smp/goal-creation`,
        message: "account no error",
        status: error.status || 200,
      });
      yield put(deleteGoalForm());
      yield put({ type: GOAL_CREATION_FAILURE, payload: "timeoutError" });
    } else if (error.message === "failedToCreateGoal") {
      yield put(deleteGoalForm());
      yield put({ type: GOAL_CREATION_FAILURE, payload: "failedToCreateGoal" });
    } else {
      yield put({ type: GOAL_CREATION_FAILURE, payload: error.message });
    }
  }
}

function* getCampaignSaga() {
  try {
    const userInfo = JSON.parse(localStorage.getItem("mpUserInfo"));
    const cifId = userInfo.masterId;
    const res = yield call(
      api.get,
      `${API_URL.mp}/smp/customer/campaigns/${cifId}`
    );
    const { data } = res;

    if (data.errorCode) {
      throw new Error("failedGetCampaigs");
    }

    let tempCampaign = { save: [], invest: [] };
    data.map(item => {
      if (item.productCategoryCd === "MPSave") {
        tempCampaign.save.push(item);
      }
      if (item.productCategoryCd === "MPInvest") {
        tempCampaign.invest.push(item);
      }
    });
    yield put({ type: GET_CAMPAIGN_SUCCESS, payload: tempCampaign });
  } catch (error) {
    yield put({ type: GET_CAMPAIGN_FAILED });
  }
}

const forceError = ({ url, message, status }) => {
  let payload = {
    api: url || window.location.pathname,
    message: message || "---",
    errorResponse: false,
    status: status || "Not Found",
  };
  sendLogError(payload);
};

export function* GoalSaga() {
  yield all([
    takeLatest(GET_GOAL_REQUEST, getGoalList),
    takeLatest(GET_GOAL_PERIODS_REQUEST, getGoalPeriods),
    takeLatest(GET_INSTALLMENT_REQUEST, getInstallment),
    takeLatest(GOAL_CREATION_REQUEST, createGoal),
    takeLatest(GET_CUSTOMER_GOALS_REQUEST, getCustomerGoals),
    takeLatest(GET_CUSTOMER_GOAL_DETAIL_REQUEST, getCustomerGoalDetail),
    takeLatest(GET_NEWS_FEED_REQUEST, getNewsFeed),
    takeLatest(GET_ONBOARDING_REQUEST, getOnboarding),
    takeLatest(GET_OCCUPATION_REQUEST, getOccupation),
    takeLatest(GET_BUSINESS_NATURE_REQUEST, getBusinessNature),
    takeLatest(GET_ANNUAL_INCOME_REQUEST, getAnnualIncome),
    takeLatest(GET_RACE_REQUEST, getRace),
    takeLatest(GET_MARITAL_STATUS_REQUEST, getMaritalStatus),
    takeLatest(GET_COUNTRY_REQUEST, getCountry),
    takeLatest(GET_STATE_REQUEST, getState),
    takeLatest(GET_SOURCE_OF_FUND_REQUEST, getSourceOfFund),
    takeLatest(GET_SOURCE_OF_INCOME_REQUEST, getSourceOfIncome),
    takeLatest(GET_SUPPORTING_DOCS_REQUEST, getSupportingDocs),
    takeLatest(GET_TRX_HISTORY_REQUEST, getTransactionHistory),
    takeLatest(GET_PPG_TOKEN_REQUEST, getPPGToken),
    takeLatest(GET_WITHDRAWAL_BANK_LIST_REQUEST, getWithdrawalBankListSaga),
    takeLatest(GET_WITHDRAWAL_BANK_ACCOUNT_REQUEST, getWithdrawalBankAccount),
    takeLatest(DEPOSIT_REQUEST, sagaDepositRequest),
    takeLatest(WITHDRAWAL_REQUEST, sagaWithdrawalRequest),
    takeLatest(ECDD_REQUEST, ecddRequest),
    takeLatest(SEND_OTP_EMAIL_REQUEST, sendEmailOtp),
    takeLatest(VERIFY_OTP_EMAIL_REQUEST, verifyEmailOtp),
    takeLatest(UPDATE_PROFILE_REQUEST, updateProfile),
    takeLatest(SEND_CONTACT_MESSAGE_REQUEST, sendContactMessage),
    takeLatest(SEND_FEEDBACK_REQUEST, sendFeedback),
    takeLatest(CHECK_ECDD_STATUS_REQUEST, checkEcddStatusSaga),
    takeLatest(SMS_OTP_REQUEST, sendSMSOtp),
    takeLatest(VERIFY_OTP_SMS_REQUEST, verifySMSOtp),
    takeLatest(CHECK_GOAL_NAME_REQUEST, checkGoalName),
    takeLatest(GET_GOAL_SUMMARY_REQUEST, getGoalSummary),
    takeLatest(GET_GOAL_INTEREST_REQUEST, getGoalInterest),
    takeLatest(CHECK_WITHDRAWAL_MAX_AMOUNT_REQUEST, checkWithdrawalMaxAmount),
    takeLatest(GET_CUSTOMER_REQUEST, getCustomer),
    takeLatest(CREATE_GOAL_RSP, createGoalRsp),
    takeLatest(GET_CAMPAIGN, getCampaignSaga),
  ]);
}
