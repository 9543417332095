import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { withRouter } from "react-router";
import { compose } from "underscore";
import { setStatus } from "../../../utils/functions/goalCombineTrx";
import {
  CATEGORY_SAVING,
  GENERAL_SAVING,
  DASHBOARD,
} from "../../redux/constant";
import { getCustAccSts } from "../redux/saga";
import {
  SavingItem,
  CustomButton,
  Dropdown,
  DropdownItem,
  DefaultLoader,
  CampaignCard,
  AlertAccount,
  NewsList,
  MenuBar,
  OverviewCard,
  AlertDDA,
  EmptyStateSave,
  GiveawayAlert,
} from "../../../components";
import {
  checkAcctStsCashIn,
  checkAcctStsCashOut,
} from "../../../utils/functions/validationsDeposit";

import { icons, images } from "../../../constants";
import styles from "../styles";
import "../../styles/index.scss"; // global styles
import { dataLayerGTM } from "../../../utils/functions/dataLayerGTM";
import { stringDateToNumber } from "../../../utils/functions/stringDateToNumber";
import {
  getCustomerGoals,
  getGoalSummary,
  setSavingGoalDetails,
  setSavingType,
  setGeneralSavingDetails,
} from "../../redux/action";
import { getProjectedReturn } from "../../getStarted/redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { injectHtmlToSpecificSentence } from "../../../utils/functions/formatCampaignContent";
import { NOT_ALLOWED_ACCOUNT_STATUS } from "../../../constants/subaccount-status";
import { getAccountStatusV2 } from "../redux/actions";
import { useGiveawayAlert } from "../../../utils/hooks";

const SAVE_RSP = "SAVE_RSP";
const SAVE_CASHIN = "SAVE_CASHIN";

const Save = ({
  customerGoals,
  setGeneralSavingDetails,
  setSavingGoalDetails,
  setSavingType,
  goalSummary,
  history,
  loadingCampaign,
  getGoalSummary,
  getCustomerGoals,
  getProjectedReturn,
  saveProjectedReturnValue,
  getAccountStatusV2,
  profile,
  loading,
  setbackPayloadRsp,
  setCommingFrom,
  handleViewAllNews,
  handleCheckEcdd,
  campaignsSave = [],
  newsFeed,
  loadingCustomerGoals,
  mpToken,
  accountStatusLoading,
}) => {
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [target, setTarget] = useState("");
  const [savingGoal, setSavingGoal] = useState([]);
  const [rspList, setRspList] = useState({ full: false, list: [] });
  const [ddaPending, setDdaPending] = useState(false);

  const [isLoading, setIsLoading] = useState({
    render: true,
    subAccountSts: false,
  });
  const [accountSts, setAccountSts] = useState({
    status: "Active",
    alert: false,
  });

  // const { notifyGiveaway, setNotifyGiveaway } = useGiveawayAlert();

  const now = new Date().getTime();

  const handleTACPdf = url => {
    // window.my.showLoading();
    // window.my.postMessage({
    //   api: "downloadFile",
    //   pdf: url,
    //   event: "openPdf",
    // });
    window.my.navigateTo({
      url: "/pages/link/link?url=" + encodeURIComponent(url),
    });
  };

  useEffect(() => {
    getGoalSummary({ cifId: profile.masterId, type: "MPSave" });
    getCustomerGoals();
    getProjectedReturn();
  }, []);

  useEffect(() => {
    setIsLoading({ ...isLoading, render: false });

    setbackPayloadRsp({});
  }, []);

  useEffect(() => {
    if (customerGoals && customerGoals.length > 0) {
      setSavingGoal(setStatus(customerGoals).slice(0, 2));
    }
  }, [customerGoals]);

  const handleViewAllGoals = () => {
    dataLayerGTM("click_tag", "Click", "Link", "View All Goals");
    history.push("/goal-list");
  };

  /**
   ** check if subaccount status is possible to do transaction
   ** START
   */

  const getSubAccSts = (item, type) => {
    const { accountNo } = item;

    if ((!accountNo || accountNo === "") && type === "cashIn") {
      history.push(`/make-deposit/${DASHBOARD}CASHIN`);
      return;
    }
    if ((!accountNo || accountNo === "") && type === "cashOut") {
      history.push("/make-withdrawal");
      return;
    }

    setIsLoading({ ...isLoading, subAccountSts: true });
    let newObj = {
      status: "Active",
      alert: false,
    };

    //! somewhere inside this then or catch block causing warning: Can't perform a React state update on an unmounted component.
    getCustAccSts(accountNo, mpToken)
      .then(res => {
        if (res.status === 204) {
          throw new Error("no content");
        }
        let result = res.data;
        let { sts } = result;
        newObj.status = sts;
        let stopAction =
          type === "cashIn"
            ? checkAcctStsCashIn(sts)
            : checkAcctStsCashOut(sts);
        if (stopAction && type === "cashIn") {
          history.push(`/make-deposit/${DASHBOARD}CASHIN`);
        }

        if (!stopAction && type === "cashIn") {
          newObj.alert = true;
        }

        if (stopAction && type === "cashOut") {
          history.push("/make-withdrawal");
        }

        if (!stopAction && type === "cashOut") {
          newObj.alert = true;
        }
        setAccountSts(newObj);

        setIsLoading({ ...isLoading, subAccountSts: false });
      })
      .catch(err => {
        if (type === "cashIn") {
          history.push(`/make-deposit/${DASHBOARD}CASHIN`);
        } else {
          history.push("/make-withdrawal");
        }
        setIsLoading({ ...isLoading, subAccountSts: false });
      });
    setOpenDeposit(false);
    setOpenWithdraw(false);
  };
  /**
   ** check if subaccount status is possible to do transaction
   ** END
   */

  const ddaStatus = status => {
    if (status) {
      switch (true) {
        case parseFloat(status) >= 2 && parseFloat(status) <= 4:
        case parseFloat(status) >= 13 && parseFloat(status) <= 14:
        case parseFloat(status) >= 22 && parseFloat(status) <= 24:
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const handleGoRsp = async item => {
    if (item.id === SAVE_RSP) {
      history.push("/step-one");
      return;
    }
    const { rspStatus = "" } = item;
    let ddaResult = ddaStatus(rspStatus);
    if (ddaResult) {
      setDdaPending(ddaResult);
      return;
    }

    getAccountStatusV2(item.accountNo, subaccountResult => {
      if (
        ["Suspended", "Frozen", "Closing", "Closed"].includes(
          subaccountResult.sts
        )
      ) {
        setAccountSts({ status: subaccountResult.sts, alert: true });
        return;
      }

      if (item.plan.retailFlag === true) {
        setSavingType(GENERAL_SAVING);
        setGeneralSavingDetails(item);
      } else {
        setSavingType(CATEGORY_SAVING);
        setSavingGoalDetails(item);
      }

      history.push(`/make-deposit/${DASHBOARD}/success/auto-deposit/setupRSP`);
    });
  };

  const handleOpenRsp = () => {
    setTarget(SAVE_RSP);

    setOpenDeposit(true);
  };

  const handleDeposit = item => {
    dataLayerGTM("click_tag", "Click", "Dropdown", `${item.planAlias}`);
    if (item.plan.retailFlag === true) {
      setSavingType(GENERAL_SAVING);
    } else {
      setSavingType(CATEGORY_SAVING);
    }
    setSavingGoalDetails(item);
    getSubAccSts(item, "cashIn");
  };

  const handleWithdraw = item => {
    dataLayerGTM("click_tag", "Click", "Dropdown", `${item.planAlias}`);
    if (item.plan.retailFlag === true) {
      setSavingType(GENERAL_SAVING);
      setGeneralSavingDetails(item);
    } else {
      setSavingType(CATEGORY_SAVING);
      setSavingGoalDetails(item);
    }
    getSubAccSts(item, "cashOut");
  };

  const handleCreateNewGoal = () => {
    const headAccountIsObjectAndItsStatusExist =
      "headAccount" in profile &&
      typeof profile.headAccount === "object" &&
      "sts" in profile.headAccount;

    if (headAccountIsObjectAndItsStatusExist) {
      const headAccountStatusIsSuspended =
        profile.headAccount.sts === "Suspended";

      const headAccountStatusIsDeactived = NOT_ALLOWED_ACCOUNT_STATUS.includes(
        profile.headAccount.sts
      );

      if (headAccountStatusIsSuspended)
        return setAccountSts(prev => ({
          ...prev,
          alert: true,
          status: "Suspended",
        }));

      if (headAccountStatusIsDeactived)
        return setAccountSts(prev => ({
          ...prev,
          alert: true,
          status: "Deactived",
        }));
    }

    history.push("/step-one");
  };

  const handleOpenDeposit = () => {
    setTarget(SAVE_CASHIN);
    setOpenDeposit(true);
  };

  const handleOpenWithdraw = () => {
    dataLayerGTM(
      "click_tag",
      "Click",
      "Button",
      "Click Dashboard - Cash out button"
    );
    setOpenWithdraw(true);
  };

  const heightDropdown = val => {
    if (target !== SAVE_RSP) {
      return customerGoals.length > 10 ? val - val / 10 : val / 2;
    }

    if (target === SAVE_RSP && rspList.full) {
      return val / 10;
    }
    if (target === SAVE_RSP && !rspList.full) {
      return rspList.list.length > 10 ? val - val / 2 : val / 3;
    }
    return customerGoals.length > 10 ? val - val / 10 : val / 2;
  };

  useEffect(() => {
    if (Array.isArray(customerGoals) && customerGoals.length > 0) {
      const showableRSPStatus = [null, "1", "6", "16"];
      const showableFunds = (customerGoals || []).filter(({ rspStatus }) =>
        showableRSPStatus.includes(rspStatus)
      );

      let tempRspSet = { full: false, list: showableFunds };

      if (customerGoals.length > 0 && showableFunds.length === 0) {
        tempRspSet = {
          full: true,
          list: [{ id: SAVE_RSP, planAlias: "Create new saving goal" }],
        };
      }
      setRspList(tempRspSet);
    }
  }, [customerGoals]);

  if (
    loading ||
    isLoading.render ||
    isLoading.subAccountSts ||
    loadingCampaign ||
    accountStatusLoading ||
    loadingCustomerGoals
  )
    return (
      <div className="center">
        <DefaultLoader />
      </div>
    );

  return (
    <>
      <AlertAccount
        title={`Account ${
          accountSts.status === "Suspended" ? "Suspended" : "Inactive"
        }`}
        firstText={
          accountSts.status === "Suspended"
            ? "Your account is currently suspended, we are unable to process your Cash In request however, you can still proceed with Cash Out."
            : "Your account is currently inactive, we're sorry for the inconvenience."
        }
        secondText={
          <span>
            For assistance, kindly contact our{" "}
            <span
              onClick={() => {
                setAccountSts({ ...accountSts, alert: false });

                window.my.call("openAppLink", {
                  url: "https://wa.me/60162996213",
                });
              }}
              style={{ color: "#1278CC" }}
            >
              Customer Care Centre.
            </span>
          </span>
        }
        isOpen={accountSts.alert}
        handleClose={() => setAccountSts({ ...accountSts, alert: false })}
      />
      {/* <GiveawayAlert
        isOpen={notifyGiveaway}
        handleClose={() => {
          localStorage.setItem("lastNotifyGiveaway", new Date().toDateString());
          setNotifyGiveaway(false);
        }}
        productType="save"
      /> */}
      {savingGoal.length > 0 ? (
        <>
          <AlertDDA
            title="This Goal has already enabled Auto Cash In."
            firstText="Your Auto Cash In request is being processed, please come back and check the status later."
            isOpen={ddaPending}
            handleClose={() => setDdaPending(false)}
          />

          {/* Dropdown */}
          <Dropdown
            bottomHeight={({ maxHeight }) => heightDropdown(maxHeight)}
            isOpen={openDeposit}
            handleDismiss={() => {
              setTarget("");
              setOpenDeposit(false);
            }}
          >
            <DropdownItem
              containerStyle={{ marginBottom: "80px" }}
              title={`${
                target === SAVE_RSP
                  ? "Please select a goal or fund for setting up Auto Cash In?"
                  : "Please select a goal to Cash In"
              }`}
              fullRsp={target === SAVE_RSP && rspList.full}
              goals={target === SAVE_RSP ? rspList.list : customerGoals}
              onClick={val => {
                target === SAVE_CASHIN ? handleDeposit(val) : handleGoRsp(val);
              }}
            />
          </Dropdown>
          <Dropdown
            bottomHeight={({ maxHeight }) =>
              customerGoals.length > 10
                ? maxHeight - maxHeight / 10
                : maxHeight / 2
            }
            isOpen={openWithdraw}
            handleDismiss={() => setOpenWithdraw(false)}
          >
            <DropdownItem
              containerStyle={{ marginBottom: "80px" }}
              title="Please select a goal to Cash Out"
              goals={customerGoals}
              onClick={handleWithdraw}
            />
          </Dropdown>

          <OverviewCard
            totalAsset={goalSummary.totalAsset}
            totalReturn={goalSummary.totalReturn}
            lastUpdate={moment(goalSummary.lastUpdated)
              .utcOffset("+0800")
              .format("DD MMM YYYY, h:mm A")}
            type="save"
            projectedReturn={saveProjectedReturnValue.replace("*", "")}
          />

          <MenuBar
            handleWithdraw={handleOpenWithdraw}
            handleSwitching={handleCheckEcdd({
              cb: () => history.push("/switching/create"),
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Click Dashboard - Switching button",
              },
            })}
            handleDeposit={handleCheckEcdd({
              cb: handleOpenDeposit,
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Click Dashboard - Cash In button",
              },
            })}
            handleAutoDeposit={handleCheckEcdd({
              cb: handleOpenRsp,
              ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
              gtm: {
                event: "click_tag",
                action: "Click",
                category: "Button",
                label: "Click Dashboard - RSP button Dashboard",
              },
            })}
          />

          {/* Campaign List */}
          {campaignsSave.map((campaign, idx) =>
            now < stringDateToNumber(campaign.endDate) ? (
              <div style={{ margin: "0 1rem 1rem 1rem" }} key={idx}>
                <CampaignCard
                  startDate={campaign.startDate}
                  endDate={campaign.endDate}
                  onCashIn={handleCheckEcdd({
                    cb: handleOpenDeposit,
                    ecddExpiredCb: () =>
                      history.push("/onBoarding/ecdd/DASHBOARD"),
                    gtm: {
                      event: "click_tag",
                      action: "Click",
                      category: "Button",
                      label: "Click Dashboard - Cash In button",
                    },
                  })}
                  openPDF={() => handleTACPdf(campaign.extUrl)}
                  type="lebaran"
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: injectHtmlToSpecificSentence({
                        text: campaign.desc.replace(/\s(Get)/, "\n$1"),
                        regex: /(Monthly Auto Cash In|RM500|3%\*)/g,
                        classNames: {
                          RM500: "campaign-content",
                          "3%*": "campaign-content",
                          "Monthly Auto Cash In": "campaign-content",
                        },
                      }),
                    }}
                  />
                </CampaignCard>
              </div>
            ) : null
          )}

          <div
            style={{
              backgroundColor: "#fff",
              paddingTop: "1rem",
            }}
          >
            <div
              style={{
                ...styles.row,
                justifyContent: "space-between",
                margin: "0 16px 8px",
              }}
            >
              <Typography
                style={{
                  ...styles.baseFont,
                  fontWeight: "700",
                  color: "#262D58",
                }}
              >
                Financial goals
              </Typography>
              <div style={{ ...styles.row }} onClick={handleViewAllGoals}>
                <Typography color="primary" style={{ ...styles.baseFont }}>
                  View all
                </Typography>
                <img src={icons.arrowRight} alt="arrowRight" />
              </div>
            </div>

            {/* Most recent saving goal */}
            {savingGoal.length > 0 &&
              savingGoal.map(
                (
                  {
                    planAlias,
                    targetAmount,
                    totalCashIn,
                    portfolio,
                    id,
                    cashInStatus,
                    targetPercentage,
                    product: { image },
                    plan: { retailFlag },
                    currentValue,
                  },
                  index
                ) => (
                  <SavingItem
                    key={index}
                    cashInSts={cashInStatus}
                    retailFlag={retailFlag}
                    savingName={planAlias}
                    totalSaving={totalCashIn}
                    savingTarget={targetAmount}
                    savingMonthBar={
                      targetPercentage
                        ? Number(targetPercentage)
                        : ((portfolio.unitHeld * portfolio.fund.nav) /
                            targetAmount) *
                          100
                    }
                    image={image}
                    portfolio={portfolio}
                    currentValue={currentValue}
                    onClick={() => {
                      dataLayerGTM(
                        "click_tag",
                        "Click",
                        "Link",
                        `${planAlias}`
                      );
                      setCommingFrom("/dashboard");
                      history.push(`/goal-list/${id}`);
                    }}
                  />
                )
              )}

            <div style={{ margin: "18px 16px 16px 24px" }}>
              <CustomButton
                text="+ Create new goal"
                variant="outlined"
                color="#1278CC"
                backgroundColor="#fff"
                onClick={handleCheckEcdd({
                  cb: handleCreateNewGoal,
                  ecddExpiredCb: () =>
                    history.push("/onBoarding/ecdd/DASHBOARD"),
                  gtm: {
                    event: "click_tag",
                    action: "Click",
                    category: "Button",
                    label: "Create New Goal",
                  },
                })}
              />
            </div>
          </div>

          {newsFeed && newsFeed.length > 0 && (
            <NewsList
              handleViewAllNews={handleViewAllNews}
              newsFeed={newsFeed}
            />
          )}
        </>
      ) : (
        <EmptyStateSave
          handleNext={handleCheckEcdd({
            cb: handleCreateNewGoal,
            ecddExpiredCb: () => history.push("/onBoarding/ecdd/DASHBOARD"),
            gtm: {
              event: "click_tag",
              action: "Click",
              category: "Button",
              label: "Dashboard empty - Create New Goal",
            },
          })}
          faqLink="https://www.principal.com.my/en/investment-platform-faq/mv"
          title="Haven't invested yet?"
          desc={
            <>
              Build your financial goals and dreams with Principal. Invest from
              as low as RM10 to earn returns of up to{" "}
              <span
                style={{
                  color: "#fbd822",
                  fontWeight: 900,
                  fontSize: "22px",
                }}
              >
                {saveProjectedReturnValue}
              </span>{" "}
              p.a. in our low-risk investment option.
            </>
          }
          tagline={
            <>
              Invest smarter from as low as{" "}
              <span style={{ fontWeight: 900 }}>RM10.</span>
            </>
          }
          actionText="Create your financial Goals"
          illustration={images.goalInvestmentIllustration}
          className="l-invest--save"
        />
      )}
    </>
  );
};

const withConnect = connect(
  state => ({
    customerGoals: state.goal.customerGoals,
    goalSummary: state.goal.goalSummary,
    loadingCampaign: state.goal.loadingCampaign,
    loadingCustomerGoals: state.goal.loadingCustomerGoals,
    mpToken: state.auth.MPtoken,
    saveProjectedReturnValue: state.getStartedReducer.saveProjectedReturnValue,
    accountStatusLoading: state.dashboardReducer.accountStatusLoading,
  }),
  {
    getProjectedReturn,
    getCustomerGoals,
    getGoalSummary,
    setSavingGoalDetails,
    setGeneralSavingDetails,
    setSavingType,
    getAccountStatusV2,
  }
);

export default compose(
  withConnect,
  withRouter
)(Save);
